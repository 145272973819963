import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location } from 'src/app/shared/model/Location';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProductVariant, VariableProduct } from '../models/variable-product';
import { VariantView } from '../models/variant-to-view';
import { PackageView } from '../models/package-to-view';
import { PricingDetails } from '../models/pricing-to-view';
import { ShippingConfiguration } from '../../../../../../../shared/model/ShippingConfiguration';

@Injectable()
export class VariantDataStructureService {
  // VARIANTS HANDLERS
  variantsDetails = {
  } as ProductVariant;

  showPackagesSection = false;

  isFromMoreVariants = false;

  variantViewList: VariantView[] = [];

  // PACKAGES HANDLERS
  packageSizes: number[] = [];

  packageViewList: PackageView[] = [];

  // STOCK AND PRICING HANDLERS

  pricingDetailsView: PricingDetails[] = [];

  shippingConfigs = {} as ShippingConfiguration;

  // VARIANT PAYLOAD
  variantPayload: VariableProduct = {
    productImages: [],
    options: [{ option: '', values: [] }],
  };

  locations: Location[] = [];

  public statusSource = new BehaviorSubject<boolean>(false);

  currentStatus = this.statusSource.asObservable();

  validRoutes = [
    '/inventory/products/',
    '/inventory/products/variant-details',
  ];

  constructor(private router: Router) {
    this.displayRouterCaption();
  }

  displayRouterCaption(): void {
    this.router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((event: any) => {
      const currentRoute = event.snapshot._routerState.url;
      const [createEditPage, viewMoreDetailsPage] = this.validRoutes;
      if (currentRoute.includes(createEditPage) || currentRoute.includes(viewMoreDetailsPage)) {

      } else {
        this.clearPayload();
      }
    });
  }

  clearPayload(): void {
    this.variantViewList = [];
    this.variantsDetails = {} as any;
    this.packageSizes = [];
    this.packageViewList = [];
    this.pricingDetailsView = [];
    this.variantPayload = {
      productImages: [],
      options: [{ option: '', values: [] }],
    };
    this.isFromMoreVariants = false;
  }

  toggleSideBarNav() {
    this.statusSource.next(true);
  }
}
