import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Action, createReducer, on } from '@ngrx/store';
// import { ProductVariant } from '../../model/product-variant';
import * as TransferStockActions from './transfer-stock.actions';
import { TransferStock } from '../../models/transfer-stock';

export interface TransferStocksState extends EntityState<any> {
  areTransferStocksLoaded: boolean;
  isProcessing: boolean;
}

export const adaptor: EntityAdapter<TransferStock> = createEntityAdapter<TransferStock>({
  sortComparer: (a: TransferStock, b: TransferStock) => b.id - a.id,
});

export const initialTransferStocksState = {
  ...adaptor.getInitialState(),
  isProcessing: false,
};

export const reducer = createReducer(
  initialTransferStocksState,
  on(TransferStockActions.startProcessing, (state) => ({ ...state, isProcessing: true })),
  on(TransferStockActions.endProcessing, (state) => ({ ...state, isProcessing: false })),
  on(TransferStockActions.allTransferStocksLoaded, (state, action) => {
    const transferStocks = action.transferStocks || [];
    adaptor.removeAll(state);
    return adaptor.setAll(transferStocks, {
      ...state,
      areTransferStocksLoaded: true,
      isProcessing: false,
    });
  }),
  on(TransferStockActions.transferStockUpdated, (state, action) => adaptor
    .updateOne(action.update, state)),
  on(TransferStockActions.transferStockSaved, (state, action) => adaptor
    .addOne(action.transferStock, {
      ...state,
    })),
  on(TransferStockActions.multipleTransferStockSaved, (state, action) => adaptor
    .setAll(action.transferStock, {
      ...state,
    })),
  on(TransferStockActions.transferStockLoaded, ((state,
    action) => adaptor.addOne(action.transferStock, state))),
  on(TransferStockActions.updateBulkTransferStock, (state, action) => adaptor.updateMany(action.updates, state)),
);

export const {
  selectAll,
} = adaptor.getSelectors();

export function transferStocksReducer(state: TransferStocksState | undefined, action: Action) {
  return reducer(state, action);
}
