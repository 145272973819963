import { ICreateARN, Resources, TargetType } from '../types/arn-types';

export function getArn(params: ICreateARN): string {
  const { region, accountId, type } = params;
  if (!TargetType[type]) throw Error('Not Implemented.');
  else if (type === TargetType.LambdaFunction) {
    params.resourceName = `function:${params.resourceName}`;
  }
  return `arn:aws:${Resources[type]}:${region}:${accountId}:${params.resourceName}`;
}
