import { Pipe, PipeTransform } from '@angular/core';
import { SettingDropdownItems } from '../types';

@Pipe({
  name: 'resolveItemDisabled',
})
export class ResolveItemDisabled implements PipeTransform {
  transform(
    disabledAttrValue: SettingDropdownItems['disabled'] | undefined,
    data: unknown,
  ): boolean {
    if (typeof disabledAttrValue === 'function') {
      return disabledAttrValue(data);
    }
    return disabledAttrValue ?? false;
  }
}
