import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const ACTIVITY_URL_PREFIX = '/api/activity';

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient) {
  }

  getActivityServiceUsage(payload: any): Observable<any> {
    return this.http.post<any>(`${ACTIVITY_URL_PREFIX}/search`, payload);
  }
}
