import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { RewaaApiResponse } from './types/base-api-types';

/**
 * @deprecated Moved to packages/rewaa-api
 */
export abstract class RewaaApi {
  constructor(
    baseUrl: string,
    apiKey: string,
    additionalHeaders?: { [key: string]: string },
    retryConfig?: { enable: boolean; maxRetry: number; retryDelayMs: number },
  ) {
    this.baseUrl = baseUrl;
    this.additionalHeaders = additionalHeaders || this.additionalHeaders;
    this.retryConfig = retryConfig || this.retryConfig;
    this.axios = this.getAxiosInstance(apiKey);
  }

  private readonly axios: AxiosInstance;

  private readonly retryConfig = {
    enable: true,
    maxRetry: 2,
    retryDelayMs: 1000,
  };

  private readonly additionalHeaders: { [key: string]: string } = {};

  readonly baseUrl: string;

  private getAxiosInstance(apiKey: string): AxiosInstance {
    const instance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        ...this.additionalHeaders,
        'x-api-key': apiKey,
      },
    });

    if (this.retryConfig.enable) {
      axiosRetry(instance, {
        retries: this.retryConfig.maxRetry,
        retryDelay: (retryCount) => retryCount * this.retryConfig.retryDelayMs,
        onRetry: (retryCount, error, requestConfig) => {
          // TODO a common logger service should be used here
          // eslint-disable-next-line no-console
          console.error('Retrying API call:', {
            retryCount,
            error,
            requestConfig,
          });
        },
      });
    }

    return instance;
  }

  protected async get<T>(
    endpoint: string,
    params?: { [key: string]: string | number },
    headers?: { [key: string]: string },
  ): Promise<RewaaApiResponse<T>> {
    const response = await this.axios.get(endpoint, {
      params,
      headers,
    });
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  }

  protected async put<T>(
    endpoint: string,
    data: unknown,
    params?: { [key: string]: string | number },
    headers?: { [key: string]: string },
  ): Promise<RewaaApiResponse<T>> {
    const response = await this.axios.put(endpoint, data, {
      params,
      headers,
    });
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  }

  protected async post<T>(
    endpoint: string,
    data: unknown,
    params?: { [key: string]: string | number },
    headers?: { [key: string]: string },
  ): Promise<RewaaApiResponse<T>> {
    const response = await this.axios.post(endpoint, data, {
      params,
      headers,
    });
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  }

  protected async patch<T>(
    endpoint: string,
    data: unknown,
    params?: { [key: string]: string | number },
    headers?: { [key: string]: string },
  ): Promise<RewaaApiResponse<T>> {
    const response = await this.axios.patch(endpoint, data, {
      params,
      headers,
    });
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  }

  protected async delete<T>(
    endpoint: string,
    params?: { [key: string]: string | number },
    headers?: { [key: string]: string },
  ): Promise<RewaaApiResponse<T>> {
    const response = await this.axios.delete(endpoint, {
      params,
      headers,
    });
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  }
}
