import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { LocalStorageKey } from '../constants';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RwMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private localStorageService: LocalStorageService) {}

  handle(params: MissingTranslationHandlerParams) {
    const currentLang =
      params.translateService.defaultLang ||
      this.localStorageService.getItem<string>(
        LocalStorageKey.Language,
        params.translateService.defaultLang,
      );
    const langFile = this.localStorageService.getItem<any>(
      currentLang === 'en' ? LocalStorageKey.LanguageEn : LocalStorageKey.LanguageAr,
      {},
    );
    return langFile[params.key];
  }
}
