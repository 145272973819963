import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef, Inject, Injectable, Pipe, PipeTransform,
} from '@angular/core';
import {
  FakeMissingTranslationHandler,
  TranslateDefaultParser, TranslateFakeCompiler, TranslatePipe, TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable()
export class CustomTranslationService extends TranslateService {
  constructor(http: HttpClient) {
    super(null, new TranslateHttpLoader(http, 'assets/i18n/', '.json'), new TranslateFakeCompiler(), new TranslateDefaultParser(), new FakeMissingTranslationHandler(), false, true, false, null);
  }
}
