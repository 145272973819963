// eslint-disable-next-line check-file/folder-naming-convention
import { Injectable } from '@angular/core';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';
import { Customer } from '../../../model/order/Customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerStoreV2Service {
  private async getDB(): Promise<DatabaseService> {
    return DatabaseService.getDB();
  }

  async bulkPutCustomers(customers: Customer[]): Promise<void> {
    const db: DatabaseService = await this.getDB();
    await db.getRepository(TableName.OfflineCustomers).bulkPut(customers);
  }

  async deleteCustomers(ids: number[]): Promise<void> {
    const db: DatabaseService = await this.getDB();
    await db.getRepository(TableName.OfflineCustomers).bulkDelete(ids);
  }

  async findCustomers(searchQuery: string): Promise<Customer[]> {
    const db: DatabaseService = await this.getDB();
    const offlineCustomersRepo = db.getRepository(TableName.OfflineCustomers);
    return offlineCustomersRepo
      .where('searchableFields')
      .startsWithIgnoreCase(searchQuery)
      .distinct()
      .limit(20)
      .toArray();
  }

  async getCustomer(id: number): Promise<Customer> {
    const db = await DatabaseService.getDB();
    return db.getRepository(TableName.OfflineCustomers).get(id);
  }
}
