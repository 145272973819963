import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'primeng/sidebar';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { SideMenuService } from './side-menu.service';

@NgModule({
  declarations: [MenuItemComponent],
  imports: [RouterModule, CommonModule, SidebarModule, TranslateModule],
  providers: [SideMenuService],
  exports: [],
})
export class SideMenuModule {}
