import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { Router } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { RegexEnum } from '../../../../shared/model/enum/RegexPattern';
import { CustomToastService } from '../../../../shared/services/custom-toast.service';
import { ResetPasswordEmailStep } from '../../../auth.models';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageKey } from '../../../../shared/constants';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { MerchantService } from '../../../services/merchant.service';
import { FeatureFlagService } from '../../../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../../shared/constants/feature-flag.constants';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'rw-reset-password-v2',
  templateUrl: './reset-password-v2.component.html',
  styleUrls: ['./reset-password-v2.component.scss'],
})
@AutoUnsubscribe
export class ResetPasswordV2Component implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  fGroup: UntypedFormGroup;

  isShowSpinner = false;

  isVerificationCodeSent = false;

  userEmail: string;

  changeLang: string;

  lang: string;

  message: string;

  sendToEmailButtonVisibility = false;

  resendConfirmationCode = false;

  translationPrefix = 'resetPassword';

  smsOtpFeatureFlag = false;

  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private merchantService: MerchantService,
    public translate: TranslateService,
    private intercom: Intercom,
    private toaster: CustomToastService,
    private localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
      'ar',
    );
    translate.setDefaultLang(this.lang);
    this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    this.localStorageService.setItem<string>(
      LocalStorageKey.Language,
      this.lang,
    );

    if (this.router.getCurrentNavigation()?.extras?.state) {
      const state = this.router.getCurrentNavigation()?.extras?.state;
      if (state?.unverifiedUser === true) {
        this.userEmail = state?.email;
        this.subscriptionRefs = this.merchantService
          .forgotPasswordMerchant(this.userEmail)
          .subscribe({
            next: () => {
              this.isVerificationCodeSent = true;
              this.toaster.success(
                this.translate.instant(
                  'A verification code is sent to your email',
                ),
              );
            },
            error: (err) => {
              this.toaster.error(err.message);
            },
          });
      }
    }
  }

  ngOnInit() {
    this.createForm();

    this.featureFlagService
      .isEnabled(FeatureFlagEnum.SmsOtp, undefined)
      .subscribe((smsOtpFeatureFlag) => {
        this.smsOtpFeatureFlag = smsOtpFeatureFlag;
      });
  }

  get email() {
    return this.fGroup.get('email');
  }

  createForm() {
    const emailRegex = environment.allowAliasedEmails
      ? RegexEnum.laxEmail
      : RegexEnum.email;

    this.fGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    });
  }

  startIntercom(): void {
    this.intercom.show();
  }

  startResetPasswordV1(formContent: ResetPasswordEmailStep) {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .forgotPasswordMerchantV1(formContent.email)
      .subscribe({
        next: (response) => {
          if (response.status === 'unverified') {
            this.toaster.warning(
              this.translate.instant(
                'This account is not verified, please verify the account first!',
              ),
            );
            this.router.navigate(['/register'], {
              queryParams: { email: formContent.email.toLowerCase() },
              state: { unverifiedUser: true },
            });
          } else {
            this.isShowSpinner = false;
            this.isVerificationCodeSent = true;
            this.userEmail = formContent.email.toLowerCase();
            this.toaster.success(
              this.translate.instant(
                'A verification code is sent to your email',
              ),
            );
          }
        },
        error: (err) => {
          this.toaster.error(this.translate.instant(err.message));
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  startResetPassword(formContent: ResetPasswordEmailStep) {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .forgotPasswordMerchant(formContent.email)
      .subscribe({
        next: (response) => {
          let toastMessage: string;
          if (response.data.resendConfirmationCode) {
            this.resendConfirmationCode = true;
          }

          if (response.CodeDeliveryDetails.DeliveryMedium === 'SMS') {
            if (!this.resendConfirmationCode)
              this.sendToEmailButtonVisibility = true;
            const phone = response.CodeDeliveryDetails.Destination;
            toastMessage = this.translate.instant(
              'A verification code is sent to your mobile phone',
            );
            this.message = this.translate.instant(
              `${this.translationPrefix}.resetPasswordCodeToPhone`,
              { email: formContent.email, phone: phone.slice(-2) },
            );
          } else {
            toastMessage = this.translate.instant(
              'A verification code is sent to your email',
            );
            this.message = this.translate.instant(
              `${this.translationPrefix}.resetPasswordCodeToEmail`,
              { email: formContent.email },
            );
          }
          this.isShowSpinner = false;
          this.isVerificationCodeSent = true;
          this.userEmail = formContent.email.toLowerCase();
          this.toaster.success(toastMessage);
        },
        error: (err) => {
          this.isShowSpinner = false;
          if (
            err.error &&
            (err.error.name?.includes('LimitExceededException') ||
              err.error.message?.includes('LimitExceededException'))
          ) {
            this.toaster.error(
              this.translate.instant(
                'confirmRegistration.limitExceededMessage',
              ),
            );
          } else if (err.error?.name !== 'USER INPUT ERROR') {
            this.toaster.error(this.translate.instant(err.message));
          }
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  switchLang(lang: string) {
    this.localStorageService.setItem<string>(
      LocalStorageKey.Language,
      lang,
    );
    this.authService.switchLanguage(lang);
  }
}
