import { PosInvoiceTypes, ProductTypes } from '@rewaa-team/types';
import {
  ECardForUpdate,
} from '../factories/product/types/product-types';

export const mapVariantToTrackForUpdateStocks = (
  variantToInvoice: PosInvoiceTypes.VariantToInvoice,
  useAbsoluteQuantity = false,
): ProductTypes.VariantToTrackForUpdate[] => {
  return (
    variantToInvoice.VariantToInvoiceTracks?.map(
      (track) =>
        ({
          id: track.variantToTrackId,
          expiryDate: track.expiryDate,
          issueDate: track.issueDate,
          quantity: useAbsoluteQuantity
            ? Math.abs(track.quantity)
            : track.quantity,
          supplierId: track.supplierId,
          trackNo: track.trackNo,
          variantToInvoiceTrackId: track.id,
        } as ProductTypes.VariantToTrackForUpdate),
    ) || []
  );
};

export const mapEcardsForUpdateStocks = (
  variantToInvoice: PosInvoiceTypes.VariantToInvoice,
  returnEcard = false,
): ECardForUpdate[] => {
  return (
    variantToInvoice.VariantToInvoiceEcards?.map(
      (ecard) =>
        ({
          id: ecard.ecardId,
          code: ecard.code,
          productVariantId: variantToInvoice.productVariantId,
          variantToInvoiceEcardId: ecard.id,
          isSold: returnEcard ? false : undefined,
        } as ECardForUpdate),
    ) || []
  );
};
