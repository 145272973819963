import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { ColumnFilter } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { DATE_FILTER_LOOKUP_V4 } from '../../../../constants/lookup.constants';
import { CellTemplates, ComparisonOperatorOption, ComparisonOperatorsV2, DatatableColumnV3, DateTypes, DEFAULT_MATCH_MODES } from '../../types';
import { DATE_LOOKUP_OPTIONS_V4, LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { KeyboardEventKey } from '../../../../constants/keyboard-shortcuts.constants';

@Component({
  selector: 'rw-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnInit {
  @Input() col: DatatableColumnV3;

  @Input() loading: boolean = false;

  @Input() display = 'menu';

  @Input() showButtons = true;

  @Input() tableFilters = {};

  @Input() dateRanges = {};

  @Input() selectedCustomDateFilter: object | undefined;

  @Input() autoCompleteSearches = {};

  autoCompleteInputs = {};

  cellTemplates = CellTemplates;

  defaultMatchModes = DEFAULT_MATCH_MODES;

  dateFilterOpts = DATE_FILTER_LOOKUP_V4;

  dateTypes = DateTypes;

  autoCompleteField = 'name';

  chunkSize = 20;

  searchSubject = new Subject<{ col: DatatableColumnV3; query: string }>();

  oldTableFilters = {};

  oldDateRanges = {};

  oldAutoCompleteSearches = {};

  oldAutoCompleteInputs = {};

  changeDetected = false;

  timeLabel: any[];

  selectedTime: any = 'AM';

  booleanOptions: any[];

  singleDaySelected: boolean = false;

  isSpecificRangeSelected: boolean = false;

  specificDateFrom: Date;

  specificDateTo: Date;

  numberFilterOptions: ComparisonOperatorOption[];

  operatorNames: string[];

  betweenOperatorFromValue: number;

  betweenOperatorToValue: number;

  @Output() filterApplied = new EventEmitter<{
    tableFilters;
    dateRanges;
    autoCompleteSearches;
    autoCompleteInputs;
  }>();

  @Output() filterChanges = new EventEmitter<{
    tableFilters;
    dateRanges;
    autoCompleteSearches;
    autoCompleteInputs;
  }>();

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public config: PrimeNGConfig,
  ) {
    const lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(lng);
    const translationKeys = [
      'primeng',
      'Whole Day',
      'Specific Time',
      'today',
      'yesterday',
      'last 7 days',
      'last 30 days',
      'this month',
      'last month',
      'this week',
    ];
    this.translate.get(translationKeys).subscribe((translatedTexts) => {
      this.config.setTranslation(translatedTexts.primeng);
    });
    this.timeLabel = [
      { name: 'AM', value: 'AM' },
      { name: 'PM', value: 'PM' },
    ];
    this.booleanOptions = [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ];
  }

  ngOnInit(): void {
    this.initializeComparisonOperatorOptions();
    this.searchSubject.pipe(debounceTime(500)).subscribe((event) => {
      this.onSearch(event.col, event.query);
    });
    this.dateFilterOpts = this.dateFilterOpts.map(({ name, value }) => ({
      name: this.translate.instant(name),
      value,
    }));

    if (this.selectedCustomDateFilter && Object.keys(this.selectedCustomDateFilter).length > 0) {
      this.dateRanges = this.selectedCustomDateFilter;
      const firstEntry = Object.entries(this.dateRanges)[0];
      this.specificDateFrom = firstEntry[1][0];
      this.specificDateTo = firstEntry[1][1];
      this.validateSpecificTimeSelected();
    }
  }


  private validateSpecificTimeSelected(): void {
    if (!this.isStartOfDay(this.specificDateFrom) || !this.isEndOfDay(this.specificDateTo)) {
      this.col.dateType = DateTypes.Specific;
      return;
    }
    this.col.dateType = DateTypes.Range;
  }

  onAllDayChange(): void {
    const firstEntry = Object.entries(this.dateRanges)[0];
    this.setToStartOfDay(firstEntry[1][0]);
    this.setToEndOfDay(firstEntry[1][1]);
  }

  private setToStartOfDay(date: Date): void {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }

  private setToEndOfDay(date: Date): void {
    date.setHours(23);
    date.setMinutes(59);
  }

  private isStartOfDay(date: Date): boolean  {
    return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
  };

  private isEndOfDay(date: Date): boolean {
    return date.getHours() === 23 && date.getMinutes() === 59;
  };

  private initializeComparisonOperatorOptions(): void {
    if (this.col.cellTemplate === CellTemplates.NumericV2) {
      this.numberFilterOptions = [
        {
          name: this.translate.instant('primeng.equals'),
          value: ComparisonOperatorsV2.Equal,
        },
        {
          name: this.translate.instant('primeng.between'),
          value: ComparisonOperatorsV2.Between,
        },
        {
          name: this.translate.instant('primeng.lt'),
          value: ComparisonOperatorsV2.LessThan,
        },
        {
          name: this.translate.instant('primeng.gt'),
          value: ComparisonOperatorsV2.GreaterThan,
        },
      ];
      this.operatorNames = this.numberFilterOptions.map(option => option.name);
      this.col.operator = this.numberFilterOptions[0].value;
    }
  }

  onNumberFilterDropdownChange(optionName: string): void {
    const operator: ComparisonOperatorOption = this.numberFilterOptions.find((option: ComparisonOperatorOption) => option.name === optionName);
    if (operator) {
      this.col.operator = operator.value;
    }
  }

  // Implementing Custom Change Detection
  ngDoCheck(): void {
    if (!this.showButtons) {
      if (
        JSON.stringify(this.tableFilters) !==
        JSON.stringify(this.oldTableFilters)
      ) {
        this.changeDetected = true;
        this.oldTableFilters = { ...this.tableFilters };
      }

      if (
        JSON.stringify(this.dateRanges) !== JSON.stringify(this.oldDateRanges)
      ) {
        this.changeDetected = true;
        this.oldDateRanges = { ...this.dateRanges };
      }

      if (
        JSON.stringify(this.autoCompleteSearches) !==
        JSON.stringify(this.oldAutoCompleteSearches)
      ) {
        this.changeDetected = true;
        this.oldAutoCompleteSearches = { ...this.autoCompleteSearches };
      }

      if (
        JSON.stringify(this.autoCompleteInputs) !==
        JSON.stringify(this.oldAutoCompleteInputs)
      ) {
        this.changeDetected = true;
        this.oldAutoCompleteInputs = { ...this.autoCompleteInputs };
      }

      if (this.changeDetected) {
        this.filterChanges.emit({
          tableFilters: this.tableFilters,
          dateRanges: this.dateRanges,
          autoCompleteSearches: this.autoCompleteSearches,
          autoCompleteInputs: this.autoCompleteInputs
        });
      }

      this.changeDetected = false;
    }
  }

  onSpecificRangeSelected(event, col, dropdownType: 'to' | 'from'): void {
    const selectedDate: Date = new Date(event);

    const specificRangeOption = this.dateFilterOpts.find(option => option.name === this.translate.instant(DATE_LOOKUP_OPTIONS_V4.SPECIFIC_RANGE));

    dropdownType === 'to' ? this.specificDateTo = selectedDate : this.specificDateFrom = selectedDate;

    if (specificRangeOption) {
      specificRangeOption.value = [
        this.specificDateFrom,
        this.specificDateTo
      ];
    }

    if (this.specificDateTo && this.specificDateFrom) {
      // Create a Date object from specificDateTo
      let specificDateToObj = new Date(this.specificDateTo);
      // Add one day (24 hours) to the date
      specificDateToObj.setDate(specificDateToObj.getDate() + 1);
      // Subtract one second (6000 milliseconds) from the new date
      specificDateToObj = new Date(specificDateToObj.getTime() - 1);
      // Update the specificDateTo with the new value
      this.specificDateTo = specificDateToObj;
      specificRangeOption.value = [this.specificDateFrom, this.specificDateTo];
      this.dateRanges[col.prop] = [this.specificDateFrom, this.specificDateTo];
      return;
    }

    specificRangeOption.value = [];
    this.dateRanges[col.prop] = [];
  }

  onDateFilterChange(event, col): void {
    this.specificDateTo = event.value[1];
    this.specificDateFrom = event.value[0];
    if (event.originalEvent.target.textContent === this.translate.instant('specificRange')) {
      this.isSpecificRangeSelected = true;
    } else {
      this.isSpecificRangeSelected = false;
    }
    this.dateRanges[col.prop] = event.value;
    col.dateType = this.dateTypes.Range;
    this.singleDaySelected = this.areDatesOnSameDay(this.dateRanges[col.prop]);
  }

  onDateTimeFilterChange(col, range: Date[]): void {
    this.singleDaySelected = this.areDatesOnSameDay(range);
    if (this.singleDaySelected) {
      this.dateRanges[col.prop] = range;
      return;
    }
    this.assignTimeToDateRange(col.prop, range);
  }

  private assignTimeToDateRange(columnProp: string, dateRangeWithTime: Date[]): void {
    const fromHours: number = dateRangeWithTime[0].getHours();
    const toHours: number = dateRangeWithTime[1].getHours();

    const fromMinutes: number = dateRangeWithTime[0].getMinutes();
    const toMinutes: number = dateRangeWithTime[1].getMinutes();

    this.dateRanges[columnProp][0].setHours(fromHours, fromMinutes, 0, 0);
    this.dateRanges[columnProp][1].setHours(toHours, toMinutes, 0, 0);
  }

  private areDatesOnSameDay(dates: Date[]): boolean {
    if (dates.length !== 2) {
      return false;
    }

    const [date1, date2] = dates;

    const sameYear = date1.getFullYear() === date2.getFullYear();
    const sameMonth = date1.getMonth() === date2.getMonth();
    const sameDay = date1.getDate() === date2.getDate();

    return sameYear && sameMonth && sameDay;
  }

  autoCompleteUnSelected(col: DatatableColumnV3, value): void {
    const index = this.autoCompleteSearches[col.prop].findIndex(
      (v) => v.name === value.name,
    );
    if (index !== -1) {
      this.autoCompleteSearches[col.prop].splice(index, 1);
    }
    this.tableFilters[col.prop] = this.autoCompleteSearches[col.prop].map(
      (v) => v[this.autoCompleteField],
    );
  }

  autoCompleteSelected(col: DatatableColumnV3, value): void {
    if (!this.autoCompleteSearches[col.prop]) {
      this.autoCompleteSearches[col.prop] = [];
    }
    const exists = this.autoCompleteSearches[col.prop].find((item) => item.id ? item.id === value.id : item.name === value.name);
    if (!exists) {
      this.autoCompleteSearches[col.prop].push(value);
      this.tableFilters[col.prop] = this.autoCompleteSearches[col.prop].map(
        (v) => v[this.autoCompleteField],
      );
    }
    this.autoCompleteInputs[col.prop] = '';
  }

  updateSearchValue(col: DatatableColumnV3, value: string): void {
    this.searchSubject.next({ col, query: value });
  }

  onSearch(col: DatatableColumnV3, value: string): void {
    col.onSearch!(col, value, this.chunkSize, 0);
  }

  clearFilter(col: DatatableColumnV3, ft: ColumnFilter): void {
    // eslint-disable-next-line no-param-reassign
    this.reset(col);
    // eslint-disable-next-line no-param-reassign
    col.operator = undefined;
    ft.hide();
  }

  applyFilter(
    col: DatatableColumnV3,
    filterFunction: Function,
    ft: ColumnFilter,
  ): void {
    let filterValue;
    this.isSpecificRangeSelected = false;
    const dateRange = this.dateRanges[col.prop] || [];
    switch (col.cellTemplate) {
      case this.cellTemplates.Date:
        if (col.dateType === DateTypes.Specific && this.singleDaySelected) {
          this.tableFilters[col.prop] = dateRange;
        }
        else if (col.dateType === DateTypes.Specific && !this.singleDaySelected) {
          this.tableFilters[col.prop] = [
            DateTime.fromJSDate(dateRange).startOf('day').toJSDate(),
            DateTime.fromJSDate(dateRange).endOf('day').toJSDate(),
          ];
        } else if (col.dateType === DateTypes.Range) {
          this.tableFilters[col.prop] = [
            DateTime.fromJSDate(dateRange[0]).startOf('day').toJSDate(),
            DateTime.fromJSDate(dateRange[1]).endOf('day').toJSDate(),
          ];
        }
        filterValue = this.tableFilters[col.prop];
        break;
      case this.cellTemplates.DateTime:
        if (col.dateType === DateTypes.Specific) {
          this.tableFilters[col.prop] = [
            DateTime.fromJSDate(dateRange).toJSDate(),
            DateTime.fromJSDate(dateRange).endOf('day').toJSDate(),
          ];
        } else if (col.dateType === DateTypes.Range) {
          this.tableFilters[col.prop] = [
            DateTime.fromJSDate(dateRange[0]).toJSDate(),
            DateTime.fromJSDate(dateRange[1]).endOf('day').toJSDate(),
          ];
        }
        filterValue = this.tableFilters[col.prop];
        break;
      case this.cellTemplates.MultiSelect:
        if (!this.tableFilters[col.prop]?.length) {
          this.tableFilters[col.prop] = null;
        }
        filterValue = this.tableFilters[col.prop];
        break;
      case this.cellTemplates.AutoComplete:
        if (!this.autoCompleteSearches[col.prop]?.length && !this.autoCompleteInputs[col.prop]) {
          this.autoCompleteSearches[col.prop] = null;
          this.tableFilters[col.prop] = null;
        }
        if (this.autoCompleteInputs[col.prop]) {
          const obj = { id: this.autoCompleteInputs[col.prop], name: this.autoCompleteInputs[col.prop] };
          this.autoCompleteSelected(col, obj);
        }
        filterValue = this.tableFilters[col.prop];
        break;
      case this.cellTemplates.Numeric:
        filterValue = {
          operator: col.operator,
          value: this.tableFilters[col.prop],
        };
        break;
      case this.cellTemplates.NumericV2:
        if (col.operator === ComparisonOperatorsV2.Between) {
          filterValue = {
            operator: col.operator,
            value: [this.betweenOperatorFromValue, this.betweenOperatorToValue],
          };
          break;
        } else {
          filterValue = {
            operator: col.operator,
            value: this.tableFilters[col.prop],
          };
          break;
        }
      default:
        filterValue = this.tableFilters[col.prop];
    }
    this.emitFilters();
    filterFunction(filterValue);
    ft.hide();
  }

  autoCompleteBlur(col, event) {
    // console.log(this.autoCompleteSearches);
    const { value } = event.target;
    if (value) {
      const obj = { id: value, name: value };
      if (!this.autoCompleteSearches[col.prop]) {
        this.autoCompleteSearches[col.prop] = [];
      }
      this.autoCompleteSearches[col.prop].push(obj);
      this.tableFilters[col.prop] = this.autoCompleteSearches[col.prop].map(
        (v) => v[this.autoCompleteField],
      );
      event.target.value = '';
    }
  }

  autoCompleteKeyUp(col, event) {
    // console.log(event);
    const { value } = event.target;
    if (value) {
      if (
        event.code === KeyboardEventKey.Enter ||
        event.code === KeyboardEventKey.Comma
      ) {
        const splittedValue = value.split(',')[0];
        const obj = { id: splittedValue, name: splittedValue };
        if (!this.autoCompleteSearches[col.prop]) {
          this.autoCompleteSearches[col.prop] = [];
        }
        this.autoCompleteSearches[col.prop].push(obj);
        this.tableFilters[col.prop] = this.autoCompleteSearches[col.prop].map(
          (v) => v[this.autoCompleteField],
        );
        event.target.value = '';
        this.autoCompleteInputs[col.prop] = '';
      } else {
        this.autoCompleteInputs[col.prop] = value;
      }
    }
    // console.log(this.autoCompleteSearches);
  }

  private emitFilters() {
    this.filterApplied.emit({
      tableFilters: this.tableFilters,
      dateRanges: this.dateRanges,
      autoCompleteSearches: this.autoCompleteSearches,
      autoCompleteInputs: this.autoCompleteInputs,
    });
  }

  private reset(col: DatatableColumnV3): void {
    this.tableFilters[col.prop] = null;
    this.dateRanges[col.prop] = '';
    this.autoCompleteSearches[col.prop] = null;
  }

  protected readonly ComparisonOperatorsV2 = ComparisonOperatorsV2;
}
