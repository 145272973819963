export abstract class RewaaApiException extends Error {
  stackTrace?: string;
  statusCode: number;
  errorCode: string;
  msgs: string[];
  protected constructor(
    statusCode: number,
    errorCode: string,
    errors: string[],
    stackTrace?: string,
  ) {
    super();
    this.statusCode = statusCode;
    this.stackTrace = stackTrace;
    this.errorCode = errorCode;
    this.msgs = errors;
  }

  get Error(): {
    stackTrace: string | undefined;
    errorCode: string;
    meta: { msgs: string[] };
  } {
    return {
      stackTrace: this.stackTrace,
      errorCode: this.errorCode,
      meta: { msgs: this.msgs },
    };
  }

  get status(): number {
    return this.statusCode;
  }

  get trace(): string | undefined {
    return this.stackTrace;
  }

  get code(): string {
    return this.errorCode;
  }

  get response(): string[] {
    return this.msgs;
  }
}
