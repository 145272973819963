<div class="header" *ngIf="users">
    <div class="rw-logo">
        <a [routerLink]="['/']">
            <img src="/assets/icons/logo.svg" alt="" />
        </a>
    </div>
    <ul class="header-col list-style-list header-action-list">
        <li>
            <p-menu #userMenu [popup]="true" [model]="userMenuItems" appendTo="body"></p-menu>

            <p-button (click)="userMenu.toggle($event)" pRipple iconPos="right" [ngClass]="lang ==='en' ? 'ltr':'rtl'"
                styleClass="p-button-secondary p-button-text user-info-button">
                <ng-template pTemplate="content">
                    <div class="user-info-title">
                        <span class="user-info-name">{{ (users?.name) ? users.name : users.email }}</span>
                        <span class="user-info-role">{{users?.SystemRole|translate}}</span>
                    </div>
                    <i class="user-info-icon fa-regular fa-angle-down"></i>
                </ng-template>
            </p-button>
        </li>
    </ul>
</div>


<p-dialog class="rw__dialog rw__dialog-md" [style]="{maxWidth: '42.5rem'}" [visible]="visible" [modal]="true"
    position="center" [draggable]="false" [showHeader]="false" [resizable]="false">
    <div class="d-flex flex-column align-items-center text-center rw__py-6">
        <label class="fw-500 rw__fs-5 text-grey-80 rw__mb-0 rw__mt-7">{{ translationKey+'pageUnderMaintenance' |
            translate }}</label>
        <label class="fw-400 rw__fs-8 text-grey-50 text-center">{{ translationKey+'contactCustomerSupport' | translate
            }}</label>
        <div class="d-flex flex-wrap align-items-center gap-2 justify-content-center rw__mt-12 rw__mb-4 w-full">
            <button pButton pRipple type="button" (click)="goToDashboard()"
                class="rw__button p-button-outlined p-button-primary w-md-auto w-100"
                label="{{ translationKey+'gotoDashboardButton' | translate }}"></button>
            <button pButton pRipple type="button" (click)="openIntercom()"
                class="rw__button p-button-raised w-md-auto w-100"
                label="{{ translationKey+'contactSupportButton' | translate }}"></button>
        </div>
    </div>
</p-dialog>