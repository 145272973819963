import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { tap } from 'rxjs/operators';
import { TaxJson } from 'src/app/internal-apps/pos/model/VariantToQuotation';
import { getProductPriceWithPromotionsAndDiscountTaxInclusive, getSellTaxExclusivePrice } from 'src/app/internal-apps/pos/utils/quotation.util';
import { AppState } from 'src/app/reducers';
import { ITaxConfiguration } from 'src/app/shared/model/TaxConfiguration';
import { getUserPriceConfig } from 'src/app/users-settings/selectors/price-configuration.selector';
import { InvoicesCalculator } from '../../../../../invoices/utilities/invoices.calculator';
import { LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
@Component({
  selector: 'rw-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrls: ['./view-quote.component.scss'],
})
export class ViewQuoteComponent implements OnInit {
  @Input() content: any;

  langCss: string;

  products = [];

  promotionsExpired = [];

  promotionAmount: number = 0;

  priceConfiguration: ITaxConfiguration;

  constructor(
    public translate: TranslateService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
  ) {
    const lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(lng);
    this.langCss = lng === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    this.store.pipe(
      select(getUserPriceConfig()),
      tap((priceConfiguration) => {
        this.priceConfiguration = priceConfiguration;
      }),
    ).subscribe();

    this.products = [];
    this.content.data.quote.VariantToQuotations.forEach((q) => {
      if (q.ProductVariant) {
        const promotionJson = q.promotionsJson;
        const promotion = promotionJson ? JSON.parse(promotionJson) : [];

        const tax = JSON.parse(q.taxJson) as TaxJson;
        const currentDate = DateTime.now();
        if (promotion && currentDate > promotion.endDate) {
          this.promotionsExpired.push(promotion);
        }

        if (promotion && !(currentDate > promotion.endDate)) {
          this.promotionAmount += getSellTaxExclusivePrice(
            promotion.discountApplied,
            tax.rate,
            this.priceConfiguration,
          );
        }

        // eslint-disable-next-line max-len
        const extras = [];

        q?.VariantToQuotationToExtras?.forEach((extra) => {
          const [productExtra] = q.ProductVariant.VariantExtraLocations
            .filter((e) => e.extraId === extra.extraId);

          const perExtraPrice = InvoicesCalculator.add(extra.priceTaxExclusive, extra.tax);
          const extrasPrice = InvoicesCalculator.multiply(perExtraPrice, extra.quantity);

          if (productExtra) {
            extras.push({
              name: productExtra.Extra.name,
              quantity: extra.quantity,
              price: perExtraPrice,
              totalPrice: extrasPrice,
            });
          }
        });

        const price = q.priceTaxExclusive + q.tax;
        const prod = {
          name: q.ProductVariant.name,
          quantity: q.quantity,
          price,
          totalPrice: price * q.quantity,
        };
        this.products = [...this.products, ...(extras.length ? [prod, ...extras] : [prod])];
      }
    });
  }
}
