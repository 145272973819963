import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[rwDatatableRow]'
})
export class DatatableRowDirective {
  @Input() newDataHighlightColumnName: string;
  @Input() newDataHighlightMaxTime: number;
  @Input() isSelected: boolean;
  @Input() rwDatatableRow!: object;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(): void {
    this.applyClasses();
  }

  private applyClasses(): void {
    const classes = {
      'selected-row': this.isSelected,
      'new': this.isNewRow()
    };

    for (const [className, shouldApply] of Object.entries(classes)) {
      if (shouldApply) {
        this.renderer.addClass(this.el.nativeElement, className);
      } else {
        this.renderer.removeClass(this.el.nativeElement, className);
      }
    }
  }

  private isNewRow(): boolean {
    if (!this.rwDatatableRow || !this.newDataHighlightColumnName || !this.newDataHighlightMaxTime) {
      return false;
    }
    const dateValue = this.rwDatatableRow[this.newDataHighlightColumnName];
    if (!dateValue) {
      return false;
    }
    const currentDateTime: Date = new Date();
    currentDateTime.setMilliseconds(currentDateTime.getMilliseconds() - this.newDataHighlightMaxTime);
    const columnDateTime = new Date(dateValue);
    return columnDateTime >= currentDateTime;
  }
}
