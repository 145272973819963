<ng-template
    #rt
    let-tracklist="result"
    let-t="term"
>
    {{ tracklist.trackNo }}
</ng-template>


<div
    [ngClass]="langCss"
    class="m-3 content-body"
>
    <div class='header-row'>
        <div class="product-col">{{ 'Product Name' | translate }}</div>
        <div class="qty-col">{{ 'Quantity' | translate }}</div>
        <div class="serial-col-header">
            <span class="required-astrik">*</span>
            {{ 'Serial Number' | translate }}
        </div>
        <div class="date-col">{{ 'Expiration Date' | translate }}</div>
        <div class="date-col">{{ 'Issue Date' | translate }}</div>
    </div>
    <div
        *ngFor="let variant of getTrackedVariant(); let varIndex = index"
        class="w-full"
    >
        <div>
            <div class="product-summary-row">
                <div class="product-col">{{variant.name}}</div>
                <div class="qty-col">
                    {{ getVariantToInvoiceQuantity(variant)}}
                </div>
                <div class="serial-col"></div>
                <div class="date-col"></div>
                <div class="date-col"></div>
            </div>


            <div
                class="product-detail-row"
                [formGroup]="trackForm"
                *ngFor="let trackForm of getVariantTrackFormArray(variant.id); let formNumber = index"
            >
                <div class="product-col"></div>
                <div class="qty-col"></div>
                <div class="serial-col">
                    <p-autoComplete
                        #autoComplete
                        id="typeahead-trackNo{{variant.id}}{{formNumber}}"
                        type="text"
                        [suggestions]="searchResults"
                        field="trackNo"
                        [minLength]="1"
                        (completeMethod)="onSearchTrack($event, variant.id)"
                        pendo-tracker="spi-1"
                        formControlName="trackNo"
                        appendTo="body"
                        (onFocus)="onFocus($any($event).target.value)"
                    >
                        <ng-template let-track pTemplate="item">
                            {{ track.trackNo }}
                        </ng-template>
                    </p-autoComplete>
                    <small
                        class="err-msg"
                        *ngIf="trackForm.get('trackNo').errors?.Duplicated"
                    >
                        {{'You add the same serial number ID twice' | translate }}
                    </small>
                    <small
                        class="err-msg"
                        *ngIf="trackForm.get('trackNo').errors?.notAvilable"
                    >
                        {{'No results found for this search.' | translate }}
                    </small>
                    <small
                        class="err-msg"
                        *ngIf="trackForm.get('trackNo').errors?.unAvailableSerial"
                    >
                        {{'This serial not available' | translate }}
                    </small>
                </div>
                <div class="date-col">
                    <input pendo-tracker="spi-2"
                        type="date"
                        useValueAsDate
                        formControlName="expiryDate"
                        [value]="date | date: 'dd-MM-yyyy'"
                        class="form-control"
                        name="expiryDate"
                        id="expiryDate"
                    >
                    <small
                        class="err-msg"
                        *ngIf="trackForm.errors?.dates"
                    >
                        {{'Must be greater than issue Date' | translate }}
                    </small>
                </div>
                <div class="date-col">
                    <input pendo-tracker="spi-3"
                        type="date"
                        useValueAsDate
                        formControlName="issueDate"
                        [value]="date | date: 'dd-MM-yyyy'"
                        class="form-control"
                        name="issueDate"
                        id="issueDate"
                    >
                </div>
            </div>


        </div>
    </div>
</div>
