import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule as PrimeBreadcrumbModule } from 'primeng/breadcrumb';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbV2Component } from './breadcrumb-v2.component';

@NgModule({
  declarations: [BreadcrumbV2Component],
  imports: [TranslateModule, PrimeBreadcrumbModule, CommonModule],
  exports: [BreadcrumbV2Component],
})
export class BreadcrumbV2Module {}
