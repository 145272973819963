import { WhereClause, IndexableType, Collection } from 'dexie';
import { CollectionOnSteroids } from './collection-on-steroids';
import { TableOnSteroids } from './table-on-steroids';

export class WhereClauseOnSteroids implements WhereClause {
  constructor(private table: TableOnSteroids) {}
  above(key: any): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'above',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  aboveOrEqual(key: any): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'aboveOrEqual',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  anyOf(keys: readonly IndexableType[]): Collection<any, IndexableType>;
  anyOf(...keys: IndexableType[]): Collection<any, IndexableType>;
  anyOf(keys?: unknown, ...rest: unknown[]): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'anyOf',
      parameters: [keys, ...rest],
    });
    return new CollectionOnSteroids(this.table);
  }
  anyOfIgnoreCase(keys: string[]): Collection<any, IndexableType>;
  anyOfIgnoreCase(...keys: string[]): Collection<any, IndexableType>;
  anyOfIgnoreCase(
    keys?: unknown,
    ...rest: unknown[]
  ): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'anyOfIgnoreCase',
      parameters: [keys, ...rest],
    });
    return new CollectionOnSteroids(this.table);
  }
  below(key: any): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'below',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  belowOrEqual(key: any): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'belowOrEqual',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  between(
    lower: any,
    upper: any,
    includeLower?: boolean,
    includeUpper?: boolean,
  ): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'between',
      parameters: [lower, upper, includeLower, includeUpper],
    });
    return new CollectionOnSteroids(this.table);
  }
  equals(key: IndexableType): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'equals',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  equalsIgnoreCase(key: string): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'equalsIgnoreCase',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  inAnyRange(
    ranges: readonly { 0: any; 1: any }[],
    options?: { includeLowers?: boolean; includeUppers?: boolean },
  ): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'inAnyRange',
      parameters: [ranges, options],
    });
    return new CollectionOnSteroids(this.table);
  }
  startsWith(key: string): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'startsWith',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  startsWithAnyOf(prefixes: string[]): Collection<any, IndexableType>;
  startsWithAnyOf(...prefixes: string[]): Collection<any, IndexableType>;
  startsWithAnyOf(
    prefixes?: unknown,
    ...rest: unknown[]
  ): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'startsWithAnyOf',
      parameters: [prefixes, ...rest],
    });
    return new CollectionOnSteroids(this.table);
  }
  startsWithIgnoreCase(key: string): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'startsWithIgnoreCase',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
  startsWithAnyOfIgnoreCase(prefixes: string[]): Collection<any, IndexableType>;
  startsWithAnyOfIgnoreCase(
    ...prefixes: string[]
  ): Collection<any, IndexableType>;
  startsWithAnyOfIgnoreCase(
    prefixes?: unknown,
    ...rest: unknown[]
  ): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'startsWithAnyOfIgnoreCase',
      parameters: [prefixes, ...rest],
    });
    return new CollectionOnSteroids(this.table);
  }
  noneOf(keys: readonly IndexableType[]): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'noneOf',
      parameters: [keys],
    });
    return new CollectionOnSteroids(this.table);
  }
  notEqual(key: IndexableType): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'key',
      parameters: [key],
    });
    return new CollectionOnSteroids(this.table);
  }
}
