import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Userpilot } from 'userpilot';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.enableUserPilot) {
// Initialize Userpilot
  Userpilot.initialize('NX-046dcbef');
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch();
