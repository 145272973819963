import { Injector } from '@angular/core';
import { LocalStorageKey } from '../shared/constants';
import { LocalStorageService } from '../core/services/local-storage.service';

const SUPPORTED_LANGUAGES = ['en'];
const DEFAULT_LANGUAGE = 'ar';

const injector = Injector.create({
  providers: [
    { provide: LocalStorageService }
  ]
});

export const removeLanguageFromUrlIfRequired = (url: string): string => {
  const urlLanguage = url.split('/')[1];
  const langFound = SUPPORTED_LANGUAGES.find((lang) => lang === urlLanguage);
  if (langFound) {
    return url.replace(`/${langFound}`, '');
  }
  return url;
};

export const isUrlNeededToBeUpdated = (
  url: string,
  currentLanguage: string,
): boolean => {
  if (currentLanguage === DEFAULT_LANGUAGE) {
    const newPath = removeLanguageFromUrlIfRequired(url);
    return newPath !== url;
  }
  return false;
};

export const getLocalizedBasePath = () => {
  const localStorageService = injector.get(LocalStorageService);
  let language;
  // handling the case where in previous usage, language was stored as a string and not a JSON string
  language = localStorageService.getItem<string>(LocalStorageKey.Language);
  if (isUrlNeededToBeUpdated(window.location.pathname, language)) {
    window.location.pathname = removeLanguageFromUrlIfRequired(
      window.location.pathname,
    );
  }

  // const urlLanguage = window.location.pathname.split('/')[1];
  const langFound = SUPPORTED_LANGUAGES.find((lang) => lang === language);
  if (langFound) {
    localStorageService.setItem<string>(
      LocalStorageKey.Language,
      langFound
    );
    language = langFound;
  } else {
    localStorageService.setItem<string>(
      LocalStorageKey.Language,
      DEFAULT_LANGUAGE,
    )
    language = DEFAULT_LANGUAGE;
  }
  return language === DEFAULT_LANGUAGE ? '/' : `/${language}/`;
};

export const getLocalizedUrl = (lang: string): string => {
  const urlLanguage = window.location.pathname.split('/')[1];
  const prevLang = SUPPORTED_LANGUAGES.find((language) => language === urlLanguage);
  let path = window.location.pathname;
  if (prevLang !== undefined) {
    if (lang !== DEFAULT_LANGUAGE) path = path.replace(prevLang, lang);
    else path = path.replace(`/${prevLang}`, '');
  } else {
    path = `${lang}${path}`;
  }
  return path;
};
