<label class="switch-container">
  <div class="switch">
    <input
      type="checkbox"
      [checked]="checked"
      (change)="onChange($event)"
      [formControlName]="controlName"
      *ngIf="controlName; else withoutControl"
    />
    <ng-template #withoutControl>
      <input
        type="checkbox"
        [checked]="checked"
        (change)="onChange($event)"
      />
    </ng-template>
    <span class="slider round">
    </span>
  </div>
  <ng-container *ngIf="label">
    <span class="switch-label">{{label}}</span>
  </ng-container>
</label>
