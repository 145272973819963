import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  endWith,
  firstValueFrom,
  from,
  ignoreElements,
  map,
  mergeMap,
  Observable,
  of,
  reduce,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import omit from 'lodash/omit';

import { CustomToastService } from './custom-toast.service';
import {
  Attachment,
  AttachmentSource,
  CommitAttachmentChangesParams,
  CommitAttachmentCreateParams,
  CommitAttachmentDeleteParams,
  RequestAttachmentURLParams,
} from './types/attachments';
import { BYPASS_NORMAL } from '../interceptors/http-error.interceptor';

export enum AttachmentContentTypes {
  pdf = 'application/pdf',
  doc = 'application/msword',
  xlsx = 'application/vnd.ms-excel',
  xls = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  jpeg = 'image/jpeg',
  jpg = 'image/jpeg',
  png = 'image/png',
  gif = 'image/gif',
  bmp = 'image/bmp',
  csv = 'text/csv',
  webp = 'image/webp',
}

@Injectable()
export class AttachmentsService {
  maxFilesAllowed = 10;

  contentTypes: AttachmentContentTypes[] = Object.values(AttachmentContentTypes);

  types: Set<AttachmentContentTypes> = new Set(this.contentTypes);

  totalAttachmentSizeAllowedInBytes = 20 * 1024 * 1024;

  numberOfFilesInProcessingQueue: number = 0;

  entityId: number;

  progressByUrlCache: { [url: string]: number };

  progressByUrl$: BehaviorSubject<{ [url: string]: number }> =
  new BehaviorSubject({});

  attachments: Attachment[] = [];

  currentAttachments$ = new BehaviorSubject<Attachment[]>([]);

  attachment$ = new EventEmitter<Attachment>();

  newAttachmentsCache: Set<string>;

  /* this is to allow changes to be committed as soon as they occur
   otherwise we were waiting for the user to submit the purchase order form
   to commit the changes to the db.
  */
  liveSync: boolean = false;

  deleteAttachment$ = new EventEmitter<string>();

  uploadComplete$ = new EventEmitter<void>();

  deletingByUrlCache: Set<String>;

  requestAttachmentURL: string;

  deleteAttachmentURL: string;

  commitAttachmentURL: string;

  source?: AttachmentSource;

  averageProgress$: Observable<number | null> = this.progressByUrl$.pipe(
    map((progressByUrl): number => {
      const allProgress: number[] = Object.values(progressByUrl);
      if (allProgress.length === 0) return null;
      return (
        allProgress.reduce((prev, curr): number => prev + curr, 0)
        / allProgress.length
      );
    }),
  );

  constructor(
    private translateService: TranslateService,
    private customToastService: CustomToastService,
    private readonly http: HttpClient,
  ) {}

  init({
    entityId,
    liveSync = false,
    contentTypes,
    maxFilesAllowed,
    totalAttachmentSizeAllowedInBytes,
    attachments,
    requestAttachmentURL = '/api/invoices/purchase-orders/request-attachment-url',
    deleteAttachmentURL = '/api/invoices/purchase-orders/attachment',
    commitAttachmentURL = '/api/invoices/purchase-orders/attachment/commit',
    source,
  }: {
    entityId?: number;
    liveSync?: boolean;
    contentTypes?: AttachmentContentTypes[];
    maxFilesAllowed?: number;
    totalAttachmentSizeAllowedInBytes?: number;
    attachments?: Attachment[];
    requestAttachmentURL?: string;
    deleteAttachmentURL?: string;
    commitAttachmentURL?: string;
    source?: AttachmentSource;
  }): void {
    this.maxFilesAllowed = maxFilesAllowed || 10;
    if (contentTypes && contentTypes.length) {
      this.contentTypes = contentTypes;
    } else {
      this.contentTypes = Object.values(AttachmentContentTypes);
    }
    this.types = new Set(this.contentTypes);
    this.totalAttachmentSizeAllowedInBytes = totalAttachmentSizeAllowedInBytes || 20 * 1024 * 1024;
    this.numberOfFilesInProcessingQueue = 0;
    this.entityId = entityId;
    this.progressByUrlCache = {};
    this.progressByUrl$.next(this.progressByUrlCache);
    this.setAttachments((attachments && attachments.length) ? attachments : []);
    this.newAttachmentsCache = new Set<string>();
    this.liveSync = liveSync;
    this.deletingByUrlCache = new Set<string>();
    this.requestAttachmentURL = requestAttachmentURL;
    this.deleteAttachmentURL = deleteAttachmentURL;
    this.commitAttachmentURL = commitAttachmentURL;
    this.source = source;

    if (this.liveSync && !this.entityId) {
      throw Error('Live sync requires entity id to be set');
    }
  }

  removeQueryParamsFromLink(link: string) {
    return link.split('?')?.shift();
  }

  removeTimestamp(filename: string) {
    const pieces = filename.split('_');
    const finalPiece = pieces[pieces.length - 1].split('.');
    const ext = finalPiece[finalPiece.length - 1];

    const preFilename = pieces.slice(0, -1).join('_');
    return `${preFilename}.${ext}`;
  }

  getFileNameFromLink(link): string {
    const decodedLink = decodeURIComponent(link?.split('/')?.pop());
    const cleanedFileName = this.removeQueryParamsFromLink(decodedLink);
    return this.removeTimestamp(cleanedFileName);
  }

  filterExistingFiles(files: File[], prevAttachments: Attachment[]): File[] {
    const uploadedFilesNames = new Set(
      prevAttachments.map(({ link }) => this.getFileNameFromLink(link)),
    );
    const newFiles = Object.values(files).filter(({ name }) => {
      if (!uploadedFilesNames.has(name)) {
        return true;
      }

      this.customToastService.error(
        this.translateService.instant('File Already Exists', { name }),
      );
      return false;
    });
    return newFiles;
  }

  areFilesExtensionValid(files: File[]): void {
    for (let index: number = 0; index < files.length; index++) {
      const { type } = files[index];
      if (!this.types.has(type as AttachmentContentTypes)) {
        throw new Error(
          this.translateService.instant('Type Not Supported', { type }),
        );
      }
    }
  }

  areFilesSizeValid(
    files: File[],
    totalExistingAttachmentSizeInBytes: number,
  ): boolean {
    let totalSize = 0;
    for (let index = 0; index < files.length; index++) {
      const { size } = files[index];

      totalSize += Number(size);
    }

    return (
      totalSize
      <= this.totalAttachmentSizeAllowedInBytes
        - totalExistingAttachmentSizeInBytes
    );
  }

  doValidationsOnFiles(
    files: File[],
    totalAttachmentSizeInBytes: number,
    totalUploadedAttachments: number,
  ): boolean {
    try {
      if (totalUploadedAttachments + files.length > this.maxFilesAllowed) {
        throw Error(
          this.translateService.instant('Attachment Cap Exceeded', {
            cap: this.maxFilesAllowed,
          }),
        );
      }
      this.areFilesExtensionValid(files);
      const areSizesValid = this.areFilesSizeValid(
        files,
        totalAttachmentSizeInBytes,
      );
      if (!areSizesValid) {
        throw Error(this.translateService.instant('Attachment Space Exceeded'));
      }
      return true;
    } catch (error) {
      this.customToastService.error(
        error.message ?? error,
      );
    }
    return false;
  }

  getExtension(file: File) {
    return file.name.split('.').pop();
  }

  getExtensionFromJEAttachment(
    file: Attachment,
  ): string {
    return file.originalName.split('.').pop();
  }

  getPresignedURLsFromS3(
    params: RequestAttachmentURLParams,
  ): Observable<string> {
    return this.http.get<string>(
      this.requestAttachmentURL,
      {
        params: params as any,
        context: new HttpContext().set(BYPASS_NORMAL, true),
      },
    );
  }

  getCurrProgress(event: HttpProgressEvent) {
    return (event.loaded / event.total) * 100;
  }

  /**
        uploadImageToS3 uploads image to a presigned s3 url.
        @returns {Observable<boolean>} isFirst event
  * */
  uploadToS3(url: string, body: File): Observable<boolean> {
    return this.http
      .put<HttpEvent<any>>(url, body, {
      reportProgress: true,
      observe: 'events',
      context: new HttpContext().set(BYPASS_NORMAL, true),
    })
      .pipe(
        map((event) => {
          const uploadLink = this.removeQueryParamsFromLink(url);
          let isSent = false;
          if (event.type === HttpEventType.Sent) {
            this.progressByUrlCache = {
              ...this.progressByUrlCache,
              [uploadLink]: 0,
            };
            this.progressByUrl$.next(this.progressByUrlCache);
            isSent = true;
          }
          if (event.type === HttpEventType.UploadProgress) {
            this.progressByUrlCache = {
              ...this.progressByUrlCache,
              [uploadLink]: this.getCurrProgress(event),
            };
            this.progressByUrl$.next(this.progressByUrlCache);
          }
          if (event.type === HttpEventType.Response) {
            this.progressByUrlCache = omit(this.progressByUrlCache, uploadLink);
            this.progressByUrl$.next(this.progressByUrlCache);
          }
          return isSent;
        }),
      );
  }

  handleUploadResult(isSent, attachment, cleanedUrl) {
    if (isSent) {
      this.addAttachment(attachment, cleanedUrl);
    }
  }

  setAttachments(attachments: Attachment[]) {
    this.attachments = attachments;
    this.newAttachmentsCache = new Set(attachments.map((item) => item.link));
    this.currentAttachments$.next(attachments);
  }

  addAttachment(attachment: Attachment, cleanedUrl?: string) {
    this.newAttachmentsCache.add(cleanedUrl || attachment.link);
    this.attachment$.emit(attachment);
    this.attachments.push(attachment);
    this.currentAttachments$.next(this.attachments);
  }

  deleteAttachment(link: string, presignedURL: string) {
    this.newAttachmentsCache.delete(link);
    this.deleteAttachment$.emit(presignedURL);
    this.deletingByUrlCache.delete(link);
    this.attachments = this.attachments.filter((item) => item.link !== link);
    this.currentAttachments$.next(this.attachments);
  }

  commitAttachmentChangesToDB(
    body: CommitAttachmentChangesParams,
  ): Observable<string> {
    return this.http.put<string>(
      this.commitAttachmentURL,
      body,
      { context: new HttpContext().set(BYPASS_NORMAL, true) },
    );
  }

  handleUploadError(presignedURL: string, link: string, fileName: string) {
    this.deleteAttachment(link, presignedURL);

    this.customToastService.error(
      this.translateService.instant('Attachment Upload Failed', {
        name: fileName,
      }),
    );
  }

  getFileNameFromCleanURL(cleanedUrl: string): string {
    const urlChunks = cleanedUrl.split('/');
    const encodedFileName = urlChunks[urlChunks.length - 1];
    return decodeURIComponent(encodedFileName);
  }

  uploadToS3Pipe(file: File, linkToUpload: string): Observable<boolean> {
    const cleanedUrl: string = this.removeQueryParamsFromLink(linkToUpload);
    const attachment: Attachment = {
      link: cleanedUrl,
      size: file.size,
      savedName: this.getFileNameFromCleanURL(cleanedUrl),
      originalName: file.name,
    };
    const payload: CommitAttachmentCreateParams = {
      mode: 'CREATE',
      url: attachment.link,
      file_size: attachment.size,
      purchase_order_id: this.entityId,
    };
    return this.uploadToS3(linkToUpload, file).pipe(
      tap((isSent): void => {
        this.handleUploadResult(isSent, attachment, cleanedUrl);
      }),
      ignoreElements(),
      endWith(true),
      switchMap(() => (this.liveSync ? this.commitAttachmentChangesToDB(payload) : of(true))),
      map(() => true),
      catchError((): Observable<null> => {
        this.handleUploadError(linkToUpload, cleanedUrl, file.name);
        return of(null);
      }),
    );
  }

  async getPresignedURL(attachment: Attachment): Promise<string> {
    const requestAttachmentURLParams: RequestAttachmentURLParams = {
      name: attachment.savedName,
      type: AttachmentContentTypes[
        this.getExtensionFromJEAttachment(attachment)
      ],
      source: this.source,
      isExistingAttachment: true,
    };

    return await firstValueFrom(
      this.getPresignedURLsFromS3(requestAttachmentURLParams),
    );
  }

  uploadSingleFile(file: File) {
    const body: RequestAttachmentURLParams = {
      name: file.name,
      type: AttachmentContentTypes[this.getExtension(file)],
      source: this.source,
    };
    this.numberOfFilesInProcessingQueue += 1;
    return this.getPresignedURLsFromS3(body).pipe(
      switchMap((linkToUpload: string) => {
        this.numberOfFilesInProcessingQueue -= 1;

        return this.uploadToS3Pipe(file, linkToUpload);
      }),
      catchError(() => {
        this.customToastService.error(
          this.translateService.instant('Attachment Upload Failed', {
            name: file.name,
          }),
        );
        return of(null);
      }),
    );
  }

  uploadFiles(files: File[]) {
    if (files.length === 0) return;
    from(files)
      .pipe(
        mergeMap((file) => this.uploadSingleFile(file)),
        reduce((isSuccess, value) => value != null && isSuccess, true),
      )
      .subscribe((isSuccess) => {
        if (isSuccess) {
          this.uploadComplete$.emit();
          this.customToastService.success(
            this.translateService.instant('Attachment Upload Success'),
          );
        }
      });
  }

  async deleteAttachmentFromPresignedURL(attachment: Attachment, presignedURL?: string): Promise<void> {
    if (!presignedURL) {
      presignedURL = await this.getPresignedURL(attachment);
    }
    this.deleteAttachment(attachment.link, presignedURL);
  }

  async deleteFile(attachment: Attachment) {
    if (this.liveSync) {
      const presignedURL = await this.getPresignedURL(attachment);
    
      const payload: CommitAttachmentDeleteParams = {
        mode: 'DELETE',
        url: presignedURL,
        purchase_order_id: this.entityId,
      };
    
      this.commitAttachmentChangesToDB(payload).subscribe(() => {
        this.deleteAttachmentFromPresignedURL(attachment, presignedURL);
      });
    
    } else if (this.newAttachmentsCache.has(attachment.link)) {

      const presignedURL = await this.getPresignedURL(attachment);

      const httpOptions: any = { body: { url: presignedURL, source: this.source } };

      this.deletingByUrlCache = this.deletingByUrlCache.add(attachment.link);

      this.http
        .delete<any>(this.deleteAttachmentURL, httpOptions)
        .subscribe(() => {
          this.deleteAttachmentFromPresignedURL(attachment, presignedURL);
        });

    } else {
      this.deleteAttachmentFromPresignedURL(attachment);
    }
  }

  getFileFromUrl(url) {
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  async downloadFile(attachment: Attachment): Promise<Observable<'Completed'>> {
    const presignedUrl = await this.getPresignedURL(attachment);

    return this.getFileFromUrl(presignedUrl).pipe(
      map((data: Blob) => {
        const blob = new Blob([data]);
        const linkTag = document.createElement('a');
        linkTag.href = window.URL.createObjectURL(blob);
        linkTag.download = attachment.originalName;
        document.body.appendChild(linkTag);
        linkTag.click();
        document.body.removeChild(linkTag);
        return 'Completed'
      })
    );
  }
}
