import { RewaaApi } from '../internals/rewaa-api';

export class CustomFieldService extends RewaaApi {
  constructor(apiUrl: string, apiKey: string) {
    super(apiUrl, apiKey);
  }

  async getAllCustomFieldsInternal(
    module: string,
    tenantId: string,
  ): Promise<unknown> {
    return this.get('internal/custom-field/list', {
      tenantId,
      sortBy: 'created_at',
      sortDirection: 'ASC',
      limit: 100,
      offset: 0,
      filters: JSON.stringify([
        {
          columnName: 'module',
          value: [module],
          matchMode: 'contains',
        },
      ]),
    });
  }
}
