import { Injectable } from '@angular/core';
import { Invoice } from './stores/InvoiceStore';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';

@Injectable()
export class InvoiceStoreService {
  async saveInvoice(invoiceNumber: string, invoice: Invoice): Promise<Invoice> {
    try {
      const db: DatabaseService = await DatabaseService.getDB();
      await db.getRepository(TableName.Invoices).put(invoice, invoiceNumber);
      console.log('save invoices');
      return await db.getRepository(TableName.Invoices).get(invoiceNumber);
    } catch (err) {
      console.log(`failed to save the invoice due to error: ${err.message}`);
      console.log(err);
    }
  }

  async getInvoice(invoiceNumber: string) {
    try {
      const db: DatabaseService = await DatabaseService.getDB();
      return await db.getRepository(TableName.Invoices).get(invoiceNumber);
    } catch (err) {
      console.log(err);
    }
  }

  async getInvoices() {
    try {
      const db: DatabaseService = await DatabaseService.getDB();
      return await db.getRepository(TableName.Invoices).toArray();
    } catch (err) {
      console.log(err);
    }
  }

  async clearInvoices() {
    try {
      const db: DatabaseService = await DatabaseService.getDB();
      return await db.getRepository(TableName.Invoices).clear();
    } catch (err) {
      console.log(err);
    }
  }

  async deleteInvoices(invoiceNumbers: Array<string>) {
    try {
      const db: DatabaseService = await DatabaseService.getDB();
      return await db.getRepository(TableName.Invoices).bulkDelete(invoiceNumbers);
    } catch (err) {
      console.log(err);
    }
  }

  async getInvoiceCount() {
    try {
      const db: DatabaseService = await DatabaseService.getDB();
      return await db.getRepository(TableName.Invoices).count();
    } catch (err) {
      console.log(err);
    }
  }

  async getLastInvoice() {
    try {
      const db: DatabaseService = await DatabaseService.getDB();
      return await db.getRepository(TableName.Invoices).toCollection().last();
    } catch (err) {
      console.log(err);
    }
  }
}
