export const RegexEnum = {
  floatNumbers2DP: /^(?!0(\.00?)?$)\d+(\.\d{1,2})?$/,
  numberOnly: '^[0-9]+$',
  alphaNumberOnly: '^[a-zA-Z0-9_ ]*$',
  alphaNumberAndArabicOnly: '^[a-zA-Z0-9\u0600-\u06FF_ ]*$',
  vatNumber: '^3[0-9]{13}3$',
  phoneNumberNineDigit: '^5[0-9]{8}',
  phoneNumberTenDigit: '^0[0-9]{9}',
  phoneNumber:
    '^(5[0-9]{8}$)|^(\\+9665[0-9]{8}$)|^(009665[0-9]{8}$)|^(05[0-9]{8}$)|^(9665[0-9]{8}$)',
  phoneNumberForCognito: '^(0[0-9]{9}|5[0-9]{8})$',
  buildingNumber: '^[0-9]{4}$',
  acceptfourNumber: '^[0-9]{4}$',
  acceptFiveNumber: '^[0-9]{5}$',
  acceptSixChars: '.{6,}',
  email:
    '^[a-zA-Z0-9_\\-]+(\\.[a-zA-Z0-9_\\-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]+)$',
  /** @desc this regex is only for dev envs, to support email aliasing (allows + signs) */
  laxEmail:
    /^[a-zA-Z0-9_\-+]+(\.[a-zA-Z0-9_\-+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]+)$/,
  password: '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$',
  url: '[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
  uniPhoneNumber: '^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$',
};
