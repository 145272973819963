import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Policy } from '../../shared/model/Policies';

const API_URL = '/api';

@Injectable()
export class PermissionService {
  constructor(private http: HttpClient) {}

  public getUiPolicies(): Observable<Array<Policy>> {
    return this.http.get<Array<Policy>>(
      `${API_URL}/settings/users/ui-policies`,
    );
  }
}
