import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey } from '../../../shared/constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';
@Component({
  selector: 'rw-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidePanelComponent implements OnInit {
  changeLang: string;

  lang: string;

  translationKey = 'sidePanel.';

  constructor(
    public translate: TranslateService,
    public localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }
  }

  ngOnInit(): void {}
}
