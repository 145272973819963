export class StockLocation {
  public id?: number;

  public name: string;

  public description?: string;

  public isActive?: boolean;

  public isDefault?: boolean;

  public countryKey?: string;

  public country?: string;

  public city?: string;

  public buildingNo?: string;

  public additionalNumber?: number;

  public zipCode?: string;

  public streetName?: string;

  public districtName?: string;

  public code?: string;

  public commercialRegisterNumber?: number;

  constructor(location) {
    Object.assign(this, location);
  }
}
