<div [ngClass]="langCss">
    <div class="alert-box" class="uninstall-details">
        <div class="modal-header flex justify-content-center">
            {{'appUninstall.uninstallLabel' | translate }} {{app.name | translate}}
        </div>
        <div class="modal-body pt-5 md:px-4">
            <h6 class="pt-2">{{'appUninstall.sureMessage' | translate }}</h6>
            <p class="pt-2">{{'appUninstall.reinstallWarningMessage' | translate }}</p>
                <p class="pt-2">{{'appUninstall.syncIssueHelpMessage'  | translate }}</p>
            <h6 class="pt-2">{{"appUninstall.mainReasonForUninstallLabel" | translate  }} {{app.name | translate}}</h6>

            <div class="py-4">
                <div class="py-2">
                    <input pendo-tracker="uai" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked>
                    <label for="gridRadios1">
                        {{'appUninstall.uninstallReasons.doesNotFulfillMyNeeds' | translate }}
                    </label>
                </div>
                <div class="py-2">
                    <input pendo-tracker="uai-1" type="radio" name="gridRadios" id="gridRadios2" value="option1" checked>
                    <label for="gridRadios2">
                        {{'appUninstall.uninstallReasons.appIsNotPerformingWell' | translate }}
                    </label>
                </div>
                <div class="py-2">
                    <input pendo-tracker="uai-2" type="radio" name="gridRadios" id="gridRadios3" value="option1" checked>
                    <label for="gridRadios3">
                        {{'appUninstall.uninstallReasons.notNeededAnymore' | translate }}
                    </label>
                </div>
                <div class="py-2">
                    <input pendo-tracker="uai-3" type="radio" name="gridRadios" id="gridRadios4" value="option1" checked>
                    <label for="gridRadios4">
                        {{'appUninstall.uninstallReasons.setupCausedComplications' | translate }}
                    </label>
                </div>
                <div class="py-2">
                    <input pendo-tracker="uai-4" type="radio" name="gridRadios" id="gridRadios5" value="option1" checked>
                    <label for="gridRadios5">
                        {{'appUninstall.uninstallReasons.other' | translate }}
                    </label>
                </div>
                <div class="py-2">
                    <textarea pInputTextarea id="exampleFormControlTextarea1" rows="4" class="w-full"
                    placeholder="{{'appUninstall.appExperienceLabel' | translate}}"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer flex justify-content-center pb-5">
            <button pButton pendo-tracker="uab" class="p-button-text p-button-secondary"
                (click)=content.cancel_btn.handler() label="{{content.cancel_btn.text | translate }}"></button>
            <button pButton pendo-tracker="uab-1" class="p-button-text p-button-danger"
                (click)=content.uninstall_btn.handler() label="{{content.uninstall_btn.text | translate }}"></button>
        </div>
    </div>
</div>
