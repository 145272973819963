import { ActionReducer } from '@ngrx/store';
import { ActionTypes } from './logout.action';

export function logoutMetaReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return (state, action) => {
    if (action.type === ActionTypes.Logout) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }
    return reducer(state, action);
  };
}
