import {
  Table,
  IndexableType,
  Database,
  TableSchema,
  TableHooks,
  DBCoreTable,
  PromiseExtended,
  ThenShortcut,
  WhereClause,
  IndexableTypeArrayReadonly,
  Collection,
} from 'dexie';
import {
  Operation,
  WhereParams,
  WhereResponse,
} from '../types';
import { DatabaseService } from '../database.service';
import { WhereClauseOnSteroids } from './where-clause-on-steroids';
import { CollectionOnSteroids } from './collection-on-steroids';

export class TableOnSteroids implements Table<any, IndexableType> {
  public operations: Operation[] = [];
  constructor(
    private databaseService: DatabaseService,
    private table: Table,
    private inTransaction: boolean,
  ) {}

  async operateOnSteroids(): Promise<any> {
    if (this.inTransaction) {
      return this.processOperationsOnMainThread();
    }
    const result = await this.databaseService.operateOnSteroids(
      this.operations,
      this.table.name,
    );
    this.operations = [];
    return result;
  }

  processOperationsOnMainThread() {
    console.info(`Executing on main thread: ${this.table.name}`);
    let response = this.databaseService.table(this.table.name);
    while (this.operations.length) {
      const operation = this.operations.shift();
      response = response[operation.propertyName](...operation.parameters);
    }
    return response;
  }

  get db(): Database {
    return this.table.db;
  }

  get name(): string {
    return this.table.name;
  }

  get schema(): TableSchema {
    return this.table.schema;
  }

  get hook(): TableHooks {
    return this.table.hook;
  }

  get core(): DBCoreTable {
    return this.table.core;
  }

  mapToClass(constructor: Function): Function {
    return this.table.mapToClass(constructor);
  }

  get(key: IndexableType): PromiseExtended<any>;
  get<R>(
    key: IndexableType,
    thenShortcut: ThenShortcut<any, R>,
  ): PromiseExtended<R>;
  get(equalityCriterias: { [key: string]: any }): PromiseExtended<any>;
  get<R>(
    equalityCriterias: { [key: string]: any },
    thenShortcut: ThenShortcut<any, R>,
  ): PromiseExtended<R>;
  async get<R>(equalityCriterias: unknown, thenShortcut?: unknown): Promise<R> {
    this.operations.push({
      propertyName: 'get',
      parameters: [equalityCriterias, thenShortcut],
    });
    return await this.operateOnSteroids();
  }

  where(index: string | string[]): WhereClause;
  where(equalityCriterias: { [key: string]: any }): Collection;
  where<T extends WhereParams>(indexOrCriteria: T): WhereResponse<T> {
    this.operations.push({
      propertyName: 'where',
      parameters: [indexOrCriteria],
    });
    if (typeof indexOrCriteria === 'string' || Array.isArray(indexOrCriteria)) {
      return new WhereClauseOnSteroids(this) as any;
    }
    return new CollectionOnSteroids(this) as any;
  }

  filter(filterFunction: (obj: any) => boolean): Collection {
    let response;
    if (this.operations.length) {
      response = this.processOperationsOnMainThread();
    }
    return response.filter(filterFunction);
  }

  count(): PromiseExtended<number>;
  count<R>(thenShortcut: ThenShortcut<number, R>): PromiseExtended<R>;
  async count<R>(thenShortcut?: any): Promise<R> {
    this.operations.push({
      propertyName: 'count',
      parameters: [thenShortcut],
    });
    return await this.operateOnSteroids();
  }

  offset(offset: number): Collection {
    this.operations.push({
      propertyName: 'offset',
      parameters: [offset],
    });
    return new CollectionOnSteroids(this);
  }

  limit(numRows: number): Collection {
    this.operations.push({
      propertyName: 'limit',
      parameters: [numRows],
    });
    return new CollectionOnSteroids(this);
  }

  each(
    callback: (
      obj: any,
      cursor: { key: IndexableType; primaryKey: IndexableType },
    ) => any,
  ): PromiseExtended {
    let response;
    if (this.operations.length) {
      response = this.processOperationsOnMainThread();
    }
    return response.each(callback);
  }

  toArray(): PromiseExtended<Array<any>>;
  toArray<R>(thenShortcut: ThenShortcut<any[], R>): PromiseExtended<R>;
  async toArray(thenShortcut?: any): Promise<any> {
    this.operations.push({
      propertyName: 'toArray',
      parameters: [thenShortcut],
    });
    return await this.operateOnSteroids();
  }

  toCollection(): Collection {
    this.operations.push({
      propertyName: 'toCollection',
      parameters: [],
    });
    return new CollectionOnSteroids(this);
  }

  orderBy(index: string | string[]): Collection {
    this.operations.push({
      propertyName: 'orderBy',
      parameters: [index],
    });
    return new CollectionOnSteroids(this);
  }

  reverse(): Collection {
    this.operations.push({
      propertyName: 'reverse',
      parameters: [],
    });
    return new CollectionOnSteroids(this);
  }

  add<T, TKey>(item: T, key?: TKey): PromiseExtended<TKey>;
  async add(obj, key?: IndexableType): Promise<any> {
    this.operations.push({
      propertyName: 'add',
      parameters: [obj, key],
    });
    return await this.operateOnSteroids();
  }

  update(
    keyOrObject,
    modifications:
      | { [keyPath: string]: any }
      | ((
          obj: any,
          ctx: { value: any; primKey: IndexableType },
        ) => void | boolean),
  ): PromiseExtended<number> {
    let response;
    if (this.operations.length) {
      response = this.processOperationsOnMainThread();
    }
    return response.update(keyOrObject, modifications);
  }

  put<T, TKey>(item: T, key?: TKey): PromiseExtended<TKey>;
  async put(obj, key?: IndexableType): Promise<any> {
    this.operations.push({
      propertyName: 'put',
      parameters: [obj, key],
    });
    return await this.operateOnSteroids();
  }

  delete<TKey>(key: TKey): PromiseExtended<void>;
  async delete(key: IndexableType): Promise<any> {
    this.operations.push({
      propertyName: 'delete',
      parameters: [key],
    });
    return await this.operateOnSteroids();
  }

  clear(): PromiseExtended<void>;
  async clear(): Promise<any> {
    this.operations.push({
      propertyName: 'clear',
      parameters: [],
    });
    return await this.operateOnSteroids();
  }

  bulkGet<TKey>(keys: TKey[]): PromiseExtended<any[]>;
  async bulkGet<T>(keys: IndexableType[]): Promise<T> {
    this.operations.push({
      propertyName: 'bulkGet',
      parameters: [keys],
    });
    return await this.operateOnSteroids();
  }

  bulkAdd<B extends boolean, T, TKey>(
    items: readonly T[],
    keys: IndexableTypeArrayReadonly,
    options: {
      allKeys: B;
    },
  ): PromiseExtended<B extends true ? TKey[] : TKey>;
  bulkAdd<B extends boolean, T, TKey>(
    items: readonly T[],
    options: {
      allKeys: B;
    },
  ): PromiseExtended<B extends true ? TKey[] : TKey>;
  bulkAdd<B extends boolean, T, TKey>(
    items: readonly T[],
    keys?: IndexableTypeArrayReadonly,
    options?: {
      allKeys: boolean;
    },
  ): PromiseExtended<TKey>;
  async bulkAdd(
    objects: readonly any[],
    keysOrOptions?: ReadonlyArray<IndexableType> | { allKeys?: boolean },
    options?: { allKeys?: boolean },
  ): Promise<any> {
    this.operations.push({
      propertyName: 'bulkAdd',
      parameters: [objects, keysOrOptions, options],
    });
    return await this.operateOnSteroids();
  }

  bulkPut<B extends boolean, T, TKey>(
    items: readonly T[],
    keys: IndexableTypeArrayReadonly,
    options: {
      allKeys: B;
    },
  ): PromiseExtended<B extends true ? TKey[] : TKey>;
  bulkPut<B extends boolean, T, TKey>(
    items: readonly T[],
    options: {
      allKeys: B;
    },
  ): PromiseExtended<B extends true ? TKey[] : TKey>;
  bulkPut<B extends boolean, T, TKey>(
    items: readonly T[],
    keys?: IndexableTypeArrayReadonly,
    options?: {
      allKeys: boolean;
    },
  ): PromiseExtended<TKey>;
  async bulkPut(
    objects: readonly any[],
    keysOrOptions?: ReadonlyArray<IndexableType> | { allKeys?: boolean },
    options?: { allKeys?: boolean },
  ): Promise<any> {
    this.operations.push({
      propertyName: 'bulkPut',
      parameters: [objects, keysOrOptions, options],
    });
    return await this.operateOnSteroids();
  }

  bulkDelete<TKey>(keys: TKey[]): PromiseExtended<void>;
  async bulkDelete(keys: ReadonlyArray<IndexableType>): Promise<any> {
    this.operations.push({
      propertyName: 'bulkDelete',
      parameters: [keys],
    });
    return await this.operateOnSteroids();
  }
}
