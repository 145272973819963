/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Intercom } from 'ng-intercom';
import { MenuItem } from 'primeng/api';
import { Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthUser } from '../auth/auth.models';
import { UserAuthenticationService } from '../auth/services/user-authentication.service';
import { UserService } from '../auth/services/user.service';
import { LocalStorageKey } from '../shared/constants';
import { Merchant } from '../shared/model/Merchant';
import { CustomToastService } from '../shared/services/custom-toast.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { getLocalizedUrl } from '../utils/localizedUrl';

@Component({
  selector: 'rw-unauthorised-access',
  templateUrl: './unauthorised-access.component.html',
  styleUrls: ['./unauthorised-access.component.scss'],
})
export class UnauthorisedAccessComponent implements OnInit {
  subscriptionEnded = false;

  subscriptionDeleted = false;

  users: Merchant | any;

  lang: string;

  langCss: string;

  changeLang: string;

  languageTitle: string;

  userMenuItems: MenuItem[];

  private switchLanguageSub: Subscription | null;

  subscriptionRefs: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    public intercom: Intercom,
    private customToast: CustomToastService,
    private userService: UserService,
    private userAuthenticationService: UserAuthenticationService,
    private localStorageService: LocalStorageService,
  ) {
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.switchLanguageSub = null;
    this.translate.setDefaultLang(this.lang);
    this.langCss = this.lang === 'en' ? 'ltr' : 'rtl';
    if (this.lang === 'en') {
      this.changeLang = 'ar';
      this.languageTitle = 'Arabic';
    } else {
      this.langCss = 'rtl';
      this.changeLang = 'en';
      this.languageTitle = 'English';
    }
  }

  ngOnInit(): void {
    const user = this.localStorageService.getItem<AuthUser>(
      LocalStorageKey.User,
    );
    this.userService
      .getUser()
      .pipe(
        tap((currUser) => {
          this.users = currUser;
        }),
      )
      .subscribe();
    this.subscriptionEnded =
      user &&
      user.RewaaAccountSubscription &&
      !(new Date(user.RewaaAccountSubscription.endDate) > new Date());
    this.subscriptionDeleted = user && !!user.subscriptionDeletedAt;

    const subscriptionDeletedAt = user.subscriptionDeletedAt
      ? new Date(user.subscriptionDeletedAt)
      : '';
    if (subscriptionDeletedAt) {
      const todayDate = new Date();
      const diffTime = Math.abs(
        todayDate.getTime() - subscriptionDeletedAt.getTime(),
      );
      const deletedSince = Math.ceil(diffTime / (1000 * 60 * 60));
      if (deletedSince > 24) {
        this.subscriptionDeleted = true;
      }
    }
    if (this.subscriptionDeleted) {
      this.intercom.show();
    }
    this.userMenuItems = [
      {
        label: this.translate.instant(this.languageTitle),
        icon: 'fa-regular fa-globe',
        styleClass: 'arabic-font',
        command: () => {
          this.switchLanguage(this.changeLang);
        },
      },
      {
        label: this.translate.instant('Logout'),
        icon: 'fa-regular fa-arrow-right-from-bracket',
        command: () => {
          this.logout();
        },
      },
    ];
  }

  goHome(): void {
    this.router.navigateByUrl('/').then();
  }

  logout(): void {
    this.userAuthenticationService.logout();
    this.router
      .navigateByUrl(
        `${
          this.localStorageService.getItem<string>(LocalStorageKey.Language) ||
          'en'
        }/login`,
      )
      .then(() => window.location.reload());
  }

  switchLanguage(lang: string): void {
    if (this.switchLanguageSub) this.switchLanguageSub.unsubscribe();
    this.translate.get(['langChangeMessage']).subscribe((translations) => {
      this.customToast.success(translations.langChangeMessage);
    });
    this.switchLanguageSub = timer(2000).subscribe(() => {
      this.localStorageService.setItem<string>(LocalStorageKey.Language, lang);
      window.location.pathname = getLocalizedUrl(lang);
    });
  }
}
