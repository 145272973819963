<div>
    <div class="transfer-stock-print" [ngClass]="langCss">
        <div class="flex justify-content-between align-items-center px-4 my-4">
            <div class="title text-dark">{{ 'Print Invoice' | translate }}</div>
            <span class="pi pi-times text-dark cur-poi" (click)="closeModal()"></span>
        </div>
        <div class="content px-4">
            <div class="info mb-4 text-dark">{{ 'Please select the columns that you’d like to include in the invoice' | translate}}:</div>
            <div [formGroup]="selectionForm" class="flex flex-column cust-gap justify-content-center">
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('productCost') && viewCostPermission" formControlName="productCost" [label]="'Product Cost' | translate "></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('productPrice')" formControlName="productPrice" [label]="'Product Price' | translate"></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('sourceStock') && !isRequested" formControlName="sourceStock" [label]="'Source Stock' | translate"></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('destinationStock') && !isRequested" formControlName="destinationStock" [label]="'Destination Stock' | translate"></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('totalCost') && viewCostPermission" formControlName="totalCost" [label]="'Total Cost' | translate"></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('rejectedStock')" formControlName="rejectedStock" [label]="'Rejected Stock' | translate"></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('totalPrice')" formControlName="totalPrice" [label]="'Total Price' | translate"></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('totalRejectedCost') && viewCostPermission" formControlName="totalRejectedCost" [label]="'Rejected Cost' | translate"></p-checkbox>
                <p-checkbox binary="true" *ngIf="extraColsToStatus[status]?.includes('totalRejectedPrice')" formControlName="totalRejectedPrice" [label]="'Rejected Price' | translate"></p-checkbox>
            </div>
            <div class="flex justify-content-end align-items-center my-3 gap-1">
                <button pendo-tracker="tspb-9" 
                    pButton
                    pRipple
                    icon="pi pi-print"
                    type="button"
                    label="{{'Print' | translate }}"
                    [disabled]="disablePrint"
                    class="px-3 py-2 p-button-primary p-button-text"
                    (click)="printForm()">
                </button>
                <button pendo-tracker="tspb-10" 
                    pButton
                    pRipple
                    type="button"
                    label="{{'Back' | translate }}"
                    class="px-3 p-button-secondary p-button-text"
                    (click)="closeModal()">
                </button>
            </div>
        </div>
    </div>

    <div>
        <div id="print-doc" style="display: none;">
            <div class="title flex flex-col gap-1 fw-bold mt-3">
                <div><img src="{{ logoUrl || '/assets/images/no-image.png' }}" alt="company-logo"></div>
                <div>{{ companyName }}</div>
                <div>{{ status | translate }}</div>
            </div>
            <div class="head flex gap-1 mx-3">
                <div class="flex gap-1">
                    <div class="headers light-col flex flex-col gap-1 text-end">
                        <div>{{ 'Invoice Number' | translate }}</div>
                        <div>{{ 'Status' | translate }}</div>
                        <div>{{ 'User Name' | translate }}</div>
                    </div>
                    <p-divider layout="vertical" class="cust-bor"></p-divider>
                    <div class="headers fw-500 flex flex-col gap-1">
                        <div>{{ stockDetails.invoiceNumber }}</div>
                        <div>{{ stockDetails.status | translate }}</div>
                        <div>{{ stockDetails.userName }}</div>
                    </div>
                </div>
                <div class="flex gap-1">
                    <div class="headers light-col gap-1 flex flex-col text-end">
                        <div>{{ 'Source Location' | translate }}</div>
                        <div>{{ 'Destination' | translate }}</div>
                    </div>
                    <p-divider layout="vertical" class="cust-bor"></p-divider>
                    <div class="headers fw-500 gap-1 flex flex-col">
                        <div>{{ stockDetails.stockLocationName || '--' }}</div>
                        <div>{{ stockDetails.destinationLocationName || '--' }}</div>
                    </div>
                </div>
                <div class="flex gap-1">
                    <div class="headers light-col gap-1 flex flex-col text-end">
                        <div>{{ 'Notes' | translate }}</div>
                        <div>{{ 'Requested Date' | translate }}</div>
                    </div>
                    <p-divider layout="vertical" class="cust-bor"></p-divider>
                    <div class="headers fw-500 gap-1 flex flex-col">
                        <div>{{ stockDetails.notes || '--'}}</div>
                        <div>{{ stockDetails.createdAt | translate }}</div>
                    </div>
                </div>
            </div>
    
            <div class="mx-3">
                <div class="container flex gap-2 py-3 px-1 bg-grey cust-bor">
                    <div *ngIf="colsForTemplate.includes('requestedStock') && !isRequested"><span>{{ 'Total Requested' | translate }}:</span><span class="fw-500 px-1">{{ getTotalByProperty(variants, 'requestedStock') }}</span></div>
                    <div *ngIf="colsForTemplate.includes('totalSent')"><span>{{ 'Total Sent' | translate }}:</span><span class="fw-500 px-1">{{ getTotalByProperty(variants, 'sentStock') }}</span></div>
                    <div *ngIf="colsForTemplate.includes('totalReceived')"><span>{{ 'Total Received' | translate }}:</span><span class="fw-500 px-1">{{ getTotalByProperty(variants, 'receivedStock') }}</span></div>
                    <div *ngIf="colsForTemplate.includes('totalCost') && viewCostPermission"><span>{{ 'Total Cost' | translate }}:</span><span class="fw-500 px-1">{{ getTotalCost(variants) }}</span></div>
                    <div *ngIf="colsForTemplate.includes('totalPrice')"><span>{{ 'Total Price' | translate }}:</span><span class="fw-500 px-1">{{ getTotalPrice(variants) }}</span></div>
                    <div *ngIf="colsForTemplate.includes('totalrejected')"><span>{{ 'Total Rejected' | translate }}:</span><span class="fw-500 px-1">{{ getTotalByProperty(variants, 'rejectedStock') }}</span></div>
                    <div *ngIf="colsForTemplate.includes('totalRejectedCost') && viewCostPermission"><span>{{ 'Rejected Cost' | translate }}:</span><span class="fw-500 px-1">{{ getTotalRejectedCost(variants) }}</span></div>
                <div *ngIf="colsForTemplate.includes('totalRejectedPrice')"><span>{{ 'Rejected Price' | translate }}:</span><span class="fw-500 px-1">{{ getTotalRejectedPrice(variants) }}</span></div>
                </div>
                <div class="table cust-bor">
                    <p-table [value]="variants">
                        <ng-template pTemplate="header">
                            <tr class="bg-grey cust-bor">
                                <th class="fw-bold px-1">{{ 'Product Name / SKU' | translate }}</th>
                                <th *ngIf="colsForTemplate.includes('productCost') && viewCostPermission" class="fw-500">{{ 'Product Cost' | translate }}</th>
                                <th *ngIf="colsForTemplate.includes('productPrice')" class="fw-500">{{ 'Product Price' | translate }}</th>
                                <th *ngIf="colsForTemplate.includes('requestedStock') && !isRequested" class="fw-500">{{ 'Requested Stock' | translate }}</th>
                                <th *ngIf="colsForTemplate.includes('sentStock')" class="fw-500">{{ 'Sent Stock' | translate }}</th>                        
                                <th *ngIf="colsForTemplate.includes('receivedStock')" class="fw-500">{{ 'Received Stock' | translate }}</th>                      
                                <th *ngIf="colsForTemplate.includes('sourceStock') && !isRequested" class="fw-500">{{ 'Source Stock' | translate }}</th>                      
                                <th *ngIf="colsForTemplate.includes('destinationStock') && !isRequested" class="fw-500">{{ 'Destination Stock' | translate }}</th>                      
                                <th *ngIf="colsForTemplate.includes('rejectedStock')" class="fw-500">{{ 'Rejected Stock' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product>
                            <tr class="cust-bor">
                                <td width="40%" class="px-1">
                                    <div class="dark-col">{{product.name}}</div>
                                    <div class="light-col">{{product.sku}}</div>
                                </td>
                                <td *ngIf="colsForTemplate.includes('productCost') && viewCostPermission">{{ product.cost.toFixed(2) }}</td>
                                <td *ngIf="colsForTemplate.includes('productPrice')">{{ getPrice(product.productVariantId) }}</td>
                                <td *ngIf="colsForTemplate.includes('requestedStock') && !isRequested">{{ product.requestedStock || 0 }}</td>
                                <td *ngIf="colsForTemplate.includes('sentStock')">{{ product.sentStock || 0}}</td>
                                <td *ngIf="colsForTemplate.includes('receivedStock')">{{ product.receivedStock || 0}}</td>
                                <td *ngIf="colsForTemplate.includes('sourceStock') && !isRequested">{{ product.sourceStock }}</td>
                                <td *ngIf="colsForTemplate.includes('destinationStock') && !isRequested">{{ product.destinationStock }}</td>
                                <td *ngIf="colsForTemplate.includes('rejectedStock')">{{ product.rejectedStock || 0}}</td>
                            </tr>
                            <div *ngFor="let variant of product.childVariants; let i = index">
                                <tr *ngIf="i === 0">
                                    <td colspan="7" class="px-2 bg-grey">{{ 'Contained Products' | translate }}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="px-2">
                                        <div class="dark-col">{{variant.name}}</div>
                                        <div class="light-col">{{variant.sku}}</div>
                                    </td>
                                    <td *ngIf="colsForTemplate.includes('productCost') && viewCostPermission">{{ variant.cost }}</td>
                                    <td *ngIf="colsForTemplate.includes('productPrice')">{{ variant.price || 0}}</td>
                                    <td *ngIf="colsForTemplate.includes('requestedStock') && !isRequested">{{ product.requestedStock || 0 }}</td>
                                    <td *ngIf="colsForTemplate.includes('sentStock')">{{ product.sentStock || 0}}</td>
                                    <td *ngIf="colsForTemplate.includes('receivedStock')">{{ product.receivedStock || 0}}</td>
                                    <td *ngIf="colsForTemplate.includes('sourceStock') && !isRequested">{{ variant.sourceStock || 0 }}</td>
                                    <td *ngIf="colsForTemplate.includes('destinationStock') && !isRequested">{{ variant.destinationStock || 0 }}</td>
                                    <td *ngIf="colsForTemplate.includes('rejectedStock')">{{ product.rejectedStock || 0}}</td>
                                </tr>
                            </div>
                        </ng-template>
                    </p-table>
                </div>
            </div>

        </div>
    </div>

</div>
