import { createAction, props } from '@ngrx/store';
import { Setting } from '../model/Setting';

export const updateSettings = createAction(
  '[receipt settings page] update pos settings',
  props<{ settings: Setting[] }>(),
);
export const getSettings = createAction(
  '[Resolver] load pos settings',
);

export const getSalesTemplate = createAction(
  '[Resolver] get sales template',
  props<{ settings: Setting[] }>(),
);

export const posSettingsUpdated = createAction(
  '[POS Settings Effect] POS Settings Updated',
  props<{ settings: Setting }>(),
);

export const posSettingsAdded = createAction(
  '[POS Settings Effect] POS Settings Added',
  props<{ settings: Setting }>(),
);

export const posSettingsLoaded = createAction(
  '[POS settings effect] pos settings loaded',
  props<{ settings: Setting[] }>(),
);
