import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { selectSubscribedApps } from 'src/app/applications/selectors/app.selectors';
import { AppService } from 'src/app/applications/services/app.service';
import {
  LocationFields,
  VariantLocationFields,
} from 'src/app/inventory/products/components/create/product/simple-product/types';
import { AuthUser, Permission } from '../../auth/auth.models';
import { AuthService } from '../../auth/services/auth.service';
import { UserService } from '../../auth/services/user.service';
import { AppState } from '../../reducers';
import {
  APP_NAMES,
  APP_PERMISSION_PREFIXES,
  CommonPolicies,
  LocalStorageKey,
  modulesMapping,
  PlanType,
  TrialState,
  ZatcaAppPermissions,
} from '../constants';
import { AppChannelSubscription } from '../model/AppChannelSubscription';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { AdvancedAccountingService } from '../../advanced-accounting/advanced-accounting.service';
import { ConfigurationName } from '../../configuration/configuration.service';
import { FeatureFlagEnum } from '../constants/feature-flag.constants';
import { FeatureFlagService } from './types/feature-flag.service.interface';
import {
  OnboardingService,
  OnboardingStatus,
} from '../../auth/services/onboarding/onboarding.service';

export enum CustomerPermissions {
  CustomerDetailDelete = 'customer.detail.delete',
  CustomerDetailUpdate = 'customer.detail.update',
}

/**
 * @deprecated use PermissionHelperServiceV2 instead
 */
@Injectable()
export class PermissionHelperService {
  userPermissions: Permission[];

  user = null;

  apps: AppChannelSubscription[] = [];

  isSimpleAccountingInstalled = false;

  DASHBOARD_PERMISSION: string[] = [
    CommonPolicies.DASHBOARD_REVENUE,
    CommonPolicies.DASHBOARD_GROSS_PROFIT,
    CommonPolicies.DASHBOARD_TRANSACTION,
    CommonPolicies.DASHBOARD_INVENTORY_VALUE,
    CommonPolicies.DASHBOARD_AVERAGE_SALES,
    CommonPolicies.DASHBOARD_TOTAL_SALES,
    CommonPolicies.DASHBOARD_AVERAGE_ITEMS_PER_SALE,
    CommonPolicies.DASHBOARD_TOP_5_PRODUCTS,
    CommonPolicies.DASHBOARD_AMOUNT_COLLECTED,
    CommonPolicies.NEW_DASHBOARD_LOGS,
    CommonPolicies.NEW_DASHBOARD_NOTIFICATIONS,
    CommonPolicies.NEW_DASHBOARD_ORDERS_AND_SALES,
    CommonPolicies.NEW_DASHBOARD_REGISTER_CHART,
    CommonPolicies.NEW_DASHBOARD_SALES_BY_BRANCH,
    CommonPolicies.NEW_DASHBOARD_SALES_BY_CATEGORY,
    CommonPolicies.NEW_DASHBOARD_SALES_BY_DAY,
    CommonPolicies.NEW_DASHBOARD_SALES_BY_PRODUCT,
    CommonPolicies.NEW_DASHBOARD_SALES_BY_SALESMAN,
    CommonPolicies.NEW_DASHBOARD_SALES_TARGET,
    CommonPolicies.NEW_DASHBOARD_SALES_TRANSACTION,
    CommonPolicies.NEW_DASHBOARD_INVENTORY_VALUE,
    CommonPolicies.NEW_DASHBOARD_AMOUNT_COLLECTED,
  ];

  DASHBOARD_EXPENSE_CARDS: string[] = [
    CommonPolicies.DASHBOARD_EXPENSE,
    CommonPolicies.DASHBOARD_NET_INCOME,
  ];

  landingPageMapping = [
    {
      permission: this.DASHBOARD_PERMISSION,
      landingUrl: '/dashboard',
      additionalAppPermit: {
        expense: this.DASHBOARD_EXPENSE_CARDS,
      },
    },
    { permission: ['product.list.read'], landingUrl: '/inventory/products' },
    { permission: ['report.read'], landingUrl: '/reports' },
    { permission: ['order.list.read'], landingUrl: '/order-management/orders' },
    {
      permission: ['order.list.read'],
      landingUrl: '/order-management/settings',
    },
    {
      permission: ['invoice.list.read'],
      landingUrl: '/order-management/invoices',
    },
    {
      permission: ['customer.list.read'],
      landingUrl: '/order-management/customers',
    },
    {
      permission: ['purchase.order.list.read'],
      landingUrl: '/invoices/purchase-orders',
    },
    {
      permission: ['purchase.order.detail.read'],
      landingUrl: '/invoices/purchase-order',
    },
    {
      permission: ['return.stock.list.read'],
      landingUrl: '/invoices/return-stocks',
    },
    {
      permission: ['remove.stock.list.read'],
      landingUrl: '/invoices/remove-stock',
    },
    {
      permission: ['transfer.stock.list.read'],
      landingUrl: '/invoices/transfer-stock',
    },
    {
      permission: ['stock.count.list.read'],
      landingUrl: '/invoices/stock-count',
    },
    { permission: ['supplier.list.read'], landingUrl: '/invoices/suppliers' },
    { permission: ['payment.list.read'], landingUrl: '/invoices/payments' },
    { permission: ['available.app.read'], landingUrl: '/applications' },
    {
      permission: ['app.list.read'],
      landingUrl: '/applications/my-apps',
      hasAppPermit: true,
    },
    { permission: ['company.overview.read'], landingUrl: '/users-settings' },
    { permission: ['user.list.read'], landingUrl: '/users-settings' },
    { permission: ['location.list.read'], landingUrl: '/users-settings' },
    { permission: ['tax.list.read'], landingUrl: '/users-settings' },
    { permission: ['payment.method.list.read'], landingUrl: '/users-settings' },
    { permission: ['configuration.list.read'], landingUrl: '/users-settings' },
    {
      permission: ['subscription.read'],
      landingUrl: '/users-settings/my-subscription',
    },
    { permission: ['pos.sales_screen.open'], landingUrl: '/pos/registers' },
    { permission: ['pos.sales_screen.open'], landingUrl: '/pos/v2/registers' },
    {
      permission: ['pos.cash_management.read'],
      landingUrl: '/pos/cash-management',
    },
    { permission: ['pos.receipt_setting.read'], landingUrl: '/pos/settings' },
    { permission: ['pos.sales_setting.read'], landingUrl: '/pos/settings' },
    { permission: ['pos.layout_setting.read'], landingUrl: '/pos/layout' },
    { permission: ['promotion.list.read'], landingUrl: '/promotions' },
    {
      permission: ['accounting.dashboard.read'],
      landingUrl: '/accounting/summary',
    },
    { permission: ['expense.expense_list.search'], landingUrl: '/expenses' },
    {
      permission: ['accounting_app.chart_of_account.read'],
      landingUrl: '/accounting',
    },
    { permission: ['batch.list.read'], landingUrl: '' },
  ];

  defaultLandingPageWithoutSubscriptionv2 = {
    permission: ['subscription.read'],
    landingUrl: '/users-settings/my-subscription/active-plan',
  };

  defaultLandingPageWithoutSubscription = {
    permission: ['subscription.read'],
    landingUrl: '/users-settings/my-subscription',
  };

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private authService: AuthService,
    private userService: UserService,
    private advanceAccountingService: AdvancedAccountingService,
    private localStorageService: LocalStorageService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.apps = [];
    this.store
      .pipe(select(selectSubscribedApps), distinctUntilChanged())
      .subscribe((apps) => {
        this.apps = apps || [];
        this.isSimpleAccountingInstalled = !!apps.find(
          (sub) => sub.name.toLowerCase() === APP_NAMES.EXPENSES.toLowerCase(),
        );
      });
  }

  private getPermissions() {
    if (this.userPermissions) {
      return this.userPermissions;
    }
    this.userService
      .getUser()
      .pipe()
      .subscribe((user) => {
        this.userPermissions = user ? user.permissions : [];
        this.user = user;
      });
    return this.userPermissions;
  }

  public userHasSubscription() {
    return this.user && this.user.RewaaAccountSubscription;
  }

  public isAppPermissionExist(app_name: string, permission: string) {
    const currApp = APP_PERMISSION_PREFIXES.find(
      (a) => a.name.toLowerCase() === app_name.toLowerCase(),
    );
    return this.isPermissionExist(
      `${currApp ? currApp.prefix : ''}.${permission}`,
    );
  }

  /**
   * @deprecated use hasAtLeastOnePermission from PermissionHelperServiceV2 instead 
   * and pass permission array of length 1
   */
  public isPermissionExist(permission: string) {
    return (this.getPermissions() || []).find(
      (p) => p.eventPath === permission,
    );
  }

  public anyOnePermissionExist(permissions: Array<string>) {
    return (this.getPermissions() || []).find((p) =>
      permissions.includes(p.eventPath),
    );
  }

  public allPermissionsExist(permissions: Array<string>): boolean {
    let validPermissions = 0;
    permissions.forEach((permission: string) => {
      const isPermitted = (this.getPermissions() || []).find(
        (p) => permission === p.eventPath,
      );
      if (isPermitted) validPermissions += 1;
    });
    return validPermissions === permissions.length;
  }

  getLandingUrl(navigatedUrl: string): Observable<string | boolean> {
    const [originalRoute] = navigatedUrl.split('?');
    const user = this.localStorageService.getItem<AuthUser>(
      LocalStorageKey.User,
    );
    const subscriptionDeletedAt = user?.subscriptionDeletedAt
      ? new Date(user.subscriptionDeletedAt)
      : '';
    let foundUserWithoutSubscription = false;
    if (subscriptionDeletedAt) {
      const todayDate = new Date();
      const diffTime = Math.abs(
        todayDate.getTime() - subscriptionDeletedAt.getTime(),
      );
      const deletedSince = Math.ceil(diffTime / (1000 * 60 * 60));
      if (deletedSince > 24) {
        foundUserWithoutSubscription = true;
      }
    }
    const allowedSubscriptionPermission =
      user &&
      (!user.RewaaAccountSubscription ||
        new Date(user?.RewaaAccountSubscription.endDate) > new Date()) &&
      !foundUserWithoutSubscription;
    return this.authService.isAuthenticated().pipe(
      switchMap(async (isAuthenticated) => {
        if (!isAuthenticated) {
          return false;
        }
        const redirectUrl = await this.findLandingPage(
          navigatedUrl,
          user,
          originalRoute,
          allowedSubscriptionPermission,
          this.apps,
        );
        return redirectUrl || '/unauthorised';
      }),
    );
  }

  private isModuleAllowed(moduleName: string): boolean {
    const user = this.localStorageService.getItem<AuthUser>(
      LocalStorageKey.User,
    );
    return user.RewaaAccountSubscription.allowedModules.some(
      (allowedModule) =>
        allowedModule.featureId.toLowerCase() === moduleName.toLowerCase(),
    );
  }

  private async redirectToOnboardingIfPending(): Promise<{
    redirect: boolean;
    url: string;
  }> {
    const isOnboardingEnabled = await firstValueFrom(
      this.featureFlagService.isEnabled(FeatureFlagEnum.RewaaOnboarding),
    );

    if (!isOnboardingEnabled) {
      return {
        redirect: false,
        url: '',
      };
    }

    const { onboardingStatus } = this.user;

    if (
      onboardingStatus &&
      onboardingStatus === OnboardingStatus.OnboardingPending
    ) {
      return {
        redirect: true,
        url: '/trial-user',
      };
    }

    return {
      redirect: false,
      url: '',
    };
  }

  public async findLandingPage(
    navigatedUrl,
    user,
    originalRoute,
    allowedSubscriptionPermission,
    subscribedApps,
  ) {
    const rootUrlCharacters = new Set(['/', '']);
    const subsV2 = await firstValueFrom(
      this.featureFlagService.isEnabled(FeatureFlagEnum.SubscriptionsV2, true),
    );
    if (user) {
      const rerouteUrl = subsV2
        ? this.defaultLandingPageWithoutSubscriptionv2.landingUrl
        : this.defaultLandingPageWithoutSubscription.landingUrl;

      const subscriptionPages = subsV2 ? 'my-subscription' : 'users-settings';
      // console.log(originalRoute)
      const isSubscriptionPage = originalRoute.includes(subscriptionPages);

      if (user.isReseller) {
        if (navigatedUrl.includes('/referral-program')) {
          return true;
        }
        return '/referral-program';
      }

      const onboardingUrl = '/trial-user';

      if (
        navigatedUrl === onboardingUrl
      ) {
        return true;
      }

      const userPermissions = this.getPermissions();

      if (
        rootUrlCharacters.has(navigatedUrl)
      ) {
        const onboardingRedirect = await this.redirectToOnboardingIfPending();
        if (onboardingRedirect.redirect) {
          return onboardingRedirect.url;
        }
      }

      if (
        user.RewaaAccountSubscription.allowedModules &&
        user.RewaaAccountSubscription.planType === PlanType.Custom
      ) {
        // Find the module in the modulesMapping that matches the original route
        const matchingModule = modulesMapping.find((module) =>
          originalRoute.includes(module.path),
        );

        // If a matching module is found
        if (matchingModule) {
          // Check if the user's allowed modules include the matching module
          const isModuleAllowed = this.isModuleAllowed(
            matchingModule.moduleName,
          );
          // If the module is not allowed for the user, return the unauthorised route
          if (!isModuleAllowed) {
            return '/unauthorised';
          }
        }

        if (rootUrlCharacters.has(navigatedUrl)) {
          for (const module of modulesMapping) {
            const isModuleAllowed = this.isModuleAllowed(module.moduleName);
            if (isModuleAllowed) {
              return module.path;
            }
          }
        }
      }
      if (!allowedSubscriptionPermission && !isSubscriptionPage) {
        return rerouteUrl;
      }

      if (rootUrlCharacters.has(navigatedUrl)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const route of this.landingPageMapping) {
          const userPermissionForRoute = !!userPermissions.find((p) =>
            route.permission.includes(p.eventPath),
          );
          const isUserHasPermission =
            userPermissionForRoute ||
            (route.hasAppPermit && this.checkAtleastOneAppReadPermission());

          if (isUserHasPermission) {
            return route.landingUrl;
          }

          if (route.additionalAppPermit) {
            const isSimpleAccountingInstalled = !!subscribedApps.find(
              (sub) =>
                sub.name.toLowerCase() === APP_NAMES.EXPENSES.toLowerCase(),
            );

            this.isSimpleAccountingInstalled = isSimpleAccountingInstalled;

            if (
              route.additionalAppPermit.expense &&
              isSimpleAccountingInstalled
            ) {
              const isExpenseCardPermission = this.anyOnePermissionExist(
                route.additionalAppPermit.expense,
              );
              if (isExpenseCardPermission) {
                return route.landingUrl;
              }
            }
          }
        }
      } else if (!rootUrlCharacters.has(navigatedUrl)) {
        if (isSubscriptionPage) {
          return true;
        }
        const navigatedRouteArr = this.landingPageMapping.filter((e) => {
          if (!rootUrlCharacters.has(e.landingUrl)) {
            return originalRoute.includes(e.landingUrl);
          }
          return [];
        });
        if (navigatedRouteArr.length > 1) {
          return true;
        }
        const isUserHasPermission = userPermissions.find(
          (p) =>
            navigatedRouteArr[0] &&
            (allowedSubscriptionPermission ||
              navigatedRouteArr[0].permission.includes('subscription')) &&
            (navigatedRouteArr[0].permission.includes(p.eventPath) ||
              (navigatedRouteArr[0].hasAppPermit &&
                this.checkAtleastOneAppReadPermission())),
        );
        if (isUserHasPermission) {
          return true;
        }
      }
      return '/unauthorised';
    }
    return '/error';
  }

  public checkAtleastOneAppReadPermission() {
    let notPermit = false;
    this.store
      .pipe(
        select(selectSubscribedApps),
        take(1),
        tap((apps) => {
          this.apps = apps || [];
          const permissions: Array<any> = this.getPermissions() || [];
          Object.values(APP_NAMES).forEach((app) => {
            const isAppExists = this.apps.find(
              (a) => a.name.toLowerCase() === app.toLowerCase(),
            );
            if (isAppExists) {
              const appPrefix = APP_PERMISSION_PREFIXES.find(
                (a) => a.name.toLowerCase() === app.toLowerCase(),
              );

              const prefix = appPrefix ? appPrefix.prefix : undefined;
              const allAppRead = this.getAppReadPermissionsLists(prefix);

              if (permissions.find((p) => allAppRead.includes(p.eventPath))) {
                notPermit = true;
              }
            }
          });
        }),
      )
      .subscribe();

    return notPermit;
  }

  getAppReadPermissionsLists = (prefix: string): Array<string> => {
    const DASHBOARD_LIST = this.DASHBOARD_PERMISSION.map(
      (p) => `${prefix}.${p}`,
    );
    const DASHBOARD_EXPENSE_LIST = this.DASHBOARD_EXPENSE_CARDS.map(
      (p) => `${prefix}.${p}`,
    );
    const PERMISSIONS_LISTS = [
      `${prefix}.${CommonPolicies.DASHBOARD_READ}`,
      `${prefix}.${CommonPolicies.PRODUCT_MANAGEMENT_READ}`,
      `${prefix}.${CommonPolicies.SETTING_READ}`,
      `${prefix}.${CommonPolicies.ERROR_LIST_READ}`,
    ];

    if (this.isSimpleAccountingInstalled) {
      return [
        ...DASHBOARD_LIST,
        ...DASHBOARD_EXPENSE_LIST,
        ...PERMISSIONS_LISTS,
      ];
    }
    return [...DASHBOARD_LIST, ...PERMISSIONS_LISTS];
  };

  getDashboardPermissions = (isSimpleAccountingInstalled) => {
    if (isSimpleAccountingInstalled === undefined) {
      isSimpleAccountingInstalled = this.isSimpleAccountingInstalled;
    }
    if (isSimpleAccountingInstalled) {
      return [...this.DASHBOARD_PERMISSION, ...this.DASHBOARD_EXPENSE_CARDS];
    }
    return this.DASHBOARD_PERMISSION;
  };

  isAccountingAppInstalled = (subscribedApps: AppChannelSubscription[]) =>
    !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.EXPENSES.toLowerCase(),
    );

  isAdvAccountingAppInstalled = (subscribedApps: AppChannelSubscription[]) =>
    !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.ACCOUNTING.toLowerCase(),
    );

  checkAccountingAppInstallationAndStatus = async (
    subscribedApps: AppChannelSubscription[],
  ): Promise<{
    isAccountingAppInstalled: boolean;
    isAccountingAppTrial: boolean;
    isTrialNotStarted: boolean;
    isAccountingTrialEnd: boolean;
  }> => {
    const accountingApp = subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.ACCOUNTING.toLowerCase(),
    );
    const res = {
      isAccountingAppInstalled: false,
      isAccountingAppTrial: false,
      isTrialNotStarted: false,
      isAccountingTrialEnd: false,
    };
    if (accountingApp) {
      res.isAccountingAppInstalled = true;
      if (accountingApp.trialStatus !== TrialState.PAID) {
        res.isAccountingAppTrial = true;
        const configuration = await firstValueFrom(
          this.advanceAccountingService.getSetting(ConfigurationName.SETUP),
        );
        res.isTrialNotStarted =
          !configuration || !configuration.value?.bankAndOpeningBalance;

        if (accountingApp.trialStatus === TrialState.EXPIRED) {
          res.isAccountingTrialEnd = true;
        }
      }
    }
    return res;
  };

  isPromotionAppInstalled = (subscribedApps) =>
    !!subscribedApps.find(
      (sub) =>
        sub.name.toLowerCase() === APP_NAMES.PROMOTION_MANAGMENET.toLowerCase(),
    );

  isPosAppInstalled = (subscribedApps) =>
    !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.INTERNAL_POS.toLowerCase(),
    );

  filterAvailableFields = (fields) =>
    fields.filter((f) => {
      let isReadable = true;
      if (
        (f.id === VariantLocationFields.retailPrice &&
          !this.hasRetailPricePermission()) ||
        (f.id === VariantLocationFields.wholeSalePrice &&
          !this.hasWholeSalePricePermission()) ||
        (f.id === VariantLocationFields.buyPrice &&
          !this.hasBuyPricePermission()) ||
        (f.id === VariantLocationFields.initialCost &&
          !this.hasInitialCostPermission()) ||
        (f.id === LocationFields.QTY && !this.hasAvailableQtyPermission()) ||
        (f.id === LocationFields.RETAIL_PRICE &&
          !this.hasRetailPricePermission()) ||
        (f.id === LocationFields.WHOLE_SALE_PRICE &&
          !this.hasWholeSalePricePermission()) ||
        (f.id === LocationFields.BUY_PRICE && !this.hasBuyPricePermission())
      ) {
        isReadable = false;
      }
      return isReadable;
    });

  hasRetailPricePermission = () =>
    this.anyOnePermissionExist([
      CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_UPDATE,
    ]);

  hasWholeSalePricePermission = () =>
    this.anyOnePermissionExist([
      CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_UPDATE,
    ]);

  hasBuyPricePermission = () =>
    this.anyOnePermissionExist([
      CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_UPDATE,
    ]);

  hasAvailableQtyPermission = () =>
    this.anyOnePermissionExist([
      CommonPolicies.PRODUCT_AVAILABLE_QUANTITY_READ,
    ]);

  hasInitialCostPermission = () =>
    this.anyOnePermissionExist([
      CommonPolicies.PRODUCT_DETAILS_AVERAGE_COST_READ,
    ]);

  getZatcaPermissions(): { [key in ZatcaAppPermissions]: boolean } {
    const permissions = {
      [ZatcaAppPermissions.ZatcaDashboardRead]: false,
      [ZatcaAppPermissions.ZatcaDashboardUpdate]: false,
      [ZatcaAppPermissions.ZatcaErrorListRead]: false,
      [ZatcaAppPermissions.ZatcaSettingsRead]: false,
    };
    const permissionsList = this.getPermissions();
    permissionsList.forEach((permission) => {
      if (permission.eventPath in permissions) {
        permissions[permission.eventPath] = true;
      }
    });
    return permissions;
  }
}
