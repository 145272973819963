import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PlanSubscriptionService } from '../users-settings/services/plan-subscription.service';
import { AccountSetupComponent } from './components/account-setup/account-setup.component';

import { UserpilotGuardService } from '../services/userpilot-guard.service';
import { SideNavMenuComponent } from '../ui/components/side-nav-menu/side-nav-menu.component';
import { UiModule } from '../ui/ui.module';
import { AppSignInAlertComponent } from './components/app-signin-alert/app-signin-alert.component';
import { CobrowsingModalComponent } from './components/cobrowsing-modal/cobrowsing-modal.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { CoreRoutingModule } from './core-routing.module';
import { LocationService } from './services/location.service';
import { SubscriptionTrialBannerService } from './services/subscription-trial-banner.service';
import { BannerComponent } from './components/banner/banner.component';
import { SubscriptionReminderComponent } from './components/subscription-remainder/subscription-remainder.component';
import { EventTrackingService } from './services/event-tracking.service';
import { ImageCacheService } from './services/image-cache.service';
import { TrackingEventsService } from '../shared/services/tracking-events.service';
import { DeviceInfoService } from './services/device-info.service';
import { SideNavMenuNewComponent } from '../ui/components/side-nav-menu-new/side-nav-menu-new.component';
import { SideNavComponent } from '../ui/components/side-nav/side-nav.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreRoutingModule,
    TranslateModule,
    UiModule,
  ],
  declarations: [
    AccountSetupComponent,
    LanguageSwitcherComponent,
    SideNavMenuComponent,
    SideNavMenuNewComponent,
    SideNavComponent,
    AppSignInAlertComponent,
    CobrowsingModalComponent,
    BannerComponent,
    SubscriptionReminderComponent,
  ],
  exports: [
    AccountSetupComponent,
    LanguageSwitcherComponent,
    SideNavMenuComponent,
    SideNavMenuNewComponent,
    SideNavComponent,
    BannerComponent,
  ],
  providers: [
    PlanSubscriptionService,
    UserpilotGuardService,
    LocationService,
    SubscriptionTrialBannerService,
    EventTrackingService,
    TrackingEventsService,
    ImageCacheService,
    DeviceInfoService,
  ],
})
export class CoreModule {}
