/* eslint-disable eqeqeq */
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { mergeMap, map, of } from 'rxjs';
import {
  PermissionDTO,
  PermissionServiceV2,
} from '../../auth/services/permission-v2.service';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';
import { UserService } from '../../auth/services/user.service';
import { CHARGEBEE_SUBSCTIPION_STATUS } from '../../shared/constants';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { legacyPermissionstoNewPermissions } from '../../shared/constants/permissions.constants';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from '../../shared/decorators/auto-unsubscribe';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';

@Directive({
  selector: '[rwPermission]',
})
@AutoUnsubscribe
export class PermissionDirective implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  @Input() permission: string;

  @Input() permissions: Array<string>;

  @Input() action = 'hide'; // Optional and default  = hide

  @Input() hasPermit: boolean = null;

  userPermissions: PermissionDTO[];

  isPermissionV2Enabled: boolean;

  constructor(
    private el: ElementRef,
    private router: Router,
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
    private permissionServiceV2: PermissionServiceV2,
    private userAuthenticationService: UserAuthenticationService,
  ) {}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  ngOnInit(): void {
    if (
      !(this.permission || (this.permissions && this.permissions.length > 0))
    ) {
      return;
    }
    this.subscriptionRefs = this.userService
      .getUser()
      .pipe(
        mergeMap((authUser) => {
          if (this.isPermissionV2Enabled == undefined) {
            return this.featureFlagService
              .isEnabled(FeatureFlagEnum.PermissionsV2)
              .pipe(
                map((flag) => {
                  this.isPermissionV2Enabled = flag;
                  return authUser;
                }),
              );
          }
          return of(authUser);
        }),
        mergeMap((authUser) => {
          if (this.userPermissions == undefined && this.isPermissionV2Enabled) {
            return this.permissionServiceV2.getUserPermissions().pipe(
              map((permissions) => {
                this.userPermissions = permissions;
                return authUser;
              }),
            );
          }
          return of(authUser);
        }),
      )
      .subscribe((user) => {
        let permissions: string[];
        if (this.isPermissionV2Enabled) {
          permissions =
            this.userPermissions?.map((item) => item.permission) || [];
        } else {
          permissions = user.permissions?.map((item) => item.eventPath) || [];
        }
        this.updateInputPermissions(this.isPermissionV2Enabled);
        if (user) {
          if (!(permissions && permissions.length > 0)) {
            this.userAuthenticationService.logout();
            this.router.navigateByUrl('/login').then();
            return;
          }
          const isSubscriptionPermission =
            this.permission && this.permission.includes('subscription');
          const isSubscriptionExpired =
            user.RewaaAccountSubscription &&
            this.getDateDifferentToToday(
              user.RewaaAccountSubscription.endDate,
            ) <= 0;

          const isFutureSubscription =
            user.RewaaAccountSubscription &&
            this.isFutureSubscription(user.RewaaAccountSubscription);

          if (isSubscriptionExpired && !isSubscriptionPermission) {
            if (this.router.url === '/referral-program/invite-friends') return;
            if (this.router.url.includes('my-subscription/handler?tap_id'))
              return;
            this.router.navigateByUrl('/users-settings/my-subscription').then();
            return;
          }

          if (isFutureSubscription) {
            if (this.router.url === '/referral-program/invite-friends') return;
            this.router.navigateByUrl('/future-subscription').then();
            return;
          }

          const allowedSubscriptionPermission =
            isSubscriptionPermission ||
            !user.RewaaAccountSubscription ||
            this.getDateDifferentToToday(
              user.RewaaAccountSubscription.endDate,
            ) > -3;
          const matchedPermission =
            allowedSubscriptionPermission && this.getPermissions(permissions);
          if (
            !matchedPermission ||
            (this.hasPermit !== null &&
              this.hasPermit !== undefined &&
              this.hasPermit)
          ) {
            this.takePermissionNotFoundAction();
          } else {
            this.takePermissionFoundAction();
          }
        }
      });
  }

  getDateDifferentToToday = (date: Date): number => {
    const diffTime = new Date(date).getTime() - Date.now();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  isFutureSubscription = (subscription): boolean => {
    const subscriptionDate = new Date(subscription.startDate).getTime();
    const currentDate = Date.now();

    return (
      subscriptionDate > currentDate ||
      subscription.status === CHARGEBEE_SUBSCTIPION_STATUS.FUTURE
    );
  };

  getPermissions(permissions: string[]): boolean {
    return !!permissions.find(
      (p) =>
        (this.permissions && this.permissions.includes(p)) ||
        (this.permission && p === this.permission),
    );
  }

  takePermissionNotFoundAction(): void {
    if (this.action === 'disable') {
      this.el.nativeElement.disabled = true;
      this.el.nativeElement.classList.add('permission-disable');
    } else if (this.action === 'route') {
      this.router.navigateByUrl('/unauthorised').then();
    } else if (this.action === 'force-hide') {
      this.el.nativeElement.style.setProperty('display', 'none', 'important');
    } else if (this.action === 'simple-hide') {
      this.el.nativeElement.style.visibility = 'hidden';
    } else {
      this.el.nativeElement.style.display = 'none';
      this.el.nativeElement.style.visibility = 'hidden';
    }
  }

  takePermissionFoundAction(): void {
    if (this.action === 'disable') {
      this.el.nativeElement.disabled = this.el.nativeElement.disabled || false;
      this.el.nativeElement.classList.remove('permission-disable');
    } else {
      this.el.nativeElement.style = {};
    }
  }

  updateInputPermissions(isPermissionsV2Enabled: boolean): void {
    if (isPermissionsV2Enabled) this.updateInputPermissionsFromV1toV2();
    else this.updateInputPermissionsFromV2toV1();
  }

  updateInputPermissionsFromV1toV2(): void {
    const getUpdatedPermission = (permission: string): string =>
      legacyPermissionstoNewPermissions[permission] || permission;

    if (this.permission) {
      this.permission = getUpdatedPermission(this.permission);
    }
    if (this.permissions && this.permissions.length > 0) {
      this.permissions = this.permissions.map((permission) =>
        getUpdatedPermission(permission),
      );
    }
  }

  updateInputPermissionsFromV2toV1(): void {
    const newPermissionstoLegacyPermissions = this.swapObject(
      legacyPermissionstoNewPermissions,
    );
    const getLegacyPermission = (permission: string): string =>
      newPermissionstoLegacyPermissions[permission] || permission;

    if (this.permission) {
      this.permission = getLegacyPermission(this.permission);
    }
    if (this.permissions && this.permissions.length > 0) {
      this.permissions = this.permissions.map((permission) =>
        getLegacyPermission(permission),
      );
    }
  }

  swapObject(obj: { [key: string]: string }): { [key: string]: string } {
    const swapped = {};
    Object.keys(obj).forEach((key) => {
      swapped[obj[key]] = key;
    });
    return swapped;
  }
}
