<p-dialog id="rw__license-key-dialog" class="rw__dialog rw__dialog-sm rw__dialog-no-responsive" [(visible)]="dialogVisible" [modal]="true" [position]="dialogPosition" [draggable]="false"
    [resizable]="false" (onHide)="closeThisDialog()">
    <ng-container>
        <div class="d-flex flex-column rw__mb-7">
            <label class="fw-500 rw__fs-6 text-grey-80 rw__mb-4 rw__hide-on-desktop">{{ 'auth.licenseKey' | translate }}</label>
            <div class="rw__mb-5">
                <img src="../../../../../assets/images/license-key-dialog-bg.jpg" class="w-full" />
            </div>
            <div class="d-flex flex-column align-items-center lg:text-center">
                <label class="fw-500 rw__fs-5 text-grey-80 rw__mb-0 rw__hide-on-mobile">{{ 'auth.licenseKey' | translate }}</label>
                <p class="fw-400 rw__fs-9 text-grey-50 rw__mb-0">{{ 'auth.licenseKeyMessage' | translate }}</p>
            </div>
            <div class="rw__mt-3 rw__mb-6">
                <a href="https://www.rewaatech.com/%D8%B9%D8%B1%D8%B6-%D8%AE%D8%A7%D8%B5-%D9%84%D8%B2%D9%88%D8%A7%D8%B1%D9%86%D8%A7/" target="_blank" class="rw__link rw__fs-8" pendo-tracker="license_key_pop_up_learn_more_button">
                    {{ 'auth.learnMoreLicenseKey' | translate }}
                    <i class="fa-regular fa-arrow-up-right-from-square rw__ms-2" [ngClass]="lang === 'ar' ? 'fa-flip-horizontal' : ''"></i>
                </a>
            </div>
            <div class="d-flex flex-column">
                <span class="rw__fs-9 text-grey-60 rw__mb-2">{{ 'auth.needMoreHelp' | translate }}</span>
                <button pButton pRipple type="button" (click)="contactSupport()" pendo-tracker="license_key_pop_up_contact_support_button"
                        class="rw__button d-flex justify-content-center rw__button-hide-button-label p-button-raised p-button-primary-light">
                    <i class="fa-regular fa-headphones"></i> {{ 'auth.contactSupport' | translate }}
                </button>
            </div>
        </div>
    </ng-container>
</p-dialog>
