/* eslint-disable no-param-reassign */
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { finalize, take, tap } from 'rxjs/operators';
import { POSLocation, Register } from 'src/app/internal-apps/pos/model/Register';
import { RegisterService } from 'src/app/internal-apps/pos/services/register.service';
import { REGISTER_STATUS } from 'src/app/internal-apps/pos/utils/constants';
import { mapInvoiceToDebitable } from 'src/app/invoices/payment/utils/mapper';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import { CustomersService } from 'src/app/orders/customers/services/customers.service';
import { AppState } from 'src/app/reducers';
import { AutoUnsubscribe, AutoUnsubscribeI } from 'src/app/shared/decorators/auto-unsubscribe';
import { PaymentMethod } from 'src/app/shared/model/PaymentMethod';
import { Invoice } from 'src/app/shared/model/invoice/Invoice';
import { Customer } from 'src/app/shared/model/order/Customer';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { selectAllPaymentMethods } from 'src/app/users-settings/selectors/payment-methods.selector';
import { CustomToastService } from '../../../../../../services/custom-toast.service';
import { LocalStorageKey } from '../../../../../../constants';
import { LocalStorageService } from '../../../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-orders-receive-debit',
  templateUrl: './receive-debit.component.html',
  styleUrls: ['./receive-debit.component.scss'],
})

@AutoUnsubscribe
export class ReceiveDebitComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  recieveDebitForm: UntypedFormGroup;

  cashManagementForm: UntypedFormGroup;

  lng: string;

  langCss: string;

  invoices: Invoice[];

  locations: POSLocation[];

  registers: Register[];

  breadcrumbData: any[];

  register: Register;

  allPaymentMethods: PaymentMethod[];

  paymentMethods: PaymentMethod[];

  receiveDebitRequested = false;

  considerInCashManagement = false;

  isButtonPressed = false;

  registerStatus = REGISTER_STATUS;

  customer: Customer;

  customerId: number;

  invoiceId: number;

  getTotalPaid: number;

  totalDebitBeforePayment: number;

  getTotalDebitAfterPayment: number;

  loading: boolean;

  receiptVoucherData:any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private registerService: RegisterService,
    private store: Store<AppState>,
    private customersService: CustomersService,
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    public translate: TranslateService,
    public toast: CustomToastService,
    private localStorageService: LocalStorageService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    translate.setDefaultLang(this.lng);
    this.loading = true;
  }

  ngOnInit() {
    this.breadcrumbData = [
      {
        label: 'Order Management',
        url: '#',
        class: 'active-link',
      },
      {
        label: 'Customers',
        url: '/order-management/customers',
        class: 'active-link',
      },
      {
        label: 'Receive Debit',
        url: '#',
        class: '',
      },
    ];
    this.spinnerService.startLoading();
    this.recieveDebitForm = this.formBuilder.group({
      paymentMethodName: '',
    });
    this.cashManagementForm = this.formBuilder.group({
      note: new UntypedFormControl({ value: '', disabled: true }),
      registerId: new UntypedFormControl({ value: '', disabled: true }),
      locationId: new UntypedFormControl({ value: '', disabled: true }),
    });
    const customerId = parseInt(this.route.snapshot.paramMap.get('customerId'), 10);
    const invoiceId = parseInt(this.route.snapshot.paramMap.get('invoiceId'), 10);
    this.invoiceId = invoiceId;
    this.customerId = customerId;
    // getting customer by id
    this.subscriptionRefs = this.customersService.getCustomerById(customerId).subscribe((res) => {
      this.customer = res;
      if (res.debitAmount === 0) {
        this.router.navigateByUrl('/order-management/customers');
      }
    });

    this.subscriptionRefs = this.store.pipe(
      select(selectAllPaymentMethods),
      tap((paymentMethods) => {
        this.allPaymentMethods = paymentMethods;
        this.paymentMethods = paymentMethods;
      }),
    ).subscribe();

    this.subscriptionRefs = this.customersService.getUnPaidInvoices(customerId)
      .subscribe((invoices: Invoice[]) => {
        this.invoices = invoices.map((invoice) => {
          if (invoice.PayableInvoice.PaymentToPayableInvoices) {
            const payments = invoice.PayableInvoice.PaymentToPayableInvoices as any;
            invoice.totalPaidAmount = payments.reduce((a, b) => +a + +b.returnInvoicePaidAmount, 0);
          } else {
            invoice.totalPaidAmount = 0;
          }
          return mapInvoiceToDebitable(invoice);
        });
        this.invoices = this.invoices.filter((inv) => inv.debit > 0);
        if (this.invoiceId) {
          this.invoices = this.invoices.filter((inv) => inv.id === this.invoiceId);
        }
      });
    this.subscriptionRefs = this.registerService.getAllPOSLocationRegisters().pipe(
      tap((locationRegisters) => {
        this.locations = locationRegisters;
      }),
    ).subscribe();

    this.subscriptionRefs = this.cashManagementForm.get('registerId').valueChanges
      .subscribe((value) => {
        if (value) {
          this.recieveDebitForm.get('paymentMethodName').patchValue('');
          this.subscriptionRefs = this.registerService.getRegister(parseInt(value, 10)).pipe(
            tap((register) => {
              this.paymentMethods = register.PaymentMethodToRegisters.map((pm) => pm.PaymentMethod);
            }),
          ).subscribe();
        } else {
          this.paymentMethods = this.allPaymentMethods;
        }
      });
    this.loading = false;
    this.spinnerService.stopLoading();
  }

  onSelectedLocation(locationId: string) {
    this.registers = this.locations.filter((location) => location.id
      === parseInt(locationId, 10))[0].Registers;
    this.registers = this.registers.filter((register) => register.status
      === this.registerStatus.OPENED);
  }

  navigatetoUrl(url: string) {
    if (url && url !== '#') {
      this.router.navigateByUrl(url);
    }
  }

  // for enabling the location and register dropdown
  toggler(e) {
    this.cashManagementForm.get('locationId').patchValue('');
    this.cashManagementForm.get('registerId').patchValue('');
    this.cashManagementForm.get('note').patchValue('');
    this.recieveDebitForm.get('paymentMethodName').patchValue('');
    if (e.target.checked) {
      this.considerInCashManagement = true;
      this.paymentMethods = [];
      this.cashManagementForm.get('locationId').enable();
      this.cashManagementForm.get('registerId').enable();
      this.cashManagementForm.get('note').enable();
    } else {
      this.considerInCashManagement = false;
      this.paymentMethods = this.allPaymentMethods;
      this.cashManagementForm.get('locationId').disable();
      this.cashManagementForm.get('registerId').disable();
      this.cashManagementForm.get('note').disable();
    }
  }

  // for sum of invoices payment
  get sum() {
    this.getTotalPaid = this.invoices.reduce((a, b) => +a + +b.amount, 0);
    return this.getTotalPaid;
  }

  // for sum of invoices debit amount
  get totalDebit() {
    this.totalDebitBeforePayment = this.invoices.reduce((a, b) => +a + +b.debit, 0);
    return this.totalDebitBeforePayment;
  }

  // for remaining debit amount
  get remainingDebit() {
    this.getTotalDebitAfterPayment = InvoicesCalculator
      .subtract(this.invoices.reduce((a, b) => +a + +b.debit, 0),
        this.invoices.reduce((a, b) => +a + +b.amount, 0));
    return this.getTotalDebitAfterPayment;
  }

  get formValid() {
    if (this.invoices.some((inv) => inv.amount > inv.debit || inv.amount < 0) === false && this.recieveDebitForm.get('paymentMethodName').value && this.getTotalPaid > 0) {
      if (this.considerInCashManagement && !(this.cashManagementForm.get('registerId').value > 0)) {
        return false;
      }
      return true;
    }
    return false;
  }

  receiveDebit() {
    this.spinnerService.startLoading();
    this.isButtonPressed = true;
    if (this.invoices.some((inv) => inv.amount > inv.debit) === false) {
      this.invoices = this.invoices.filter((inv) => inv.amount > 0);
      const selectedPaymentMethod = this.paymentMethods.filter((pm) => pm.id === this.recieveDebitForm.get('paymentMethodName').value)[0];
      const reqBody: any = {
        customerId: this.customerId,
        customerName: this.customer.name,
        invoicesDetails: this.invoices,
        paymentMethod: selectedPaymentMethod,
        note: '',
        totalPaid: this.getTotalPaid,
        totalDebitBeforePayment: this.totalDebitBeforePayment,
        totalDebitAfterPayment: this.getTotalDebitAfterPayment,
      };
      if (this.considerInCashManagement) {
        reqBody.note = this.cashManagementForm.get('note').value;
        reqBody.registerId = this.cashManagementForm.get('registerId').value ? parseInt(this.cashManagementForm.get('registerId').value, 10) : null;
      }
      const getInvoicesNumbers = (invoicesDetails) => invoicesDetails.map((inv) => inv.invoiceNumber).join(',');
      this.subscriptionRefs = this.customersService.receiveDebit(reqBody).pipe(
        take(1),
        tap((receiveDebitResponse) => {
          this.receiptVoucherData = {
            type: 'RECEIPT',
            name: receiveDebitResponse.customerName,
            id: receiveDebitResponse.id,
            invoiceNumber: getInvoicesNumbers(reqBody.invoicesDetails),
            createdAt: receiveDebitResponse.createdAt,
            paidAmount: receiveDebitResponse.PayableInvoice.creditAmount,
            paymentMethod: receiveDebitResponse.PayableInvoice.paymentMethod,
            notes: receiveDebitResponse.notes,
          };
          this.cdr.detectChanges();
          window.print();
          this.toast.success(this.translate.instant('Debit Amount Received Successfully.'));
          this.location.back();
        }),
        finalize(() => {
          this.isButtonPressed = false;
          this.spinnerService.stopLoading();
        }),
      ).subscribe();
    } else {
      this.isButtonPressed = false;
      this.spinnerService.stopLoading();
    }
  }
}
