import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../../constants';

@Component({
  selector: 'rw-offline-hints',
  templateUrl: 'offline-hints.component.html',
  styleUrls: ['offline-hints.component.scss'],
})
export class OfflineHintsComponent {
  @Input() content: any;

  lng: string;

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    translate.setDefaultLang(this.lng);
  }
}
