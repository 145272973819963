import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, paymentMethodsFeatureKey, PaymentMethodsState } from '../reducers/payment-methods.reducer';

export const selectPaymentMethodsState = createFeatureSelector<PaymentMethodsState>(
  paymentMethodsFeatureKey,
);

export const arePaymentMethodsLoaded = createSelector(
  selectPaymentMethodsState,
  (state) => state && state.arePaymentMethodsLoaded,
);

export const selectAllPaymentMethods = createSelector(
  selectPaymentMethodsState,
  selectAll,
);
