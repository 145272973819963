import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey, REJECT_TRANSFER_STOCK_REASONS } from 'src/app/shared/constants';
import { AppChannelSubscription } from '../../../../model/AppChannelSubscription';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-reject-transfer-stock',
  templateUrl: './reject-transfer-stock.component.html',
  styleUrls: ['./reject-transfer-stock.component.scss'],
})
export class RejectTransferStockComponent implements OnInit {
  @Input() content: any;

  app: AppChannelSubscription;

  form: UntypedFormGroup;

  lng: string;

  showReasonTextarea = false;

  langCss:string;

  rejectMessage:string;

  reasons: Array<{ name: String, key: String }> = REJECT_TRANSFER_STOCK_REASONS;

  loading = false;

  constructor(
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lng);
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
    this.rejectMessage = this.translate.instant('transfer warning message', { tranType: this.translate.instant('Reject') });
  }

  ngOnInit() {
    this.app = this.content.app;
    this.form = this.fb.group({
      selectReason: ['', Validators.required],
      reasontextarea: [''],
    });

    this.form.get('selectReason').valueChanges.subscribe((value) => {
      if (value === 'Other') {
        this.showReasonTextarea = true;
      } else {
        this.showReasonTextarea = false;
      }
    });
  }

  reject() {
    let finalReason = this.form.get('selectReason').value;
    if (finalReason === 'Other') {
      finalReason = this.form.controls.reasontextarea.value;
    }
    this.content.success_btn.handler({
      finalReason,
      invoiceId: this.content.invoiceId,
    });
  }
}
