import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from '../../model/invoice/Invoice';
import { Sell } from '../../../internal-apps/pos/model/Sell';
import { OfflineInvoiceNumbersSyncData } from '../types/offline-invoice.types';

@Injectable()
export class OfflineInvoiceService {
  private apiUrl = '/api';
  private posServiceUrl = '/pos-service';

  constructor(private http: HttpClient) { }

  /**
   * @deprecated Use syncOfflineInvoices
   */
  saveOfflineInvoices(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/offline-data/invoices`, data);
  }

  syncOfflineInvoiceNumbers(data: OfflineInvoiceNumbersSyncData): Observable<any> {
    return this.http.post(`${this.apiUrl}/offline-data/invoice-numbers`, data);
  }

  syncOfflineInvoices(data: Sell[]): Observable<boolean[]> {
    return this.http.post<boolean[]>(`${this.apiUrl}${this.posServiceUrl}/invoices`, data);
  }

  getLastInvoiceNumber(type) {
    return this.http.get<Invoice>(`${this.apiUrl}/pos/invoices/lastInvoiceNumber/${type}`);
  }
}
