import { RewaaBaseApiResponse } from '@rewaa-team/types';
import { Injectable } from '@angular/core';

export type GetImportProgressResponse = RewaaBaseApiResponse<
  {
    processedProducts: number;
    totalProducts: number;
  },
  never
>;

export type GetTotalProductsToImportResponse = {
  total: number;
  totalPages: number;
};

export type GetInstallationProgressResponse = {
  totalCount: number;
  count: number;
};
@Injectable()
export class ImportToAppServiceMapper {
  mapToGetTotalProductsToImportResponse(
    response: GetImportProgressResponse,
  ): GetTotalProductsToImportResponse {
    return {
      total: response?.data?.totalProducts,
      totalPages: response?.data?.processedProducts,
    };
  }

  public mapToGetInstallationProgressResponse(
    response: GetImportProgressResponse,
  ): GetInstallationProgressResponse {
    return {
      totalCount: response?.data?.totalProducts,
      count: response?.data?.processedProducts,
    };
  }
}
