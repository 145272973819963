import { calculateInclusivePrice } from 'src/app/internal-apps/pos/utils/sell.utils';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import {
  DigitalProductTypes, PRODUCT_TRACK_TYPES, TAX_TYPES, VariantTypes,
} from '../../constants';
import { TaxLine } from '../../model/Tax';
import { ITaxConfiguration } from '../../model/TaxConfiguration';
import { roundToFixedDigits } from '../../../ui/utility/calculation.utility';

const setUsedVariant = (variantItem: any, variants: Array<any>) => {
  const variant = variants.find((v) => v.sku === variantItem.sku);
  return ({
    ...variant,
    usedQuantity: variantItem.quantity,
    availableQuantity: variantItem.availableLocationQuantity,
    costExclusive: variantItem.cost,
    priceExclusive: variantItem.price,
  });
};

const pushDataInUsedVariant = (variant: any, usedVariants: Array<any>, variants: Array<any>) => {
  const indx: number = usedVariants.find((v: any) => v.sku === variant.sku);

  if (indx >= 0) {
    usedVariants[indx].usedQuantity += variant.quantity;
  } else {
    usedVariants.push(setUsedVariant(variant, variants));
  }
};

export const getUsedVariantInInvoice = (invoice: any, variants: Array<any>) => {
  const { VariantToInvoices } = invoice;

  const usedVariants = [];

  VariantToInvoices.forEach((variantItem) => {
    pushDataInUsedVariant(
      {
        ...variantItem, cost: variantItem.oldCost, price: variantItem.costExclusive,
      }, usedVariants, variants,
    );

    if (variantItem.VariantToInvoiceExtras && variantItem.VariantToInvoiceExtras.length) {
      variantItem.VariantToInvoiceEcards.forEach((extra) => {
        const extraVariant: any = variants.find((v: any) => v.id === extra.productVariantId);

        if (extraVariant) {
          pushDataInUsedVariant(
            {
              ...extraVariant,
              ...extra,
              quantity: InvoicesCalculator.multiply(extra.rate, extra.quantity),
            }, usedVariants, variants,
          );
        }
      });
    }

    if (variantItem.CompositeVariantToInvoiceParts
      && variantItem.CompositeVariantToInvoiceParts.length > 0) {
      variantItem.CompositeVariantToInvoiceParts.forEach((child) => {
        pushDataInUsedVariant(
          {
            ...child,
            price: variantItem.costExclusive,
            cost: variantItem.oldCost,
            quantity: InvoicesCalculator.multiply(child.rate, variantItem.quantity),
          },
          usedVariants, variants,
        );
      });
    }

    if (variantItem.VariantToInvoicePacks && variantItem.VariantToInvoicePacks.length > 0) {
      variantItem.VariantToInvoicePacks.forEach((pack) => {
        pushDataInUsedVariant(
          {
            ...pack,
            quantity: InvoicesCalculator.multiply(pack.rate, variantItem.quantity),
            price: variantItem.costExclusive,
            cost: variantItem.oldCost,
          }, usedVariants, variants,
        );
      });
    }
  });

  return usedVariants;
};

export const getVariantExclusiveCost = (variant: any,
  stockLocationId: number, taxation: string, variants: Array<any>): number => {
  if (variant.type === VariantTypes.Composite) {
    return (
      variant.Product.VariantToComposites.reduce((sum: number, c: any) => {
        // const matchedChild: any = variants.find((v: any) => v.id === c.productVariantId);
        const childCost: number = getVariantExclusiveCost(
          c.ProductVariant, stockLocationId, taxation, variants,
        );
        return InvoicesCalculator.add(sum,
          InvoicesCalculator.multiply(childCost, c.rate));
      }, 0)
    );
  } if (variant.type === VariantTypes.Package) {
    const [pack] = variant.VariantToPackages;
    // const packChild: any = variants.find((v: any) => v.id === pack.productVariantId);
    const packCost: number = getVariantExclusiveCost(
      pack.ProductVariant, stockLocationId, taxation, variants,
    );
    return InvoicesCalculator.multiply(packCost, pack.rate);
  }
  const stock = variant.ProductVariantToStockLocations.find(
    (v) => v.stockLocationId === stockLocationId,
  );
  return taxation === TAX_TYPES.INCLUSIVE ? InvoicesCalculator.divide(
    stock.cost, InvoicesCalculator.add(1, stock.Tax.rate),
  ) : stock.cost;
};

export const getVariantQuantity = (variant: any, stockLocationId: any, variants: Array<any>) => {
  if (variant.type === VariantTypes.Composite) {
    return (
      variant.Product.VariantToComposites.reduce((sum: number, c: any) => {
        // const matchedChild: any = variants.find((v: any) => v.id === c.productVariantId);
        const childQuantity: number = getVariantQuantity(
          c.ProductVariant, stockLocationId, variants,
        );
        return Math.min(sum, childQuantity);
      }, Number.POSITIVE_INFINITY)
    );
  } if (variant.type === VariantTypes.Package) {
    const [pack] = variant.VariantToPackages;
    // const packChild: any = variants.find((v: any) => v.id === pack.productVariantId);
    const packChildQuantity: number = getVariantQuantity(
      pack.ProductVariant, stockLocationId, variants,
    );
    return Math.floor(InvoicesCalculator.divide(packChildQuantity, pack.rate));
  }
  const stock = variant.ProductVariantToStockLocations.find(
    (v) => v.stockLocationId === stockLocationId,
  );

  if (variant.trackType === PRODUCT_TRACK_TYPES.BATCH) {
    return (
      stock.VariantToTracks ? stock.VariantToTracks.reduce(
        (sum: number, t: any) => sum + t.quantity, 0,
      ) : []
    );
  } if (variant.trackType === PRODUCT_TRACK_TYPES.SERIAL) {
    return (
      stock.VariantToTracks ? stock.VariantToTracks.filter(
        (t: any) => +t.quantity > 0,
      ).length : []
    );
  } if (variant.Product.type === DigitalProductTypes.E_CARD) {
    return (
      variant.Ecards ? variant.Ecards.filter((e: any) => !e.isSold).length : []
    );
  }

  return stock.quantity;
};

export const replaceDuplicates = (arr: Array<any>, prop: string = 'id') => {
  const uniqueArr = [];
  arr.forEach((a: any) => {
    const indx: number = uniqueArr.findIndex((d: any) => d[prop] === a[prop]);

    if (indx >= 0) {
      uniqueArr[indx] = a;
    } else {
      uniqueArr.push(a);
    }
  });

  return uniqueArr;
};

export const mapPackSearchForOffline = (packs: Array<any>, existPacks: Array<any>) => {
  existPacks.forEach((p: any) => {
    const foundPack: any = packs.find((pack: any) => pack.id === p.id);

    if (p.ProductVariant && foundPack) {
      p.ProductVariant.ProductVariantToStockLocations = replaceDuplicates([
        ...p.ProductVariant.ProductVariantToStockLocations,
        ...foundPack.ProductVariant.ProductVariantToStockLocations,
      ]);
    }
  });

  return existPacks;
};

export const mapUpdateSearchProduct = (v: any, exist: any): any => {
  exist.ProductVariantToStockLocations = replaceDuplicates(
    [...exist.ProductVariantToStockLocations, ...v.ProductVariantToStockLocations],
  );

  if (exist.Product.type === DigitalProductTypes.E_CARD) exist.Ecards = replaceDuplicates([...exist.Ecards, ...exist]);

  if (exist.type === VariantTypes.Composite) {
    exist.Product.VariantToComposites((child: any) => {
      const matchedChild: any = v.Product.VariantToComposites.find((c: any) => c.id === child.id);
      if (child.ProductVariant && matchedChild && matchedChild.ProductVariant) {
        child.ProductVariant.ProductVariantToStockLocations = replaceDuplicates([
          ...child.ProductVariant.ProductVariantToStockLocations,
          ...matchedChild.ProductVariant.ProductVariantToStockLocations,
        ]);

        if (child.ProductVariant.type === VariantTypes.Package) {
          child.ProductVariant.VariantToPackages = mapPackSearchForOffline(
            matchedChild.ProductVariant.VariantToPackages,
            child.ProductVariant.VariantToPackages,
          );
        }
      }
    });
  }

  if (exist.type === VariantTypes.Package) {
    exist.VariantToPackages = mapPackSearchForOffline(v.VariantToPackages, exist.VariantToPackages);
  }

  return exist;
};

export const getChildIds = (variants: Array<any>) => {
  let childIds: Array<any> = [];

  variants.forEach((r: any) => {
    if (r) {
      const extraIds = r.VariantExtraLocations ? r.VariantExtraLocations.map(
        (e: any) => e.Extra.productVariantId,
      ).filter((e: any) => e) : [];

      childIds = [
        ...childIds,
        ...(r.Product.VariantToComposites || []).map((c: any) => c.productVariantId),
        ...(r.VariantToPackages || []).map((p: any) => p.productVariantId),
        ...extraIds,
      ];
    }
  });

  return childIds;
};

export const getVariantLocationData = (stockLocationId: number,
  productVariantToStockLocations: Array<any> = [], force: boolean = false): Array<any> => {
  const [defaultLocation] = productVariantToStockLocations || [];
  const filteredLocation = productVariantToStockLocations.filter(
    (s: any) => s.stockLocationId === stockLocationId,
  );

  if (filteredLocation && filteredLocation.length > 0) {
    return filteredLocation;
  } if (defaultLocation && force) {
    return [{
      ...defaultLocation,
      quantity: 0,
      retailPrice: 0,
      wholeSalePrice: 0,
      buyPrice: 0,
    }];
  }

  return [];
};

export const mapPacksWithSearch = (packs: Array<any>, stockLocationId: number, priceConfiguration: ITaxConfiguration) => {
  packs.forEach((pack: any) => {
    getVariantLocationData(stockLocationId,
      pack.ProductVariant.ProductVariantToStockLocations, true).forEach((sl: any) => {
      const taxRate = sl.Tax && sl.Tax.TaxLines ? sl.Tax.TaxLines.reduce(
        (sum: number, tl: TaxLine) => sum + tl.rate, 0,
      ) : 0;
      return {
        ...sl,
        retailPrice: calculateInclusivePrice(sl.retailPrice,
          taxRate, priceConfiguration.sellTaxStatus),
        Tax: { ...sl.Tax, rate: taxRate },
      };
    });
  });
};

export const adjustOfflineVariantData = (
  r: any, priceConfiguration: any, stockLocationId: number,
) => {
  r.ProductVariantToStockLocations = getVariantLocationData(
    stockLocationId, r.ProductVariantToStockLocations, true,
  ).map((s: any) => {
      const taxRate = s.Tax ? s.Tax.rate : 0
    return {
        ...s,
        retailPrice: priceConfiguration?.sellTaxStatus === TAX_TYPES.EXCLUSIVE
          ? s.retailPrice : roundToFixedDigits(
            calculateInclusivePrice(s.retailPrice, taxRate, priceConfiguration?.sellTaxStatus), 2,
          ),
        wholeSalePrice: priceConfiguration?.sellTaxStatus === TAX_TYPES.EXCLUSIVE
          ? s.wholeSalePrice : roundToFixedDigits(
            calculateInclusivePrice(s.wholeSalePrice, taxRate, priceConfiguration?.sellTaxStatus), 2,
          ),
        cost: calculateInclusivePrice(s.cost, taxRate, priceConfiguration?.costTaxStatus),
        Tax: { ...s.Tax, rate: taxRate },
      };
    });

  // extras
  r.Extras = r.VariantExtraLocations?.map((extraLocation) => ({
    ...extraLocation.Extra,
  }));
  r.Extras.forEach(
    (extra) => (extra.VariantExtraLocations = r.VariantExtraLocations),
  );
  if (r.VariantExtraLocations) {
    r.VariantExtraLocations = r.VariantExtraLocations.filter(
      (ve: any) => ve.stockLocationId === stockLocationId,
    );
    r.VariantExtraLocations.forEach((ve: any) => {
      const tax = r.ProductVariantToStockLocations
      .find(location => location.stockLocationId === ve.stockLocationId)
      .Tax;
      const taxRate = tax && tax.TaxLines ? tax.TaxLines.reduce((sum: number, tl: TaxLine) => sum + tl.rate, 0) : 0;
      ve.price = priceConfiguration?.sellTaxStatus === TAX_TYPES.EXCLUSIVE
      ? ve.price : roundToFixedDigits(
        calculateInclusivePrice(ve.price, taxRate, priceConfiguration?.sellTaxStatus), 2,
      )
    });
  }

  // composite
  if (r.type === VariantTypes.Composite) {
    r.Product.VariantToComposites.forEach((child: any) => {
      child.ProductVariant.ProductVariantToStockLocations = getVariantLocationData(
        stockLocationId, child.ProductVariant.ProductVariantToStockLocations, true,
      ).map((sl: any) => {
        const taxRate = sl.Tax && sl.Tax.TaxLines
          ? sl.Tax.TaxLines.reduce((sum: number, tl: TaxLine) => sum + tl.rate, 0) : 0;
        return {
          ...sl,
          retailPrice: calculateInclusivePrice(
            sl.retailPrice, taxRate, priceConfiguration.sellTaxStatus,
          ),
          Tax: { ...sl.Tax, rate: taxRate },
        };
      });
      if (child.ProductVariant.type === VariantTypes.Package) {
        mapPacksWithSearch(
          child.ProductVariant.VariantToPackages, stockLocationId, priceConfiguration,
        );
      }
    });
  } else if (r.type === VariantTypes.Package) { // package
    mapPacksWithSearch(r.VariantToPackages, stockLocationId, priceConfiguration);
  }
};


export const getCurrentRegisterShiftKey = (
  shiftId: number,
  registerId: number,
): string => `offlineInvoiceSync-${shiftId}-${registerId}`;
