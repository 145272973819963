import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslateAppNamesService {
  constructor(private translateService: TranslateService) {}

  getTranslatedAppNames(appNames: string[]): string[] {
    return appNames.map((appName) => this.translateService.instant(appName));
  }
}
