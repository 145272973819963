/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionInvoice } from '../models/SubscriptionInvoice';
import { SubscriptionInvoiceFilter } from '../models/SubscriptionInvoiceFilter';
import { ChargebeePlan } from '../models/ChargebeePlan';
import {
  BankTransferDTO,
  BankTransferSubscriptionInfo,
  BillingHistoryRespDTO,
  BuildTapChargeRequestData,
  BuildTapInvoiceUrlDTO,
  ChargeDTO,
  DifferentialPriceDTO,
  DifferentialPricingRequestDTO,
  FeatureDTO,
  InvoiceLevelEstimateReqDTO,
  InvoiceLevelEstimateResDTO,
  InvoiceUploadDTO,
  ItemEntitlement,
  PlanDetailsDTO,
  ProratedChargeDTO,
  ProratedRequestDTO,
  RenewEstimateDTO,
} from '../components/my-subscription-v2/types';
import { PLAN_NAMES } from '../../shared/constants';

const API_URL = '/api';

const NUCLEUS_API_URL = '/api/nucleus';

@Injectable()
export class PlanSubscriptionService {
  constructor(private http: HttpClient) {}

  loadCouponById(id: string): Observable<any> {
    return this.http.get<any>(`${API_URL}/account-subscription/coupons/${id}`);
  }

  deactivateAccount(subscriptionId: string): Observable<any> {
    return this.http.delete<any>(
      `${API_URL}/account-subscription/deactivate/${subscriptionId}`,
    );
  }

  getInvoicesList(
    query: SubscriptionInvoiceFilter,
  ): Observable<SubscriptionInvoice[]> {
    const orderQueryParams = new HttpParams()
      .set(
        'from',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'to',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      );
    return this.http.get<SubscriptionInvoice[]>(
      `${API_URL}/account-subscription/invoices/${query.subscriptionId}`,
      { params: orderQueryParams },
    );
  }

  getCreditNoteList(
    query: SubscriptionInvoiceFilter,
  ): Observable<SubscriptionInvoice[]> {
    const orderQueryParams = new HttpParams()
      .set(
        'from',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'to',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      );
    return this.http.get<SubscriptionInvoice[]>(
      `${API_URL}/account-subscription/credit-notes`,
      { params: orderQueryParams },
    );
  }

  getPlansList(): Observable<ChargebeePlan[]> {
    return this.http.get<ChargebeePlan[]>(
      `${API_URL}/rewaa-subscription/plans`,
    );
  }

  getPlansListV2(): Observable<ChargebeePlan[]> {
    return this.http.get<ChargebeePlan[]>(
      `${API_URL}/account-subscription/items/`,
    );
  }

  estimate(subscription: any): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/account-subscription/estimate`,
      subscription,
    );
  }

  subscribe(subscription): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/account-subscription/subscribe`,
      subscription,
    );
  }

  getUnpaidInvoice(): Observable<any> {
    return this.http.get<any>(`${API_URL}/account-subscription/unpaid-invoice`);
  }

  getSubscription(subscriptionId): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/account-subscription/${subscriptionId}`,
    );
  }

  getInvoice(invoiceId): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/account-subscription/invoice/${invoiceId}`,
    );
  }

  getTapPaymentUrl(requestData): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/account-subscription/build-tap-charge-url`,
      requestData,
    );
  }

  sendTapInvoice(requestData): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/account-subscription/send-tap-invoice`,
      requestData,
    );
  }

  estimateRenewalSubscription(): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/account-subscription/estimate-renewal-subscription`,
    );
  }

  getRenewEstimationsAndGetTapPaymentUrl(FEhostUrl): Observable<any> {
    const orderQueryParams = new HttpParams().set('FEorigin', FEhostUrl);
    return this.http.get<any>(
      `${API_URL}/account-subscription/get-tap-renewal-url`,
      { params: orderQueryParams },
    );
  }

  chargeFutureRenewalSubscription(): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/account-subscription/charge-future-renewal-subscription`,
    );
  }

  startIntercomChat(query): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/rewaa-subscription/startIntercomChat`,
      query,
    );
  }

  getIntercomUser(query): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/rewaa-subscription/sendBankTransaction`,
      query,
    );
  }

  getSubscriptionObjectBySubscriptionId(
    subscriptionId: string,
  ): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/account-subscription/subscription/${subscriptionId}`,
    );
  }

  getSubscriptionDetailsBySubscriptionId(
    subscriptionId: string,
  ): Observable<any> {
    return this.http.get<PlanDetailsDTO>(
      `${API_URL}/account-subscription/active-subscription-details/${subscriptionId}`,
    );
  }

  getDifferentialPricing(stateObj: DifferentialPricingRequestDTO): Observable<{
    planEstimates: ProratedChargeDTO[];
    addonPricing: DifferentialPriceDTO[];
  }> {
    return this.http.post<{
      planEstimates: ProratedChargeDTO[];
      addonPricing: DifferentialPriceDTO[];
    }>(`${API_URL}/account-subscription/checkout-details`, stateObj);
  }

  getProratedCharges(param: ProratedRequestDTO): Observable<{
    estimatedItems: ProratedChargeDTO[];
    totalCredits: number;
    promotionalCredit: number;
    invoiceLevelCouponDiscount: number;
    couponApplicabilityStatus: string;
  }> {
    return this.http.post<{
      estimatedItems: ProratedChargeDTO[];
      totalCredits: number;
      invoiceLevelCouponDiscount: number;
      promotionalCredit: number;
      couponApplicabilityStatus: string;
    }>(`${API_URL}/account-subscription/checkout/estimate/v2`, param);
  }

  getRenewEstimate(param: RenewEstimateDTO): Observable<{
    estimatedItems: ProratedChargeDTO[];
    totalCredits: number;
    promotionalCredit: number;
    invoiceLevelCouponDiscount: number;
    couponApplicabilityStatus: string;
  }> {
    return this.http.post<{
      estimatedItems: ProratedChargeDTO[];
      totalCredits: number;
      promotionalCredit: number;
      invoiceLevelCouponDiscount: number;
      couponApplicabilityStatus: string;
    }>(`${API_URL}/account-subscription/v2/renew/estimate`, param);
  }

  getTapPaymentUrlV2(requestData): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/account-subscription/v2/build-tap-charge-url`,
      requestData,
    );
  }

  updateFreeSubscription(requestData): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/account-subscription/v2/free-subscription`,
      requestData,
    );
  }

  getTapRenewalPaymentUrlV2(requestData: {
    redirectURL: string;
  }): Observable<any> {
    const options = {
      params: new HttpParams().set('redirectURL', requestData.redirectURL),
    };

    return this.http.get<any>(
      `${API_URL}/account-subscription/v2/get-tap-renewal-url`,
      options,
    );
  }

  uploadBankInvoice(
    receipt: string,
    companyName: string,
  ): Observable<InvoiceUploadDTO> {
    return this.http.post<any>(`${API_URL}/checkout/bank-transfer/receipt`, {
      receipt,
      companyName,
    });
  }

  postBankTransfer(payload: BankTransferDTO): Observable<void> {
    return this.http.post<any>(`${API_URL}/checkout/bank-transfer`, payload);
  }

  getBankTransferSubscriptionInfo(
    subscriptionId: string,
  ): Observable<BankTransferSubscriptionInfo> {
    return this.http.get<any>(
      `${API_URL}/checkout/bank-transfer/get-subscription-info/${subscriptionId}`,
    );
  }

  getTapChargeDetailsByChargeId(chargeId: string): Observable<ChargeDTO> {
    return this.http.get<ChargeDTO>(
      `${API_URL}/account-subscription/v2/get-tap-charge-by-id/${chargeId}`,
    );
  }

  getAllFeatureDetails(): Observable<FeatureDTO[]> {
    return this.http.get<FeatureDTO[]>(
      `${API_URL}/account-subscription/item-ids/feature`,
    );
  }

  getItemEntitlements(itemId: string): Observable<ItemEntitlement[]> {
    return this.http.get<ItemEntitlement[]>(
      `${API_URL}/account-subscription/items/${itemId}/entitlements`,
    );
  }

  estimateWithInvoiceLevelCoupon(
    param: InvoiceLevelEstimateReqDTO,
  ): Observable<InvoiceLevelEstimateResDTO> {
    return this.http.post<InvoiceLevelEstimateResDTO>(
      `${API_URL}/account-subscription/checkout/invoice-coupon-estimate`,
      param,
    );
  }

  getPaymentLinkForMobile(
    payload: BuildTapChargeRequestData,
  ): Observable<BuildTapInvoiceUrlDTO> {
    return this.http.post<any>(
      `${API_URL}/account-subscription/v2/send-tap-invoice`,
      payload,
    );
  }

  shouldDisableRenewButton(planPeriod: number, nextBillingDate: Date): boolean {
    const TIME_IN_YEARS = 1000 * 3600 * 24 * 365;
    const diffInYears =
      (new Date(nextBillingDate).getTime() - new Date().getTime()) /
      TIME_IN_YEARS;
    return planPeriod < parseFloat(diffInYears.toFixed(2));
  }

  isTrialEndOrPlanEnd(subscription): string {
    return subscription.currentTermStart ? PLAN_NAMES.PLAN : PLAN_NAMES.TRIAL;
  }

  getBillingHistoryBySubscriptionId(
    id: string,
    isStcManager: boolean,
  ): Observable<BillingHistoryRespDTO> {
    const queryParams = new HttpParams().set('isStcManager', isStcManager);
    return this.http.get<any>(
      `${API_URL}/account-subscription/billing-history/subscriptions/${id}`,
      { params: queryParams },
    );
  }

  pushChargeBeeInvoiceToZatca(invoiceId: string): Observable<void> {
    return this.http.post<void>(
      `${NUCLEUS_API_URL}/zatca-invoicing/invoice/${invoiceId}`,
      {},
    );
  }

  pushChargeBeeCreditNoteToZatca(creditNoteId: string): Observable<void> {
    return this.http.post<void>(
      `${NUCLEUS_API_URL}/zatca-invoicing/credit-note/${creditNoteId}`,
      {},
    );
  }
}
