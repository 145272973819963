import { Injectable } from '@angular/core';
import pako from 'pako';

@Injectable()
export class CompressorService {
  async unCompressGzipToJSON(data: Uint8Array) {
    return new Promise((resolve) => {
      const unCompressed = pako.ungzip(data, { to: 'string' });
      return resolve(JSON.parse(unCompressed));
    });
  }

  unCompressGzipToJSONSync<T>(data: Uint8Array | number[]): T | unknown {
    return JSON.parse(pako.ungzip(data, { to: 'string' }));
  }
}
