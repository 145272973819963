/* eslint-disable no-param-reassign */
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl, FormArray, FormBuilder, FormGroup, Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import { ProductVariant } from 'src/app/shared/model/product-variant';
import { VariantToTrack } from 'src/app/shared/model/VariantToTrack';
import { dateLessThan } from 'src/app/shared/validators/dates.validator';
import { LocalStorageKey } from '../../constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';

@Component({
  selector: 'rw-batched-products',
  templateUrl: './batched-products.component.html',
  styleUrls: ['./batched-products.component.scss'],
})
export class BatchedProductsComponent implements OnInit {
  @Input() variants: ProductVariant[] = [];

  @Input() locationId: number;

  @Input() invoiceType: string;

  trackFormArray: FormArray = new FormArray([]);

  trackNoFocus$: Subject<any>;

  langCss: string;

  tempId = 0;

  subscriptionRefs;

  formatter = (x: VariantToTrack) => x.trackNo;

  searchResults: any[];

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    this.trackNoFocus$ = new Subject();
  }

  ngOnInit(): void {
    this.variants.forEach((variant) => {
      this.initTrackFormForVariant(variant);
    });
    const lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.langCss = lng === 'en' ? 'ltr' : 'rtl';
    this.translateService.setDefaultLang(lng);
  }

  getTrackedVariant() {
    return this.variants;
  }

  initTrackFormForVariant(variant) {
    if (!variant.VariantToInvoiceTracks?.length) {
      return this.addStockLocationPrice(variant.id, null);
    }

    variant.VariantToInvoiceTracks.forEach((vt) => {
      const variantToTrackNo = vt.trackNo;
      const variantToInvoiceTrack = this.getVariantTrackByTrackNo(
        variantToTrackNo,
        variant.id,
      ).shift();

      this.addStockLocationPrice(
        variant.id,
        variantToInvoiceTrack,
        vt.quantity,
      );
    });
  }

  addStockLocationPrice(variantId, track, trackQty?): void {
    this.trackFormArray.push(this.createTrackForm(variantId, track, trackQty));
  }

  createTrackForm(variantId, track, trackQty?): FormGroup {
    const today = new Date();
    this.tempId += 1;
    const variant = this.variants.find((v) => v.id === variantId);

    const productVariantToStockLocation = variant.ProductVariantToStockLocations.find(
      (stock) => stock.stockLocationId === this.locationId,
    );
    let productVariantToStockLocationId;

    if (productVariantToStockLocation) {
      productVariantToStockLocationId = productVariantToStockLocation.packageStockId
        ? productVariantToStockLocation.packageStockId
        : productVariantToStockLocation.id;
    } else {
      productVariantToStockLocationId = null;
    }

    const newTrackform = this.formBuilder.group(
      {
        id: [],
        tempId: [this.tempId],
        quantity: [trackQty ?? 0, Validators.min(1)],
        issueDate: [today.toISOString().substring(0, 10)],
        availableQuantity: [],
        expiryDate: [],
        isSerial: false,
        stockLocationId: [this.locationId],
        variantToTrackId: [],
        productVariantToStockLocationId: [productVariantToStockLocationId],
        productVariantId: [variantId, Validators.required],
        packageProductVariantId: [variant.packageProductVariantId],
        trackNo: [null, [Validators.required, this.validateCode.bind(this)]],
      },
      { validator: dateLessThan('issueDate', 'expiryDate') },
    );

    if (track) {
      newTrackform.get('trackNo').patchValue(track);
    }

    this.subscriptionRefs = newTrackform
      .get('quantity')
      .valueChanges.subscribe(() => {
        const updatedVariant = this.variants.find(
          (v) => v.id === variantId,
        );
        this.validateAssignedQuantity(updatedVariant);
      });

    return newTrackform;
  }

  getVariantToInvoiceQuantity(variant) {
    let quantity = variant['New Quantity'];

    if (variant.packageRate) {
      quantity *= variant.packageRate;
    }

    return +quantity;
  }

  validateAssignedQuantity(variant) {
    const locationQty = this.getVariantToInvoiceQuantity(variant);
    const qty = this.getVariantTrackAddedQuantity(variant.id);
    return locationQty !== qty;
  }

  getVariantTrackAddedQuantity(variantId) {
    const variantTrack = this.getVariantTrackFormArray(variantId);
    const qty = +variantTrack.reduce(
      (a, b) => InvoicesCalculator.add(a, b.get('quantity').value),
      0,
    );
    return qty;
  }

  getVariantTrackFormArray(variantId) {
    return this.trackFormArray.controls.filter(
      (track) => track.value.productVariantId === variantId,
    );
  }

  getVariantById(variantId) {
    return this.variants.find((variant) => variant.id === variantId);
  }

  validateCode(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      const variantId = control.parent.get('productVariantId').value;
      let newTrackNo = control.value;
      const variant = this.getVariantById(variantId);

      if (!control.value.id) {
        const variantTracks = variant.ProductVariantToStockLocations.find(
          (stock) => stock.stockLocationId === this.locationId,
        ).VariantToTracks;
        const isValidTrack = variantTracks.find(
          (track) => track.trackNo === newTrackNo,
        );

        if (isValidTrack) {
          control.patchValue(isValidTrack);
          newTrackNo = isValidTrack;
        } else {
          control.parent.get('id').patchValue(null);
          control.parent.get('variantToTrackId').patchValue(null);
          control.parent.get('availableQuantity').patchValue(null);
          control.parent.get('expiryDate').enable();
          control.parent.get('issueDate').enable();
          return { notAvilable: true };
        }
      }

      const variantTrackFormArray = this.trackFormArray.controls.filter(
        (track) => track.value.productVariantId === variantId
          && track.value.trackNo === newTrackNo,
      );
      if (
        variantTrackFormArray.length > 0
        && variantTrackFormArray[0].get('tempId').value
          !== control.parent.get('tempId').value
      ) {
        return { Duplicated: true };
      }

      let variantTracks = [];

      variantTracks = variant.ProductVariantToStockLocations.find(
        (stock) => stock.stockLocationId === this.locationId,
      ).VariantToTracks;

      if (!variantTracks) return null;

      variantTracks = variantTracks.slice(0);

      newTrackNo = control.value.trackNo;

      const savedTrack = variantTracks.find(
        (track) => track.trackNo === newTrackNo,
      );

      if (savedTrack && !savedTrack.quantity) {
        return { unAvailableSerial: true };
      }

      if (savedTrack) {
        control.parent
          .get('expiryDate')
          .patchValue(
            savedTrack.expiryDate
              ? new Date(savedTrack.expiryDate).toISOString().substring(0, 10)
              : null,
          );
        control.parent
          .get('issueDate')
          .patchValue(
            savedTrack.issueDate
              ? new Date(savedTrack.issueDate).toISOString().substring(0, 10)
              : null,
          );
        control.parent.get('expiryDate').disable();
        control.parent.get('issueDate').disable();
        control.parent.get('availableQuantity').patchValue(savedTrack.quantity);
        control.parent.get('id').patchValue(savedTrack.id);
        control.parent.get('variantToTrackId').patchValue(savedTrack.id);
      } else {
        control.parent.get('id').patchValue(null);
        control.parent.get('availableQuantity').patchValue(null);
        control.parent.get('variantToTrackId').patchValue(null);
        control.parent.get('expiryDate').enable();
        control.parent.get('issueDate').enable();
      }
    }
    return null;
  }

  public searchTrack($variantId: any): (text: Observable<string>) => Observable<any[]> {
    const search = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => this.getVariantTracks($variantId)
        .filter((v) => v.trackNo.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)),
    );

    return search;
  }

  getVariantTracks(variantId) {
    const variant = this.variants.find((v) => v.id === variantId);
    const variantToTracks = variant.ProductVariantToStockLocations
      .find((stock) => stock.stockLocationId === this.locationId);
    return variantToTracks ? variantToTracks.VariantToTracks : [];
  }

  validateFormQuantity() {
    for (let index = 0; index < this.variants.length; index += 1) {
      const variant = this.variants[index];

      const locationQty = this.getVariantToInvoiceQuantity(variant);

      const variantTrack = this.getVariantTrackFormArray(variant.id);
      let qty = 0;
      for (let vtIndex = 0; vtIndex < variantTrack.length; vtIndex += 1) {
        const track = variantTrack[vtIndex];

        if (
          track.get('quantity').value > track.get('availableQuantity').value
        ) {
          track.get('quantity').setErrors({ unavailableQuantity: true });
          return false;
        }
      }

      qty = variantTrack.reduce(
        (a, b) => InvoicesCalculator.add(a, b.get('quantity').value),
        0,
      );

      const variantTrackValues = this.trackFormArray
        .getRawValue()
        .filter((track) => track.productVariantId === variant.id);

      variantTrackValues.forEach((track) => {
        if (typeof track.trackNo === 'object' && track.trackNo !== null) {
          track.trackNo = track.trackNo.trackNo;
        }
      });

      variant.VariantToInvoiceTracks = variantTrackValues;

      variant.VariantToInvoiceTracks.forEach((track) => {
        if (track.packageProductVariantId) {
          track.invoiceVariantid = track.productVariantId;
          track.productVariantId = track.packageProductVariantId;
        }
      });

      if (locationQty !== qty) return false;
    }
    return true;
  }

  getVariantTrackByTrackNo(trackNo, variantId) {
    return this.getVariantTracks(variantId).filter(
      (v) => v.trackNo.toLowerCase() === trackNo,
    );
  }

  removeBatch(tempId: number): void {
    const index = this.trackFormArray.controls.findIndex((x) => x.value.tempId === tempId);
    if (index >= 0) {
      this.trackFormArray.removeAt(index);
    }
  }

  isBatchRemovable(variantId) {
    const trackFormArr = this.getVariantTrackFormArray(variantId);
    return trackFormArr.length > 1;
  }

  onSearchTrack(event, variantId) {
    this.searchTrack(variantId)(of(event.query))
    .pipe(take(1))
    .subscribe((res) => { this.searchResults = res; });
  }
}
