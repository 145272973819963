import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RewaaBaseApiResponse } from '@rewaa-team/types';
import { Country } from '../../shared/model/country';
import { Register } from '../../internal-apps/pos/model/Register';
import { StockLocation } from '../../shared/model/StockLocation';
import { ExternalStockLocation } from '../../shared/model/ExternalStockLocation';
import { SubscriptionAttributes } from '../../shared/model/MerchantAppSubscription';
import { ExternalAppReference } from '../../shared/model/ExternalAppReference';

const API_URL = '/api';

const enigmaUrl = '/api/enigma';
@Injectable()
export class LocationService {
  constructor(private http: HttpClient) {
  }

  getLocationsWithSearch(filters): Observable<any> {
    const promotionProduct = new HttpParams()
      .set('name', filters.name || '');
    return this.http.get<any>(
      `${API_URL}/promotions/stock-locations/`, { params: promotionProduct },
    );
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${API_URL}/lookups/countries`).pipe(
      map((response) => response.map((country) => new Country(country))),
    );
  }

  getStockLocations(inPos?): Observable<StockLocation[]> {
    const params = new HttpParams()
      .set('inPOS', `${inPos}`);
    return this.http.get<Country[]>(`${API_URL}/stock-location`, { params })
      .pipe(
        map((response) => response.map((location) => new StockLocation(location))),
      );
  }

  getAttributeValue = (attributes: SubscriptionAttributes[], attName: string): string => attributes
    .find((attribute) => attribute.name === attName).value;

  getPosExternalStockLocations(
    attributes: SubscriptionAttributes[],
  ): Observable<ExternalStockLocation[]> {
    const apiKey = this.getAttributeValue(attributes, 'apiKey');
    const secretKey = this.getAttributeValue(attributes, 'secretKey');
    return this.http.get<ExternalStockLocation[]>(`${API_URL}/apps/subscriptions-pos/external-locations/all?apiKey=${apiKey}&secretKey=${secretKey}`);
  }

  getPosExternalMainStockLocations(
    attributes: SubscriptionAttributes[],
  ): Observable<ExternalStockLocation> {
    const apiKey = this.getAttributeValue(attributes, 'apiKey');
    const secretKey = this.getAttributeValue(attributes, 'secretKey');
    return this.http.get<ExternalStockLocation>(`${API_URL}/apps/subscriptions-pos/external-locations/main?apiKey=${apiKey}&secretKey=${secretKey}`);
  }

  getLocationsExternalAppReferences(appId: number): Observable<ExternalAppReference[]> {
    return this.http.get<ExternalAppReference[]>(`${API_URL}/apps/subscriptions/locations-reference/${appId}`);
  }

  getActiveStockLocations(): Observable<StockLocation[]> {
    return this.http.get<StockLocation[]>(`${API_URL}/stock-location/active`);
  }

  getStockLocationByCode(code: string): Observable<StockLocation> {
    return this.http.get<StockLocation>(`${API_URL}/stock-location/code/${code}`);
  }

  getPOSRegisters(id: number) {
    return this.http.get<Register[]>(`${API_URL}/stock-location/${id}/pos/registers`);
  }

  activateOrDeactivatePOSOnLocation(id: number, action: 'activate' | 'deactivate') {
    return this.http.put<StockLocation>(`${API_URL}/stock-location/action/${id}/pos/${action}`, {});
  }
}
