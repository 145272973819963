import { quantityCalculator } from '../../../services/calculation-service';
import { ProductVariantType } from '../enum/product-enum';
import { IProductFactory } from '../interfaces/product-interface';
import {
  CompositeStockLocation,
  ProductVariantStock,
  VariantQuantityAndCost,
} from '../types/product-types';
import { PackageProduct } from './package-product';

export class CompositeProduct implements IProductFactory {
  // eslint-disable-next-line class-methods-use-this
  getVariantQuantityAndCostOfProduct({
    variant,
    variantDetails,
  }: VariantQuantityAndCost): ProductVariantStock[] {
    const { quantityChanged, stockLocationId } = variant;

    const productVariantStocks: ProductVariantStock[] = [];
    for (let index = 0; index < variantDetails.length; index++) {
      const detail = variantDetails[index];
      let variantQuantity = detail.rate * quantityChanged;
      // eslint-disable-next-line prefer-destructuring
      let variantId = detail.productVariantId;
      if (!detail.productVariant) {
        throw new Error(
          'Product Variant type is required in case of composite product',
        );
      }
      if (detail.productVariant.type === ProductVariantType.package) {
        const packed = new PackageProduct().getVariantQuantityAndCostOfProduct({
          variant: {
            ...variant,
            stockLocationId,
            productVariantId: detail.productVariantId,
            quantityChanged: quantityCalculator.multiply(
              detail.rate,
              quantityChanged,
            ),
          },
          variantDetails: [
            {
              productVariantId: detail.productVariant.productVariantId,
              rate: detail.productVariant.rate,
            },
          ],
        });
        variantQuantity = packed[0].quantityChanged;
        variantId = packed[0].productVariantId;
      }
      productVariantStocks.push({
        ...variant,
        productVariantId: variantId,
        stockLocationId,
        newCost: undefined,
        quantityChanged: variantQuantity,
      });
    }
    return productVariantStocks;
  }

  static calculateCompositeQuantity(variant: CompositeStockLocation) {
    const currentComposite: {
      [x: string]: number;
    } = {};
    variant.productToComposite.forEach((c) => {
      c.variantToLocation.forEach((curr) => {
        const quantity = curr.quantity * c.rate;
        currentComposite[`${variant.id}-${curr.stockLocationId}`] = Math.min(
          currentComposite[`${variant.id}-${curr.stockLocationId}`] || Infinity,
          quantity,
        );
      });
    });
    return currentComposite;
  }
}
