import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Setting } from '../models/order-setting-model';
import { OrderSettingHttpService } from './order-setting-http.service.interface';

@Injectable()
export class OrderSettingsService implements OrderSettingHttpService {
  private API_URL = '/api';

  constructor(private http: HttpClient) {}

  updateSettings(settings: Setting[]): Observable<void> {
    return this.http.post<void>(`${this.API_URL}/orders/settings`, settings);
  }

  getSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(`${this.API_URL}/orders/settings`);
  }

  getProductsWithoutPrices(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.API_URL}/pos/locations/products-without-price`,
    );
  }
}
