import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MenuItem } from './types/menu-item';

@Component({
  selector: 'rw-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() items: MenuItem[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.items = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.translateItems().subscribe((e) => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    if (!this.isMenuItemsOverridden()) {
      this.buildBreadcrumbsOfRoute(this.activatedRoute.snapshot);
    }
    this.translateItems().subscribe((e) => {
      this.changeDetectorRef.markForCheck();
    });
  }

  private translateItems(): Observable<boolean> {
    const translateWordsObservables: Observable<string>[] = [];
    const translatedBreadcrumbMenuItems = [];
    this.items.forEach((breadcrumbMenuItem) => {
      translateWordsObservables.push(
        this.translateService.get(breadcrumbMenuItem.label).pipe(
          tap((translatedLabel) => {
            translatedBreadcrumbMenuItems.push({
              ...breadcrumbMenuItem,
              label: translatedLabel,
            });
          }),
        ),
      );
    });
    return forkJoin(translateWordsObservables).pipe(
      map((e) => {
        this.items = translatedBreadcrumbMenuItems;
        return true;
      }),
    );
  }

  private isMenuItemsOverridden() {
    return this.items && this.items.length > 0;
  }

  private buildBreadcrumbsOfRoute(
    activatedRouteSnapShot: ActivatedRouteSnapshot,
  ) {
    if (
      activatedRouteSnapShot.parent
      && activatedRouteSnapShot.parent.data
      && activatedRouteSnapShot.parent.data.breadcrumb
    ) {
      this.buildBreadcrumbsOfRoute(activatedRouteSnapShot.parent);
    }
    this.appendIntoBreadcrumbs(activatedRouteSnapShot);
  }

  private appendIntoBreadcrumbs(
    activatedRouteSnapShot: ActivatedRouteSnapshot,
  ) {
    if (
      !activatedRouteSnapShot.routeConfig.data
      || !activatedRouteSnapShot.routeConfig.data?.breadcrumb
    ) return;
    const { breadcrumb } = activatedRouteSnapShot.routeConfig.data;
    const routerLink: string[] = [];
    if (breadcrumb.url) {
      routerLink.push(breadcrumb.url);
    } else {
      routerLink.push(this.resolveUrlOfRouteSnapshot(activatedRouteSnapShot));
    }
    const currentBreadcrumb: MenuItem = {
      label: activatedRouteSnapShot.routeConfig.data.breadcrumb.title,
      routerLink,
    };
    this.items.push(currentBreadcrumb);
  }

  private resolveUrlOfRouteSnapshot(
    activatedRouteSnapShot: ActivatedRouteSnapshot,
  ) {
    let url = '/';
    activatedRouteSnapShot.pathFromRoot.forEach((e) => {
      if (e.url.length > 0) {
        e.url.forEach((segment) => {
          url += `${segment.path}/`;
        });
      }
    });
    return url;
  }
}
