import { Subscription, timer } from 'rxjs';

export const isDefined = (val) => val !== null && val !== undefined;

export const debounce = (fn, dely) => {
  let subscription: Subscription;

  return function (...args) {
    const context = this;
    if (subscription) {
      subscription.unsubscribe();
    }
    subscription = timer(dely).subscribe(() => {
      fn.call(context, ...args);
    });
  };
};

export const isObjectEmpty = (obj = {}) => Object.keys(obj).length === 0
  && obj.constructor === Object;

export const inventoryValueFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' k' },
    { value: 1e6, symbol: ' M' },
    { value: 1e9, symbol: ' G' },
    { value: 1e12, symbol: ' T' },
    { value: 1e15, symbol: ' P' },
    { value: 1e18, symbol: ' E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((rawItem) => num >= rawItem.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const transformToPendoTrackerId = (name: string) => name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');

export const roundToDecimal = (num: number, decimalPlaces: number) => (Math.round((num + Number.EPSILON) * (10 ** decimalPlaces)) / (10 ** decimalPlaces));
