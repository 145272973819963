<img class="none md:flex position-absolute top-0 pointer-events-none circle" [ngClass]="lang==='ar'?'left-0 flip-image':'right-0'" src="../../../../assets/icons/circle-bg-transparent.svg"/>
<div class="hidden md:flex auth-content-area text-white flex-column flex-grow-1">
  <div class="hidden md:flex auth-content flex-grow-1 align-items-start justify-content-between w-full flex-row-reverse p-3 md:py-2 md:px-4 xl:p-0 md:mt-2 xl:mt-8">
    <div
      class="hidden md:flex align-items-start auth-content-text justify-content-between w-full md:flex-column gap-4 px-0 xl:px-6"
      [ngClass]="changeLang !== 'ar' ? 'md:text-right xl:pr-8' : 'md:text-left xl:pl-8'">
      <div class="d-flex flex-column align-items-start">
        <h2 id="title">{{ translationKey+'yourBusinessFuture' | translate }}</h2>
        <p style="background-color: rgba(157,239,239,.15); font-size:24px;" class="bg-teal-30 mb-0 p-2">{{ translationKey+'technicalPartner' | translate }}</p>
      </div>
      <div
        class="d-flex align-items-start auth-content-text justify-content-between w-full md:flex-column"
        [ngClass]="changeLang !== 'ar' ? 'md:text-right' : 'md:text-left'">
        <div class="d-flex flex-column align-items-center w-full md:px-4 lg:px-4 xl:px-8 py-4 testimonial">
          <h3 class="my-3">{{ translationKey+'customerExperience' | translate }}</h3>
          <span>
            <p class="text-center" [ngClass]="lang === 'en' ? 'font-italic' : '' ">{{ translationKey+'testimonialDescription' | translate }}</p>
          </span>
          <p>{{ translationKey+'businessOwner' | translate }}</p>
        </div>
      </div>
      <div
        class="d-flex align-items-start auth-content-text justify-content-between w-full md:flex-column"
        [ngClass]="changeLang !== 'ar' ? 'md:text-right' : 'md:text-left'">
        <div class="d-flex flex-column align-items-center w-full gap-4">
          <h3 class="text-center px-6 text-grey-30">{{ translationKey+'join' | translate }}</h3>
          <div class="flex flex-wrap gap-2 w-full justify-content-around users-icon">
            <img src="../../../../assets/icons/bazil.png"/>
            <img src="../../../../assets/icons/lamha.svg"/>
            <img src="../../../../assets/icons/umbro.svg"/>
            <img src="../../../../assets/icons/saroot.svg"/>
            <img src="../../../../assets/icons/nabataty.png"/>
          </div>
        </div>
      </div>
      <span style="border-bottom: 1px solid #5C7070; opacity:0.3; height:1px; width:100%; margin: 1rem 0"></span>
    </div>
  </div>
</div>