<div class="flex flex-column gap-4 py-5">
  <div class="flex flex-column gap-2">
    <label class="font-bold text-2xl mb-0">{{ 'auth.signUpTitle' | translate }} 🚀</label>
    <p class="font-normal text-lg mb-0">{{ 'auth.signUpSubTitle' | translate }}</p>
  </div>

  <!-- Email Already Used Warning - Start -->
  <div *ngIf="userAlreadyExists" class="border-1 border-red-40 rw__notification-error">
    <div class="notification-body align-items-start">
        <i class="border-3 border-red-30 rounded-circle fa-regular fa-circle-exclamation rw__fs-5"></i>
        <div>
            <p class="rw__m-0">
                {{ 'auth.emailAlreadyUsed' | translate }}
                <a [routerLink]="['login']" class="rw__link">{{ 'auth.tryingToSignIn' | translate }}</a>
            </p>
        </div>
    </div>
  </div>
  <!-- Email Already Used Warning - End -->

  <div class="flex flex-column gap-3">

    <form class="flex flex-column gap-3" [formGroup]="signUpForm" #ngForm (ngSubmit)="submitSignUp()">
      <!-- License Key - Start -->
      <div class="flex flex-column">
        <div class="d-flex align-items-center gap-2">
          <span (click)="handleShowLicenseKey()" class="cursor-pointer text-teal-60 ">{{'auth.haveLicenseKey'|translate}}</span>
          <div>
            <button type="button" (click)="openLicenseKeyDialog()" class="text-grey-60 bg-transparent border-0 rw__p-0">
              <i class="fa-regular fa-circle-info"></i>
            </button>
          </div>
        </div>
        <span *ngIf="showLicenseKey" class="rw__input rw__input-default rw__mt-2" [ngClass]="lang === 'ar' ? 'rw__input-mask' : ''">
          <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
            <i class="fa-regular fa-key"></i>
            <p-inputMask  mask="99999 - 99999 - 99999 - 99999" placeholder="XXXXX - XXXXX - XXXXX - XXXXX" slotChar="x"
              [unmask]="true" [autoClear]="false" [formControl]="licenseKey" [showClear]="true" pendo-tracker="sign_up_license_key_field_typing"
              (onComplete)="onLicenseKeyComplete()" (keyup)="validateLicenseKeyInput()" (onBlur)="validateLicenseKeyInput()" (onClear)="clearLicenseKey()">
              <input type="text" pInputText/>
            </p-inputMask>
          </span>
          <ng-container *ngIf="licenseKey.touched && licenseKey.invalid">
            <div class="flex align-items-center mt-1">
              <i class="fa-solid fa-circle-exclamation p-error"></i>
              <small *ngIf="licenseKey.errors.minlength || licenseKey.errors.maxlength" class="p-error block mx-1">
                {{ 'auth.licenseLengthError' | translate }}
              </small>
              <small *ngIf="licenseKey.errors.notValid" class="p-error block mx-1">
                {{ 'auth.licenseNotValidError' | translate }}
              </small>
            </div>
          </ng-container>
          <small class="rw__fs-10 text-grey-50 block rw__mt-2">
            {{ 'auth.youCanFindLicenseKeyMessage' | translate }}
          </small>
        </span>
      </div>
      <!-- License Key - End -->

      <!-- Company Name - Start -->
      <div>
        <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
          <i class="fa-regular fa-store"></i>
          <input name="companyName" type="text" pInputText placeholder="{{ 'Company' | translate }}" 
            [formControl]="companyName" context="register" [required]="true" />
        </span>
        <ng-container *ngIf="companyName.invalid && companyName.touched">
          <div class="flex align-items-center mt-1">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small *ngIf="companyName.errors.required" class="p-error block mx-1">{{ 'auth.cRequired' | translate }}</small>
          </div>
        </ng-container>
      </div>
      <!-- Company Name - End -->

      <!-- Email - Start -->
      <div>
        <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
          <i class="fa-regular fa-at"></i>
          <input name="email" type="text" pInputText placeholder="{{ 'Email' | translate }}" 
            [formControl]="email" context="register" [required]="true" />
        </span>
        <ng-container *ngIf="email.invalid && email.touched">
          <div class="flex align-items-center mt-1">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small *ngIf="email.errors.required" class="p-error block mx-1">{{ 'auth.emailRequired' | translate }}</small>
            <small *ngIf="email.errors.pattern" class="p-error block mx-1">{{ 'auth.emailInvalid' | translate }}</small>
          </div>
        </ng-container>
      </div>
      <!-- Email - End -->

      <!-- Phone - Start -->
      <div class="flex flex-column">
        <div class="flex gap-2 ltr">
          <span class="rw__input rw__input-float p-float-label minw-0 w-14rem" pendo-tracker="sign_up_country_code_drop_down_clicked">
            <p-dropdown panelStyleClass="rw__input-country-dropdown" [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="localeName" [autoDisplayFirst]="false"
              (onChange)="onCountrySelect()" [ngModelOptions]="{standalone: true}" appendTo="body">
              <ng-template pTemplate="filter" let-options="options">
                <div class="d-flex">
                  <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full" (click)="$event.stopPropagation()" [ngClass]="{'rw__input-icon-close': filterValue}">
                    <i class="fa-regular fa-magnifying-glass"></i>
                    <input type="text" pInputText placeholder="{{ 'Search' | translate }}" [(ngModel)]="filterValue" [ngModelOptions]="{standalone: true}" (keyup)="options.filter($event)">
                    <i class="fa-regular fa-xmark" (click)="myResetFunction(options)"></i>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="selectedItem">
                  <div class="flex align-item-center" *ngIf="selectedCountry">
                    <img class="border-round" src="https://flagsapi.com/{{selectedCountry.countryKey}}/flat/32.png" alt="{{ selectedCountry.name }} Flag" width="22" />
                    <span class="rw__ms-3">+{{selectedCountry.code}}</span>
                  </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                  <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <span>{{ country.localeName }}</span>
                        <span class="rw__ms-2 ltr">(+{{country.code}})</span>
                      </div>
                  </div>
              </ng-template>
            </p-dropdown>
          </span>
          <span class="rw__input rw__input-default minw-0 w-full">
            <input name="email" type="text" pInputText placeholder="{{ '5x xxx xxxx' | translate }}" 
              [formControl]="phone" autocomplete="one-time-code" pendo-tracker="sign_up_phone_number_field_typing" />
          </span>
        </div>
        <ng-container *ngIf="phone.invalid && phone.touched">
          <div class="d-flex flex-column">
            <div *ngIf="phone.errors.required" class="d-flex rw__mt-2">
              <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
              <small class="p-error block mx-1">{{ 'auth.phoneRequired' | translate }}</small>
            </div>
            <div *ngIf="!phone.errors.required">
              <div *ngIf="phone.errors.zeroSaudiPattern" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.zeroSaudiPattern' | translate }}</small>
              </div>
              <div *ngIf="phone.errors.saudiPattern" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.saudiPattern' | translate }}</small>
              </div>
              <div *ngIf="phone.errors.saudiZeroOrZeroFive" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.saudiZeroOrZeroFive' | translate }}</small>
              </div>
              <div *ngIf="phone.errors.otherThanSaudiPattern" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.phoneErrorNotSaudiPattern' | translate }}</small>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Phone - End -->

      <!-- Agree - Start -->
      <div>
        <div class="flex align-items-start">
          <p-checkbox 
            formControlName="isTermsAndPrivacyAccepted" 
            [binary]="true"
            inputId="isTermsAndPrivacyAccepted" 
            class="rw__me-3"
          />
          <label for="isTermsAndPrivacyAccepted" class="rw__mb-0">
            {{ 'I agree to Rewaa' | translate}}
            <a [href]="getTermsAndConditionsHRef()" target="_blank" class="rw__link">
              {{ 'Terms & Conditions' | translate }}
            </a>
            {{ 'and Rewaas' | translate }}<a [href]="getPrivacyPolicyHRef()" target="_blank" rel="noopener noreferrer" class="rw__link">
              <ng-container>{{ 'Privacy Policy' | translate }}.</ng-container>
            </a>
          </label>
        </div>
        <ng-container *ngIf="termsWarning">
          <div class="flex mt-1">
            <i class="fa-solid fa-circle-exclamation p-error mt-1"></i>
            <small class="p-error block mx-1">
              {{ 'auth.agreementIsRequired' | translate }}
            </small>
          </div>
        </ng-container>
      </div>
      <!-- Agree - End -->

      <!-- Submit - Start -->
      <div class="mt-2 gap-2">
        <button
          pButton pRipple
          pendo-tracker="sign_up_next_cta_button_click"
          type="submit"
          class="flex align-items-center justify-content-center w-full"
          [disabled]="isShowSpinner || isShowInternalSpinner">
          <p-progressSpinner *ngIf="isShowInternalSpinner" [style]="{ width: '24px', height: '24px' }" 
            styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner>
          <span class="text-base">{{ 'Next' | translate }}</span>
        </button>
      </div>
      <!-- Submit - End -->
    </form>
  </div>  

  <!-- Already Account - Start -->
  <div class="text-center">
    <span class="text-base text-grey-50">
      {{ 'auth.alreadyHaveAccount' | translate }}
      <a [routerLink]="['/login']" class="rw__link" pendo-tracker="sign_up_login_cta_button_click">{{ 'auth.login' | translate }}</a>
    </span>
  </div>
  <!-- Already Account - End -->
</div>

<!-- License Key Modal - Start -->
<rw-license-key [dialogVisible]="openLicenseKeyDialogVisible" (closeDialog)="closeLicenseKeyDialog()"></rw-license-key>
<!-- License Key Modal - End -->