import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-cancel-progress',
  templateUrl: './cancel-progress.component.html',
  styleUrls: ['./cancel-progress.component.scss'],
})
export class CancelProgressComponent {
  @Input() content: any;

  langCss: string;

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.setDefaultLang(lang);
    this.langCss = lang === 'en' ? 'ltr' : 'rtl';
  }
}
