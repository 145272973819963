import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { getBase64Image } from '../../point-of-sale/utils/func';

@Injectable()
export class ImageCacheService implements OnDestroy {
  constructor() {}

  private readonly destroy$ = new Subject();

  private readonly images = new Map<string, string>();

  async getImage(url: string): Promise<string> {
    const existingImage = this.images.get(url);
    if (existingImage) {
      return existingImage;
    }
    const base64 = await getBase64Image(url);
    this.images.set(url, base64);
    return base64;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
