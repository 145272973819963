import { ProductVariantType } from './enum/product-enum';
import { IProductFactory } from './interfaces/product-interface';
import { ChildProduct } from './services/child-product';
import { CompositeProduct } from './services/composite-product';
import { PackageProduct } from './services/package-product';

export class ProductFactory {
  static getProduct(variantType: ProductVariantType): IProductFactory {
    switch (variantType) {
      case ProductVariantType.child:
        return new ChildProduct();

      case ProductVariantType.composite:
        return new CompositeProduct();

      case ProductVariantType.package:
        return new PackageProduct();

      default:
        throw new Error('Product Type does not exist');
    }
  }
}
