import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppChannelSubscription } from '../../../../model/AppChannelSubscription';
import { LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-uninstall-app',
  templateUrl: './uninstall-app.component.html',
  styleUrls: ['./uninstall-app.component.scss'],
})
export class UninstallAppComponent implements OnInit {
  @Input() content: any;

  app: AppChannelSubscription;

  langCss: string;

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    const lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(lng);
    this.langCss = lng === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    this.app = this.content.app;
  }
}
