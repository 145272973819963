import { Component, OnInit } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { UserService } from '../auth/services/user.service';
import { AutoUnsubscribe, AutoUnsubscribeI } from '../shared/decorators/auto-unsubscribe';
import { Merchant } from '../shared/model/Merchant';

@Component({
  selector: 'rw-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
@AutoUnsubscribe
export class ErrorPageComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  user: Merchant;

  constructor(private userService:UserService) { }

  ngOnInit() {
    this.subscriptionRefs = this.userService.getUser().pipe(
      take(1),
      tap((user) => {
        this.user = user;
      }),
    ).subscribe();
  }
}
