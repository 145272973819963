/**
 * @deprecated Moved to packages/rewaa-api
 */
export interface RewaaApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

/**
 * @deprecated Moved to packages/rewaa-api
 */
export interface RewaaPagedApiResponse<T> {
  count: number;
  rows: T[];
}

/**
 * @deprecated Moved to packages/rewaa-api
 */
export class PagedApiRequest {
  limit: number;
  offset: number;
}

/**
 * @deprecated Moved to packages/rewaa-api
 */
export class AllProductVariantRequest extends PagedApiRequest {
  sortDirection: string;
  userName: string;
}
