import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Tax } from '../../shared/model/Tax';

export const loadAllTaxes = createAction(
  '[Resolver] Load All Taxes',
);

export const taxesLoaded = createAction(
  '[Tax Effects] Taxs Loaded',
  props<{ taxes: Tax[] }>(),
);

export const updateTax = createAction(
  '[Edit TAX Page] Update Tax',
  props<{ taxId: number, tax: Tax }>(),
);

export const taxUpdated = createAction(
  '[Taxs Effect] Tax Updated',
  props<{ update: Update<Tax> }>(),
);

export const addTax = createAction(
  '[Add TAX Page] Create new TAX',
  props<{ tax: Tax }>(),
);

export const taxAdded = createAction(
  '[Tax Effect] New Tax Added',
  props<{ tax: Tax }>(),
);

export const loadTaxById = createAction(
  '[Load Tax By ID Effect] load tax by ID',
  props<{ taxId: number }>(),
);
