import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SideMenuService {
  private menuStatusSource$ = new BehaviorSubject<string>('');

  private resetStatusSource$ = new BehaviorSubject<boolean>(false);

  private sideMenuStatusSource$ = new BehaviorSubject<boolean>(false);

  private sideMenuDisplayChanged$ = new BehaviorSubject<boolean>(false);

  onReset = this.resetStatusSource$.asObservable();

  onMenuStatusChanged = this.menuStatusSource$.asObservable();

  onSideMenuStatusChanged = this.sideMenuStatusSource$.asObservable();

  onSideMenuDisplayChanged = this.sideMenuDisplayChanged$.asObservable();

  menuStatusChanged(key: string) {
    this.menuStatusSource$.next(key);
  }

  reset() {
    this.resetStatusSource$.next(true);
  }

  sideMenuStatusChanged(opened:boolean) {
    this.sideMenuStatusSource$.next(opened);
  }

  sideMenuDisplayChanged(hidden:boolean) {
    this.sideMenuDisplayChanged$.next(hidden);
  }
}
