import { DateTime } from 'luxon';
import { DATE_LOOKUP_OPTIONS, DATE_LOOKUP_OPTIONS_V4 } from '../constants';

export const PAYMENT_STATUS_LOOKUP = [
  { name: 'All', value: 'All' },
  { name: 'Paid', value: 'Paid' },
  { name: 'Not Paid', value: 'Not Paid' },
  { name: 'Partially Paid', value: 'Partially Paid' },
];

export const DATE_FILTER_LOOKUP = [
  {
    name: DATE_LOOKUP_OPTIONS.TODAY,
    value: [
      DateTime.now().startOf('day').toJSDate(),
      DateTime.now().toJSDate(),
    ],
  },
  {
    name: DATE_LOOKUP_OPTIONS.YESTERDAY,
    value: [
      DateTime.now().minus({ days: 1 }).startOf('day')
        .toJSDate(),
      DateTime.now().minus({ days: 1 }).startOf('day')
        .toJSDate(),
    ],
  },
  {
    name: DATE_LOOKUP_OPTIONS.WEEK,
    value: [
      DateTime.now().minus({ weeks: 1 }).startOf('day')
        .toJSDate(), DateTime.now().toJSDate()],
  },
  {
    name: DATE_LOOKUP_OPTIONS.MONTH,
    value: [
      DateTime.now().minus({ months: 1 }).startOf('day')
        .toJSDate(),
      DateTime.now().toJSDate()],
  },
  {
    name: DATE_LOOKUP_OPTIONS.QUARTER,
    value: [
      DateTime.now().minus({ months: 3 }).startOf('day')
        .toJSDate(), DateTime.now().toJSDate()],
  },
];

export const DATE_FILTER_LOOKUP_V4 = [
  {
    name: DATE_LOOKUP_OPTIONS_V4.TODAY,
    value: [
      DateTime.now().startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    name: DATE_LOOKUP_OPTIONS_V4.WEEK,
    value: [
      DateTime.now().minus({ days: DateTime.now().weekday % 7 }).startOf('day').toJSDate(),
      DateTime.now().plus({ days: 6 - (DateTime.now().weekday % 7) }).endOf('day').toJSDate(),
    ],
  },
  {
    name: DATE_LOOKUP_OPTIONS_V4.MONTH,
    value: [
      DateTime.now().startOf('month')
        .toJSDate(),
      DateTime.now().endOf('month').toJSDate()],
  },
  {
    name: DATE_LOOKUP_OPTIONS_V4.QUARTER,
    value: [
      DateTime.now().minus({ months: 2 }).startOf('month')
        .toJSDate(), DateTime.now().endOf('month').toJSDate()],
  },
  {
    name: DATE_LOOKUP_OPTIONS_V4.SPECIFIC_RANGE,
    value: [],
  },
];
