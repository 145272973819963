import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlags } from '../services/enum/feature-flag.interface';

export type FeatureFlagNames = keyof FeatureFlags;

// eslint-disable-next-line max-len
export type FlagActionType<T extends FeatureFlagNames> = FeatureFlagGuardAction | FeatureFlagGuardActionCallBack<T>;

// eslint-disable-next-line max-len
export type FlagComparison<T extends FeatureFlagNames> = FeatureFlagComparatorEnum | FeatureFlagComparatorFunction<T>;

export enum FeatureFlagGuardAction {
  AllowAccess = 'AllowAccess',
  Reroute = 'Reroute',
}

export type FeatureFlagGuardActionCallBack<T extends FeatureFlagNames> = (
  router: Router,
  flagValue: FeatureFlags[T]
) =>
| boolean
| UrlTree
| Observable<boolean | UrlTree>;
// | Promise<boolean | UrlTree>

export enum FeatureFlagComparatorEnum {
  Equality = 'Equality',
  Inequality = 'Inequality',
  keyInequality = 'keyInequality',
}

export type FeatureFlagComparatorFunction<T extends FeatureFlagNames> = (
  flagValueFromLaunchDarkly: FeatureFlags[T],
  flagValue: FeatureFlags[T],
  flagKey?: keyof FeatureFlags[T],
) => boolean;

export interface FeatureFlagGuardInputI<T extends FeatureFlagNames> {
  name: T;
  flagValue: FeatureFlags[T];
  comparison?: FlagComparison<T>;
  action?: FlagActionType<T>;
  flagKey?: keyof FeatureFlags[T] | undefined;
}
