import { Pipe, PipeTransform, Inject } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'amountFormat',
})
export class AmountFormatPipe implements PipeTransform {
  constructor(
    @Inject(DecimalPipe)
    private readonly decimalPipe: DecimalPipe = new DecimalPipe('en-US'),
  ) {}

  transform(value?: number): string {
    if (value === null || value === undefined) {
      return '';
    }
    if (Number(value) === Math.floor(value)) {
      return this.formatInteger(value);
    }
    return this.formatDecimal(value);
  }

  private formatInteger(value: number): string {
    if (value >= 1000) {
      return this.formatWithCommas(value);
    }
    return value.toString();
  }

  private formatDecimal(value: number): string {
    const formattedValue = this.decimalPipe.transform(value, '1.2-2');
    const decimalPart = formattedValue.split('.')[1];

    if (decimalPart === '00') {
      return formattedValue.replace(/(\.0+|(\.[0-9]*[1-9])0+)$/, '$2');
    }
    if (decimalPart.length < 2) {
      return formattedValue.replace(/(\.0+|(\.[0-9]*[1-9])0+)$/, '$2') + '0';
    }
    return formattedValue;
  }

  private formatWithCommas(value: number): string {
    return new Intl.NumberFormat('ja-JP', {
      maximumSignificantDigits: 21,
    }).format(value);
  }
}
