import Currency from 'currency.js';
import { WeightedProductConfiguration } from '../model/WeightedProductConfiguration';
import { ProductVariant } from '../../inventory/model/product-variant';

function formatNumber(number: number, length: number) {
  if (number.toString(10).length > length) {
    return number.toString(10).slice(-length);
  }
  if (number.toString(10).length < length) {
    return number.toString(10).padStart(length, '0');
  }
  return number.toString(10);
}

export const generateWeightedProductSku = (
  weightedProductConfig: WeightedProductConfiguration,
  variant: ProductVariant,
  priceType: 'retailPrice' | 'wholeSalePrice' = 'retailPrice',
  stockLocationId?: number,
) => {
  const {
    starter, decimalNumberDigits, lastDigits,
  } = weightedProductConfig;
  if (lastDigits === 'Weight') {
    const weight = (variant.shippingWeight || 0);
    const whole = parseInt(`${weight}`, 10);
    const decimal = new Currency(weight).subtract(whole).multiply(10 ** decimalNumberDigits).value;
    return `${starter} ${variant.sku} ${formatNumber(whole, 2)}${formatNumber(decimal, decimalNumberDigits)}`;
  }
  if (lastDigits === 'Price') {
    const stockRecord = variant.ProductVariantToStockLocations
      .find((stock) => stock.stockLocationId === stockLocationId);
    const price = stockRecord ? (stockRecord[priceType] || 0) : 0;
    const whole = parseInt(`${price}`, 10);
    const decimal = new Currency(price).subtract(whole).multiply(10 ** decimalNumberDigits).value;
    return `${starter} ${variant.sku} ${formatNumber(whole, 2)}${formatNumber(decimal, decimalNumberDigits)}`;
  }
  return variant.sku;
};
