import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { LocalStorageKey } from '../../../shared/constants';
import { CobrowsingService } from '../../services/cobrowsing.service';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'rw-cobrowsing-modal',
  templateUrl: './cobrowsing-modal.component.html',
  styleUrls: ['./cobrowsing-modal.component.scss'],
})
export class CobrowsingModalComponent {
  lang = 'ar';

  loading = false;

  languageBtnDisabled = false;

  constructor(
    private authService: AuthService,
    private cobrowsingService: CobrowsingService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
    this.lang = localStorageService.getItem<string>(LocalStorageKey.Language);
  }

  get displayCobrowsingModal(): boolean {
    return this.isValidUrlForCobrowsing && this.cobrowsingModalIsActive;
  }

  get cobrowsingModalIsActive(): boolean {
    return this.cobrowsingService.cobrowsingModalIsActive;
  }

  onChangeLanguage(): void {
    this.languageBtnDisabled = true;

    let newLanguage: string;
    if (this.lang === 'ar') {
      newLanguage = 'en';
      this.localStorageService.setItem<string>(
        LocalStorageKey.Language,
        newLanguage,
      );
    }

    if (this.lang === 'en') {
      newLanguage = 'ar';
      this.localStorageService.setItem<string>(
        LocalStorageKey.Language,
        newLanguage,
      );
    }

    this.authService.switchLanguage(newLanguage);
  }

  get isValidUrlForCobrowsing(): boolean {
    return !this.router.url.startsWith('/cobrowsing-wizard');
  }

  async startTour(): Promise<void> {
    this.loading = true;
    await this.cobrowsingService.initializeFullviewSdk();
  }
}
