import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';
import { CustomToastService } from '../services/custom-toast.service';
import { LANGUAGES, LocalStorageKey } from '../constants';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { removeLanguageFromUrlIfRequired } from '../../utils/localizedUrl';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private sessionExpiredToastShown = false;

  constructor(
    private toaster: CustomToastService,
    private userAuthenticationService: UserAuthenticationService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.urlWithParams && req.urlWithParams.includes('X-Amz-Algorithm')) {
      return next.handle(req);
    }

    const externalParam = req.params.get('external');
    if (externalParam) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if (err.status === 401) {
          const language = this.localStorageService.getItem<string>(
            LocalStorageKey.Language,
            'en',
          );
          if (!this.sessionExpiredToastShown) {
            this.sessionExpiredToastShown = true;
            this.toaster.error(
              language === LANGUAGES.ar
                ? 'انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى'
                : 'Session expired, please log in again',
            );
          }
          this.userAuthenticationService.logout();
          const url = this.localStorageService.getItem<string>(
            LocalStorageKey.returnUrl,
          );
          this.getRouter(url).then(() => {
            setTimeout(() => {
              this.sessionExpiredToastShown = false;
            }, 3000);
          });
        }
        throw err;
      }),
    );
  }

  getRouter(url: string | undefined): Promise<boolean> {
    const language = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
      'en',
    );
    if (url) {
      const urlWithoutLanguage = removeLanguageFromUrlIfRequired(url);

      return this.router.navigate([`/login`], {
        queryParams: {
          returnUrl: urlWithoutLanguage,
        },
      });
    }

    return this.router.navigateByUrl(`${language}/login`);
  }
}
