import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRemoveStock from './remove-stock.reducer';

export const selectInvoiceState = createFeatureSelector<
fromRemoveStock.InvoiceState>(fromRemoveStock.removeStockFeatureKey);

export const areInvoicesLoaded = createSelector(
  selectInvoiceState,
  (state) => state.areInvoicesLoaded,
);

export const selectAllRemoveStock = createSelector(
  selectInvoiceState,
  fromRemoveStock.selectAll,
);

export const selectRemoveStockByInvoiceNumber = (invoiceNumber) => createSelector(
  selectAllRemoveStock,
  (removeStock) => removeStock.find((rmStock) => rmStock.invoiceNumber === invoiceNumber),
);

export const isRemoveStockProcessing = createSelector(
  selectInvoiceState,
  (state) => state.isProcessing,
);
