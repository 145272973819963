import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, takeUntil, tap,
} from 'rxjs/operators';
import { KeyboardShortcutServiceOld } from 'src/app/shared/services/keyboard-shortcut-old.service';
import { POSShortcutAction, POSShortcutContext } from 'src/app/internal-apps/pos/utils/constants';
import { TypeaheadSearchService } from 'src/app/inventory/products/components/create/typeahead-search.service';
import { AppState } from 'src/app/reducers';
import { selectAllUsers } from 'src/app/users-settings/selectors/users.selector';
import { SettingsActions } from 'src/app/users-settings/settings.action-types';
import { LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-add-salesman',
  templateUrl: './add-salesman.component.html',
  styleUrls: ['./add-salesman.component.scss'],
})
export class AddSalesmanComponent implements OnInit {
  @Input() content: any;

  salesmanDetailsInput: { id: number; name: string };

  users: any;

  filteredUsers: any = [];

  searchText: string;

  debounceSearch: Subject<any> = new Subject<any>();

  langCss: string;

  private destroy$ = new Subject();

  ngOnInit(): void {
    const { salesmanDetails } = this.content.data;
    this.salesmanDetailsInput = salesmanDetails;

    this.loadUsers();

    this.debounceSearch
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((term) => this.filterUser(term)),
      )
      .subscribe();

    this.keyboardShortcutService.registerContext(POSShortcutContext.AssignSalesman)
      .pipe(takeUntil(this.destroy$))
      .subscribe((shortcut) => { this.handleShortcut(shortcut); });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.keyboardShortcutService.unregisterContext(POSShortcutContext.AssignSalesman);
  }

  constructor(
    private translate: TranslateService,
    private autoCompleteService: TypeaheadSearchService,
    private store: Store<AppState>,
    private keyboardShortcutService: KeyboardShortcutServiceOld,
    private localStorageService: LocalStorageService,
  ) {
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.setDefaultLang(lang);
    this.langCss = lang === 'en' ? 'ltr' : 'rtl';
  }

  private handleShortcut(shortcut: POSShortcutAction) {
    if (!this.filteredUsers.length) return;

    const currentSalesmanIndex = this.filteredUsers.findIndex((user) => user.id === this.salesmanDetailsInput.id);

    if (shortcut === POSShortcutAction.AssignNextSalesman) {
      if (currentSalesmanIndex > -1 && this.filteredUsers[currentSalesmanIndex + 1]) {
        // select next salesman
        this.onSelect(this.filteredUsers[currentSalesmanIndex + 1]);
      } else if (!this.salesmanDetailsInput.id) {
        // select first salesman
        this.onSelect(this.filteredUsers[0]);
      }
    } else if (shortcut === POSShortcutAction.AssignPreviousSalesman) {
      if (currentSalesmanIndex > -1 && this.filteredUsers[currentSalesmanIndex - 1]) {
        // select previous salesman
        this.onSelect(this.filteredUsers[currentSalesmanIndex - 1]);
      }
    }
  }

  private loadUsers() {
    this.store
      .pipe(
        select(selectAllUsers),
        tap((users) => {
          if (users?.length) {
            this.users = users;
            this.filteredUsers = users;
          }
          this.store.dispatch(SettingsActions.loadAllUsers());
        }),
        tap((users) => {
          this.users = users;
          this.filteredUsers = this.moveSelectedSalesmanToTop(users);
        }),
      )
      .subscribe();
  }

  private filterUser(term: string) {
    this.filteredUsers = [];
    const filteredUsers = this.users
      .filter(
        (user) => (
          user.status === 'Active'
          && user.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        ),
      )
      .slice(0, 50);

    if (filteredUsers.length) {
      this.filteredUsers = this.moveSelectedSalesmanToTop(filteredUsers);
      return;
    }

    const selectedSalesman = this.users.find((user) => user.id === this.salesmanDetailsInput?.id);

    if (selectedSalesman) {
      this.filteredUsers = [selectedSalesman];
    }

    this.filteredUsers.push({ searchFailed: true, id: -1, name: 'No data found' });
  }

  getSelectedSalesmanRowClass(userId: number, searchFailed) {
    let classTxt = this.salesmanDetailsInput?.id === userId ? 'selected-salesman-row' : 'salesman-row';
    classTxt = searchFailed ? `${classTxt} no-data` : classTxt;

    return classTxt;
  }

  getSelectedSalesmanListClass(userId: number) {
    return this.salesmanDetailsInput?.id === userId ? 'selected-salesman' : 'salesman-list';
  }

  moveSelectedSalesmanToTop(users) {
    const idToSearch = this.salesmanDetailsInput?.id;

    if (idToSearch == null) {
      return users;
    }
    const index = users.findIndex(({ id }) => id === idToSearch);

    if (index !== -1) {
      const updatedData = [...users];
      updatedData.unshift(...updatedData.splice(index, 1));
      return updatedData;
    }

    return users;
  }

  onSelect(e): void {
    const salesmanDetails = e;
    if (salesmanDetails) {
      this.salesmanDetailsInput = salesmanDetails;
      this.content.success_btn.handler({
        id: salesmanDetails.id,
        name: salesmanDetails.name,
      });
    }
    this.content.cancel_btn.handler();
  }

  removeSalesmanDetails() {
    const { removeSalesmanDetails: removeSalesmanFunc } = this.content.data;
    removeSalesmanFunc(this.salesmanDetailsInput);
    this.salesmanDetailsInput = null;
  }

  public onFocus(e: any) {
    return this.autoCompleteService.userFocus$.next(e);
  }
}
