import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface OnboardUserDto {
  merchantId: number;
  userRole: string;
  businessType: string;
  employeeCount: string;
  userName: string;
  status: OnboardingStatus;
}

export enum OnboardingStatus {
  OnboardingPending = 'ONBOARDING_PENDING',
  OnboardingCompleted = 'ONBOARDING_COMPLETED',
  OnboardingSkipped = 'ONBOARDING_SKIPPED',
}

const API_URL = '/api/nucleus';

@Injectable()
export class OnboardingService {
  constructor(private http: HttpClient) {}

  onboardMerchant = (
    onboardingDto: OnboardUserDto,
  ): Observable<{ success: boolean }> =>
    this.http.put<{ success: boolean }>(`${API_URL}/onboarding`, onboardingDto);
}
