import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TabButtonDirective } from './tab-button.directive';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabViewComponent } from './tab-view/tab-view.component';

@NgModule({
  declarations: [TabViewComponent, TabContentComponent, TabButtonDirective],
  imports: [CommonModule, TranslateModule],
  exports: [TabViewComponent, TabContentComponent, TabButtonDirective],
})
export class TabViewModule {}
