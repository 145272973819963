<div class="trans-popup">
    <div class="modal-header d-flex align-items-center">
        <div class="modal-title trans-title" id="exampleModalLongTitle"> {{'Quotations' | translate }}
            <br>
        <span class="quote-number">{{ content.data.quote.listNumber }}</span>
        </div>
        <button pendo-tracker="vqb" type="button" pButton class="rw__button p-button-rounded p-button-secondary p-button-text p-button-icon-only rw__m-0 rw__p-2 rw__fs-5" data-dismiss="show" (click)=content.cancel_btn.handler()
            aria-label="Close">
            <span class="fa-regular fa-xmark fa-lg x-icn"></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="pl-2">
            <div class="grid">
                <div class="col md:col-4">
                    <div class="lable-text">{{'Expiry Date' | translate }}</div>
                    <div class="content-text">{{ content.data.quote.expiryDate }}</div>
                </div>
                <div class="col md:col-4">
                    <div class="lable-text">{{'Customer Name' | translate }}</div>
                    <div class="content-text">{{ content.data.quote.Customer?.name }}</div>
                </div>
                <div class="col md:col-4">
                    <div class="lable-text">{{'Customer Phone Number' | translate }}</div>
                    <div class="content-text">{{ content.data.quote.Customer?.mobileNumber }}</div>
                </div>
            </div>
            <div class="grid">
                <div class="col md:col-4">
                    <div class="lable-text">{{'Issue Date' | translate }}</div>
                    <div class="content-text">{{ content.data.quote.issueDate }}</div>
                </div>
                <div class="col md:col-4">
                    <div class="lable-text">{{'Created by' | translate }}</div>
                    <div class="content-text">{{ content.data.quote.issuerName }}</div>
                </div>
                <div class="col md:col-4">
                    <div class="lable-text">{{'Location' | translate }}</div>
                    <div class="content-text">{{ content.data.quote.StockLocation.name }}</div>
                </div>
            </div>
        </div>
        <div>
            <p-table class="styleTable" [value]="products" [responsive]="true"
            [resizableColumns]="true" [paginator]="false" columnResizeMode="expand"
            [frozenColumns]="[{name:'Product Name'}]" styleClass="p-datatable-gridlines" [resizableColumns]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name" class="header">{{'Product Name' | translate }} <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="quantity" class="header text-right">{{'Quantity' | translate }} <p-sortIcon field="quantity"></p-sortIcon></th>
                        <th pSortableColumn="price" class="header text-right">{{'Price' | translate }} <p-sortIcon field="price"></p-sortIcon></th>
                        <th pSortableColumn="totalPrice" class="header text-right">{{'Total Price' | translate }} <p-sortIcon field="totalPrice"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-products>
                    <tr class="p-selectable-row">
                        <td class="table-text">{{ products.name }}</td>
                        <td class="text-right table-text">{{ products.quantity }}</td>
                        <td class="text-right table-text">{{ products.price | amountFormat }}</td>
                        <td class="text-right table-text">{{ products.totalPrice | amountFormat  }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="text-right table-summary-part">
                <div class="grid">
                    <div class="col md:col-8 total-text">
                        <span class="dark-text">{{'Subtotal' | translate }}</span> {{'(Tax Exclusive)' | translate }}
                    </div>
                    <div class="col md:col-4 pr-5 table-text">{{ content.data.quote.subtotalTaxExclusive | amountFormat  }}</div>
                </div>
                <div class="grid">
                  <div class="col md:col-8 table-text"><span class="dark-text">{{'Promotions' | translate }}</span><span> {{'(Tax Exclusive)' | translate }}</span></div>
                    <div class="col md:col-4 pr-5 table-text">{{ promotionAmount | amountFormat }}</div>
                </div>
                <div class="grid">
                    <div class="col md:col-8 table-text"><span class="dark-text">{{'Discount' | translate }}</span><span> {{'(Tax Exclusive)' | translate }}</span></div>
                    <div class="col md:col-4 pr-5 table-text">{{ content.data.quote.totalDiscount | amountFormat  }}</div>
                </div>
                <ng-container *ngIf="promotionsExpired">
                    <div class="grid" *ngFor="let expiredPromo of promotionsExpired">
                        <div class="col md:col-12 pr-5 table-text">
                            <span class="exclamation-icon">!</span>
                            <span class="promotion-text">{{ expiredPromo.discountApplied }} {{ expiredPromo.name }} {{ "Promotion exceeded its expiry date" | translate }}</span>
                            <span class="alert-text"> {{ "Exclude Promotion" | translate }}</span>
                        </div>
                    </div>
                </ng-container>
                <div class="grid">
                    <div class="col md:col-8 table-text dark-text">{{'Tax' | translate }}</div>
                    <div class="col md:col-4 pr-5 table-text">{{ content.data.quote.totalTax | amountFormat  }}</div>
                </div>
                <div class="grid">
                    <div class="col md:col-5"></div>
                    <div class="col md:col-7 pl-4 pr-5"><hr class="total-summary"></div>
                </div>
                <div class="grid">
                    <div class="col md:col-8 total-text"><span class="dark-text">{{'Total' | translate }}</span> {{'(Tax Inclusive)' | translate }}</div>
                    <div class="col md:col-4 pr-5 table-text dark-text">{{ content.data.quote.totalPriceTaxInclusive | amountFormat  }}</div>
                </div>
            </div>
        </div>
        <div>
            <div class="terms-text">{{'Terms and conditions' | translate }}</div>
            <div class="table-text terms-details" 
            [innerHTML]='content.data.quote.terms'>
            </div>
        </div>
    </div>
    <div class="modal-footer flex justify-content-left pt-4">
        <div>
            <button pButton [attr.pendo-tracker]="content.cancel_btn.pendoTag" class="p-button-text p-button-secondary" (click)=content.cancel_btn.handler() label="{{content.cancel_btn.text | translate}}">
            </button>
            <button pButton [attr.pendo-tracker]="content.retrieve_btn.pendoTag" class="p-button-text p-button-secondary" (click)=content.retrieve_btn.handler() label="{{content.retrieve_btn.text | translate}}">
            </button>
            <button pButton [attr.pendo-tracker]="content.print_btn.pendoTag" class="rw__button rw__button-raised" (click)=content.print_btn.handler() label="{{content.print_btn.text | translate}}">
            </button>
        </div>
    </div>
</div>
