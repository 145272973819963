import { createAction, props } from '@ngrx/store';
import { POSLocation, Register } from '../model/Register';

export const loadLocationPOSRegisters = createAction(
  '[Registers Effect] Load Location POS Registers',
  props<{ locationId: number }>(),
);

export const locationPOSRegistersLoaded = createAction(
  '[Registers Effect] Loc POS Registers Loaded',
  props<{ id: number, registers: any[] }>(),
);

export const addPOSRegister = createAction(
  '[Registers Effect] Add POS Registers',
  props<{ register: Register }>(),
);

export const addPOSRegisterAdded = createAction(
  '[Registers Effect] Add POS Register Added',
  props<{ id: number, register: Register }>(),
);

export const deletePOSRegister = createAction(
  '[Registers Effect] Delete POS Registers',
  props<{ register: Register }>(),
);

export const posRegisterDeleted = createAction(
  '[Registers Effect] POS Register Deleted',
  props<{ register: Register, resp: { success: number, id: number } }>(),
);

export const updatePOSRegister = createAction(
  '[Registers Effect] Update POS Register',
  props<{ register: Register }>(),
);

export const posRegisterUpdated = createAction(
  '[Registers Effect] POS Register Updated',
  props<{ register: Register }>(),
);

export const loadAllLocationRegisters = createAction(
  '[Registers Resolver Effect] Load All Locations Registers',
);

export const poslocationRegistersLoaded = createAction(
  '[Registers Effect] POS Location Registers Loaded',
  props<{ posLocations: POSLocation[] }>(),
);
