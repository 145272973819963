// Credits go to Bruno Paulino for the main idea and initial implementation
// Of Retry with Exponential Backoff
// https://bpaulino.com/entries/retrying-api-calls-with-exponential-backoff

export interface RetryParams<T> {
  handler: () => Promise<T>;
  maxRetries?: number;
  firstRetryWaitTimeInMilliseconds?: number;
  shouldRetryOnError?: (e: any) => boolean;
  beforeRetry?: () => void;
}

export function waitFor(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function executeWithRetry<T>(params: RetryParams<T>): Promise<T> {
  const { handler, beforeRetry } = params;
  const maxRetries = params.maxRetries ?? 3;
  const firstRetryWaitTime = params.firstRetryWaitTimeInMilliseconds ?? 100;
  const shouldRetryOnError = params.shouldRetryOnError || (() => true);

  async function retryWithBackoff(retries: number): Promise<T> {
    try {
      if (retries > 0) {
        const jitterPercentage = 0.8;
        const maxTimeToWait = 2 ** retries * firstRetryWaitTime;
        const minTimeToWait = maxTimeToWait * (1 - jitterPercentage);
        const finalWaitTime = Math.floor(
          Math.random() * (maxTimeToWait - minTimeToWait) + minTimeToWait,
        );
        await waitFor(finalWaitTime);
      }
      return await handler();
    } catch (e) {
      if (retries < maxRetries && shouldRetryOnError(e)) {
        if (beforeRetry) {
          beforeRetry();
        }
        return retryWithBackoff(retries + 1);
      }
      throw e;
    }
  }
  return retryWithBackoff(0);
}
