import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
} from '@angular/router';
import { isObservable, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { FeatureFlags } from '../services/enum/feature-flag.interface';
import { FeatureFlagService } from '../services/types/feature-flag.service.interface';
import {
  FeatureFlagComparatorEnum,
  FeatureFlagComparatorFunction,
  FeatureFlagGuardAction,
  FeatureFlagGuardInputI,
  FeatureFlagNames,
  FlagActionType,
  FlagComparison,
} from '../types/feature-flag.type';

@Injectable()
export class FeatureFlagGuard<T extends FeatureFlagNames> implements CanActivate {
  public flagName: T;

  public flagValue: FeatureFlags;

  public flagKey: keyof FeatureFlags[T];

  public flagComparator: FlagComparison<T> = FeatureFlagComparatorEnum.Equality;

  public flagAction: FlagActionType<T> = FeatureFlagGuardAction.AllowAccess;

  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagService,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  private getComparatorFunction(
    comparison: FlagComparison<T>,
  ): FeatureFlagComparatorFunction<T> {
    if (comparison === FeatureFlagComparatorEnum.Equality) {
      return (
        flagValueFromLaunchDarkly,
        targetFlagValue,
      ) => flagValueFromLaunchDarkly === targetFlagValue;
    }
    if (comparison === FeatureFlagComparatorEnum.Inequality) {
      return (
        flagValueFromLaunchDarkly,
        targetFlagValue,
      ) => flagValueFromLaunchDarkly !== targetFlagValue;
    }
    if (comparison === FeatureFlagComparatorEnum.keyInequality) {
      return (
        flagValueFromLaunchDarkly,
        targetFlagValue,
        targetKey,
      ) => flagValueFromLaunchDarkly[targetKey] !== targetFlagValue[targetKey];
    }
    return comparison;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const flagData = route.data.featureFlag as FeatureFlagGuardInputI<T>;
    const { name, flagValue, flagKey } = flagData;
    const action = flagData.action ?? this.flagAction;

    const comparatorFunction = this.getComparatorFunction(
      flagData.comparison ?? this.flagComparator,
    );

    return this.featureFlagService.variation(name).pipe(
      first(),
      switchMap((flagValueFromLaunchDarkly) => {
        if (typeof action === 'function') {
          if (comparatorFunction(flagValueFromLaunchDarkly, flagValue, flagKey)) {
            const actionResult = action(this.router, flagValueFromLaunchDarkly);
            return !isObservable(actionResult) ? of(actionResult) : actionResult;
          }
          return of(true);
        }
        if (action === FeatureFlagGuardAction.Reroute) {
          // Not implemented yet
          return of(true);
        }
        if (action === FeatureFlagGuardAction.AllowAccess) {
          if (comparatorFunction(flagValueFromLaunchDarkly, flagValue)) {
            return of(true);
          }
          return of(false);
        }
        return of(false);
      }),
    );
  }
}
