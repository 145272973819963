import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Layout } from '../model/Layout';
import { PaginatedLayoutCategoryProducts } from '../shared/types/common.types';

@Injectable()
export class LayoutService {
  API_URL = '/api';

  constructor(private readonly http: HttpClient) {}

  getLayoutList(filters): Observable<{ count: number; rows: Layout[] }> {
    const layoutQueryParams = new HttpParams()
      .set('name', filters.name || '')
      .set('registerName', filters.registerName || '')
      .set('type', filters.layoutType || '')
      .set('offset', filters.offset || '0')
      .set('limit', filters.limit || '10');
    return this.http.get<{ count: number; rows: Layout[] }>(
      `${this.API_URL}/pos/layouts`,
      { params: layoutQueryParams },
    );
  }

  getUncategorizedProduct(filters): Observable<any> {
    const layoutQueryParams = new HttpParams()
      .set('offset', filters.offset || '0')
      .set('limit', filters.limit || '10')
      .set('query', filters.query || '');
    return this.http.get<any>(`${this.API_URL}/categories/none/variants`, {
      params: layoutQueryParams,
    });
  }

  getLayoutDetail(id: number) {
    return this.http.get(`${this.API_URL}/pos/layouts/${id}`);
  }

  getLayoutDetailV2(id: number) {
    return this.http.get(`${this.API_URL}/v2/pos/layouts/${id}`);
  }

  getLayoutCategoryProducts(id:number, stockLocationId:number, offset?:string, limit?:string) {
    return this.http.get(`${this.API_URL}/pos/layouts/category/${id}/${stockLocationId}/products?offset=${offset}&limit=${limit}`);
  }

  deleteLayout(id: number) {
    return this.http.delete(`${this.API_URL}/pos/layouts/${id}`);
  }

  saveLayout(layoutDetail): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/pos/layouts/`, layoutDetail);
  }

  updateLayout(layoutDetail): Observable<any> {
    return this.http.put<any>(
      `${this.API_URL}/pos/layouts/${layoutDetail.id}`,
      layoutDetail,
    );
  }
}
