<div [ngClass]="langCss" class="error d-grid " id="error" *ngIf="errors | async as errors">
  <div class="close-icon-container" (click)=content.cancel_btn.handler()>
    <i class="fa fa-times close-icon" aria-hidden="true"></i>
  </div>

  <div class="container p-5">
    <!-- <div class="grid  error-modal-header text-capitalize">
      <p>{{content?.title | translate}}</p>
    </div> -->
    <div class="grid">
      <div class="col-8">
				<div class="  error-modal-header text-capitalize">
					<p>{{content?.title | translate}}</p>
				</div>
        <p class="lable-resolvable"> {{ getSummaryMessage(errors) }}</p>
      </div>
      <div class="col-4">
        <button pendo-tracker="edpb" pButton *ngIf="shouldEnableResolve()"
                class="rw__button rw__button-raised"
                (click)="handleResolveAllErrors()"
                [disabled]='!content.resolveAll_btn.enabled'
                label="{{ content.resolveAll_btn.text | translate }}">
        </button>
      </div>
    </div>
    <hr>
    <ng-container *ngIf="errors">
      <div class="info mb-3" *ngFor=" let error of errors">
        <ng-container *ngIf="error?.status !== 'Irresolvable' || error?.status !== 'Unresolvable' ">
        <div class="grid">
          <div class="col-8">
            <p class="error-title">
              {{ getErrorDescription(error) | translate: {sku: error.sku} }}
            </p>
            <p class="error-details">
              <span>{{'ER-' + error.id }}</span>
              <span class="mx-2">{{error.createdAt | date:'d MMMM y' }}</span>
            </p>
            <ng-container>
              <p class="error-details text-capitalize">{{'Recovery Steps' | translate }}</p>
              <p class="error-title">
                {{ getErrorRecoveryStep(error) | translate: {sku: error.sku} }}
              </p>
            </ng-container>
          </div>
          <div class="col-4">
            <p class=" status-lable mb-7 text-capitalize text-center">
              <span>{{error.status || '-' |translate }}</span>
            </p>
            <button pendo-tracker="edpb-1"
              pButton
              [title]="'Make the recovery steps first, then click resolve to resolve the error.'| translate"
              class="rw__button rw__button-raised"
              (click)=content.resolve_btn.handler(error.id)
              *ngIf="error?.status === 'Unresolved' && shouldEnableResolve()"
              label="{{ content.resolve_btn.text | translate }}">
            </button>
          </div>
        </div>
        <hr>
      </ng-container>
      </div>
    </ng-container>
  </div>
</div>
