import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoginInProcessService {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loginInProcessScreen$: Observable<boolean> =
    this.loadingSubject.asObservable();

  loginInProcess = false;

  showLoginInProcessScreen(): void {
    this.loginInProcess = true;
    this.loadingSubject.next(this.loginInProcess);
  }

  stopLoginInProcessScreenLoading(): void {
    this.loginInProcess = false;
    this.loadingSubject.next(this.loginInProcess);
  }
}
