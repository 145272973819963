import { EventEmitter, Input, Component, Output } from '@angular/core';

@Component({
  selector: 'rw-setting-account',
  templateUrl: './setting-account.component.html',
  styleUrls: ['./setting-account.component.scss'],
})
export class SettingAccountComponent {
  @Input() dialogVisible = false;

  @Output() closeDialog = new EventEmitter<undefined>();

  closeThisDialog(): void {
    this.closeDialog.emit();
    this.dialogVisible = false;
  }
}
