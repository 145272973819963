import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/shared/model/invoice/Invoice';
import { Category } from 'src/app/inventory/model/category';
import { RegisterTypes } from '@rewaa-team/pos-sdk';
import { v4 as uuid } from 'uuid';
import { PosInvoiceTypes } from '@rewaa-team/types';
import { POSLocation, Register } from '../model/Register';
import { IRegisterLogQuery } from '../model/IRegisterLogQuery';
import { RegisterActionLog } from '../model/RegisterActionLog';
import { Sell } from '../model/Sell';
import { RegisterClosingLog } from '../model/RegisterClosingLog';
import { RegisterReport } from '../../../shared/components/register-report-template/register-report.type';
import { VerifyRegisterAccessResponse } from './types/types';

@Injectable()
export class RegisterService {
  private API_URL = '/api';
  private POS_SERVICE_URL = '/api/pos-service';

  constructor(
    private http: HttpClient,
  ) { }

  addRegister(register: Register): Observable<Register> {
    return this.http.post<Register>(`${this.API_URL}/pos/registers`, register);
  }

  getRegister(id: number): Observable<Register> {
    return this.http.get<Register>(`${this.API_URL}/pos/registers/${id}`);
  }

  deleteRegister(id: number): Observable<any> {
    return this.http.delete<any>(`${this.API_URL}/pos/registers/${id}`);
  }

  deactivateRegister(id: number): Observable<any> {
    return this.http.put<any>(`${this.API_URL}/pos/registers/${id}/deactivate`, {});
  }

  activateRegister(id: number): Observable<any> {
    return this.http.put<any>(`${this.API_URL}/pos/registers/${id}/activate`, {});
  }

  makeRegisterDefault(id: number): Observable<Register> {
    return this.http.put<Register>(`${this.API_URL}/pos/registers/${id}/makeDefault`, {});
  }

  getAllPOSLocationRegisters(includeInactive = false): Observable<POSLocation[]> {
    const params = { includeInactive: includeInactive ? `${includeInactive}` : '' };
    return this.http.get<POSLocation[]>(`${this.API_URL}/pos/locations`, { params });
  }

  getAllPOSLocationRegistersV2(): Observable<
    RegisterTypes.StockLocationRegisterResponse[]
  > {
    return this.http.get<RegisterTypes.StockLocationRegisterResponse[]>(
      `${this.POS_SERVICE_URL}/registers/locations`,
    );
  }

  updateRegister(register: Register): Observable<Register> {
    return this.http.put<Register>(`${this.API_URL}/pos/registers/${register.id}`, {
      name: register.name,
      description: register.description,
      PaymentMethodToRegisters: register.PaymentMethodToRegisters,
      layoutId: register.layoutId,
    });
  }

  addWithdrawCashRegister(id: number,
    data: { userId: number, amount: number, type: string, notes: string }): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/pos/registers/${id}/cash`, {
      userId: data.userId,
      amount: data.amount,
      type: data.type,
      notes: data.notes,
    });
  }

  getTotalRegisterBalance(id: number): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/pos/registers/${id}/balance`, {});
  }

  getCashRegistersLogs(logsQuery: IRegisterLogQuery):
  Observable<{ result: RegisterActionLog[], total: number }> {
    const params = Object.keys(logsQuery).reduce((paramsV, key) => paramsV.set(key, `${logsQuery[key]}`), new HttpParams());
    return this.http.get<{ result: any[], total: number }>(`${this.API_URL}/pos/cash-management`, { params });
  }

  getRegisterReport(id: number):
  Observable<RegisterReport> {
    return this.http.get<RegisterReport>(`${this.API_URL}/pos/registers/report/${id}`);
  }

  exportAllCashRegistersLogs(logsQuery: IRegisterLogQuery):
  Observable<{ result: RegisterActionLog[] }> {
    const params = Object.keys(logsQuery).reduce((paramsV, key) => paramsV.set(key, `${logsQuery[key]}`), new HttpParams());
    return this.http.get<{ result: RegisterActionLog[], total: number }>(`${this.API_URL}/pos/cash-management/export`, { params });
  }

  openRegister(
    id: number,
    data: { userId: number; amount: number; deviceIdentifier?: string },
  ): Observable<Register> {
    const payload = {
      userId: data.userId,
      openingAmount: data.amount,
      deviceIdentifier: data.deviceIdentifier,
    };
    return this.http.put<Register>(
      `${this.API_URL}/pos/registers/${id}/open`,
      payload,
    );
  }

  closeRegister(
    id: number,
    data: {
      userId: number;
      closingLogs: Array<RegisterClosingLog>;
      registerForceClose?: boolean;
    },
  ): Observable<RegisterReport | { message: string }> {
    return this.http.put<RegisterReport>(`${this.API_URL}/pos/registers/${id}/close`, data);
  }

  registerSellingSession(payload): Observable<boolean> {
    const { registerId, ...rest } = payload;
    return this.http.put<boolean>(
      `${this.POS_SERVICE_URL}/registers/${registerId}/selling-session`,
      { ...rest },
    );
  }

  unRegisterSellingSession(
    userId: number,
    registerId: number,
  ): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.POS_SERVICE_URL}/registers/${registerId}/selling-session`,
      { body: { userId } },
    );
  }

  sell(id: number, data: PosInvoiceTypes.SellInvoice) {
    // eslint-disable-next-line no-param-reassign
    data.timezoneOffset = new Date().getTimezoneOffset().toString();
    // eslint-disable-next-line no-param-reassign
    data.transactionReferenceId = uuid();
    return this.http.post<Invoice>(
      `${this.API_URL}/pos/registers/${id}/sell?ngsw-bypass=true`,
      data,
    );
  }

  returnOrder(id: number, returnInvoice: any) {
    // eslint-disable-next-line no-param-reassign
    returnInvoice.transactionReferenceId = uuid();
    return this.http.post<Invoice>(
      `${this.API_URL}/pos/registers/${id}/return`,
      returnInvoice,
    );
  }

  park(id: number, data: Sell) {
    return this.http.post<Invoice>(`${this.API_URL}/pos/registers/${id}/park`, data);
  }

  deletePark(invoiceId: number) {
    return this.http.delete<any>(`${this.API_URL}/pos/registers/park/${invoiceId}`);
  }

  getParkedSales(id: number): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/pos/registers/${id}/parkedInvoices`);
  }

  verifyRegisterAccess(id: number): Observable<VerifyRegisterAccessResponse> {
    return this.http.get<VerifyRegisterAccessResponse>(
      `${this.API_URL}/pos/registers/${id}/auth`,
    );
  }

  getProducts(registerId:string, productIds:string[], offset:number, limit:number) {
    return this.http.get<Category>(`${this.API_URL}/pos/registers/${registerId}/products`, {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        productIds: productIds.join('|'),
      },
    });
  }

  getOrderNumber(
    stockLocationId: number,
    timezoneOffset: number,
  ): Observable<number> {
    const payload = { timezoneOffset, stockLocationId };
    return this.http.post<number>(
      `${this.API_URL}/pos/registers/${stockLocationId}/order-number`,
      payload,
    );
  }
}
