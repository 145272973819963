export const TaxTypeConstant = {
  Inclusive: 'Inclusive',
  Exclusive: 'Exclusive',
} as const;

// export type TaxType =
//   (typeof TaxTypeConstant)[keyof typeof TaxTypeConstant];

export const enum TaxType {
  Inclusive = 'Inclusive',
  Exclusive = 'Exclusive',
}

export const enum PaymentMethodType {
  Cash = 'Cash',
  Card = 'Card',
  Other = 'Other',
  SoftPos = 'SoftPos',
}

export const enum FileStatus {
  Created = 'Created',
  Pending = 'Pending',
}

export const enum TaxCode {
  TobaccoCode = 'ST',
  VAT = 'S',
  NotSubjectToTax = 'VATEX-SA-OOS',
  CustomStandard = 'S',
  CustomExempt29 = 'VATEX-SA-29',
  CustomExempt29_7 = 'VATEX-SA-29-7',
  CustomExempt30 = 'VATEX-SA-30',
  CustomZeroRated32 = 'VATEX-SA-32',
  CustomZeroRated33 = 'VATEX-SA-33',
  CustomZeroRated34_1 = 'VATEX-SA-34-1',
  CustomZeroRated34_2 = 'VATEX-SA-34-2',
  CustomZeroRated34_3 = 'VATEX-SA-34-3',
  CustomZeroRated34_4 = 'VATEX-SA-34-4',
  CustomZeroRated34_5 = 'VATEX-SA-34-5',
  CustomZeroRated35 = 'VATEX-SA-35',
  CustomZeroRated36 = 'VATEX-SA-36',
  CustomZeroRatedEdu = 'VATEX-SA-EDU',
  CustomZeroRatedHealth = 'VATEX-SA-HEA',
  CustomZeroRatedMilitary = 'VATEX-SA-MLTRY',
}
