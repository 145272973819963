<div class="container">
    <div class="grid">
        <div class="md:col-12">
            <div class="error-template">
                <h1>
                    Oops!</h1>
                <h2>
                    404 Not Found</h2>
                <div class="error-details">
                    Sorry, an error has occured, Requested page not found!
                    <a pendo-tracker="epa" href="/login" *ngIf="!user || !user.permissions || user.permissions.length === 0">Back to login!</a>
                </div>
            </div>
        </div>
    </div>
</div>
