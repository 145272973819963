import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Intercom } from 'ng-intercom';
import { MenuItem } from 'primeng/api';
import { Subscription, tap, timer } from 'rxjs';
import { UserAuthenticationService } from '../auth/services/user-authentication.service';
import { UserService } from '../auth/services/user.service';
import { Merchant } from '../shared/model/Merchant';
import { CustomToastService } from '../shared/services/custom-toast.service';
import { getLocalizedUrl } from '../utils/localizedUrl';
import { LocalStorageService } from '../core/services/local-storage.service';
import { LocalStorageKey } from '../shared/constants';
import { AuthUser } from '../auth/auth.models';

@Component({
  selector: 'rw-future-subscription',
  templateUrl: './future-subscription.component.html',
  styleUrls: ['./future-subscription.component.scss'],
})
export class FutureSubscriptionComponent implements OnInit {
  futureSubscriptionDate = '';

  users: Merchant | any;

  lang: string;

  langCss: string;

  changeLang: string;

  languageTitle: string;

  userMenuItems: MenuItem[];

  private switchLanguageSub: Subscription | null;

  subscriptionRefs: Subscription;

  constructor(private router: Router,
    private translate: TranslateService,
    public intercom: Intercom,
    private customToast: CustomToastService,
    private userService: UserService,
    private userAuthenticationService: UserAuthenticationService,
    private localStorageService: LocalStorageService,
  ) {
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.switchLanguageSub = null;
    this.translate.setDefaultLang(this.lang);
    this.langCss = this.lang === 'en' ? 'ltr' : 'rtl';
    if (this.lang === 'en') {
      this.changeLang = 'ar';
      this.languageTitle = 'Arabic';
    } else {
      this.langCss = 'rtl';
      this.changeLang = 'en';
      this.languageTitle = 'English';
    }
  }

  ngOnInit() {
    const user = this.localStorageService.getItem<AuthUser>(
      LocalStorageKey.User,
    );
    this.userService.getUser().pipe(
      tap((currUser) => {
        this.users = currUser;
      }),
    ).subscribe();

    if (user) {
      this.translate.use(this.lang);

      const subscriptionDate = new Date(user.RewaaAccountSubscription.startDate);
      if (this.lang === 'en') {
        this.futureSubscriptionDate = subscriptionDate.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      } else {
        this.translate.get('primeng.monthNames').subscribe((monthNames: string[]) => {
          const numericMonth = subscriptionDate.getMonth();
          const numericYear = subscriptionDate.getFullYear();
          const numericDay = subscriptionDate.getDate();

          const monthName = monthNames[numericMonth];
          this.futureSubscriptionDate = `${numericDay} ${monthName} ${numericYear}`;
        });
      }
    }

    this.userMenuItems = [
      {
        label: this.translate.instant(this.languageTitle),
        icon: 'fa-regular fa-globe',
        styleClass: 'arabic-font',
        command: () => {
          this.switchLanguage(this.changeLang);
        },
      },
      {
        label: this.translate.instant('Logout'),
        icon: 'fa-regular fa-arrow-right-from-bracket',
        command: () => {
          this.logout();
        },
      },
    ];
  }

  openIntercom() {
    this.intercom.show();
  }

  logout() {
    this.userAuthenticationService.logout();
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
      'en',
    );
    this.router.navigateByUrl(`${lang}/login`).then(() => window.location.reload());
  }

  switchLanguage(lang: string) {
    if (this.switchLanguageSub) this.switchLanguageSub.unsubscribe();
    this.translate.get(['langChangeMessage']).subscribe((translations) => {
      this.customToast.success(translations.langChangeMessage);
    });
    this.switchLanguageSub = timer(2000).subscribe(() => {
      this.localStorageService.setItem<string>(
        LocalStorageKey.Language,
        lang,
      );
      window.location.pathname = getLocalizedUrl(lang);
    });
  }
}
