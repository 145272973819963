import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import ArabicLocale from '@angular/common/locales/ar';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { IntercomModule } from 'ng-intercom';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppActions } from './applications/app.action-types';
import { AuthModule } from './auth/auth.module';
import { UserService } from './auth/services/user.service';
import { CoreModule } from './core/core.module';
import { ErrorPageComponent } from './error-page/error-page.component';
import { VariantService } from './inventory/variants/services/variant.service';
import { AppState, metaReducers, reducers } from './reducers';
import { GeoLocationService } from './services/geo-location.service';
import { HeaderDetailsService } from './shared/services/header-details.service';
import { RwMissingTranslationHandler } from './shared/services/missing-translation.service';
import { HttpLoaderFactory } from './shared/shared.module';
import { UnauthorisedAccessComponent } from './unauthorised-access/unauthorised-access.component';
import { PlanSubscriptionService } from './users-settings/services/plan-subscription.service';
import { dataDogInitialize } from './utils/datadogRumInitialization';
import { loadLanguageStyles } from './utils/loadLanguageStyles';
import { getLocalizedBasePath } from './utils/localizedUrl';
import { FutureSubscriptionComponent } from './future-subscription/future-subscription.component';
import { UiModule } from './ui/ui.module';
import { CobrowsingService } from './core/services/cobrowsing.service';
import { AppService } from './applications/services/app.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { WebSocketService } from './core/services/web-socket.service';
import { EventsService } from './core/utils/events.service';
import { ExpenseCategoryService } from './expenses/services/expense-category.service';
import { ExpenseService } from './expenses/services/expense.service';
import { NotificationsService } from './firebase-notifications/notifications.service';
import { InvoiceService } from './internal-apps/pos/services/invoice.service';
import { LayoutCategoryService } from './internal-apps/pos/services/layout-category.service';
import { LayoutService } from './internal-apps/pos/services/layout.service';
import { QuoteService } from './internal-apps/pos/services/quote.service';
import { ShareDataService } from './internal-apps/pos/shared/services/share-data.service';
import { PromotionImportService } from './internal-apps/promotion/services/promotion-import.service';
import { PromotionsService } from './internal-apps/promotion/services/promotions.service';
import { BrandService } from './inventory/brands/brand.service';
import { ExportProductsService } from './inventory/import-export/services/export-products.service';
import { ImportProductsTemplateInfoService } from './inventory/import-export/services/import-products-template-info.service';
import { ImportProductsService } from './inventory/import-export/services/import-products.service';
import { SheetService } from './inventory/import-export/services/sheet.service';
import { VariantDataStructureService } from './inventory/products/components/create/product/variable-product/services/variant-data-structure.service';
import { CategoryService } from './inventory/products/services/category.service';
import { EcardService } from './inventory/products/services/ecard.service';
import { ProductService } from './inventory/products/services/product.service';
import { TagsService } from './invoices/services/tags.service';
import { UploadValidationService } from './invoices/services/upload-validation.service';
import { TransferStockService } from './invoices/transfer-stock/services/transfer-stock.service';
import { OrderInvoiceService } from './orders/invoices/services/order-invoice.service';
import { OrderSettingsService } from './orders/services/order-settings.service';
import { ActivityService } from './referral-program/services/activity.service';
import { ReferralProgramService } from './referral-program/services/referral-program.service';
import { AttachmentsService } from './shared/services/attachments.service';
import { CompressorService } from './shared/services/compressor.service';
import { CustomFieldInputService } from './shared/services/custom-field-input.service';
import { CustomToastService } from './shared/services/custom-toast.service';
import { FileUploadService } from './shared/services/file-upload.service';
import { KeyboardShortcutServiceOld } from './shared/services/keyboard-shortcut-old.service';
import { LabelMappingService } from './shared/services/label-mapping.service';
import { LabelsService } from './shared/services/labels-service';
import { PermissionHelperService } from './shared/services/permission.helper.service';
import { SupplierService } from './shared/services/supplier.service';
import { CustomerCacheService } from './shared/services/offline/customer-cache.service';
import { OfflineCustomerService } from './shared/services/offline/offline-customer.service';
import { OfflineFirstService } from './shared/services/offline/offline-first.service';
import { OfflineInvoiceService } from './shared/services/offline/offline-invoice.service';
import { OfflineProductService } from './shared/services/offline/offline-product.service';
import { OnlineOfflineService } from './shared/services/offline/online-offline.service';
import { VariantCacheService } from './shared/services/offline/variant-cache.service';
import { ConfigStoreService } from './shared/services/offline/indexedDB/config-store.service';
import { CustomerStoreService } from './shared/services/offline/indexedDB/customer-store.service';
import { InvoiceStoreService } from './shared/services/offline/indexedDB/invoice-store.service';
import { ProductVariantStoreService } from './shared/services/offline/indexedDB/product-variant-store.service';
import { PromotionStoreV2Service } from './shared/services/offline/indexedDB/promotion-store-v2.service';
import { PromotionStoreService } from './shared/services/offline/indexedDB/promotion-store.service';
import { FormFileUploadUtility } from './shared/utility/form.file.upload.utility';
import { SettingsService } from './users-settings/services/settings.service';
import { TranslationCacheResolver } from './users-settings/services/translation-cache.resolver';
import { AdvancedAccountingService } from './advanced-accounting/advanced-accounting.service';
import { ConfigurationService } from './configuration/configuration.service';
import { LoginInProcessService } from './auth/services/login-in-process.service';
import { SubscriptionValidationService } from './auth/services/subscription-validation.service';
import { RoutePermissionsService } from './shared/services/route-permissions.service';
import { VariantStoreService } from './shared/services/offline/indexedDB/variant-store.service';
import { ImportServiceFactory } from './applications/services/integrations-v2/import.service.factory';
import { ImportToAppServiceMapper } from './applications/services/integrations-v2/import-to-app-service.mapper';
import { ImportService } from './applications/services/integrations-v2/import.service';
import { OnboardingService } from './auth/services/onboarding/onboarding.service';
import { SubscriptionFallbackComponent } from './subscription-fallback/subscription-fallback.component';
import { ProductLinkNotificationSourceManagerBehaviorService } from './orders-v2/services/behavior/product-link-notification-source-manager/product-link-notification-source-manager-behavior.service';
import { PermissionHelperServiceV2 } from './shared/services/permission-helper-service-v2';
import { ApplicationsHelperService } from './shared/services/applications.helper.service';
import { UserPreferencesService } from './shared/services/user-preferences.service';

registerLocaleData(ArabicLocale);

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    UnauthorisedAccessComponent,
    FutureSubscriptionComponent,
    SubscriptionFallbackComponent,
  ],
  imports: [
    CoreModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    EntityDataModule.forRoot({}),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: RwMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    UiModule,
    NgxFileDropModule,
    ScriptLoaderModule,
  ],
  providers: [
    Title,
    PlanSubscriptionService,
    GeoLocationService,
    CobrowsingService,
    PrimeNGConfig,
    VariantService,
    AdvancedAccountingService,
    ConfigurationService,
    UserService,
    LoginInProcessService,
    SubscriptionValidationService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadLanguageStyles,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: dataDogInitialize,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<AppState>) => () =>
        store.dispatch(AppActions.trueLoadAllApps()),
      multi: true,
      deps: [Store],
    },
    HeaderDetailsService,
    {
      provide: APP_BASE_HREF,
      useValue: getLocalizedBasePath(),
    },
    AppService,
    LocalStorageService,
    WebSocketService,
    EventsService,
    ExpenseCategoryService,
    ExpenseService,
    NotificationsService,
    InvoiceService,
    LayoutCategoryService,
    LayoutService,
    QuoteService,
    ShareDataService,
    PromotionImportService,
    PromotionsService,
    BrandService,
    ExportProductsService,
    ImportProductsTemplateInfoService,
    ImportProductsService,
    SheetService,
    VariantDataStructureService,
    CategoryService,
    EcardService,
    ProductService,
    TagsService,
    UploadValidationService,
    TransferStockService,
    OrderInvoiceService,
    OrderSettingsService,
    ActivityService,
    ReferralProgramService,
    AttachmentsService,
    CompressorService,
    CustomFieldInputService,
    CustomToastService,
    FileUploadService,
    HeaderDetailsService,
    KeyboardShortcutServiceOld,
    LabelMappingService,
    LabelsService,
    PermissionHelperService,
    PermissionHelperServiceV2,
    OnboardingService,
    RoutePermissionsService,
    SupplierService,
    CustomerCacheService,
    OfflineCustomerService,
    OfflineFirstService,
    OfflineInvoiceService,
    OfflineProductService,
    OnlineOfflineService,
    VariantCacheService,
    ConfigStoreService,
    CustomerStoreService,
    InvoiceStoreService,
    ProductVariantStoreService,
    PromotionStoreV2Service,
    PromotionStoreService,
    VariantStoreService,
    FormFileUploadUtility,
    SettingsService,
    TranslationCacheResolver,
    TranslateService,
    ImportServiceFactory,
    ImportService,
    ImportToAppServiceMapper,
    ProductLinkNotificationSourceManagerBehaviorService,
    ApplicationsHelperService,
    UserPreferencesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
