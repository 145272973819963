import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ledgerReferenceIdDisplay',
})
export class LedgerReferenceIdDisplayPipe implements PipeTransform {

  transform(id: any, type: string): any {
    if (type === 'expense')
      return `Exp-${id}`;
    return id;
  }
}
