import { FormControl, FormGroup } from '@angular/forms';
import {
  PromotionDiscountType,
  PromotionTypes,
} from '../../../model/promotion.types';

export enum PromotionSelectionType {
  ALL_PRODUCTS = 'all-products',
  SELECTED_PRODUCTS = 'selected-products',
  SELECTED_CATEGORY = 'selected-category',
  IMPORT_PRODUCTS = 'import-products',
}

export interface PromotionProduct extends PromotionItem {
  name: string;
  sku: string;
}

export type PromotionCategory = {
  id: number;
  name: string;
};

export enum PromotionStartDateOptions {
  Now = 'now',
  Tomorrow = 'tomorrow',
  NextWeek = 'nextWeek',
  NextMonth = 'nextMonth',
}

export enum PromotionStates {
  Active = 'Active',
  Scheduled = 'Scheduled',
  Ended = 'Ended',
}

export interface PromotionItem {
  id: number;
}

export interface PromotionXForm {
  selection: FormControl<PromotionSelectionType>;
  items: FormControl<PromotionItem[]>;
  quantity?: FormControl<number>;
}

export interface PromotionYForm {
  selection: FormControl<PromotionSelectionType>;
  items: FormControl<PromotionItem[]>;
  quantity: FormControl<number>;
}

export interface PromotionForm {
  name: FormControl<string>;
  startDate: FormControl<Date>;
  startTime: FormControl<Date>;
  promotionStartDateOption: FormControl<PromotionStartDateOptions>;
  endDate: FormControl<Date>;
  endTime: FormControl<Date>;
  promotionEndDateOption: FormControl<number>;
  stockLocations: FormControl<number[]>;
  discountType: FormControl<PromotionDiscountType>;
  amount: FormControl<number>;
  x: FormGroup<PromotionXForm>;
  y?: FormGroup<PromotionYForm>;
  type: FormControl<PromotionTypes>;
}
