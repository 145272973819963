/* eslint-disable arrow-body-style */
import { Observable, MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export function distinctUntilChangedV2<T>(
  compare?: (x: T, y: T) => boolean
): MonoTypeOperatorFunction<T>;
export function distinctUntilChangedV2<T, K>(
  compare: (x: K, y: K) => boolean, keySelector: (x: T) => K
): MonoTypeOperatorFunction<T>;

export function distinctUntilChangedV2<T, K>(
  compare?: (x: K, y: K) => boolean, keySelector?: (x: T) => K,
): MonoTypeOperatorFunction<T> {
  let firstValue = true;
  return (source: Observable<T>): Observable<T> => {
    return new Observable((subscriber) => {
      return source.subscribe({
        next(value) {
          if (firstValue) {
            subscriber.next(value);
            subscriber.next(value);
            firstValue = false;
          } else {
            subscriber.next(value);
          }
        },
      });
    }).pipe(
      distinctUntilChanged(compare, keySelector),
    );
  };
}
