export const generateShortHash = (inputString: string): string => {
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    const char = inputString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32-bit integer
  }

  hash = Math.abs(hash);

  // Convert to 6-character hexadecimal string
  return hash.toString(16).substring(0, 6);
};
