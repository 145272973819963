import { ProductEnums, ProductTypes } from '@rewaa-team/types';
import { CartTypes } from '..';

export class OfflineStockService {
  getQuantityUpdatesForStockLocation(
    lineItems: CartTypes.SellApiLineItem[],
    stockLocationId: number,
  ): ProductTypes.ProductVariantToStockLocationUpdate[] {
    const variantsToUpdate: ProductTypes.ProductVariantToStockLocationUpdate[] =
      [];
    for (let index = 0; index < lineItems.length; index++) {
      const lineItem = lineItems[index];
      const variantDetails: ProductTypes.VariantDetails[] = [];
      if (lineItem.type === ProductEnums.VariantType.Package) {
        variantDetails.push({
          productVariantId: lineItem.packs[0].variantId,
          rate: lineItem.packs[0].rate,
        });
      } else if (lineItem.type === ProductEnums.VariantType.Composite) {
        lineItem.composites?.forEach((composingProduct) => {
          if (lineItem.packs.length) {
            const pack = lineItem.packs?.find(
              (p) => p.variantId === composingProduct.variantId,
            );
            if (!pack) {
              return;
            }
            variantDetails.push({
              productVariantId: composingProduct.variantId,
              rate: composingProduct.rate,
              productVariant: {
                id: composingProduct.variantId,
                type: composingProduct.type as string as ProductEnums.ProductVariantType,
                productVariantId: pack.variantId,
                rate: pack.rate,
              },
            });
          } else {
            variantDetails.push({
              productVariantId: composingProduct.variantId,
              rate: composingProduct.rate,
              productVariant: {
                id: composingProduct.variantId,
                type: composingProduct.type,
              } as any,
            });
          }
        });
      }
      if (lineItem.extras.length) {
        lineItem.extras.forEach((extra) => {
          if (extra.variantId) {
            variantsToUpdate.push({
              productVariantId: extra.variantId,
              quantityChanged: extra.rate * extra.quantity,
              stockLocationId,
              variantType: ProductEnums.ProductVariantType.child,
              variantToInvoiceId: lineItem.variantId,
            });
          }
        });
      }
      const variantToTracks = this.mapVariantToTrackForUpdateStocks(lineItem);
      const ecards = this.mapEcardsForUpdateStocks(lineItem);
      variantsToUpdate.push({
        productVariantId: lineItem.variantId,
        quantityChanged: lineItem.quantity,
        stockLocationId,
        variantType: lineItem.type as string as ProductEnums.ProductVariantType,
        variantToInvoiceId: lineItem.variantId,
        variantDetails,
        ecards,
        variantToTracks,
      });
    }
    return variantsToUpdate;
  }

  private mapVariantToTrackForUpdateStocks(
    lineItem: CartTypes.SellApiLineItem,
    useAbsoluteQuantity = false,
  ): ProductTypes.VariantToTrackForUpdate[] {
    return (
      lineItem.trackDetails?.map(
        (track) =>
          ({
            id: track.id,
            expiryDate: new Date(),
            issueDate: new Date(),
            quantity: useAbsoluteQuantity
              ? Math.abs(track.quantity)
              : track.quantity,
            supplierId: 0,
            trackNo: track.trackNo,
            variantToInvoiceTrackId: track.id,
          } as ProductTypes.VariantToTrackForUpdate),
      ) || []
    );
  }

  private mapEcardsForUpdateStocks(
    lineItem: CartTypes.SellApiLineItem,
    returnEcard = false,
  ): ProductTypes.ECardForUpdate[] {
    return (
      lineItem.eCards?.map(
        (ecard) =>
          ({
            id: ecard.id,
            code: ecard.code,
            productVariantId: lineItem.variantId,
            variantToInvoiceEcardId: ecard.id,
            isSold: returnEcard ? false : undefined,
          } as ProductTypes.ECardForUpdate),
      ) || []
    );
  }
}

export const offlineStockService = new OfflineStockService();
