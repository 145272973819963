import {
  IndexableType,
  PromiseExtended,
  ThenShortcut,
  IndexableTypeArray,
  WhereClause,
  Collection,
} from 'dexie';
import { TableOnSteroids } from './table-on-steroids';
import { WhereClauseOnSteroids } from './where-clause-on-steroids';

export class CollectionOnSteroids implements Collection<any, IndexableType> {
  constructor(private table: TableOnSteroids) {}
  and(filter: (x: any) => boolean): Collection<any, IndexableType> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.and(filter);
  }
  clone(props?: Object): Collection<any, IndexableType> {
    throw new Error('Method not implemented.');
  }
  count(): PromiseExtended<number>;
  count<R>(thenShortcut: ThenShortcut<number, R>): PromiseExtended<R>;
  async count(thenShortcut?: unknown): Promise<any> {
    this.table.operations.push({
      propertyName: 'count',
      parameters: [thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  distinct(): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'distinct',
      parameters: [],
    });
    return this;
  }
  each(
    callback: (
      obj: any,
      cursor: { key: IndexableType; primaryKey: IndexableType },
    ) => any,
  ): PromiseExtended<void> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.each(callback);
  }
  eachKey(
    callback: (
      key: IndexableType,
      cursor: { key: IndexableType; primaryKey: IndexableType },
    ) => any,
  ): PromiseExtended<void> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.eachKey(callback);
  }
  eachPrimaryKey(
    callback: (
      key: IndexableType,
      cursor: { key: IndexableType; primaryKey: IndexableType },
    ) => any,
  ): PromiseExtended<void> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.eachPrimaryKey(callback);
  }
  eachUniqueKey(
    callback: (
      key: IndexableType,
      cursor: { key: IndexableType; primaryKey: IndexableType },
    ) => any,
  ): PromiseExtended<void> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.eachUniqueKey(callback);
  }
  filter(filter: (x: any) => boolean): Collection<any, IndexableType> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.filter(filter);
  }
  first(): PromiseExtended<any>;
  first<R>(thenShortcut: ThenShortcut<any, R>): PromiseExtended<R>;
  async first(thenShortcut?: unknown): Promise<any> {
    this.table.operations.push({
      propertyName: 'first',
      parameters: [thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  keys(): PromiseExtended<IndexableTypeArray>;
  keys<R>(
    thenShortcut: ThenShortcut<IndexableTypeArray, R>,
  ): PromiseExtended<R>;
  async keys(thenShortcut?: unknown): Promise<any> {
    this.table.operations.push({
      propertyName: 'keys',
      parameters: [thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  primaryKeys(): PromiseExtended<IndexableType[]>;
  primaryKeys<R>(
    thenShortcut: ThenShortcut<IndexableType[], R>,
  ): PromiseExtended<R>;
  async primaryKeys(thenShortcut?: unknown): Promise<any> {
    this.table.operations.push({
      propertyName: 'keys',
      parameters: [thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  last(): PromiseExtended<any>;
  last<R>(thenShortcut: ThenShortcut<any, R>): PromiseExtended<R>;
  async last<R>(thenShortcut?: unknown): Promise<R> {
    this.table.operations.push({
      propertyName: 'last',
      parameters: [thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  limit(n: number): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'limit',
      parameters: [n],
    });
    return this;
  }
  offset(n: number): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'offset',
      parameters: [n],
    });
    return this;
  }
  or(indexOrPrimayKey: string): WhereClause<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'or',
      parameters: [indexOrPrimayKey],
    });
    return new WhereClauseOnSteroids(this.table);
  }
  raw(): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'raw',
      parameters: [],
    });
    return this;
  }
  reverse(): Collection<any, IndexableType> {
    this.table.operations.push({
      propertyName: 'reverse',
      parameters: [],
    });
    return this;
  }
  sortBy(keyPath: string): PromiseExtended<any[]>;
  sortBy<R>(
    keyPath: string,
    thenShortcut: ThenShortcut<any[], R>,
  ): PromiseExtended<R>;
  async sortBy<R>(keyPath: unknown, thenShortcut?: unknown): Promise<R> {
    this.table.operations.push({
      propertyName: 'sortBy',
      parameters: [keyPath, thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  toArray(): PromiseExtended<any[]>;
  toArray<R>(thenShortcut: ThenShortcut<any[], R>): PromiseExtended<R>;
  async toArray<R>(thenShortcut?: unknown): Promise<R> {
    this.table.operations.push({
      propertyName: 'toArray',
      parameters: [thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  uniqueKeys(): PromiseExtended<IndexableTypeArray>;
  uniqueKeys<R>(
    thenShortcut: ThenShortcut<IndexableTypeArray, R>,
  ): PromiseExtended<R>;
  async uniqueKeys<R>(thenShortcut?: unknown): Promise<R> {
    this.table.operations.push({
      propertyName: 'uniqueKeys',
      parameters: [thenShortcut],
    });
    return await this.table.operateOnSteroids();
  }
  until(
    filter: (value: any) => boolean,
    includeStopEntry?: boolean,
  ): Collection<any, IndexableType> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.until(filter, includeStopEntry);
  }
  delete(): PromiseExtended<number>;
  async delete(): Promise<number> {
    this.table.operations.push({
      propertyName: 'delete',
      parameters: [],
    });
    return await this.table.operateOnSteroids();
  }
  modify(
    changeCallback: (obj: any, ctx: { value: any }) => boolean | void,
  ): PromiseExtended<number>;
  modify(changes: { [keyPath: string]: any }): PromiseExtended<number>;
  modify(changes: unknown): Promise<number> {
    let response;
    if (this.table.operations.length) {
      response = this.table.processOperationsOnMainThread();
    }
    return response.modify(changes);
  }
}
