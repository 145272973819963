/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { SerialProductComponent } from 'src/app/shared/components/serial-product/serial-product.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BatchedProductsComponent } from '../batched-products/batched-products.component';
import { LocalStorageKey } from '../../constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';

export enum ActivePage {
  SERIAL = 'serial',
  BATCH = 'batch',
}

@Component({
  selector: 'rw-tracked-products',
  templateUrl: './tracked-products.component.html',
  styleUrls: ['./tracked-products.component.scss'],
})
export class TrackedProductsComponent implements OnInit {
  serialVariants: ProductVariant[] = [];

  batchVariants: ProductVariant[] = [];

  locationId: number;

  invoiceType: string;

  @ViewChild(SerialProductComponent, { static: false })
  serialProductComponent: SerialProductComponent;

  @ViewChild(BatchedProductsComponent, { static: false })
  batchProductComponent: BatchedProductsComponent;

  activeIndex = 0;

  items: MenuItem[] = [];

  activePage = ActivePage.SERIAL;

  activePageEnum = ActivePage;

  styleClass = '';

  serialFormValid: boolean;

  batchFormValid: boolean;

  currentLanguage: string;

  langCss: 'ltr' | 'rtl';

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private modalRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
  ) {
    this.currentLanguage = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
      'en',
    );
    this.translate.use(this.currentLanguage);
    this.langCss = this.currentLanguage === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit(): void {
    if (this.dynamicDialogConfig?.data) {
      this.serialVariants = this.dynamicDialogConfig?.data?.serialVariants;
      this.batchVariants = this.dynamicDialogConfig?.data?.batchVariants;
      this.invoiceType = this.dynamicDialogConfig?.data?.invoiceType;
      this.locationId = this.dynamicDialogConfig?.data?.locationId;
    }
    this.setMenuItems();

    this.serialFormValid = !this.serialVariants?.length;
    this.batchFormValid = !this.batchVariants?.length;

    if (this.items.length === 1) {
      this.styleClass = 'single-item';
    } else {
      this.styleClass = 'multiple-item';
    }
  }

  setMenuItems() {
    if (this.serialVariants.length) {
      this.items.push({
        label: this.translate.instant('Serial Number Tracked Products'),
        command: () => {
          this.activePage = ActivePage.SERIAL;
          this.activeIndex = 0;
        },
      });
    }

    if (this.batchVariants.length) {
      this.activePage = this.serialVariants.length
        ? ActivePage.SERIAL
        : ActivePage.BATCH;

      this.items.push({
        label: this.translate.instant('Batched Tracked Products'),
        command: () => {
          this.activePage = ActivePage.BATCH;
          this.activeIndex = this.items.length - 1;
        },
      });
    }
  }

  formValid() {
    if (this.serialProductComponent) {
      this.serialFormValid =
        this.serialProductComponent.trackFormArray.valid &&
        this.validateSerialFormQuantity;
    }

    if (this.batchProductComponent) {
      this.batchFormValid =
        this.batchProductComponent.trackFormArray.valid &&
        this.validateBatchFormQuantity;
    }

    return this.serialFormValid && this.batchFormValid;
  }

  get validateSerialFormQuantity() {
    if (!this.serialProductComponent) {
      return true;
    }

    return this.serialProductComponent.validateFormQuantity();
  }

  get validateBatchFormQuantity() {
    if (!this.batchProductComponent) {
      return true;
    }

    return this.batchProductComponent.validateFormQuantity();
  }

  save() {
    if (this.formValid()) {
      this.modalRef.close({
        serialVariants: this.serialVariants,
        batchVariants: this.batchVariants,
      });
    }
  }

  cancel() {
    this.modalRef.close();
  }
}
