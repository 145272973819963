import Currency from 'currency.js';
import { DeviceBreakPoints } from 'src/app/internal-apps/pos/utils/constants';

export const roundToFixedDigits = (value: number,
  decimals: number = 2): number => new Currency(value, { precision: decimals }).value;
export const formatToFixedDigits = (value: number, minDecimals: number = 2, maxDecimals: number = 2): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  });
  return formatter.format(value);
};
export const formatToCompareTime = (date1: any, date2: any) => {
  const time = Math.floor((date2.getTime() - date1.getTime()) / (60000));
  if (time >= 1 && time < 60) {
    return { time, type: 'mins' };
  }
  if (time >= 60 && time < 1440) return { time: Math.floor(time / 60), type: 'hours' };
  if (time >= 1440 && time < 43200) return { time: Math.floor(time / 1440), type: 'days' };
  if (time >= 43200 && time < 525600) return { time: Math.floor(time / 43200), type: 'months' };
  if (time >= 525600) return { time: Math.floor(time / 525600), type: 'year' };
  return { time: Math.floor((date2.getTime() - date1.getTime()) / (1000)), type: 'seconds' };
};

export const addNumberPadding = (num) => (`0${num}`).slice(-2);

export const isMobile = () => window.innerWidth <= DeviceBreakPoints.TabletPortrait;

export const isTouchDevice = (): boolean =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  'msMaxTouchPoints' in navigator;
