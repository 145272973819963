import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { getSettings } from 'src/app/internal-apps/pos/actions/settings.action';
import { selectAllPosSettings } from 'src/app/internal-apps/pos/selectors/settings.selector';
import { NumberDialogueComponent } from 'src/app/shared/components/number-dialogue/number-dialogue.component';
import { getSettingValue } from 'src/app/internal-apps/pos/utils/common.utils';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import { AppState } from 'src/app/reducers';
import { LocalStorageKey, POS_SETTINGS } from 'src/app/shared/constants';
import { AutoUnsubscribe, AutoUnsubscribeI } from 'src/app/shared/decorators/auto-unsubscribe';
import { PaymentMethod } from 'src/app/shared/model/PaymentMethod';
import { CustomToastService } from '../../../../services/custom-toast.service';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-receive-debit',
  templateUrl: './receive-debit.component.html',
  styleUrls: ['./receive-debit.component.scss'],
})
@AutoUnsubscribe
export class ReceiveDebitComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  @Input() content: any;

  form: UntypedFormGroup;

  langCss: string;

  lng: string;

  paymentMethods: PaymentMethod[];

  remainingDebit = 0;

  totalPaidAmount = 0;

  autoPayValue: number[] = [50, 100, 500, 0.5, 1, 5, 10];

  numberDialogValue: string;

  constructor(
    private dialogService: DialogService,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private toast: CustomToastService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.setDefaultLang(this.lng);
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    if (this.content.register.PaymentMethodToRegisters) {
      const paymentMethodToRegisters = this.content.register.PaymentMethodToRegisters;
      this.paymentMethods = paymentMethodToRegisters.map((pmr) => pmr.PaymentMethod);
    }

    if (this.content.invoice.PayableInvoice.PaymentToPayableInvoices) {
      const paymentInvoices = this.content.invoice.PayableInvoice.PaymentToPayableInvoices;
      this.content.invoice.totalPaidAmount = paymentInvoices.reduce(
        (a, b) => +a + +b.returnInvoicePaidAmount, 0,
      );
    } else {
      this.content.invoice.totalPaidAmount = 0;
    }
    this.content.invoice.debitAmount = InvoicesCalculator.subtract(
      this.content.invoice.PayableInvoice.debitAmount, this.content.invoice.totalPaidAmount,
    );

    this.createForm();

    this.store.dispatch(getSettings());
    this.store.pipe(
      select((selectAllPosSettings)),
      distinctUntilChanged(),
      tap((settings) => {
        if (settings.length > 0) {
          this.numberDialogValue = getSettingValue(settings, POS_SETTINGS.NumberDialogue);
        }
      }),
    ).subscribe();
  }

  showNumberDialogue() {
    if (this.numberDialogValue === '1') {
      this.dialogService.open(NumberDialogueComponent, {
        styleClass: 'pos-number-modal',
        data: {
          amount: this.form.value.amount,
          onOk: this.onSetAmount.bind(this),
        },
      });
    }
  }

  onSetAmount(num: number) {
    this.form.patchValue({ amount: num });
  }

  createForm() {
    this.form = this.fb.group({
      amount: [this.content.invoice.debitAmount, Validators.required],
      paymentMethodName: ['', Validators.required],
    });
    this.subscriptionRefs = this.form.get('amount').valueChanges
      .pipe(
        tap((amount) => {
          this.remainingDebit = InvoicesCalculator.subtract(
            this.content.invoice.debitAmount, amount,
          );
          if (this.remainingDebit < 0) {
            this.toast.error(this.translate.instant('This number is larger than the required amount.'));
          }
        }),
      ).subscribe();
  }

  get formValid() {
    if (this.form.get('paymentMethodName').value && this.form.get('amount').value > 0 && this.remainingDebit >= 0) {
      return true;
    }
    return false;
  }

  onAddSetAmount(amount: number) {
    const total = this.form.get('amount').value;
    if (total === this.content.invoice.debitAmount) {
      this.form.get('amount').patchValue(amount);
    } else {
      this.form.get('amount').patchValue(InvoicesCalculator.add(amount, total));
    }
  }

  receiveDebit() {
    if (!this.form.get('paymentMethodName').value) {
      return;
    }
    if (this.remainingDebit < 0) {
      this.toast.error(this.translate.instant('This number is larger than the required amount.'));
      return;
    }

    this.content.invoice.amount = this.form.get('amount').value;
    const selectedPaymentMethod = this.paymentMethods.filter((pm) => pm.id === this.form.get('paymentMethodName').value)[0];
    const reqBody = {
      customerId: this.content.invoice.customerId,
      customerName: this.content.invoice.customerName,
      invoicesDetails: [this.content.invoice],
      paymentMethod: selectedPaymentMethod,
      note: '',
      totalPaid: this.content.invoice.amount,
      totalDebitBeforePayment: this.content.invoice.debitAmount,
      totalDebitAfterPayment: this.remainingDebit,
    };
    this.content.pay_btn.handler(reqBody);
  }
}
