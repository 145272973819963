import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/auth';
import {
  Timestamp, addDoc, collection, onSnapshot,
} from 'firebase/firestore';
import { FirebaseUser } from '../../../firebase-notifications/notification.interface';
import { NotificationsService } from '../../../firebase-notifications/notifications.service';
import { Merchant } from '../../model/Merchant';
import { CustomToastService } from '../custom-toast.service';
import { FirebaseCollections } from '../enum/firebase-constants';
import { FirebaseAppService } from './firebase-app.service';
import { FirebaseQueryProviderService } from './firebase-query-provider.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FirebaseUserService implements OnDestroy {
  private firebaseUser;

  notificationRead : Unsubscribe;

  constructor(
    private notificationsService:NotificationsService,
    private firebaseQueryProviderService: FirebaseQueryProviderService,
    private firebaseAppService: FirebaseAppService,
  ) {
  }

  ngOnDestroy(): void {
    this.unregisterListener();
  }

  unregisterListener() {
    if (this.notificationRead) {
      this.notificationRead();
    }
  }

  async initializeUser(user: Merchant) {
    if (environment.enableFirestore) {
      try {
        await this.setFirebaseUser(user.id, user.schemaName);
      } catch (error) {
        console.error('firebase err', error);
      }
    }
  }

  async setFirebaseUser(userId: number, schemaName: string) {
    const firestore = this.firebaseAppService.getFirestore();
    const userCollection = collection(firestore, FirebaseCollections.Users);
    const queryRef = this.firebaseQueryProviderService.buildGetFirebaseUserQuery(userCollection, schemaName, userId);
    this.notificationRead = onSnapshot(
      queryRef,
      async (snapshot) => { await this.processGetFirebaseUserSnapshot(snapshot, userCollection, userId, schemaName); },
      (error : FirebaseError) => { this.processError(error, 'FIREBASE DOC snapshot err'); },
    );
  }

  async processGetFirebaseUserSnapshot(snapshot, userCollection, userId: number, schemaName: string) {
    if (!snapshot.docs.length) {
      await this.addUserIfDoesntExist(userCollection, userId, schemaName);
    }
    snapshot.docs.forEach((document) => { this.setFirebaseUserInNotificationService(document); });
  }

  async addUserIfDoesntExist(userCollection, userId: number, schemaName: string) {
    await addDoc(userCollection, {
      n_last_read: Timestamp.fromDate(new Date()),
      uid: `${userId}`,
      mid: schemaName,
    });
  }

  setFirebaseUserInNotificationService(document: any) {
    this.firebaseUser = this.getFirebaseUserFromDocument(document);
    this.notificationsService.setFirebaseUser(this.firebaseUser);
  }

  private getFirebaseUserFromDocument(document) : FirebaseUser {
    return {
      id: document.id,
      ...document.data(),
    } as unknown as FirebaseUser;
  }

  getFirebaseUserLastReadInMillis() {
    return this.firebaseUser.n_last_read.toMillis();
  }

  getFirebaseUserId() {
    return this.firebaseUser.id;
  }

  getFirebaseUser() {
    return this.firebaseUser;
  }

  processError(error, message : string) {
    console.log(message, { error });
  }
}
