import {
  Component, Input, Output, ViewEncapsulation, EventEmitter,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'rw-toggle-switch',
  templateUrl: './rewaa-toggle-switch.component.html',
  styleUrls: ['./rewaa-toggle-switch.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RewaaToggleSwitchComponent {
  @Input() checked: boolean;

  @Output() changeEmit = new EventEmitter<{ target:{ checked:boolean } }>();

  @Input() controlName: string;

  @Input() label: string;

  onChange(event) {
    this.changeEmit.emit(event);
  }
}
