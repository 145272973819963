import { Component, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LocalStorageKey } from '../../constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';

interface ButtonData {
  handler: () => void;
  text: string;
  enabled: boolean;
}
@Component({
  selector: 'rw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
  lng: string;

  showScroll = false;

  loading = false;

  content: {
    templateName?: string;
    title: string;
    body?: string;
    install_btn?: ButtonData;
    success_btn?: ButtonData;
    cancel_btn?: ButtonData;
    data: [];
    unlink_btn: ButtonData;
    unpublish_btn: ButtonData;
    uninstall: ButtonData;
    uninstall_btn: ButtonData;
    get_link_btn?: ButtonData;
    class: string;
    modalParagraph?: string;
    modalParagraphTitle?: string;
    errorParagraph?: string;
    errorParagraphTitle?: string;
    btnId?: string;
    resolve_btn?: ButtonData;
    resolveAll_btn?: ButtonData;
    pendoTag?: string;
    cancel_progress_btn?: ButtonData;
    showScroll?: boolean;
  };

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit() {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
      'en',
    );
    this.translate.setDefaultLang(this.lng);
    this.showScroll = this.content?.showScroll || false;
    if (this.config?.data?.content) {
      this.content = this.config?.data?.content; // map data iff using primeng dialog.
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }
}
