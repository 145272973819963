import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { translateAccountHeadName } from '@rewaa-team/rewaa-accounting';

@Pipe({
  name: 'ahTranslate',
})
export class AcHeadTransPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(title: string, separator = ' '): string {
    return translateAccountHeadName(
      (key) => this.translateService.instant(key),
      title,
      separator,
    );
  }
}
