import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

// Services
import { Intercom } from 'ng-intercom';
import { LocalStorageService } from '../../../../core/services/local-storage.service';

// Utilities
import { isMobile } from '../../../../ui/utility/calculation.utility';

// Constants
import { LocalStorageKey } from '../../../../shared/constants';

@Component({
  selector: 'rw-license-key',
  templateUrl: './license-key.component.html',
  styleUrls: ['./license-key.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LicenseKeyComponent implements OnInit {
  @Input() dialogVisible = false;

  @Output() closeDialog = new EventEmitter<unknown>();

  lang: string;

  dialogPosition: string = 'center';

  constructor(
    private localStorageService: LocalStorageService,
    public intercom: Intercom,
  ) {
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
  }

  ngOnInit(): void {
    if (isMobile()) {
      this.dialogPosition = 'bottom';
    } else {
      this.dialogPosition = 'center';
    }
  }

  contactSupport(): void {
    this.intercom.show();
  }

  closeThisDialog(): void {
    this.closeDialog.emit();
    this.dialogVisible = false;
  }
}
