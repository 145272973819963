
<div class="mx-auto wdh-50 mt-5" [ngClass]="langCss">
    <div class="lang-change">
        <label class="switch">
            <input pendo-tracker="aui" type="checkbox" [checked]="changeLang === 'ar' ? true:false" (change)="switchLang(changeLang)">
            <span class="switch-left">English</span>
            <span class="switch-right">عربي</span>
        </label> 
    </div>
    <p-card styleClass="p-3">
        <div class="text-center">
            <img src="../../../../assets/icons/Icon-material-lock.svg" alt="lock" />
            <h3 class="font-bold mt-3">{{ 'Set Your Password' | translate }}</h3>
            <p>{{ 'This is the password you will use to Sign in' | translate }}</p>
        </div>
        <form (ngSubmit)="updatePassword()" [formGroup]="fGroup" class="mt-3">
            <div class="form-row">
                <div class="md:col-6">
                    <div class="center-box">
                        <input pendo-tracker="aui-1" class="form-control" [formControl]="password" id="password" [type]="fieldTextType ? 'text' : 'password'"
                            placeholder="{{ 'Password' | translate }}">
                        <div class="inst mt-1">
                            <p class="guide">{{ 'Use 6 or more characters' | translate }}</p>
                            <div class="form-group show-hide">
                                <input pendo-tracker="aui-2" type="checkbox" id="showPassword" (change)="toggleFieldTextType()">
                                <label class="font-bold" for="showPassword">
                                    {{'Show Password' | translate}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="pwdConfirm.touched && password.errors?.required" class="alert alert-danger mt-2">
                            {{ 'Password is required' | translate }}
                        </div>
                        <div *ngIf="password.errors?.pattern" class="alert alert-danger mt-2">
                            {{ 'passwordValidation.requirements' | translate }}
                        </div>
                    </div>
                </div>
                <div class="md:col-6">
                    <div class="center-box">
                        <input pendo-tracker="aui-3" [formControl]="pwdConfirm" class="form-control"
                            placeholder=" {{ 'Confirm Password' | translate }}" type="password">
                        <div *ngIf="pwdConfirm.touched && (password.value !== pwdConfirm.value)"
                            class="alert alert-danger mt-2">
                            {{ 'Both passwords must be identical' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid my-5">
                <div class="col">
                    <div class="center-button mx-auto">
                        <button pendo-tracker="aub" pButton
                            label="{{'Get Started' | translate}}"
                            [disabled]="fGroup.invalid || (password.value !== pwdConfirm.value)" type="submit">
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </p-card>
</div>