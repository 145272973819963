<div class="number-dialogue-body" [ngClass]="langCss">
  <div class="border-none">
    <div *ngIf="!hideArrow" class="dummy-box"></div>
    <div class="grid center-box">
      <div class="col" [formGroup]="form">
        <span class="rw__input rw__input-compact">
          <input pendo-tracker="ndi" type="text" pInputText rwSelectOnClick autofocus numeric [decimals]="2" id="amount" class="form-control" formControlName="amount"/>
        </span>
      </div>
    </div>
    <div class="number-grid mt-4 mb-2">
      <div class="grid">
        <div class="md:col-9">
          <div class="grid">
            <div class="md:col-4 border-btm" *ngFor="let num of numbers" [ngClass]="num%3==0?'':'border-lr'"> 
              <div class="number-card pointer justify-content-center flex align-items-center"
                   (click)="onNumClick(num)">
                <span>{{ num }}</span>
              </div>
            </div>
            <div class="md:col-4 offset-section border-lr">
              <div class="number-card pointer justify-content-center flex align-items-center pointer"
                   (click)="onNumClick(0)">
                <span>0</span>
              </div>
            </div>
            <div class="md:col-4 justify-content-center flex align-items-center pointer">
              <div class="number-card pointer decimal-point"
                   (click)="onNumClick('.')" *ngIf="acceptFraction">
                <span>.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="md:col-3">
          <div class="other-number-card flex align-items-center justify-content-center pointer mb-2"
                (click)="onDelete(form.get('amount').value)">
                <img src="../../../../assets/icons/clear.svg">
          </div>
          <div class="other-number-card flex align-items-center justify-content-center pointer mb-2"
                (click)="setAmount('')">
                <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div class="check-number-card flex align-items-center justify-content-center pointer mb-2"
                (click)="onOkClick()">
                <i class="fas fa-check text-white"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
