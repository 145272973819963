import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayoutCategory } from 'src/app/internal-apps/pos/model/Layout';
import { Product } from 'src/app/inventory/model/product';
import { Category, CreatedCategoryResult } from '../../model/category';
import { PageQuery } from '../../../shared/services/pagination-interface';

const API_URL = '/api';

@Injectable()
export class CategoryService {
  private categories$: BehaviorSubject<Category[] | undefined> = new BehaviorSubject(undefined);

  constructor(private http: HttpClient) {
  }

  getCategories(): Observable<Category[]> {
    if (this.categoriesLoaded()) return this.getCategoriesObs();
    return this.http.get<Category[]>(`${API_URL}/categories`)
      .pipe(
        map((response) => {
          const categories = response.map((type) => new Category(type));
          this.categories$.next(categories);
          return categories;
        }),
      );
  }

  private categoriesLoaded(): boolean {
    return Boolean(this.categories$.getValue());
  }

  private getCategoriesObs() {
    return this.categories$.asObservable();
  }

  getCategoriesPage({ limit, offset, query }: PageQuery = { limit: 10, offset: 0, query: '' }) {
    return this.http.get<{ result: Category[], count: number }>(`${API_URL}/categories?offset=${offset}&limit=${limit}&query=${query}`);
  }

  createCategory(category: Category) {
    return this.http.post<CreatedCategoryResult>(`${API_URL}/categories`, category).pipe(
      map((response) => {
        if (response.category) {
          response.category = new Category(response.category);
          this.pushCategory(response.category);
        }
        return response;
      }),
    );
  }

  pushCategory(category: Category) {
    this.categories$.getValue().push(category);
    this.categories$.next(this.categories$.getValue());
  }

  public getCategoryByLevel(level: number): Observable<Category[]> {
    return this.http.get<Category[]>(`${API_URL}/categories?level=${level}`)
      .pipe(
        map((response) => response.map((type) => new Category(type))),
      );
  }

  async getCategoryChildren(id: number): Promise<LayoutCategory[]> {
    return this.http.get<LayoutCategory[]>(`${API_URL}/categories/${id}/children`)
      .pipe(
        map((response) => response.map((type) => new LayoutCategory(type))),
      ).toPromise();
  }

  async getCategoryProduct(id: number, query:{ offset?: string, limit?: string, all?: boolean })
    : Promise<{ count: number, rows : Product[] }> {
    return this.http.get<{ count: number, rows : any[] }>(`${API_URL}/categories/${id}/products?offset=${query.offset}&limit=${query.limit}&all=${query.all || false}`).toPromise();
  }

  async fetchUncategorizedProducts(query:{ offset?: string, limit?: string, all?: boolean })
    : Promise<{ count: number, rows: Product[] }> {
    return this.http.get<{ count: number, rows : any[] }>(`${API_URL}/categories/none/products?offset=${query.offset}&limit=${query.limit}&all=${query.all || false}`).toPromise();
  }

  searchVariantsByCategoryId(id, query) {
    // id none if uncategorised product
    const selectedId = id || 'none';
    return this.http.get(`${API_URL}/categories/${selectedId}/variants?query=${query.search}&offset=${query.offset}&limit=${query.limit}`);
  }

  getCategoryByName(categoryName: string) {
    return of(this.categories$.getValue().find((category) => category.name === categoryName));
  }
}
