export class ZohoInvoiceException extends Error {
  constructor(invoiceId: string, originalError: Error) {
    super(
      `ZOHO Invoice creation failed for ChargeBee Invoice ID: ${invoiceId}.` +
        `Cause: ${originalError.message}.` +
        `Stack Trace: ${
          originalError.stack || 'No stack trace available'
        }`.replace(/\n/g, ' '),
    );
    this.name = 'ZohoInvoiceException';
  }
}

export class ZohoCreditNoteException extends Error {
  constructor(invoiceId: string, creditNoteID: string, originalError: Error) {
    super(
      `ZOHO Credit note creation failed for ChargeBee credit note: ${creditNoteID} against ChargeBee Invoice ID: ${invoiceId}. ` +
        `Cause: ${originalError.message}. ` +
        `Stack Trace: ${
          originalError.stack || 'No stack trace available'
        }`.replace(/\n/g, ' '),
    );
    this.name = 'ZohoCreditNoteException';
  }
}
