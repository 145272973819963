import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  ButtonSize,
  ButtonTypes,
} from '../../../shared/modules/datatable-v3/types';

@Component({
  selector: 'rw-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  /**
   * What Button Type to use
   */
  @Input()
  type: ButtonTypes = ButtonTypes.Primary;

  /**
   * What Button Size to use
   */
  @Input()
  size: ButtonSize = ButtonSize.Medium;

  /**
   * Button contents
   *
   * @required
   */
  @Input()
  label = 'Button';

  /**
   * You can use Font Awesome Icons
   */
  @Input()
  icon = '';

  /**
   * You can add Permissions
   */
  @Input()
  permission = '';

  /**
   * You can add Pendo-Tracker
   */
  @Input()
  tracker = '';

  @Input()
  badge = 0;

  @Input() tooltipText = '';

  @Input() tooltipPosition = "top";

  @Input()
  disabled = false;

  @Input()
  loading = false;

  @Input()
  rounded = false;

  @Input()
  responsive = false;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<Event>();

  buttonClass = 'rw__button';
  bgButtonClass = '';

  ngOnInit(): void {
    const typeClass = this.getTypeClass();
    this.buttonClass = `${this.buttonClass} ${typeClass} rw__button-${this.size}`;
    if(this.type == 'primary-outline'){
      this.bgButtonClass = "bg-teal-20";
    }
  }

  public getTypeClass(): string {
    switch (this.type) {
      case 'primary':
        return 'p-button-raised p-button-primary';

      case 'primary-light':
        return 'p-button-raised p-button-primary-light';

      case 'primary-transparent':
        return 'p-button-text p-button-primary';

      case 'primary-outline':
        return 'p-button-outlined p-button-primary';

      case 'primary-light-outline':
        return 'p-button-outlined p-button-primary-fill-light';

      case 'danger':
        return 'p-button-raised p-button-danger';

      case 'danger-transparent':
        return 'p-button-text p-button-danger';

      case 'danger-outline':
        return 'p-button-outlined p-button-danger';

      case 'grey-transparent':
        return 'p-button-text p-button-plain';

      case 'purple-white':
        return 'p-button-raised p-button-purple';
      default:
        return '';
    }
  }
}
