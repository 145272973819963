import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ShareDataService {
  private modalCloseSignalSource: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  modalCloseSignalData: Observable<void> = this.modalCloseSignalSource.asObservable();

  constructor() { }

  sendModalCloseSignal() {
    this.modalCloseSignalSource.next();
  }
}
