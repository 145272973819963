import { ElementRef, Injectable } from '@angular/core';
import { PrintFrameId } from '../constants';

@Injectable()
export class PrintService {
  private productBarcodeStylesLTR: string;

  private productBarcodeStylesRTL: string;

  /**
   * @deprecated Bye bye problems
   */
  private posReceiptStyle: string;

  private posNewReceiptStyle: string;

  private registerReportStyle: string;

  private returnInvoiceStyle: string;

  private removeStockStyle: string;

  constructor() {
    this.cacheStyles();
  }

  public printPosReceipt(element: ElementRef<HTMLElement> | string): void {
    const body: HTMLElement =
      typeof element === 'string'
        ? document.getElementById(element)
        : element.nativeElement;
    const containerFrame = document.getElementById(
      PrintFrameId.PosReceipt,
    ) as HTMLIFrameElement;
    const frameDoc = containerFrame.contentWindow.document;

    if (frameDoc.body.innerHTML) {
      frameDoc.body.innerHTML = body.innerHTML;
      containerFrame.contentWindow.print();
    } else {
      const html = `
      <html>
          <head>
            <style>
              ${this.posReceiptStyle}
            </style>
          </head>
          <body onload="window.print()">
              ${body.innerHTML}
          </body>
      </html>`;
      frameDoc.open();
      frameDoc.write(html);
      frameDoc.close();
    }
  }
  public printNewPosReceipt(element: ElementRef<HTMLElement> | string): void {
    const body: HTMLElement =
      typeof element === 'string'
        ? document.getElementById(element)
        : element.nativeElement;
    const containerFrame = document.getElementById(
      PrintFrameId.PosReceipt,
    ) as HTMLIFrameElement;
    const frameDoc = containerFrame.contentWindow.document;
    if (frameDoc.body.innerHTML) {
      frameDoc.body.innerHTML = body.innerHTML;
      containerFrame.contentWindow.print();
    } else {
      const html = `
      <html>
          <head>
          <style>
            ${this.posNewReceiptStyle}
            html, body{
              margin: 0 !important;
              padding: 0 !important;
            }
            @media print{
              @page{
                margin: 0 !important;
                padding: 0 !important;
              }
            }
          </style>
            <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&amp;display=swap" rel="stylesheet">
            <link href="https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap" rel="stylesheet">
          </head>
          <body>
              ${body.innerHTML}
              <script>window.onload = function() { window.print(); }</script>
          </body>
      </html>`;
      frameDoc.open();
      frameDoc.write(html);
      frameDoc.close();
    }
  }
  public printReturnOrderInvoice(): void {
    const arabicStyle = document.body.classList.contains('rtl')
      ? `
    body {
      direction: rtl;
      text-align: right;
    }
    .table th,
      .table td {
        text-align: right;
      }
    `
      : '';

    const body: HTMLElement = document.querySelector('#returnStock');
    const containerFrame = document.getElementById(
      PrintFrameId.returnStock,
    ) as HTMLIFrameElement;
    const frameDoc = containerFrame.contentWindow.document;
    if (frameDoc.body.innerHTML) {
      frameDoc.body.innerHTML = body.innerHTML;
      containerFrame.contentWindow.print();
    } else {
      const html = `
      <html>
          <head>
          <style>
          ${this.returnInvoiceStyle}
          ${arabicStyle}
          </head>
          </style>
          <body onload="window.print()">
              ${body.innerHTML}
          </body>
      </html>`;
      frameDoc.open();
      frameDoc.write(html);
      frameDoc.close();
    }
  }

  public printRemoveStock(): void {
    const arabicStyle = document.body.classList.contains('rtl')
      ? `
      body {
        direction: rtl;
        text-align: right;
      }
      .table th,
      .table td {
        text-align: right;
      }
      `
      : '';

    const body: HTMLElement = document.querySelector('#removeStock');
    const containerFrame = document.getElementById(
      PrintFrameId.returnStock,
    ) as HTMLIFrameElement;
    const frameDoc = containerFrame.contentWindow.document;
    if (frameDoc.body.innerHTML) {
      frameDoc.body.innerHTML = body.innerHTML;
      containerFrame.contentWindow.print();
    } else {
      const html = `
      <html>
          <head>
          <style>
          ${this.removeStockStyle}
          ${arabicStyle}
          </head>
          </style>
          <body onload="window.print()">
              ${body.innerHTML}
          </body>
      </html>`;
      frameDoc.open();
      frameDoc.write(html);
      frameDoc.close();
    }
  }

  public printProductBarcode(
    element: ElementRef<HTMLElement> | string,
    noOfCopies: number,
  ): void {
    const containerFrame = document.getElementById(
      PrintFrameId.ProductBarcode,
    ) as HTMLIFrameElement;
    const frameDoc = containerFrame.contentWindow.document;
    const body: HTMLElement =
      typeof element === 'string'
        ? document.getElementById(element)
        : element.nativeElement;
    const direction = body.classList.value;
    const styles =
      direction === 'ltr'
        ? this.productBarcodeStylesLTR
        : this.productBarcodeStylesRTL;
    let bodyInnerHTML = body.innerHTML;
    for (let i = 1; i < noOfCopies; i++) {
      bodyInnerHTML += body.innerHTML;
    }
    const html = `
      <html>
          <head>
            <style>
              ${styles}
            </style>
          </head>
          <body onload="window.print()" style = "margin:0">
              ${bodyInnerHTML}
          </body>
      </html>`;
    frameDoc.open();
    frameDoc.write(html);
    frameDoc.close();
  }

  public printRegisterReport(element: ElementRef<HTMLElement>): void {
    const body: HTMLElement = element.nativeElement;
    const containerFrame = document.getElementById(
      PrintFrameId.RegisterReport,
    ) as HTMLIFrameElement;
    const frameDoc = containerFrame.contentWindow.document;
    const html = `
      <html>
          <head>
            <style>
              ${this.registerReportStyle}
            </style>
          </head>
          <body onload="window.print()">
              ${body.innerHTML}
          </body>
      </html>`;
    frameDoc.open();
    frameDoc.write(html);
    frameDoc.close();
  }

  private async cacheStyles(): Promise<void> {
    const [
      productBarcodeCssLTR,
      productBarcodeCssRTL,
      posReceiptCss,
      registerReportCSS,
      returnInvoice,
      removeStock,
      posNewReceiptCss,
    ] = await Promise.all([
      fetch('/assets/css/print-product-barcode-ltr.css'),
      fetch('/assets/css/print-product-barcode-rtl.css'),
      fetch('/assets/css/print-pos-receipt.css'),
      fetch('/assets/css/print-register-report.css'),
      fetch('/assets/css/print-return-invoice.css'),
      fetch('/assets/css/print-remove-stock.css'),
      fetch('/assets/css/print-new-pos-receipt.css'),
    ]);

    this.productBarcodeStylesLTR = await productBarcodeCssLTR.text();
    this.productBarcodeStylesRTL = await productBarcodeCssRTL.text();
    this.posReceiptStyle = await posReceiptCss.text();
    this.registerReportStyle = await registerReportCSS.text();
    this.returnInvoiceStyle = await returnInvoice.text();
    this.removeStockStyle = await removeStock.text();
    this.posNewReceiptStyle = await posNewReceiptCss.text();
  }

  async printSoftPosReceipt(url: string): Promise<void> {
    const containerFrame = document.getElementById(
      PrintFrameId.SoftPosReceipt,
    ) as HTMLIFrameElement;

    if (!containerFrame) {
      console.error(
        `Failed to find iframe with ID: ${PrintFrameId.SoftPosReceipt} `,
      );
      return;
    }

    const frameDoc = containerFrame.contentWindow.document;

    const styleElement = frameDoc.createElement('style');
    styleElement.textContent = `${this.posNewReceiptStyle}
      html, body {
          margin: 0 !important;
          padding: 0 !important;
      }
      @media print {
          @page {
              margin: 0 !important;
              padding: 0 !important;
          }
      }
    `;
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch content from receipt URL with status: ${response.status}`,
        );
      }

      const htmlContent = await response.text();

      frameDoc.open();
      frameDoc.write(htmlContent);
      frameDoc.head.appendChild(styleElement);
      frameDoc.close();

      containerFrame.onload = () => {
        containerFrame.contentWindow?.focus();
        containerFrame.contentWindow?.print();
      };
    } catch (error) {
      console.error('Failed to load HTML content:', error);
    }
  }
}
