import { Category } from 'src/app/inventory/model/category';

/* eslint-disable @typescript-eslint/naming-convention */
export const REGISTER_STATUS = {
  OPENED: 'Opened',
  CLOSED: 'Closed',
  INACTIVE: 'Inactive',
};

export enum ShiftFilterStatus {
  OPENED = 'Opened In progress',
  CLOSED_UNBALANCED = 'Closed Unbalanced',
  CLOSED_BALANCED = 'Closed Balanced',
}
export enum ShiftStatus {
  OPENED = 'Opened',
  CLOSED = 'Closed',
}

export const REGISTER_ACTIVE_MODE = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deActivate',
};

export enum LogTypes {
  OPEN = 'Open',
  CLOSE = 'Close',
  ADD = 'Add',
  WITHDRAW = 'Withdraw',
}

export const PAYMENT_METHODS_TYPES = {
  CASH: 'Cash',
  CARD: 'Card',
  CREDIT: 'CreditCard',
  POST: 'Post',
  CUSTOMER_DEBIT: 'CustomerDebit',
  DEBIT: 'DebitCard',
  SOFT_POS: 'SoftPos',
};

export enum PAYMENT_PROCESSORS {
  SURE_PAY = 'SurePay',
}

export const OFFLINE_BATCH_LIMIT = 5;

export const DEFAULT_ACTIVATED_TAB_INDEX = 0;

export const SELLING_SCREEN_TAB_INDEX = 2;

export const KitchenTemplateTabIndex = 2;

export const ReceiptTemplateTabIndex = 1;

export const RANGE_OF_YEARS_FOR_CALENDAR = 100;

export enum DeviceBreakPoints {
  Mobile = 375,
  TabletPortrait = 768,
  TabletLandscape = 1024,
  Desktop = 1366,
  SunmiV2 = 360,
  FiveHundred = 500,
}

export type TableColumnState = {
  name: string;
  isSelected: boolean;
};

export enum TableIds {
  ProductListV2 = 'ProductListV2',
  PurchaseOrderV2 = 'PurchaseOrderV2',
  CustomField = 'CustomField',
  Customers = 'Customers',
}

export enum SaleType {
  Wholesale = 'Wholesale',
  Retail = 'Retail',
}

export enum TemplateVersion {
  ONE = 1,
  CURRENT = 2,
}

export const PRINT_TYPE_ID = 'print-type-id-1';

export const PrintTypeIdB2C = 'print-type-id-3';
export const PrintTypeIdB2B = 'print-type-id-4';

export enum PrintersStatus {
  Other = 1,
  Unknown = 2,
  Idle = 3,
  Printing = 4,
  Warmup = 5,
  Stopped = 6,
  Offline = 7,
}

export const CHECK_PRINTER_STATUS_INTERVAL = 5000;

export const UncategorisedProductsCategory: Category = {
  label: 'UncategorisedProductsCategory',
  value: 1,
  name: 'Uncategorised Products',
  id: -1,
  parents: [],
};

export enum POSShortcutAction {
  ToggleShortcutsDialog = 'ToggleShortcutsDialog',
  FocusSearch = 'FocusSearch',
  ClearCart = 'ClearCart',
  TogglePriceMode = 'TogglePriceMode',
  ShowPaymentDialog = 'ShowPaymentDialog',
  Pay = 'Pay',
  ShowParkSaleDialog = 'ShowParkSaleDialog',
  ParkSale = 'ParkSale',
  ShowSalesmanDialog = 'ShowSalesmanDialog',
  AssignNextSalesman = 'AssignNextSalesman',
  AssignPreviousSalesman = 'AssignPreviousSalesman',
  AddCustomer = 'AddCustomer',
}

export enum POSShortcutContext {
  RegisterView = 'RegisterView',
  SaleViewClassic = 'SaleViewClassic',
  ParkSaleForm = 'ParkSaleForm',
  SalePayment = ' SalePayment',
  SaleItemSearch = 'SaleItemSearch',
  SaleItemList = 'SaleItemList',
  AssignSalesman = 'AssignSalesman',
  AddCustomerDialog = 'AddCustomerDialog',
}

export enum ShortcutKey {
  F2 = '113',
  F4 = '115',
  F8 = '119',
  F9 = '120',
  P = '80',
  S = '83',
  DownArrow = '40',
  UpArrow = '38',
  Alt = 'Alt',
  Ctrl = 'Ctrl',
  AltP = 'Alt_80',
  AltS = 'Alt_83',
  AltUp = 'Alt_38',
  AltDown = 'Alt_40',
  Enter = '13',
  Delete = '46',
}

export const CashPaymentMethodName = 'Cash';

export const RegisterActivateSuccessMessage =
  'Register has been activated successfully';

export const RegisterDeActivateSuccessMessage =
  'Register has been deactivated successfully';

export const SetDefaultRegisterSuccessMessage =
  'Register has been updated Successfully';
