import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerTypes } from '@rewaa-team/pos-sdk';
import { Observable, from, lastValueFrom } from 'rxjs';
import { Invoice } from 'src/app/shared/model/invoice/Invoice';
import { CustomerCacheService } from 'src/app/shared/services/offline/customer-cache.service';
import { OnlineOfflineService } from 'src/app/shared/services/offline/online-offline.service';
import { Customer } from '../../../shared/model/order/Customer';
import { CreatedCustomerResult } from '../../../shared/model/order/CreatedCustomerResult';
import { GetCustomerQuery } from '../components/customers-list/customers-list-v2/api/get-cusomer-query';
import { OfflineFirstService } from '../../../shared/services/offline/offline-first.service';

const API_URL = '/api';

export interface CustomerIdentifier {
  id: number;
  name: string;
  mobileNumber: string;
}
@Injectable()
export class CustomersService {
  constructor(private http: HttpClient,
    private onlineOfflineService: OnlineOfflineService,
    private customerCacheService: CustomerCacheService,
    private offlineFirstService: OfflineFirstService,
  ) {}

  // This gives only customers who has quotation
  getCustomersDropdownLists(): Observable<CustomerIdentifier[]> {
    return this.http.get<CustomerIdentifier[]>(`${API_URL}/customers-dropdownlist`);
  }

  getAllCustomersLists(): Observable<CustomerIdentifier[]> {
    return this.http.get<CustomerIdentifier[]>(`${API_URL}/customers-list`);
  }

  getCustomers(
    pageQuery: GetCustomerQuery,
  ): Observable<{ result: Customer[]; total: number }> {
    const isCustomerEnable = this.offlineFirstService.customersEnabled;
    if (this.onlineOfflineService.isOnline && !isCustomerEnable) {
      return this.getCustomersOnline(pageQuery);
    }

    const offlineCustomerPromise = this.getCustomersOffline(pageQuery);
    return from(offlineCustomerPromise);
  }

  private getCustomersOnline(
    pageQuery: GetCustomerQuery,
  ): Observable<{ result: Customer[]; total: number }> {
    const {
      offset,
      limit,
      query = '',
      sortBy = '',
      sortDirection = '',
      filters = '',
    } = pageQuery;

    const params = new HttpParams()
      .set('query', query)
      .set('limit', limit)
      .set('offset', offset)
      .set('sortBy', sortBy)
      .set('sortDirection', sortDirection)
      .set('filters', filters);

    return this.http.get<{ result: Customer[]; total: number }>(
      `${API_URL}/customers`,
      { params },
    );
  }

  private async getCustomersOffline(
    pageQuery: GetCustomerQuery,
  ): Promise<{ result: Customer[]; total: number }> {
    const { query = '' } = pageQuery;
    return this.customerCacheService.searchCustomers(query);
  }

  getCustomersV2(pageQuery: GetCustomerQuery): Observable<{ result: Customer[], total: number }> {
    const {
      offset, limit, query = '', sortBy = '', sortDirection = '', filters = '',
    } = pageQuery;
    const queryParams = new HttpParams()
        .set(
          'query',
          query,
        )
        .set(
          'limit', limit,
        ).set(
          'offset', offset,
        )
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection)
        .set('filters', filters);
    return this.http.get<{ result: Customer[], total: number }>(`${API_URL}/customers-v2`, { params: queryParams })
  }

  createCustomer(customer: Customer) {
    return this.http.post<CreatedCustomerResult>(`${API_URL}/customers`, customer);
  }

  deleteCustomer(id: number): Observable<any> {
    return this.http.delete<{ success: boolean, id: number }>(`${API_URL}/customers?id=${id}`);
  }

  autoGenerateCustomerCode() {
    return this.http.get<{ code: string }>(`${API_URL}/customers/nextCode`);
  }

  getCustomerByCode(code: string) {
    return this.http.get<Customer>(`${API_URL}/customers/getByCode/${code}`);
  }

  getCustomerById(id: number) {
    return this.http.get<Customer>(`${API_URL}/customers/getById/${id}`);
  }

  getCustomersByIds(ids: number[]): Observable<Customer[]> {
    const params = new HttpParams({ fromObject: { ids } });
    return this.http.get<Customer[]>(`${API_URL}/customers/ids`, { params });
  }

  getCustomerByMobile(mobileNumber: string) {
    return this.http.get<Customer>(`${API_URL}/customers/getByMobile/${mobileNumber}`);
  }

  updateCustomer(customer: Customer): Observable<CreatedCustomerResult> {
    return this.http.put<CreatedCustomerResult>(`${API_URL}/customers/${customer.id}`, customer);
  }

  exportCustomers(query: any): Observable<any> {
    return this.http.post<any>(`${API_URL}/customers/export`, { query });
  }

  getUnPaidInvoices(customerId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${API_URL}/customer/unpaid-invoices/${customerId}`);
  }

  receiveDebit(reqBody: any) {
    return this.http.post<any>(`${API_URL}/payments/receive-debit-from-customer`, reqBody);
  }

  getCustomerTemplate(): Observable<any[]> {
    return this.http.get<any[]>(`${API_URL}/customers/import/templates`);
  }

  import(payload: any): Observable<any> {
    return this.http.post<any>(`${API_URL}/customers/import`, payload);
  }

  getImportProgress(fileName: string, merchantId: number): Observable<any> {
    return this.http.get<any>(`${API_URL}/customers/import/progress?fileName=${fileName}&merchantId=${merchantId}`);
  }

  cancelImport(fileName: string, merchantId: number): Observable<any> {
    return this.http.post<any>(`${API_URL}/customers/import/cancel`, { fileName, merchantId });
  }

  getUnpaidCustomerInvoices(
    customerId: number,
  ): Observable<CustomerTypes.UnpaidInvoiceOutput[]> {
    return this.http.get<CustomerTypes.UnpaidInvoiceOutput[]>(
      `/api/pos-service/v2/customers/${customerId}/unpaid-invoices`,
    );
  }
}
