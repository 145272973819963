import {
  Directive, ElementRef, HostListener, Input,
} from '@angular/core';

@Directive({
  selector: '[numeric]',
})

export class NumericDirective {
  @Input() decimals = 0;

  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete',
  ];

  private check = (value: string, decimals: number) => {
    if (decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    }
    const regExpString = `^\\s*((\\d+(\\.\\d{0,${decimals}})?)|((\\d*(\\.\\d{1,${decimals}}))))\\s*$`;
    return String(value).match(new RegExp(regExpString));
  };

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const current: string = this.el.nativeElement.value;
    // has to be text, search, password, url, or tel to work
    const { selectionStart } = this.el.nativeElement;
    const next: string = (!selectionStart && selectionStart !== 0)
      ? current.concat(event.key) : `${current.substr(0, selectionStart)}${event.key}${current.substr(selectionStart)}`;
    const selectionValue: string = document.getSelection().toString();
    if (!selectionValue && next && !this.check(next, this.decimals)) {
      event.preventDefault();
    }
  }
}
