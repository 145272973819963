import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FirebaseAppService {
  private app: FirebaseApp;

  private analytics: Analytics;

  private db: Firestore;

  constructor() {
    if (environment.enableFirestore) {
      this.app = initializeApp(environment.firebaseConfig);
      this.analytics = getAnalytics(this.app);
      this.db = getFirestore(this.app);
    }
  }

  getFirestore() {
    return this.db;
  }

  getApp() {
    return this.app;
  }
}
