import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { DateTime } from 'luxon';

export const dateLessThan = (from: string, to: string) => (group: UntypedFormGroup)
: { [key: string]: any } => {
  const f = group.controls[from];
  const t = group.controls[to];
  if (f.value !== null && t.value !== null && f.value >= t.value) {
    return {
      dates: true,
    };
  }
  return {};
};

export const startEndDateValidator =
  (start: string, end: string): ValidatorFn =>
  (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const startDate = formGroup.get(start).value;
    const endDateControl = formGroup.get(end);
    const endDate = endDateControl.value;
    const validEndDate = DateTime.fromJSDate(startDate)
      .plus({ day: 1 })
      .toJSDate();
    if (endDate && endDate < validEndDate) {
      endDateControl.setErrors({ invalidEndDate: true });
      return {
        invalidEndDate: { value: { invalid: endDate, valid: validEndDate } },
      };
    }
    return null;
  };

export function minimumDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null =>
    control.value?.valueOf() < minDate.valueOf()
      ? { dateNotAllowed: { value: control.value } }
      : null;
}
