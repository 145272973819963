<div [ngClass]="lng ==='en' ? 'ltr':'rtl'">
<div class="alert-box" class="app-details">
    <div class="close-button">
        <i class="fas fa-times" (click)=content.cancel_btn.handler()></i>
    </div>
    <div class="modal-header flex justify-content-end pb-0" rwPermission [permission]="'app.detail.update'">

        <button pButton pendo-tracker="adpb" *ngIf="!content.data.isSubscribed; else uninstall" class="rw__button rw__button-raised" type="button"
        [id]="'install-'+content.data.name" (click)=content.install_btn.handler() label="{{content.install_btn.text | translate }}">
        </button>
        <ng-template #uninstall>
            <button pButton pendo-tracker="adpb-1" class="p-button-text p-button-danger" [id]="'uninstall-'+content.data.name"
                type="button" (click)=content.uninstall_btn.handler() label="{{content.uninstall_btn.text | translate }}">
            </button>
        </ng-template>
    </div>
    <div class="modal-body pt-0">
        <div class="modal-dialog modal-lg m-0" role="document" *ngIf="content">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="media">
                        <div class="flex flex-column justify-content-center mr-3 app-name"
                           >
                            <img class="align-self-center" src="../../../../assets/images/{{content.data.logo}}"
                                alt="Card image cap" style="width: 150px; padding-top: 20px; padding-bottom: 20px">
                            <div style="font-size: smaller" class="border-bottom-1 pb-2">
                                <b class="text-capitalize">{{content.data.name | translate}}</b>  {{'Integration with Rewaa' | translate }}
                                <br /> 
                                {{'Platform starts from' | translate }}
                                <br />
                                <b class="rewaa-default">{{'SAR 100 a month' | translate }}.</b>
                            </div>
                            <div class="flex flex-column pt-2">
                                <div>
                                    <i class="fas fa-envelope"></i> {{content.data.email}}
                                </div>

                                <a pendo-tracker="adpa" class="pt-2" [href]="content.data.website"><i class="fas fa-globe-americas"></i>
                                    {{content.data.website}}</a>
                            </div>
                        </div>

                        <div class="media-body">
                            <div style="padding-top: 20px">
                                <b>{{'Introduction'  | translate  }}</b>
                            </div>
                            <div style="padding-top: 10px">
                                {{content.data.longDescription | translate}}
                            </div>
                            <div style="padding-top: 20px">
                                <b>{{'Instructions'  | translate  }}</b>
                            </div>
                            <div style="padding-top: 10px">
                                <div *ngFor="let step of content.data.instructions?.split('\n')" >{{ step | translate}}</div>
                            </div>
                            <div style="padding-top: 20px">
                                <b>{{'How It Works with'  | translate  }} {{content.data.name | translate}}</b>
                            </div>
                            <div style="padding-top: 10px" [innerHTML]="content.howItWork">
                            </div>

                            <div style="padding-top: 20px">
                                <b class="user-gauid-link text-capitalize"><a pendo-tracker="adpa-1" [href]="">
                                        {{content.data.name | translate}}
                                        {{'Integration SyncGuide'  | translate  }} </a></b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--article id="slider" class="flex flex-column">
        <input pendo-tracker="adpi" type="radio" name="slider" id="slide1" checked />
        <input pendo-tracker="adpi-1" type="radio" name="slider" id="slide2" />
        <input pendo-tracker="adpi-2" type="radio" name="slider" id="slide3" />
        <input pendo-tracker="adpi-3" type="radio" name="slider" id="slide4" />
        <input pendo-tracker="adpi-4" type="radio" name="slider" id="slide5" />
        <div class="flex" id="slides">
            <div id="overflow">
                <div class="inner">
                    <article>
                        <img src="https://via.placeholder.com/600x400" />
                    </article>
                    <article>
                        <img src="https://via.placeholder.com/600x400" />
                    </article>
                    <article>
                        <img src="https://via.placeholder.com/600x400" />
                    </article>
                    <article>
                        <img src="https://via.placeholder.com/600x400" />
                    </article>
                    <article>
                        <img src="https://via.placeholder.com/600x400" />
                    </article>
                </div>
            </div>
        </div>

        <div id="active" class="flex justify-content-center py-2">
            <label class="mr-1" for="slide1"></label>
            <label class="mr-1" for="slide2"></label>
            <label class="mr-1" for="slide3"></label>
            <label class="mr-1" for="slide4"></label>
            <label class="mr-1" for="slide5"></label>
        </div>
    </article-->

</div>
</div>
