import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { take } from 'rxjs/operators';

import { lastValueFrom } from 'rxjs';

import { TaxesService } from 'src/app/shared/services/tax.service';
import {
  purchaseOrderTemplateHeaders,

} from '../purchase-order-new/common/constants';
import { PurchaseOrderRowImportType } from '../purchase-order-new/common/types';
import { InvoicesService } from './invoices.service';
import { LocalStorageKey } from '../../shared/constants';
import { LocalStorageService } from '../../core/services/local-storage.service';

export interface UploadValidationResponse {
  notFoundRows: number[];
  dupRows: number[][];
  validatedSkus: string[];
  notSupportedRows: number[];
  notPurchasableRows: number[];
  invalidDataTypeForQty:number[],
  invalidDataTypeForCost:number[],
  qtyMissingRows: number[];
  costMissingRows: number[];
  invalidTaxCodeRows: number[];
}
@Injectable()
export class UploadValidationService {
  lng: string = '';

  taxCodesSet:Set<string> = new Set();

  constructor(
    private translate: TranslateService,
    private invoicesService: InvoicesService,
    private taxesService: TaxesService,
    private localStorageService: LocalStorageService,
  ) {
    this.taxesService
      .getAllTaxes().pipe(take(1)).subscribe((taxes) => {
        const taxCodes = taxes.map(({ code }) => code.replace('NONE', 'No Tax'));
        this.taxCodesSet = new Set(taxCodes);
      });

    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lng);
  }

  public isFileCSVOrXlsx(file: File) {
    const extension = file.name.split('.').pop();
    const { type } = file;
    return (
      (extension === 'csv' && file.type === 'text/csv')
      || (extension === 'xlsx'
        && (type
          === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          || type === 'application/vnd.ms-excel'))
    );
  }

  async performImportValidation(
    rows: PurchaseOrderRowImportType[],
  ): Promise<UploadValidationResponse> {
    const skuToIndex:{ [sku:string]:number } = {};
    rows.forEach(({ productSKU }, i) => {
      skuToIndex[productSKU] = i;
    });

    const {
      dupRows, uniqueSKUs, qtyMissingRows, costMissingRows, invalidTaxCodeRows, invalidDataTypeForQty, invalidDataTypeForCost,
    } = this.doLocalValidation(rows);
    let validatedSkus = [];
    let notFoundSKUs = [];
    let notPurchasableSKUs = [];
    let notSupportedSKUs = [];
    if (uniqueSKUs.length > 0) {
      const res = await lastValueFrom(
        this.invoicesService.verifyImportProductsV2(uniqueSKUs), //check out here for global changes
      );
      validatedSkus = res.validSKUs;

      ({ notFoundSKUs, notSupportedSKUs, notPurchasableSKUs } = res.notValidSKUs);
    }

    return {
      notFoundRows: notFoundSKUs.map((sku) => skuToIndex[sku] + 1),
      dupRows,
      validatedSkus,
      notSupportedRows: notSupportedSKUs.map((sku) => skuToIndex[sku] + 1),
      notPurchasableRows: notPurchasableSKUs.map((sku) => skuToIndex[sku] + 1),
      invalidDataTypeForQty,
      invalidDataTypeForCost,
      qtyMissingRows,
      costMissingRows,
      invalidTaxCodeRows,
    };
  }

  doLocalValidation(rows: PurchaseOrderRowImportType[]) {
    const rowIndexBySku = new Map<string, number[]>();
    const uniqueSKUs: string[] = [];
    const dupRows: number[][] = [];
    const costMissingRows: number[] = [];
    const invalidDataTypeForCost: number[] = [];
    const invalidDataTypeForQty: number[] = [];
    const qtyMissingRows: number[] = [];
    const invalidTaxCodeRows:number[] = [];
    for (let i = 0; i < rows.length; i++) {
      const {
        productSKU, cost, qty, tax: taxCode,
      } = rows[i];

      const row = i + 2;
      if (cost === undefined || Number.isNaN(cost)) {
        costMissingRows.push(row);
      } else if (Number.isNaN(parseFloat(cost as any))) {
        invalidDataTypeForCost.push(row);
      } else if (taxCode && !this.taxCodesSet.has(taxCode)) {
        invalidTaxCodeRows.push(row);
      } else if (qty === undefined || Number.isNaN(qty)) {
        qtyMissingRows.push(row);
      } else if (Number.isNaN(parseFloat(qty as any))) {
        invalidDataTypeForQty.push(row);
      } else {
        this.separateUniqueAndDuplicateSKUs({
          rowIndexBySku,
          row,
          uniqueSKUs,
          productSKU,
        });
      }
    }
    [...rowIndexBySku.values()].forEach((value) => value.length > 1 && dupRows.push(value));

    return {
      uniqueSKUs,
      dupRows,
      costMissingRows,
      invalidDataTypeForCost,
      invalidDataTypeForQty,
      qtyMissingRows,
      invalidTaxCodeRows,
    };
  }

  checkForPurchaseOrderTemplateHeaders(data, onError: (msg: string) => void) {
    const firstRow = data[0];
    const firstRowHeaders = Object.keys(data[0]);
    const duplicateColumn = firstRowHeaders.filter((row) => row.endsWith('_1'));
    if (duplicateColumn.length > 0) {
      const msg = this.translate.instant(
        'uploadPurchaseOrder.duplicate_header',
      );
      onError(msg);
      return;
    }
    Object.values(purchaseOrderTemplateHeaders).forEach((value) => {
      if (!(value in firstRow)) {
        const msg = this.translate.instant(
          'uploadPurchaseOrder.header_name',
          { name: value },
        );
        onError(msg);
      }
    });
  }

  separateUniqueAndDuplicateSKUs(params: {
    rowIndexBySku: Map<string, number[]>;
    productSKU: string;
    row: number;
    uniqueSKUs: string[];
  }) {
    const {
      rowIndexBySku, productSKU, row, uniqueSKUs,
    } = params;

    if (rowIndexBySku.has(productSKU)) {
      const duplicatedRows = [...rowIndexBySku.get(productSKU)];
      if (duplicatedRows.length === 1) {
        const uniqueSkuIndex = uniqueSKUs.indexOf(productSKU);
        uniqueSKUs.splice(uniqueSkuIndex, 1);
      }

      duplicatedRows.push(row);
      rowIndexBySku.set(productSKU, duplicatedRows);
    } else {
      rowIndexBySku.set(productSKU, [row]);
      uniqueSKUs.push(productSKU);
    }
  }
}
