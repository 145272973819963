import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Expense } from 'src/app/expenses/model/expense';
import {
  ExpenseCategory,
  CreatedExpenseCategoryResult,
} from '../model/expenseCategory';
import { PageQuery } from '../../shared/services/pagination-interface';

const API_URL = '/api';

@Injectable()
export class ExpenseCategoryService {
  constructor(private http: HttpClient) {}

  public getExpenseCategories(): Observable<ExpenseCategory[]> {
    return this.http
      .get<ExpenseCategory[]>(`${API_URL}/expense-categories`)
      .pipe(
        map((response) => response.map((type) => new ExpenseCategory(type))),
      );
  }

  getExpenseCategoriesPage(
    { limit, offset, query }: PageQuery = { limit: 10, offset: 0, query: '' },
  ): Observable<any> {
    return this.http.get<{ result: ExpenseCategory[]; count: number }>(
      `${API_URL}/expense-categories?offset=${offset}&limit=${limit}&query=${query}`,
    );
  }

  createExpenseCategory(
    expenseCategory: Pick<ExpenseCategory, 'name'> & {
      parentAccountHeadReferenceURI?: string;
      code?: string;
      description?: string;
    },
  ): Observable<any> {
    return this.http.post<CreatedExpenseCategoryResult>(
      `${API_URL}/expense-categories`,
      expenseCategory,
    );
  }

  updateExpenseCategory(
    expenseCategory: Pick<ExpenseCategory, 'name' | 'id'> & {
      accountHeadReferenceURI?: string;
      parentAccountHeadReferenceURI?: string;
      code?: string;
      description?: string;
    },
  ): Observable<any> {
    return this.http.put<CreatedExpenseCategoryResult>(
      `${API_URL}/expense-categories`,
      expenseCategory,
    );
  }

  public getExpenseCategoryByLevel(
    level: number,
  ): Observable<ExpenseCategory[]> {
    return this.http
      .get<ExpenseCategory[]>(`${API_URL}/expense-categories?level=${level}`)
      .pipe(
        map((response) => response.map((type) => new ExpenseCategory(type))),
      );
  }

  async getExpenseCategoryChildren(id: number): Promise<ExpenseCategory[]> {
    return this.http
      .get<ExpenseCategory[]>(`${API_URL}/expense-categories/${id}/children`)
      .pipe(
        map((response) => response.map((type) => new ExpenseCategory(type))),
      )
      .toPromise();
  }

  async getExpenseCategoryProduct(
    id: number,
    query: { offset?: string; limit?: string; all?: boolean },
  ): Promise<{ count: number; rows: Expense[] }> {
    return this.http
      .get<{ count: number; rows: any[] }>(
        `${API_URL}/expense-categories/${id}/expense?offset=${
          query.offset
        }&limit=${query.limit}&all=${query.all || false}`,
      )
      .toPromise();
  }
}
