<div [ngClass]="langCss">
  <div class="alert-box" class="add-withdraw-money">
    <div class="modal-header flex justify-content-center pb-0">
      <div class="grid">
        <div class="col-12 text-center">
          <h5>{{content.title | translate}}</h5>
        </div>
        <div class="col-12 text-center">
          <p>{{ 'Current Total Amount' | translate }} : {{totalBalance}} SAR</p>
        </div>
      </div>
    </div>
    <div class="modal-body pt-0">
      <div class="modal-dialog mx-auto modal-lg m-0" role="document" *ngIf="content">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form" [formGroup]="form">
              <div class="grid text-center">
                <div class="col-12">
                  <div class="form-row">
                    <div class="field form-valid col-12">
                      <div class="width11 rw__mx-6 d-inline-flex align-items-center shade-box p-3 mb-2 bg-white border-round-sm add-label" id="add-cash"
                      [ngClass]="form.value.cashType == cashTypes.ADD ? 'active-box':''">
                        <input class="form-check-input" type="radio" formControlName="cashType" id="cashType1"
                          value="{{cashTypes.ADD}}" pendo-tracker="pos-add-withdraw-cash-add">
                        <label class="form-check-label mb-0" for="cashType1">
                          <img *ngIf="form.value.cashType != cashTypes.ADD" src='../../../../../../assets/icons/addCash.svg'>
                          <img *ngIf="form.value.cashType == cashTypes.ADD" src='../../../../../../assets/icons/addcashActive.svg'>
                          {{ cashTypes.ADD | translate}}
                        </label>
                      </div>
                      <div class="width11 d-inline-flex align-items-center shade-box p-3 mb-2 bg-white border-round-sm with-label" id="withdraw-cash"
                      [ngClass]="form.value.cashType == cashTypes.WITHDRAW ? 'active-box':''">
                        <input class="form-check-input" type="radio" formControlName="cashType" id="cashType2"
                          value="{{cashTypes.WITHDRAW}}" pendo-tracker="pos-add-withdraw-cash-withdraw">
                        <label class="form-check-label mb-0 withdraw-radio" for="cashType2">
                          <img *ngIf="form.value.cashType != cashTypes.WITHDRAW" src='../../../../../../assets/icons/withdrwcash.svg'>
                          <img *ngIf="form.value.cashType == cashTypes.WITHDRAW" src='../../../../../../assets/icons/withdrawCash.svg'>
                          {{ cashTypes.WITHDRAW | translate}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-row grid justify-content-center ">
                    <div class="field col-8 justify-content-center">
                      <div class="rw__input rw__input-float p-float-label">
                        <input
                          pInputText
                          class="w-full"
                          stopWheel
                          rwSelectOnClick
                          type="number"
                          step="any"
                          formControlName="amount"
                          [attr.pendo-tracker]="'pos-add-withdraw-amount'+form.controls.cashType.value.toLowerCase()"
                          (click)="showNumberDialogue()"
                          required>
                        <label class="rw__px-4 rw__fs-9">{{'Amount' | translate}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-row grid justify-content-center mt-1 mb-1">
                    <div class="col justify-content-center">
                      <ng-container *ngFor="let val of autoPayValue; let i = index">
                        <button pendo-tracker="awmb" class="auto-button" (click)="patchAmount(val)"><span class="font-bold">{{val}}</span> {{'SR' |
                            translate}}
                        </button>
                    </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-column align-items-end w-full">
                    <span class="field rw__mb-0 w-full">
                      <textarea
                        id="notes"
                        placeholder="{{ 'Notes' | translate }}" 
                        type="text"
                        formControlName="notes"
                        class="rw__mb-0 w-full"
                        pInputTextarea
                        rows="1" 
                      ></textarea>
                    </span>
                   </div>
                  </div>
                <div *ngIf="form.value.amount > 0" class="col-12 d-flex justify-content-center">
                  <button pButton [disabled]="disabledSubmit" pendo-tracker="pos-add-withdraw-save-amount" class="rw__button rw__button-raised" type="button" id="submit-amount"
                    (click)=submitAddWithdrawMoney() label="{{submitButtonText | translate }} {{form.value.amount}} {{'SAR' | translate }}">
                  </button>
                </div>
                <div *ngIf="form.value.amount <= 0" class="col-12 d-flex justify-content-center">
                  <h6 class="no-amount mx-4 mt-2 mb-0">{{'No amount added yet' |
                    translate}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>