<div [ngClass]="langCss" data-qa="addSalesman">
  <div class="alert-box" class="add-salesman">
    <div class="modal-body pt-0">
      <div class="modal-dialog modal-lg m-0 " role="document" *ngIf="content">
        <div class="modal-content mt-1">

          <div class="grid p-0 m-0">

            <span class="col-12 sl-body">
              {{'Select salesman to assign this selling process for.' | translate}}
            </span>

            <div class="col-12 pt-2 salesman-search">
                <div class="input-group">
                  <input pInputText pendo-tracker="asi" id="product-search-field" 
                  type="text"  
                  class="search-icon search-box inline-field text-width w-full" 
                  [(ngModel)]="searchText"
                  (keyup)="debounceSearch.next($event.target.value)"
                  required
                  data-qa="addSalesman-searchInput">
                  <label>{{'Search for Salesman\'s name ...' | translate}}</label>
                </div>
            </div>

            <div *ngIf="filteredUsers" class="result-list col-12 pt-2">
              <div *ngFor="let user of filteredUsers" 
                [class]="getSelectedSalesmanRowClass(user.id, user.searchFailed)"
                data-qa="addSalesman-salesManList">
                <div 
                  [class]="getSelectedSalesmanListClass(user.id)"
                >
                  <span 
                    class="salesman-ls-item"
                    (click)="onSelect(user)" pendo-tracker="pos-sell-assign-salesman"
                    data-qa="addSalesman-btnAssign">
                    {{user.name | translate}}
                  </span>
                  <span 
                    *ngIf="user.id === salesmanDetailsInput?.id" 
                    class="unassign-btn" pendo-tracker="pos-sell-unassign-salesman"
                    (click)="removeSalesmanDetails()"
                    data-qa="addSalesman-btnUnAssign"
                  >
                    {{ 'Unassign' | translate}}
                  </span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>