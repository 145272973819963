import { RewaaApi } from '../../internals/rewaa-api';
import { InvoiceV2 } from '../../types/invoice-types';
import { InstallZatcaDto, UninstallZatcaDto } from '../../types/zatca-types';

export class PosApiService extends RewaaApi {
  constructor(apiUrl: string, apiKey: string) {
    super(apiUrl, apiKey);
  }

  async verifyInvoice(
    schemaName: string,
    invoice: InvoiceV2,
  ): Promise<unknown> {
    return this.post('/invoices/verify', invoice, undefined, {
      'x-client-schema': schemaName,
    });
  }

  async installZatca(zatcaInstallBody: InstallZatcaDto): Promise<unknown> {
    return this.post('/zatca', zatcaInstallBody, undefined, {
      'x-client-schema': zatcaInstallBody.schemaName,
    });
  }

  async uninstallZatca(
    zatcaUninstallParams: UninstallZatcaDto,
  ): Promise<unknown> {
    return this.delete(`/zatca`, undefined, {
      'x-client-schema': zatcaUninstallParams.schemaName,
      'x-tenant-id': zatcaUninstallParams.tenantId,
    });
  }
}
