import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadingApiEnum, LoadingScreenEnum } from '../enums';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { LoadingSkeletonContext } from '../types/skeleton.types';

@Injectable()
export class LoadingSkeletonService implements OnDestroy {
  constructor(private readonly featureFlagService: FeatureFlagService) {
    this.featureFlagService
      .isEnabled(FeatureFlagEnum.LoadingSkeleton)
      .subscribe((resp) => {
        this.isEnableLoadingSkeleton = resp;
      });
  }

  private isEnableLoadingSkeleton = false;

  private loadingScreenStatus$ = new BehaviorSubject<boolean>(false);

  private loadingSkeletonContext: LoadingSkeletonContext = {};

  public get loadingSkeletonFeatureFlag(): boolean {
    return this.isEnableLoadingSkeleton;
  }

  public get loadingScreenStatusUpdate(): Observable<boolean> {
    return this.loadingScreenStatus$ as Observable<boolean>;
  }

  pushContext(
    loadingScreen: LoadingScreenEnum,
    loadingApi: LoadingApiEnum,
  ): void {
    if (!this.loadingSkeletonContext[loadingScreen]) {
      this.loadingSkeletonContext[loadingScreen] = [];
    }
    this.loadingSkeletonContext[loadingScreen].push(loadingApi);
  }

  popContext(
    loadingScreen: LoadingScreenEnum,
    loadingApi: LoadingApiEnum,
  ): void {
    const loadingScreenContext = this.loadingSkeletonContext[loadingScreen];

    if (!loadingScreenContext?.length) {
      return;
    }

    const index = loadingScreenContext.indexOf(loadingApi);

    if (index !== -1) {
      loadingScreenContext.splice(index, 1);
    }

    if (loadingScreenContext.length === 0) {
      this.loadingScreenStatus$.next(true);
      delete this.loadingSkeletonContext[loadingScreen];
      this.loadingScreenStatus$.next(false);
    }
  }

  ngOnDestroy(): void {
    this.loadingSkeletonContext = {};
    this.loadingScreenStatus$.next(false);
    this.loadingScreenStatus$.complete();
  }
}
