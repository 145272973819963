import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { TaxesActions } from '../tax.action-types';

import { Tax } from '../../../shared/model/Tax';

export interface TaxesState extends EntityState<Tax> {
  areTaxesLoaded: boolean;
}

export const taxesFeatureKey = 'taxes';

export const adaptor: EntityAdapter<Tax> = createEntityAdapter<Tax>();

export const initialTaxesState = adaptor.getInitialState();

const reducer = createReducer(
  initialTaxesState,
  on(TaxesActions.taxesLoaded, (state, action) => adaptor.setAll(action.taxes, {
    ...state,
    areTaxesLoaded: true,
  })),
  on(TaxesActions.taxUpdated, (state, action) => adaptor.updateOne(action.update, state)),
  on(TaxesActions.taxAdded, (state, action) => adaptor.addOne(action.tax, state)),
);

export const {
  selectAll,
} = adaptor.getSelectors();

export function taxesReducer(state: TaxesState | undefined, action: Action) {
  return reducer(state, action);
}
