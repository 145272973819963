/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { LocalStorageKey } from '../../../../shared/constants';
import { LocalStorageService } from '../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-update-number-dialog',
  templateUrl: './update-number.component.html',
  styleUrls: ['./update-number.component.scss'],
})
@AutoUnsubscribe
export class UpdatePhoneComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  @Input() numberForm: UntypedFormGroup;

  @Input() dialogVisible: boolean;

  @Output() completion = new EventEmitter<string>();

  isShowSpinner = false;

  isShowInternalSpinner = false;

  changeLang: string;

  lang: string;

  countries: any[] = [];

  selectedCountry = {
    countryKey: 'SA',
    name: 'Saudi Arabia',
    code: '966',
    currency: 'SAR',
    languages: 'ar',
    localeName: 'Saudi Arabia',
  };

  filterValue = '';

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }
  }

  get phone(): AbstractControl | null {
    return this.numberForm.get('phone');
  }

  ngOnInit(): void {
    this.phone.valueChanges.subscribe(() => {
      this.phone.markAsUntouched();
    });

    this.phone.setValidators([
      Validators.required,
      this.getPhoneNumberValidator(),
    ]);
  }

  getPhoneNumberValidator() {
    return (control: FormControl) => {
      const inputValue = control.value;

      if (this.selectedCountry?.countryKey !== 'SA') {
        return /^[0-9]{5,13}$/.test(inputValue)
          ? null
          : { otherThanSaudiPattern: true };
      }

      if (!inputValue.startsWith('05') && !inputValue.startsWith('5')) {
        return { saudiZeroOrZeroFive: true };
      }

      const saudiPattern = inputValue.startsWith('0')
        ? /^05[0-9]{8}$/
        : /^5[0-9]{8}$/;

      const saudiError = inputValue.startsWith('0')
        ? { zeroSaudiPattern: true }
        : { saudiPattern: true };

      return saudiPattern.test(inputValue) ? null : saudiError;
    };
  }

  closeThisDialog(): void {
    this.completion.emit('');
  }

  updatePhone(): void {
    if (this.numberForm.invalid) return;

    this.completion.emit(this.phone.value);
    this.phone.reset();
  }
}
