import { PaymentMethod } from '../PaymentMethod';
import { PaymentToPayableInvoice } from './PayableInvoice';
import { PaymentProcess } from '../../../internal-apps/pos/model/PaymentProcess';

export class Payment {
  public paidAmount?: number;

  public id?: number;

  public createdAt?: Date;

  public updatedAt?: Date;

  public deletedAt?: Date;

  public invoiceNumber?: string;

  public debitAmount?: number;

  public invoiceId?: number;

  public paymentMethod?: string;

  public paymentMethodId?: number;

  public PaymentMethod?: PaymentMethod;

  public PaymentProcess?: PaymentProcess;

  public displayPaymentNumber?: string;

  public PaymentToPayableInvoices?: PaymentToPayableInvoice;
}

export class PaymentV2 {
  public paidAmount?: number;

  public paymentMethod?: {
    name: string;
    id: number;
    type: string;
    bankId?: number;
    bankName?: string;
    accountNumber?: string;
  };
}

export class PaymentPoV2 {
  paidAmount: number;

  paymentMethod: PaymentMethodV2;
}
export type PaymentMethodV2 = {
  id?: number;
  name: string;
  type: string;
  bankId: string;
  description: string;
};
