import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, Observable } from 'rxjs';
import { FeatureFlagService } from './types/feature-flag.service.interface';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { FeatureFlagEnum } from '../constants/feature-flag.constants';
import { SideNavMenuOptions } from './enum/feature-flag.interface';
import { LocalStorageKey } from '../constants';
import {
  MerchantService,
  NavUserPreferenceEnum,
  UserPreferenceDTO,
} from '../../auth/services/merchant.service';
import { UserService } from '../../auth/services/user.service';

@Injectable()
export class UserPreferencesService {
  constructor(
    private readonly featureFlagService: FeatureFlagService,
    private readonly localStorageService: LocalStorageService,
    private readonly merchantService: MerchantService,
    private readonly userService: UserService,
  ) {
    this.featureFlagService
      .isEnabled(FeatureFlagEnum.NewSideNavMenuComp)
      .pipe(filter(() => this.userService.isLoggedIn()))
      .subscribe(async (flag: SideNavMenuOptions) => {
        if (flag === SideNavMenuOptions.NewNavOnly) {
          this.setValue({
            sideMenu: NavUserPreferenceEnum.NewNav,
          });
        } else if (flag === SideNavMenuOptions.SwitchShown) {
          this.localStorageService.removeItem(
            LocalStorageKey.NewNavDesignEnabled,
          );
          await this.checkUserPreferences();
        } else if (flag === SideNavMenuOptions.SwitchHidden) {
          this.setValue({
            sideMenu: NavUserPreferenceEnum.OldNav,
          });
        }
      });
  }

  private preferenceSubject = new BehaviorSubject<UserPreferenceDTO>(null);

  public get preference$(): Observable<UserPreferenceDTO> {
    return this.preferenceSubject.asObservable();
  }

  setValue(newValue: UserPreferenceDTO): void {
    this.preferenceSubject.next(newValue);
  }

  getValue(): UserPreferenceDTO {
    return this.preferenceSubject.value;
  }

  async checkUserPreferences(): Promise<void> {
    const flagValue = await firstValueFrom(
      this.featureFlagService.isEnabled(FeatureFlagEnum.NewSideNavMenuComp),
    );

    if (flagValue === SideNavMenuOptions.NewNavOnly) {
      this.setValue({
        sideMenu: NavUserPreferenceEnum.NewNav,
      });
      return;
    }
    if (flagValue === SideNavMenuOptions.SwitchHidden) {
      this.setValue({
        sideMenu: NavUserPreferenceEnum.OldNav,
      });
      return;
    }

    const cachedPreference = this.localStorageService.getItem<string>(
      LocalStorageKey.NewNavDesignEnabled,
    );
    if (cachedPreference) {
      this.setValue({
        sideMenu:
          cachedPreference === 'true'
            ? NavUserPreferenceEnum.NewNav
            : NavUserPreferenceEnum.OldNav,
      });
      return;
    }

    const recordedPreference = await firstValueFrom(
      this.merchantService.getUserPreferences(),
    );

    this.saveToLocalStorage(recordedPreference.sideMenu);
    this.setValue(recordedPreference);
  }

  private saveToLocalStorage(newPref: NavUserPreferenceEnum): void {
    this.localStorageService.setItem(
      LocalStorageKey.NewNavDesignEnabled,
      (newPref === NavUserPreferenceEnum.NewNav).toString(),
    );
  }
}
