import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OfflinePosTypes } from '@rewaa-team/pos-sdk';
import {
  ConfigStoreKeys,
  CustomerChangeSetAPIConfig,
  OFFLINE_MODE,
} from '../../constants';
import { Customer } from '../../model/order/Customer';
import { CompressorService } from '../compressor.service';
import { CustomToastService } from '../custom-toast.service';
import { ConfigStoreService } from './indexedDB/config-store.service';
import { CustomerStoreService } from './indexedDB/customer-store.service';
import { OfflineCustomerService } from './offline-customer.service';
import { OnlineOfflineService } from './online-offline.service';
import { CustomerChangeSetAPIResponse } from '../types/offline-customer.type';
import { ChangeSetActionType } from './enums';
import { CustomerStoreV2Service } from './indexedDB/customer-store-v2.service';
import { NotificationsService } from '../../../firebase-notifications/notifications.service';
import {
  NotificationSource,
  NotificationType,
} from '../../../firebase-notifications/enums';
import { FileDownLoadService } from './file-download.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerCacheService implements OnDestroy {
  customerSubscription: Subscription;

  destroySubs$ = new Subject();

  downloadingOfflineCustomersFile = false;

  constructor(
    private offlineCustomerService: OfflineCustomerService,
    private customerStoreService: CustomerStoreService,
    private configStoreService: ConfigStoreService,
    private onlineOfflineService: OnlineOfflineService,
    private compressorService: CompressorService,
    private toastMessage: CustomToastService,
    private customerStoreV2Service: CustomerStoreV2Service,
    private notificationService: NotificationsService,
    private fileDownLoadService: FileDownLoadService,
  ) {
    this.notificationService
      .getNotifications({
        type: NotificationType.CustomerChanges,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((notifications) => {
        if (notifications.length) {
          this.loadOfflineData();
        }
      });

    this.notificationService
      .getNotifications({
        type: NotificationType.OfflineCustomerFile,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .subscribe((notifications) => {
        if (notifications.length) {
          const data: OfflinePosTypes.OfflineCustomerFileData = notifications[0].m.d;
          this.downloadAndProcessOfflineCustomerFile(data);
        }
      });
  }

  fetchCustomersOnInterval() {
    this.customerSubscription = timer(0, environment.offline_intervals.customer)
      .pipe()
      .subscribe(() => {
        if (this.onlineOfflineService.isOnline) {
          Promise.all([
            this.configStoreService.getConfigration(
              OFFLINE_MODE.CUSTOMER_LAST_TIME,
            )]).then((result) => {
            const time = result[0];
            this.offlineCustomerService.getOfflineCustomers({ time }).subscribe((data) => {
              if (!time) {
                if (data.error) {
                  console.log(data.error);
                } else {
                  this.onlineOfflineService.getOfflineJSONData(data.url).pipe(
                    tap((blobData: any) => {
                      blobData.arrayBuffer().then((fileData: any) => {
                        this.compressorService.unCompressGzipToJSON(fileData)
                          .then((unCompressedData: any) => {
                            const { customers } = unCompressedData;
                            console.log(customers);
                            this.configStoreService.setConfigration(
                              OFFLINE_MODE.CUSTOMER_LAST_TIME, data.last,
                            );
                            this.pushToOfflineCustomers(customers);
                          });
                      });
                    }),
                  ).subscribe();
                }
              } else {
                if (data.last) this.configStoreService.setConfigration(OFFLINE_MODE.CUSTOMER_LAST_TIME, data.last);

                this.customerStoreService.handleCustomerUpdates(data.customers);
              }
            });
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destoryCustomerSubscription();
    this.destroySubs$.next(null);
    this.destroySubs$.complete();
  }

  destoryCustomerSubscription(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  async pushToOfflineCustomers(customers: Array<any>) {
    return new Promise(async (resolve, reject) => {
      let offset = 0;

      while (offset < customers.length) {
        const batchedCustomers: Array<any> = customers.filter(
          (c: any, i: number) => i >= offset && i < Math.min(customers.length, offset + 100),
        );

        await this.customerStoreService.putCustomers(batchedCustomers);

        offset = Math.min(customers.length, offset + 100);
      }

      resolve(customers);
    });
  }

  async searchCustomers(
    searchQuery: string,
  ): Promise<{ result: Customer[]; total: number }> {
    const customers = await this.customerStoreV2Service.findCustomers(
      searchQuery,
    );
    return {
      result: customers,
      total: customers.length,
    };
  }

  async loadOfflineData(): Promise<void> {
    const customerChangeSetAPIConfig =
      await this.configStoreService.getConfigration(
        ConfigStoreKeys.CustomerChangeSetAPIConfig,
      );

    if (!customerChangeSetAPIConfig) {
      this.loadOfflineCustomers();
      return;
    }
    const { updatedAt } = JSON.parse(customerChangeSetAPIConfig);
    this.loadOfflineCustomers(updatedAt);
  }

  async loadOfflineCustomers(updatedAt?: string): Promise<void> {
    this.offlineCustomerService
      .getOfflineCustomersChangeSet(updatedAt)
      .pipe(takeUntil(this.destroySubs$))
      .subscribe(async (customerChangeSet: CustomerChangeSetAPIResponse[]) => {
        if (!customerChangeSet.length) return;

        const promises = [];

        const customersToAdd: Customer[] =
          this.getCustomersToAdd(customerChangeSet);

        promises.push(
          this.customerStoreV2Service.bulkPutCustomers(customersToAdd),
        );

        const customerIdsToDelete: number[] =
          this.getCustomerIdsToDelete(customerChangeSet);

        promises.push(
          this.customerStoreV2Service.deleteCustomers(customerIdsToDelete),
        );

        const latestUpdatedDate = this.getLatestUpdatedAt(customerChangeSet);
        const config: CustomerChangeSetAPIConfig = {
          updatedAt: latestUpdatedDate,
        };

        promises.push(
          this.configStoreService.setConfigration(
            ConfigStoreKeys.CustomerChangeSetAPIConfig,
            JSON.stringify(config),
          ),
        );
        await Promise.all(promises);
      });
  }

  private getCustomersToAdd(
    customerChangeSet: CustomerChangeSetAPIResponse[],
  ): Customer[] {
    const customersToAdd = customerChangeSet.find(
      (changeSet: CustomerChangeSetAPIResponse) =>
        changeSet.actionType === ChangeSetActionType.Updated,
    );
    if (!customersToAdd) return [];

    return customersToAdd.customers.map((customer) => {
      const nameArray = customer?.name ? customer.name.split(' ') : [];
      const searchableFields = [
        customer.mobileNumber,
        customer.code,
        customer.name,
        ...nameArray,
      ];
      return { ...customer, searchableFields };
    });
  }

  private getCustomerIdsToDelete(
    customerChangeSet: CustomerChangeSetAPIResponse[],
  ): number[] {
    const customers: Customer[] = customerChangeSet.find(
      (changeSet: CustomerChangeSetAPIResponse) =>
        changeSet.actionType === ChangeSetActionType.Deleted,
    )?.customers;

    return customers ? customers.map((customer) => customer.id) : [];
  }

  private getLatestUpdatedAt(
    customerChangeSet: CustomerChangeSetAPIResponse[],
  ): string {
    return customerChangeSet.find(
      (changeSet: CustomerChangeSetAPIResponse) => changeSet.updatedDate,
    )?.updatedDate;
  }

  async downloadAndProcessOfflineCustomerFile(
    input: OfflinePosTypes.OfflineCustomerFileData,
  ): Promise<void> {
    if (this.downloadingOfflineCustomersFile) {
      return;
    }
    this.downloadingOfflineCustomersFile = true;
    this.fileDownLoadService
      .downloadFile(input.url)
      .pipe(
        takeUntil(this.destroySubs$),
        tap(async (res) => {
          const customerChangeSet =
            (await this.compressorService.unCompressGzipToJSON(
              new Uint8Array(res),
            )) as unknown as CustomerChangeSetAPIResponse[];
          const customersToAdd: Customer[] =
            this.getCustomersToAdd(customerChangeSet);
          const config: CustomerChangeSetAPIConfig = {
            updatedAt: input.updatedAt,
          };
          const promises = [
            this.customerStoreV2Service.bulkPutCustomers(customersToAdd),
            this.configStoreService.setConfigration(
              ConfigStoreKeys.CustomerChangeSetAPIConfig,
              JSON.stringify(config),
            ),
          ];
          await Promise.all(promises);
          this.downloadingOfflineCustomersFile = false;
          this.loadOfflineData();
        }),
        finalize(() => {
          this.downloadingOfflineCustomersFile = false;
        }),
      )
      .subscribe();
  }
}
