import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PaymentMethod } from '../../shared/model/PaymentMethod';
import { SettingsActions } from '../settings.action-types';

export interface PaymentMethodsState extends EntityState<PaymentMethod> {
  arePaymentMethodsLoaded: boolean;
}

export const paymentMethodsFeatureKey = 'paymentMethods';

export const adaptor = createEntityAdapter<PaymentMethod>();

export const initialPaymentMethodsState = adaptor.getInitialState();

const reducer = createReducer(
  initialPaymentMethodsState,
  on(SettingsActions.paymentMethodsLoaded,
    (state, action) => adaptor
      .setAll(action.paymentMethods, {
        ...state,
        arePaymentMethodsLoaded: true,
      })),

  on(SettingsActions.paymentMethodUpdated,
    (state, action) => adaptor
      .upsertOne(action.paymentMethod, state)),

  on(SettingsActions.paymentMethodAdded,
    (state, action) => adaptor
      .addOne(action.paymentMethod, state)),
);

export const {
  selectAll,
} = adaptor.getSelectors();

export function paymentMethodsReducer(state: PaymentMethodsState | undefined, action: Action) {
  return reducer(state, action);
}
