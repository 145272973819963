import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Template } from '../model/Template';
import { TemplateType } from '../model/TemplateType';

@Injectable()
export class TemplateService {
  private API_URL = '/api';

  constructor(
    private http: HttpClient,
  ) { }

  getTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(`${this.API_URL}/pos/templates`);
  }

  getTemplateTypes(isActive: boolean = undefined, withSettings: boolean = false, withCategories: boolean = false): Observable<TemplateType[]> {
    const params: any = {};
    if (isActive !== undefined) {
      params.isActive = isActive;
    }
    params.settings = withSettings;
    params.categories = withCategories;
    return this.http.get<TemplateType[]>(`${this.API_URL}/pos/template-types/templates`, { params });
  }

  updateTemplateTypesStatus(templateTypes: TemplateType[]): Observable<TemplateType[]> {
    return this.http.put<TemplateType[]>(`${this.API_URL}/pos/templates-types/bulk-update`, templateTypes);
  }

  updateTemplate(template: Template): Observable<Template> {
    return this.http.put<Template>(`${this.API_URL}/pos/templates/${template.id}`, template);
  }

  addTemplate(template: Template): Observable<Template> {
    return this.http.post<Template>(`${this.API_URL}/pos/templates`, template);
  }

  getTemplateById(id: number): Observable<Template> {
    return this.http.get<Template>(`${this.API_URL}/pos/templates/${id}`);
  }
}
