import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState, selectAll, usersFeatureKey } from '../reducers/users.reducer';

export interface Page {
  index: number;
  size: number;
}

export const selectUsersState = createFeatureSelector<UsersState>(usersFeatureKey);

export const areUsersLoaded = createSelector(
  selectUsersState,
  (state) => state.areUsersLoaded,
);

export const selectAllUsers = createSelector(
  selectUsersState,
  selectAll,
);

export const selectUserById = (id: number) => createSelector(
  selectAllUsers,
  (users) => users.find((user) => user.id === id),
);
