import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { CustomToastService } from 'src/app/shared/services/custom-toast.service';
import { Observable, tap } from 'rxjs';
import { Intercom } from 'ng-intercom';
import { RegexEnum } from '../../../../shared/model/enum/RegexPattern';
import { ResetPasswordNewPasswordStep } from '../../../auth.models';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageKey } from '../../../../shared/constants';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import {
  ForgotPasswordDTO,
  MerchantService,
  ResendConfirmationDTO,
} from '../../../services/merchant.service';
import { TimerService } from '../../../services/timer.service';
import { FeatureFlagService } from '../../../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../../shared/constants/feature-flag.constants';

@Component({
  selector: 'rw-reset-new-password-v2',
  templateUrl: './new-password-v2.component.html',
  styleUrls: ['./new-password-v2.component.scss'],
})
@AutoUnsubscribe
export class NewPasswordV2Component implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  @Input() userEmail: string;

  @Input() userPhone: number;

  @Input() message: string;

  @Input() resendConfirmationCode: boolean;

  @Input() sendToEmailButtonVisibility: boolean;

  isShowSpinner = false;

  newPasswordFormGroup: UntypedFormGroup;

  changeLang: string;

  errMessage: string;

  isShowPassowd = false;

  otpFormControl: FormControl<number | null>;

  lang: string;

  timerVisible = false;

  translationPrefix = 'resetPassword';

  passwordModel: string;

  countdownSeconds: number;

  confirmPasswordModel: string;

  confirmationChangePassword = false;

  confirmationOTP = '';

  vButtonVisibility = false;

  smsOtpFeatureFlag = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private merchantsService: AuthService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private translate: TranslateService,
    private customToast: CustomToastService,
    private merchantService: MerchantService,
    private timerService: TimerService,
    private intercom: Intercom,
    private localStorageService: LocalStorageService,
  ) {
    this.timerService.checkForTimer();
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    translate.use(this.lang);
    if (this.lang === 'en') {
      this.changeLang = 'ar';
    } else {
      this.changeLang = 'en';
    }
    // this.changeLang = this.lang || 'ar';
    this.otpFormControl = new FormControl(
      null,
      Validators.compose([Validators.required]),
    );
  }

  ngOnInit() {
    this.createForm();
    this.featureFlagService
      .isEnabled(FeatureFlagEnum.SmsOtp, false)
      .subscribe((smsOtpFeatureFlag) => {
        this.smsOtpFeatureFlag = smsOtpFeatureFlag;
      });

    this.timerService
      .isTimerActive()
      .pipe(
        tap((timerEnded) => {
          this.timerVisible = timerEnded;
        }),
      )
      .subscribe();

    this.timerService
      .currentTimeRemaining()
      .pipe(
        tap((timeRemaining) => {
          this.countdownSeconds = timeRemaining;
        }),
      )
      .subscribe();
  }

  get password() {
    return this.newPasswordFormGroup.get('password');
  }

  get pwdConfirm() {
    return this.newPasswordFormGroup.get('pwdConfirm');
  }

  get verificationCode() {
    return this.newPasswordFormGroup.get('verificationCode');
  }

  createForm() {
    this.newPasswordFormGroup = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(RegexEnum.password)]],
      pwdConfirm: [''],
      verificationCode: ['', [Validators.required]],
    });
    this.newPasswordFormGroup.valueChanges.subscribe(() => {
      this.errMessage = null;
    });
  }

  startIntercom(): void {
    this.intercom.show();
  }

  getOTP(event: string): void {
    this.confirmationOTP = event;
    this.errMessage = null;
    if (this.confirmationOTP.length === 6) {
      this.vButtonVisibility = true;
    } else {
      this.vButtonVisibility = false;
    }
  }

  renderMessage(response: ForgotPasswordDTO): void {
    if (response.CodeDeliveryDetails.DeliveryMedium === 'SMS') {
      if (!this.resendConfirmationCode) this.sendToEmailButtonVisibility = true;
      const phone = response.CodeDeliveryDetails.Destination;
      this.message = this.translate.instant(
        `${this.translationPrefix}.resetPasswordCodeToPhone`,
        { email: this.userEmail, phone: phone.slice(-2) },
      );
    } else {
      this.message = this.translate.instant(
        `${this.translationPrefix}.resetPasswordCodeToEmail`,
        { email: this.userEmail },
      );
    }
  }

  sendToEmailInstead(): void {
    this.isShowSpinner = true;
    this.confirmationChangePassword = true;
    this.subscriptionRefs = this.merchantService
      .forgotPasswordMerchant(this.userEmail, this.confirmationChangePassword)
      .subscribe({
        next: (response) => {
          this.errMessage = null;
          this.renderMessage(response);
          this.isShowSpinner = false;
          this.customToast.success(this.message);
        },
        error: (err) => {
          if (
            err.error &&
            (err.error.message.includes('LimitExceededException') ||
              err.error.name.includes('LimitExceededException'))
          ) {
            this.customToast.error(
              this.translate.instant(
                `confirmRegistration.limitExceededMessage`,
              ),
            );
          } else {
            this.customToast.error(this.translate.instant(err.message));
          }
          this.customToast.error(this.translate.instant(err.message));
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  resendForgotPasswordCode(): void {
    this.subscriptionRefs = this.merchantService
      .forgotPasswordMerchant(this.userEmail)
      .subscribe({
        next: (response) => {
          this.timerService.startTimer(25);
          this.renderMessage(response);
          this.isShowSpinner = false;
          this.errMessage = null;
          this.customToast.success(
            this.translate.instant('A verification code is sent to your email'),
          );
        },
        error: (err) => {
          if (
            err.error &&
            (err.error.message.includes('LimitExceededException') ||
              err.error.name.includes('LimitExceededException'))
          ) {
            this.customToast.error(
              this.translate.instant(
                `confirmRegistration.limitExceededMessage`,
              ),
            );
          } else {
            this.customToast.error(this.translate.instant(err.message));
          }
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  setNewPassword(newPasswordStepValue: ResetPasswordNewPasswordStep): void {
    this.isShowSpinner = true;
    const confirmationCode = this.smsOtpFeatureFlag
      ? this.confirmationOTP
      : newPasswordStepValue.verificationCode;
    this.resetPasswordOrConfirmAlso(
      confirmationCode,
      newPasswordStepValue.password,
    ).subscribe({
      next: () => {
        this.isShowSpinner = false;
        this.errMessage = null;
        this.customToast.success(this.translate.instant('Password is changed successfully'));
        this.router.navigateByUrl('/login');
      },
      error: (err) => {
        if (
          err.error &&
          (err.error.name.includes('LimitExceededException') ||
            err.error.message.includes('LimitExceededException'))
        ) {
          this.customToast.error(
            this.translate.instant(`confirmRegistration.limitExceededMessage`),
          );
        } else {
          this.errMessage = this.translate.instant('Confirmation Code didn’t match the code you provided');
        }
        this.isShowSpinner = false;
      },
      complete: () => {
        this.isShowSpinner = false;
      },
    });
  }

  resetPasswordOrConfirmAlso(
    verificationCode: string,
    newPassword: string,
  ): Observable<ResendConfirmationDTO | boolean> {
    if (this.resendConfirmationCode === true) {
      return this.merchantService.setNewPasswordAndConfirmMerchant(
        this.userEmail,
        verificationCode,
        newPassword,
      );
    }
    return this.merchantsService.resetPassword(
      this.userEmail,
      newPassword,
      verificationCode,
    );
  }

  showPassword() {
    this.isShowPassowd = !this.isShowPassowd;
  }
}
