import { Subscription } from 'rxjs';

export function AutoUnsubscribe<T extends AutoUnsubscribeI>(target: new (...args) => T) {
  const originalOnDestroy = target.prototype.ngOnDestroy;
  let subscriptions = new Subscription();

  Object.defineProperty(target.prototype, 'subscriptionRefs', {
    set(sub: Subscription) { subscriptions.add(sub); },
  });

  target.prototype.ngOnDestroy = function () {
    subscriptions.unsubscribe();
    subscriptions = new Subscription();

    if (originalOnDestroy) {
      originalOnDestroy.apply(this, arguments);
    }
  };
}

export declare interface AutoUnsubscribeI {
  readonly subscriptionRefs: Subscription;
}
