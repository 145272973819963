import { SaleType } from '../../../constants/invoice-contants';
import { ProductVariantType } from '../enum/product-enum';

export type VariantQuantityAndCost = {
  variant: ProductVariantStock;
  variantDetails: VariantDetails[];
};

export type CompositeStockLocation = {
  id: number;
  productId: number;
  sku: string;
  productToComposite: {
    id: number;
    productVariantId: number;
    rate: number;
    variantToLocation: {
      productVariantId: number;
      stockLocationId: number;
      quantity: number;
    }[];
  }[];
};

export type VariantDetails = {
  productVariantId: number;
  rate: number;
  productVariant?: {
    id: number;
    type: ProductVariantType;
    productVariantId: number;
    rate: number;
  };
};

export type IVariantToTrack = {
  expiryDate?: Date | undefined;
  id?: number | undefined;
  trackNo: string;
  quantity: number;
  issueDate?: Date;
  productVariantToStockLocationId?: number;
  supplierId?: number;
};

type IECard = {
  id?: number | undefined;
  productVariantId?: number | undefined;
  isSold?: boolean | undefined;
  code: string;
};

export type ECardForUpdate = IECard & {
  variantToInvoiceEcardId?: number;
};

export type VariantToTrackForUpdate = IVariantToTrack & {
  variantToInvoiceTrackId?: number;
};

export enum TrackTypes {
  batch = 'batch',
  serial = 'serial',
}

export enum ProductType {
  simple = 'simple',
  variable = 'variable',
  composite = 'composite',
  ecard = 'ecard',
  eproduct = 'eproduct',
}

export type UpdateVariantToStockLocation = {
  quantityChanged: number;
  newCost?: number;
  stockLocationId: number | null;
  productVariantId: number;
  variantToTracks?: VariantToTrackForUpdate[];
  ecards?: ECardForUpdate[];
  trackType?: TrackTypes | undefined | null;
  productType?: ProductType;
  variantToInvoiceId?: number;
};

export type UpdateVariantStocksAndCost = {
  quantityChanged: number;
  newCost?: number;
  productVariantId: number;
  variantToTracks: VariantToTrackForUpdate[];
  ecards: ECardForUpdate[];
  manageStockLevels?: boolean;
  sku?: string;
  productName?: string;
  quantity?: number;
  metadata?: {
    invoiceNumber?: string;
    costInclusive?: number;
    costExclusive?: number;
    isAllLocations?: number;
    saleType?: SaleType;
    price?: string;
    tax?: string;
    discount?: string;
    cost?: string;
    sku?: string;
  };
};

export type ProductVariantStock = UpdateVariantToStockLocation;
