<rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner>
<div class="auth-page">
  <div class="container px-4" [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
      <div class="auth-head">
        <div class="flex align-items-center justify-content-between py-5">
          <div>
            <img src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="" height="36">
          </div>
          <div class="flex align-items-center gap-2">
            <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
        </div>
        </div>
      </div>

      <div class="auth-body">
        <rw-sign-up
          [ngClass]="{'hidden': activeScreen !== OnboardingScreens.SignUp}"
          [signUpForm]="signUpForm"
          (signUpFormEmitter)="handleSignUpFormEvent($event)"
          (completion)="nextPage()"
        ></rw-sign-up>

        <rw-create-password
          [ngClass]="{'hidden': activeScreen !== OnboardingScreens.Password}"
          [signUpForm]="signUpForm"
          [passwordForm]="passwordForm"
          [activatedRoute]="activatedRoute"
          (editInfo)="prevPage()"
          (completion)="nextPage()"
        ></rw-create-password>

        <rw-verify-otp
          [ngClass]="{'hidden': activeScreen !== OnboardingScreens.VerifyOtp}"
          [activeScreen]="activeScreen"
          [signUpForm]="signUpForm"
          [passwordForm]="passwordForm"
          (completion)="nextPage()"
        ></rw-verify-otp>

        <rw-preparing-account
          [ngClass]="{'hidden': activeScreen !== OnboardingScreens.Preparing}"
        ></rw-preparing-account>
      </div>
  </div>
</div>