import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChildVariant, Product } from 'src/app/inventory/model/product';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { INVOICE_TYPES, VariantTypes } from 'src/app/shared/constants';
import { ProductVariantStockLocation } from 'src/app/shared/model/ProductVariantStockLocation';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TransferStockVariants } from '../../models/transfer-stock-variants';
import { isDefined } from '../../../utils';
import { InvoicesCalculator } from '../../utilities/invoices.calculator';
import { TrackedProductsComponent } from '../../../shared/components/tracked-products/tracked-products.component';
import { TransferStockService } from './transfer-stock.service';

@Injectable()
export class TransferStockHelperService {
  constructor(
    public translate: TranslateService,
    private transferStockService: TransferStockService,
    private dialogService: DialogService,
    private modalRef: DynamicDialogRef,
  ) {}

  async getTransferStockByIds(ids) {
    const reqs = [];
    ids.forEach((id) => {
      reqs.push(this.transferStockService.getTransferStockById(id).toPromise());
    });
    return Promise.all(reqs);
  }

  mapInvoicesToAcceptReq(invoices) {
    const VariantToInvoices = invoices.map((invoice) => ({
      name: invoice.name,
      sku: invoice.sku,
      productVariantId: invoice.productVariantId,
      oldCost: invoice.oldCost,
      newCost: invoice.newCost,
      transferQuantity: invoice.transferQuantity,
      availableLocationQuantity: invoice.availableLocationQuantity,
      availableDestnationLocationQuantity:
        invoice.availableDestnationLocationQuantity,
      quantity: invoice.transferQuantity,
      requestedQuantity: invoice.requestedQuantity,
      type: invoice.type,
      VariantToInvoiceTracks: this.addAcceptedQtyToTrack(
        invoice.VariantToInvoiceTracks,
      ),
      HasTrackNumber:
        invoice.VariantToInvoiceTracks.length > 0 &&
        invoice.VariantToInvoiceTracks[0].trackNo !== '',
      trackType: invoice.ProductVariant?.trackType,
      childVariants:
        invoice.type === 'composite'
          ? invoice?.ProductVariant?.Product?.VariantToComposites
          : [],
    }));
    return {
      deliveryDate: new Date(),
      notes: '',
      VariantToInvoices,
    };
  }

  addAcceptedQtyToTrack(tracks) {
    return tracks.map((track) => {
      let accQty = 0;
      if (track.trackNo) accQty = track.quantity;
      track.acceptedTransferQuantity = accQty;
      return track;
    });
  }

  splitIdsToChunks(ids, size = 10) {
    let index = 0;
    const arrayLength = ids.length;
    const tempArray = [];
    for (index = 0; index < arrayLength; index += size) {
      const myChunk = ids.slice(index, index + size);
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  confirmPopup(body, successHanlder, cancelHandler) {
    const content = {
      title: this.translate.instant('Transfer Stock'),
      body,
      class: 'bulk-delete',
      modalParagraph: 'Confirm',
      success_btn: {
        handler: successHanlder,
        text: this.translate.instant('Confirm'),
      },
      cancel_btn: {
        handler: cancelHandler,
        text: this.translate.instant('Cancel'),
      },
    };
    this.modalRef = this.dialogService.open(ModalComponent, {
      showHeader: false,
      styleClass: 'rw__dynamic-dialog rw__dynamic-dialog-md max-w-30rem',
      data: { content },
      contentStyle: {
        padding: '0',
      },
    });
    return this.modalRef;
  }

  warningPopup(body, successHanlder, cancelHandler) {
    const content = {
      title: this.translate.instant('Composite Product Rate Changed'),
      body,
      class: 'bulk-delete',
      modalParagraph: 'Confirm',
      success_btn: {
        handler: successHanlder,
        text: this.translate.instant('Confirm'),
      },
      cancel_btn: {
        handler: cancelHandler,
        text: this.translate.instant('Cancel'),
      },
    };
    this.modalRef = this.dialogService.open(ModalComponent, {
      showHeader: false,
      styleClass: 'rw__dynamic-dialog rw__dynamic-dialog-md max-w-30rem',
      data: { content },
      contentStyle: {
        padding: '0',
      },
    });
    return this.modalRef;
  }

  openRejectPopUp(invoiceId, successHanlder, cancelHandler) {
    const content = {
      templateName: 'reject-transfer-stock',
      invoiceId,
      title: this.translate.instant('Transfer Stock'),
      body: this.translate.instant('transfer warning message', {
        tranType: this.translate.instant('Reject'),
      }),
      class: 'bulk-delete',
      modalParagraph: 'Confirm',
      success_btn: {
        handler: successHanlder,
        text: this.translate.instant('Confirm'),
      },
      cancel_btn: {
        handler: cancelHandler,
        text: this.translate.instant('Cancel'),
      },
    };
    this.modalRef = this.dialogService.open(ModalComponent, {
      showHeader: false,
      styleClass: 'rw__dynamic-dialog rw__dynamic-dialog-md max-w-30rem',
      data: { content },
      contentStyle: {
        padding: '0',
      },
    });
    return this.modalRef;
  }

  openTrackingInfo(serialVariants, batchVariants, stockLocationId) {
    return this.dialogService.open(TrackedProductsComponent, {
      data: {
        serialVariants,
        batchVariants,
        invoiceType: INVOICE_TYPES.TRANSFER_STOCK,
        locationId: stockLocationId,
      },
    });
  }

  findChildVariantStockPackaged(
    childVariant: ChildVariant,
    locationId: number,
  ) {
    const variantToPackages = childVariant?.ProductVariant?.VariantToPackages;
    if (variantToPackages && variantToPackages?.length < 1) return 0;
    const stockLocation =
      variantToPackages[0]?.ProductVariant?.ProductVariantToStockLocations?.find(
        (stock) => stock.stockLocationId === locationId,
      );
    if (!stockLocation) return 0;
    return stockLocation;
  }

  findChildVariantStockNonPackaged(
    childVariant: ChildVariant,
    locationId: number,
  ) {
    const stockLocation =
      childVariant?.ProductVariant?.ProductVariantToStockLocations;
    return (
      stockLocation?.find((stock) => stock.stockLocationId === locationId) || {}
    );
  }

  getTotalByProp(prop, variants) {
    const temp = variants.reduce((total, variant) => +variant[prop] + total, 0);
    return temp ?? 0;
  }

  getPackageStockLocation(variant: ProductVariant, locationId: number) {
    const variantToPackages = variant.VariantToPackages;
    const packageStockLocation = variant.ProductVariantToStockLocations.find(
      (l) => l.stockLocationId === locationId,
    );

    if (!Array.isArray(variantToPackages) || variantToPackages.length === 0)
      return null;

    // variantToPackages in this case holds the Base Product.
    // The Product from which the Package is created
    const baseVariantToPackage = variantToPackages[0];
    const packageRate = baseVariantToPackage.rate;

    const baseVariantToStockLocations =
      baseVariantToPackage.ProductVariant?.ProductVariantToStockLocations;
    const baseStockLocation = baseVariantToStockLocations.find(
      (l) => l.stockLocationId === locationId,
    );
    const baseStockLocationQty = baseStockLocation?.quantity || 0;

    const isPackageVariantCostFalsy = !packageStockLocation.cost;
    const packageCost = isPackageVariantCostFalsy
      ? baseStockLocation.cost * packageRate
      : packageStockLocation.cost;

    return {
      ...packageStockLocation,
      quantity: baseStockLocationQty / packageRate,
      cost: packageCost,
    };
  }

  getCompositeStockLocation(
    compositeVariant: Product,
    locationId: number,
  ): number {
    const possibleLocationQuantity: Array<number> = [];
    if (!locationId) return 0;
    compositeVariant.VariantToComposites.forEach(
      (childVariant: ChildVariant) => {
        let variantLocation;
        if (childVariant.ProductVariant.type === VariantTypes.Package) {
          variantLocation = this.getPackageStockLocation(
            childVariant.ProductVariant,
            +locationId,
          );
        } else {
          variantLocation =
            childVariant.ProductVariant.ProductVariantToStockLocations.find(
              (d: ProductVariantStockLocation) =>
                d.stockLocationId === +locationId,
            );
        }
        const locationNotFound = !variantLocation;
        if (locationNotFound) {
          possibleLocationQuantity.push(0);
          return;
        }
        const { quantity } = variantLocation;
        possibleLocationQuantity.push(Math.floor(quantity / childVariant.rate));
      },
    );
    if (possibleLocationQuantity.length === 0) return 0;
    return Math.min(...possibleLocationQuantity);
  }

  calculateCompositeChildStockAvailability(
    childVariant: ChildVariant,
    locationId: number,
    isPackaged,
  ): number {
    const stockLocation = isPackaged
      ? this.findChildVariantStockPackaged(childVariant, locationId)
      : this.findChildVariantStockNonPackaged(childVariant, locationId);
    const packagedRate = isPackaged
      ? this.computePackagedRate(childVariant)
      : 1;

    if (!stockLocation) return 0;
    return  parseFloat((stockLocation.quantity / packagedRate).toFixed(2));
  }

  computePackagedRate(childVariant: ChildVariant) {
    const variantToPackages = childVariant?.ProductVariant?.VariantToPackages;
    return variantToPackages && variantToPackages.length > 0
      ? variantToPackages[0]?.rate
      : 1;
  }

  calculateCompositeChildQty = (childVariant: ChildVariant, quantity: number) =>
    childVariant.rate * quantity;

  getRetailPriceForStockLocationVariant = (
    variant: TransferStockVariants,
    productVariants: Array<any>,
  ) => {
    const productVariant = productVariants.find(
      (v) => v?.productVariantId === variant.productVariantId,
    );

    return productVariant?.retailPrice;
  };

  handleCompositePackageWithZeroCost(variantToPackage, locationId) {
    if (!Array.isArray(variantToPackage) || variantToPackage.length === 0)
      return 0;
    const basePackageVariant = variantToPackage[0];
    const baseVariantLocation =
      basePackageVariant?.ProductVariant.ProductVariantToStockLocations.find(
        (location) => location.stockLocationId === locationId,
      );
    const packageRate = basePackageVariant?.rate;
    return baseVariantLocation?.cost * packageRate;
  }

  getCompositeCost(product: Product, locationId: number) {
    const compositeCost = product?.VariantToComposites?.reduce(
      (sum: any, childVariant: ChildVariant): number => {
        let variantLocation: ProductVariantStockLocation =
          childVariant.ProductVariant.ProductVariantToStockLocations.find(
            (stockLocation: ProductVariantStockLocation) =>
              stockLocation.stockLocationId === +locationId,
          );

        if (!variantLocation) {
          [variantLocation] =
            childVariant.ProductVariant.ProductVariantToStockLocations;
        }

        let { cost } = variantLocation;
        if (childVariant.ProductVariant.type === VariantTypes.Package) {
          cost = variantLocation.cost || variantLocation.initialCost;
          const variantToPackage =
            childVariant?.ProductVariant?.VariantToPackages;

          cost = this.handleCompositePackageWithZeroCost(
            variantToPackage,
            locationId,
          );
        }
        const costForRate: number =
          variantLocation && childVariant.rate
            ? InvoicesCalculator.multiply(cost, childVariant.rate)
            : 0;
        if (variantLocation) {
          return isDefined(sum)
            ? InvoicesCalculator.add(sum, costForRate)
            : costForRate;
        }
        return 0;
      },
      0,
    );
    return compositeCost || 0;
  }

  mapDataToTransferStockProductVariant(variants): unknown {
    const transferStockProductVariant: any = {};

    transferStockProductVariant.productVariantId = variants.id;
    transferStockProductVariant.transferredQuantity = variants.transferQuantity;
    transferStockProductVariant.receivedQuantity = variants.receivedQuantity;
    transferStockProductVariant.costPrice = variants.newCost;
    transferStockProductVariant.productVariantName = variants.name;
    transferStockProductVariant.sku = variants.sku;
    transferStockProductVariant.type = variants.Product.type;

    return transferStockProductVariant;
  }

  mapDataForTheTrackStock(variantsToTrack): unknown {
    const variantsToTrackData = [];
    if (variantsToTrack) {
      for (const i of variantsToTrack) {
        variantsToTrackData.push({
          trackType: i.isSerial ? 'serial' : 'batch',
          transferredQuantity: i.quantity,
          trackNo: i.trackNo,
          productVariantId: i.productVariantId,
        });
      }
    }
    return variantsToTrackData;
  }
}
