<div class="welcome-container">
  <div class="header">
    <img class="header-image" src="../../../assets/images/{{lang === 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" />
    <img class="header-image ampersand" src="../../../../assets/images/ampersand-logo.png" />
    <img class="header-image" src="../../../../assets/images/stc-logo.png" />
  </div>

  <div class="welcome">
    {{'cobrowsing.welcomeTo' | translate}}
    <a class="rewaa">{{'cobrowsing.rewaa' | translate}}</a>
    {{'cobrowsing.inside' | translate}}
    <a class="stc">stc</a>
  </div>

  <div class="partner">
    {{'cobrowsing.partner' | translate}}
  </div>

  <button [disabled]="loading" pButton pRipple class="start-tour-container" (click)="startTour()">
    <div class="text-container">
      <div class="start-tour" [style.text-align]="lang === 'ar' ? 'right' : 'left'">{{'cobrowsing.startTour' | translate}}</div>
      <div class="representative" [style.text-align]="lang === 'ar' ? 'right' : 'left'">{{'cobrowsing.startWith' | translate}}</div>
    </div>
    <i class="fa-regular {{lang === 'ar' ? 'fa-arrow-left' : 'fa-arrow-right'}}"></i>
  </button>
</div>
<div class="lang-btn-container">
  <p-button [disabled]="languageBtnDisabled" label="{{lang === 'ar' ? 'Eng' : 'عربي'}}" styleClass="p-button-raised p-button-text p-button-success" (click)="onChangeLanguage()"></p-button>
</div>
