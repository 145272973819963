import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SettingsActions } from '../settings.action-types';
import { ITaxConfiguration } from '../../shared/model/TaxConfiguration';

export interface UserPriceConfigurationState extends EntityState<ITaxConfiguration> {
  allPriceConfigurationLoaded: boolean;
}

export const userPriceConfigStateKey = 'UPCSK';
export const userPriceConfigAdaptor = createEntityAdapter<ITaxConfiguration>();
export const userPriceConfigInitialState = {
  ...userPriceConfigAdaptor.getInitialState(),
  allPriceConfigurationLoaded: false,
};

const reducer = createReducer(userPriceConfigInitialState,
  on(SettingsActions.addUserPriceConfigurations,
    (state, action) => userPriceConfigAdaptor
      .setAll(action.configurations, { ...state, allPriceConfigurationLoaded: true })),
  on(SettingsActions.updateUserPriceConfiguration,
    (state, action) => {
      userPriceConfigAdaptor.removeAll(state);
      return userPriceConfigAdaptor.setAll([action.priceConfig],
        { ...state, allPriceConfigurationLoaded: true });
    }));

export const { selectAll } = userPriceConfigAdaptor.getSelectors();
export function userPriceConfigReducer(state: UserPriceConfigurationState, action: Action) {
  return reducer(state, action);
}
