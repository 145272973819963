export enum PaymentStatusSource {
  Webhook = 'Webhook',
  WebSdk = 'WebSdk',
  AndroidSdk = 'AndroidSdk',
  Api = 'Api',
}

export enum PaymentStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum PaymentType {
  NearPay = 'NearPay',
}

export const RewaaPayTransactionStatusConstant = {
  Approved: 'Approved',
  Rejected: 'Rejected',
  Cancel: 'Cancel',
  Declined: 'Declined',
  GeneralFailure: 'GeneralFailure',
  AuthenticationFailed: 'AuthenticationFailed',
  InvalidDeviceStatus: 'InvalidDeviceStatus',
} as const;

export type RewaaPayTransactionStatus =
  (typeof RewaaPayTransactionStatusConstant)[keyof typeof RewaaPayTransactionStatusConstant];
