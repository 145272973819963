import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[rwSelectOnClick]',
})
export class SelectOnClickDirective {
  constructor(private el: ElementRef) {}

  @HostListener('click') onClick() {
    const inputElement = this.el.nativeElement.querySelector('input') as HTMLInputElement;
    if (inputElement) {
      inputElement.select();
    } else {
      this.el.nativeElement.select();
    }
  }
}
