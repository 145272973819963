import {
  Component, Input, OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { getSettings } from 'src/app/internal-apps/pos/actions/settings.action';
import { selectAllPosSettings } from 'src/app/internal-apps/pos/selectors/settings.selector';
import { RegisterService } from 'src/app/internal-apps/pos/services/register.service';
import { getSettingValue } from 'src/app/internal-apps/pos/utils/common.utils';
import { AppState } from 'src/app/reducers';
import { AutoUnsubscribe, AutoUnsubscribeI } from 'src/app/shared/decorators/auto-unsubscribe';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NumberDialogueComponent } from '../../../number-dialogue/number-dialogue.component';
import { CASH_TYPES, LocalStorageKey, POS_SETTINGS } from '../../../../constants';
import { CustomToastService } from '../../../../services/custom-toast.service';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
@Component({
  selector: 'rw-add-withdraw-money',
  templateUrl: './add-withdraw-money.component.html',
  styleUrls: ['./add-withdraw-money.component.scss'],
})
@AutoUnsubscribe
export class AddWithdrawMoneyComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  @Input() content: any;

  form: UntypedFormGroup;

  cashTypes = CASH_TYPES;

  langCss: string;

  autoPayValue: number[] = [10, 50, 100, 500, 0.5, 1, 5];

  totalBalance = 0;

  destroyed = false;

  submitButtonText = CASH_TYPES.ADD;

  numberDialogValue: string;

  disabledSubmit: boolean = false;

  constructor(
    private dialogService: DialogService,
    private fb: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    private registerService: RegisterService,
    private store: Store<AppState>,
    public translate: TranslateService,
    private toaster: CustomToastService,
    public dynamicDialogRef: DynamicDialogRef,
    private localStorageService: LocalStorageService,
  ) {
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.setDefaultLang(lang);
    this.langCss = lang === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    this.createForm();
    this.subscriptionRefs = this.registerService
      .getTotalRegisterBalance(this.content.register.id).pipe(
        take(1),
        tap((balance) => {
          if (balance > 0) {
            this.totalBalance = balance;
          }
        }),
      ).subscribe();

    this.store.dispatch(getSettings());
    this.store.pipe(
      select((selectAllPosSettings)),
      distinctUntilChanged(),
      tap((settings) => {
        if (settings.length > 0) {
          this.numberDialogValue = getSettingValue(settings, POS_SETTINGS.NumberDialogue);
        }
      }),
    ).subscribe();
  }

  showNumberDialogue() {
    if (this.numberDialogValue === '1') {
      this.dialogService.open(NumberDialogueComponent, {
        styleClass: 'pos-number-modal',
        data: {
          amount: this.form.value.amount,
          onOk: this.onSetAmount.bind(this),
        },
      });
    }
  }

  onSetAmount(num: number) {
    this.form.patchValue({ amount: num });
  }

  createForm() {
    this.form = this.fb.group({
      cashType: [this.cashTypes.ADD, Validators.required],
      amount: [0, Validators.required],
      notes: ['', Validators.required],
    });
    this.subscriptionRefs = this.form.get('cashType').valueChanges
      .pipe(
        tap((type) => {
          if (type === this.cashTypes.ADD) {
            this.submitButtonText = this.cashTypes.ADD;
          } else {
            this.submitButtonText = this.cashTypes.WITHDRAW;
            if (this.form.get('amount').value > this.totalBalance) {
              this.form.get('amount').patchValue(0);
            }
          }
        }),
      ).subscribe();

    this.subscriptionRefs = this.form.get('amount').valueChanges
      .pipe(
        tap((amount) => {
          if (this.form.get('cashType').value === this.cashTypes.WITHDRAW) {
            if (amount > this.totalBalance) {
              this.form.get('amount').patchValue(0);
            }
          }
        }),
      ).subscribe();
  }

  submitAddWithdrawMoney() {
    this.disabledSubmit = true;
    const data = {
      userId: this.content.register.openedByUserId,
      type: this.form.get('cashType').value,
      amount: this.form.get('amount').value,
      notes: this.form.get('notes').value,
    };

    this.spinnerService.startLoading();
    this.subscriptionRefs = this.registerService
      .addWithdrawCashRegister(this.content.register.id, data).pipe(
        tap((response) => {
          this.spinnerService.stopLoading();
          if (response && response.success) {
            if (this.form.get('cashType').value === this.cashTypes.ADD) {
              this.toaster.success(this.translate.instant('the amount has been added to the Register Successfully', { amount: data.amount }));
            } else {
              this.toaster.success(this.translate.instant('Withdrawn amount from your Register Successfully', { amount: data.amount }));
            }
            this.dynamicDialogRef.close();
          }
        }),
      ).subscribe(() => {
        this.disabledSubmit = false;
      },
      (error) => {
        this.disabledSubmit = false;
        console.error(error);
      });
  }

  patchAmount(amount) {
    this.form.get('amount').patchValue(amount);
    if (this.form.get('cashType').value === this.cashTypes.WITHDRAW && amount > this.totalBalance) {
      this.form.get('amount').patchValue(0);
    }
  }
}
