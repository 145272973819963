import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Tax } from '../../shared/model/Tax';
import { TaxesService } from '../../shared/services/tax.service';

@Injectable({
  providedIn: 'root'
})
export class TaxCacheService implements OnDestroy {
  constructor(private readonly taxApiService: TaxesService) {
    console.log(`ineigw`)
  }

  private destroy$ = new Subject();

  private readonly taxes$ = new BehaviorSubject<Tax[]>([]);

  get taxes(): Observable<Tax[]> {
    return this.taxes$.asObservable();
  }

  setTaxes(): Observable<Tax[]> {
    return this.taxApiService.getAllTaxes().pipe(
      takeUntil(this.destroy$),
      tap((taxes) => this.taxes$.next(taxes)),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
