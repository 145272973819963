import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface HeaderDetailsInterface {
  title?: string;
  onBackButtonClick?: ()=> void;
  extraButton?: {
    visible: boolean,
    text: string;
    icon?: string;
    onClick: () => void;
  },
  pendoTag?: string;
}

@Injectable()
export class HeaderDetailsService {
  public headerSubject$ = new BehaviorSubject<HeaderDetailsInterface>({});

  public headerObservable$: Observable<HeaderDetailsInterface> = this.headerSubject$.asObservable();

  setHeader(headerDetails: HeaderDetailsInterface) {
    this.headerSubject$.next(headerDetails);
  }

  resetHeader() {
    this.headerSubject$.next({});
  }
}
