// eslint-disable-next-line import/no-cycle
import { Template } from './Template';

export class TemplateType {
  public id: number;

  public name: string;

  public isActive: number;

  public Templates?: Template[];
}

export enum TemplateTypes {
  Sales = 'Sales/ Return',
  KitchenTicket = 'Kitchen ticket',
  B2B = 'B2B Sales/ Return',
  B2C = 'B2C Sales/ Return',
}