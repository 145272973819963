export enum FirebaseCollections {
  Notifications = 'notifications',
  Users = 'users',
  TrialBalanceReportFilter = 'trialBalanceReportFilter',
  TransactionMappingJobStatus = 'transactionMappingJobStatus',
}

export enum FirebaseErrorCodes {
  FailedPrecondition = 'failed-precondition',
  Unimplemented = 'unimplemented',
}
