import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CompressorService } from '../services/compressor.service';

@Injectable()
export class GzipCompressionInterceptor implements HttpInterceptor {
  constructor(private compressorService: CompressorService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler) {
    return next.handle(httpRequest).pipe(map((value) => this.parseJsonResponse(value)));
  }

  private parseJsonResponse(event: HttpEvent<any>) {
    if (
      event instanceof HttpResponse
      && event.headers.get('X-Content-Encoding') === 'gzip'
      && event.headers.get('X-Content-Unzip-Type') === 'application/json'
    ) {
      // Logging for datadog
      console.warn(`performance may degrade due to gzip response of ${event.url}`);
      return event.clone({ body: this.compressorService.unCompressGzipToJSONSync(event.body) });
    }
    return event;
  }
}
