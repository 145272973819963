import { Injector } from '@angular/core';
import { LocalStorageKey } from '../shared/constants';
import { LocalStorageService } from '../core/services/local-storage.service';

const injector = Injector.create({
  providers: [
    { provide: LocalStorageService }
  ]
});

function switchToRTL() {
  document.getElementsByTagName('html').item(0).setAttribute('dir', 'rtl');
}
function switchToLTR() {
  document.getElementsByTagName('html').item(0).setAttribute('dir', 'ltr');
}

export function loadLanguageStyles() {
  return () => Promise.resolve().then(() => {
    const localStorageService = injector.get(LocalStorageService);
    const lang = localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    if (lang === 'en') {
      switchToLTR();
    } else {
      switchToRTL();
    }
  });
}
