import {
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

import { AuthUser } from '../../auth/auth.models';
import { environment } from '../../../environments/environment';

export class CognitoUtility {
  public static getUserPoolConfigurations(): CognitoUserPool {
    return new CognitoUserPool({
      UserPoolId: environment.userPoolId,
      ClientId: environment.clientId,
      endpoint: environment.userPoolId.includes('local')
        ? 'http://localhost:27123'
        : undefined,
    });
  }

  public static buildSignupAttributes(user: AuthUser): CognitoUserAttribute[] {
    const companyAttribute = this.buildAttribute(
      'custom:company_name',
      user.companyName,
    );
    const mobileAttribute = this.buildAttribute('phone_number', user.mobile);
    const emailAttribute = this.buildAttribute('email', user.email);
    const schemaNameAttribute = this.buildAttribute(
      'custom:schema_name',
      user.schemaName,
    );

    return [
      companyAttribute,
      mobileAttribute,
      emailAttribute,
      schemaNameAttribute,
    ];
  }

  private static buildAttribute(
    name: string,
    value: string,
  ): CognitoUserAttribute {
    return new CognitoUserAttribute({
      Name: name,
      Value: value,
    });
  }
}
