import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Setting } from '../model/Setting';
import { SettingsActions } from '../pos.actions';

export interface PosSettingsState extends EntityState<Setting> {
  posSettingsLoaded: boolean;
}

export const posSettingsFeatureKey = 'posSettings';

export const adaptor = createEntityAdapter<Setting>();

export const initialPosSettingsState = adaptor.getInitialState();

const reducer = createReducer(
  initialPosSettingsState,
  on(SettingsActions.posSettingsLoaded, (state, action) => adaptor.setAll(action.settings, {
    ...state,
    posSettingsLoaded: true,
  })),
  on(SettingsActions.posSettingsUpdated, (state, action) => adaptor
    .upsertOne(action.settings, state)),

  on(SettingsActions.posSettingsAdded, (state, action) => adaptor.addOne(action.settings, state)),
);

export const {
  selectAll,
} = adaptor.getSelectors();

export function settingsReducer(state: PosSettingsState | undefined, action: Action) {
  return reducer(state, action);
}
