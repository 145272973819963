import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeleteResponse } from 'src/app/inventory/variants/services/delete-response';
import { Promotion } from '../model/Promotions';
import { CreatePromotionInput } from '../model/create-promotion-input.types';
import {
  Category,
  GetPromotion,
  GetPromotionProduct,
} from '../model/promotion.types';

@Injectable()
export class PromotionsService {
  API_URL = '/api';

  MARKETING_URL_PREFIX = '/api/marketing';

  constructor(private readonly http: HttpClient) {}

  getPromotions(queryParams?: { ids: number[] }): Observable<Promotion[]> {
    const httpParams: {
      ids?: string;
    } = {};
    if (queryParams) {
      if (queryParams?.ids) {
        httpParams.ids = queryParams.ids.map((val) => val.toString()).join(',');
      }
    }
    return this.http
      .get<{ rows: Promotion[]; total: number }>(`${this.API_URL}/promotions`, {
        params: httpParams,
      })
      .pipe(
        map((response) =>
          response.rows.map((promotion) => new Promotion(promotion)),
        ),
      );
  }

  getIntersectedProductsPromotion(promotionDetails): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}/promotions/get_intersected_products_promotion`,
      promotionDetails,
    );
  }

  getUncategorizedProduct(filters): Observable<any> {
    const promotionParams = new HttpParams()
      .set('offset', filters.offset || '0')
      .set('limit', filters.limit || '10')
      .set('query', filters.query || '');
    return this.http.get<any>(`${this.API_URL}/promotions/variants`, {
      params: promotionParams,
    });
  }

  getUncategorizedProductV2(filters: {
    offset?: number;
    limit?: number;
    query?: string;
    productVariantIds?: string;
  }): Observable<GetPromotionProduct[]> {
    const promotionPayload = {
      offset: filters.offset || '0',
      limit: filters.limit || '10',
      query: filters.query || '',
      productVariantIds: filters.productVariantIds || '',
    };

    return this.http.post<GetPromotionProduct[]>(
      `${this.API_URL}/promotions/variants/v2`,
      promotionPayload,
    );
  }

  getAnyPageByQuery(filters): Observable<any> {
    const promotions = new HttpParams()
      .set('offset', filters.offset || '0')
      .set('limit', filters.limit || '10')
      .set('query', filters.search || '')
      .set('startDate', filters.startDate || '')
      .set('name', filters.name || '');
    return this.http.get<any>(`${this.API_URL}/promotions`, {
      params: promotions,
    });
  }

  getPromotionDetail(promotionId: any): Observable<Promotion> {
    return this.http.get<Promotion>(
      `${this.API_URL}/promotions/${promotionId}`,
    );
  }

  getPromotionDetailV2(promotionId: number): Observable<GetPromotion> {
    return this.http.get<GetPromotion>(
      `${this.MARKETING_URL_PREFIX}/promotions/${promotionId}`,
    );
  }

  deletePromotion(listOfIds: Array<number>): Observable<any> {
    const ids = listOfIds.join(',');
    return this.http.delete<DeleteResponse>(
      `${this.API_URL}/promotions?ids=${ids}`,
    );
  }

  savePromotion(promotionDetails): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/promotions/`, promotionDetails);
  }

  savePromotionV2(promotionDetails: CreatePromotionInput): Observable<boolean> {
    return this.http.post<any>(
      `${this.MARKETING_URL_PREFIX}/promotions/`,
      promotionDetails,
    );
  }

  uploadAgainPromotion(promotionDetails): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}/promotions/import`,
      promotionDetails,
    );
  }

  updatePromotion(promotionDetails): Observable<any> {
    return this.http.put<any>(
      `${this.API_URL}/promotions/${promotionDetails.id}`,
      promotionDetails,
    );
  }

  updatePromotionV2(
    promotionDetails: CreatePromotionInput,
    id: number,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.MARKETING_URL_PREFIX}/promotions/${id}`,
      promotionDetails,
    );
  }

  getImportProgress(fileName: string, merchantId: number): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL}/promotions/import/progress?fileName=${fileName}&merchantId=${merchantId}`,
    );
  }

  cancelImport(fileName: string, merchantId: number): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/promotions/import/cancel`, {
      fileName,
      merchantId,
    });
  }

  public getProductTemplate(templateData): Observable<any> {
    const queryString = Object.keys(templateData)
      .map((key) => `${key}=${templateData[key]}`)
      .join('&');
    return this.http.get<any[]>(
      `${this.API_URL}/promotions/import/templates?${queryString}`,
    );
  }

  getCategories(filters): Observable<Category[]> {
    const promotionParams = new HttpParams()
      .set('offset', filters.offset || '0')
      .set('limit', filters.limit || '10')
      .set('query', filters.query || '');
    return this.http.get<Category[]>(`${this.API_URL}/categories-promotions`, {
      params: promotionParams,
    });
  }

  getPromotionListV2(filtersData): Observable<any> {
    let sortByKey = null;
    let sortByValue = null;
    if (filtersData?.sortBy) {
      [sortByKey] = Object.keys(filtersData?.sortBy);
      sortByValue = filtersData?.sortBy[sortByKey];
    }
    let promotionParams;
    promotionParams = new HttpParams()
      .set('offset', filtersData.offset || '0')
      .set('limit', filtersData.limit || '10')
      .set(
        'filters',
        filtersData?.filters?.length > 0
          ? JSON.stringify(filtersData.filters)
          : '',
      );
    if (sortByKey && sortByValue) {
      promotionParams = promotionParams
        .set('sortBy', sortByKey)
        .set('sortDirection', sortByValue);
    }
    return this.http.get<any>(`${this.MARKETING_URL_PREFIX}/promotions`, {
      params: promotionParams,
    });
  }

  getPromotionListByStatus(status): Observable<any> {
    const promotionParams = new HttpParams()
      .set('filters', JSON.stringify(status))
      .set('offset', '0')
      .set('limit', '10');
    return this.http.get<any>(`${this.MARKETING_URL_PREFIX}/promotions`, {
      params: promotionParams,
    });
  }

  deletePromotionMarketing(listOfIds: Array<number>): Observable<any> {
    const ids = listOfIds.join(',');
    return this.http.delete<any>(
      `${this.MARKETING_URL_PREFIX}/promotions?ids=${ids}`,
    );
  }
}
