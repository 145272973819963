import {
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { AuthUser } from 'src/app/auth/auth.models';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import {
  BARCODE_SIZE,
  LocalStorageKey,
  TAX_TYPES,
} from 'src/app/shared/constants';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { PrintService } from 'src/app/shared/services/print.service';
import { UserService } from '../../../../../../auth/services/user.service';
import { Promotion } from '../../../../../../internal-apps/promotion/model/Promotions';
import { DiscountTypes } from '../../../../../../internal-apps/promotion/utills/constants';
import { AppState } from '../../../../../../reducers';
import { selectAllLocations } from '../../../../../../users-settings/selectors/locations.selector';
import { getUserPriceConfig } from '../../../../../../users-settings/selectors/price-configuration.selector';
import { selectProductConfiguration } from '../../../../../../users-settings/selectors/product-configuration.selector';
import { StockLocation } from '../../../../../model/StockLocation';
import { WeightedProductConfiguration } from '../../../../../model/WeightedProductConfiguration';
import { ProductVariant } from '../../../../../model/product-variant';
import { generateWeightedProductSku } from '../../../../../utility/weighted.product.utility';
import { LocalStorageService } from '../../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-print-barcode-v2',
  templateUrl: './print-barcode.component.html',
  styleUrls: ['./print-barcode.component.scss'],
})
@AutoUnsubscribe
export class PrintBarcodeComponent
  implements OnInit, OnDestroy, AutoUnsubscribeI
{
  subscriptionRefs;

  private destroy$ = new Subject();

  @HostBinding('class.print-barcode-revamp')
  content: any = {};

  variant: ProductVariant;

  variants: ProductVariant[];

  variantsToShowInDropdown: ProductVariant[];

  currentVariant: ProductVariant[];

  product: any;

  barcode: string;

  productName: string;

  form: FormGroup;

  locations$: Observable<StockLocation[]>;

  lng: string;

  langCss: string;

  weightedProductConfiguration: WeightedProductConfiguration;

  price: number;

  barcodeSize = BARCODE_SIZE;

  taxTypes = TAX_TYPES;

  type = this.barcodeSize.STANDARD.value;

  promotionValue = undefined;

  user: AuthUser;

  private getPriceConfiguration$ = this.store.select(getUserPriceConfig());

  private taxConfig;

  selectedLocation: Observable<StockLocation>;

  codeValues: string[] = ['barcode'];

  priceValues: string[] = ['retailPrice'];

  printOptions: string[] = [];

  currentVariantName: string = '';

  currentLocationId: number = -100;

  stockLocations: StockLocation[];

  minNoOfCopies: number = 1;

  InValidNoOfCopies: boolean = false;

  taxStatus: string;

  variantInfoArray: { name: string; price: number; code: string }[];

  fontSizeAndMaxCharactersInLineMapForStandard = {
    1: {
      fontClassName: 'standard-product-name-font-12px',
      maxCharactersInLine: 30,
    },
    2: {
      fontClassName: 'standard-product-name-font-10px',
      maxCharactersInLine: 32,
    },
    3: {
      fontClassName: 'standard-product-name-font-10px',
      maxCharactersInLine: 32,
    },
    4: {
      fontClassName: 'standard-product-name-font-9px',
      maxCharactersInLine: 34,
    },
    5: {
      fontClassName: 'standard-product-name-font-9px',
      maxCharactersInLine: 34,
    },
    6: {
      fontClassName: 'standard-product-name-font-8px',
      maxCharactersInLine: 38,
    },
    7: {
      fontClassName: 'standard-product-name-font-8px',
      maxCharactersInLine: 38,
    },
    8: {
      fontClassName: 'standard-product-name-font-7px',
      maxCharactersInLine: 42,
    },
    9: {
      fontClassName: 'standard-product-name-font-7px',
      maxCharactersInLine: 42,
    },
    10: {
      fontClassName: 'standard-product-name-font-6px',
      maxCharactersInLine: 60,
    },
  };

  printFeatures: {
    includeName: boolean;
    includeCompanyName: boolean;
    includePrice: boolean;
    includeTaxType: boolean;
    includeBarcode: boolean;
  };

  generateBarcode: ElementRef;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private translate: TranslateService,
    private router: Router,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    public printService: PrintService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
    this.content = this.dynamicDialogConfig.data.content;
  }

  ngOnInit() {
    this.loadContent();
    this.createForm();
    this.loadConfigsAndLocation();
    this.subscriptionRefs = this.form
      .get('copy')
      .valueChanges.subscribe((value) => {
        if (value > 0) {
          this.InValidNoOfCopies = false;
        } else {
          this.InValidNoOfCopies = true;
        }
      });
    this.subscriptionRefs = this.store
      .pipe(
        select(selectProductConfiguration),
        tap((config) => {
          this.taxConfig = {
            ...config.taxConfiguration,
            sellTaxStatus: config.taxConfiguration.sellTaxation,
          };
          this.taxStatus = this.translate.instant(
            `Tax ${this.taxConfig.sellTaxStatus}`,
          );
        }),
      )
      .subscribe();
    this.form.valueChanges.subscribe(() => {
      this.updateVariantInfoArray();
      this.updatePrintFeatures();
    });
    this.setSize('STANDARD');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadContent() {
    this.variants = this.content.variants;
    [this.variant] = [this.content.variants[0]];
    this.product = this.variant.Product || this.variant;
    if (this.content.showOnlyOneVariant) {
      this.variants = [this.content.variants[0]];
      this.variantsToShowInDropdown = this.content.variants;
    }
    this.currentVariantName = this.variants[0].name;
    this.barcode = this.content.barcode;
    this.productName =
      this.content.variant?.name ||
      this.content.productName ||
      this.content.product?.name;
    this.user = this.content.userDetails;
    if (!this.user) {
      this.setUser();
    }
  }

  private setUser(): void {
    this.userService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  calculatePromotionPriceFromVariantPromotions(promotions: any) {
    const promotion = promotions.find(
      (promotionTemp) =>
        promotionTemp.PromotionStockLocations.find(
          (tem) => tem.stockLocationId === this.stockLocationId,
        ) || promotionTemp.isAllLocations,
    );
    return promotion && this.getPrice() > 0
      ? this.calculateProductPriceAfterPromotion(this.getPrice(), promotion)
      : this.getPrice();
  }

  calculateProductPriceAfterPromotion(originalPrice = 0, promotion: Promotion) {
    if (promotion.discountType === DiscountTypes.PERCENTAGE) {
      return InvoicesCalculator.multiply(
        InvoicesCalculator.subtract(
          1,
          InvoicesCalculator.divide(promotion.amount, 100),
        ),
        originalPrice,
      );
    }
    if (promotion.discountType === DiscountTypes.AMOUNT) {
      return Math.max(
        InvoicesCalculator.subtract(originalPrice, promotion.amount),
        0,
      );
    }
    return originalPrice;
  }

  createForm() {
    const includeNameValue = this.checkIfExistsInLocalStorage(
      LocalStorageKey.PrintBarcodeIncludeName,
    );
    const includePriceValue = this.checkIfExistsInLocalStorage(
      LocalStorageKey.PrintBarcodeIncludePrice,
    );
    const includeCompanyValue = this.checkIfExistsInLocalStorage(
      LocalStorageKey.PrintBarcodeIncludeCompany,
    );
    const includeTaxTypeValue = this.checkIfExistsInLocalStorage(
      LocalStorageKey.PrintBarcodeIncludeTaxType,
    );

    this.form = this.fb.group({
      includeName: [includeNameValue || false],
      includePrice: [includePriceValue || false],
      includeCompany: [includeCompanyValue || false],
      includeBarcode: [true],
      code: ['sku'],
      size: [this.type],
      priceType: ['retailPrice'],
      includeTaxType: [includeTaxTypeValue],
      copy: [1, [Validators.min(1), Validators.required]],
      stockLocationId: [1],
      currentVariantName: this.variant.name,
    });
    this.form
      .get('includePrice')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((includePrice) => {
          if (!includePrice) {
            this.form.get('priceType').disable();
            this.form.get('stockLocationId').disable();
          } else {
            this.form.get('priceType').enable();
            this.form.get('stockLocationId').enable();
          }
          this.localStorageService.setItem<boolean>(
            LocalStorageKey.PrintBarcodeIncludePrice,
            includePrice,
          );
        }),
      )
      .subscribe();

    this.form
      .get('includeTaxType')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((includeTaxType) => {
          this.localStorageService.setItem<boolean>(
            LocalStorageKey.PrintBarcodeIncludeTaxType,
            includeTaxType,
          );
        }),
      )
      .subscribe();

    this.form
      .get('includeName')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((includeName) => {
          this.localStorageService.setItem<boolean>(
            LocalStorageKey.PrintBarcodeIncludeName,
            includeName,
          );
        }),
      )
      .subscribe();

    this.form
      .get('includeCompany')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((includeCompany) => {
          this.localStorageService.setItem<boolean>(
            LocalStorageKey.PrintBarcodeIncludeCompany,
            includeCompany,
          );
        }),
      )
      .subscribe();

    this.subscriptionRefs = this.form
      .get('priceType')
      .valueChanges.pipe(
        distinctUntilChanged(),
        tap(() => {
          this.price = this.getPrice();
        }),
      )
      .subscribe();

    this.subscriptionRefs = this.form
      .get('stockLocationId')
      .valueChanges.pipe(
        distinctUntilChanged(),
        tap(() => {
          this.price = this.getPrice();
          // this.calculatePromotionPriceFromProduct(this.product);
        }),
      )
      .subscribe();
  }

  loadConfigsAndLocation() {
    this.locations$ = this.store.pipe(
      select(selectAllLocations),
      tap((locations) => {
        if (locations.length > 0) {
          this.stockLocations = locations;
          this.currentLocationId = this.stockLocations[0].id;
          this.form.get('stockLocationId').patchValue(locations[0].id);
          this.form.get('currentVariantName').patchValue(this.variant.name);
        }
      }),
    );
    this.subscriptionRefs = this.store
      .pipe(
        select(selectProductConfiguration),
        tap((config) => {
          this.weightedProductConfiguration = {
            ...config.weightedProductConfiguration,
          };
        }),
      )
      .subscribe();
  }

  close() {
    this.dynamicDialogRef.close();
  }

  getPrice() {
    const priceType = this.form.get('priceType').value;
    const locationId = parseInt(this.form.get('stockLocationId').value, 10);
    const stockRecord = this.variant?.ProductVariantToStockLocations
      ? this.variant?.ProductVariantToStockLocations.find(
          (stock) => stock.StockLocation.id === locationId,
        )
      : this.variant;
    return stockRecord ? +stockRecord[priceType] : 0;
  }

  get includeName() {
    return this.form.get('includeName').value;
  }

  get includeCompanyName() {
    return this.form.get('includeCompany').value;
  }

  get includePrice() {
    return this.form.get('includePrice').value;
  }

  get includeTaxType() {
    return this.form.get('includeTaxType').value;
  }

  get stockLocationId() {
    return this.form.get('stockLocationId').value;
  }

  get priceType() {
    return this.form.get('priceType').value;
  }

  get code() {
    return this.form.get('code').value;
  }

  get getSku() {
    if (this.variant) {
      if (this.variant.isWeightedScale) {
        return generateWeightedProductSku(
          this.weightedProductConfiguration,
          this.variant,
          this.priceType,
          this.stockLocationId,
        );
      }
      return this.variant.sku;
    }
    return '';
  }

  get printedCode() {
    if (this.code === 'sku') {
      return this.getSku;
    }
    return this.variant.barCode;
  }

  get includeBarcode() {
    return this.form.get('includeBarcode').value;
  }

  printBarcode() {
    this.printService.printProductBarcode(
      'generate-inventory-barcode',
      this.form.get('copy').value,
    );
    this.closeModal();
  }

  setVariant(product: ProductVariant) {
    this.variant = product;
    this.setProduct();
  }

  setProduct() {
    this.product = this.variant.Product;
  }

  setSize(event: any) {
    this.type = event;
    if (this.type === 'STANDARD') {
      document.getElementById('standard-button').style.backgroundColor =
        '#00807d29';
      document.getElementById('large-button').style.backgroundColor =
        'transparent';
    } else if (this.type === 'LARGE') {
      document.getElementById('large-button').style.backgroundColor =
        '#00807d29';
      document.getElementById('standard-button').style.backgroundColor =
        'transparent';
    }
  }

  removeOrUpdateVariants(event: any) {
    if (event.value !== null) {
      this.updateVariants(event.value);
    } else if (
      event.value === null &&
      (this.variantsToShowInDropdown?.length > 1 || this.variants.length > 1)
    ) {
      this.removeVariants(this.currentVariantName);
      this.updateVariants(this.currentVariantName);
    }
    this.updateVariantInfoArray();
  }

  updateVariants(variantName: string) {
    this.currentVariantName = variantName;
    if (this.content.showOnlyOneVariant) {
      this.variants = [
        this.variantsToShowInDropdown.find((v) => v.name === variantName),
      ];
    }
  }

  removeVariants(variantName: string) {
    if (this.content.showOnlyOneVariant) {
      this.variantsToShowInDropdown = this.variantsToShowInDropdown.filter(
        (v) => v.name !== variantName,
      );
      this.updateSelectedVariantValueInForm(this.variantsToShowInDropdown);
    } else {
      this.variants = this.variants.filter((v) => v.name !== variantName);
      this.updateSelectedVariantValueInForm(this.variants);
    }
  }

  updateSelectedVariantValueInForm(variantsArray: ProductVariant[]) {
    if (variantsArray.length >= 1) {
      this.currentVariantName = variantsArray[0].name;
      this.form.get('currentVariantName').patchValue(variantsArray[0].name);
    }
  }

  removeOrUpdateLocations(event: any) {
    if (event.value !== null) {
      this.updateLocations(event.value);
    } else if (event.value === null && this.stockLocations.length > 1) {
      this.removeLocations(this.currentLocationId);
      this.updateLocations(this.currentLocationId);
    }
    this.updateVariantInfoArray();
  }

  updateLocations(locationId: number) {
    this.currentLocationId = locationId;
  }

  removeLocations(locationId: number) {
    this.stockLocations = this.stockLocations.filter(
      (v) => v.id !== locationId,
    );
    this.updateSelectedLocationValueInForm(this.stockLocations);
  }

  updateSelectedLocationValueInForm(locatioinsArray: StockLocation[]) {
    if (locatioinsArray.length >= 1) {
      this.currentLocationId = locatioinsArray[0].id;
      this.form.get('stockLocationId').patchValue(locatioinsArray[0].id);
    }
  }

  showLocationClearOption() {
    return this.stockLocations.length > 1;
  }

  showVariantsClearOption() {
    if (this.content.showOnlyOneVariant) {
      return this.variantsToShowInDropdown.length > 1;
    }
    return this.variants.length > 1;
  }

  closeModal() {
    this.dynamicDialogRef.close();
    if (this.content.showOnlyOneVariant === false) {
      this.router.navigateByUrl('/inventory/products/new').then(() => {
        this.router.navigateByUrl('/inventory/products');
      });
    }
  }

  getFinalPrice(variant: any) {
    return variant?.promotions?.length > 0
      ? this.calculatePromotionPriceFromVariantPromotions(variant.promotions)
      : this.getPrice();
  }

  updateVariantInfoArray() {
    const variantInfoArray: any = [];
    this.variants.forEach((variant) => {
      this.setVariant(variant);
      variantInfoArray.push({
        name: this.updateVariantName(variant.name),
        originalPrice: this.getPrice(),
        price: this.getFinalPrice(variant),
        code: this.printedCode,
      });
    });
    this.variantInfoArray = variantInfoArray;
  }

  updatePrintFeatures() {
    this.printFeatures = {
      includeName: this.includeName,
      includeCompanyName: this.includeCompanyName,
      includePrice: this.includePrice,
      includeTaxType: this.includeTaxType,
      includeBarcode: this.includeBarcode,
    };
  }

  checkIfExistsInLocalStorage(key: LocalStorageKey) {
    const val = this.localStorageService.getItem<boolean>(key);
    if (val !== undefined && val !== null) {
      return val;
    }
    return key !== LocalStorageKey.PrintBarcodeIncludeTaxType;
  }

  updateVariantName(name: string): string {
    let fontKey = 1;
    if (name.length > 30) {
      fontKey = Math.ceil((name.length - 30) / 7);
      fontKey = fontKey > 10 ? 10 : fontKey;
    }
    const lineLimit =
      this.fontSizeAndMaxCharactersInLineMapForStandard[fontKey.toString()]
        .maxCharactersInLine;
    let newName = '';
    let counter = 0;
    for (let i = 0; i < name.length; i++) {
      if (counter > lineLimit) {
        counter = 0;
        newName += ' ';
      }
      newName += name[i];
      counter += 1;
    }
    return newName;
  }
}
