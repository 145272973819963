import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Location } from '../shared/model/Location';
import { Merchant } from '../shared/model/Merchant';
import { ITaxConfiguration, TaxConfiguration } from '../shared/model/TaxConfiguration';
import { WeightedProductConfiguration } from '../shared/model/WeightedProductConfiguration';
import { ShippingConfiguration } from '../shared/model/ShippingConfiguration';
import { PaymentMethod } from '../shared/model/PaymentMethod';
import { QuantityConfiguration } from '../shared/model/QuantityConfiguration';
import { Tax } from '../shared/model/Tax';

export const loadAllUsers = createAction(
  '[Resolver] Load All Users',
);

export const usersLoaded = createAction(
  '[Settings Effects] Users Loaded',
  props<{ users: Merchant[] }>(),
);

export const loadAllLocations = createAction(
  '[Resolver] Load All Locations',
);
export const loadAllPaymentMethods = createAction(
  '[Resolver] Load All Payment Methods',
);

export const locationsLoaded = createAction(
  '[Settings Effects] Locations Loaded',
  props<{ locations: Location[] }>(),
);

export const paymentMethodsLoaded = createAction(
  '[Settings Effects] Payment Methods Loaded',
  props<{ paymentMethods: PaymentMethod[] }>(),
);

export const loadCompanyOverview = createAction(
  '[Resolver] Load Company Overview',
);

export const companyOverviewLoaded = createAction(
  '[Settings Effects] Overview Loaded',
  props<{ overview: Merchant }>(),
);

export const updateCompanyOverview = createAction(
  '[Overview page] Update Overview',
  props<{ updatedOverview: Merchant }>(),
);

export const companyOverviewUpdated = createAction(
  '[Settings effects] Overview Updated',
  props<{ update: Update<Merchant> }>(),
);

export const updateUser = createAction(
  '[View User] Update User',
  props<{ userId: number, updatedUser: Merchant }>(),
);

export const userUpdated = createAction(
  '[Settings Effect] User Updated',
  props<{ update: Update<Merchant> }>(),
);

export const updateLocation = createAction(
  '[Edit Location Page] Update Location',
  props<{ locationId: number, location: Location }>(),
);

export const locationUpdated = createAction(
  '[Settings Effect] Location Updated',
  props<{ location: Location }>(),
);

export const addLocation = createAction(
  '[Add Location Page] Create new location',
  props<{ location: Location }>(),
);

export const locationAdded = createAction(
  '[Settings Effect] New Location Added',
  props<{ location: Location }>(),
);

export const updatePaymentMethod = createAction(
  '[Edit Payment Method Page] Update Payment Method',
  props<{ paymentMethodId: number, paymentMethod: PaymentMethod }>(),
);

export const paymentMethodUpdated = createAction(
  '[Settings Effect] Payment Method Updated',
  props<{ paymentMethod: PaymentMethod }>(),
);

export const addPaymentMethod = createAction(
  '[Add Payment Method Page] Create new payment method',
  props<{ paymentMethod: PaymentMethod }>(),
);

export const paymentMethodAdded = createAction(
  '[Settings Effect] New Payment Method Added',
  props<{ paymentMethod: PaymentMethod }>(),
);

export const addUser = createAction(
  '[Add User] Add User',
  props<{ userMerchant: Merchant }>(),
);

export const userAdded = createAction(
  '[Settings Effect] User Added',
  props<{ savedUser: Merchant }>(),
);

export const userInError = createAction('[Settings Effect] User In Error');

export const loadAllLocationStocks = createAction(
  '[Resolver Effect] Load All Location Stock',
);

export const locationStocksLoaded = createAction(
  '[Settings Effect] All Location Stock Loaded',
  props<{ locationStock: any[] }>(),
);

export const getUserPriceConfiguration = createAction(
  '[User setting Resolver] Get user price configurations',
);

export const saveUserPriceConfiguration = createAction(
  '[User setting] Save user crice configurations',
  props<{ priceConfig: ITaxConfiguration }>(),
);

export const updateUserPriceConfiguration = createAction(
  '[User setting] Update user price configurations',
  props<{ priceConfig: ITaxConfiguration }>(),
);

export const addUserPriceConfigurations = createAction(
  '[User Settings] Add user price configurations state',
  props<{ configurations: Array<ITaxConfiguration> }>(),
);

export const loadWeightedProductConfiguration = createAction(
  '[Resolver] load weighted product configuration',
);

export const weightedProductConfigurationLoaded = createAction(
  '[settings effect] weighted product configuration loaded',
  props<{ config: WeightedProductConfiguration }>(),
);

export const updateWeightedProductConfiguration = createAction(
  '[configuration page] update weighted product configuration',
  props<{ config: WeightedProductConfiguration }>(),
);

export const loadShippingConfiguration = createAction(
  '[Resolver] load shipping configuration',
);

export const shippingConfigurationLoaded = createAction(
  '[Settings Effects] shipping configuration loaded',
  props<{ config: ShippingConfiguration }>(),
);

export const updateShippingConfiguration = createAction(
  '[configuration page] update shipping configuration',
  props<{ config: ShippingConfiguration }>(),
);

export const loadProductConfiguration = createAction(
  '[Resolver] load product configuration',
);

export const productConfigurationLoaded = createAction(
  '[settings effect] product configuration loaded',
  props<{ config: {
    quantityConfiguration: QuantityConfiguration,
    shippingConfiguration: ShippingConfiguration,
    weightedProductConfiguration: WeightedProductConfiguration,
    taxConfiguration: TaxConfiguration,
    taxes: Tax[]
  } }>(),
);
