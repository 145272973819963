import { IPOTableColumn } from '../../../shared/components/datatable-po-variant/types';

export enum DiscountTypes {
  FIXED = 'fixed',
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

export const PROMOTION = {
  TEXT: '',
  ICON: '',
  FORMNAME: '',
};

export const MODES = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const PRIMENG_DIALOG_SIZE = {
  SM: '30%',
  MD: '50%',
  LG: '80%',
};

export const PromotionTemplateHeaders = {
  productSKU:
    'الرمز التعريفي SKU | مطلوب | Product SKU | Required | Unique in the file and in the system | Text',
};

export const PromotionTableColumns: IPOTableColumn[] = [
  {
    header: 'Promotion Name',
    field: 'name',
    isSelected: true,
    skipSort: false,
    dataType: 'string',
    skipFilter: false,
    placeholder: 'Promotion Name',
  },
  {
    header: 'Location',
    field: 'description',
    isSelected: true,
    skipFilter: true,
    skipSort: true,
    dataType: 'string',
  },
  {
    header: 'Products Included',
    field: 'name',
    skipSort: true,
    skipFilter: true,
    isSelected: true,
    dataType: 'string',
  },
  {
    header: 'Start Date',
    field: 'startDate',
    skipSort: false,
    skipFilter: false,
    isSelected: true,
    dataType: 'date',
  },
  {
    header: 'End Date',
    field: 'endtDate',
    isSelected: true,
    skipSort: false,
    skipFilter: false,
    dataType: 'date',
  },
  {
    header: 'Status',
    field: 'status',
    dataType: 'enum',
    multiple: true,
    isSelected: true,
    matchMode: 'in',
    filter: [],
    defaultFilter: false,
    skipSort: true,
    skipFilter: false,
    placeholder: 'Status',
  },
];
