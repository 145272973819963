/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UserGuidingService {
  userGuiding!: any;

  userGuidingLayer!: any;

  constructor() {
    this.userGuiding = window['userGuiding'];
    this.userGuidingLayer = window['userGuidingLayer'];
  }

  previewGuide(guideId: number, options: any) {
    this.userGuiding?.previewGuide(guideId, options);
  }

  onPreviewStart(): Observable<void> {
    return new Observable((subscriber) => {
      this.userGuidingLayer?.push({
        event: 'onPreviewStart',
        fn: () => subscriber.complete(),
      });
    });
  }

  onPreviewStep(): Observable<{ guideId: number, stepIndex: number }> {
    return new Observable<{ guideId: number, stepIndex: number }>((subscriber) => {
      this.userGuidingLayer?.push({
        event: 'onPreviewStep',
        fn: (data: { guideId: number; stepIndex: number; }) => {
          subscriber.next(data);
        },
      });
    });
  }

  onPreviewEnd(): Observable<void> {
    return new Observable((subscriber) => {
      this.userGuidingLayer?.push({
        event: 'onPreviewEnd',
        fn: () => subscriber.complete(),
      });
    });
  }

  finishPreview() {
    this.userGuiding?.finishPreview();
  }
}
