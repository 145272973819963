export enum UserType {
  Gcc = 'gcc',
  NonGcc = 'nongcc',
  GccWithoutPh = 'gcc_phone',
}

export enum DeliveryMediumType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
