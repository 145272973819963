import { ISupplierQuery } from '../../inventory/model/supplier';
import {
  ISuppliersSerachFilterV2,
  ISuppliersSerachFilter,
  Filter,
} from '../model/supplier-search-and-filter';

function mapSupplierV1FiltersToV2(params: ISuppliersSerachFilter): Filter[] {
  const filters: Filter[] = [];
  const columnNames = [
    'search',
    'debitAmount',
    'creditAmount',
    'netDueAmount',
    'dates',
  ];
  columnNames.forEach((element) => {
    if (element in params) {
      // eslint-disable-next-line default-case
      switch (element) {
        case 'debitAmount':
          filters.push({
            columnName: 'debitAmount',
            value: 0,
            matchMode: params[element] === 'has' ? 'not' : 'equal',
          });
          break;
        case 'creditAmount':
          filters.push({
            columnName: 'creditAmount',
            value: 0,
            matchMode: params[element] === 'has' ? 'not' : 'equal',
          });
          break;
        case 'netDueAmount':
          filters.push({
            columnName: 'netDueAmount',
            value: 0,
            matchMode: params[element] === 'has' ? 'not' : 'equal',
          });
          break;
        case 'dates':
          if (params.dates?.fromDate && params.dates?.toDate) {
            const filter: any = {
              columnName: 'stockOrder.paymentDueDate',
              value: [params.dates.fromDate, params.dates.toDate],
              matchMode: 'between',
            };
            filters.push(filter);
          }
      }
    }
  });
  return filters;
}

export function mapSupplierQueryParamsToV2(
  params: ISuppliersSerachFilter,
): ISuppliersSerachFilterV2 {
  return {
    offset: params.offset,
    limit: params.limit,
    filters: JSON.stringify(mapSupplierV1FiltersToV2(params)),
    sortBy: 'supplier.id',
    sortDirection: 'desc',
    search: params.search,
  };
}

export function mapSupplierQueryToV2(
  params: ISupplierQuery,
): ISuppliersSerachFilterV2 {
  return {
    offset: params.offset,
    limit: params.limit,
    filters: '',
    search: params.name,
    sortBy: 'supplier.id',
    sortDirection: 'desc',
  };
}
