import {
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageKey } from '../../constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { commonCalculationService } from '@rewaa-team/pos-sdk';
import { NumberDialogueData } from './number-dialogue.component.type';

@Component({
  selector: 'rw-number-dialogue',
  templateUrl: './number-dialogue.component.html',
  styleUrls: ['./number-dialogue.component.scss'],
})
export class NumberDialogueComponent implements OnInit {
  @Input() modalOpen = false;

  @Input() modalClose = false;

  @Input() acceptFraction = true;

  @Input() modal: any = null;

  @Input() onOk: Function;

  @Input() amount: number;

  @Input() formControlName: string;

  @Input() closeModel: Function;

  @Output() onModalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() hideArrow = false;

  @Input() dialog = true;

  langCss: string;

  numbers: Array<number> = [7, 8, 9, 4, 5, 6, 1, 2, 3];

  form: UntypedFormGroup;

  dialogRef: DynamicDialogRef;

  constructor(
    private injector: Injector,
    private formBulider: UntypedFormBuilder,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.setDefaultLang(lang);
    this.langCss = lang === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    if (this.dialog) {
      const config = <DynamicDialogConfig<NumberDialogueData>>(
        this.injector.get(DynamicDialogConfig)
      );
      this.dialogRef = <DynamicDialogRef>this.injector.get(DynamicDialogRef);
      if (config.data) {
        this.amount = commonCalculationService.roundTo2Decimals(
          config.data.amount,
        );
        this.onOk = config.data.onOk;
        this.formControlName = config.data.formControlName ?? '';
        this.acceptFraction = config.data.acceptFraction ?? true;
      }
    }
    this.form = this.formBulider.group({ amount: [this.amount || ''] });
  }

  cancel() {
    this.onModalClose.emit(true);
    if (this.dialog) this.dialogRef.close();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onOkClick();
    }
  }

  onOkClick() {
    if (this.formControlName) {
      this.onOk(this.formControlName, +this.form.get('amount').value);
    } else {
      this.onOk(+this.form.get('amount').value);
    }
    this.cancel();
  }

  checkSelection() {
    const inputElement = document.getElementById('amount') as HTMLInputElement;
    // Check if the input value is selected
    if (
      inputElement.selectionStart !== null &&
      inputElement.selectionEnd !== null &&
      inputElement.selectionStart !== inputElement.selectionEnd
    ) {
      return true;
    }
    return false;
  }

  onNumClick(num: number | string) {
    const amount = this.form.get('amount').value;
    if (num === '.' && amount.toString().includes('.')) return;
    if (this.checkSelection()) {
      this.setAmount(`${num}`);
      return;
    }
    this.setAmount(`${amount}${num}`);
  }

  setAmount(value) {
    this.form.get('amount').patchValue(value);
  }

  onDelete(value) {
    this.setAmount(
      value ? value.toString().substr(0, value.toString().length - 1) : '',
    );
  }
}
