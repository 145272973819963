export interface ProductPackage {
  key: string;
  name: string;
  sku: string;
  barcode: string;
  packageSize: number;
  image: UploadedImage;
  sellable: boolean;
  purchaseable: boolean;
}

export interface UploadedImage {
  id: number;
  name: string;
  isDefault: boolean;
  ProductToFile?: ProductToFile;
  type: string;
  link: string;
  s3TmpKey: string;
  isSelected?: boolean;
}

export interface ProductToFile {
  fileId?: number;
  id?: number;
  isDefault?: boolean
  productId?: number;
}

export enum UploadFileErrors {
  MAX_FILE_SIZE_EXISTED = 'MAX_FILE_SIZE_EXISTED',
  FILE_NOT_ALLOWED = 'FILE_NOT_ALLOWED',
}

export interface ExtraProduct {
  id: number;
  image: UploadedImage;
  name: string;
  price: string;
  isPartFormOtherProduct: boolean;
  fromProduct: {
    ratio: number;
    quantity: number;
    product: {
      id: number;
      name: string;
    };
  };
}

export interface onAddExtraPayload extends ExtraProduct { }
export interface onRemoveExtraPayload extends ExtraProduct { }
export interface onExtraChangedPayload extends ExtraProduct { }

export interface Identifier {
  id: number;
  name: string;
  level?: number;
  parentId?: number;
}

export type FormConfig = {
  disableQuantity?: boolean;
  disableTax?: boolean;

};
export interface Location {
  id: number;
  name: string;
  locationProducts: LocationProduct[];
  extended?: boolean;
  stockLocationId: string;
  availableLocationFieldId?: LocationFields[];
}
export interface LocationProduct {
  id: string;
  name: string;
  quantity: number;
  retailPrice: number;
  wholeSalePrice: number;
  buyPrice: number;
  initialCost: number;
  cost?: any;
  taxId: number;
  isMainProductStock: boolean;
  rateFromAnotherProduct: {
    quantity: number;
  };
  productVariantId: string
}

export interface StockPrice {
  initialCost: number;
  disableStockManagement: boolean;
  weightedScaleProduct: boolean;
}

export interface Tax {
  id: number;
  name: string;
}
export enum LocationFields {
  QTY = 'QTY',
  RETAIL_PRICE = 'RETAIL_PRICE',
  WHOLE_SALE_PRICE = 'WHOLE_SALE_PRICE',
  BUY_PRICE = 'BUY_PRICE',
  TAX_TYPE = 'TAX_TYPE',
}

export enum VariantLocationFields {
  quantity = 'quantity',
  retailPrice = 'retailPrice',
  wholeSalePrice = 'wholeSalePrice',
  buyPrice = 'buyPrice',
  initialCost = 'initialCost',
  cost = 'cost',
  taxId = 'taxId',
}

export interface PackageAddedPayload {
  key: string;
  name: string;
  sku: string;
  barcode: string;
  packageSize: number;
  image: UploadedImage;
  sellable: boolean;
  purchaseable: boolean;
}
export interface PackageChangedPayload extends PackageAddedPayload { }
export interface PackageRemovedPayload {
  id: number;
}

export interface onPackageAddedPayload {
  key: string;
  name: string;
  sku: string;
  barcode: string;
  packageSize: string;
  image: {
    link: string;
  };
  sellable: boolean;
  purchaseable: boolean;
}
export interface onPackageRemovedPayload {
  key: string;
}
export interface AddPackagePayload {
  id: string;
  key: string;
  name: string;
  sku: string;
  barcode: string;
  packageSize: string;
  image: UploadedImage;
  sellable: boolean;
  purchaseable: boolean;
  canRemove?: boolean;
}
export interface RemovePackagePayload {
  key: string;
}
