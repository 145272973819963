import { Component } from '@angular/core';

// Services
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../../core/services/local-storage.service';

// Constants
import { LocalStorageKey } from '../../../../shared/constants';

@Component({
  selector: 'rw-preparing-account',
  templateUrl: './preparing-account.component.html',
})
export class PreparingAccountComponent {
  isShowSpinner = false;

  changeLang: string;

  lang: string;

  isShowInternalSpinner = false;

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }
  }
}