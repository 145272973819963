import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from 'src/app/inventory/model/product';
import { UploadFile } from 'src/app/shared/model/UploadFile';
import { ProductVariant } from '../../model/product-variant';
import { PaginationQuery } from '../../../shared/model/PaginationQuery';
import { INVOICE_TYPES } from '../../../shared/constants';
import { VariantToPackage } from '../../../shared/model/VariantToPackage';
import { StockCountOrderItem } from '../../../invoices/stock-count/v2/interfaces/stock-count.interface';

const API_URL = '/api';

@Injectable()
export class VariantService {
  constructor(private http: HttpClient) {}

  public getNextSKU(productSku, limit) {
    let params = new HttpParams();
    params = params.append('productSku', productSku);
    params = params.append('limit', limit);

    return this.http.get<string[]>(`${API_URL}/variants/nextSKUCode`, {
      params,
    });
  }

  getProductVariantBySKU(
    sku: string,
    isComposite: boolean = false,
  ): Observable<ProductVariant> {
    return this.http.get<ProductVariant>(
      `${API_URL}/variants/variant?sku=${sku}&isComposite=${isComposite}`,
    );
  }

  getVariantsAndStockLocationByIds(
    ids: number[],
  ): Observable<ProductVariant[]> {
    return this.http.post<ProductVariant[]>(
      `${API_URL}/variants/variant/multiple`,
      { variantIds: ids },
    );
  }

  getVariantsRetailPriceByIdsAndStockLocation(
    ids: number[],
    stockLocationId: number,
  ) {
    return this.http.post(
      `${API_URL}/variants/variant/retailPrices`,
      { variantIds: ids,stockLocationId:stockLocationId },
    );
  }


  validateSKUOrBarcode(sku): Observable<ProductVariant> {
    return this.http.get<ProductVariant>(`${API_URL}/variants/variant/${encodeURIComponent(sku)}`);
  }

  saveProductVariant(productVariant): Observable<ProductVariant> {
    return this.http.post<ProductVariant>(
      `${API_URL}/variants`,
      productVariant,
    );
  }

  searchVariants(
    pageQuery: PaginationQuery,
    invoiceType?: INVOICE_TYPES,
    isComposite?: boolean,
  ) {
    const { query, limit, offset } = pageQuery;
    let url = `${API_URL}/variants/search?query=${query}&limit=${limit}&offset=${offset}`;
    url = !invoiceType ? url : `${url}&invoiceType=${invoiceType}`;
    url = !isComposite ? url : `${url}&isComposite=${isComposite}`;
    return this.http.get<ProductVariant[]>(url);
  }

  addNewVariant(productVariant): Observable<Product> {
    return this.http.post<Product>(`${API_URL}/add-variants`, productVariant);
  }

  addNewPack(packVariant): Observable<Product> {
    return this.http.post<Product>(
      `${API_URL}/products/${packVariant.productId}/pack`,
      packVariant,
    );
  }

  editPack(packVariant): Observable<Product> {
    return this.http.put<Product>(
      `${API_URL}/products/${packVariant.productId}/pack/${packVariant.id}`,
      packVariant,
    );
  }

  deletePack(productId, id): Observable<any> {
    return this.http.delete<any>(`${API_URL}/products/${productId}/pack/${id}`);
  }

  getVariants(pageQuery: PaginationQuery, invoiceType?: INVOICE_TYPES) {
    const { query, limit, offset } = pageQuery;
    let url = `${API_URL}/variants/search?query=${query}&limit=${limit}&offset=${offset}`;
    url = !invoiceType ? url : `${url}&invoiceType=${invoiceType}`;
    return this.http.get<{ result: ProductVariant[]; total: number }>(url);
  }

  getVariantsForStockCountV2(pageQuery) {
    // const params = new URLSearchParams(pageQuery).toString();
    const url = `${API_URL}/variants/search/v2/stockcount_v2`;
    return this.http.post<{ result: ProductVariant[]; total: number }>(
      url,
      pageQuery,
    );
  }

  getVariantToPackage(packageVariantId): Observable<VariantToPackage> {
    return this.http.get<VariantToPackage>(
      `${API_URL}/variants/packages/${packageVariantId}`,
    );
  }

  searchVariantsForSpecificLocation(
    pageQuery: PaginationQuery,
    stockLocationId: number,
  ) {
    const { query, limit, offset } = {
      ...pageQuery,
      query: encodeURIComponent(pageQuery.query),
    };
    return this.http.get<ProductVariant[]>(
      `${API_URL}/pos/locations/${stockLocationId}/variants?query=${query}&limit=${limit}&offset=${offset}`,
    );
  }

  getVariantByScannerCode(
    code: string,
    stockLocationId: number,
  ): Observable<ProductVariant> {
    const encodedCode = encodeURIComponent(code);
    return this.http.get<ProductVariant>(
      `${API_URL}/pos/locations/${stockLocationId}/scanner/${encodedCode}`,
    );
  }

  getVariantByScannerCodeOnly(
    code: string,
    invoiceType,
    isComposite,
  ): Observable<ProductVariant> {
    const skuQueryParams = new HttpParams()
      .set('sku', code || '')
      .set('invoiceType', invoiceType || '')
      .set('isComposite', isComposite || '');
    return this.http.get<ProductVariant>(`${API_URL}/variants/variant`, {
      params: skuQueryParams,
    });
  }

  getActiveSkuCount(): Observable<number> {
    return this.http.get<number>(`${API_URL}/variants/get-active-sku-count`);
  }

  getVariantImages(variantId): Observable<UploadFile[]> {
    return this.http.get<UploadFile[]>(
      `${API_URL}/variants/${variantId}/images`,
    );
  }

  getVariantCountByScannerCode(code: String): Observable<ProductVariant> {
    return this.http.get<ProductVariant>(`${API_URL}/variants/scanner/${code}`);
  }

  getVariantPackages(variantId: number): Observable<VariantToPackage[]> {
    return this.http.get<VariantToPackage[]>(
      `${API_URL}/variants/${variantId}/packages`,
    );
  }

  getVariantByPack(packVariantId: number): Observable<VariantToPackage> {
    return this.http.get<VariantToPackage>(
      `${API_URL}/packages/${packVariantId}/variant`,
    );
  }

  getVariantsByPackageIds(
    ids: Array<number>,
    stockLocationId: number,
  ): Observable<Array<VariantToPackage>> {
    return this.http.get<Array<VariantToPackage>>(
      `${API_URL}/variants/packages?ids=${ids.join(',')}&&stockLocationId=${stockLocationId}`,
    );
  }

  getVariantByIdAndProductId(id: number): Observable<any> {
    return this.http.get<any>(`${API_URL}/products/variants/${id}`);
  }

  isValidTrack(
    productVariantId: number,
    stockLocationId: number,
    trackNo: string,
  ): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/variants/${productVariantId}/track/valid?stockLocationId=${stockLocationId}&trackNo=${trackNo}`,
    );
  }

  isValidEcard(
    productVariantId: number,
    code: string,
    isSold?: boolean,
  ): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/variants/${productVariantId}/ecard/valid?code=${code}${
        isSold === undefined ? '' : `&isSold=${isSold}`
      }`,
    );
  }
}
