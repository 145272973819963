export class Promotion {
  id:number;

  name:string;

  description:string;

  startDate: string;

  endDate: string;

  type: string;

  discountType: string;

  amount?: number;

  isAllProducts?: boolean;

  isAllLocations?: boolean;

  createdAt?: string;

  updatedAt?: string;

  deletedAt?: any;

  isChecked?: boolean = false;

  status?:any;

  PromotionStockLocations: any[];

  PromotionVariants: any[];

  constructor(promotion?) {
    Object.assign(this, promotion || {});
  }
}
