import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LinkNotificationSource } from '../../../enums/notification-source.enum';

@Injectable()
export class ProductLinkNotificationSourceManagerBehaviorService {
  private notificationSourceSubject =
    new BehaviorSubject<LinkNotificationSource>(null);

  public notificationSource$: Observable<LinkNotificationSource> =
    this.notificationSourceSubject.asObservable();

  setSource(channel: LinkNotificationSource): void {
    this.notificationSourceSubject.next(channel);
  }

  getSource(): LinkNotificationSource {
    return this.notificationSourceSubject.value;
  }
}
