import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ProductConfigurationState,
  productConfigurationStateKey,
} from '../reducers/product-configuration.reducer';

export const
  selectProductConfigState = createFeatureSelector<ProductConfigurationState>(
    productConfigurationStateKey,
  );

export const selectProductConfiguration = createSelector(
  selectProductConfigState,
  (state) => state.productConfiguration,
);

export const isProductConfigurationLoaded = createSelector(
  selectProductConfigState,
  (state) => !!state.productConfiguration,
);
