export const PlatformConstant = {
  DesktopWeb: 'DesktopWeb',
  AndroidWeb: 'AndroidWeb',
  iOSWeb: 'iOSWeb',
  iOSApp: 'iOSApp',
  AndroidApp: 'AndroidApp',
  Unknown: 'Unknown',
  IonicAndroidApp: 'IonicAndroidApp',
  IonicIosApp: 'IonicIosApp',
} as const;

export type Platform = (typeof PlatformConstant)[keyof typeof PlatformConstant];

export const PaymentTypeConstant = {
  Debit: 'Debit',
  Credit: 'Credit',
} as const;

export type PaymentType =
  (typeof PaymentTypeConstant)[keyof typeof PaymentTypeConstant];

export const PaymentSourceConstant = {
  ReceiveDebit: 'ReceiveDebit',
  SellInvoice: 'SellInvoice',
  ReturnInvoice: 'ReturnInvoice',
} as const;

export type PaymentSource =
  (typeof PaymentSourceConstant)[keyof typeof PaymentSourceConstant];

export const PaymentMethodConstant = {
  Cash: 'Cash',
  Card: 'Card',
  CustomerDebit: 'CustomerDebit',
  SoftPos: 'SoftPos',
} as const;

export type PaymentMethod =
  (typeof PaymentMethodConstant)[keyof typeof PaymentMethodConstant];

export const PaymentMethodNameConstant = {
  Cash: 'Cash',
  Card: 'Credit Card',
  RewaaPay: 'Rewaa Pay',
  PostPay: 'Post Pay',
} as const;

export type PaymentMethodName =
  (typeof PaymentMethodNameConstant)[keyof typeof PaymentMethodNameConstant];

export const PaymentMethodIdConstant = {
  Cash: 1,
  Card: 2,
  CustomerDebit: 5,
};

export type PaymentMethodIdConstant =
  (typeof PaymentMethodIdConstant)[keyof typeof PaymentMethodIdConstant];

export const InvoiceZatcaStatusConstant = {
  Pending: 'Pending',
  Completed: 'Completed',
  Rejected: 'Rejected',
  NotSubmitted: 'NotSubmitted',
} as const;

export type InvoiceZatcaStatus =
  (typeof InvoiceZatcaStatusConstant)[keyof typeof InvoiceZatcaStatusConstant];

export const InvoiceTypeConstant = {
  B2B: 'B2B',
  B2C: 'B2C',
} as const;

export type InvoiceType =
  (typeof InvoiceTypeConstant)[keyof typeof InvoiceTypeConstant];

export const InvoiceStatusConstant = {
  Pending: 'Pending',
  Completed: 'Completed',
  Rejected: 'Rejected',
} as const;

export type InvoiceStatus =
  (typeof InvoiceStatusConstant)[keyof typeof InvoiceStatusConstant];

export const PaymentStatusConstant = {
  Paid: 'Paid',
  Unpaid: 'Unpaid',
} as const;

export type PaymentStatus =
  (typeof PaymentStatusConstant)[keyof typeof PaymentStatusConstant];

export const InvoicePaymentStatusConstant = {
  Approved: 'Approved',
  Pending: 'Pending',
  Rejected: 'Rejected',
} as const;

export type InvoicePaymentStatus =
  (typeof InvoicePaymentStatusConstant)[keyof typeof InvoicePaymentStatusConstant];

export const enum InvoiceSellTaxStatus {
  Inclusive = 'Inclusive',
  Exclusive = 'Exclusive',
}

export const enum InvoiceSaleType {
  Retail = 'Retail',
  Wholesale = 'Wholesale',
}

export const enum InvoiceTransactionType {
  Sale = 'POSSale',
  Return = 'POSReturn',
}

export const enum InvoiceVariantType {
  Simple = 'simple',
  Variable = 'variable',
  Composite = 'composite',
  ECard = 'ecard',
  EProduct = 'eproduct',
}

export const PriceModeConstant = {
  Retail: 'Retail',
  Wholesale: 'Wholesale',
} as const;
export type PriceMode =
  (typeof PriceModeConstant)[keyof typeof PriceModeConstant];

export const InvoiceSnapshotVersionConstant = {
  One: 1,
  Two: 2,
  Three: 3,
  Current: 4,
} as const;
export type InvoiceSnapshotVersion =
  (typeof InvoiceSnapshotVersionConstant)[keyof typeof InvoiceSnapshotVersionConstant];

export const TransactionTypeConstant = {
  B2B: 'B2B',
  B2C: 'B2C',
} as const;
export type TransactionType =
  (typeof TransactionTypeConstant)[keyof typeof TransactionTypeConstant];

export const InvoiceFeeTypeConstant = {
  Shipping: 'Shipping',
  Other: 'Other',
} as const;

export type InvoiceFeeType =
  (typeof InvoiceFeeTypeConstant)[keyof typeof InvoiceFeeTypeConstant];

export const enum AutoCompleteKey {
  InvoiceNumber = 'invoiceNumber',
}
