/* eslint-disable check-file/folder-naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import {
  promotionService,
  PromotionTypes,
} from '@rewaa-team/pos-sdk';
import { OfflinePosTypes } from '@rewaa-team/pos-sdk';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';
import { OfflineProductVariant } from './stores/OfflineProductVariantStore';
import { ConfigStoreService } from './config-store.service';
import {
  ConfigStoreKeys,
  OFFLINE_MODE_FULL_TEXT_SEARCH_LIMIT,
} from '../../../constants';
import { Variant } from './stores/VariantStore';
import { AppState } from '../../../../reducers';
import { selectAllTaxes } from '../../../../users-settings/tax/selectors/tax.selector';
import { Tax } from '../../../model/Tax';
import { PromotionStoreService } from './promotion-store.service';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';
import { FeatureFlagService } from '../../types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../constants/feature-flag.constants';
import { PromotionStoreV2Service } from './promotion-store-v2.service';
import { CategoryService } from '../../../../inventory/products/services/category.service';
import { addParentsToCategory } from '../../../../internal-apps/pos/utils/sell.utils';
import { Category } from '../../../../inventory/model/category';
import { OnlineOfflineService } from '../online-offline.service';

@Injectable()
export class ProductVariantStoreService implements OnDestroy {
  private taxes: Tax[] = [];

  isAdvancePromotionEnable = false;

  destroySubs$ = new Subject();

  private stockLocationId?: number;

  private categories: Category[];

  isOffline = false;

  constructor(
    private configStoreService: ConfigStoreService,
    private promotionStoreService: PromotionStoreService,
    private store: Store<AppState>,
    private featureFlagService: FeatureFlagService,
    private promotionStoreV2Service: PromotionStoreV2Service,
    private categoryService: CategoryService,
    private onlineOfflineService: OnlineOfflineService
  ) {
    this.mapOfflineProductVariantToVariant = this.mapOfflineProductVariantToVariant.bind(this);
    this.featureFlagService
      .isEnabled(FeatureFlagEnum.PromotionRevamp, false)
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((res: boolean) => {
        this.isAdvancePromotionEnable = res;
      });

    this.categoryService.getCategories().subscribe((categories) => {
      categories.forEach((category) => {
        addParentsToCategory(categories, category, []);
      });
      this.categories = categories;
    });
    this.onlineOfflineService.networkChange
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((isOnline) => {
        this.isOffline = !isOnline;
      });
  }

  ngOnDestroy(): void {
    this.destroySubs$.next(null);
    this.destroySubs$.complete();
  }

  init(stockLocationId: number) {
    this.stockLocationId = stockLocationId;
    this.store
      .pipe(
        select(selectAllTaxes),
        tap((taxes) => {
          if (!taxes?.length) {
            return;
          }
          this.taxes = taxes;
        }),
      )
      .subscribe();
  }

  private async getDB(): Promise<any> {
    return DatabaseService.getDB();
  }

  public async save(variants: OfflineProductVariant[]): Promise<number> {
    const databaseService: DatabaseService = await this.getDB();
    const lastKey: number = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkPut(variants);
    return lastKey;
  }

  public async delete(ids: number[]) {
    const databaseService: DatabaseService = await this.getDB();
    await databaseService.getRepository(TableName.OfflineProductVariants).bulkDelete(ids);
  }

  public async deleteAll() {
    const databaseService: DatabaseService = await this.getDB();
    await Promise.all([
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.ProductApiConfig,
      ),
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.InventoryApiConfig,
      ),
      databaseService.getRepository(TableName.OfflineProductVariants).clear()
    ]);
  }

  public async search(term: string): Promise<Variant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variantsCount: number =
      await databaseService.getRepository(TableName.OfflineProductVariants).count();
    let variants: OfflinePosTypes.ProductVariant[] = [];
    //  We are applying limit of 1000 here because the substring search is too slow when data size is larger
    if (variantsCount <= OFFLINE_MODE_FULL_TEXT_SEARCH_LIMIT) {
      variants = await databaseService.getRepository(TableName.OfflineProductVariants).
      toArray();
      variants = variants.filter(
        (variant) =>
          variant.sku?.toLowerCase().includes(term.toLowerCase()) ||
          variant.name?.toLowerCase().includes(term.toLowerCase()) ||
          variant.barCode?.toLowerCase().includes(term.toLowerCase()) ||
          variant.product?.name?.toLowerCase().includes(term.toLowerCase()),
      ).slice(0, 20);
    } else {
      const query = databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('sku')
      .startsWithIgnoreCase(term)
      .or('barCode')
        .startsWithIgnoreCase(term);

      if (this.isOffline) {
        query
          .or('name')
          .startsWithIgnoreCase(term)
          .or('product.name')
          .startsWithIgnoreCase(term);
      }

      variants = await query.limit(40).toArray();
    }
    if (variants && variants.length) {
      const fullVariants = await this.loadFullVariants(variants);
      return fullVariants.map(this.mapOfflineProductVariantToVariant);
    }
    return [];
  }

  public async findById(id): Promise<Variant> {
    const databaseService: DatabaseService = await this.getDB();
    const variant: OfflinePosTypes.ProductVariant = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .get(id);
    const [fullVariant] = (await this.loadFullVariants([variant])) || [];
    return this.mapOfflineProductVariantToVariant(fullVariant);
  }

  public async findByProductId(productId: number): Promise<Variant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('productId')
      .equals(productId)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants.map(this.mapOfflineProductVariantToVariant);
  }

  public async findByProductIds(productIds: number[]): Promise<Variant[]> {
    const databaseService: DatabaseService = await this.getDB();
    let variants: OfflinePosTypes.ProductVariant[] = [];
    variants = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('productId')
      .anyOf(productIds)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants.map(this.mapOfflineProductVariantToVariant);
  }

  public async findBySkuOrBarcode(skuOrBarcode): Promise<Variant> {
    const databaseService: DatabaseService = await this.getDB();
    const results = await Promise.all([
      databaseService.getRepository(TableName.OfflineProductVariants)
        .where('sku')
        .equals(skuOrBarcode)
        .first(),
      databaseService.getRepository(TableName.OfflineProductVariants)
        .where('barCode')
        .equals(skuOrBarcode)
        .first(),
    ]);
    const variant =
      results.length > 1
        ? results[1] || results[0]
        : results.length > 0
        ? results[0]
        : undefined;
    const [fullVariant] = (await this.loadFullVariants([variant])) || [];
    return this.mapOfflineProductVariantToVariant(fullVariant);
  }

  async findByIds(ids: number[]): Promise<Variant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkGet(ids);
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants.map(this.mapOfflineProductVariantToVariant);
  }

  async findByIdsUnmapped(ids: number[]): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkGet(ids);
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants;
  }

  async findByIdsAfterDate(
    ids: number[],
    updatedAfterDate: Date,
  ): Promise<Variant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkGet(ids);
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants
      .filter((variant) => {
        const updatedAt = new Date(variant.updatedAt);
        return updatedAt > updatedAfterDate;
      })
      .map(this.mapOfflineProductVariantToVariant);
  }

  async findBySkus(skus: string[]): Promise<Variant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('sku')
      .anyOf(skus)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants.map(this.mapOfflineProductVariantToVariant);
  }

  async findByProductNames(names: string[]): Promise<Variant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService.getRepository(TableName.OfflineProductVariants)
      .where('Product.name')
      .anyOf(names)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants.map(this.mapOfflineProductVariantToVariant);
  }

  async updateInventory(inventory: OfflinePosTypes.InventoryApiResult, locationId: number): Promise<void> {
    const inventoryVariants = inventory.variants;
    const databaseService: DatabaseService = await this.getDB();
    const variantIds: number[] = Object.keys(inventoryVariants).map(id => +id);
    const variants: OfflinePosTypes.ProductVariant[] = (await databaseService.getRepository(TableName.OfflineProductVariants)
    .bulkGet(variantIds)).filter((variant) => !!variant);
    variants.forEach((variant) => {
      const inventoryVariant = inventoryVariants[variant.id];
      const stockLocation = variant.productVariantToStockLocations
      .find((stockLocation) => stockLocation.stockLocationId === locationId);
      if(stockLocation) {
        stockLocation.quantity = inventoryVariant.quantity;
      }
      if (inventoryVariant.tracks?.length) {
        stockLocation.variantToTracks = inventoryVariant.tracks.map((track) => {
          return {
            id: track.id,
            expiryDate: track.expiryDate,
            quantity: track.quantity,
            trackNo: track.trackNo,
            productVariantToStockLocationId: stockLocation.id,
            issueDate: null,
            supplierId: null
          }
        });
      }
      if(inventoryVariant.ecards) {
        variant.ecards = inventoryVariant.ecards.map((ecard) => {
          return {
            id: ecard.id,
            code: ecard.code,
            isSold: false,
            productVariantId: variant.id
          }
        });
      } else {
        variant.ecards = [];
      }
    });
    await this.save(variants);
  }

  private async loadFullVariants(
    variants: OfflinePosTypes.ProductVariant[],
  ): Promise<OfflinePosTypes.ProductVariant[]> {
    if (!variants.length) {
      return variants;
    }
    variants = variants.filter((variant) => variant);
    const childVariantIds = this.getChildVariantIds(variants);
    const variantIds: number[] = variants.map((variant) => variant.id);
    const categoryIds: number[] = Array.from(new Set(variants.flatMap(
      (variant: OfflinePosTypes.ProductVariant) => [
        variant.product.categoryId,
        ...(this.categories
          .find((c) => c.id === variant.product.categoryId)
          ?.parents?.map((c) => c.id) || []),
      ],
    ))).filter((id) => id);

    const variantPromos: any = this.isAdvancePromotionEnable
      ? await this.promotionStoreV2Service.findActive(variantIds, categoryIds)
      : await this.promotionStoreService.findActive(variantIds);
    let childVariants: OfflinePosTypes.ProductVariant[] = [];

    if (childVariantIds.length) {
      childVariants = await this.findByIdsUnmapped(childVariantIds);
    }

    for (const variant of variants) {
      // Map tax and taxLines to stockLocations
      variant.productVariantToStockLocations.forEach((stockLocation) => {
        const locationTax = this.taxes.find(
          (tax) => tax.id === stockLocation.taxId,
        );
        if (!locationTax) {
          return;
        }
        stockLocation.tax = {
          code: locationTax.code,
          id: locationTax.id,
          coumpoundTaxLineId: locationTax.coumpoundTaxLineId,
          name: locationTax.name,
          taxLines: (locationTax.TaxLines as OfflinePosTypes.TaxLine[]) || [],
          compoundTax: (locationTax.CoumpoundTax as OfflinePosTypes.TaxLine) || null,
          rate: locationTax.rate,
        };
      });

      // Map variant to extras
      for (const extraLocation of variant.variantExtraLocations) {
        const variantId = extraLocation.extra?.productVariantId;
        if (variantId) {
          if (variantId !== variant.id) {
            extraLocation.extra.productVariant = childVariants.find(
              (childVariant) => childVariant.id === variantId,
            );
          } else {
            extraLocation.extra.productVariant = JSON.parse(
              JSON.stringify({
                ...variant,
                variantExtraLocations: [],
              }),
            );
          }
        }
      }

      // Map variant to packages
      for (const packageVariant of variant.variantToPackages) {
        const variantId = packageVariant?.productVariantId;
        if (variantId !== variant.id) {
          packageVariant.productVariant = childVariants.find(
            (childVariant) => childVariant.id === variantId,
          );
        } else {
          packageVariant.productVariant = JSON.parse(
            JSON.stringify({
              ...variant,
              variantToPackages: [],
            }),
          );
        }
      }

      // Map variant to composites
      for (const compositeVariant of variant.product.variantToComposites) {
        const variantId = compositeVariant?.productVariantId;
        if (variantId !== variant.id) {
          compositeVariant.productVariant = childVariants.find(
            (childVariant) => childVariant.id === variantId,
          );
        } else {
          compositeVariant.productVariant = JSON.parse(JSON.stringify(variant));
        }
      }

      if (this.isAdvancePromotionEnable) {
        const variantCategoryIds = [
          variant.product.categoryId,
          ...(this.categories
            .find((c) => c.id === variant.product.categoryId)
            ?.parents.map((c) => c.id) || []),
        ].filter((id) => id);
        promotionService.mapPromotionToVariant(
          variantCategoryIds,
          variant,
          variantPromos as PromotionTypes.ActiveAdvancePromotion,
        );
      } else {
        promotionService.mapSimplePromotionToVariant(
          variant,
          variantPromos as PromotionTypes.ActiveSimplePromotion,
        );
      }
    }
    return variants;
  }

  /**
   * Maps Camel case properties to Pascal case
   * since that is being used on the frontend entirely
   */
  private mapOfflineProductVariantToVariant(
    offlineVariant: OfflineProductVariant,
  ): Variant {
    if (!offlineVariant) {
      return;
    }
    const variant: Variant = {} as any;
    Object.assign(variant, offlineVariant);
    variant.ProductVariantToStockLocations =
      offlineVariant.productVariantToStockLocations.map((stockLocation) => {
        return {
          ...stockLocation,
          Tax: {
            ...stockLocation.tax,
            TaxLines: stockLocation.tax?.taxLines,
            CoumpoundTax: stockLocation.tax?.compoundTax,
            rate: stockLocation.tax?.rate,
          },
          VariantToTracks: stockLocation.variantToTracks,
        } as any;
      });
    variant.VariantExtraLocations = offlineVariant.variantExtraLocations.map(
      (extraLocation) => {
        return {
          ...extraLocation,
          Extra: {
            ...extraLocation.extra,
            ProductVariant: this.mapOfflineProductVariantToVariant(extraLocation.extra?.productVariant),
          },
        };
      },
    );
    variant.VariantToPackages = offlineVariant.variantToPackages.map(
      (packageVariant) => {
        return {
          ...packageVariant,
          ProductVariant: this.mapOfflineProductVariantToVariant(packageVariant.productVariant)
        }
      }
    );
    variant.Ecards = offlineVariant.ecards;
    variant.Product = {
      ...offlineVariant.product,
      Files: (offlineVariant.product as any).files,
    };
    variant.Product.VariantToComposites = offlineVariant.product.variantToComposites.map(
      (compositeVariant) => {
        return {
          ...compositeVariant,
          ProductVariant: this.mapOfflineProductVariantToVariant(compositeVariant.productVariant)
        }
      }
    );

    if (!offlineVariant.promotion) return variant;

    if (!this.isAdvancePromotionEnable) {
      variant.promotion =
        this.promotionStoreService.mapOfflinePromotionToPromotion(
          offlineVariant.promotion,
          this.stockLocationId,
        );
    }
    return variant;
  }

  private getChildVariantIds(variants: OfflinePosTypes.ProductVariant[]): number[] {
    const ids: number[] = [];
    variants.forEach((variant) => {
      let childId: number;
      variant.variantExtraLocations.forEach((extraLocation) => {
        childId = extraLocation.extra?.productVariantId;
        if(childId && childId !== variant.id) {
          ids.push(extraLocation.extra.productVariantId);
        }
      });

      variant.variantToPackages.forEach((variantToPackage) => {
        childId = variantToPackage.productVariantId;
        if(childId && childId !== variant.id) {
          ids.push(variantToPackage.productVariantId);
        }
      });

      variant.product.variantToComposites.forEach((variantToComposite) => {
        childId = variantToComposite?.productVariantId;
        if(childId && childId !== variant.id) {
          ids.push(variantToComposite.productVariantId);
        }
      })
    });
    return Array.from(new Set(ids));
  }
}
