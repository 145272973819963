import { Injectable } from '@angular/core';
import { Config } from './stores/ConfigStore';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';

@Injectable()
export class ConfigStoreService {
  async setConfigration(key: string, value: any) {
    const db = await DatabaseService.getDB();
    return db.getRepository(TableName.Config).put({ key, value });
  }

  async getConfigration(key: string) {
    const db = await DatabaseService.getDB();
    const config: Config = await db
      .getRepository(TableName.Config)
      .where('key')
      .equals(key)
      .first();
    return config ? config.value : null;
  }

  async deleteConfigration(key: string) {
    const db = await DatabaseService.getDB();
    await db
      .getRepository(TableName.Config)
      .where('key')
      .equals(key)
      .delete();
  }
}
