import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LAYOUT_CATEGORY_CONFIG_PARAMS } from 'src/app/shared/constants';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { getDeepClone } from '../utils/common.utils';
import { LayoutCategory } from '../model/Layout';

@Injectable()
export class LayoutCategoryService {
  categoriesDataSource$: BehaviorSubject<Array<LayoutCategory>> = new BehaviorSubject([]);

  allCategory = this.categoriesDataSource$.asObservable();

  categoryList;

  selectedCategories = [];

  getSelectCategories() {
    const categoryData = [];

    let categories = this.categoriesDataSource$.value;

    for (let i = 0; i < this.selectedCategories.length; i++) {
      let foundCategory;
      if (!this.selectedCategories[i].id) {
        const isUncategorized = this.selectedCategories[i].name
         === LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name;
        foundCategory = isUncategorized
          ? categories.find((cate) => cate.name
             === LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name)
          : categories.find((cate) => cate.name
           !== LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name);
      } else {
        foundCategory = categories.find((cate) => cate.id === this.selectedCategories[i].id);
      }

      if (!foundCategory) break;

      categoryData.push(foundCategory);

      if (foundCategory.children) categories = foundCategory.children;
    }

    return categoryData;
  }

  get currentCategory() {
    return this.selectedCategories[this.selectedCategories.length - 1];
  }

  set setCurrentCategory(category) {
    this.selectedCategories[this.selectedCategories.length - 1] = category;
  }

  constructor(private store: Store<AppState>) {
    this.allCategory.subscribe((data) => {
      this.categoryList = data;
    });
  }

  updateCategoryData(categories) {
    this.categoriesDataSource$.next(getDeepClone(categories));
  }

  addDataToCategoryLevel(data) {
    this.selectedCategories.push(data);
  }

  removeLastCategoryLevel(type = 'pop') {
    if (type === 'all') {
      this.selectedCategories = [];
    } else {
      this.selectedCategories.pop();
    }
  }

  getCurrentSelectedCategories() {
    return this.selectedCategories;
  }

  getCategoryParents(categoryState, category, parents = []) {
    for (let i = 0; i < categoryState.length; i++) {
      const isFound = categoryState[i].id === category.parentId;
      if (isFound) {
        parents.push(categoryState[i]);
        break;
      }

      if (categoryState[i].children && categoryState[i].children.length > 0) {
        const foundChildren = this.getCategoryParents(categoryState[i].children, category, parents);

        if (foundChildren.length > 0) parents.push(categoryState[i]);
        parents = [...parents, ...foundChildren];
      }
    }

    return parents;
  }

  calculateCategoryData(state, payload, categories, i = 0) {
    let index = -1;
    if (!categories[i].id) {
      const isUncategorized = categories[i].name
       === LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name;
      index = isUncategorized
        ? state.findIndex((c) => c.name === LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name)
        : state.findIndex((c) => c.name !== LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name);
    } else {
      index = state.findIndex((c) => c.id === categories[i].id);
    }
    if (i === categories.length - 1) {
      if (index >= 0) state[index] = this.getUpdatedCategoryData(state[index], payload);

      return state;
    } if (index >= 0) {
      state[index] = {
        ...state[index],
        children: this.calculateCategoryData(state[index].children
           || [], payload, categories, i + 1),
      };
    }

    return state;
  }

  assignCategory(category) {
    if (category.parentId) {
      const parentCategories = this.getCategoryParents(this.categoriesDataSource$.value, category);

      if (parentCategories && parentCategories[parentCategories.length - 1].dataFetched) {
        const updatedCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
          type: 'addCategory', data: category,
        }, parentCategories);
        this.updateCategoryData(updatedCategories);
      }
    } else {
      const updatedCategories = [...this.categoriesDataSource$.value,
        { ...category, order: this.categoriesDataSource$.value.length + 1 }];
      this.updateCategoryData(updatedCategories);
    }
  }

  deleteCategory(category) {
    let updatedCategories;
    let index = -1;
    if (!category.id) {
      const isUnCategorized = category.name === LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name;
      index = isUnCategorized ? this.categoriesDataSource$.value.findIndex((c: any) => c.name
       === LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name)
        : this.categoriesDataSource$.value.findIndex((c: any) => c.name
         !== LAYOUT_CATEGORY_CONFIG_PARAMS.UNCATEGORIZED.name);
      this.categoriesDataSource$.value.splice(index, 1);
      updatedCategories = this.categoriesDataSource$.value;
    } else {
      index = this.categoriesDataSource$.value.findIndex((c) => c.id === category.id);
    }
    if (index < 0) {
      updatedCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
        type: 'deleteCategory', data: category,
      }, this.getSelectCategories());
    } else {
      if (category.id) {
        updatedCategories = this.categoriesDataSource$.value.filter((c) => c.id !== category.id);
      }
      updatedCategories = this.updateListOrder(updatedCategories, index);
    }
    this.updateCategoryData(updatedCategories);
  }

  addProduct(product: any, selectedCategory?: any) {
    const updatedCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
      type: 'addProduct', data: product,
    }, selectedCategory || this.getSelectCategories());
    this.updateCategoryData(updatedCategories);
  }

  deleteProduct(id: number, selectedCategory?: any) {
    const updatedCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
      type: 'deleteProduct', data: { id },
    }, selectedCategory || this.getSelectCategories());

    this.updateCategoryData(updatedCategories);
  }

  addVariants(variants) {
    const updatedCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
      type: 'addVariants', data: variants,
    }, this.getSelectCategories());
    this.updateCategoryData(updatedCategories);
  }

  deleteVariant(data) {
    const updatedCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
      type: 'deleteVariant', data,
    }, this.getSelectCategories());
    this.updateCategoryData(updatedCategories);
  }

  getUpdatedCategoryData(state: any, payload: any) {
    const { type = '', data = null } = payload || {};
    let index;

    switch (type) {
      case 'addCategory':
        state.children.push({ ...data, order: data.order || state.children.length + 1 });
        break;
      case 'deleteCategory':
        index = state.children.findIndex((c) => c.id === data.id);
        state.children = state.children.filter((c) => c.id !== data.id);
        state.children = this.updateListOrder(state.children, index);
        break;
      case 'addProduct':
        if (!state.products.find((p) => p.id === data.id)) {
          state.products.push(data);
          state.excludedProducts = state.excludedProducts.filter((p) => p !== data.id);
        }
        break;
      case 'deleteProduct':
        index = state.products.findIndex((c) => c.id === data.id);
        state.products = state.products.filter((s) => s.id !== data.id);
        state.products = this.updateListOrder(state.products, index);
        state.excludedProducts.push(data.id);
        break;
      case 'addProducts':
        state.products = (state.products || []).concat(data);
        state.dataFetched = true;
        break;
      case 'updateCategory':
        state = {
          ...state, ...data,
        };
        break;
      case 'addVariants':
        index = state.products.findIndex((c) => c.id === data[0].productId);
        state.products[index].variants = data;
        state.excludedVariants = state.excludedVariants
          .filter((p) => !state.products[index].variants.find((v) => v.sku === p));
        break;
      case 'deleteVariant':
        index = state.products.findIndex((c) => c.id === data.productId);
        const indx = state.products[index].variants.findIndex((v) => v.sku === data.sku);
        // if variant have packages delete packages
        if (data.VariantToPackages && data.VariantToPackages.length > 0) {
          const packagesToBeDeleted = data.VariantToPackages && data.VariantToPackages.length > 0
            ? data.VariantToPackages.map((m) => m.packageVariantId) : [];

          const packVariantToBeDeleted = state.products[index].variants
            .filter((v) => packagesToBeDeleted.includes(v.id));

          state.products[index].variants = state.products[index].variants
            .filter((v) => !packagesToBeDeleted.includes(v.id));
          state.excludedVariants = [...state.excludedVariants, ...packVariantToBeDeleted
            .map((p) => p.sku)];
        }
        // delete variant

        const deletedVariant = state.products[index].variants[indx];
        state.products[index].variants = state.products[index].variants
          .filter((v) => v.sku !== data.sku);
        state.products[index].variants = this
          .updateVariantListOrder(state.products[index].variants, indx, deletedVariant);
        state.excludedVariants.push(data.sku);
        break;
      default:
        break;
    }

    return state;
  }

  updateListOrder = (data: Array<any>, index: number = 0) => {
    for (let i = index; i < data.length; i++) {
      data[i].order = i + 1;
    }
    return data;
  };

  updateCategoryProducts(products: Array<any>) {
    const updateCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
      type: 'addProducts', data: products,
    }, this.getSelectCategories());

    this.updateCategoryData(updateCategories);
  }

  updateCategoryOrder(category, prop) {
    this.updateCategory({
      ...category,
      [prop]: this.updateListOrder(category[prop]),
    });
  }

  updateCategory(category) {
    const updateCategories = this.calculateCategoryData(this.categoriesDataSource$.value, {
      type: 'updateCategory', data: category,
    }, this.getSelectCategories());

    this.updateCategoryData(updateCategories);
  }

  updateVariantListOrder = (variants: Array<any>, index: number, deletedVariant: any) => {
    let { order } = deletedVariant;
    for (let i = index; i < variants.length; i++) {
      if (variants[i].type === deletedVariant.type) {
        variants[i].order = order;
        order += 1;
      }
    }
    return variants;
  };

  productExistsInQuickMenu(productId: number) {
    const { products } = this.categoriesDataSource$.value.find((data) => data.isQuickMenu);
    return !!products.find((p) => p.id === productId);
  }
}
