import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';
import { EmailOptions } from '../types/email-service-types';

export class EmailService {
  private options: EmailOptions;
  private sesClient: SESClient;

  constructor(options: EmailOptions) {
    this.options = options;
    this.sesClient = new SESClient({ region: this.options.region });
  }

  private createSendEmailCommand = (
    toAddress: Array<string>,
    emailText: string,
    emailSubject: string,
    ccAddress?: Array<string>,
  ) => {
    return new SendEmailCommand({
      Destination: {
        CcAddresses: ccAddress ?? [],
        ToAddresses: toAddress,
      },
      Message: {
        Body: {
          Text: {
            Charset: 'UTF-8',
            Data: emailText,
          },
        },
        Subject: {
          Charset: 'UTF-8',
          Data: emailSubject,
        },
      },
      Source: this.options.sourceEmail,
    });
  };

  async sendEmail(
    toAddress: Array<string>,
    emailText: string,
    emailSubject: string,
    ccAddress?: Array<string>,
  ): Promise<any> {
    const sendEmailCommand = this.createSendEmailCommand(
      toAddress,
      emailText,
      emailSubject,
      ccAddress,
    );

    try {
      return await this.sesClient.send(sendEmailCommand);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Email Service: Error : ${e}`);
    }
  }
}
