/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  EMPTY, Subject, Subscription, timer,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  expand, finalize, mergeMap, takeUntil, tap,
} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Product } from 'src/app/inventory/model/product';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { VariantOption } from 'src/app/inventory/model/variant-option';
import { OfflinePosTypes, OfflinePosEnums } from '@rewaa-team/pos-sdk';
import { posInvoiceCalculationService } from '@rewaa-team/rewaa-common';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy'
import {
  ConfigStoreKeys,
  InventoryApiConfig,
  InventoryApiDefaultInterval,
  ProductApiConfig,
  PromotionApiConfig,
  PromotionV2ApiConfig,
} from '../../constants';
import { OfflineProductService } from './offline-product.service';
import { ConfigStoreService } from './indexedDB/config-store.service';
import { ITaxConfiguration } from '../../model/TaxConfiguration';
import { AppState } from '../../../reducers';
import { getUserPriceConfig } from '../../../users-settings/selectors/price-configuration.selector';
import {
  getSkuFromScannerCode,
  playErrorNotificationAudio,
} from '../../../internal-apps/pos/utils/sell.utils';
import { WeightedProductConfiguration } from '../../model/WeightedProductConfiguration';
import {
  adjustOfflineVariantData,
} from '../../utility/offline/offline-invoice.util';
import { ProductVariantStoreService } from './indexedDB/product-variant-store.service';
import { NotificationsService } from '../../../firebase-notifications/notifications.service';
import {
  NotificationSource,
  NotificationType,
} from '../../../firebase-notifications/enums';
import { FeatureFlagService } from '../types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../constants/feature-flag.constants';
import { CustomToastService } from '../custom-toast.service';
import { PromotionStoreService } from './indexedDB/promotion-store.service';
import { OfflineFirstService } from './offline-first.service';
import { CompressorService } from '../compressor.service';
import { PromotionStoreV2Service } from './indexedDB/promotion-store-v2.service';
import { PromotionTypes } from '@rewaa-team/pos-sdk';
import { PromotionChangeSetV2 } from '../types/types';
import { PriceConfigService } from '../price-config.service';
@Injectable()
export class VariantCacheService implements OnDestroy{
  private variantSubscription: Subscription;

  private priceConfiguration: ITaxConfiguration;

  private inventorySyncInterval: Subscription;

  private inventoryApiInterval = InventoryApiDefaultInterval;

  private locationId?: number;

  private downloadingVariantsFile: boolean = false;

  private destroySubs$ = new Subject();

  constructor(
    private configStoreService: ConfigStoreService,
    private offlineProductService: OfflineProductService,
    private store: Store<AppState>,
    private productVariantStoreService: ProductVariantStoreService,
    private promotionStoreService: PromotionStoreService,
    private promotionStoreV2Service: PromotionStoreV2Service,
    private notificationService: NotificationsService,
    private featureFlagService: FeatureFlagService,
    private toastMessage: CustomToastService,
    private offlineFirstService: OfflineFirstService,
    private compressorService: CompressorService,
    private priceConfigService: PriceConfigService
  ) {
    this.priceConfigService.getCurrentConfigPrice$
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((config) => {
        this.priceConfiguration = config;
      });
    this.notificationService
      .getNotifications({
        type: NotificationType.ProductChanges,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .subscribe((notifications) => {
        if (notifications.length && this.locationId) {
          this.loadProductVariants();
        }
      });
    this.notificationService
      .getNotifications({
        type: NotificationType.PromotionChanges,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .subscribe(async (notifications) => {
        if (notifications.length && this.locationId) {
          await this.loadPromotionsV2();
          await this.loadPromotions();
        }
      });

    this.notificationService
      .getNotifications({
        type: NotificationType.PromotionChanges,
        silent: true,
        source: NotificationSource.Marketing,
        fromDate: new Date(),
        onlyNew: true,
      })
      .subscribe(async (notifications) => {
        if (notifications.length && this.locationId) {
          await this.loadPromotionsV2();
          await this.loadPromotions();
        }
      });

    this.notificationService
      .getNotifications({
        type: NotificationType.ProductVariantFile,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .subscribe(async (notifications) => {
        if (notifications.length && this.locationId) {
          const data: OfflinePosTypes.VariantFileData = notifications[0].m.d;
          if(this.locationId !== data.stockLocationId) {
            // On a different location
            return;
          }
          this.downloadAndProcessVariantsFile(data);
        }
      });

    this.featureFlagService
      .variation(
        FeatureFlagEnum.OfflineInventorySyncInterval,
        InventoryApiDefaultInterval,
      )
      .pipe(
        tap((value) => {
          this.inventoryApiInterval = value;
          if (this.locationId && this.inventorySyncInterval) {
            this.startInventorySync();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroySubs$.next(null);
    this.destroySubs$.complete();
  }

  async loadOfflineData(stockLocationId: number): Promise<void> {
    this.locationId = stockLocationId;
    await Promise.allSettled([
      this.loadProductVariants(),
      this.loadPromotionsV2(),
      this.loadPromotions(),
    ]);
  }

  async downloadAndProcessVariantsFile(input: OfflinePosTypes.VariantFileData) {
    if (this.downloadingVariantsFile) {
      return;
    }
    this.downloadingVariantsFile = true;
    this.offlineFirstService.setProductsLoaded(false);
    this.offlineProductService
      .downloadVariantsFile(input.url)
      .pipe(
        tap(async (res) => {
          const variants = await this.compressorService.unCompressGzipToJSON(
            new Uint8Array(res),
          );
          const productApiConfig: ProductApiConfig = {
            updatedAt: input.updatedAt,
            locationId: this.locationId,
          };
          const promises = [
            this.configStoreService.setConfigration(
              ConfigStoreKeys.ProductApiConfig,
              JSON.stringify(productApiConfig),
            ),
            this.productVariantStoreService.save(
              variants as OfflinePosTypes.ProductVariant[],
            ),
            this.configStoreService.setConfigration(
              ConfigStoreKeys.InventoryApiConfig,
              JSON.stringify(productApiConfig),
            ),
          ];
          await Promise.all(promises);
          this.downloadingVariantsFile = false;
          this.loadVariantsChangeSet(productApiConfig);
        }),
        finalize(() => {
          this.downloadingVariantsFile = false;
        }),
      )
      .subscribe();
  }

  /**
   * TODO: Deprecate once Advanced Promotions is 100% Live
   */
  async loadPromotions() {
    const promotionApiConfig = await this.configStoreService.getConfigration(
      ConfigStoreKeys.PromotionApiConfig,
    );
    if (promotionApiConfig) {
      const config: PromotionApiConfig = JSON.parse(promotionApiConfig);
      if (config.locationId && config.locationId !== this.locationId) {
        this.reloadPromotions();
        return;
      }
      config.locationId = this.locationId;
      if (config.updatedAt) {
        this.loadPromotionChangeSet(config);
      } else {
        this.loadInitialPromotions();
      }
    } else {
      this.loadInitialPromotions();
    }
  }

  async loadPromotionsV2(): Promise<void> {
    const promotionV2ApiConfig = await this.configStoreService.getConfigration(
      ConfigStoreKeys.PromotionV2ApiConfig,
    );
    if (!promotionV2ApiConfig) {
      this.loadInitialPromotionsV2();
      return;
    }
    const config: PromotionV2ApiConfig = JSON.parse(promotionV2ApiConfig);
    if (config.locationId && config.locationId !== this.locationId) {
      this.reloadPromotionsV2();
      return;
    }
    config.locationId = this.locationId;
    if (config.updatedAt) {
      this.loadPromotionChangeSetV2(config);
      return;
    }
    this.loadInitialPromotionsV2();
  }

  async reloadPromotions() {
    this.offlineFirstService.setPromotionsLoaded(false);
    await this.promotionStoreService.deleteAll();
    if (this.locationId) {
      await this.loadPromotions();
    }
  }

  async reloadPromotionsV2(): Promise<void> {
    this.offlineFirstService.setPromotionsLoaded(false);
    await this.promotionStoreV2Service.deleteAll();
    if (this.locationId) {
      await this.loadPromotionsV2();
    }
  }

  async loadInitialPromotions() {
    const startDate = new Date().toISOString();
    this.offlineProductService
      .getOfflinePromotionChangeSet(this.locationId)
      .pipe(
        expand((response) => {
          if (response.nextToken) {
            return this.offlineProductService.getOfflinePromotionChangeSet(
              this.locationId,
              null,
              response.nextToken,
            );
          }
          this.offlineFirstService.setPromotionsLoaded(true);
          return EMPTY;
        }),
        tap(async (response) => {
          await Promise.all([
            this.configStoreService.setConfigration(
              ConfigStoreKeys.PromotionApiConfig,
              JSON.stringify({
                updatedAt: response.nextToken ? null : startDate,
                nextToken: response.nextToken,
                locationId: this.locationId,
              }),
            ),
            this.promotionStoreService.save(
              response.data.map((payload) => payload.promotion),
            ),
          ]);
        }),
      )
      .subscribe();
  }

  async loadInitialPromotionsV2(): Promise<void> {
    const startDate = new Date().toISOString();
    this.offlineProductService
      .getOfflinePromotionChangeSetV2(this.locationId)
      .pipe(
        tap(async (response) => {
          this.offlineFirstService.setPromotionsLoaded(true);
          const promotionsToAdd = response
            .filter((item) =>
              [
                PromotionTypes.PromotionChangeType.Updated,
                PromotionTypes.PromotionChangeType.Created,
              ].includes(item.actionType),
            )
            .map((item) => item.promotion);

          const promotionsToDelete = response
            .filter(
              (item) =>
                item.actionType === PromotionTypes.PromotionChangeType.Deleted,
            )
            .map((item) => item.promotion?.id);
          const configUpdate: PromotionV2ApiConfig = {
            locationId: this.locationId,
            updatedAt: startDate,
          };
          if (response.length === 0) {
            delete configUpdate.updatedAt;
          }
          await Promise.all([
            this.configStoreService.setConfigration(
              ConfigStoreKeys.PromotionV2ApiConfig,
              JSON.stringify(configUpdate),
            ),
            this.promotionStoreV2Service.save(promotionsToAdd),
            this.promotionStoreV2Service.delete(promotionsToDelete),
          ]);
        }),
      )
      .subscribe();
  }

  async loadPromotionChangeSet(config: PromotionApiConfig) {
    const startDate = new Date().toISOString();
    this.offlineProductService
      .getOfflinePromotionChangeSet(
        this.locationId,
        config.updatedAt,
        config.nextToken,
      )
      .pipe(
        expand((response) => {
          if (response.nextToken) {
            return this.offlineProductService.getOfflinePromotionChangeSet(
              this.locationId,
              config.updatedAt,
              response.nextToken,
            );
          }
          this.promotionStoreService.deleteStale();
          this.offlineFirstService.setPromotionsLoaded(true);
          return EMPTY;
        }),
        tap(async (response) => {
          const promotionsToAdd = response.data
            .filter((item) =>
              [
                OfflinePosEnums.PromotionChangeTypeConstant.Updated,
                OfflinePosEnums.PromotionChangeTypeConstant.Created,
              ].includes(item.actionType),
            )
            .map((item) => item.promotion);
          const promotionsToDelete = response.data
            .filter(
              (item) =>
                item.actionType === OfflinePosEnums.PromotionChangeTypeConstant.Deleted,
            )
            .map((item) => item.promotion.id);
          await Promise.all([
            this.configStoreService.setConfigration(
              ConfigStoreKeys.PromotionApiConfig,
              JSON.stringify({
                updatedAt: response.nextToken ? null : startDate,
                nextToken: response.nextToken,
                locationId: this.locationId,
              }),
            ),
            this.promotionStoreService.save(promotionsToAdd),
            this.promotionStoreService.delete(promotionsToDelete),
          ]);
        }),
      )
      .subscribe();
  }

  async loadPromotionChangeSetV2(config: PromotionV2ApiConfig): Promise<void> {
    const startDate = new Date().toISOString();
    this.offlineProductService
      .getOfflinePromotionChangeSetV2(
        this.locationId,
        new Date(config.updatedAt),
      )
      .pipe(
        tap(async (response: PromotionChangeSetV2[]) => {
          this.offlineFirstService.setPromotionsLoaded(true);
          const promotionsToAdd = response
            .filter((item: PromotionChangeSetV2) =>
              [
                PromotionTypes.PromotionChangeType.Updated,
                PromotionTypes.PromotionChangeType.Created,
              ].includes(item.actionType),
            )
            .map((item) => item.promotion);

          const promotionsToDelete = response
            .filter(
              (item: PromotionChangeSetV2) =>
                item.actionType === PromotionTypes.PromotionChangeType.Deleted,
            )
            .map((item: PromotionChangeSetV2) => item.promotion?.id);
          const configUpdate: PromotionV2ApiConfig = {
            locationId: this.locationId,
            updatedAt: startDate,
          };
          if (response.length === 0) {
            configUpdate.updatedAt = config.updatedAt;
          }
          await Promise.allSettled([
            this.configStoreService.setConfigration(
              ConfigStoreKeys.PromotionV2ApiConfig,
              JSON.stringify(configUpdate),
            ),
            this.promotionStoreV2Service.deleteStale(),
            this.promotionStoreV2Service.save(promotionsToAdd),
            this.promotionStoreV2Service.delete(promotionsToDelete),
          ]);
        }),
      )
      .subscribe();
  }

  async reloadProductVariants() {
    this.offlineFirstService.setProductsLoaded(false);
    await Promise.all([
      this.deleteAllVariants(),
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.ProductApiConfig,
      ),
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.InventoryApiConfig,
      ),
    ]);
    if (this.locationId) {
      await this.loadProductVariants();
    }
  }

  async loadProductVariants() {
    const productApiConfig = await this.configStoreService.getConfigration(
      ConfigStoreKeys.ProductApiConfig,
    );

    if (productApiConfig) {
      const config: ProductApiConfig = JSON.parse(productApiConfig);
      if (config.locationId && config.locationId !== this.locationId) {
        await this.reloadProductVariants();
        return;
      }
      config.locationId = this.locationId;
      if (config.updatedAt) {
        this.loadVariantsChangeSet(config);
      } else {
        this.loadInitialVariants();
      }
    } else {
      this.loadInitialVariants();
    }
  }

  private loadInitialVariants() {
    this.offlineProductService
      .triggerVariantFileCreation(this.locationId)
      .subscribe();
  }

  private loadVariantsChangeSet(config: ProductApiConfig) {
    if(this.downloadingVariantsFile) {
      /**
       * Change-set triggered from notification while
       * file was being downloaded
       **/
      return;
    }
    this.offlineProductService
      .getOfflineProductVariantsChangeSet(
        config.locationId,
        new Date(config.updatedAt).toISOString(),
      )
      .pipe(
        tap(async (response) => {
          if(response.creatingFile) {
            /**
             * Will receive a new file so no need to update
             */
            return;
          }
          const variantsToDelete = response.data
            .filter(
              (item) =>
                item.actionType === OfflinePosEnums.ProductChangeTypeConstant.Deleted,
            )
            .map((item) => item.variant.id);
          const variantsToAdd = response.data
            .filter((item) =>
            item.actionType === OfflinePosEnums.ProductChangeTypeConstant.Updated || OfflinePosEnums.ProductChangeTypeConstant.Created
            )
            .map((item) => item.variant);
          await Promise.all([
            this.configStoreService.setConfigration(
              ConfigStoreKeys.ProductApiConfig,
              JSON.stringify({
                updatedAt: response.updatedAt || new Date().toISOString(),
                locationId: config.locationId,
              }),
            ),
            this.productVariantStoreService.save(variantsToAdd),
            this.productVariantStoreService.delete(variantsToDelete),
          ]);
          if(!this.inventorySyncInterval) {
            this.startInventorySync();
          }
          this.offlineFirstService.setProductsLoaded(true);
        }),
      )
      .subscribe();
  }

  startInventorySync() {
    this.stopInventorySync();
    this.inventorySyncInterval = timer(0, this.inventoryApiInterval)
      .pipe(mergeMap(() => this.syncInventory()))
      .subscribe();
  }

  stopInventorySync() {
    if (this.inventorySyncInterval) {
      this.inventorySyncInterval.unsubscribe();
      this.inventorySyncInterval = null;
    }
  }

  private async syncInventory() {
    const inventoryApiConfig = await this.configStoreService.getConfigration(
      ConfigStoreKeys.InventoryApiConfig,
    );
    if (!inventoryApiConfig) {
      return;
    }
    const config: InventoryApiConfig = JSON.parse(inventoryApiConfig);
    const updatedAt = new Date(config.updatedAt).toISOString();
    const locationId = this.locationId || config.locationId;
    if (!locationId) {
      console.error(`Inventory Sync location not defined`);
      return;
    }
    return this.offlineProductService
      .getInventory(locationId, updatedAt, null)
      .pipe(
        expand((response) => {
          if (response.nextToken) {
            return this.offlineProductService.getInventory(
              locationId,
              updatedAt,
              response.nextToken,
            );
          }
          return EMPTY;
        }),
        tap(async (response) => {
          if (!response.nextToken) {
            await this.configStoreService.setConfigration(
              ConfigStoreKeys.InventoryApiConfig,
              JSON.stringify({
                updatedAt: response.updatedAt,
                locationId,
              }),
            );
          }
          await this.productVariantStoreService.updateInventory(
            response,
            locationId,
          );
        }),
      )
      .subscribe();
  }

  destoryVariantSubscription(): void {
    if (this.variantSubscription) {
      this.variantSubscription.unsubscribe();
    }
  }

  async searchVariants(
    stockLocationId?: number,
    term?: string,
    skus?: Array<string>,
    priceConfiguration?: any,
  ): Promise<any> {
    try {
      let searchResult;
      if (skus && skus.length > 0) {
        searchResult = await this.productVariantStoreService.findBySkus(skus);
      } else {
        searchResult = await this.productVariantStoreService.search(term);
      }

      this.priceConfiguration = priceConfiguration || this.priceConfiguration;

      // TODO move the below lines to be calculated during the insertion of variants in the indexeddb
      searchResult.forEach((r: any) => {
        adjustOfflineVariantData(r, this.priceConfiguration, stockLocationId);
      });

      return searchResult;
    } catch (err) {
      console.log(err);
    }
  }

  async getVariantsForInvoice(
    stockLocationId: number,
    skus: string[],
    priceConfiguration?: any,
  ) {
    const variants = await this.searchVariants(
      stockLocationId,
      '',
      skus,
      priceConfiguration,
    );
    for (const variant of variants) {
      const children = [];
      if (variant.type === OfflinePosEnums.VariantTypesConstant.Package) {
        variant.VariantToPackages.forEach((variantToPackage) => {
          variantToPackage.ProductVariant.packSku = variant.sku;
          variantToPackage.ProductVariant.packId = variant.id;
          children.push({
            ...variantToPackage.ProductVariant,
            VariantToPackages: JSON.parse(
              JSON.stringify(variant.VariantToPackages),
            ),
          });
        });
      } else if (variant.type === OfflinePosEnums.VariantTypesConstant.Composite) {
        const stock = variant.ProductVariantToStockLocations.find(
          (stockLocation) => stockLocation.stockLocationId === stockLocationId,
        );
        variant.Product.VariantToComposites.forEach((variantToComposite) => {
          children.push({
            ...variantToComposite.ProductVariant,
            VariantToComposites: [variantToComposite],
          });
        });
        stock.quantity =
          posInvoiceCalculationService.calculateMaxToSell(children);
      }
      variant.children = children;
    }
    return variants;
  }

  /**
   * @deprecated
   */
  async getProductByScannerCode(
    code: string,
    stockLocationId: number,
    weightedConfiguration: WeightedProductConfiguration,
  ) {
    try {
      const sku = getSkuFromScannerCode(weightedConfiguration, code);
      const foundItem =
        await this.productVariantStoreService.findBySkuOrBarcode(sku);

      if (!foundItem) {
        throw new Error(
          `You can’t sell ${code} product is not exist in the inventory.`,
        );
      } else if (foundItem && !foundItem.isSellable) {
        throw new Error(`You can’t sell ${code} product is not sellable.`);
      }

      adjustOfflineVariantData(
        foundItem,
        this.priceConfiguration,
        stockLocationId,
      );

      return foundItem;
    } catch (err) {
      console.log(err);
      this.toastMessage.error(err.message);
      playErrorNotificationAudio();
    }
  }

  async getProductByScannerCodeV2(
    code: string,
    stockLocationId: number,
    weightedConfiguration: WeightedProductConfiguration,
  ) {
    const sku = getSkuFromScannerCode(weightedConfiguration, code);
    const foundItem = await this.productVariantStoreService.findBySkuOrBarcode(
      sku,
    );

    if (!foundItem) {
      return null;
    } else if (foundItem && !foundItem.isSellable) {
      return foundItem;
    }

    adjustOfflineVariantData(
      foundItem,
      this.priceConfiguration,
      stockLocationId,
    );

    return foundItem;
  }

  async getVariantByProductId(
    productId: number,
    locationId: number,
  ): Promise<ProductVariant[]> {
    const variants = (await this.productVariantStoreService.findByProductId(
      productId,
    )) as any;
    variants.forEach((r: any) => {
      adjustOfflineVariantData(r, this.priceConfiguration, locationId);
    });
    return variants;
  }

  async getProductVariants(
    variantIds: number[],
    stockLocationId: number,
    updatedAfterDate: Date,
  ): Promise<ProductVariant[]> {
    const variants = (await this.productVariantStoreService.findByIdsAfterDate(
      variantIds,
      updatedAfterDate,
    )) as any;
    variants.forEach((r: any) => {
      adjustOfflineVariantData(r, this.priceConfiguration, stockLocationId);
    });
    return variants as ProductVariant[];
  }

  async getProductById(
    id: number,
    stockLocationId: number,
    priceConfiguration?: any,
  ): Promise<any> {
    try {
      const variants = await this.productVariantStoreService.findByProductId(
        id,
      );
      this.priceConfiguration = priceConfiguration || this.priceConfiguration;

      variants.forEach((r: any) => {
        adjustOfflineVariantData(r, this.priceConfiguration, stockLocationId);
      });

      return this.variantsToProduct(variants);
    } catch (err) {
      console.log(err);
    }
  }

  async getProductByIds(
    ids: number[],
    stockLocationId: number,
    priceConfiguration?: any,
  ): Promise<any> {
    try {
      const variants = await this.productVariantStoreService.findByProductIds(
        ids,
      );
      this.priceConfiguration = priceConfiguration || this.priceConfiguration;

      variants.forEach((r: any) => {
        adjustOfflineVariantData(r, this.priceConfiguration, stockLocationId);
      });

      const groupedVariants = map(
        groupBy(variants, (variant) => variant.productId),
      );
      const products = groupedVariants.map((variants) =>
        this.variantsToProduct(variants),
      );
      return products;
    } catch (err) {
      console.log(err);
    }
  }

  async deleteAllVariants(): Promise<void> {
    return this.productVariantStoreService.deleteAll();
  }

  private variantsToProduct(data: any[]): Product {
    const product = new Product(data[0].Product);
    product.ProductVariants = data;
    const attributes = product.ProductVariants[0]?.variantAttributes;
    let options = [];
    const optionObject = {};

    // Creating options from attributes
    if (attributes && attributes.length) {
      product.ProductVariants?.forEach((variant) => {
        if (variant.variantAttributes && variant.variantAttributes.length) {
          options = options.concat(
            JSON.parse(variant.variantAttributes).map((attribute) => ({
              ...attribute,
            })),
          );
        }
      });
      options.forEach((option) => {
        if (optionObject[option.optionId]) {
          optionObject[option.optionId].values.push(option.value);
        } else {
          optionObject[option.optionId] = {
            id: option.optionId,
            option: option.option,
            values: [option.value],
          };
        }
      });
    }
    product.VariantOptions = Object.values(optionObject).map(
      (option: VariantOption) => ({
        ...option,
        values: [...new Set(option.values)],
      }),
    );
    return product;
  }
}
