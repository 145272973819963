import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { UserAuthenticationService } from './user-authentication.service';
import { LocalStorageKey } from '../../shared/constants';

@Injectable()
export class SubscriptionValidationService {
  private dataSubject$ = new BehaviorSubject<boolean>(undefined);

  constructor(
    private localStorageService: LocalStorageService,
    private userAuthenticationService: UserAuthenticationService,
  ) {
    const value =
      this.localStorageService.getItem<boolean>(
        LocalStorageKey.subscriptionDataDirtyKey,
      ) ?? false;

    this.dataSubject$.next(value);
  }

  makeSubscriptionDirty(): void {
    this.localStorageService.setItem<boolean>(
      LocalStorageKey.subscriptionDataDirtyKey,
      true,
    );
    this.dataSubject$.next(true);
  }

  async makeSubscriptionClean(): Promise<void> {
    this.localStorageService.removeItem(
      LocalStorageKey.subscriptionDataDirtyKey,
    );
    await this.userAuthenticationService.refreshUser();
    this.dataSubject$.next(false);
  }

  // if it is true it mean that the subscription is stale and not up to date
  isSubscriptionDirty(): Observable<boolean> {
    return this.dataSubject$.asObservable();
  }
}
