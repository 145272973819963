import { Injectable } from '@angular/core';
import { ConfigStoreService } from './config-store.service';
import { ConfigStoreKeys } from '../../../constants';
import { OfflinePromotion } from './stores/OfflinePromotionStore';
import { Promotion } from '../../../../internal-apps/promotion/model/Promotions';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';
import { PromotionTypes } from '@rewaa-team/pos-sdk';

@Injectable()
export class PromotionStoreService {
  constructor(private configStoreService: ConfigStoreService) {}

  private async getDB(): Promise<any> {
    return DatabaseService.getDB();
  }

  public async save(promotions: OfflinePromotion[]): Promise<number> {
    const databaseService: DatabaseService = await this.getDB();
    const lastKey: number = await databaseService
      .getRepository(TableName.OfflinePromotions)
      .bulkPut(promotions);
    return lastKey;
  }

  public async delete(ids: number[]) {
    const databaseService: DatabaseService = await this.getDB();
    await databaseService.getRepository(TableName.OfflinePromotions).bulkDelete(ids);
  }

  public async deleteAll() {
    const databaseService: DatabaseService = await this.getDB();
    await Promise.all([
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.PromotionApiConfig,
      ),
      databaseService.getRepository(TableName.OfflinePromotions).clear(),
    ]);
  }

  public async deleteStale() {
    const currentDate = new Date().toISOString();
    const databaseService: DatabaseService = await this.getDB();
    await databaseService
      .getRepository(TableName.OfflinePromotions)
      .where('endDate')
      .below(currentDate)
      .delete();
  }

  public async findActiveByVariantIds(
    variantIds: number[],
  ): Promise<OfflinePromotion[]> {
    const currentDate = new Date();
    const databaseService: DatabaseService = await this.getDB();
    const promotions = await databaseService
      .getRepository(TableName.OfflinePromotions)
      .where('promotionVariants')
      .anyOf(variantIds)
      .distinct()
      .toArray();
    return promotions.filter((promo) => {
      const startDate = new Date(promo.startDate);
      const endDate = new Date(promo.endDate);
      return currentDate > startDate && endDate > currentDate;
    });
  }

  public async findActiveForAllProducts(): Promise<
    OfflinePromotion | undefined
  > {
    const currentDate = new Date();
    const databaseService: DatabaseService = await this.getDB();
    const promotions = await databaseService
      .getRepository(TableName.OfflinePromotions)
      .where('isAllProducts')
      .equals(1)
      .distinct()
      .toArray();
    return promotions.find((promo) => {
      const startDate = new Date(promo.startDate);
      const endDate = new Date(promo.endDate);
      return currentDate > startDate && endDate > currentDate;
    });
  }

  public async findActive(
    variantIds: number[],
  ): Promise<{
    variantSpecific: OfflinePromotion[],
    forAllProducts?: OfflinePromotion
  }> {
    const [forAllProducts, variantSpecific] = await Promise.all([
      this.findActiveForAllProducts(),
      this.findActiveByVariantIds(variantIds),
    ]);
    return {
      variantSpecific,
      forAllProducts
    };
  }

  public mapOfflinePromotionToPromotion(
    offlinePromotion: OfflinePromotion,
    stockLocationId: number,
  ): PromotionTypes.Promotion {
    let promotion: PromotionTypes.Promotion = {} as any;
    Object.assign(promotion, offlinePromotion);
    promotion.PromotionStockLocations = [
      {
        promotionId: promotion.id,
        stockLocationId,
      },
    ];
    promotion.PromotionVariants = (
      offlinePromotion?.promotionVariants || []
    ).map((productVariantId) => ({
      promotionId: promotion.id,
      productVariantId,
    }));
    return promotion;
  }
}
