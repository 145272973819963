import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataPermission } from '../data-permissions.reducers';

const API_URL = '/api';

@Injectable()
export class DataPermissionsService {
  constructor(
    private http: HttpClient,
  ) {}

  public getDataPermission(objectName: string) {
    return this.getDataPermissions(objectName).pipe(
      map(
        (dataPermissions) => {
          const permission = dataPermissions.find(
            (dataPermission) => dataPermission.objectName === objectName,
          );
          return permission;
        },
      ),
    );
  }

  private getDataPermissions(objectName) {
    return this.http.get<DataPermission[]>(
      `${API_URL}/data-permissions`,
      {
        params: {
          objectName,
        },
      },
    );
  }
}
