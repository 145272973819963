// eslint-disable-next-line max-classes-per-file
export class Category {
  public id?: number;

  public name?: string;

  public parentId?: number;

  public createdAt?: string;

  public updatedAt?: string;

  public deletedAt?: string;

  public level?: number;

  public products?: Array<number>;

  public parents?: Category[] = [];

  constructor(type?: Category) {
    Object.assign(this, type || {});
  }

  get label() {
    return this.name;
  }

  get value() {
    return this.id;
  }
}
export class CreatedCategoryResult {
  public category?: Category;

  public error?: string;
}

export type AppPublishCategory = {
  image: string;
  name: string;
  key: string;
};
