import { Injectable } from '@angular/core';
import { TranslateAppNamesService } from './translate-app-names.service';

@Injectable()
export class FormatAppNamesService {
  constructor(private translateAppNamesService: TranslateAppNamesService) {}

  formatByLangAndAppNames(lang: string, appNames: string | string[]): string {
    if (Array.isArray(appNames)) {
      const translatedAppNames: string[] = this.translateAppNamesService.getTranslatedAppNames(appNames);
      if (lang === 'en') {
        return this.formatAppNamesInEn(translatedAppNames);
      }
      return this.formatAppNamesInAr(translatedAppNames);
    }
    return appNames;
  }

  private formatAppNamesInEn(appNames: string[]): string {
    const captializedAppNames = [...new Set(appNames)].map(
      (appName) => appName[0].toUpperCase() + appName.slice(1),
    );
    if (captializedAppNames?.length <= 2) {
      return captializedAppNames?.join(' and ');
    }
    return `${captializedAppNames
      ?.slice(0, appNames.length - 1)
      .join(', ')} and ${captializedAppNames[captializedAppNames.length - 1]}`;
  }

  private formatAppNamesInAr(appNames: string[]): string {
    const captializedAppNames = [...new Set(appNames)].map(
      (appName) => appName[0].toUpperCase() + appName.slice(1),
    );
    if (captializedAppNames?.length <= 2) {
      return captializedAppNames?.join(' و ');
    }
    return `${captializedAppNames
      ?.slice(0, appNames.length - 1)
      .join(' و ')} و ${captializedAppNames[captializedAppNames.length - 1]}`;
  }
}
