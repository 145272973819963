import { fromEvent, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

export interface IDownloadFile {
  filename: string;
  downloadUrl: string;
}

export function downloadFileFromUrl(invoice: IDownloadFile): Observable<void> {
  return new Observable<HTMLAnchorElement>((sub) => {
    const downloadAnchorTag = document.createElement('a');
    downloadAnchorTag.setAttribute('href', invoice.downloadUrl);
    downloadAnchorTag.setAttribute('download', invoice.filename);
    document.body.appendChild(downloadAnchorTag);
    downloadAnchorTag.click();
    sub.next(downloadAnchorTag);
  }).pipe(
    concatMap((element: HTMLAnchorElement) => fromEvent(element, 'click').pipe(
      map(() => element),
    )),
    map((element: HTMLAnchorElement) => {
      element.click();
    }),
  );
}
