import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomLoaderComponent } from '../components/custom-loader/custom-loader.component';
import { StartLoaderOptions } from '../types/loader.types';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class CustomLoaderService {

  loaderRef: DynamicDialogRef;

  isEnableCustomLoader = false;

  destroySub$ = new Subject();

  constructor(
    private readonly dialogService: DialogService,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.featureFlagService.isEnabled(FeatureFlagEnum.CustomLoader).pipe(
      takeUntil(this.destroySub$)
    ).subscribe((response) => {
      this.isEnableCustomLoader = response;
      if (!this.isEnableCustomLoader && this.loaderRef) {
        this.loaderRef.close();
      }
    })
  }

  private onBeforeUnloadHandler(event: BeforeUnloadEvent): string {
    event.preventDefault();
    return '';
  }

  startLoader(options: StartLoaderOptions = {}): void {
    if (!this.isEnableCustomLoader) {
      return;
    }
    const { header, body, footer } = options;
    if (this.loaderRef) {
      this.loaderRef.close();
    }
    window.addEventListener('beforeunload', this.onBeforeUnloadHandler);
    this.loaderRef = this.dialogService.open(
      CustomLoaderComponent,
      {
        showHeader: false,
        header,
        styleClass: 'rw__dynamic-dialog rw__dynamic-dialog-sm',
        data: { 
          body,
          footer,
         },
      },
    );
    const closeModalRef = this.loaderRef.onClose.subscribe(() => {
      this.loaderRef = null;
      closeModalRef.unsubscribe();
      window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
    });
  }

  stopLoader(): void {
    if (!this.loaderRef) {
      return;
    }
    this.loaderRef.close();
  }
}
