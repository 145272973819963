import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { interval, Subscription } from 'rxjs';
import { AutoUnsubscribe, AutoUnsubscribeI } from 'src/app/shared/decorators/auto-unsubscribe';
import { MerchantService } from '../../../auth/services/merchant.service';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss'],
})
@AutoUnsubscribe
export class AccountSetupComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  isLoading = true;

  numberObservable = interval(1000);

  subscription: Subscription = undefined;

  constructor(private merchantService: MerchantService,
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscriptionRefs = this.numberObservable.subscribe(() => {
      this.checkMerchantStatus();
    });
    this.subscription = this.subscriptionRefs;
  }

  checkMerchantStatus() {
    const authenticatedUser = this.authService.getAuthenticatedUser();
    // Current cognito setup to use email as username.
    const email = authenticatedUser.getUsername();
    this.subscriptionRefs = this.merchantService.getMerchantByEmail(email)
      .subscribe((merchant) => {
        if (merchant && merchant.status === 'Active') {
          this.isLoading = false;
          this.router.navigate(['inventory/products']);
          this.subscription.unsubscribe();
        }
      });
  }
}
