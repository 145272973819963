<div class="flex justify-content-center ltr_otp">
  <span
    class="rw__input rw__input-lg"
    *ngFor="let input of [].constructor(otpLength); index as i"
  >
    <input
      pendo-tracker="oii"
      #input
      pInputText
      [disabled]="disabled"
      (keydown)="onKeyDown($event, i)"
      (focus)="onFocus(i)"
      type="tel"
      (input)="onChange($event, i)"
      autocomplete="one-time-code"
    />
  </span>
  <div
    *ngIf="showClearOtpButton === true"
    class="bg-white rounded-4 shadow-1 rw__p-2"
  >
    <button
      pButton
      class="rw__button rw__button-sm rw__button-icon p-button-plain p-button-text p-button-primary rw__m-1"
      data-qa="otpInputDialog-clearOtp"
      (click)="clearOtp()"
      [disabled]="
        value === '' ||
        value === undefined ||
        value?.split('Backspace').length > otpLength
      "
    >
      <i class="fa-regular fa-trash-can"></i>
    </button>
  </div>
</div>
