<p-dialog header="{{ 'auth.changeNumber' | translate }}"
    class="rw__dialog edit-phone-dialog" 
    [modal]="true" 
    [(visible)]="dialogVisible" 
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
>
  <div class="flex flex-column">
      <div class="flex gap-2 ltr">
          <div class="rw__input rw__input-float p-float-label minw-0 w-10rem">
              <div class="div-input" pInputText>
                  <div class="flex align-item-center">
                      <img class="border-round" src="https://flagsapi.com/SA/flat/32.png" alt="Saudi Arabia Flag" width="22" />
                      <span class="rw__ms-3">+966</span>
                  </div>
              </div>
          </div>
          <span class="rw__input rw__input-default minw-0 w-full">
              <input name="email" type="text" pInputText placeholder="{{ '5x xxx xxxx' | translate }}" autocomplete="one-time-code" 
                [formControl]="phone"
              />
          </span>
      </div>
      <ng-container *ngIf="phone.invalid && phone.touched">
        <div class="d-flex flex-column">
          <div *ngIf="phone.errors.required" class="d-flex rw__mt-2">
            <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
            <small class="p-error block mx-1">{{ 'auth.phoneRequired' | translate }}</small>
          </div>
          <div *ngIf="!phone.errors.required">
            <div *ngIf="phone.errors.zeroSaudiPattern" class="d-flex rw__mt-2">
              <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
              <small class="p-error block mx-1">{{ 'auth.zeroSaudiPattern' | translate }}</small>
            </div>
            <div *ngIf="phone.errors.saudiPattern" class="d-flex rw__mt-2">
              <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
              <small class="p-error block mx-1">{{ 'auth.saudiPattern' | translate }}</small>
            </div>
            <div *ngIf="phone.errors.saudiZeroOrZeroFive" class="d-flex rw__mt-2">
              <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
              <small class="p-error block mx-1">{{ 'auth.saudiZeroOrZeroFive' | translate }}</small>
            </div>
            <div *ngIf="phone.errors.otherThanSaudiPattern" class="d-flex rw__mt-2">
              <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
              <small class="p-error block mx-1">{{ 'auth.phoneErrorNotSaudiPattern' | translate }}</small>
            </div>
          </div>
        </div>
      </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-column gap-2">
        <button
            pButton pRipple
            pendo-tracker="update_phone_cta_button_click"
            type="button"
            class="flex align-items-center justify-content-center w-full"
            (click)="updatePhone()"
        >
            <span>{{ 'auth.confirmAndSendOtp' | translate }}</span>
        </button>
        <button
            pButton pRipple
            pendo-tracker="update_phone_cancel_cta_button_click"
            (click)="closeThisDialog()"
            class="flex align-items-center justify-content-center w-full p-button-text p-button-plain m-0"
        >
            {{ 'Cancel' | translate }}
        </button>
    </div>
  </ng-template>
</p-dialog>