import { addNumberPadding } from '../utils/common';
import { GetInvoiceNumberInput, GetPaymentNumberInput } from './types';
import { v4 as uuidv4 } from 'uuid';
import { generateShortHash } from '../utils/hash.utils';

export class InvoiceService {
  public getInvoiceNumber(input: GetInvoiceNumberInput): string {
    const {
      completionDate,
      registerId = 0,
      stockLocationId,
      isReturn,
      isOffline,
      invoiceNumber,
      timezone,
    } = input;
    const date = timezone
      ? new Date(completionDate.toLocaleString('en-US', { timeZone: timezone }))
      : completionDate;
    const dateString = `${date.getFullYear()}${addNumberPadding(
      date.getMonth() + 1,
    )}${addNumberPadding(date.getDate())}`;

    const prefix = isReturn ? 'R' : 'S';

    if (isOffline) {
      const uuid = uuidv4();
      const randomCharacters = generateShortHash(
        `${stockLocationId}-${registerId}-${uuid}`,
      ).toUpperCase();

      return `${prefix}${dateString}-${randomCharacters}-${invoiceNumber}`;
    }

    return `${prefix}${dateString}-${invoiceNumber}`;
  }

  public getPaymentNumber(input: GetPaymentNumberInput): string {
    const {
      completionDate,
      registerId = 0,
      stockLocationId,
      isReturn,
      isOffline,
      paymentNumber,
      timezone,
    } = input;
    const date = timezone
      ? new Date(completionDate.toLocaleString('en-US', { timeZone: timezone }))
      : completionDate;
    const dateString = `${date.getFullYear()}${addNumberPadding(
      date.getMonth() + 1,
    )}${addNumberPadding(date.getDate())}`;
    const prefix = isReturn ? 'PayR' : 'PayS';
    if (isOffline) {
      return `${prefix}${dateString}-${stockLocationId}-${registerId}-${paymentNumber}`;
    }
    return `${prefix}${dateString}-${paymentNumber}`;
  }
}

export const invoiceService = new InvoiceService();
