import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardService } from './auth/services/guard.service';
import { ActiveGuardService } from './auth/services/active.guard.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { UnauthorisedAccessComponent } from './unauthorised-access/unauthorised-access.component';
import { TranslationCacheResolver } from './users-settings/services/translation-cache.resolver';
import { FutureSubscriptionComponent } from './future-subscription/future-subscription.component';
import { UserpilotGuardService } from './services/userpilot-guard.service';
import { SubscriptionFallbackComponent } from './subscription-fallback/subscription-fallback.component';

const routes: Routes = [
  {
    path: '',
    resolve: {
      translationCacheResolver: TranslationCacheResolver,
    },
    canLoad: [GuardService],
    canActivate: [ActiveGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./shell/shell.module').then((m) => m.ShellModule),
      },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'unauthorised',
    component: UnauthorisedAccessComponent,
  },
  {
    path: 'future-subscription',
    component: FutureSubscriptionComponent,
  },
  {
    path: 'subscription-fallback',
    component: SubscriptionFallbackComponent,
    data: {},
  },
  {
    path: 'cobrowsing-wizard',
    loadChildren: () =>
      import('./cobrowsing/cobrowsing.module').then((m) => m.CobrowsingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
