import { Injector } from '@angular/core';
import { AuthUser } from '../../auth/auth.models';
import { LocalStorageKey } from '../../shared/constants';
import { LocalStorageService } from '../../core/services/local-storage.service';

export class UserSettingsUtility {
  public static getExpirationInDays(endDate: Date): number {
    const end = new Date(endDate);
    const days = Math.trunc((end.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
    return days > 0 ? days : 0;
  }

  public static isExpiredSubscription(endDate: Date): boolean {
    return new Date() > new Date(endDate);
  }

  public static isMaxActiveStockLocationsReached(locations) {
    const injector = Injector.create({
      providers: [
        { provide: LocalStorageService },
      ],
    });
    const localStorageService = injector.get(LocalStorageService);
    const user = localStorageService.getItem<AuthUser>(LocalStorageKey.User);
    const accountSubscription = user?.RewaaAccountSubscription;
    const activeLocationsNo = locations.filter((l) => (l.isActive)).length;
    if (accountSubscription && !accountSubscription.trialPlan
      && activeLocationsNo >= accountSubscription.maxStockLocationsNo) {
      return true;
    }
    return false;
  }

  public static getDaysLeftToDisableAccount(endDate: Date): number {
    const end = new Date(endDate);
    const sixtyDaysInMs = 60 * 60 * 24 * 60 * 1000;
    return Math.abs(Math.ceil(((end.getTime() + sixtyDaysInMs) - Date.now())
          / (1000 * 60 * 60 * 24)));
  }
}
