<p-steps
    [model]="items"
    [(activeIndex)]="activeIndex"
    [readonly]="false"
    [styleClass]="styleClass"
></p-steps>



<div>



    <div *ngIf="activePage === activePageEnum.SERIAL">
        <rw-serial-product
            [variants]="serialVariants"
            [locationId]="locationId"
        ></rw-serial-product>
    </div>



    <div *ngIf="activePage === activePageEnum.BATCH">
        <rw-batched-products
            [variants]="batchVariants"
            [locationId]="locationId"
        ></rw-batched-products>
    </div>



    <div class="footer-row">
        <div class="">
            <button pendo-tracker="tpb"
                class="px-5 mx-2 back-btn"
                pButton pRipple
                type="button"
                (click)="cancel()"
            >
                {{ 'Back' | translate }}
            </button>
            <button pendo-tracker="tpb-1"
                [disabled]="!formValid()"
                pButton pRipple
                class="px-5 mx-2 save-btn"
                type="button"
                (click)="save()"
            >
                <span>{{ 'Send' | translate }}</span>
            </button>
        </div>
    </div>


</div>
