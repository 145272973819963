import { Pipe, PipeTransform } from '@angular/core';
import { DefaultAccountHeadsReferenceURI } from '@rewaa-team/rewaa-accounting';
import { AdvancedAccountingService } from '../../advanced-accounting/advanced-accounting.service';
import { AcHeadCodeTransPipe } from './ah-code-translate.pipe';

@Pipe({
  name: 'ahCodeTitlePipe',
})
export class AcHeadCodeTitlePipe implements PipeTransform {
  constructor(
    private advancedAccountingService: AdvancedAccountingService,
    private acHeadCodeTransPipe: AcHeadCodeTransPipe,
  ) {}

  transform(
    code: string,
    title: string,
    parentHeadReferenceUri?: string,
  ): string {
    const translatedTitle =
      this.advancedAccountingService.accountHeadTranslationHelper(title);
    if (
      !parentHeadReferenceUri ||
      ![
        DefaultAccountHeadsReferenceURI.AccountPayable as string,
        DefaultAccountHeadsReferenceURI.AccountReceivable as string,
      ].includes(parentHeadReferenceUri)
    ) {
      const translatedCode = this.acHeadCodeTransPipe.transform(code).trim();
      return `${translatedCode} - ${translatedTitle}`;
    }
    return `${translatedTitle}`;
  }
}
