export class BrandResult {
  public brand?: Brand;

  public error?: string;
}

export class Brand {
  public id: number;

  public name;

  constructor(brand?) {
    Object.assign(this, brand || {});
  }

  get label() {
    return this.name;
  }

  get value() {
    return this.id;
  }
}
