<div
    [ngClass]="langCss"
    class="modal-container"
>
    <div class="title-row">
        <div class="title">{{content.title | translate}}</div>
        <span
            class="flex cancel-icon"
            (click)="content.cancel_btn.handler()"
        >
            <i class="fa-regular fa-xmark"></i>
        </span>
    </div>
    <div class="body">
        <div class="body-content">{{content.body.content | translate}}</div>
        <div class="body-description">
            {{content.body.description | translate}}
        </div>
    </div>
    <div class="footer">
        <div
            class="save-btn"
            (click)="content.success_btn.handler()"
            [ngClass]="{'disabled-btn': !content.success_btn.enabled}"
        >
            <span>
                {{content.success_btn.text | translate}}
            </span>
        </div>
        <div
            class="cancel-btn"
            (click)="content.cancel_progress_btn.handler()"
        >
            <span>
                {{content.cancel_progress_btn.text | translate}}
            </span>
        </div>
    </div>
</div>
