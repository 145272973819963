import { Tax } from '../Tax';

export class StockOrderItem {
  public buyPrice?: number;

  public id?: number;

  public productVariantId?: number;

  public name: string;

  public sku: string;

  public variantId: number;

  public requestedQuantity: number;

  public availableLocationQuantity: number;

  public oldCost: number;

  public tax?: Tax;

  public stockOrderTrackedItems?: TrackItem[];

  public receivedQuantity: number;

  public averageCost: number;

  public requisitionCost: number;

  public totalTax: number;

  public discountAmount: number;

  public trackType: TrackTypeV2;

  public createdAt: string;

  public updatedAt: string;

  public deletedAt: string;

  public defaultTaxConfig: Tax;

  public variantType: string;

  public rate: number;
}

export enum TrackTypeV2 {
  Serial = 'serial',
  Batch = 'batch',
  Ecard = 'ecard',
}

export class TrackItem {
  public trackNumber: string;

  public issueDate: Date | string;

  public expiryDate: Date | string;

  public quantity: number;

  public createdAt?: Date;

  public deletedAt?: Date;

  public id?: number;

  public updatedAt?: Date;
}
