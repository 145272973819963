import { BaseError, ErrorData } from '../common/base-error';
import { LineItemFindByParam } from './types';

export const enum ErrorCodes {
  InvalidSerialNumber = 'invalid-serial-number',
  OutOfStock = 'out-of-stock',
  NotFoundInCart = 'not-found-in-cart',
  InvalidSearchParameters = 'invalid-search-parameters',
}
export class InvalidSerialNumberError extends BaseError {
  constructor(serial: string) {
    super(ErrorCodes.InvalidSerialNumber, 'Serial number not found', 400, {
      serial,
    });
  }
}

export interface OutOfStockError {
  code: ErrorCodes.OutOfStock;
  quantity: number;
  availableQuantity: number;
}

export class OutOfStockError extends BaseError {
  constructor() {
    super(ErrorCodes.OutOfStock, 'Product out of stock', 400);
  }
}

export class ProductNotFoundInCart extends BaseError {
  constructor(findBy: LineItemFindByParam) {
    super(
      ErrorCodes.NotFoundInCart,
      'Product not found in cart.',
      400,
      findBy as ErrorData,
    );
  }
}

export class InvalidSearchParameters extends BaseError {
  constructor(findBy: LineItemFindByParam) {
    super(
      ErrorCodes.InvalidSearchParameters,
      'Invalid search parameter(s)',
      400,
      findBy as ErrorData,
    );
  }
}
