import { Pipe, PipeTransform } from '@angular/core';
import { SettingDropdownItems } from '../types';

@Pipe({
  name: 'resolveItemName',
})
export class ResolveItemName implements PipeTransform {
  transform(
    actionBtnNameValue: SettingDropdownItems['actionBtnName'] | undefined,
    data: unknown,
  ): string {
    if (typeof actionBtnNameValue === 'function') {
      return actionBtnNameValue(data);
    }
    return actionBtnNameValue ?? '';
  }
}
