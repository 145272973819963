import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map } from 'rxjs';

const API_URL = '/api';
@Injectable()
export class ImportProductsTemplateInfoService {
  constructor(private http: HttpClient) {}

  public getAllProductTemplate(templateData) {
    // will be deprecated need a get bulk api
    const observables = ['simple', 'variable', 'composite'].map((type) => {
      // eslint-disable-next-line no-param-reassign
      templateData.type = type;
      const queryString = Object.keys(templateData)
        .map((key) => `${key}=${templateData[key]}`)
        .join('&');
      return this.http
        .get<any[]>(`${API_URL}/products/import/templates?${queryString}`)
        .pipe(map((data) => ({ template: data ? data[0] : null, type })));
    });
    return forkJoin(observables);
  }

  public getProductTemplate(templateData) {
    const queryString = Object.keys(templateData)
      .map((key) => `${key}=${templateData[key]}`)
      .join('&');
    return this.http.get<any[]>(
      `${API_URL}/products/import/templates?${queryString}`,
    );
  }

  public getProductUpdateTemplate(templateData) {
    const queryString = Object.keys(templateData)
      .map((key) => `${key}=${templateData[key]}`)
      .join('&');
    return this.http.get<any[]>(
      `${API_URL}/products/update/templates?${queryString}`,
    );
  }
}
