import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WebSocketService } from '../../../core/services/web-socket.service';

declare const window: any;

@Injectable()
export class OnlineOfflineService {
  isOnline = true;

  forceOffline = false;

  webSocketPingPongEnabled = false;

  networkChange = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private webSocketService: WebSocketService,
  ) {
    this.webSocketService.wsObservable.subscribe((message) => {
      if (
        typeof message.isOnline === 'boolean' &&
        this.isOnline !== message.isOnline &&
        !this.forceOffline
      ) {
        this.isOnline = message.isOnline;
        this.networkChange.next(message.isOnline);
      }
    });
    window.addEventListener('offline', (_) => {
      this.webSocketService.wsObservable.next({
        isOnline: false,
        source: 'window: offline',
      });
      this.isOnline = false;
    });

    window.addEventListener('online', (_) => {
      if (this.webSocketPingPongEnabled) {
        return;
      }
      this.webSocketService.wsObservable.next({
        isOnline: true,
        source: 'window: online',
      });
      this.isOnline = true;
    });
  }

  public getOfflineJSONData(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public toggleForceOffline = (force: boolean): void => {
    this.forceOffline = force;
    this.networkChange.next(!force);
    this.isOnline = !force;
    this.webSocketService.wsObservable.next({
      isOnline: !force,
      source: 'force offline',
    });
  };

  public togglePingPong(value: boolean): void {
    this.webSocketPingPongEnabled = value;
  }
}
