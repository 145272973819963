import {
  costCalculator,
  quantityCalculator,
} from '../../../services/calculation-service';
import { IProductFactory } from '../interfaces/product-interface';
import {
  ProductVariantStock,
  VariantQuantityAndCost,
} from '../types/product-types';

export class PackageProduct implements IProductFactory {
  // eslint-disable-next-line class-methods-use-this
  getVariantQuantityAndCostOfProduct({
    variant,
    variantDetails,
  }: VariantQuantityAndCost): ProductVariantStock[] {
    const { quantityChanged, stockLocationId, newCost } = variant;
    const productVariantStocks: ProductVariantStock[] = [];
    variantDetails.forEach((detail) => {
      productVariantStocks.push({
        ...variant,
        productVariantId: detail.productVariantId,
        stockLocationId,
        newCost: newCost
          ? costCalculator.divide(newCost, detail.rate)
          : undefined,
        quantityChanged: quantityCalculator.multiply(
          detail.rate,
          quantityChanged,
        ),
      });
    });
    return productVariantStocks;
  }

  static calculatePackageQuantity(rate: number, quantity: number) {
    return Math.floor(quantityCalculator.divide(quantity, rate));
  }

  static calculatePackageCost(rate: number, cost: number) {
    return costCalculator.multiply(cost, rate);
  }
}
