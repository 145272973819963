/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PurchaseOrderFormComponent } from '../../invoices/purchase-order-new/purchase-order-form/purchase-order-form.component';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable()
export class PoUnsavedChangesGuardService
  implements CanDeactivate<PurchaseOrderFormComponent>
{
  canDeactivate(
    component: PurchaseOrderFormComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean {
    if (component.getAllFormValidation() && !component.submitData) {
      component.showUnsavedModalForPo(nextState);
      return false;
    }
    return true;
  }
}
