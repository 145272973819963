import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceStatus, PAYMENT_STATUSES } from 'src/app/shared/constants';
import { Tags } from 'src/app/shared/constants/tag.constants';
import { PromotionStates } from '../../internal-apps/promotion/v2/create-edit-promotion/types/promotion.types';

@Injectable()
export class TagsService {
  constructor(public translate: TranslateService) {}

  getTagTypeForPayment(paymentType:PAYMENT_STATUSES) {
    switch (paymentType) {
      case PAYMENT_STATUSES.PAID:
        return Tags.success;
      case PAYMENT_STATUSES.NOT_PAID_CREDITOR:
        return Tags.error;
      case PAYMENT_STATUSES.PARTIALLY_PAID_CREDITOR:
        return Tags.warning;
      default:
        return Tags.expired;
    }
  }

  getTagTextForPayment(paymentType: PAYMENT_STATUSES) {
    switch (paymentType) {
      case PAYMENT_STATUSES.PAID:
        return this.translate.instant('Fully Paid');
      case PAYMENT_STATUSES.DRAFT:
        return this.translate.instant('Draft');
      case PAYMENT_STATUSES.NOT_PAID_CREDITOR:
        return this.translate.instant('Not Paid');
      case PAYMENT_STATUSES.PARTIALLY_PAID_CREDITOR:
        return this.translate.instant('Partially Paid');
      case PAYMENT_STATUSES.PARTIALLY_PAID_DEBTOR:
        return this.translate.instant(PAYMENT_STATUSES.PARTIALLY_PAID_DEBTOR);
      case PAYMENT_STATUSES.NOT_PAID_DEBTOR:
        return this.translate.instant(PAYMENT_STATUSES.PARTIALLY_PAID_DEBTOR);
      default:
        return this.translate.instant('Draft');
    }
  }

  getTagTypeForPromotions(promotionType:PromotionStates) {
    switch (promotionType) {
      case PromotionStates.Active:
        return Tags.success;
      case PromotionStates.Ended:
        return Tags.error;
      default:
        return Tags.expired;
    }
  }
  
  getTagTypeForStatus(status) {
    switch (status) {
      case InvoiceStatus.COMPLETED:
        return Tags.success;
      default:
        return Tags.expired;
    }
  }

  getTagTextForStatus(status) {
    switch (status) {
      case InvoiceStatus.COMPLETED:
        return this.translate.instant('Completed');
      default:
        return this.translate.instant('Draft');
    }
  }
}
