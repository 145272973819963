import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'rw-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss']
})
export class CustomLoaderComponent implements OnInit{
  body?: string;
  footer?: string;

  constructor(private readonly config: DynamicDialogConfig) {}

  ngOnInit(): void {
    const { data } = this.config;
    this.body = data.body
    this.footer = data.footer
  }
}
