import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscribable } from 'rxjs';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import { AppService } from '../app.service';
import { ImportService } from './import.service';
import { IImportService } from './import.service.interface';

@Injectable()
export class ImportServiceFactory implements OnDestroy {
  private isIntegrationsV2Enabled: boolean;

  private subscriptionRef: Unsubscribable;

  constructor(
    private readonly featureFlagService: FeatureFlagService,
    private readonly appService: AppService,
    private readonly importService: ImportService,
  ) {
    this.subscriptionRef = this.featureFlagService
      .isEnabled(FeatureFlagEnum.IntegrationsV2)
      .subscribe((value) => {
        this.isIntegrationsV2Enabled = value;
      });
  }

  ngOnDestroy(): void {
    this.subscriptionRef?.unsubscribe();
  }

  getImportService(): IImportService {
    if (this.isIntegrationsV2Enabled) {
      return this.importService;
    }
    return this.appService;
  }
}
