<div class="p-dialog-content-wrap">
  <div
  class="flex flex-column justify-content-center align-items-center rw__pt-9 rw__pb-11"
  >
    <div class="flex-grow-1 flex align-items-center justify-content-center rw__my-6">
      <i
        class="fa-duotone fa-spinner-third fa-xl fa-spin rw__fs-1 text-primary"
      ></i>
    </div>

    <div class="w-60 flex flex-column align-items-center justify-content-center">
      <label class="rw__fs-8 fw-400 line-height-3 rw__m-0 rw__mt-6 text-center"
        [innerHtml]="body"></label
      >
      <span class="rw__fs-10 text-grey-60 rw__mt-5"  *ngIf="footer">
        {{footer}}
      </span>
    </div>
  </div>
</div>