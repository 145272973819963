import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { PosInvoiceTypes, TemplateTypes } from '@rewaa-team/types';
import { PromotionTypes } from '@rewaa-team/pos-sdk';
import { PayableInvoice } from './PayableInvoice';
import { VariantToInvoice } from './VariantToInvoice';
import { Supplier } from '../../../inventory/model/supplier';
import { StockLocation } from '../StockLocation';
import {
  DiscountTypes,
  InvoiceTypes,
  PAYMENT_OPTIONS,
  PAYMENT_STATUSES,
  TAX_TYPES,
} from '../../constants';
import { Order } from '../order/Order';
import { Customer } from '../order/Customer';
import { Register } from '../../../internal-apps/pos/model/Register';
import { Tax, TaxLine } from '../Tax';
import { StockOrderItem } from './StockOrderItem';
import { GRN } from './GRN';
import { MetadataV2 } from '../../../invoices/purchase-order-new/purchase-order-form/types';
import { AdditionalCosts } from '../../../invoices/purchase-order-new/common/types';
import { PaymentPoV2 } from './Payment';

export class Invoice {
  public invoiceNumber?: string;

  public completeDate?: Date;

  public status?: string;

  public notes?: string;

  public type?: string;

  public updatedAt?: Date;

  public invoiceType?: string;

  public issueDate?: Date;

  public subTotalTaxExclusive?: number;

  public totalTax?: number;

  public totalTaxInclusive?: number;

  public destinationLocationId?: number;

  public destinationLocationName?: string;

  public id?: number;

  public stockLocationId?: number;

  public stockLocationName?: string;

  public supplierId?: number;

  public supplierName?: string;

  public supplierVatNumber?: string;

  public companyName?: string;

  public companyVatNumber?: string;

  public taxNumber?: string;

  public PayableInvoice?: PayableInvoice;

  public VariantToInvoices?: VariantToInvoice[];

  public totalPaidAmount?: number;

  public amount?: number;

  public deliveryDate?: Date;

  public Supplier?: Supplier;

  public StockLocation?: StockLocation;

  public Order?: Order;

  public createdAt?: Date;

  public childReturnInvoices?: Invoice[];

  public childPurchaseOrderInvoices?: Invoice[];

  public rtnInvoice?: Invoice;

  public poInvoice?: Invoice;

  public paidInvoices?: Invoice[];

  public customerName?: string;

  public Customer?: Customer;

  public customerVatNumber?: string;

  public userId?: string;

  public registerId?: number;

  public debit?: number;

  public supplyDate?: Date;

  public saleInvoiceId?: number;

  public saleInvoiceNumber?: string;

  public isLoading?: boolean;

  public variants?: Array<ProductVariant>;

  public settings?: string | TemplateTypes.TemplateSettings;

  public registerJson?: any;

  public Register?: Register;

  public userName?: string;

  public permittedUser?: boolean;

  public debitAmount?: number;

  public salesmanId?: number;

  public salesmanUsername?: string;

  public sellType?: string;

  taxLinesAmounts?: { id: number; name: string; amount: number }[];

  displayInvoiceNumber?: string;

  payments?: any;

  customerId?: any;

  commentImage?: any;

  templateVersionNumber?: number;

  orderNumber?: number;

  isSample?: boolean;

  subtotalExclusive?: number;

  version?: number;

  additionalCosts?: { [x: string]: number };

  invoiceSnapshot?: Invoice;

  totalPromotionsAmount?: number;

  totalDiscountExclusive?: number;

  metadata?: Metadata;

  verificationResponse?: ZatcaVerificationResponse;

  zatcaStatus?: string;

  isReturnInvoice?: boolean;

  taxCategories?: PosInvoiceTypes.TaxBreakdown[];

  isOfflineFirst?: boolean;

  promotionGroups?: PromotionTypes.PromotionGroup[];

  returnedPromotionGroups?: PromotionTypes.PromotionGroup[];

  CustomFieldsData?: any;

  additionalCostsArray?: [];
}

export class InvoiceV2 {
  public id: number;

  public invoiceNumber: string;

  public totalTaxInclusive: number;

  public totalTax: number;

  public stockOrderItems: StockOrderItem[];

  public grn: GRN[];

  public paidAmount: number;

  public stockLocationId: number;

  public supplierId: number;

  public completeDate: Date;

  public status: string;

  public notes: string;

  public orderType: string;

  public subTotalTaxExclusive: number;

  public supplier: Supplier;

  public createdAt: Date;

  public attachments: Attachment[];

  public taxType: TAX_TYPES;

  public stockLocationName: string;

  public createdBy: number;

  public expectedDeliveryDate: Date;

  public supplierInvoiceNumber: string;

  public metadata: MetadataV2;

  public additionalCosts: AdditionalCosts[];

  public discountAmount: number;

  public discountType: DiscountTypes;

  public paymentStatus: PAYMENT_STATUSES;

  public productVariantInfo?: {
    buyPrice: number;
    cost: number;
    initialCost: number;
    initialQuantity: number;
    productVariantId: number;
    quantity: number;
  }[];
}

export interface OfflineTrackingInvoice {
  offlineFirst: boolean;
  userId: string;
  displayInvoiceNumber: string;
  completeDate: Date;
  isPlugin?: boolean;
  timeTakenInMilliSeconds?: number;
}

export enum TrackingEventEnum {
  OfflineInvoiceCreated = 'OfflineInvoiceCreated',
  OfflineInvoiceSynced = 'OfflineInvoiceSynced',
  PosRegisterSessionOccupied = 'PosRegisterSessionOccupied',
  PosRegisterSessionReleased = 'PosRegisterSessionReleased',
  PosRegisterSessionBlocked = 'PosRegisterSessionBlocked',
  PosRegisterSessionNotificationReceived = 'PosRegisterSessionNotificationReceived',
}

export interface TaxCategory {
  name: string;
  code: string;
  compoundTaxLineId: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  TaxLines: TaxLine[];
  CoumpoundTax: any;
  rate: number;
  subtotal: number;
  totalAmount: number;
}
export interface CompoundTaxSummary {
  name: string;
  rate: number;
  price: number;
}

export interface TaxLineSummary {
  name: string;
  rate: number;
  price: number;
}

export interface TaxSummary {
  [taxName: string]: {
    taxLines: TaxLineSummary[];
    totalTaxRate: number;
    totalTaxPrice: number;
    compound?: CompoundTaxSummary;
  };
}
export enum ZatcaVerificationStatus {
  Failed = 'Failed',
  InProgress = 'InProgress',
  Completed = 'Completed',
  NotSubmitted = 'NotSubmitted',
}

export enum TransactionType {
  B2B = 'B2B',
  B2C = 'B2C',
}

export enum ZatcaTransactionStatus {
  Failed = 'zatcaApp.failed',
  Pending = 'zatcaApp.pending',
  NotSubmitted = 'zatcaApp.notSubmitted',
  Cleared = 'zatcaApp.cleared',
  Reported = 'zatcaApp.reported',
}

export enum ZatcaDisplayStatus {
  Rejected = 'zatcaApp.invoiceStatus.rejected',
  Pending = 'zatcaApp.invoiceStatus.inProgress',
  NotSubmitted = 'zatcaApp.invoiceStatus.preIntegration',
  Completed = 'zatcaApp.invoiceStatus.accepted',
}

export interface ZatcaMessage {
  type: string;
  code: string;
  status: string;
  category: string;
  message: string;
}

export interface ZatcaValidationResult {
  infoMessages: ZatcaMessage[];
  warningMessages: ZatcaMessage[];
  errorMessages: ZatcaMessage[];
  status: string;
}

export interface ZatcaVerificationResponse {
  clearanceStatus: string;
  clearedInvoice: string;
  validationResults: ZatcaValidationResult;
}

export interface Metadata {
  additionalCosts?: { [x: string]: { value: number; tax?: Tax } };
  attachments: Attachment[];
  taxType: TAX_TYPES;
  costCenterIds?: number[];
  payment?: PaymentPoV2;
}

export interface Attachment {
  link: string;
  size: number;
}

export interface PayDetails {
  amount: number;
  payOption: PAYMENT_OPTIONS;
  payMethodId: number;
  paymentDueDate: Date;
  deliveryDate?: Date;
}

export interface IExportInvoice {
  type: InvoiceTypes;
}
