<button
    pButton
    pRipple
    type="button"
    class="{{ label.length === 0 ? 'rw__button-icon' : '' }} {{ rounded ? 'rounded-circle' : '' }} {{ icon ? '' : 'justify-content-center' }} {{ responsive ? 'rw__button-icon-only-resp' : '' }} rw__button-hide-default-label"
    [ngClass]="[buttonClass, badge ? bgButtonClass : '']"
    pTooltip="{{tooltipText}}"
    tooltipPosition="{{tooltipPosition}}"
    tooltipStyleClass="rw__tooltip"
    rwPermission
    [permission]="permission"
    action="force-hide"
    [attr.pendo-tracker]="tracker"
    [disabled]="disabled || loading"
    (click)="onClick.emit($event)"
  >
    <i *ngIf="icon" [class]="loading ? 'pi pi-spin pi-spinner': icon"></i>
    <span class="white-space-nowrap custom-label">{{ label }}</span>
    <span *ngIf="badge" class="bg-purple-70 text-white ng-star-inserted p-badge p-component p-badge-no-gutter ml-0 rw__ms-3">{{badge}}</span>
</button>
