import { CalculationService } from '@rewaa-team/rewaa-common';
import { AmountType, AmountTypeConstant } from './enums';
export class CommonCalculationService extends CalculationService {
  /**
   * Calculates the tax based on a tax rate.
   *
   * @param {number} price
   * @param {number} taxRate The tax rate as a decimal (e.g., 0.15 for 15% tax).
   * @param {number} [quantity=1]
   * @returns {number} The tax amount.
   */
  calculateTax(
    taxExclusivePrice: number,
    taxRate: number,
    quantity = 1,
  ): number {
    return this.multiply(this.multiply(taxExclusivePrice, taxRate), quantity);
  }

  /**
   * Calculates the tax-inclusive price based on a tax rate.
   *
   * @param {number} taxExclusivePrice The unit price before tax (tax exclusive amount).
   * @param {number} taxRate The tax rate as a decimal (e.g., 0.15 for 15% tax).
   * @param {number} [quantity=1]
   * @returns {number} The tax-inclusive amount.
   */
  calculateTaxInclusivePrice(
    taxExclusivePrice: number,
    taxRate: number,
    quantity = 1,
  ): number {
    const taxAmount = this.calculateTax(taxExclusivePrice, taxRate);
    const unitTaxInclusivePrice = this.add(taxExclusivePrice, taxAmount);
    return this.multiply(quantity, unitTaxInclusivePrice);
  }

  /**
   * Calculates the tax exclusive price from a tax inclusive price based on a tax rate.
   *
   * @param {number} taxExclusivePrice The price before tax (tax exclusive amount).
   * @param {number} taxRate The tax rate as a decimal (e.g., 0.15 for 15% tax)..
   * @returns {number} The tax-inclusive amount.
   */
  calculateTaxExclusiveFromTaxInclusive(
    taxInclusivePrice: number,
    taxRate: number,
  ): number {
    const rate = this.add(taxRate, 1);
    return this.divide(taxInclusivePrice, rate);
  }

  /**
   * Calculates the total discount based on discount amount type (fixed/percentage)
   *
   * @param {number} price
   * @param {number} discount The discount amount itself for 1 quantity if type is 'Fixed'. The discount rate as a decimal (e.g., 0.15 for 15% discount) if type is 'Percentage'.
   * @param {number} amountType 'Fixed' or 'Percentage'
   * @param {number} [quantity=1]
   * @returns {number} The discount amount.
   */
  calculateDiscountAmount(
    price: number,
    discount: number,
    amountType: AmountType,
    quantity = 1,
  ): number {
    // discount cannot exceed the price
    const unitDiscount = Math.min(
      price,
      amountType === AmountTypeConstant.Fixed
        ? discount
        : this.multiply(price, discount),
    );
    return this.multiply(unitDiscount, quantity);
  }

  /**
   * Calculates the discounted price based on the discount type.
   *
   * @param {number} price
   * @param {number} discount The discount amount itself for 1 quantity if type is 'Fixed'. The discount rate as a decimal (e.g., 0.15 for 15% discount) if type is 'Percentage'.
   * @param {number} amountType 'Fixed' or 'Percentage'
   * @param {number} [quantity=1]
   * @returns {number} The discount amount.
   */
  calculateDiscountedPrice(
    price: number,
    discount: number,
    amountType: AmountType,
    quantity = 1,
  ): number {
    const discountAmount = this.calculateDiscountAmount(
      price,
      discount,
      amountType,
      quantity,
    );
    const unitPrice = this.subtract(price, discountAmount);
    return this.multiply(quantity, unitPrice);
  }

  calculateOfflineInvoiceSyncProgress(
    syncedInvoices: number,
    unSyncedInvoices: number,
  ): string {
    return this.multiply(
      this.divide(syncedInvoices, this.add(syncedInvoices, unSyncedInvoices)),
      100,
    ).toFixed(1);
  }
}

export const commonCalculationService = new CommonCalculationService();
