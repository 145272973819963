export const GenderConstant = {
  Male: 'Male',
  Female: 'Female',
  PreferNotToSay: 'Prefernottosay',
} as const;

export type Gender = (typeof GenderConstant)[keyof typeof GenderConstant];

export const CustomerWalletSourceConstant = {
  POSSale: 'POSSale',
  POSReturn: 'POSReturn',
  ReceiveDebit: 'ReceiveDebit',
  JournalEntry: 'JournalEntry',
} as const;

export type CustomerWalletSource =
  (typeof CustomerWalletSourceConstant)[keyof typeof CustomerWalletSourceConstant];

export const AutoCompleteFieldConstant = {
  Name: 'name',
  Code: 'code',
  MobileNumber: 'mobileNumber',
};

export type AutoCompleteField =
  (typeof AutoCompleteFieldConstant)[keyof typeof AutoCompleteFieldConstant];
