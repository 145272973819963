import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  tap,
} from 'rxjs';
import {
  CustomFieldMetadata,
  ICustomFieldServerResponseDetail,
  IModules,
} from '../../users-settings/custom-field.types';
import { environment } from '../../../environments/environment';
import { BYPASS_NORMAL } from '../interceptors/http-error.interceptor';
import { IPOTableColumn } from '../components/datatable-po-variant/types';

@Injectable()
export class CustomFieldInputService {
  customFieldUrl = environment.customFieldApiPath;

  private customFields$: BehaviorSubject<ICustomFieldServerResponseDetail[]> = new BehaviorSubject([]);

  moduleToRouteMap = new Map<string, IModules>()
    .set('/inventory', 'inventory')
    .set('/customers', 'customer')
    .set('/pos', 'sales')
    .set('/reports', 'reports');

  constructor(private http: HttpClient) {}

  getCustomFields(
    currentURL: string,
    bypassTenant = false,
  ): Observable<ICustomFieldServerResponseDetail[]> {
    const moduleName = this.getModuleFromUrl(currentURL);
    const filters = [
      {
        columnName: 'module',
        value: [moduleName],
        matchMode: 'contains',
      },
    ];

    const params = new HttpParams()
      .set('sortBy', 'created_at')
      .set('sortDirection', 'ASC')
      .set('limit', 100)
      .set('offset', 0)
      .set('filters', JSON.stringify(filters))
      .set('bypassTenant', bypassTenant);
    return this.http
      .get<[ICustomFieldServerResponseDetail[], [total: number]]>(
      `${this.customFieldUrl}/custom-field/list`,
      { params, context: new HttpContext().set(BYPASS_NORMAL, true) },
    )
      .pipe(
        catchError(() => of([[]])),
        map(([customFields]) => this.setCustomFields(customFields)),
      );
  }

  getCustomFieldsForReports(
    dashboardName: string,
  ): Observable<ICustomFieldServerResponseDetail[]> {
    const params = new HttpParams()
      .set('dashboardName', dashboardName);
    return this.http
      .get<[ICustomFieldServerResponseDetail[]]>(
      `${this.customFieldUrl}/custom-field/list/report`,
      { params, context: new HttpContext().set(BYPASS_NORMAL, true) },
    )
      .pipe(
        catchError(() => of([[]])),
        map(([customFields]) => this.setCustomFields(customFields)),
      );
  }

  setCustomFields(customFields: ICustomFieldServerResponseDetail[]) {
    this.customFields$.next(customFields);
    return this.customFields$.getValue();
  }

  addCustomField(customField: ICustomFieldServerResponseDetail) {
    this.customFields$.getValue().push(customField);
    this.customFields$.next(this.customFields$.getValue());
  }

  getModuleFromUrl(Url: string) {
    let moduleKey = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const key of this.moduleToRouteMap.keys()) {
      if (Url.includes(key)) {
        moduleKey = key;
      }
    }
    if (!moduleKey) return '';
    return this.moduleToRouteMap.get(moduleKey);
  }

  getCustomFieldsObs() {
    return this.customFields$.asObservable();
  }

  search(
    customFieldId: number,
    cfMetadata: CustomFieldMetadata,
    params: {
      [x: string]: string | number;
    },
  ) {
    const { autoCompleteUrl, response } = cfMetadata;
    const [endpoint, queryParamsStrings] = autoCompleteUrl.split('?');
    const queryParams = queryParamsStrings.split('&');
    const SUBSTR_IN_CURLY_BRACES_REGEX = /{{(.*?)}}/g;

    let queryString = '';
    queryParams.forEach((queryParam) => {
      const [valueToReplace] = queryParam
        .match(SUBSTR_IN_CURLY_BRACES_REGEX)
        .map((s) => s.replace('{{', '').replace('}}', ''));

      if (params[valueToReplace]) {
        if (queryString.length) {
          queryString += '&';
        }
        queryString += `${queryParam.replace(
          `{{${valueToReplace}}}`,
          `${params[valueToReplace]}`,
        )}`;
      }
    });
    if (!queryString) {
      throw new Error('Invalid Query Params');
    }
    return this.http
      .get<{ data: any[] }>(`/api${endpoint}?${queryString}`)
      .pipe(
        map(({ data }) => ({
          customFieldId,
          data: data.map((value) => ({
            displayText: value[response.displayText],
            value: value[response.value],
          })),
        })),
      );
  }
}
