import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAppChannels from '../reducers/app.reducers';
import { AppChannelState } from '../reducers/app.reducers';
import { APP_NAMES } from '../../shared/constants';

export const selectAppChannelState = createFeatureSelector<AppChannelState>('applications-subscriptions');

export const selectAllApps = createSelector(
  selectAppChannelState,
  fromAppChannels.selectAll,
);

export const areAppsLoaded = createSelector(
  selectAppChannelState,
  (state) => state && state.areAppsLoaded,
);

export const areSubscribedAppsLoaded = createSelector(
  selectAppChannelState,
  (state) => state && state.areSubscribedAppsLoaded,
);

export const selectSubscribedApps = createSelector(
  selectAllApps,
  (apps) => apps.filter((app) => app.isSubscribed),
);

export const selectAppById = (appId: number) =>
  createSelector(selectAllApps, (apps) => apps.find((app) => app.id === appId));

export const refreshSallaTokens = createSelector(selectAllApps, (apps) => {
  const salla = apps.find(
    (app) => app.name?.toLowerCase() === APP_NAMES.SALLA && app.isSubscribed,
  );
  return salla?.refreshTokenFailure === 'true' || salla?.refreshTokenFailure === '1';
});
