import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { InvoiceStatusMap } from 'src/app/invoices/utilities/maps';
import { VariantService } from 'src/app/inventory/variants/services/variant.service';
import { SettingsService } from 'src/app/users-settings/services/settings.service';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { firstValueFrom } from 'rxjs';
import { LocalStorageKey, VariantTypes } from 'src/app/shared/constants';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { customStyles } from './custom-styles';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { PermissionHelperService } from '../../../../services/permission.helper.service';
import { StockControlPermissions } from 'src/app/shared/constants';

@Component({
  selector: 'rw-transfer-stock-print',
  templateUrl: './transfer-stock-print.component.html',
  styleUrls: ['./transfer-stock-print.component.scss'],
  providers: [DatePipe],
})
@AutoUnsubscribe
export class TransferStockPrintComponent implements OnInit, AutoUnsubscribeI {
  @Input() content: any = {};

  lng: string;

  langCss: string;

  subscriptionRefs;

  selectionForm: FormGroup;

  options = true;

  stockDetails: any;

  variants: any = [];

  createdAt: Date;

  status = '';

  statusMap = InvoiceStatusMap;

  variantIds: Array<number> = [];

  variantsStockValues: any = [];

  variantsStockPrices: any = [];

  variantsStockPricesMap: any = new Map();

  viewCostPermission: boolean = false;

  packageStockValues: any = [];

  logoUrl: string;

  companyName: string;

  extraColsToStatus = {
    [InvoiceStatusMap.Requested]: ['destinationStock'],
    [InvoiceStatusMap.Pending]: [
      'productCost',
      'productPrice',
      'sourceStock',
      'destinationStock',
      'totalCost',
      'totalPrice',
    ],
    [InvoiceStatusMap.Accepted]: [
      'productCost',
      'productPrice',
      'sourceStock',
      'destinationStock',
      'totalCost',
      'totalPrice',
    ],
    [InvoiceStatusMap.Rejected]: [
      'productCost',
      'productPrice',
      'sourceStock',
      'destinationStock',
      'totalCost',
      'totalPrice',
    ],
    [InvoiceStatusMap.PartiallyAccepted]: [
      'productCost',
      'productPrice',
      'rejectedStock',
      'sourceStock',
      'totalRejectedCost',
      'totalRejectedPrice',
    ],
  };

  mandateColsToStatus = {
    [InvoiceStatusMap.Requested]: [
      'productName',
      'requestedStock',
      'totalRequested',
    ],
    [InvoiceStatusMap.Pending]: [
      'productName',
      'requestedStock',
      'sentStock',
      'totalSent',
      'totalRequested',
    ],
    [InvoiceStatusMap.Accepted]: [
      'productName',
      'totalSent',
      'sentStock',
      'receivedStock',
      'totalReceived',
    ],
    [InvoiceStatusMap.Rejected]: [
      'productName',
      'sentStock',
      'rejectedStock',
      'totalSent',
      'totalReceived',
      'totalrejected',
    ],
    [InvoiceStatusMap.PartiallyAccepted]: [
      'productName',
      'sentStock',
      'receivedStock',
      'totalSent',
      'totalReceived',
    ],
  };

  colsForTemplate = [];

  isRequested = false;

  packIds: Array<number> = [];

  element: HTMLCollectionOf<Element>;

  disablePrint = true;

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private settingsService: SettingsService,
    private variantService: VariantService,
    private localStorageService: LocalStorageService,
    private modalRef: DynamicDialogRef,
    private permissionService: PermissionHelperService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lng);
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
    const cols = this.localStorageService.getItem<{ [key: string]: string[] }>(
      LocalStorageKey.TransferStockPrint,
    );
    if (!cols) {
      this.setFields(this.extraColsToStatus);
    }
  }

  ngOnInit() {
    this.setStatus(this.content.res.status);
    this.buildForm();
    this.mapToView(this.content.res);
    this.updateColumns(this.status);
    this.subscriptionRefs = this.settingsService
      .getCompanyOverview()
      .subscribe((overview) => {
        this.logoUrl = overview?.logoUrl;
        this.companyName = overview?.companyName;
      });

    this.selectionForm.valueChanges.subscribe((res) => {
      const allCols = Object.keys(res);
      const selectedCols = allCols.filter((col) => res[col] === true);
      const colsToBeStored = this.localStorageService.getItem<{
        [key: string]: string[];
      }>(LocalStorageKey.TransferStockPrint);
      colsToBeStored[this.status] = selectedCols;
      this.setFields(colsToBeStored);
      this.updateColumns(this.status);
    });

    this.element = document.getElementsByClassName('modal-content');
    this.element[0]?.classList.add('mt-20');

    this.viewCostPermission = this.permissionService.isPermissionExist(
      StockControlPermissions.TransferStockDetailViewCost,
    )
      ? true
      : false;
  }

  setFields(cols) {
    this.localStorageService.setItem<{ [x: string]: string[] }>(
      LocalStorageKey.TransferStockPrint,
      cols,
    );
  }

  updateColumns(status) {
    const selectedCols = this.localStorageService.getItem<{
      [key: string]: string[];
    }>(LocalStorageKey.TransferStockPrint);
    const cols = selectedCols[status];
    this.colsForTemplate = this.mandateColsToStatus[status].concat(cols);
  }

  setStatus(status) {
    this.status = this.statusMap[status];
  }

  buildForm() {
    const allCols = this.localStorageService.getItem<{
      [key: string]: string[];
    }>(LocalStorageKey.TransferStockPrint);
    const fields = allCols[this.status];
    this.selectionForm = this.formBuilder.group({
      productCost: [fields.includes('productCost')],
      productPrice: [fields.includes('productPrice')],
      sourceStock: [fields.includes('sourceStock')],
      destinationStock: [fields.includes('destinationStock')],
      rejectedStock: [fields.includes('rejectedStock')],
      totalCost: [fields.includes('totalCost')],
      totalPrice: [fields.includes('totalPrice')],
      totalRejectedCost: [fields.includes('totalRejectedCost')],
      totalRejectedPrice: [fields.includes('totalRejectedPrice')],
    });
  }

  async mapToView(transferStock) {
    this.stockDetails = this.getTransferStockDetails(transferStock);
    this.variantIds = this.getVariantIds(transferStock);
    this.packIds = this.getPackIds(transferStock);
    await this.getVariantPrices(this.variantIds);
    if (this.packIds.length > 0)
      await this.getVariantDetailsByPackIds(this.packIds);
    this.variants = this.getVariants(transferStock);
    this.disablePrint = false;
  }

  getTransferStockDetails(transferStock) {
    const {
      invoiceNumber,
      status,
      userName,
      stockLocationName,
      stockLocationId,
      destinationLocationName,
      destinationLocationId,
      notes,
      createdAt,
    } = transferStock;

    this.createdAt = new Date(createdAt);
    this.isRequested = this.isVariantNotRequested(
      transferStock.VariantToInvoices[0],
    );

    return {
      invoiceNumber,
      status: this.statusMap[status],
      userName,
      stockLocationName,
      stockLocationId,
      destinationLocationName,
      destinationLocationId,
      notes,
      createdAt: this.formatDate(this.createdAt),
    };
  }

  getVariantIds(transferStock) {
    return transferStock.VariantToInvoices.map(
      (invoice) => invoice.productVariantId,
    );
  }

  getPackIds(transferStock) {
    const invoices = transferStock.VariantToInvoices.filter(
      (invoice) => invoice?.ProductVariant?.type === VariantTypes.Package,
    );
    return invoices.map((invoice) => invoice.productVariantId);
  }

  formatDate(date: Date): string {
    let str = '';
    const mon = this.translate.instant(
      `shorterMonths.${this.datePipe.transform(date, 'MMM')}`,
    );
    const hrs = this.datePipe.transform(date, 'hh:mm');
    const meridian = this.translate.instant(
      this.datePipe.transform(date, 'aaa'),
    );
    str = `${date.getDate()} ${mon} ${date.getFullYear()} ${hrs} ${meridian}`;
    return str;
  }

  getVariants(transferStock) {
    return transferStock.VariantToInvoices.map((invoice) => {
      let childVariants = [];
      if (invoice.type === VariantTypes.Composite) {
        childVariants = !invoice.ProductVariant
          ? []
          : this.getChildVariants(invoice.ProductVariant.Product);
      }
      return {
        sku: invoice.sku,
        name: invoice.name,
        productVariantId: invoice.productVariantId,
        requestedStock: invoice.requestedQuantity,
        sentStock: invoice.transferQuantity,
        receivedStock: invoice.quantity,
        rejectedStock: invoice.transferQuantity - invoice.quantity,
        type: invoice.type,
        cost: invoice.oldCost || invoice.newCost,
        price: 0,
        sourceStock: invoice.availableLocationQuantity,
        destinationStock: invoice.availableDestnationLocationQuantity,
        childVariants,
      };
    });
  }

  getChildVariants(cProduct) {
    const childVariants = [];
    cProduct.VariantToComposites.forEach((variant) => {
      const { name, sku, ProductVariantToStockLocations } =
        variant.ProductVariant;
      const sourceLoc = this.getLocationById(
        this.stockDetails.stockLocationId,
        ProductVariantToStockLocations,
      );
      const destLoc = this.getLocationById(
        this.stockDetails.destinationLocationId,
        ProductVariantToStockLocations,
      );
      childVariants.push({
        name,
        sku,
        cost: sourceLoc?.cost || 0,
        price: Math.round(sourceLoc?.retailPrice),
        sourceStock: sourceLoc?.quantity || 0,
        destinationStock: destLoc?.quantity || 0,
      });
    });
    return childVariants;
  }

  isVariantNotRequested(variant) {
    if (this.status === this.statusMap.Pending) {
      return !variant.requestedQuantity;
    }
    return false;
  }

  getStockByLocationId(variant, locationId) {
    if (variant && variant.childVariants.length > 0) {
      const { childVariants } = variant;
      if (locationId === this.stockDetails.stockLocationId)
        return this.getLowestSrcStock(childVariants);
      if (locationId === this.stockDetails.destinationLocationId)
        return this.getLowestDestStock(childVariants);
      return 0;
    }
    if (!variant) return 0;
    const variantDetails = this.variantsStockValues.find(
      (v) => v.id === variant.productVariantId,
    );
    if (!variantDetails) return 0;
    if (variantDetails.type === VariantTypes.Package) {
      return this.getPackStock(variant.productVariantId, locationId);
    }
    const loc = this.getLocationById(
      locationId,
      variantDetails.ProductVariantToStockLocations,
    );
    return loc ? loc.quantity : 0;
  }

  getPackStock(variantId, locationId) {
    if (!variantId) return 0;
    const variantDetails = this.packageStockValues.find(
      (variant) => variant.packageVariantId === variantId,
    );
    if (!variantDetails) return 0;
    const loc = this.getLocationById(
      locationId,
      variantDetails?.ProductVariant?.ProductVariantToStockLocations,
    );
    return Math.floor(
      InvoicesCalculator.divide(loc.quantity, variantDetails.rate),
    );
  }

  getLowestSrcStock(variants) {
    let qty = Number.MAX_VALUE;
    variants.forEach((variant) => {
      qty = qty < variant.sourceStock ? qty : variant.sourceStock;
    });
    return qty;
  }

  getLowestDestStock(variants) {
    let qty = Number.MAX_VALUE;
    variants.forEach((variant) => {
      qty = qty < variant.destinationStock ? qty : variant.destinationStock;
    });
    return qty;
  }

  getCost(variant) {
    if (variant?.childVariants?.length > 0) {
      return this.getRoundedValue(
        this.getCostForComposite(variant.childVariants),
      );
    }
    if (variant.cost) return this.getRoundedValue(variant.cost);
    const varian = this.variantsStockValues.find(
      (v) => v.id === variant.productVariantId,
    );
    if (varian) {
      const loc = this.getLocationById(
        this.stockDetails.stockLocationId,
        varian?.ProductVariantToStockLocations,
      );
      return this.getRoundedValue(loc?.cost);
    }
    return 0;
  }

  getCostForComposite(variants) {
    let cost = 0;
    variants.forEach((variant) => {
      cost += variant.cost;
    });
    return cost;
  }

  getPrice(variantId) {
    const variant = this.variantsStockPricesMap.get(variantId);
    if (variant) {
      const loc = this.getLocationById(
        this.stockDetails.stockLocationId,
        variant?.ProductVariantToStockLocations,
      );
      return this.getRoundedValue(loc?.retailPrice);
    }
    return 0;
  }

  getLocationById(id, stockLocations) {
    return stockLocations.find((location) => location.stockLocationId === id);
  }

  async getStockDetails(ids) {
    this.variantsStockValues = await firstValueFrom(
      this.variantService.getVariantsAndStockLocationByIds(ids),
    );
  }

  async getVariantPrices(ids) {
    this.variantsStockPrices = await firstValueFrom(
      this.variantService.getVariantsRetailPriceByIdsAndStockLocation(
        ids,
        this.stockDetails.stockLocationId,
      ),
    );
    this.variantsStockPricesMap = this.variantsStockPrices.reduce((acc, currentValue) => {
      acc.set(currentValue.id, currentValue);
      return acc;
    }, new Map<number, any>());
  }

  async getVariantDetailsByPackIds(ids) {
    const reqs = ids.map((id) =>
      firstValueFrom(this.variantService.getVariantToPackage(id)),
    );
    this.packageStockValues = await Promise.all(reqs);
  }

  getTotalCost(variants) {
    let cost = 0;
    variants.forEach((variant) => {
      cost += variant.sentStock * this.getCost(variant);
    });
    return this.getRoundedValue(cost);
  }

  getTotalPrice(variants) {
    let price = 0;
    variants.forEach((variant) => {
      price += variant.sentStock * this.getPrice(variant.productVariantId);
    });
    return this.getRoundedValue(price);
  }

  getTotalRejectedCost(variants) {
    let cost = 0;
    variants.forEach((variant) => {
      cost +=
        this.getCost(variant) * (variant.sentStock - variant.receivedStock);
    });
    return this.getRoundedValue(cost);
  }

  getTotalRejectedPrice(variants) {
    let cost = 0;
    variants.forEach((variant) => {
      cost +=
        this.getPrice(variant.productVariantId) *
        (variant.sentStock - variant.receivedStock);
    });
    return this.getRoundedValue(cost);
  }

  getTotalByProperty(variants, prop) {
    let qty = 0;
    if (!variants) return 0;
    variants.forEach((variant) => {
      qty += variant[prop];
    });
    return qty;
  }

  printForm() {
    this.options = false;
    const con = document.getElementById('print-doc');
    const win = window.open();
    const styles = customStyles();
    win.document.write(`
    <!DOCTYPE html>
    <html lang=${this.lng} dir="${this.langCss}" xmlns="http://www.w3.org/1999/html">
    <head>
      <title>print transfer stock </title>
      <style>
      @page { size: auto;  margin: 0mm; }
        ${styles}
      </style>
    </head>
    <body>
      ${con.innerHTML}
    </body>`);
    win.document.close();
    win.onload = () => {
      setTimeout(() => {
        win.focus();
        win.print();
      }, 200);
    };
    win.onafterprint = () => {
      win.close();
      this.closeModal();
    };
  }

  closeModal() {
    console.log('CLOSE PRINT');
    this.element[0]?.classList.remove('mt-20');
    this.modalRef.destroy();
  }

  getRoundedValue(value): number {
    if (!value) return 0;
    return InvoicesCalculator.divide(
      Math.round(InvoicesCalculator.multiply(value, 100)),
      100,
    );
  }
}
