import { createAction, props } from '@ngrx/store';
import { Invoice } from '../../shared/model/invoice/Invoice';

export const loadAllRemoveStock = createAction(
  '[Resolve] Load Remove Stock',
);

export const removeStockLoaded = createAction(
  '[Remove Stock Effects] Remove Stock Loaded',
  props<{ removeStock: Invoice }>(),
);

export const loadRemoveStockByInvoiceNumber = createAction(
  '[Load Remove Stock  Effect] Remove Stock by invoice number Loaded',
  props<{ invoiceNumber: string }>(),
);

export const allRemoveStockLoaded = createAction(
  '[Load Remove Stock  Effect] Stock Count Loaded',
  props<{ removeStock: Invoice[] }>(),
);
export const createRemoveStock = createAction(
  '[Create Purchase Order Page] Create new Remove Stock',
  props<{ removeStock: Invoice }>(),
);

export const RemoveStockCreated = createAction(
  '[Invoices Effects] Remove Stock created',
  props<{ removeStock: Invoice }>(),
);

export const startProcessing = createAction('[Remove Stock Effects] Start Processing');

export const endProcessing = createAction('[Remove Stock Effects] End Processing');
