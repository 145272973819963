<div class="flex flex-column align-items-center gap-4 pb-5">
    <div 
        class="text-center flex flex-column align-items-center" 
        style="
            width: 300px;
            height: 300px;
        "
    >
        <img src="../../../../assets/icons/preparing-account.gif" width="360">
    </div>
    <div class="flex flex-column text-center">
        <label class="text-2xl mb-0">{{ 'auth.preparingAccount' | translate }}</label>
    </div>
</div>