import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeleteResponse } from 'src/app/inventory/variants/services/delete-response';
import { Promotion } from '../model/Promotions';
import { CreatePromotionInput } from '../model/create-promotion-input.types';
import {
  PromotionProductImportRow,
  UploadValidationResponse,
  ValidateImportResponse,
} from '../model/promotion.types';
import { PromotionTemplateHeaders } from '../utills/constants';
import { TranslateService } from '@ngx-translate/core';
import { PromotionProduct } from '../v2/create-edit-promotion/types/promotion.types';

@Injectable()
export class PromotionImportService {
  API_URL = '/api';
  MARKETING_URL_PREFIX = '/api/marketing';

  constructor(private http: HttpClient, private translate: TranslateService) {}

  getDataForPromotionImportTemplate(): PromotionProductImportRow[] {
    const cols = [];
    cols.push({
      [PromotionTemplateHeaders.productSKU]: '',
    });

    return cols;
  }

  public isFileCSVOrXlsx(file: File) {
    const extension = file.name.split('.').pop();
    const { type } = file;
    return (
      (extension === 'csv' && file.type === 'text/csv')
      || (extension === 'xlsx'
        && (type
          === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          || type === 'application/vnd.ms-excel'))
    );
  }

  checkForPromotionTemplateHeaders(data, onError: (msg: string) => void) {
    const firstRow = data[0];
    const firstRowHeaders = Object.keys(data[0]);
    const duplicateColumn = firstRowHeaders.filter((row) => row.endsWith('_1'));
    if (duplicateColumn.length > 0) {
      const msg = this.translate.instant(
        'uploadPurchaseOrder.duplicate_header',
      );
      onError(msg);
      return;
    }
    Object.values(PromotionTemplateHeaders).forEach((value) => {
      if (!(value in firstRow)) {
        const msg = this.translate.instant('uploadPurchaseOrder.header_name', {
          name: value,
        });
        onError(msg);
      }
    });
  }

  doLocalValidation(rows: PromotionProductImportRow[]) {
    const rowIndexBySku = new Map<string, number[]>();
    const uniqueSKUs: string[] = [];
    const dupRows: number[][] = [];
    for (let i = 0; i < rows.length; i++) {
      const { productSku } = rows[i];

      const row = i + 2;

      this.separateUniqueAndDuplicateSKUs({
        rowIndexBySku,
        row,
        uniqueSKUs,
        productSku,
      });
    }
    [...rowIndexBySku.values()].forEach(
      (value) => value.length > 1 && dupRows.push(value),
    );

    return {
      uniqueSKUs,
      dupRows,
    };
  }

  separateUniqueAndDuplicateSKUs(params: {
    rowIndexBySku: Map<string, number[]>;
    productSku: string;
    row: number;
    uniqueSKUs: string[];
  }) {
    const { rowIndexBySku, productSku, row, uniqueSKUs } = params;

    if (rowIndexBySku.has(productSku)) {
      const duplicatedRows = [...rowIndexBySku.get(productSku)];
      if (duplicatedRows.length === 1) {
        const uniqueSkuIndex = uniqueSKUs.indexOf(productSku);
        uniqueSKUs.splice(uniqueSkuIndex, 1);
      }

      duplicatedRows.push(row);
      rowIndexBySku.set(productSku, duplicatedRows);
    } else {
      rowIndexBySku.set(productSku, [row]);
      uniqueSKUs.push(productSku);
    }
  }

  async performImportValidation(
    rows: PromotionProductImportRow[],
  ): Promise<UploadValidationResponse> {
    const skuToIndex: { [sku: string]: number } = {};
    rows.forEach(({ productSku }, i) => {
      skuToIndex[productSku] = i;
    });

    const { dupRows, uniqueSKUs } = this.doLocalValidation(rows);
    let validatedSkus: PromotionProduct[] = [];
    let notFoundSkus = [];
    if (uniqueSKUs.length > 0) {
      const res = await lastValueFrom(this.verifyImportProducts(uniqueSKUs))
      validatedSkus = res.validSkus;

      ({ notFoundSkus } = res.notValidSkus);
    }

    return {
      notFoundRows: notFoundSkus.map((sku) => skuToIndex[sku] + 1),
      dupRows,
      validatedSkus,
    };
  }

  verifyImportProducts(skus: Array<string>): Observable<ValidateImportResponse> {
    const requestBody = { skus: skus.map((x) => x.toString()) };
    return this.http.post<ValidateImportResponse>(
      `${this.API_URL}/product/import`,
      requestBody
    );
  }
  
}
