import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RewaaBaseApiResponse } from '@rewaa-team/types';
import { IImportService } from './import.service.interface';
import { ImportRequest } from '../../../shared/model/ImportRequest';
import { ImportProductsResponse } from '../../../shared/model/ImportProductResponse';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../shared/constants';
import {
  GetImportProgressResponse,
  GetInstallationProgressResponse,
  GetTotalProductsToImportResponse,
  ImportToAppServiceMapper,
} from './import-to-app-service.mapper';
import {
  GetProductMigrationResponse,
  GetProductMigrationStatsResponse,
} from './migration.dto';

const API_URL = '/api/omni';

@Injectable()
export class ImportService implements IImportService {
  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private importToAppServiceMapper: ImportToAppServiceMapper,
  ) {}

  importProducts(
    actionRequest: ImportRequest,
  ): Observable<ImportProductsResponse> {
    return this.http
      .post<RewaaBaseApiResponse<{ batchId: string }, never>>(
        `${API_URL}/subscriptions/1/import`,
        {
          appName: actionRequest.appName,
          stockLocationId: actionRequest.stockLocationId,
        },
      )
      .pipe(
        tap((response) => {
          this.localStorageService.setItem<string>(
            LocalStorageKey.InstallImportBatchId,
            response.data.batchId,
          );
        }),
        map(
          (): ImportProductsResponse => ({
            status: 'success',
            message: 'Import request has been sent',
          }),
        ),
      );
  }

  getBatchStatsCall(): Observable<GetImportProgressResponse> {
    const batchId = this.localStorageService.getItem<string>(
      LocalStorageKey.InstallImportBatchId,
    );
    if (!batchId) {
      return of({ data: undefined });
    }
    return this.http.get<GetImportProgressResponse>(
      `${API_URL}/subscriptions/1/import/${batchId}/stats`,
    );
  }

  getInstallationProgress(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    appId: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    totalCount: number,
  ): Observable<GetInstallationProgressResponse> {
    return this.getBatchStatsCall().pipe(
      map((response) =>
        this.importToAppServiceMapper.mapToGetInstallationProgressResponse(
          response,
        ),
      ),
    );
  }

  getTotalProductsToImport(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    appId: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    merchantId: number,
  ): Observable<GetTotalProductsToImportResponse> {
    return this.getBatchStatsCall().pipe(
      map((r) =>
        this.importToAppServiceMapper.mapToGetTotalProductsToImportResponse(r),
      ),
    );
  }

  isInternalInventoryMigrationNeeded(): Observable<boolean> {
    return this.http
      .get<
        RewaaBaseApiResponse<GetProductMigrationResponse, never>
      >(`${API_URL}/migrations/product`)
      .pipe(map((response) => response.data.isNeeded));
  }

  getInventoryImportProgress(): Observable<GetProductMigrationStatsResponse> {
    return this.http
      .get<
        RewaaBaseApiResponse<GetProductMigrationStatsResponse, never>
      >(`${API_URL}/migrations/product/stats`)
      .pipe(map((response) => response.data));
  }

  importInternalInventory(): Observable<void> {
    return this.http.post<void>(`${API_URL}/migrations/product`, {});
  }
}
