export enum TargetType {
  SqsQueue = 'SqsQueue',
  SnsTopic = 'SnsTopic',
  LambdaFunction = 'LambdaFunction',
}

export const Resources: Record<TargetType, string> = {
  [TargetType.SqsQueue]: 'sqs',
  [TargetType.SnsTopic]: 'sns',
  [TargetType.LambdaFunction]: 'lambda',
};

export interface ICreateARN {
  /**
   * TODO: Need to get these from `aws-sdk`, whenever
   * we are on same version for all the service
   */
  region: string;
  accountId: string;
  type: TargetType;
  resourceName: string;
}
