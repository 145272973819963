import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SpinnerService {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  private requestsCounterMap = new BehaviorSubject<Map<string, number>>(
    new Map(),
  );

  _loading$: Observable<boolean> = combineLatest([
    this.loadingSubject.asObservable(),
    this.requestsCounterMap.asObservable(),
  ]).pipe(
    map(([loading, counterMap]) => {
      const anyCounterActive = Array.from(counterMap.values()).some(
        (count) => count > 0,
      );
      return loading || anyCounterActive;
    }),
  );

  startLoading() {
    this.loadingSubject.next(true);
  }

  stopLoading() {
    this.loadingSubject.next(false);
  }

  startLoadingV2(componentId: string): void {
    const currentMap = this.requestsCounterMap.value;
    const currentCount = currentMap.get(componentId) || 0;
    currentMap.set(componentId, currentCount + 1);
    this.requestsCounterMap.next(new Map(currentMap));
  }

  stopLoadingV2(componentId: string): void {
    const currentMap = this.requestsCounterMap.value;
    const currentCount = currentMap.get(componentId) || 0;
    if (currentCount > 0) {
      currentMap.set(componentId, currentCount - 1);
      this.requestsCounterMap.next(new Map(currentMap));
    }
  }
}
