export const VariantTypesConstant = {
  Child: 'child',
  Package: 'package',
  Composite: 'composite',
  Simple: 'simple',
} as const;
export type VariantTypes =
  (typeof VariantTypesConstant)[keyof typeof VariantTypesConstant];

export const ProductTypesConstant = {
  Variable: 'variable',
  Digital: 'digital',
  Composite: 'composite',
  Simple: 'simple',
} as const;
export type ProductTypes =
  (typeof ProductTypesConstant)[keyof typeof ProductTypesConstant];

export const ProductChangeTypeConstant = {
  Updated: 'Updated',
  Created: 'Created',
  Deleted: 'Deleted',
} as const;
export type ProductChangeType =
  (typeof ProductChangeTypeConstant)[keyof typeof ProductChangeTypeConstant];

export const PromotionChangeTypeConstant = {
  Updated: 'Updated',
  Created: 'Created',
  Deleted: 'Deleted',
};
export type PromotionChangeType =
  (typeof PromotionChangeTypeConstant)[keyof typeof PromotionChangeTypeConstant];

export const PromotionTypeConstant = {
  Simple: 'simple',
  Conditional: 'conditional',
} as const;
export type PromotionType =
  (typeof PromotionTypeConstant)[keyof typeof PromotionTypeConstant];

export const PromotionDiscountTypeConstant = {
  Fixed: 'fixed',
  Percentage: 'percentage',
  Free: 'free_product',
} as const;
export type PromotionDiscountType =
  (typeof PromotionDiscountTypeConstant)[keyof typeof PromotionDiscountTypeConstant];

export const PromotionItemTypeConstant = {
  Category: 'category',
  Variant: 'product',
} as const;
export type PromotionItemType =
  (typeof PromotionItemTypeConstant)[keyof typeof PromotionItemTypeConstant];
