import { Injectable } from '@angular/core';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';

@Injectable()
export class CustomerStoreService {
  private async getDB(): Promise<any> {
    return DatabaseService.getDB();
  }

  async putCustomers(customers: Array<any>) {
    try {
      const db: DatabaseService = await this.getDB();

      const lastKey: number = await db
        .getRepository(TableName.Customers)
        .bulkPut(customers);

      console.log(
        `Done putting customers and Last customer id was: ${lastKey}`,
      );
      return lastKey;
    } catch (err) {
      console.log(`failed to putting customers due to error: ${err.message}`);
      console.log(err);
    }
  }

  async handleCustomerUpdates(customers: Array<any>) {
    try {
      const customersToDelete = customers.filter(
        (d: any) => d.action === 'deleted',
      );
      const customersToPut = customers.filter(
        (d: any) => d.action !== 'deleted',
      );

      await Promise.all([
        this.putCustomers(customersToPut.map((d: any) => d.customer)),
        this.deleteCustomers(customersToDelete.map((v: any) => v.id)),
      ]);
    } catch (err) {
      console.log(
        `failed to handle customers updates due to error: ${err.message}`,
      );
      console.log(err);
    }
  }

  async deleteCustomers(ids: Array<any>) {
    try {
      const db: DatabaseService = await this.getDB();
      await db.getRepository(TableName.Customers).bulkDelete(ids);
      console.log('done customers deletion');
    } catch (err) {
      console.log(`failed customers deletion due to error: ${err.message}`);
      console.log(err);
    }
  }

  async findCustomers(term: string) {
    try {
      const db: DatabaseService = await this.getDB();
      const customers = await db
        .getRepository(TableName.Customers)
        .toCollection()
        .filter(
          (customer) =>
            customer.name?.toLowerCase().includes(term.toLowerCase()) ||
            customer.code?.toLowerCase().includes(term.toLowerCase()) ||
            customer.mobileNumber?.includes(term),
        )
        .limit(10)
        .toArray();
      return customers;
    } catch (error) {
      console.log(error);
    }
  }

  async getCustomer(id: number) {
    try {
      const db = await DatabaseService.getDB();
      return await db.getRepository(TableName.Customers).get(id);
    } catch (err) {
      console.log(err);
    }
  }

  async getCustomerCount() {
    try {
      const db = await DatabaseService.getDB();
      return await db.getRepository(TableName.Customers).count();
    } catch (err) {
      console.log(err);
    }
  }
}
