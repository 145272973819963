<div [ngClass]="lng ==='en' ? 'ltr':'rtl'">
  <div class="alert-box" class="app-details">
      <div class="close-button">
          <i class="fas fa-times" (click)=content.cancel_btn.handler()></i>
      </div>
      <div class="modal-body pt-0">
          <div class="modal-dialog modal-lg m-0" role="document" *ngIf="content">
              <div class="modal-content">
                  <div class="modal-body">
                      <div class="media">
                        
                      <div class="media-body" data-qa="offlineHint">
                              <div class="offline-mode-style" >
                                  <b>{{'Offline mode will impact'  | translate  }}</b>
                              </div>
                              <div class="offline-instraction">
                                   <div class="hint-title" >{{'Available Invoices'  | translate  }} </div>
                                   <div class="hint-text" > {{'You can make up to 250 invoices in the offline mode'  | translate  }}</div>

                                   <div class="hint-title" >{{'Data synchronization'  | translate  }} </div>
                                   <div class="hint-text" > {{'Your invoices will be synchronized within 2-3 hours after get back online'| translate  }}</div>
                            

                                   <div class="hint-title" >{{'Post Pay Method'  | translate  }} </div>
                                   <div class="hint-text" > {{'Post Pay Method won\'t be available in the offline mode'| translate  }}</div>

                                   <div class="hint-title" >{{'Tracking information'  | translate  }} </div>
                                   <div class="hint-text" > {{'Tracking information won\'t be included in the invoice'| translate  }}</div>
                                   <div class="hint-text " > {{'Tracking information won\'t be accurate'| translate  }}</div>

                                   <div class="hint-title" >{{'Products Quantity'  | translate  }} </div>
                                   <div class="hint-text" > {{'Products with quantity less than or equal to 0 may be sold'| translate  }}</div>

                                   <div class="hint-title" >{{'Product cost'  | translate  }} </div>
                                   <div class="hint-text" > {{'Products cost which affect your profit won\'t be accurate'| translate  }}</div>

                                </div>
                                <div class="info-text">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    {{'Try to connect to internet as soon as possible' | translate}}
                                </div>
                              <div style="padding-top: 20px" data-qa="offlineHints-gotItBtn">
                                  <b class="user-gauid-link got-it text-capitalize"><a pendo-tracker="oha" [href]="" (click)=content.cancel_btn.handler()>
                                          {{'Got it' | translate}} </a></b>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
  