import { Injectable } from '@angular/core';
import { Parser, parse } from 'bowser';

@Injectable()
export class DeviceInfoService {
  constructor() {
    this.result = this.tryParseUserAgent();
  }

  private readonly result?: Parser.ParsedResult;

  public get isDesktop(): boolean {
    return this.result?.platform?.type?.toLowerCase() === 'desktop';
  }

  public get isChrome(): boolean {
    return this.result?.browser?.name?.toLowerCase() === 'chrome';
  }

  private tryParseUserAgent(): Parser.ParsedResult | undefined {
    try {
      return parse(window.navigator.userAgent);
    } catch (error) {
      return undefined;
    }
  }
}
