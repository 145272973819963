import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ecard } from '../../model/ecard';

const API_URL = '/api';

@Injectable()
export class EcardService {
  constructor(private http: HttpClient) { }

  addEcards(ecards: Ecard[]): Observable<Ecard[]> {
    return this.http.put<Ecard[]>(`${API_URL}/ecards`, ecards);
  }

  deleteEcards(ecardCodes: number[], productVariantId: number) {
    return this.http.delete<any>(`${API_URL}/ecards`, {
      body: { productVariantId, ecardCodes },
    });
  }
}
