<rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner>

<div class="auth-page">
  <div id="rw__auth-page" class="container" [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
    <div class="auth-head">
      <div class="flex align-items-center justify-content-between py-5">
        <div>
          <img src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="" height="36">
        </div>
        <div class="flex align-items-center gap-2">
          <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
      </div>
      </div>
    </div>
    <!-- smsOtpFeatureFlag -->
    <div class="auth-body" *ngIf="smsOtpFeatureFlag">
      <div *ngIf="!isVerificationCodeSent" class="flex flex-column gap-4 py-5" >
        <div class="flex flex-column gap-2">
          <label class="font-bold text-2xl mb-0">{{'Forgot Your Password?' | translate}}</label>
          <p class="font-normal text-lg mb-0">{{'resetPassword.itsOkay' | translate}}</p>
          <p class="font-normal text-lg mb-0">{{'resetPassword.enterEmail' | translate}}</p>
        </div>
        <form
          [formGroup]="fGroup"
          #ngForm="ngForm"
          class="flex flex-column gap-3"
        >
          <!-- Email Input - Start -->
          <div class="mb-2">
            <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
              <i class="fa-regular fa-at"></i>
              <input pendo-tracker="rpi-v2"
                [formControl]="fGroup.get('email')"
                pInputText
                type="email"
                name="email"
                required
                placeholder="{{ 'Email' | translate }}"
              />
            </span>
            <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
              <ng-container *ngIf="email.errors.required">
                <div class="flex align-items-center mt-1">
                  <i class="fa-solid fa-circle-exclamation p-error"></i>
                  <small class="p-error block mx-1">{{ 'Email is required' | translate }}</small>
                </div>
              </ng-container>
              <ng-container *ngIf="email.errors.pattern">
                <div class="flex align-items-center mt-1">
                  <i class="fa-solid fa-circle-exclamation p-error"></i>
                  <small class="p-error block mx-1">{{ 'Not valid email' | translate }}</small>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <!-- Email Input - End -->

          <!-- Submit - Start -->
          <button
            pendo-tracker="rpb-1-v2"
            pButton pRipple
            type="button"
            class="flex align-items-center justify-content-center w-full"
            (click)="startResetPassword(fGroup.value)"
            [disabled]="fGroup.invalid || isShowSpinner || userEmail === ''"
          >
            <!-- TODO for Dev: Handle the spinner functionality -->
            <!-- <p-progressSpinner [style]="{ width: '24px', height: '24px' }" 
              styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner> -->
            <span class="text-base">{{'resetPassword.sendOtp' | translate}}</span>
          </button>
          <!-- Submit - End -->

          <!-- Back to Sign In - Start -->
          <button pendo-tracker="rpb-v2"
            pButton pRipple
            class="flex align-items-center justify-content-center w-full p-button-text p-button-plain text-grey-90"
            [routerLink]="['/login']"
          >
            <i 
                class="fa-regular rw__me-3"
                [ngClass]="{
                    'fa-chevron-left': lang==='en',
                    'fa-chevron-right': lang==='ar',
                }"
            ></i>
            <span>{{'resetPassword.backToSignIn' | translate}}</span>
          </button>
          <!-- Back to Sign In - Start -->
        </form>

        <!-- Need Help - Start -->
        <div class="text-center">
          <span class="text-base text-grey-50">
            {{'needHelp' | translate}}
            <a (click)="startIntercom()" class="rw__link text-teal-60 cursor-pointer" pendo-tracker="lb-1-v2">{{ 'Contact Support' | translate }}</a>
          </span>
        </div>
        <!-- Need Help - End -->
      </div>

      <ng-container *ngIf="isVerificationCodeSent">
        <rw-reset-new-password-v2
          [userEmail]="userEmail"
          [userPhone]="userPhone"
          [message]="message"
          [resendConfirmationCode]="resendConfirmationCode"
          [sendToEmailButtonVisibility]= "sendToEmailButtonVisibility"
        ></rw-reset-new-password-v2>
      </ng-container>
    </div>

    <!-- !smsOtpFeatureFlag -->
    <div class="auth-body" *ngIf="!smsOtpFeatureFlag">
      <div *ngIf="!isVerificationCodeSent" class="flex flex-column gap-4 py-5" >
        <div class="flex flex-column gap-2">
          <label class="font-bold text-2xl mb-0">{{'Forgot Your Password?' | translate}}</label>
          <p class="font-normal text-lg mb-0">It's okay, we all forget passwords...</p>
          <p class="font-normal text-lg mb-0">Enter your email, and we'll send you an OTP to verify that it's really you 🧐</p>
        </div>
        <form
          [formGroup]="fGroup"
          #ngForm="ngForm"
          class="flex flex-column gap-3"
        >
          <!-- Email Input - Start -->
          <div class="mb-2">
            <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
              <i class="fa-regular fa-at"></i>
              <input
                formControlName="email"
                pInputText
                type="email"
                id="email"
                placeholder="{{ 'Email' | translate }}"
              />
            </span>
            <ng-container *ngIf="email.touched && email.invalid">
              <div class="flex align-items-center mt-1">
                <i class="fa-solid fa-circle-exclamation p-error"></i>
                <small class="p-error block mx-1">{{ 'Email is required' | translate }}</small>
              </div>
            </ng-container>
          </div>
          <!-- Email Input - End -->

          <!-- Submit - Start -->
          <button
            pButton pRipple
            type="button"
            class="flex align-items-center justify-content-center w-full"
            (click)="startResetPasswordV1(fGroup.value)"
            [disabled]="fGroup.invalid || isShowSpinner"
          >
            <!-- TODO for Dev: Handle the spinner functionality -->
            <!-- <p-progressSpinner [style]="{ width: '24px', height: '24px' }" 
              styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner> -->
            <span class="text-base">{{ 'Send OTP' | translate }}</span>
          </button>
          <!-- Submit - End -->

          <!-- Back to Sign In - Start -->
          <button
            pButton pRipple
            class="flex align-items-center justify-content-center w-full p-button-text p-button-plain text-grey-90"
            [routerLink]="['/login']"
          >
            <i 
                class="fa-regular rw__me-3"
                [ngClass]="{
                    'fa-chevron-left': lang==='en',
                    'fa-chevron-right': lang==='ar',
                }"
            ></i>
            <span>{{ 'Back to sign in' | translate }}</span>
          </button>
          <!-- Back to Sign In - Start -->
        </form>
        <!-- Need Help - Start -->
        <div class="text-center">
          <span class="text-base text-grey-50">
            {{'Need help recovering your account?' | translate}}
            <a (click)="startIntercom()" class="rw__link text-teal-60 cursor-pointer" pendo-tracker="lb-2-v2">{{ 'Contact Support' | translate }}</a>
          </span>
        </div>
        <!-- Need Help - End -->
      </div>

      <ng-container *ngIf="isVerificationCodeSent">
        <rw-reset-new-password-v2
          [userEmail]="userEmail"
        ></rw-reset-new-password-v2>
      </ng-container>
    </div>

  </div>
</div>