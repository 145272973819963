import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserPriceConfigurationState, selectAll, userPriceConfigStateKey } from '../reducers/price-configuration.reducer';

export const selectUserPriceConfigState = createFeatureSelector<UserPriceConfigurationState>(
  userPriceConfigStateKey,
);
export const getAllUserPriceConfigs = createSelector(selectUserPriceConfigState, selectAll);
export const getUserPriceConfig = () => createSelector(
  getAllUserPriceConfigs,
  (priceConfigs) => ((priceConfigs.length
    && priceConfigs.length > 0) ? priceConfigs[0] : undefined),
);

export const arePriceConfigurationLoaded = createSelector(
  selectUserPriceConfigState,
  (state) => state.allPriceConfigurationLoaded,
);
