import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChildVariant, Product } from 'src/app/inventory/model/product';
import { ProductVariantStockLocation } from 'src/app/shared/model/ProductVariantStockLocation';
import { isDefined } from 'src/app/utils';
import { INVOICE_TYPES, VariantTypes } from '../../../shared/constants';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { InvoicesCalculator } from '../../utilities/invoices.calculator';
import { TransferStockHelperService } from './transfer-stock-helper.service';

@Injectable()
export class RemoveRejectedService {
  rejectedVariants = [];

  areRejectedVariantsValid = true;

  transferInvoice: any;

  transferStockId: number;

  variantsStockValues: any;

  constructor(
    private translate: TranslateService,
    private toaster: CustomToastService,
    private transferStockHelperService: TransferStockHelperService,
  ) {}

  createTransactions({ transferInvoice, variantsStockValues }) {
    this.transferInvoice = transferInvoice;
    this.transferStockId = transferInvoice.id;
    this.variantsStockValues = variantsStockValues;
    this.prepareRejectedVariants();
    if (this.areRejectedVariantsValid) {
      return this.generateTransactions();
    }
    return null;
  }

  prepareRejectedVariants() {
    this.rejectedVariants = [];
    this.transferInvoice.VariantToInvoices.forEach((variant) => {
      if (variant.transferQuantity - variant.quantity) {
        const extraDetails = { ...this.addStockDetailsToVariant(variant) };
        const VariantToInvoiceTracks = variant.VariantToInvoiceTracks
          .filter((track) => track.transferQuantity > track.quantity);
        const rejectVariant = {
          ...variant.ProductVariant,
          ...extraDetails,
          VariantToInvoiceTracks,
        };
        this.rejectedVariants.push(rejectVariant);
      }
    });
    this.checkRejectedVariants();
  }

  handleCompositePackageWithZeroCost(variantToPackage, locationId) {
    if (!Array.isArray(variantToPackage) || variantToPackage.length === 0) return 0;
    const basePackageVariant = variantToPackage[0];
    const baseVariantLocation = basePackageVariant?.ProductVariant.ProductVariantToStockLocations.find((location) => location.stockLocationId === locationId);
    const packageRate = basePackageVariant?.rate;
    return baseVariantLocation?.cost * packageRate;
  }

  getCompositeCost(product: Product, locationId: number) {
    const compositeCost = product?.VariantToComposites?.reduce((sum: any, childVariant: ChildVariant): number => {
      let variantLocation: ProductVariantStockLocation = childVariant.ProductVariant
        .ProductVariantToStockLocations
        .find((stockLocation: ProductVariantStockLocation) => stockLocation.stockLocationId === +locationId);

      if (!variantLocation) {
        [variantLocation] = childVariant.ProductVariant
          .ProductVariantToStockLocations;
      }

      let { cost } = variantLocation;
      if (childVariant.ProductVariant.type === VariantTypes.Package) {
        cost = variantLocation.cost || variantLocation.initialCost;
        const isPackageCostFalsy = !cost;
        const variantToPackage = childVariant?.ProductVariant?.VariantToPackages;
        cost = isPackageCostFalsy ? this.handleCompositePackageWithZeroCost(variantToPackage, locationId) : cost;
      }
      const costForRate: number = variantLocation && childVariant.rate
        ? InvoicesCalculator.multiply(cost, childVariant.rate) : 0;
      if (variantLocation) {
        return (isDefined(sum) ? InvoicesCalculator.add(sum, costForRate) : costForRate);
      }
      return 0;
    }, 0);
    return compositeCost || 0;
  }

  getVariantStockLocation(variantDetails) {
    if (variantDetails.type === VariantTypes.Package) { return this.transferStockHelperService.getPackageStockLocation(variantDetails, this.transferInvoice.stockLocationId); }

    return variantDetails.ProductVariantToStockLocations
      .find((l) => l.stockLocationId === this.transferInvoice.stockLocationId);
  }

  addStockDetailsToVariant(variant) {
    const variantDetails = this.getVariantStockDetails(variant.productVariantId);
    let variantQuantity = 0;
    let variantCost = 0;
    const stockLocation = this.getVariantStockLocation(variantDetails);
    if (variant?.type === VariantTypes.Composite) {
      variantQuantity = this.transferStockHelperService.getCompositeStockLocation(variantDetails.Product, this.transferInvoice.stockLocationId);
      variantCost = this.getCompositeCost(variantDetails.Product, this.transferInvoice.stockLocationId);
    } else {
      variantQuantity = stockLocation?.quantity;
      variantCost = stockLocation?.cost;
    }

    return this.calculateExtraDetails(variant, variantQuantity, variantCost, stockLocation, variantDetails);
  }

  calculateExtraDetails(variant, variantQuantity, variantCost, stockLocation, variantDetails) {
    const extraDetails = {
      productName: variant.name,
      quantity: variantQuantity,
      buyPrice: stockLocation.buyPrice,
      'Remove Cost (Tax Exclusive)': variantCost,
      'Remove Cost (Tax Inclusive)': InvoicesCalculator.add(variantCost, InvoicesCalculator.multiply(variantCost, InvoicesCalculator.divide(stockLocation.Tax.rate, 100))),
      'Tax Code': stockLocation.Tax.id,
      'Remove Quantity': InvoicesCalculator.subtract(variant.transferQuantity, variant.quantity),
      selectedOption: stockLocation.Tax.id,
      Tax: stockLocation.Tax,
      Product: variantDetails.Product,
      ProductVariantToStockLocations: variantDetails.ProductVariantToStockLocations,
      total: 0,
      taxAmount: 0,
    };

    extraDetails.total = InvoicesCalculator.multiply(extraDetails['Remove Cost (Tax Exclusive)'], extraDetails['Remove Quantity']);
    extraDetails.taxAmount = InvoicesCalculator.multiply(extraDetails.total, InvoicesCalculator.divide(stockLocation.Tax.rate, 100));
    return extraDetails;
  }

  getVariantStockDetails(id) {
    const variant = this.variantsStockValues.find((v) => v.id === id);
    return variant;
  }

  checkRejectedVariants() {
    this.rejectedVariants.forEach((variant) => {
      if (variant['Remove Quantity'] > variant.quantity) {
        this.errorMsg('Available quantity is less than', { rejectedQuantity: variant['Remove Quantity'] });
        this.areRejectedVariantsValid = false;
      }
      if (variant['Remove Cost (Tax Exclusive)'] <= 0) {
        this.errorMsg("Can't remove product with zero cost");
        this.areRejectedVariantsValid = false;
      }
    });
  }

  errorMsg(msg: string, interpolateParams?: Object) {
    this.toaster.error(
      this.translate.instant(msg, interpolateParams),

    );
  }

  generateTransactions() {
    return {
      ...this.mapToRejectVariantsForm(),
      transferStockId: this.transferStockId,
    };
  }

  mapToRejectVariantsForm() {
    const {
      stockLocationId,
      stockLocationName,
    } = this.transferInvoice;

    const deleteProductsForm = {
      stockLocationId,
      stockLocationName,
      status: 'Completed',
      notes: '',
      type: INVOICE_TYPES.REMOVE_STOCK,
      // updating total pricing details at the end of the method
      subTotalTaxExclusive: 0,
      totalTaxInclusive: 0,
      totalTax: 0,
      completeDate: new Date(),
      VariantToInvoices: this.mapVariantsToRejectForm(),
    };
    const {
      subTotalTaxExclusive,
      totalTaxInclusive,
      totalTax,
    } = this.getTotalPricing(deleteProductsForm);

    deleteProductsForm.subTotalTaxExclusive = subTotalTaxExclusive;
    deleteProductsForm.totalTaxInclusive = totalTaxInclusive;
    deleteProductsForm.totalTax = totalTax;

    return deleteProductsForm;
  }

  mapVariantsToRejectForm() {
    const variants = [];
    this.rejectedVariants.forEach((variant) => {
      variants.push({
        sku: variant.sku,
        name: variant.name,
        quantity: variant['Remove Quantity'],
        totalExclusive: variant.total,
        buyPriceExclusive: variant.buyPrice,
        availableLocationQuantity: variant.quantity,
        productVariantId: variant.id,
        type: variant.type,
        taxAmount: variant.taxAmount,
        VariantToInvoiceTracks: variant.VariantToInvoiceTracks.map((v2it) => ({
          ...v2it,
          quantity: v2it.transferQuantity - v2it.quantity, // adjust remove quantity
        })),
        Tax: variant.Tax,
        taxJson: JSON.stringify(variant.Tax),
        costExclusive: variant['Remove Cost (Tax Exclusive)'],
        costInclusive: variant['Remove Cost (Tax Inclusive)'],
      });
    });
    return variants;
  }

  getTotalPricing(deleteForm) {
    let subTotalTaxExclusive = 0;
    let totalTax = 0;
    deleteForm.VariantToInvoices.forEach((invoice) => {
      subTotalTaxExclusive += invoice.totalExclusive;
      totalTax += invoice.taxAmount;
    });
    const totalTaxInclusive = InvoicesCalculator.add(subTotalTaxExclusive, totalTax);
    return ({ subTotalTaxExclusive, totalTaxInclusive, totalTax });
  }
}
