import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  WebSocketAuth,
  WEB_SOCKET_WORKER_ACTIONS,
  LocalStorageKey,
} from '../../shared/constants';
import { LocalStorageService } from './local-storage.service';
import { AuthUser } from '../../auth/auth.models';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';

@Injectable()
export class WebSocketService {
  wsObservable: Subject<any>;

  url: string;

  socketWorker: Worker;

  constructor(
    private localStorageService: LocalStorageService,
    private userAuthenticationService: UserAuthenticationService,
  ) {
    this.url = environment.webSocketUrl;
    this.wsObservable = new Subject();
    this.socketWorker = new Worker(
      '../../../assets/scripts/web-socket.worker.js',
    );
    this.userAuthenticationService.getAuthorizationToken().then((idToken) => {
      this.socketWorker.postMessage([
        {
          action: WEB_SOCKET_WORKER_ACTIONS.INITIALISE,
          data: {
            url: environment.webSocketUrl,
            email: this.localStorageService.getItem<AuthUser>(
              LocalStorageKey.User,
            )?.email,
            token: idToken,
          },
        },
      ]);
      this.socketWorker.onmessage = this.onMessage;
    });
  }

  private onMessage = (event: MessageEvent) => {
    this.wsObservable.next(event.data);
  };

  connect = async (): Promise<void> => {
    const token = await this.userAuthenticationService.getAuthorizationToken();
    this.setAuthDetails({
      email: this.localStorageService.getItem<AuthUser>(LocalStorageKey.User)
        ?.email,
      token,
    });
    this.socketWorker.postMessage([
      {
        action: WEB_SOCKET_WORKER_ACTIONS.CONNECT,
      },
    ]);
  };

  disconnect = () => {
    this.socketWorker.postMessage([
      {
        action: WEB_SOCKET_WORKER_ACTIONS.DISCONNECT,
      },
    ]);
  };

  send = <T>(data: T, retry: boolean = true) => {
    this.socketWorker.postMessage([
      {
        action: WEB_SOCKET_WORKER_ACTIONS.SEND,
        data,
        retry,
      },
    ]);
  };

  startPingPong = () => {
    this.socketWorker.postMessage([
      {
        action: WEB_SOCKET_WORKER_ACTIONS.START_PING_PONG,
      },
    ]);
  };

  stopPingPong = () => {
    this.socketWorker.postMessage([
      {
        action: WEB_SOCKET_WORKER_ACTIONS.STOP_PING_PONG,
      },
    ]);
  };

  destroy = () => {
    this.socketWorker.postMessage([
      {
        action: WEB_SOCKET_WORKER_ACTIONS.DESTROY,
      },
    ]);
  };

  setAuthDetails = (data: WebSocketAuth) => {
    this.socketWorker.postMessage([
      {
        action: WEB_SOCKET_WORKER_ACTIONS.SET_AUTH_DETAILS,
        data,
      },
    ]);
  };
}
