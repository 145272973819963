import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FormFileUploadUtility {
  dragOver: boolean;

  file: any;

  constructor() {
    this.resetFiles();
  }

  resetFiles() {
    this.file = null;
  }

  onUploadOutput(output: any): void {
    const [file] = output?.target?.files || [];
    this.file = file;
    if (output.type === 'removed') {
      // remove file from array when removed
      this.file = null;
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
    this.showFiles();
  }

  /** Return distinct message for sent, upload progress, & response events */
  getEventMessage(event: HttpEvent<any>, _file: File): {
    message: string; percentageDone?: number; done?: boolean
  } {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          message: `Uploading file "${this.file.name}" of size ${this.file.size}.`,
        };
        break;
      case HttpEventType.UploadProgress:
        // eslint-disable-next-line no-case-declarations
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return {
          message: `File "${this.file.name}" is ${percentDone}% uploaded.`,
          percentageDone: percentDone,
        };
        break;
      case HttpEventType.Response:
        return {
          message: `File "${this.file.name}" was completely uploaded!`,
          done: true,
        };

        break;
      default:
        return {
          message: `File "${this.file.name}" surprising upload event: ${event.type}.`,
        };
    }
  }

  showFiles() {
    return this.file ? this.file.name : '';
  }
}
