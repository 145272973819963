import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AppChannelSubscription } from 'src/app/shared/model/AppChannelSubscription';
import { AppActions } from '../app.action-types';

export interface AppChannelState extends EntityState<AppChannelSubscription> {
  areAppsLoaded: boolean;
  areSubscribedAppsLoaded: boolean;
}

export const adapter = createEntityAdapter<AppChannelSubscription>();

export const initialSuppliersState = adapter.getInitialState();

export const reducer = createReducer(
  initialSuppliersState,

  on(AppActions.subscribedAppsLoaded, (state, action) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    const updatedStates = Object.keys(state.entities).map((id) => {
      const app = action.apps.find(
        (_subscribedApp) => _subscribedApp.id === +id,
      );
      if (app) {
        return {
          ...app,
          isSubscribed : true ,
        };
      }
      return {
        ...state.entities[id],
        isSubscribed: false,
      };
    });
    return adapter.upsertMany(updatedStates, {
      ...state,
      areSubscribedAppsLoaded: true,
    });
  }),

  on(AppActions.allAppsLoaded, (state, action) => adapter.upsertMany(action.apps, {
    ...state,
    areAppsLoaded: true,
  })),

  on(AppActions.loadPublisheddVariantsSuccess, (state, action) =>
    // adapter.updateOne()
    // eslint-disable-next-line implicit-arrow-linebreak
    adapter.updateOne(action.variants, {
      ...state,
      products: action.variants,
    })),
);

export const { selectAll } = adapter.getSelectors();

export function appsReducer(state: AppChannelState | undefined, action: Action) {
  return reducer(state, action);
}
