import { CalculationService } from '../../services/calculation-service';

import {
  PosTaxTypes,
  PosInvoiceTypes,
  PosInvoiceEnums,
  ProductEnums,
  ProductTypes,
  TaxType,
} from '@rewaa-team/types';

export class PosInvoiceCalculationService extends CalculationService {
  calculateSubtotalTaxExclusive = (
    variantToInvoices: PosInvoiceTypes.VariantToInvoice[] = [],
  ): number => {
    const subtotalWithoutExtras = variantToInvoices.reduce(
      (tax, variant) => this.add(tax, variant.totalExclusive),
      0,
    );
    return this.add(
      subtotalWithoutExtras,
      this.calculateExtrasExclusivePrice(variantToInvoices),
    );
  };

  calculateTotalTax = (
    variantToInvoices: PosInvoiceTypes.VariantToInvoice[] = [],
  ): number => {
    const totalTaxWithoutExtras = variantToInvoices.reduce(
      (tax, variant) => this.add(tax, variant.taxAmount),
      0,
    );
    return this.add(
      totalTaxWithoutExtras,
      this.calculateExtrasTotalTaxes(variantToInvoices),
    );
  };

  calculateExtrasExclusivePrice = (
    variantsToInvoice: PosInvoiceTypes.VariantToInvoice[] = [],
  ) => {
    let totalExtraPrice = 0;
    variantsToInvoice.forEach((variantToInvoice) => {
      const { VariantToInvoiceExtras: variantToInvoiceExtras = [] } =
        variantToInvoice;
      variantToInvoiceExtras.forEach((invoiceExtra) => {
        const price =
          invoiceExtra.totalExclusive ??
          this.multiply(invoiceExtra.quantity, invoiceExtra.price);
        totalExtraPrice = this.add(totalExtraPrice, price);
      });
    });
    return totalExtraPrice;
  };

  calculateExtrasTotalTaxes = (
    variantsToInvoice: PosInvoiceTypes.VariantToInvoice[] = [],
  ) => {
    let totalExtrasTaxes = 0;
    variantsToInvoice.forEach((variantToInvoice) => {
      const { VariantToInvoiceExtras: variantToInvoiceExtras = [] } =
        variantToInvoice;
      variantToInvoiceExtras.forEach((invoiceExtra) => {
        const taxAmount =
          invoiceExtra.taxAfterDiscount ?? invoiceExtra.taxAmount;
        totalExtrasTaxes = this.add(
          totalExtrasTaxes,
          this.multiply(invoiceExtra.quantity, taxAmount),
        );
      });
    });
    return totalExtrasTaxes;
  };

  calculateProductCostExclusive = (
    taxation: TaxType,
    product: PosInvoiceTypes.SellProduct,
    taxRate: number,
    priceProp: 'price' | 'initPrice' = 'price',
  ) => {
    return taxation === TaxType.Exclusive
      ? product[priceProp] || 0
      : this.divide(product[priceProp] || 0, this.add(1 + taxRate, 0));
  };

  calculateProductCostInclusive = (
    taxation: TaxType,
    product: PosInvoiceTypes.SellProduct,
    taxRate: number,
    priceProp: 'price' | 'initPrice' = 'price',
  ) => {
    return taxation === TaxType.Exclusive
      ? this.multiply(product[priceProp] || 0, this.add(1, taxRate))
      : product[priceProp] || 0;
  };

  calculateProductTotalExclusive = (
    taxation: TaxType,
    product: PosInvoiceTypes.SellProduct,
    taxRate: number,
    discountRate = 0,
  ) => {
    const costExclusive = this.calculateProductCostExclusive(
      taxation,
      product,
      taxRate,
    );
    const initPriceExclusive = product.discountPercentage
      ? costExclusive
      : this.calculateProductCostExclusive(
          taxation,
          product,
          taxRate,
          'initPrice',
        );
    /**
     * product.quantity is used for backwards compatibility
     * because mobile doesn't send promotionQuantity
     */
    const promotionQuantity = product.promotion
      ? product.promotionQuantity || product.quantity
      : 0;
    const promotionPrice = this.multiply(costExclusive, promotionQuantity);
    const withoutPromotionPrice = this.multiply(
      initPriceExclusive || costExclusive,
      product.quantity - promotionQuantity,
    );
    let totalCostExclusiveWithoutDiscount = this.add(
      promotionPrice,
      withoutPromotionPrice,
    );
    return this.multiply(
      this.subtract(1, discountRate),
      totalCostExclusiveWithoutDiscount,
    );
  };

  calculateProductTotalInclusive = (
    taxation: TaxType,
    product: PosInvoiceTypes.SellProduct,
    taxRate: number,
    discountRate = 0,
  ) => {
    const costInclusive = this.calculateProductCostInclusive(
      taxation,
      product,
      taxRate,
    );
    const initPriceInclusive = product.discountPercentage
      ? costInclusive
      : this.calculateProductCostInclusive(
          taxation,
          product,
          taxRate,
          'initPrice',
        );
    const promotionQuantity = product.promotion
      ? product.promotionQuantity || product.quantity
      : 0;
    const promotionPrice = this.multiply(costInclusive, promotionQuantity);
    const withoutPromotionPrice = this.multiply(
      initPriceInclusive || costInclusive,
      product.quantity - promotionQuantity,
    );
    let totalCostInclusiveWithoutDiscount = this.add(
      promotionPrice,
      withoutPromotionPrice,
    );
    return this.multiply(
      this.subtract(1, discountRate),
      totalCostInclusiveWithoutDiscount,
    );
  };

  calculateExclusiveExtraPrice = (
    taxation: TaxType,
    price: number,
    taxRate: number,
  ) => {
    if (taxation === TaxType.Exclusive) {
      return price;
    }
    return this.divide(price, this.add(1, taxRate));
  };

  calculateDiscountRateForProduct = (
    subtotal = 0,
    totalTax = 0,
    taxation: TaxType,
    invoiceDiscountAmount = 0,
    invoiceDiscountType?: PosInvoiceEnums.DiscountType,
  ) => {
    if (invoiceDiscountAmount > 0) {
      if (taxation === TaxType.Exclusive) {
        if (invoiceDiscountType === PosInvoiceEnums.DiscountType.Percentage) {
          return this.divide(invoiceDiscountAmount, 100);
        }
        if (invoiceDiscountType === PosInvoiceEnums.DiscountType.Fixed) {
          return this.divide(invoiceDiscountAmount, subtotal);
        }
      } else if (taxation === TaxType.Inclusive) {
        if (invoiceDiscountType === PosInvoiceEnums.DiscountType.Percentage) {
          return this.divide(invoiceDiscountAmount, 100);
        }
        if (invoiceDiscountType === PosInvoiceEnums.DiscountType.Fixed) {
          return this.divide(
            invoiceDiscountAmount,
            this.add(subtotal, totalTax),
          );
        }
      }
    }
    return 0;
  };

  calculateCompositePrice = (
    variant: ProductTypes.Variant,
    prop: keyof ProductTypes.ProductVariantToStockLocation = 'retailPrice',
  ): number =>
    variant.children?.reduce(
      (price: number, c: ProductTypes.CompositeChildVariant) => {
        const [child] = c.VariantToComposites!;
        return this.add(
          price,
          this.multiply(
            child.rate || 1,
            this.calculateVariantPrice(c, prop) || 0,
          ),
        );
      },
      0,
    ) || 0;

  calculateVariantPrice = (
    variant: ProductTypes.Variant,
    prop: keyof ProductTypes.ProductVariantToStockLocation = 'retailPrice',
  ): number => {
    if (variant.type === ProductEnums.VariantType.Composite)
      return this.calculateCompositePrice(variant, prop);
    const [stock] = variant.ProductVariantToStockLocations || [];
    return +stock[prop]!;
  };

  getTaxConfig = (
    priceConfiguration: PosTaxTypes.TaxConfiguration,
    prop: keyof ProductTypes.ProductVariantToStockLocation = 'retailPrice',
  ) =>
    prop === 'cost'
      ? priceConfiguration.costTaxation || priceConfiguration.costTaxStatus
      : priceConfiguration.sellTaxation || priceConfiguration.sellTaxStatus;

  calculateExclusivePrice = (
    variant: ProductTypes.Variant,
    priceConfiguration: PosTaxTypes.TaxConfiguration,
    prop: keyof ProductTypes.ProductVariantToStockLocation = 'retailPrice',
  ) => {
    const price = this.calculateVariantPrice(variant, prop);

    return this.calculateExclusive(price, variant, priceConfiguration, prop);
  };

  calculateExclusive = (
    price: number,
    variant: ProductTypes.Variant,
    priceConfiguration: PosTaxTypes.TaxConfiguration,
    prop: keyof ProductTypes.ProductVariantToStockLocation = 'retailPrice',
  ) => {
    const [stock] = variant.ProductVariantToStockLocations || [];
    return this.getTaxConfig(priceConfiguration, prop) === TaxType.Inclusive
      ? this.divide(price, this.add(1, this.divide(stock.Tax?.rate || 0, 100)))
      : price;
  };

  getVariantQuantity = (
    variant: ProductTypes.Variant,
    includedInfinity?: boolean,
  ) => {
    let quantity = 0;
    const [stock] = variant.ProductVariantToStockLocations!;
    if (!stock) return quantity;

    if (variant.type === ProductEnums.VariantType.Composite) {
      quantity = variant.children
        ? this.calculateMaxToSell(variant.children)
        : quantity;
    } else if (variant.type === ProductEnums.VariantType.Package) {
      let [child] = variant.children || [];
      let pack =
        child && child.VariantToPackages
          ? child.VariantToPackages[0]
          : undefined;

      if (variant.VariantToPackages) {
        pack = variant.VariantToPackages[0];
        child = child || pack.ProductVariant;
      }

      quantity = pack
        ? this.divide(this.getVariantQuantity(child), pack.rate)
        : quantity;
    } else {
      quantity = this.getAvailableLocationQuantity(variant, includedInfinity);
    }

    return quantity;
  };

  calculateMaxToSell = (childVariants: ProductTypes.CompositeChildVariant[]) =>
    childVariants.reduce((min, child) => {
      const [childVariant] = child.VariantToComposites!;
      const quantity = this.getVariantQuantity(child, true);
      if (quantity === null || quantity === undefined) return min;
      return min && childVariant.rate
        ? Math.floor(Math.min(min, this.divide(quantity, childVariant.rate)))
        : Math.floor(this.divide(quantity, childVariant.rate));
    }, 0);

  getAvailableLocationQuantity = (
    variant: ProductTypes.Variant,
    includedInfinity = false,
  ) => {
    if (!variant) return 0;
    if (variant && !variant.manageStockLevel)
      return includedInfinity ? Number.POSITIVE_INFINITY : 0;

    const [stock] = variant.ProductVariantToStockLocations!;

    if (variant.trackType && stock.VariantToTracks) {
      return stock.VariantToTracks.reduce(
        (sum, t) => this.add(sum, Math.max(0, t.quantity)),
        0,
      );
    }

    return stock.quantity;
  };

  getExtrasTotalPrice = (
    variantToInvoiceExtras: PosInvoiceTypes.VariantToInvoiceExtra[] = [],
  ) =>
    variantToInvoiceExtras?.reduce(
      (total, extra) => total + extra.price * extra.quantity,
      0,
    );
}

export const posInvoiceCalculationService = new PosInvoiceCalculationService(8);
