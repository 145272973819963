<div class="print-barcode-container" [ngClass]="langCss">
    <div class="flex flex-row check-scroll">
        <div class="col-6 column-height" [formGroup]="form">
            <div class="spacing-container flex-column">
                <div class="title">
                        {{ 'Print Barcode' | translate}}
                </div>
                <div>
                    <span class="p-float-label">
                        <p-dropdown  [autoDisplayFirst]="false" [showClear]="showVariantsClearOption()" inputId="dropdown" (onChange)="removeOrUpdateVariants($event)" 
                        [options]="(variantsToShowInDropdown!=null) ? variantsToShowInDropdown:variants" optionValue="name" optionLabel="name" [style]="{'width':'350px'}" 
                        formControlName="currentVariantName"></p-dropdown>
                        <label  for="dropdown">{{'Selected Products' | translate }}</label>
                    </span>
                </div>
                <div>
                    <p class="labels">{{'Code' | translate}}</p>
                    <div class="flex-row">
                        <p-radioButton class="col-6" name="code" value="sku" [label]="'SKU' | translate" formControlName="code"></p-radioButton> 
                        <p-radioButton class="col-6" name="code" value="barcode" [label]="'Barcode' | translate" formControlName="code"></p-radioButton>    
                    </div>
                </div>
                <div>
                    <p class="labels">{{'Price type' | translate}}</p>
                    <div class="flex-row">
                        <p-radioButton class="col-6" name="priceType" value="retailPrice" [label]="'Retail price' | translate" formControlName="priceType"></p-radioButton> 
                        <p-radioButton class="col-6" name="priceType" value="wholeSalePrice" [label]="'Wholesale price' | translate" formControlName="priceType"></p-radioButton>    
                    </div>
                </div>

                <div>
                    <div class = 'location-selector' *ngIf="locations$ | async as locations">
                        <span class="p-float-label">
                            <p-dropdown [autoDisplayFirst]="false" [showClear]="showLocationClearOption()" inputId="dropdown" (onChange)="removeOrUpdateLocations($event)"
                            [options]="stockLocations" optionValue="id" optionLabel="name" [style]="{'width':'350px'}" formControlName="stockLocationId"></p-dropdown>
                            <label  for="dropdown">{{ 'Locations' | translate }}</label>
                        </span>
                    </div>
                </div>
                
                <div>
                    <span class="p-float-label">
                        <p-inputNumber [showButtons]="true" inputId="inputnumber" formControlName="copy" [style]="{'width':'350px','text-align':'left'}"></p-inputNumber>
                        <label for="inputnumber">{{'Copies' | translate }}</label>
                    </span>
                    <rw-input-error *ngIf="InValidNoOfCopies"
                    [text]="'Minimum value should be 1'|translate"></rw-input-error>
                </div>
                <div class="grid flex-column print-options-container spacing-print-options">
                    <p-checkbox class = "flex" name="print" binary="true" [label]="'Include company name' | translate" formControlName="includeCompany"></p-checkbox> 
                    <p-checkbox class = "flex" name="print" binary="true" [label]="'Include product name' | translate"  formControlName="includeName"></p-checkbox> 
                    <p-checkbox class = "flex" name="print" binary="true" [label]="'Include product price' | translate"  formControlName="includePrice"></p-checkbox> 
                    <p-checkbox class = "flex" name="print" binary="true" [label]="'Include Tax Type' | translate"  formControlName="includeTaxType"></p-checkbox> 
                    <p-checkbox class = "flex" name="print" binary="true" [label]="'Barcode' | translate" formControlName="includeBarcode"></p-checkbox> 
                </div>
            </div>
        </div>
  
        <div class="col-6 print-panel column-height">
            <div class="flex-column">
                <div class="print-preview"> 
                    {{'Print Preview'|translate}}
                </div>
                <div class="size-panel" >
                    <button pendo-tracker="pbb"  type="button" pButton class="p-button-outlined size-select-button"
                    (click)="setSize('STANDARD')" [label]="'Standard Size' | translate" id="standard-button"></button>
                    <button pendo-tracker="pbb-1"  type="button" pButton class="p-button-outlined size-select-button"
                    (click)="setSize('LARGE')" [label]="'Large Size' | translate" id="large-button"></button>
                </div>
                <rw-generate-barcode [variantInfoArray]="variantInfoArray" [printFeatures]="printFeatures" [type]="type" [companyName]="user?.companyName" [taxStatus]="this.taxStatus" ></rw-generate-barcode>
            </div>            
        </div>
    </div>
    <hr class="header"/>
    <div class="buttons grid justify-content-end position-sticky" autofocus>
        <div class="print-button">
            <button pendo-tracker="pbb-2" pButton class="btn p-button-text" (click)="close()">{{ 'Cancel' | translate }}</button>
            <button pendo-tracker="pbb-3" pButton class="btn m-2" [disabled]="!form.valid" (mouseup)="printBarcode()">
                {{ 'Print' | translate }}</button>
        </div>
    </div>
</div>
