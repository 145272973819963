import { IndexableType, WhereClause, Collection } from 'dexie';

export type WhereParams =
  | (string | string[])
  | { [key: string]: IndexableType };
export type WhereResponse<T> = T extends string | string[]
  ? WhereClause
  : Collection;
export type Operation = {
  propertyName: string;
  parameters: any[];
};

export const enum TableName {
  /**
   * @deprecated This table is removed in version 9
   */
  Variants = 'variants',
  /**
   * @deprecated This table is removed in version 13
   */
  Customers = 'customers',
  OfflineCustomers = 'offlineCustomers',
  Config = 'config',
  /**
   * @deprecated This table will be removed soon
   */
  Invoices = 'invoices',
  ParkedInvoices = 'parkedInvoices',
  OfflineProductVariants = 'offlineProductVariants',
  /**
   * @deprecated Old Pos Sales, the one before Pos Reborn
   */
  Sell = 'sell',
  OfflinePromotions = 'offlinePromotions',
  Promotion = 'promotion',
  ParkedSales = 'parkedSales',
  Sales = 'sales',
}

export enum DexieWorkerAction {
  Initialise = 'INITIALISE',
}

export interface BulkPutInTransactionInput {
  tableName: TableName;
  objects: readonly any[];
  keysOrOptions?: ReadonlyArray<IndexableType> | { allKeys?: boolean };
  options?: { allKeys?: boolean };
}
