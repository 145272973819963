<div
 class="rw__receive-debit-wrap" #container
  rwPermission
  [permission]="'customer.detail.update'"
  [action]="'route'"
>
  <div class="col-12 rw__receive-breadcrumb">
    <rw-breadcrumb></rw-breadcrumb>
  </div>

  <div class="col-12 rw__receive-debit">
    <p-toolbar>
      <ng-template pTemplate="left">
        <div class="mb-0">
          <h4  class="rw__fs-5 fw-500">{{ customer?.name }}</h4>
          <p>{{ customer?.mobileNumber }}</p>
        </div>
      </ng-template>
      <ng-template pTemplate="right"> </ng-template>
    </p-toolbar>

    <div class="flex flex-column md:flex-row">
      <div class="col-12 md:col-8 p-0">
        <p-card styleClass="p-fluid p-3 card-container">
          <p-table
            class="styleTable"
            [value]="invoices"
            [loading]="loading"
            [showCurrentPageReport]="true"
            [responsive]="true"
            [resizableColumns]="true"
            [paginator]="false"
            currentPageReportTemplate="{{ 'ShowingEntries' | translate }}"
            columnResizeMode="expand"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div
                    class="p-flex p-jc-between p-ai-center p-column-title text-capitalize"
                  >
                    {{ 'Invoice Number' | translate }}
                  </div>
                </th>
                <th>
                  <div
                    class="p-flex p-jc-between p-ai-center p-column-title text-capitalize"
                  >
                    {{ 'Total' | translate }}
                  </div>
                </th>
                <th>
                  <div
                    class="p-flex p-jc-between p-ai-center p-column-title text-capitalize"
                  >
                    {{ 'Paid' | translate }}
                  </div>
                </th>
                <th>
                  <div
                    class="p-flex p-jc-between p-ai-center p-column-title text-capitalize"
                  >
                    {{ 'Debit' | translate }}
                  </div>
                </th>
                <th>
                  <div
                    class="p-flex p-jc-between p-ai-center p-column-title text-capitalize"
                  >
                    {{ 'Payment' | translate }}
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-invoice
            >
              <tr class="p-selectable-row">
                <td>
                  <span class="p-column-title text-capitalize">
                    {{ 'Invoice Number' | translate }}</span
                  >
                  {{ invoice.invoiceNumber }}
                </td>

                <td>
                  <span class="p-column-title text-capitalize">
                    {{ 'Total' | translate }}</span
                  >
                  {{ invoice.total | number : '1.2-2' }}
                </td>

                <td>
                  <span class="p-column-title text-capitalize">
                    {{ 'Paid' | translate }}</span
                  >
                  {{ invoice.totalPaid | number : '1.2-2' }}
                </td>

                <td>
                  <span class="p-column-title text-capitalize">
                    {{ 'Debit' | translate }}</span
                  >
                  {{ invoice.debit | number : '1.2-2' }}
                </td>

                <td>
                  <span class="p-column-title text-capitalize">
                    {{ 'Payment' | translate }}</span
                  >
                  <div class="p-inputgroup">
                    <input
                      pendo-tracker="rdi-2"
                      pInputText
                      type="number"
                      class="p-inputgroup-addon"
                      [(ngModel)]="invoice.showAmount"
                      (ngModelChange)="invoice.amount = $event"
                      [ngClass]="
                        invoice.amount > invoice.debit || invoice.amount < 0
                          ? 'ng-invalid ng-dirty'
                          : ''
                      "
                    />
                    <span class="p-inputgroup-addon">{{
                      'SAR' | translate
                    }}</span>
                  </div>
                  <small
                    *ngIf="invoice.amount > invoice.debit"
                    class="p-error"
                  >
                    {{
                      'This number is greater than the required debit amount'
                        | translate
                    }}.
                  </small>
                  <small
                    *ngIf="invoice.amount < 0"
                    class="p-error"
                  >
                    {{ 'This number is greater than zero' | translate }}.
                  </small>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-card>
        <p-card styleClass="p-fluid p-3 card-container">
          <div
            class="form"
            [formGroup]="cashManagementForm"
          >
            <h6 class="rw__fs-8 font-bold">
              {{ 'Cash Management' | translate }}
            </h6>
            <div class="flex align-items-center py-4">
              <rw-toggle-switch [checked]="considerInCashManagement" (changeEmit)="toggler($event)"></rw-toggle-switch>
              <p class="rw__fs-8 ml-2 mb-2 font-para">
                {{ 'Consider in' | translate }}
                <span class="font-bold font-para">{{
                  'EN_POS' | translate
                }}</span>
                {{ 'Cash Management' | translate }}
              </p>
            </div>
            <div class="grid">
              <div class="md:col-6 form-group">
                <p-dropdown
                  formControlName="locationId"
                  class="select_withdownarrow"
                  (onChange)="onSelectedLocation($event.value)"
                  [options]="locations"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="{{ 'Location' | translate}}"
                ></p-dropdown>
              </div>
              <div class="md:col-6 form-group">
                <p-dropdown
                  formControlName="registerId"
                  class="select_withdownarrow"
                  [options]="registers"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="{{ 'POS_Register' | translate}}"
                ></p-dropdown>
              </div>

              <div class="md:col-12 form-group">
                <div class="d-flex flex-column align-items-end w-full">
                  <span class="rw__input rw__mb-0 w-full rw__mt-5">
                    <textarea
                      placeholder="{{ 'Notes' | translate }}" 
                      type="text"
                      formControlName="note"
                      class="rw__mb-0 w-full textarea-disabled"
                      pInputTextarea
                      rows="2" 
                      ></textarea>
                  </span>
                 </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>
      <div class="col-12 md:col-4 p-0">
        <p-card styleClass="p-fluid card-container">
          <div
            class="form"
            [formGroup]="recieveDebitForm"
          >
            <div *ngIf="invoices">
              <ul class="p-0">
                <li class="flex py-2">
                  <p class="col-8 rw__fs-8  mb-0">
                    {{ 'Total Debit' | translate }}
                    <span class="text-grey-60 font-para">{{
                      'Before Payment' | translate
                    }}</span>
                  </p>
                  <p class="col text-right mb-0">
                    {{ (totalDebit ? totalDebit : 0) | number : '1.2-2' }}
                  </p>
                </li>
                <li class="flex py-2">
                  <p class="col-8 font-bold mb-0 rw__fs-8">
                    {{ 'Total Payment' | translate }}
                  </p>
                  <p class="col text-right font-bold mb-0">
                    {{ (sum ? sum : 0) | number : '1.2-2' }}
                  </p>
                </li>
                <li class="flex py-2">
                  <p class="col-8 rw__fs-8 mb-0">
                    {{ 'Remaining Debit' | translate }}
                    <span class="text-grey-60 font-para">{{
                      'After
                        Payment' | translate
                    }}</span>
                  </p>
                  <p class="col text-right mb-0 remaining-debit-color">
                    {{
                      (remainingDebit ? remainingDebit : 0) | number : '1.2-2'
                    }}
                  </p>
                </li>
              </ul>
              <div class="py-4 mt-3">
                <h6 class="rw__fs-8 font-bold ml-3">
                  {{ 'Payment Method' | translate }}
                </h6>
                <ul class="list-box list-none">
                  <li
                    class="flex border-bottom-1 py-3 list-box-item"
                    *ngFor="let paymentMethod of paymentMethods"
                  >
                    <div
                      class="form-check pl-0 payment-radio"
                      [dir]="lng === 'en' ? 'ltr' : 'rtl'"
                    >
                      <input
                        pendo-tracker="rdi-3"
                        [value]="paymentMethod.id"
                        [checked]="
                          recieveDebitForm.get('paymentMethodName').value ===
                          paymentMethod.id
                        "
                        type="radio"
                        formControlName="paymentMethodName"
                        [id]="paymentMethod.id"
                      />
                      <label
                        class="form-check-label label-lenght rw__mb-0"
                        [for]="paymentMethod.id"
                      >
                        <span class="font-16 font-weight-500 ml-1">{{
                          paymentMethod.name | translate
                        }}</span>
                      </label>
                    </div>
                  </li>
                </ul>
                <div class="px-3">
                  <button
                    rwDebounceClick
                    pendo-tracker="rdb-1"
                    pButton
                    pRipple
                    [disabled]="!formValid || isButtonPressed"
                    class="rw__button rw__button-raised w-full mx-auto"
                    (debounceClick)="receiveDebit()"
                    label="{{ 'Cash Pay' | translate }} {{ (sum ? sum : 0) | number : '1.2-2' }} SAR"
                  >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </div>
  <!-- Print Receipt Voucher - Start -->
  <rw-voucher class="printable"
              [voucherData]="receiptVoucherData"
  ></rw-voucher>
  <!-- Print Receipt Voucher - End -->
</div>
