// eslint-disable-next-line check-file/folder-naming-convention
import { Injectable } from '@angular/core';
import { PromotionTypes } from '@rewaa-team/pos-sdk';
import { ConfigStoreService } from './config-store.service';
import { ConfigStoreKeys } from '../../../constants';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';
@Injectable()
export class PromotionStoreV2Service {
  constructor(private configStoreService: ConfigStoreService) {}

  private async getDB(): Promise<DatabaseService> {
    return DatabaseService.getDB();
  }

  public async save(promotions: PromotionTypes.Promotion[]): Promise<number> {
    const databaseService: DatabaseService = await this.getDB();
    const lastKey: number = await databaseService
      .getRepository(TableName.Promotion)
      .bulkPut(promotions);
    return lastKey;
  }

  public async delete(ids: number[]): Promise<void> {
    const databaseService: DatabaseService = await this.getDB();
    await databaseService.getRepository(TableName.Promotion).bulkDelete(ids);
  }

  public async deleteAll(): Promise<void> {
    const databaseService: DatabaseService = await this.getDB();
    await Promise.all([
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.PromotionV2ApiConfig,
      ),
      databaseService.getRepository(TableName.Promotion).clear(),
    ]);
  }

  public async deleteStale(): Promise<void> {
    const currentDate = new Date().toISOString();
    const databaseService: DatabaseService = await this.getDB();
    await databaseService
      .getRepository(TableName.Promotion)
      .where('endDate')
      .below(currentDate)
      .delete();
  }

  private filterActivePromos(
    promotions: PromotionTypes.Promotion[],
  ): PromotionTypes.Promotion[] {
    const currentDate = new Date();
    return promotions.filter((promo) => {
      const startDate = new Date(promo.startDate);
      if(!promo.endDate) {
        return currentDate > startDate;
      }
      const endDate = new Date(promo.endDate);
      return currentDate > startDate && endDate > currentDate;
    });
  }

  public async findActiveByItemIds(
    itemIds: number[],
  ): Promise<PromotionTypes.Promotion[]> {
    const databaseService: DatabaseService = await this.getDB();
    const promotions = await databaseService
      .getRepository(TableName.Promotion)
      .where('itemIds')
      .anyOf(itemIds)
      .distinct()
      .toArray();
    return this.filterActivePromos(promotions);
  }

  public async findActiveForAllProducts(): Promise<PromotionTypes.Promotion[]> {
    const databaseService: DatabaseService = await this.getDB();
    const promotions = await databaseService
      .getRepository(TableName.Promotion)
      .where('isAll')
      .equals(1)
      .distinct()
      .toArray();
    return this.filterActivePromos(promotions);
  }

  public async findActive(
    productIds: number[],
    categoryIds: number[],
  ): Promise<PromotionTypes.ActiveAdvancePromotion> {
    const [forAllProducts, itemSpecific] = await Promise.all([
      this.findActiveForAllProducts(),
      this.findActiveByItemIds(categoryIds.concat(productIds)),
    ]);
    return {
      productSpecific: itemSpecific.filter(
        (promotion) =>
          promotion.itemType === PromotionTypes.PromotionItemType.Product,
      ),
      categorySpecific: itemSpecific.filter(
        (promotion) =>
          promotion.itemType === PromotionTypes.PromotionItemType.Category,
      ),
      forAllProducts,
    };
  }
}
