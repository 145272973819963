<div [ngClass]="langCss" class="transfer-stock-preview">
    <div class="header p-4">
        <div class="title flex mb-2">
            <div class="mr-3">
                <div class="font-bold">{{ transferStock.invoiceNumber }}</div>
                <div class="fs-14 mt-1">{{ dateString }}</div>
            </div>
            <div class="mr-auto ml-4 mt-4">
                <span (click)="detailedView()" class="link cur-poi">
                    {{ 'Detailed View' | translate }}
                    <img class="pb-1" src="../../../../../../assets/icons/arrow-up-right-from-square-light.svg">
                </span>
            </div>
            <div>
                <span class="pi pi-times cur-poi" (click)="cancelModal()"></span>
            </div>
        </div>
        <div class="divider-line"></div>
        <div class="details flex justify-content-between my-3 pr-5">
            <div *ngIf="transferStock.status !== 'Requested'">
                <div class="mb-2 light-col font-normal">{{'Source' | translate }}</div>
                <div class="dark-col font-normal">{{ transferStock.stockLocationName }}</div>
            </div>
            <div *ngIf="transferStock.status !== 'Requested'">
                <img src="../../../../../../assets/icons/location-arrow-left.svg" class="left-arrow">
            </div>
            <div>
                <div class="mb-2 light-col font-normal">{{'Destination' | translate }}</div>
                <div class="dark-col font-normal">{{ transferStock.destinationLocationName }}</div>
            </div>
            <div class="status">
                <div class="mb-2 light-col font-normal">{{'Transfer Status' | translate }}</div>
                <div class="transfer-status text-center w-fc fs-14" [ngClass]="transferStock.status">{{ (statusMap[transferStock.status] || transferStock.status) | translate }}</div>
            </div>
            <div class="notes">
                <div class="mb-2 light-col font-normal">{{'Notes' | translate }}</div>
                <div class="dark-col font-normal">{{ transferStock.notes }}</div>
            </div>
        </div>
    </div>
    <div class="divider-line"></div>
    <div *ngIf="transferStock.status === 'Pending'">
        <form [formGroup]="pendingForm" class="px-4 mt-3">
            <div class="flex flex-column align-items-start">
                <div class="mb-2 font-normal">{{ 'Receiving Date' | translate }}</div>
                <p-calendar placeholder="{{ 'Select Receiving Date' | translate }}*"
                            formControlName="receivingDate"
                            [minDate]="createdAt"
                            [showIcon]="true"
                            [readonlyInput]="true"
                            [showOnFocus]="false">
                </p-calendar>
                <div class="err font-normal" *ngIf="enableWarnings && !pendingForm.valid">
                    {{'This field is required' | translate }}
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="transferStock.status === 'Requested'">
        <form [formGroup]="requestForm" class="px-4 mt-3">
            <div>
                <div class="mb-2 font-normal">{{ 'Source' | translate }}</div>
                <div class="col-4 p-0">
                    <p-dropdown
                        [options]="locations"
                        formControlName="sourceLocation"
                        optionLabel="name"
                        [filter]="true"
                        filterBy="name"
                        placeholder="{{ 'Select a Location' | translate }}">
                    </p-dropdown>
                </div>
                <div class="err font-normal" *ngIf="enableWarnings">
                    {{'Requested Stock is more than avilable source stock' | translate }}
                </div>
            </div>
        </form>
    </div>
    <div class="content-table my-1 p-4">
        <p-table [value]="products" dataKey="id" [scrollable]="true" scrollHeight="20rem" [tableStyle]="{'min-width': '100%'}">
            <ng-container [ngSwitch]="statusForTemplate">

                <ng-container *ngSwitchCase="isAcceptedOrRejected()">
                    <ng-template pTemplate="header">
                        <tr class="table-header">
                            <th></th>
                            <th style="min-width: 40%"  class="font-bold">{{ 'Product Name / SKU' | translate }}</th>
                            <th>{{ 'Sent Stock' | translate }}</th>
                            <th>{{ 'Source Stock' | translate }}</th>
                            <th>{{ 'Received Stock' | translate }}</th>
                            <th>{{'Destination Stock' | translate }}</th>
                            <th></th>
                        </tr>
                    </ng-template>

                        <ng-template pTemplate="body" let-product let-expanded="expanded">
                            <tr class="table-body">
                                <td>
                                    <button pendo-tracker="tspb" type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="product.isComposite && expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                </td>
                                <td style="min-width: 40%" class="flex flex-column justify-content-start align-items-start">
                                    <div class="dark-col">{{product.name}}</div>
                                    <div class="light-col">{{product.sku}}</div>
                                </td>
                                <td>{{product.sentStock}}</td>
                                <td>{{product.sourceStock || 0}}</td>
                                <td>{{ product.receivedStock || 0 }}</td>
                                <td>{{product.destinationStock || 0}}</td>
                                <td></td>
                            </tr>
                        </ng-template>

                    <ng-template pTemplate="footer">
                        <tr class="table-footer">
                          <td style="min-width: 40%"></td>
                          <td class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Sent' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateTransferedStock()}}</div>
                          </td>
                          <td></td>
                          <td class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Received' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateReceivedStock()}}</div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                    </ng-template>
                </ng-container>
                <ng-template pTemplate="rowexpansion" let-product>
                    <ng-container [ngTemplateOutlet]="childProducts" [ngTemplateOutletContext]="{ product: product }"></ng-container>
                </ng-template>

                <ng-container *ngSwitchCase="TRANSFER_STOCK_STATUSES.REQUESTED">
                    <ng-template pTemplate="header">
                        <tr class="table-header">
                            <th></th>
                            <th style="min-width: 41%" class="font-bold">{{ 'Product Name / SKU' | translate }}</th>
                            <th>{{ 'Source Stock' | translate }}</th>
                            <th>{{ 'Destination Stock' | translate }}</th>
                            <th>{{'Requested Stock' | translate }}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product let-expanded="expanded">
                        <tr class="table-body">
                            <td>
                                <button pendo-tracker="tspb-1" type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="product.isComposite && expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td style="min-width: 41%" class="flex flex-column justify-content-start align-items-start">
                                <div class="dark-col">{{product.name}}</div>
                                <div class="light-col">{{product.sku}}</div>
                            </td>
                            <td>{{product.sourceStock || 0}}</td>
                            <td>{{product.destinationStock || 0}}</td>
                            <td>{{product.requestedStock}}</td>
                            <td></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr class="table-footer">
                          <td style="min-width: 41%"></td>
                          <td></td>
                          <td></td>
                          <td class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Requested' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateRequestedStock()}}</div>
                          </td>
                          <td></td>
                        </tr>
                    </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="TRANSFER_STOCK_STATUSES.PENDING">
                    <ng-template pTemplate="header">
                        <tr class="table-header">
                            <th></th>
                            <th style="min-width: 45%" class="font-bold ">{{ 'Product Name / SKU' | translate }}</th>
                            <th style="min-width: 18%">{{ 'Requested Stock' | translate }}</th>
                            <th style="min-width: 12%">{{ 'Sent Stock' | translate }}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product let-expanded="expanded">
                        <tr class="table-body">
                            <td>
                                <button pendo-tracker="tspb-2" type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="product.isComposite && expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td style="min-width: 45%" class="flex flex-column justify-content-start align-items-start">
                                <div class="dark-col">{{product.name}}</div>
                                <div class="light-col">{{product.sku}}</div>
                            </td>
                            <td style="min-width: 18%">{{product.requestedStock || 0}}</td>
                            <td style="min-width: 12%">{{product.sentStock || 0}}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr class="table-footer">
                          <td style="min-width: 45%"></td>
                          <td style="min-width: 18%" class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Requested' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateRequestedStock()}}</div>
                          </td>
                          <td style="min-width: 12%" class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Sent' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateTransferedStock()}}</div>
                          </td>
                          <td *ngIf="permissions.transferStockDetailViewCost" class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Sent Cost' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateTotalCost()}}</div>
                          </td>
                          <td></td>
                        </tr>
                    </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="TRANSFER_STOCK_STATUSES.PARTIALLY_ACCEPTED">
                    <ng-template pTemplate="header">
                        <tr class="table-header">
                            <th></th>
                            <th style="min-width: 45%" class="font-bold">{{ 'Product Name / SKU' | translate }}</th>
                            <th style="min-width: 12%">{{ 'Sent Stock' | translate }}</th>
                            <th>{{ 'Received Stock' | translate }}</th>
                            <th>{{'Rejected Stock' | translate }}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product let-expanded="expanded">
                        <tr class="table-body">
                            <td>
                                <button pendo-tracker="tspb-3" type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="product.isComposite && expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td style="min-width: 45%" class="flex flex-column justify-content-start align-items-start ">
                                <div class="dark-col">{{product.name}}</div>
                                <div class="light-col">{{product.sku}}</div>
                            </td>
                            <td style="min-width: 12%">{{product.sentStock}}</td>
                            <td>{{ product.receivedStock || 0 }}</td>
                            <td>{{product.sentStock - product.receivedStock }}</td>
                            <td></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr class="table-footer">
                          <td style="min-width: 45%"></td>
                          <td style="min-width: 12%" class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Sent' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{ calculateTransferedStock()}}</div>
                          </td>
                          <td class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Received' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateReceivedStock()}}</div>
                          </td>
                          <td class="flex flex-column justify-content-start align-items-start">
                            <div class="text-muted" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{'Total Rejected' | translate}}:</div>
                            <div class="font-bold" [ngClass]="langCss === 'ltr' ? 'text-left' : 'text-right'">{{calculateRejectedStock()}}</div>
                          </td>
                          <td></td>
                        </tr>
                    </ng-template>
                </ng-container>

            </ng-container>
        </p-table>
    </div>
    <p-card styleClass="position-sticky px-4 py-2 flex flex-row btns">
        <button pendo-tracker="tspb-4" pButton class="p-button-text back-btn mr-auto w-fc" (click)="cancelModal()">{{ 'Back' | translate }}</button>
        <div *ngIf="(transferStock.status === 'Pending' || transferStock.status === 'Requested') && (permissions.transferStockDetailUpdate)">
            <button pendo-tracker="tspb-5" [disabled]="isProcessing" pButton class="p-button-text p-button-danger w-fc px-3" (click)="rejectHandler(transferStock.id)">{{ 'Reject all' | translate}}</button>
            <button pendo-tracker="tspb-6" [disabled]="isProcessing" pButton class="p-button-text w-fc px-4" (click)="detailedView()" *ngIf="isReceiveEnable">
                {{ transferStock.status === 'Pending' ? 'Receive Some' : 'Send Some' | translate }}
            </button>
            <button pendo-tracker="tspb-7" [disabled]="isProcessing" pButton class="p-button-primary w-fc px-3" (click)="receiveHandler()" *ngIf="isReceiveEnable">
                {{ transferStock.status === 'Pending' ? 'Receive all' : 'Send All' | translate }}
            </button>
        </div>
        <div  *ngIf="isRejectButtonEnabled && (permissions.transferStockDetailUpdate)">
            <button pendo-tracker="tspb-8"
                [disabled]="isRemoveStockProcessing"
                pButton
                pRipple
                type="button"
                label="{{'Remove Rejected Stock' | translate }}"
                class="px-5 p-button-danger p-button-text"
                (click)="handleRemoveRejectedProducts()"
            >
            </button>
        </div>
    </p-card>
</div>


<ng-template #childProducts let-product="product">
    <tr class="table-body" *ngFor="let childVariant of product?.childVariants; index as i">
        <td>
            <span></span>
        </td>
        <ng-container [ngSwitch]="statusForTemplate">
            <ng-container *ngSwitchCase="isAcceptedOrRejected()">
                <td style="min-width: 40%" class="flex flex-column justify-content-start align-items-start">
                    <div class="dark-col">{{childVariant?.ProductVariant?.name}}</div>
                    <div class="light-col">{{childVariant?.ProductVariant?.sku}}</div>
                </td>
                <td>{{calculateChildProductQuantities(product.sentStock, childVariant) || 0}}</td>
                <td> -</td>
                <td>{{ calculateChildProductQuantities(product.receivedStock, childVariant) || 0 }}</td>
                <td> -</td>
                <td></td>
            </ng-container>
            <ng-container *ngSwitchCase="TRANSFER_STOCK_STATUSES.PARTIALLY_ACCEPTED">
                <td style="min-width: 45%" class="flex flex-column justify-content-start align-items-start">
                    <div class="dark-col">{{childVariant?.ProductVariant?.name}}</div>
                    <div class="light-col">{{childVariant?.ProductVariant?.sku}}</div>
                </td>
                <td style="min-width: 12%">{{calculateChildProductQuantities(product.sentStock, childVariant) || 0}}</td>
                <td>{{ calculateChildProductQuantities(product.receivedStock, childVariant) || 0 }}</td>
                <td>{{calculateChildProductQuantities(product.sentStock - product.receivedStock, childVariant) || 0}}</td>
                <td></td>
            </ng-container>
            <ng-container *ngSwitchCase="TRANSFER_STOCK_STATUSES.PENDING">
                <td style="min-width: 45%" class="flex flex-column justify-content-start align-items-start ml-3">
                    <div class="dark-col">{{childVariant?.ProductVariant?.name}}</div>
                    <div class="light-col">{{childVariant?.ProductVariant?.sku}}</div>
                </td>
                <td style="min-width: 18%">{{calculateChildProductQuantities(product.requestedStock, childVariant) || 0}}</td>
                <td style="min-width: 12%">{{calculateChildProductQuantities(product.sentStock, childVariant) || 0}}</td>
                <td></td>
                <td></td>
            </ng-container>
            <ng-container *ngSwitchCase="TRANSFER_STOCK_STATUSES.REQUESTED">
                <td style="min-width: 41%" class="flex flex-column justify-content-start align-items-start">
                    <div class="dark-col">{{childVariant?.ProductVariant?.name}}</div>
                    <div class="light-col">{{childVariant?.ProductVariant?.sku}}</div>
                </td>
                <td> -</td>
                <td> -</td>
                <td>{{calculateChildProductQuantities(product.requestedStock, childVariant) || 0}}</td>
                <td></td>
            </ng-container>
        </ng-container>
    </tr>
</ng-template>
