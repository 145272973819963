import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TransferStock } from '../../models/transfer-stock';

export const loadAllTransferStocks = createAction(
  '[Transfer Stock Effect] loading all transfer stocks',
);

export const allTransferStocksLoaded = createAction(
  '[Transfer Stock Effect] loaded all transfer stocks',
  props<{ transferStocks: any }>(),
);

export const create = createAction(
  '[Transfer Stock Effect] create new transfer stock',
  props<{ transferStock: any, draftId?: number }>(),
);

export const createDraft = createAction(
  '[Transfer Stock Effect] create new transfer stock draft',
  props<{ transferStock: any }>(),
);

export const createMultiple = createAction(
  '[Multiple Transfer Stock Effect] create new multiple transfer stock',
  props<{ transactions: any, draftId?: number }>(),
);

export const createMultipleDraft = createAction(
  '[Multiple Transfer Stock Effect] create new multiple transfer stock draft',
  props<{ transactions: any }>(),
);

// export const createMultipleTransferRequest = createAction(
//   '[Multiple Transfer Stock Effect] create new multiple transfer stock request',
//   props<{ transactions: any }>(),
// );

export const transferStockSaved = createAction(
  '[Transfer Stock Effect] transfer stock saved',
  props<{ transferStock: any }>(),
);

export const multipleTransferStockSaved = createAction(
  '[Multiple Transfer Stock Effect] multiple transfer stock saved',
  props<{ transferStock: any }>(),
);

export const loadTransferStockById = createAction(
  '[Transfer Stock Effect] loading transfer stock by id',
  props<{ invoiceId: number }>(),
);

export const transferStockLoaded = createAction(
  '[Transfer Stock Effect] transfer stock is loaded',
  props<{ transferStock: TransferStock }>(),
);

export const acceptTransferStock = createAction(
  '[Transfer Stock Effect] accept transfer stock',
  props<{ invoiceId: number }>(),
);
export const acceptMultipleTransferStock = createAction(
  '[Transfer Stock Effect] accept multiple transfer stock',
  props<{ invoiceId: number, requestBody: any }>(),
);

export const acceptBulkTransferStock = createAction(
  '[Transfer Stock Effect] accept bulk transfer stock',
  props<{ bulkInvoices: any }>(),
);

export const rejectBulkTransferStock = createAction(
  '[Transfer Stock Effect] reject bulk transfer stock',
  props<{ ids: number[], reason: any }>(),
);

export const cancelTransferStock = createAction(
  '[Transfer Stock Effect] cancel transfer stock',
  props<{ invoiceId: number, requestBody: any }>(),
);

export const transferStockUpdated = createAction(
  '[Transfer Stock Effect] transfer stock updated',
  props<{ update: Update<TransferStock> }>(),
);

export const rejectRequest = createAction(
  '[Transfer Stock Effect] transfer request rejected',
  props<{ invoiceId: number }>(),
);

export const completeRequest = createAction(
  '[Transfer Stock Effect] transfer request completed',
  props<{ invoiceId: number, transactions: any }>(),
);

export const updateBulkTransferStock = createAction(
  '[Transfer Stock Effect] transfer stock updated bulk',
  props<{ updates: Update<TransferStock>[] }>(),
);

export const startProcessing = createAction('[Transfer Stock Effect] Start Processing');

export const endProcessing = createAction('[Transfer Stock Effect] End Processing');
