import {
  OFFLINE_INVOICE_STORAGE_LIMIT,
  POS_SETTINGS, PRODUCT_TRACK_TYPES, PRODUCT_TYPES, TAX_TYPES, VariantTypes,
} from 'src/app/shared/constants';
import { ITaxConfiguration } from 'src/app/shared/model/TaxConfiguration';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { ChildVariant } from 'src/app/inventory/model/product';
import { Tax } from 'src/app/shared/model/Tax';
import { WeightedProductConfiguration } from 'src/app/shared/model/WeightedProductConfiguration';
import { VariantToTrack } from 'src/app/shared/model/VariantToTrack';
import { Observable, firstValueFrom, of } from 'rxjs';
import { Invoice } from 'src/app/shared/model/invoice/Invoice';
import { Category } from 'src/app/inventory/model/category';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { SellProductBatch, SellItem, SellProductExtra } from '../model/Sell';
import { Register } from '../model/Register';
import { compareStockQuantity } from './common.utils';
import { Setting } from '../model/Setting';
import { InvoiceTemplateMap } from '../components/sales/sale-view/types';
import { Template } from '../model/Template';
import { TemplateTypes } from '../model/TemplateType';
import { roundToFixedDigits } from '../../../ui/utility/calculation.utility';
import { TemplateConstants } from '@rewaa-team/pos-sdk';
import { Customer } from '../../../shared/model/order/Customer';

export const getTaxConfig = (priceConfiguration: ITaxConfiguration, prop: string = 'retailPrice'): string => (prop === 'cost' ? priceConfiguration.costTaxStatus : priceConfiguration.sellTaxStatus);

export const calculateExtrasExclusivePrice = (extras: Array<SellProductExtra> = [],
  quantity: number = 1, tax: Tax, prop: string,
  priceConfiguration: ITaxConfiguration) => (extras || []).reduce(
  (sum: number, p: SellProductExtra) => {
    let costPrice;
    if (priceConfiguration && getTaxConfig(priceConfiguration, prop) === TAX_TYPES.INCLUSIVE) {
      costPrice = InvoicesCalculator.divide(
        p.price,
        InvoicesCalculator.add(1, InvoicesCalculator.divide(tax?.rate || 0, 100)),
      );
    } else {
      costPrice = p.price;
    }
    return InvoicesCalculator.add(sum, InvoicesCalculator.multiply(quantity,
      InvoicesCalculator.multiply(
        costPrice, p.quantity,
      )));
  }, 0,
);

export const computeExtraTax = (tax: Tax, variantExtra: SellProductExtra,
  priceConfiguration: ITaxConfiguration) => {
  const stock = variantExtra.extra.ProductVariant
    ? variantExtra.extra.ProductVariant.ProductVariantToStockLocations[0] : undefined;
  const taxRate: number = variantExtra.extra.hasOtherProduct && stock ? stock.Tax.rate : tax && tax.rate;
  const price = priceConfiguration.sellTaxStatus === TAX_TYPES.INCLUSIVE
    ? InvoicesCalculator.divide(variantExtra.price,
      InvoicesCalculator.add(1, InvoicesCalculator.divide(taxRate, 100))) : variantExtra.price;
  return InvoicesCalculator.multiply(price, InvoicesCalculator.multiply(
    variantExtra.quantity, InvoicesCalculator.divide(taxRate, 100),
  ));
};

export const calculateExtrasPrice = (
  extras: Array<SellProductExtra> = [],
  quantity: number = 1, tax: Tax,
  prop: string, priceConfiguration: ITaxConfiguration,
  taxInclude: boolean = false,
) => {
  const isTaxTypeInclusive = priceConfiguration
    && getTaxConfig(priceConfiguration, prop) === TAX_TYPES.INCLUSIVE;
  return (extras || []).reduce((sum: number, p: SellProductExtra) => {
    let costPrice = p.price;
    if (!isTaxTypeInclusive && taxInclude) {
      // if tax needs to be included
      // get taxInclusive price
      const taxRate = tax ? tax.rate : 0;
      const taxAmount = InvoicesCalculator.divide(taxRate, 100);
      costPrice = InvoicesCalculator.multiply(p.price, InvoicesCalculator.add(1, taxAmount));
    }
    // multiply qty by the taxInclusive price and return
    return InvoicesCalculator.add(sum, InvoicesCalculator.multiply(quantity, InvoicesCalculator.multiply(costPrice, p.quantity)));
  }, 0);
};

export const calculatePrice = (product: SellItem, prop: string,
  priceConfiguration: ITaxConfiguration, taxInclude: boolean = false,
  includeGeneralDiscount = false) => {
  const { tax = {} } = product || {};

  let costPrice;
  let actualCost = product.actualCost;
  if (priceConfiguration && getTaxConfig(priceConfiguration, prop) === TAX_TYPES.INCLUSIVE) {
    costPrice = product[prop];
  } else if (taxInclude) {
    costPrice = InvoicesCalculator.multiply(
      product[prop],
      InvoicesCalculator.add(1, InvoicesCalculator.divide(tax && tax.rate, 100)),
    );
    actualCost = InvoicesCalculator.multiply(
      actualCost,
      InvoicesCalculator.add(1, InvoicesCalculator.divide(tax && tax.rate, 100)),
    );
  } else {
    costPrice = product[prop];
  }

  const extrasTotalPrice: number = calculateExtrasPrice(product.extras,
    product.quantity, tax, prop, priceConfiguration, taxInclude);

  const promotionQuantity = Math.min(product.promotionQuantity || 0, product.quantity);
  let promotionPrice = InvoicesCalculator.multiply(costPrice, promotionQuantity);
  let price = costPrice;

  if(promotionQuantity) {
    price = InvoicesCalculator.add(
      InvoicesCalculator.multiply(actualCost, product.quantity - promotionQuantity),
      promotionPrice,
    );
  } else {
    price = InvoicesCalculator.multiply(costPrice, product.quantity);
  }

  price = InvoicesCalculator.add(
    price,
    extrasTotalPrice,
  );
  if (includeGeneralDiscount && product.generalDiscountPercentage > 0) {
    price = InvoicesCalculator.multiply(
      price,
      InvoicesCalculator.subtract(1, product.generalDiscountPercentage),
    );
  }
  return price;
};

export const calculateSellItemExclusivePrice = (product: SellItem,
  prop: string, priceConfiguration: ITaxConfiguration) => {
  const { tax = {} } = product || {};

  let costPrice;
  if (priceConfiguration && getTaxConfig(priceConfiguration, prop) === TAX_TYPES.INCLUSIVE) {
    costPrice = InvoicesCalculator.divide(
      product[prop],
      InvoicesCalculator.add(1, InvoicesCalculator.divide(tax?.rate || 0, 100)),
    );
  } else {
    costPrice = product[prop];
  }
  const extrasTotalPrice: number = calculateExtrasExclusivePrice(product.extras,
    product.quantity, tax, prop, priceConfiguration);

  return InvoicesCalculator.add(InvoicesCalculator.multiply(costPrice,
    product.quantity), extrasTotalPrice);
};

export const calculateSellItemExclusivePriceWithPromo = (product: SellItem,
  prop: string, priceConfiguration: ITaxConfiguration) => {
  const { tax = {} } = product || {};
  const isInclusive = priceConfiguration && getTaxConfig(priceConfiguration, prop) === TAX_TYPES.INCLUSIVE;

  let costPrice;
  let actualCost = +product.actualCost;
  if (isInclusive) {
    costPrice = InvoicesCalculator.divide(
      product[prop],
      InvoicesCalculator.add(1, InvoicesCalculator.divide(tax?.rate || 0, 100)),
    );
    actualCost = InvoicesCalculator.divide(
      actualCost,
      InvoicesCalculator.add(1, InvoicesCalculator.divide(tax?.rate || 0, 100)),
    );
  } else {
    costPrice = product[prop];
  }
  const promotionQuantity = Math.min(product.promotionQuantity || 0, product.quantity);
  const promotionPrice = InvoicesCalculator.multiply(costPrice, promotionQuantity);
  const nonPromotionPrice = InvoicesCalculator.multiply(actualCost, product.quantity - promotionQuantity);
  const extrasTotalPrice: number = calculateExtrasExclusivePrice(product.extras,
    product.quantity, tax, prop, priceConfiguration);
  const totalPrice = InvoicesCalculator.add(promotionPrice, nonPromotionPrice);
  return InvoicesCalculator.add(totalPrice, extrasTotalPrice);
};

export const calculateSellItemExclusivePriceWithoutExtra = (product: SellItem,
  prop: string, priceConfiguration: ITaxConfiguration) => {
  const { tax = {} } = product || {};

  let costPrice;
  if (priceConfiguration && getTaxConfig(priceConfiguration, prop) === TAX_TYPES.INCLUSIVE) {
    costPrice = InvoicesCalculator.divide(
      product[prop],
      InvoicesCalculator.add(1, InvoicesCalculator.divide(tax?.rate || 0, 100)),
    );
  } else {
    costPrice = product[prop];
  }

  return InvoicesCalculator.multiply(costPrice, product.quantity);
};

export const calculateExtrasTax = (product: SellItem,
  priceConfiguration: ITaxConfiguration, quantity: number = 1) => {
  // will keep for future when we have extra tax we can utilize this
  const { tax } : { tax?: Tax } = product;
  if (!tax?.rate && tax?.TaxLines) {
    const taxLineWithTaxRate = tax.TaxLines.find((taxLine) => taxLine?.rate);
    tax.rate = taxLineWithTaxRate?.rate;
  }
  return (product.extras || []).reduce((sum,
    extra: SellProductExtra) => InvoicesCalculator.add(InvoicesCalculator.multiply(
    quantity, computeExtraTax(tax, extra, priceConfiguration),
  ), sum),
  0);
};

export const calculateTax = (product: SellItem, priceConfiguration: ITaxConfiguration, withGeneralDiscount = true) => {
  const { tax = {} } = product || {};
  const cost = priceConfiguration.sellTaxStatus
    === TAX_TYPES.INCLUSIVE ? InvoicesCalculator.divide(
      product.price, InvoicesCalculator.add(1,
        InvoicesCalculator.divide(tax && tax.rate, 100)),
    ) : product.price;

    const actualCost = priceConfiguration.sellTaxStatus
    === TAX_TYPES.INCLUSIVE ? InvoicesCalculator.divide(
      product.actualCost, InvoicesCalculator.add(1,
        InvoicesCalculator.divide(tax && tax.rate, 100)),
    ) : product.actualCost;

  const extrasTotalTax = calculateExtrasTax(product, priceConfiguration, product.quantity);
  const promotionQuantity = Math.min(product.promotionQuantity || 0, product.quantity);

  /**
   * There can either be promo or discount per product
   * when promo is applied promotionQuantity will always be > 0
   * and so the actualCost should be used
   */
  const withoutPromoTax = InvoicesCalculator.multiply(
    promotionQuantity ? actualCost : cost, InvoicesCalculator.multiply(product.quantity - promotionQuantity,
      InvoicesCalculator.divide(tax && tax.rate, 100))
  )

  const withPromoTax = InvoicesCalculator.multiply(
    cost, InvoicesCalculator.multiply(promotionQuantity,
      InvoicesCalculator.divide(tax && tax.rate, 100))
  )

  const totalTaxWithoutExtra = InvoicesCalculator.add(withoutPromoTax, withPromoTax);

  let calculatedTax = InvoicesCalculator.add(totalTaxWithoutExtra, extrasTotalTax);
  if (product.generalDiscountPercentage > 0 && withGeneralDiscount) {
    calculatedTax = InvoicesCalculator.multiply(
      calculatedTax,
      InvoicesCalculator.subtract(1, product.generalDiscountPercentage),
    );
  }
  return calculatedTax;
};

export const computeVariantPrice = (variant: ProductVariant, prop = 'retailPrice') => {
  if (variant.type === VariantTypes.Composite) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return getCompositeProductPrice(variant, prop);
  }

  const [stock] = variant.ProductVariantToStockLocations;
  return stock[prop] || 0;
};

export const getCompositeProductPrice = (
  variant: ProductVariant, prop = 'retailPrice',
) => variant.Product.VariantToComposites
  .reduce((price, child: ChildVariant) => InvoicesCalculator
    .add(price, InvoicesCalculator.multiply(
      child.rate, computeVariantPrice(child.ProductVariant, prop),
    )), 0);

export const getProductBatches = (variant: ProductVariant, quantity: number) => {
  if (variant.trackType !== PRODUCT_TRACK_TYPES.BATCH) return [];

  const stock = variant.ProductVariantToStockLocations[0];

  let i = 0;
  const batches: Array<SellProductBatch> = [];

  if (stock.VariantToTracks.length) {
    while (quantity > 0 && quantity > stock.VariantToTracks[i].quantity
      && i < stock.VariantToTracks.length - 1) {
      batches.push({
        trackNo: stock.VariantToTracks[i].trackNo,
        quantity: stock.VariantToTracks[i].quantity,
      });
      quantity -= stock.VariantToTracks[i].quantity;
      i += 1;
    }

    batches.push({ trackNo: stock.VariantToTracks[i].trackNo, quantity });
  }

  return batches;
};

export const getCompositeDetailInfo = (
  quantity: number, v: ChildVariant,
) => `<span class='composite-item'><b>${InvoicesCalculator.multiply(quantity, v.rate)} `
  + `</b>${v.ProductVariant.name || v.ProductVariant.Product.name}</span>`;

export const pickWeightOrPriceFromBarcode = (barCode: string,
  weightedConfiguration: WeightedProductConfiguration) => {
  if (!barCode) return 1;

  barCode = weightedConfiguration.hasCheckDigit ? barCode.substr(0, barCode.length - 1) : barCode;
  const realDigits = weightedConfiguration.lastDigits === 'Price' ? 3 : 2;
  const digits = InvoicesCalculator.add(realDigits, weightedConfiguration.decimalNumberDigits);
  const lastDigits = barCode.substr(barCode.length - digits);
  return +(`${lastDigits.split('').filter((s, i) => i < realDigits).join('')}.${lastDigits.split('').filter((s, i) => i >= realDigits).join('')}`);
};

export const getAvailableLocationQuantity = (
  register: Register,
  variant: ProductVariant,
  includedInfinity: boolean = false,
) => {
  if (!variant) return 0;

  if (variant && !variant.manageStockLevel) return includedInfinity ? Number.POSITIVE_INFINITY : 0;

  const stock = variant.ProductVariantToStockLocations.find(
    (d) => d.stockLocationId === register.stockLocationId,
  );

  if (variant.trackType && stock?.VariantToTracks?.length) {
    return stock.VariantToTracks.reduce((sum: number,
      t: VariantToTrack) => InvoicesCalculator.add(sum, Math.max(0, t.quantity)), 0);
  }

  return stock ? stock.quantity : 0;
};

export const calculateMaxToSell = (
  register: Register,
  variant: ProductVariant,
): number =>
  variant.Product.VariantToComposites.reduce(
    (quantity: number, v: ChildVariant) => {
      const availableLocationQuantity = getAvailableLocationQuantity(
        register,
        v.ProductVariant,
        true,
      );

      const availableQuantityByRate = availableLocationQuantity
        ? availableLocationQuantity / v.rate
        : 0;

      return Math.min(quantity, availableQuantityByRate || 0);
    },
    Infinity,
  );

export const checkCompositeProductAvailablity = (register: Register,
  variant: ProductVariant, quantity: number = 0): boolean => {
  const maxToSell = calculateMaxToSell(register, variant);

  return compareStockQuantity(quantity, maxToSell, true);
};

export const checkPackageProductAvailablity = (register: Register,
  variant: ProductVariant, quantity: number) => {
  const [child] = variant.VariantToPackages;
  const availableLocationQuantity = getAvailableLocationQuantity(register, child.ProductVariant);

  return compareStockQuantity(quantity, InvoicesCalculator.divide(availableLocationQuantity,
    child.rate), child.ProductVariant ? child.ProductVariant.manageStockLevel : false);
};

export const checkECardProductAvailablity = (register: Register,
  variant: ProductVariant, quantity: number) => (!!(
  variant.Ecards.length && variant.Ecards.length >= quantity));

export const checkProductStockAvailability = (register: Register,
  variant: ProductVariant, quantity: number = 0): boolean => {
  let areStockAvailable = true;

  if (variant.type === PRODUCT_TYPES.Composite.name) {
    areStockAvailable = checkCompositeProductAvailablity(register, variant, quantity);
  } else if (variant.type === VariantTypes.Package) {
    areStockAvailable = checkPackageProductAvailablity(register, variant, quantity);
  } else if (variant.Product.type === PRODUCT_TYPES.Digital.values[0]) {
    areStockAvailable = checkECardProductAvailablity(register, variant, quantity);
  } else {
    const availableLocationQuantity = getAvailableLocationQuantity(register, variant);
    areStockAvailable = compareStockQuantity(quantity,
      availableLocationQuantity, variant.manageStockLevel);
  }

  return areStockAvailable;
};

export const getProductAvailableQuantity = (
  register: Register,
  variant: ProductVariant,
): number => {
  if (!variant || (variant && !variant.manageStockLevel)) return 0;

  if (variant.type === VariantTypes.Composite) {
    return calculateMaxToSell(register, variant);
  }
  if (variant.type === VariantTypes.Package) {
    const [pack] = variant.VariantToPackages;
    return pack.rate > 0 ? InvoicesCalculator.multiply(
      getAvailableLocationQuantity(register, pack.ProductVariant), pack.rate,
    ) : 0;
  }
  if (variant.Product.type === PRODUCT_TYPES.Digital.values[0]) {
    return variant.Ecards.length;
  }

  return getAvailableLocationQuantity(register, variant);
};

export const playErrorNotificationAudio = (): void => {
  const audio = new Audio('../../../../assets/audio/pristine.mp3');
  audio.play();
};

export const computeExclusive = (price: number, priceConfiguration: ITaxConfiguration, variant: ProductVariant, prop: string = 'retailPrice') => {
  const [stock] = variant.ProductVariantToStockLocations;
  return getTaxConfig(priceConfiguration, prop)
  === TAX_TYPES.INCLUSIVE ? InvoicesCalculator.divide(price,
      InvoicesCalculator.add(1, InvoicesCalculator.divide(stock.Tax?.rate || 0, 100))) : price;
};

export const calculateExclusivePrice = (variant: ProductVariant, priceConfiguration: ITaxConfiguration, prop: string = 'retailPrice') => {
  const price: number = computeVariantPrice(variant, prop);

  return computeExclusive(price, priceConfiguration, variant, prop);
};

export const validateQuantityLessZero = (variant: ProductVariant,
  posSettings: Setting[], register: Register, quantity: number = 0): boolean => {
  if (variant.trackType) {
    return checkProductStockAvailability(register, variant, quantity);
  }
  if (variant.type === VariantTypes.Package) {
    const [child] = variant.VariantToPackages;

    if (child.ProductVariant.trackType) {
      return checkProductStockAvailability(register, variant, quantity);
    }
  }

  return posSettings.find((s) => s.settingName === POS_SETTINGS.SellOnQuantityLtZero).settingValue === '1';
};

export const calculateInclusivePrice = (exclusivePrice: number,
  taxRate: number, taxStatus: string) => (taxStatus === TAX_TYPES.INCLUSIVE
  ? InvoicesCalculator.multiply(InvoicesCalculator.add(1,
    InvoicesCalculator.divide(taxRate, 100)), exclusivePrice)
  : exclusivePrice);

export function isAndroid() {
  const userAgent = navigator.userAgent || navigator.vendor;

  return !!/android/i.test(userAgent);
}

export function getInvoiceStorageLimit() {
  return isAndroid() ? OFFLINE_INVOICE_STORAGE_LIMIT.android : OFFLINE_INVOICE_STORAGE_LIMIT.others;
}

export function getCountReturnSellPercentage(count) {
  return isAndroid() ? (count * 100)
    / OFFLINE_INVOICE_STORAGE_LIMIT.android : (count * 100) / OFFLINE_INVOICE_STORAGE_LIMIT.others;
}

export async function checkstorageLimit() {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const { usage, quota } = await navigator.storage.estimate();
    const percentUsed = Math.round((usage / quota) * 100);
    if (percentUsed >= 89) {
      return true;
    }
    return false;
  }
}
export const convertPriceExclusive = (price: number,
  taxRate: number, taxStatus: string) => (taxStatus === TAX_TYPES.INCLUSIVE
  ? InvoicesCalculator.divide(price,
    InvoicesCalculator.add(1, InvoicesCalculator.divide(taxRate, 100)))
  : price);

export const convertBlobToBase64 = (blob: Blob) => new Observable<string>((observer) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = (event: any) => {
    observer.next(event.target.result);
    observer.complete();
  };

  reader.onerror = (event: any) => {
    observer.next(event.target.error.code);
    observer.complete();
  };
});

export const loadScripts = (url: string, id = null) => {
  const body = document.body as HTMLDivElement;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.id = id;
  script.src = url;
  body.appendChild(script);
};

export const getSkuFromScannerCode = (weightedConfiguration: WeightedProductConfiguration,
  code: string) => {
  const {
    starter, decimalNumberDigits, hasCheckDigit, lastDigits,
  } = weightedConfiguration || {};

  if (code.startsWith(`${starter}`)) {
    const scannerCode = hasCheckDigit ? code.substr(0, code.length - 1) : code;

    let sku: string = scannerCode.replace(`${starter}`, '');
    const realDigits = lastDigits === 'Price' ? 3 : 2;
    const digits = InvoicesCalculator.add(realDigits, decimalNumberDigits);

    if (scannerCode.length <= InvoicesCalculator.add(digits, `${starter}`.length)) return scannerCode;

    sku = sku.substr(0, InvoicesCalculator.subtract(sku.length, digits));
    return sku;
  }

  return code;
};

export const getAllCategoryIdsOfVariant = (
  categoryId: number,
  categories: Category[],
): number[] => {
  if (!categoryId) return [];
  return [
    categoryId,
    ...(categories
      .find((category) => category.id === categoryId)
      ?.parents.map((c) => c.id) || []),
  ].filter((id) => id);
};

export const isB2bTemplate = (customer: Customer, posSettings: Setting[]) => {
  const isB2b =
    posSettings.find((s) => s.settingName === POS_SETTINGS.SellOnB2B)
      ?.settingValue === '1';
  const isBusinessCustomer =
    customer &&
    !!(
      customer.vatNumber ||
      customer.commercialRegisterNumber || customer.nationalId
    );

  return isB2b && isBusinessCustomer;
};

export const getMultiTemplateInvoices = (
  invoice: Invoice,
  templates: Template[],
  cartProducts: any[],
  categories: Category[],
  b2bNewFlow: boolean = false,
  isB2BTemplate: boolean,
): InvoiceTemplateMap[] => {
  let saleTemplateIndex = templates.findIndex((t) => t.TemplateType.name === TemplateTypes.Sales);
  
  if (b2bNewFlow) {
    const templateTypeId = isB2BTemplate? TemplateConstants.TemplateType.B2B: TemplateConstants.TemplateType.B2C;
    saleTemplateIndex = templates.findIndex((t) => t.typeId === templateTypeId);
  }

  const invoiceTemplateMap: InvoiceTemplateMap[] = [{
    invoice,
    template: templates[saleTemplateIndex],
    templateIndex: saleTemplateIndex,
  }];

  templates.forEach((template, index) => {
    if (index === saleTemplateIndex) return;

    const templateCategories = template.TemplateCategories.map((tc) => tc.categoryId);
    const currentInvoice: Invoice = JSON.parse(JSON.stringify(invoice));
    currentInvoice.VariantToInvoices = [];

    invoice.VariantToInvoices.forEach((variant) => {
      const categoryId = cartProducts.find(
        (p) => p.variant.id === variant.productVariantId,
      )?.variant?.Product?.categoryId;
      if (
        (!categoryId && template.settings.forUnCategorisedProducts)
        || (categoryId && template.settings.forAllCategories)
      ) {
        currentInvoice.VariantToInvoices.push(variant);
        return;
      }
      if (!categoryId) return;
      let categoryIds = [
        categoryId,
        ...getAllCategoryIdsOfVariant(categoryId, categories),
      ];

      categoryIds = [...new Set(categoryIds)];

      for (let i = 0; i < categoryIds.length; i++) {
        if (templateCategories.includes(categoryIds[i])) {
          currentInvoice.VariantToInvoices.push(variant);
          break;
        }
      }
    });

    if (currentInvoice.VariantToInvoices.length) {
      invoiceTemplateMap.push({
        invoice: currentInvoice,
        template,
        templateIndex: index,
      });
    }
  });
  return invoiceTemplateMap;
};

export const addParentsToCategory = (
  categories: Category[],
  category: Category,
  parents: Category[] = [],
) => {
  const searchCategoryId = parents.length ? parents[parents.length - 1].parentId : category.parentId;
  const parent = categories.find((c) => searchCategoryId === c.id);
  if (!parent) {
    category.parents = parents;
    return;
  }
  parents.push(parent);
  return addParentsToCategory(categories, category, parents);
};

export const getBase64Image = async (url: string): Promise<string> => {
  try {
    if (!url || url.startsWith('data:image')) {
      return url;
    }
    const response = await fetch(url);
    const blob = await response.blob();
    return await firstValueFrom(convertBlobToBase64(blob));
  } catch (error) {
    console.error(`Image fetching failed: ${url}`);
    console.error(error);
    return url;
  }
};

