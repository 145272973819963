import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Invoice } from '../../shared/model/invoice/Invoice';
import * as removeStockActions from './remove-stock.actions';

export interface InvoiceState extends EntityState<Invoice> {
  areInvoicesLoaded: boolean;
  isProcessing: boolean;
}

export const removeStockFeatureKey = 'removeStock';

export const adapter: EntityAdapter<Invoice> = createEntityAdapter<Invoice>();

export const initialInvoicesState = {
  ...adapter.getInitialState(),
  isProcessing: false,
};

export const reducer = createReducer(
  initialInvoicesState,
  on(removeStockActions.startProcessing, (state) => ({ ...state, isProcessing: true })),
  on(removeStockActions.endProcessing, (state) => ({ ...state, isProcessing: false })),
  on(removeStockActions.allRemoveStockLoaded, (state, action) => {
    adapter.removeAll(state);
    return adapter.setAll(action.removeStock, {
      ...state,
      areLoaded: true,
      isProcessing: false,
    });
  }),
  on(removeStockActions.RemoveStockCreated, (state, action) => adapter
    .addOne(action.removeStock, state)),
  on(removeStockActions.removeStockLoaded, (state, action) => adapter
    .addOne(action.removeStock, state)),
);

export const {
  selectAll,
} = adapter.getSelectors();

export function removeStockReducer(state: InvoiceState | undefined, action: Action) {
  return reducer(state, action);
}
