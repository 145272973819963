import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Location } from '../../shared/model/Location';
import { SettingsActions } from '../settings.action-types';

export interface LocationsState extends EntityState<Location> {
  areLocationsLoaded: boolean;
}

export const locationsFeatureKey = 'locations';

export const adaptor: EntityAdapter<Location> = createEntityAdapter<Location>();

export const initialLocationsState = adaptor.getInitialState();

const reducer = createReducer(
  initialLocationsState,
  on(SettingsActions.locationsLoaded, (state, action) => adaptor.setAll(action.locations, {
    ...state,
    areLocationsLoaded: true,
  })),

  on(SettingsActions.locationUpdated, (state, action) => adaptor.upsertOne(action.location, state)),

  on(SettingsActions.locationAdded, (state, action) => adaptor.addOne(action.location, state)),
);

export const {
  selectAll,
} = adaptor.getSelectors();

export function locationsReducer(state: LocationsState | undefined, action: Action) {
  return reducer(state, action);
}
