import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

// Services
import { TranslateService } from '@ngx-translate/core';
import { map, catchError, finalize } from 'rxjs';
import { LocalStorageService } from '../../../../core/services/local-storage.service';

// Constants
import { LocalStorageKey } from '../../../../shared/constants';
import { AuthService } from '../../../services/auth.service';
import { FeatureFlagEnum } from '../../../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../../../shared/services/types/feature-flag.service.interface';
import { AuthUser } from '../../../auth.models';
import { CustomToastService } from '../../../../shared/services/custom-toast.service';

@Component({
  selector: 'rw-create-password',
  templateUrl: './create-password.component.html',
})
export class CreatePasswordComponent implements OnInit {
  subscriptionRefs;

  isShowSpinner = false;

  changeLang: string;

  lang: string;

  isShowInternalSpinner = false;

  passwordModel: string;

  minLengthErrorHistory = false;

  numberErrorHistory = false;

  caseErrorHistory = false;

  symbolErrorHistory = false;

  @Input() signUpForm: UntypedFormGroup;

  @Input() passwordForm: UntypedFormGroup;

  @Input() activatedRoute;

  @Output() editInfo = new EventEmitter();

  @Output() completion = new EventEmitter();

  smsOtpFeatureFlag: boolean;

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private toastService: CustomToastService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }

    this.featureFlagService
      .isEnabled(FeatureFlagEnum.SmsOtp, false)
      .subscribe((smsOtpFeatureFlag) => {
        this.smsOtpFeatureFlag = smsOtpFeatureFlag;
      });
  }

  get password(): AbstractControl | null {
    return this.passwordForm?.get('password');
  }

  ngOnInit(): void {
    this.password.setValidators([
      Validators.required,
      this.passwordValidator(),
    ]);
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value ?? '';

      if (password === '') {
        this.numberErrorHistory = false;
        this.minLengthErrorHistory = false;
        this.caseErrorHistory = false;
        this.symbolErrorHistory = false;
        return null;
      }

      const hasMinimumLength = password.length >= 8;
      const hasNumbers = /[0-9]/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasUppercase = /[A-Z]/.test(password);
      const hasSymbol = /[^A-Za-z0-9]/.test(password);

      const errors: ValidationErrors = {};
      if (!hasNumbers) {
        errors.noNumerics = 'Password must have at least one number';
      } else {
        this.numberErrorHistory = true;
      }
      if (!hasMinimumLength) {
        errors.minLength = 'Password must be at least 8 characters long';
      } else {
        this.minLengthErrorHistory = true;
      }
      if (!hasLowercase || !hasUppercase) {
        errors.caseError =
          'Password must contain at least one lowercase letter';
      } else {
        this.caseErrorHistory = true;
      }
      if (!hasSymbol) {
        errors.symbol = 'Password must contain at least one symbol';
      } else {
        this.symbolErrorHistory = true;
      }

      return Object.keys(errors).length ? errors : null;
    };
  }

  // Error Checks
  get passwordDirty(): boolean {
    return this.password?.dirty;
  }

  get minLengthError(): boolean {
    return this.password?.hasError('minLength') ?? false;
  }

  get numberError(): boolean {
    return this.password?.hasError('noNumerics') ?? false;
  }

  get caseError(): boolean {
    return this.password?.hasError('caseError') ?? false;
  }

  get symbolError(): boolean {
    return this.password?.hasError('symbol') ?? false;
  }

  private get user(): AuthUser {
    return {
      companyName: this.companyName.value,
      email: this.email.value,
      phone: this.selectedCountry.value.code + this.phone.value,
      licenseKey: this.licenseKey.value,
      referralCode: this.referralCode.value,
      utmParams: this.utmParams.value,
      password: this.password.value,
      isTermsAndPrivacyAccepted: this.isTermsAndPrivacyAccepted.value,
    } as unknown as AuthUser;
  }

  register(): void {
    this.isShowInternalSpinner = true;
    if (
      this.activatedRoute.snapshot.queryParams.email ===
      this.signUpForm.get('email').value
    ) {
      this.isShowInternalSpinner = false;
      this.completion.emit();
    }
    this.subscriptionRefs = this.authService
      .registerMerchant(this.user)
      .pipe(
        map(() => {
          this.isShowInternalSpinner = false;
          this.toastService.success(
            this.translate.instant('auth.createPasswordSuccess'),
          );
          this.completion.emit();
        }),
        catchError((err) => {
          this.isShowInternalSpinner = false;
          return err;
        }),
        finalize(() => {
          this.isShowInternalSpinner = false;
        }),
      )
      .subscribe();
  }

  editInfoEmit(): void {
    this.editInfo.emit();
  }

  // Sign Up Form Controls
  get firstName(): AbstractControl | null {
    return this.signUpForm.get('firstName');
  }

  get lastName(): AbstractControl | null {
    return this.signUpForm.get('lastName');
  }

  get email(): AbstractControl | null {
    return this.signUpForm.get('email');
  }

  get companyName(): AbstractControl | null {
    return this.signUpForm.get('companyName');
  }

  get selectedCountry(): AbstractControl | null {
    return this.signUpForm.get('selectedCountry');
  }

  get phone(): AbstractControl | null {
    return this.signUpForm.get('phone');
  }

  get licenseKey(): AbstractControl | null {
    return this.signUpForm.get('licenseKey');
  }

  get utmParams(): AbstractControl | null {
    return this.signUpForm.get('utmParams');
  }

  get referralCode(): AbstractControl | null {
    return this.signUpForm.get('referralCode');
  }

  get isTermsAndPrivacyAccepted(): AbstractControl | null {
    return this.signUpForm.get('isTermsAndPrivacyAccepted');
  }
}
