import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { TaxesService } from './tax.service';
import { ITaxConfiguration } from '../model/TaxConfiguration';

@Injectable({
  providedIn: 'root',
})
export class PriceConfigService {
  constructor(private taxService: TaxesService) {}
  private readonly configPrice$ = new BehaviorSubject<ITaxConfiguration>(null);

  get getCurrentConfigPrice$() {
    return this.configPrice$.asObservable();
  }

  initialize(): Observable<ITaxConfiguration> {
    return this.taxService
      .getUserPricesConfiguration()
      .pipe(tap((config) => this.configPrice$.next(config)));
  }
}
