import { BaseError } from '../common/base-error';

export const enum ErrorCodes {
  QuotationNotFound = 'quotation-not-found',
}

export class QuotationNotFoundError extends BaseError {
  constructor(id: number) {
    super(
      ErrorCodes.QuotationNotFound,
      `Quotation with id: ${id} does not exist`,
      404,
      {
        id,
      },
    );
  }
}
