import { Injectable } from '@angular/core';
import { enableIndexedDbPersistence } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { FirebaseAppService } from './firebase-app.service';
import { FirebaseErrorCodes } from '../enum/firebase-constants';
import { Merchant } from '../../model/Merchant';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FirebaseAuthenticationService {
  constructor(private firebaseService: FirebaseAppService) {
  }

  async authenticateUser(user: Merchant) {
    if (environment.enableFirestore) {
      try {
        const auth = getAuth(this.firebaseService.getApp());
        if (!user) return;

        await enableIndexedDbPersistence(this.firebaseService.getFirestore()).catch((err) => {
          if (err.code === FirebaseErrorCodes.FailedPrecondition) {
            console.log('Silent error: Open in multiple tabs');
          } else if (err.code === FirebaseErrorCodes.Unimplemented) {
            console.log('Silent error: Firestore persistence is not available on this browser.');
          }
        });
        await signInWithCustomToken(auth, user['custom:firebase_token']);
      } catch (error) {
        console.error('firebase err', error);
      }
    }
  }

  signOutUser() {
    try {
      const auth = getAuth();
      auth.signOut();
    } catch (error) {
      console.error('firebase err', error);
    }
  }
}
