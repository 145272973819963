import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[rwDebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 500;

  @Output() debounceClick = new EventEmitter<unknown>();

  @Output() beforeDebounce? = new EventEmitter<unknown>();

  private clicks$ = new Subject();

  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.clicks$
      .pipe(
        tap(() => {
          this.beforeDebounce.emit();
        }),
        debounceTime(this.debounceTime),
      )
      .subscribe((event) => this.debounceClick.emit(event));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks$.next(event);
  }
}
