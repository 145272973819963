import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LabelGroupIdsAngular } from '@rewaa-team/rewaa-common';
import { LabelMappingTypes } from './enum/label-mapping';
import { LabelWithChildren } from './types/labels-service-types';
import { PaginatedResponse } from '../../accounting/accounting.types';

const API_PREFIX = '/api';

type Label = Omit<LabelWithChildren, 'children' | 'options'>;

@Injectable()
export class LabelMappingService {
  constructor(private readonly httpClient: HttpClient) {}

  getAssignedLabels(params: { referenceId: number; labelGroupId: LabelGroupIdsAngular; type: LabelMappingTypes }) {
    const { referenceId, labelGroupId, type } = params;
    const options = {
      params: new HttpParams().set('type', type).set('labelGroupId', labelGroupId),
    };
    return this.httpClient.get<PaginatedResponse<Label>>(
      `${API_PREFIX}/assigned-labels/${referenceId}`,
      options,
    );
  }
}
