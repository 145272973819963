import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SubscriptionTrialBannerService {
  private isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  );

  public isVisible$ = this.isVisible.asObservable();

  hideBanner(): void {
    this.isVisible.next(false);
  }

  showBanner(): void {
    this.isVisible.next(true);
  }
}
