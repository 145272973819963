import { Observable, Subject, merge, of, EMPTY, from } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CustomersService } from 'src/app/orders/customers/services/customers.service';
import { INVOICE_TYPES } from 'src/app/shared/constants';
import { VariantCacheService } from 'src/app/shared/services/offline/variant-cache.service';
import { selectAllUsers } from 'src/app/users-settings/selectors/users.selector';
import { SettingsActions } from 'src/app/users-settings/settings.action-types';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { PromotionTypes, promotionService } from '@rewaa-team/pos-sdk';
import { AppState } from '../../../../reducers';
import { selectAllLocations } from '../../../../users-settings/selectors/locations.selector';
import { VariantService } from '../../../variants/services/variant.service';
import { SupplierService } from '../../../../shared/services/supplier.service';
import { CategoryService } from '../../services/category.service';
import { BrandService } from '../../../brands/brand.service';
import { ISuppliersSerachFilter } from '../../../../shared/model/supplier-search-and-filter';
import { OnlineOfflineService } from '../../../../shared/services/offline/online-offline.service';
import { SettingsService } from '../../../../users-settings/services/settings.service';
import { StockLocation } from '../../../../shared/model/StockLocation';
import { OfflineFirstService } from '../../../../shared/services/offline/offline-first.service';
import { Supplier } from '../../../model/supplier';
import { Category } from '../../../model/category';
import { Brand } from '../../../model/brand';
import { FeatureFlagService } from '../../../../shared/services/types/feature-flag.service.interface';
import { PromotionStoreV2Service } from '../../../../shared/services/offline/indexedDB/promotion-store-v2.service';
import { PromotionStoreService } from '../../../../shared/services/offline/indexedDB/promotion-store.service';
import { FeatureFlagEnum } from '../../../../shared/constants/feature-flag.constants';
import {
  addParentsToCategory,
  getAllCategoryIdsOfVariant,
} from '../../../../internal-apps/pos/utils/sell.utils';

const options = ['Color', 'Size'];

@Injectable()
export class TypeaheadSearchService {
  supplierFocus$ = new Subject<string>();

  typeFocus$ = new Subject<string>();

  categoryFocus$ = new Subject<string>();

  brandFocus$ = new Subject<string>();

  locationFocus$ = new Subject<string>();

  productsFocus$ = new Subject<string>();

  variantFocus$ = new Subject<string>();

  customerFocus$ = new Subject<string>();

  userFocus$ = new Subject<string>();

  searchedList: any = [];

  formatter = (x: { name: string }): string => x.name;

  private isAdvancePromotionEnable: boolean;

  private categories: Category[];

  destroySub$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private variantService: VariantService,
    private supplierService: SupplierService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private customerService: CustomersService,
    private onlineOfflineService: OnlineOfflineService,
    private variantCacheService: VariantCacheService,
    private settingsService: SettingsService,
    private offlineFirstService: OfflineFirstService,
    private featureFlagService: FeatureFlagService,
    private promotionStoreV2Service: PromotionStoreV2Service,
    private promotionStoreService: PromotionStoreService,
  ) {
    this.featureFlagService
      .isEnabled(FeatureFlagEnum.PromotionRevamp, false)
      .pipe(takeUntil(this.destroySub$))
      .subscribe((res: boolean) => {
        this.isAdvancePromotionEnable = res;
      });
    this.categoryService.getCategories().subscribe((categories) => {
      categories.forEach((category) => {
        addParentsToCategory(categories, category, []);
      });
      this.categories = categories;
    });
  }

  searchBrands = (text$: Observable<string>): Observable<Brand[]> =>
    merge(this.brandFocus$, text$).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        this.brandService
          .getBrandsPage({ limit: 10, offset: 0, query: term })
          .pipe(
            map((result) => result.result),
            tap((list) => {
              this.searchedList = list;
            }),
          ),
      ),
    );

  searchCategories = (text$: Observable<string>): Observable<Category[]> =>
    merge(this.categoryFocus$, text$).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        this.categoryService
          .getCategoriesPage({ limit: 10, offset: 0, query: term })
          .pipe(
            map((result) => result.result),
            tap((list) => {
              this.searchedList = list;
            }),
          ),
      ),
    );

  searchSuppliers = (text$: Observable<string>): Observable<Supplier[]> =>
    merge(this.supplierFocus$, text$).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => {
        const query: ISuppliersSerachFilter = {} as ISuppliersSerachFilter;
        query.search = term.toString();
        return this.supplierService.getSuppliersPage(query).pipe(
          map((data) => data.result),
          tap((list) => {
            this.searchedList = list;
          }),
        );
      }),
    );

  searchOptions = (text$: Observable<string>): Observable<string[]> =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : options
            .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10),
      ),
      tap((list) => {
        this.searchedList = list;
      }),
    );

  searchLocations = (text$: Observable<string>) =>
    merge(this.locationFocus$, text$).pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term) =>
        this.store.pipe(
          select(selectAllLocations),
          map((locations) =>
            locations
              .filter(
                (location) =>
                  location.isActive &&
                  (location.code.toLowerCase().indexOf(term.toLowerCase()) !==
                    -1 ||
                    location.name.toLowerCase().indexOf(term.toLowerCase()) !==
                    -1),
              )
              .slice(0, 10),
          ),
          tap((list) => {
            this.searchedList = list;
          }),
        ),
      ),
    );

  searchVariant = (text$: Observable<string>): Observable<ProductVariant[]> =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        this.variantService
          .searchVariants({ query: term, limit: 10, offset: 0 })
          .pipe(
            take(1),
            tap((list) => {
              this.searchedList = list;
            }),
          ),
      ),
    );

  searchVariantForSpecificLocation(
    stockLocationId: number,
    focus$?: Subject<any>,
  ): (text: Observable<string>) => Observable<any[] | Promise<any[]>> {
    return (text$: Observable<string>) =>
      merge(focus$, text$).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => {
          if (term.length < 3) {
            return of([]).pipe(
              take(1),
              tap((list) => {
                this.searchedList = list;
              }),
            );
          }

          if (term.length > 2) {
            if (this.offlineFirstService.productsEnabled) {
              return this.offlineFirstSearch(stockLocationId, term);
            }
            if (this.onlineOfflineService.isOnline) {
              return this.variantService
                .searchVariantsForSpecificLocation(
                  { query: term, limit: 20, offset: 0 },
                  stockLocationId,
                )
                .pipe(
                  tap(async (variants: ProductVariant[]) => {
                    await this.addAdvancePromotionToVariants(variants);
                    return variants;
                  }),
                );
            }
            return from(
              this.variantCacheService.searchVariants(stockLocationId, term),
            ).pipe(
              take(1),
              tap((list) => {
                this.searchedList = list;
              }),
            );
          }

          return of([]);
        }),
      );
  }

  private async addAdvancePromotionToVariants(
    variants: ProductVariant[],
  ): Promise<void> {
    const variantIds = variants.map((variant: ProductVariant) => variant.id);

    const categoryIds = variants
      .flatMap((variant: ProductVariant) => [
        variant.Product?.categoryId,
        ...getAllCategoryIdsOfVariant(
          variant.Product?.categoryId,
          this.categories,
        ),
      ])
      .filter((id) => id);

    const variantPromos: any = this.isAdvancePromotionEnable
      ? await this.promotionStoreV2Service.findActive(variantIds, [
        ...new Set(categoryIds),
      ])
      : await this.promotionStoreService.findActive(variantIds);

    variants.forEach((variant: ProductVariant) => {
      if (this.isAdvancePromotionEnable) {
        const variantCategoryIds = [
          variant.Product.categoryId,
          ...getAllCategoryIdsOfVariant(
            variant.Product?.categoryId,
            this.categories,
          ),
        ];
        promotionService.mapPromotionToVariant(
          variantCategoryIds,
          variant as any,
          variantPromos as PromotionTypes.ActiveAdvancePromotion,
        );
      } else {
        promotionService.mapSimplePromotionToVariant(
          variant as any,
          variantPromos as PromotionTypes.ActiveSimplePromotion,
        );
      }
    });
  }

  /**
   * @deprecated use getCustomers from customer service instead
   */
  searchCustomers = (text$: Observable<string>) =>
    merge(this.customerFocus$, text$).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        this.customerService
          .getCustomers({ query: term, limit: 50, offset: 0 })
          .pipe(
            map((data) => {
              if (data && data.result) {
                const filteredCustomers = data.result.filter(
                  (customer) => customer.name,
                );
                return filteredCustomers.map((obj, i) => ({
                  ...obj,
                  index: i,
                }));
              }

              return [];
            }),
            take(1),
            tap((list) => {
              this.searchedList = list;
            }),
          ),
      ),
    );

  searchUsers = (
    text$?: Observable<any>,
  ) =>
    merge(this.userFocus$, text$).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        this.store.pipe(
          select(selectAllUsers),
          // eslint-disable-next-line consistent-return
          tap((users) => {
            if (users?.length) {
              return users;
            }
            this.store.dispatch(SettingsActions.loadAllUsers());
          }),
          map((users) => {
            const filteredUsers = users
              .filter(
                (user) =>
                  user.status === 'Active' &&
                  user.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
              )
              .slice(0, 50);

            if (filteredUsers.length) {
              return filteredUsers;
            }

            return [{ searchFailed: 'No data found' }];
          }),
          tap((list) => {
            this.searchedList = list;
          }),
        ),
      ),
    );

  //   searchCustomerOffline(focus$?: Subject<any>):
  //   (text: Observable<string>) => Observable<any[] | Promise<any[]>> {
  //   return (text$: Observable<string>) => merge(focus$, text$).pipe(
  //     debounceTime(500),
  //     distinctUntilChanged(),
  //     switchMap((term) =>  this.customerCacheService.searchCustomers(term) || of([])
  //         .pipe(
  //           take(1),
  //           tap(list => this.searchedList = list)
  //         )),
  //   );
  // }

  searchVariantForInvoicesWithQuery(
    invoiceType: INVOICE_TYPES,
    isComposite: boolean,
  ): (
    text$: Observable<string>,
  ) => Observable<{ data: ProductVariant[]; query: string }> {
    return (text$: Observable<string>) =>
      merge(this.productsFocus$, text$).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) =>
          (
            (term.trim().length >= 1 &&
              this.variantService.searchVariants(
                { query: term.trim(), limit: 10, offset: 0 },
                invoiceType,
                isComposite,
              )) ||
            of([])
          ).pipe(
            take(1),
            tap((list) => {
              this.searchedList = list;
            }),
            map((data) => ({ data, query: term })),
          ),
        ),
      );
  }

  searchVariantForInvoicesWithoutQuery(
    invoiceType: INVOICE_TYPES,
    isComposite: boolean,
  ): (text$: Observable<string>) => Observable<any[]> {
    return (text$: Observable<string>) =>
      merge(this.productsFocus$, text$).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(
          (term) =>
            (term.length >= 1 &&
              this.variantService.searchVariants(
                { query: term, limit: 10, offset: 0 },
                invoiceType,
                isComposite,
              )) ||
            EMPTY.pipe(
              take(1),
              tap((list) => {
                this.searchedList = list;
              }),
            ),
        ),
      );
  }

  searchCustomLocations = (
    text$: Observable<string>,
  ): Observable<StockLocation[]> =>
    merge(this.locationFocus$, text$).pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term) =>
        this.settingsService.getAllStockLocations().pipe(
          map((locations) =>
            [
              { code: 'All', name: 'All Locations', id: null } as StockLocation,
            ].concat(
              locations
                .filter(
                  (location) =>
                    location.isActive &&
                    (location.code.toLowerCase().indexOf(term.toLowerCase()) !==
                      -1 ||
                      location.name
                        .toLowerCase()
                        .indexOf(term.toLowerCase()) !== -1),
                )
                .slice(0, 10),
            ),
          ),
          tap((list) => {
            this.searchedList = list;
          }),
        ),
      ),
    );

  private offlineFirstSearch(stockLocationId: number, term: string) {
    return from(
      this.variantCacheService.searchVariants(stockLocationId, term),
    ).pipe(
      mergeMap((list) => {
        if (list.length) {
          return of(list);
        }
        if (this.onlineOfflineService.isOnline) {
          return this.variantService.searchVariantsForSpecificLocation(
            { query: term, limit: 20, offset: 0 },
            stockLocationId,
          );
        }
        return of([]);
      }),
      tap((list) => {
        this.addAdvancePromotionToVariants(list);
        this.searchedList = list;
      }),
    );
  }
}
