import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import {
  WorkBook, WorkSheet, read, utils, write,
} from 'xlsx';
import {
  CSV_EXTENSION, CSV_TYPE,
  EXCEL_EXTENSION,
  EXCEL_TYPE,
  XLSX_TYPE,
} from '../utils/constants';

interface SheetToJsonWithArabicSupportOptions{
  skipHint: boolean;
  skipMinHeaderCheck:boolean;
}

@Injectable()
export class SheetService {
  public exportAsExcelFile(json: any[], excelFileName: string, skipHeader: boolean = false): void {
    const worksheet: WorkSheet = utils.json_to_sheet(json, { skipHeader });
    const workbook: WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = write(workbook, { bookType: XLSX_TYPE, type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName, EXCEL_EXTENSION);
  }

  public exportAsCSVFile(json: any[], excelFileName: string, skipHeader: boolean = false): void {
    const worksheet: WorkSheet = utils.json_to_sheet(json, { skipHeader });
    const workbook: WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = write(workbook, { bookType: CSV_TYPE, type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName, CSV_EXTENSION);
  }

  private saveAsExcelFile(buffer: any, fileName: string, fileExtension: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, `${fileName}${fileExtension}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public sheetToJson(file: File, skipHint: boolean = false):Promise<any> {
    let arrayBuffer;
    let json;
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        arrayBuffer = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const arr = [];
        for (let i = 0; i !== data.length; i += 1) arr[i] = String.fromCharCode(data[i]);
        const bstr = arr.join('');
        const workbook = read(bstr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        if (!(worksheet.A1 && worksheet.B1 && worksheet.C1)) { resolve({ error: 'The file does not have a header label' }); }
        if (!skipHint && !(worksheet.A2 && worksheet.B2 && worksheet.C2)) { resolve({ error: 'The hint row can\'t be empty' }); }
        json = utils.sheet_to_json(worksheet, { raw: true, defval: null });
        if (json) resolve(json);
        resolve({ error: 'no data found' });
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  public sheetToJsonWithArabicSupport(
    file: File,
    {
      skipHint = false,
      skipMinHeaderCheck = false,
    }:SheetToJsonWithArabicSupportOptions,
  ):Promise<any> {
    // handle Arabic letters as well
    let arrayBuffer;
    let json;
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = () => {
        try {
          arrayBuffer = fileReader.result;
          const data = new Uint8Array(arrayBuffer);
          const bstr = new TextDecoder('utf-8').decode(data);
          const workbook = read(bstr, { type: 'binary', raw: true, FS: ',' } as any);
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          if (!skipMinHeaderCheck && !(worksheet.A1 && worksheet.B1 && worksheet.C1)) {
            resolve({ error: 'The file does not have a header label' });
          }
          if (!skipHint && !(worksheet.A2 && worksheet.B2 && worksheet.C2)) {
            resolve({ error: 'The hint row can\'t be empty' });
          }
          json = utils.sheet_to_json(worksheet, { raw: true, defval: null });
          if (json) resolve(json);
          resolve({ error: 'no data found' });
        } catch (e) {
          reject(e);
        }
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public sheetToJsonProduct(file: File):Promise<any> {
    let arrayBuffer;
    let json;
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        arrayBuffer = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const arr = [];
        for (let i = 0; i !== data.length; i += 1) arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join('');
        /*  Below condition handling the single column in csv file and
            concat the termination characters at the end of csv content to extract the data properly
            using XLSX read method.
        */
        if (bstr.split(',').length === 1 && file.type.includes('csv')) bstr = bstr.concat(';');
        const workbook = read(bstr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        if (!(worksheet.A1)) { resolve({ error: 'The file does not have a header label' }); }
        json = utils.sheet_to_json(worksheet, { raw: true });
        if (json) resolve(json);
        resolve({ error: 'no data found' });
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public blobToJson(blob: Blob): Promise<any> {
    let arrayBuffer;
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        arrayBuffer = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const arr = [];
        for (let i = 0; i !== data.length; i += 1) arr[i] = String.fromCharCode(data[i]);
        const bstr = arr.join('');
        const workbook = read(bstr, { type: 'binary', raw: true });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const json = utils.sheet_to_json(worksheet, { raw: false, defval: '' });
        if (json) resolve(json);
        resolve({ error: 'no data found' });
      };
      fileReader.readAsArrayBuffer(blob);
    });
  }
}
