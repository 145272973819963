import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { CobrowsingSessionIdObject } from '../../cobrowsing/cobrowsing.models';
import { environment } from '../../../environments/environment';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import { UserService } from '../../auth/services/user.service';

@Injectable()
export class CobrowsingService {
  private cobrowsingDialogSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private callAcceptedSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private autoAcceptCallRequestedSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private dialogTriggered$: Observable<boolean> =
    this.cobrowsingDialogSubject$.asObservable();

  private callAccepted$: Observable<boolean> =
    this.callAcceptedSubject$.asObservable();

  private autoAcceptCall$: Observable<boolean> =
    this.autoAcceptCallRequestedSubject$.asObservable();

  private cobrowsingSessionId: string;

  private cobrowsingTicketId: string;

  private currentUserEmail: string;

  private readonly FULLVIEW_SDK_ID = 'dynamic-fullview-script';

  private feedbackSessionActive = false;

  private showModal = false;

  private callRequestedListener: EventListener;

  private callAcceptedListener: EventListener;

  private callEndedListener: EventListener;

  private callDeniedListener: EventListener;

  private renderer: Renderer2;

  constructor(
    private userService: UserService,
    private featureFlag: FeatureFlagService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private router: Router,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.initCheckCoBrowsingFeatureFlag();

    this.assignUserEmailForCoBrowsingSession();
  }

  private initCheckCoBrowsingFeatureFlag() {
    this.featureFlag
      .isEnabled(FeatureFlagEnum.CoBrowsingEnabled)
      .subscribe((coBrowsingEnabled) => {
        if (coBrowsingEnabled) {
          this.showModal = true;
        } else {
          this.showModal = false;
        }
        this.cobrowsingDialogSubject$.next(this.showModal);
      });
  }

  private assignUserEmailForCoBrowsingSession(): void {
    this.userService.getUser().subscribe((user) => {
      if (user) {
        this.currentUserEmail = user.email;
      }
    });
  }

  public get onCobrowsingDialogToggled(): Observable<boolean> {
    return this.dialogTriggered$;
  }

  public get onCallAccepted(): Observable<boolean> {
    return this.callAccepted$;
  }

  public get onAutoCallAccepted(): Observable<boolean> {
    return this.autoAcceptCall$;
  }

  async initializeFullviewSdk(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://install.fullview.io';
      script.async = false;
      script.id = this.FULLVIEW_SDK_ID;
      script.setAttribute('data-org', environment.fullViewClientId);
      this.renderer.appendChild(document.head, script);

      script.addEventListener('load', async () => {
        // Script loaded successfully
        await this.initializeFullviewSession();
        resolve();
      });

      // Event listener for script load error
      script.addEventListener('error', (errorEvent) => {
        // Script loading failed
        reject(
          new Error(
            `Failed to load Fullview SDK: ${errorEvent.message}, Please refresh the page`,
          ),
        );
        this.router.navigate(['/']).then(() => {
          this.removeDynamicSdkScript();
          this.enableCoBrowsingModal();
        });
      });
    });
  }

  private async initializeFullviewSession(): Promise<void> {
    this.router.navigate(['/cobrowsing-wizard']).then(() => {
      this.disableCobrowsingModal();
      const sessionIdSubscription = this.fetchSessionIds().subscribe(
        async (data) => {
          this.fullviewSessionId = data.externalId;
          this.coBrowsingTokenId = data.ticketId;
          await this.initializeFullViewObject();
          sessionIdSubscription.unsubscribe();
        },
        (err) => {
          sessionIdSubscription.unsubscribe();
          console.error(`Unable to fetch session id's for fullview :${err}`);
          this.router.navigate(['/']).then(() => {
            this.enableCoBrowsingModal();
          });
        },
      );
    });
  }

  private fetchSessionIds(): Observable<CobrowsingSessionIdObject> {
    const requestBody = {
      deviceEmail: this.currentUserEmail,
    };

    return this.http.post<CobrowsingSessionIdObject>(
      `${environment.applicationUrl}/api/nucleus/sessions`,
      requestBody,
    );
  }

  public async updateRatingToCoBrowsingTicket(rating: number): Promise<void> {
    const requestBody = {
      rating,
    };

    await lastValueFrom(
      this.http.patch(
        `${environment.applicationUrl}/api/nucleus/sessions/tickets/${this.coBrowsingTicketId}`,
        requestBody,
      ),
    );
  }

  public set fullviewSessionId(id: string) {
    this.cobrowsingSessionId = id;
  }

  public set coBrowsingTokenId(id: string) {
    this.cobrowsingTicketId = id;
  }

  public get coBrowsingTicketId(): string {
    return this.cobrowsingTicketId;
  }

  public get isFeedbackSession(): boolean {
    return this.feedbackSessionActive;
  }

  public disableCobrowsingModal(): void {
    this.showModal = false;
    this.cobrowsingDialogSubject$.next(this.showModal);
  }

  public enableCoBrowsingModal(): void {
    this.feedbackSessionActive = false;
    this.showModal = true;
    this.cobrowsingDialogSubject$.next(this.showModal);
  }

  public get cobrowsingModalIsActive(): boolean {
    return this.showModal;
  }

  private async initializeFullViewObject(): Promise<void> {
    window['$fvIdentity'] = {
      id: this.getCobrowsingSessionId(),
      name: `Anonymous User: ${this.getCobrowsingSessionId()}`,
      phone: 'Not Provided',
      businessType: 'Not Provided',
    };

    this.addCallRequestedListener();
    this.addCallAcceptedListener();
    this.addCallEndedListener();
    this.addCallDeniedListener();
  }

  private addCallDeniedListener(): void {
    this.callDeniedListener = this.renderer.listen(
      window,
      'fullview:callDenied',
      (event: CustomEvent) => {
        this.endCoBrowsingSession();
        this.enableCoBrowsingModal();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      },
    );
  }

  private addCallRequestedListener(): void {
    this.callRequestedListener = this.renderer.listen(
      window,
      'fullview:callRequested',
      (event: CustomEvent) => {
        this.setDefaultFullviewAlignment();
        this.autoAcceptCallRequestedSubject$.next(true);
      },
    );
  }

  private addCallAcceptedListener(): void {
    this.callAcceptedListener = this.renderer.listen(
      window,
      'fullview:callAccepted',
      (event: CustomEvent) => {
        this.disableCobrowsingModal();
        this.callAcceptedSubject$.next(true);
      },
    );
  }

  private addCallEndedListener(): void {
    this.callEndedListener = this.renderer.listen(
      window,
      'fullview:callEnded',
      (event: CustomEvent) => {
        this.endCoBrowsingSession();
        this.feedbackSessionActive = true;
        this.router.navigate(['/cobrowsing-wizard']);
      },
    );
  }

  public endCoBrowsingSession(): void {
    window['Fullview'].endSession();
    this.removeSession();
    this.removeDynamicSdkScript();
  }

  private removeDynamicSdkScript(): void {
    const scriptToRemove = document.getElementById(this.FULLVIEW_SDK_ID);
    if (scriptToRemove) {
      this.renderer.removeChild(document.head, scriptToRemove);
    }
  }

  private removeSession(): void {
    this.removeAllListeners();
  }

  private removeAllListeners(): void {
    this.callRequestedListener = null;
    this.callAcceptedListener = null;
    this.callEndedListener = null;
    this.callDeniedListener = null;
  }

  /**
   * For current implementation, the fullview container
   * and its children alignment needs to stay ltr <==> en
   * @private
   */
  private setDefaultFullviewAlignment(): void {
    const containerRef: HTMLElement = document.querySelector(
      '#fullview-container',
    ) as HTMLElement;
    if (containerRef) {
      containerRef.classList.remove('rtl');
      containerRef.classList.add('ltr');
    }
  }

  getCobrowsingSessionId(): string {
    return this.cobrowsingSessionId;
  }
}
