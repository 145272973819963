import { quantityCalculator } from '../../services/calculation-service';
import { ProductTypes, ProductEnums, PosInvoiceTypes } from '@rewaa-team/types';
import * as StockUtils from '../../utils/stock.utils';

export class PosStockService {
  static getQuantityUpdatesForStockLocation(
    variantToInvoices: PosInvoiceTypes.VariantToInvoice[],
    stockLocationId: number,
  ): ProductTypes.ProductVariantToStockLocationUpdate[] {
    const variantsToUpdate: ProductTypes.ProductVariantToStockLocationUpdate[] = [];
    for (let index = 0; index < variantToInvoices.length; index++) {
      const variantToInvoice = variantToInvoices[index];
      const variantDetails: ProductTypes.VariantDetails[] = [];
      switch (variantToInvoice.type) {
        case ProductEnums.VariantType.Package:
          variantDetails.push({
            productVariantId:
              variantToInvoice.VariantToInvoicePacks![0].productVariantId,
            rate: variantToInvoice.VariantToInvoicePacks![0].rate,
          });
          break;
        case ProductEnums.VariantType.Composite:
          variantToInvoice.CompositeVariantToInvoiceParts?.forEach(
            (composingProduct) => {
              if (composingProduct.type === ProductEnums.VariantType.Package) {
                const pack = variantToInvoice.VariantToInvoicePacks?.find(
                  (p) => p.parentSku === composingProduct.sku,
                );
                if (!pack) {
                  return;
                }
                variantDetails.push({
                  productVariantId: composingProduct.productVariantId,
                  rate: composingProduct.rate,
                  productVariant: {
                    id: composingProduct.productVariantId,
                    type: composingProduct.type as any,
                    productVariantId: pack.productVariantId,
                    rate: pack.rate,
                  },
                });
              } else {
                variantDetails.push({
                  productVariantId: composingProduct.productVariantId,
                  rate: composingProduct.rate,
                  productVariant: {
                    id: composingProduct.productVariantId,
                    type: composingProduct.type,
                  } as any,
                });
              }
            },
          );

          break;

        default:
          break;
      }
      if (variantToInvoice.VariantToInvoiceExtras?.length) {
        variantToInvoice.VariantToInvoiceExtras.forEach((extra) => {
          if (extra.productVariantId) {
            variantsToUpdate.push({
              productVariantId: extra.productVariantId,
              quantityChanged: quantityCalculator.multiply(
                extra.rate,
                extra.quantity,
              ),
              stockLocationId,
              variantType: ProductEnums.ProductVariantType.child,
              variantToInvoiceId: variantToInvoice.id,
            });
          }
        });
      }
      const variantToTracks =
        StockUtils.mapVariantToTrackForUpdateStocks(variantToInvoice);
      const ecards = StockUtils.mapEcardsForUpdateStocks(variantToInvoice);
      variantsToUpdate.push({
        productVariantId: variantToInvoice.productVariantId!,
        quantityChanged: variantToInvoice.quantity,
        stockLocationId,
        variantType: variantToInvoice.type as unknown as ProductEnums.ProductVariantType,
        variantToInvoiceId: variantToInvoice.id,
        variantDetails,
        ecards,
        variantToTracks,
      });
    }
    return variantsToUpdate;
  }
}
