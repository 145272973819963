import { createAction, props } from '@ngrx/store';
import { AppChannelSubscription } from '../shared/model/AppChannelSubscription';
import { AppProductStatus } from '../shared/model/AppProductStatus';
import { MerchantAppSubscription } from '../shared/model/MerchantAppSubscription';
import { ImportRequest } from '../shared/model/ImportRequest';
import { ProductVariant } from '../shared/model/product-variant';

/**
 * @deprecated
 */
export const loadSubscribedApps = createAction(
  '[Resolve] Load Subscribed Apps',
);

export const trueLoadSubscribedApps = createAction(
  '[Resolve] True Load Subscribed Apps',
);

export const subscribedAppsLoaded = createAction(
  '[Load Apps Effect] Subscribed Apps Loaded',
  props<{ apps: AppChannelSubscription[] }>(),
);

/**
 * @deprecated
 */
export const loadAllApps = createAction(
  '[Load Apps Effect] Load all apps',
);

export const trueLoadAllApps = createAction(
  '[Load Apps Effect] True Load all apps',
);

export const allAppsLoaded = createAction(
  '[Load Apps Effect] All Apps Loaded',
  props<{ apps: AppChannelSubscription[] }>(),
);

export const linkProduct = createAction(
  '[App Product Listing] link Product to App',
  props<{ status: AppProductStatus, appId: number }>(),
);

export const productLinked = createAction(
  '[App Effects] product is linked with external app',
  props<{ variant: ProductVariant }>(),
);

export const unlinkProduct = createAction(
  '[App Product Listing] unlink Product from App',
  props<{ status: AppProductStatus, appId: number }>(),
);

export const productUnlinked = createAction(
  '[App Effects] product is unlinked from external app',
  props<{ variant: ProductVariant }>(),
);

export const addSubscription = createAction(
  '[TBD] add subscription',
  props<{ subscription: MerchantAppSubscription }>(),
);

export const cancelSubscription = createAction(
  '[App Installation] cancel app subscription',
  props<{ subscriptionId: number }>(),
);

export const importProducts = createAction(
  '[App Installation] Import products',
  props<{ importRequest: ImportRequest }>(),
);

export const importProductsLater = createAction(
  '[App Installation] Import products later',
  props<{ importRequest: ImportRequest }>(),
);

export const updateAppSubscription = createAction(
  '[Save App Settings Effect] save app settings',
  props<{ settings: MerchantAppSubscription }>(),
);

export const loadPublisheddVariants = createAction(
  '[Load Variants] Load Published Variants',
  props<{ subscriptionId: String | number }>(),
);

export const loadPublisheddVariantsSuccess = createAction(
  '[Load Variants] Load Published Variants Success',
  props<{ variants: any }>(),
);
