/* eslint-disable no-param-reassign */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { TransferStockVariants } from 'src/app/invoices/models/transfer-stock-variants';
import { AppState } from 'src/app/reducers';
import * as TransferStockActions from 'src/app/invoices/transfer-stock/store/transfer-stock.actions';
import { Router } from '@angular/router';
import { InvoiceStatusMap } from 'src/app/invoices/utilities/maps';
import { selectAllLocations } from 'src/app/users-settings/selectors/locations.selector';
import { tap } from 'rxjs/operators';
import { VariantService } from 'src/app/inventory/variants/services/variant.service';
import {
  LocalStorageKey,
  TRANSFER_STOCK_STATUSES,
  VariantTypes,
  StockControlPermissions
} from 'src/app/shared/constants';
import { PermissionHelperService } from 'src/app/shared/services/permission.helper.service';
import { from, Subscription } from 'rxjs';
import { ChildVariant } from 'src/app/inventory/model/product';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { ProductVariantStockLocation } from 'src/app/shared/model/ProductVariantStockLocation';
import { TransferStockHelperService } from 'src/app/invoices/transfer-stock/services/transfer-stock-helper.service';
import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import { isTransferStockProcessing } from 'src/app/invoices/transfer-stock/store/transfer-stock.selectors';
import { isRemoveStockProcessing } from 'src/app/invoices/remove-stock/remove-stock.selectors';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { formatToFixedDigits } from 'src/app/ui/utility/calculation.utility';
import * as removeStockActions from '../../../../../invoices/remove-stock/remove-stock.actions';
import { RemoveRejectedService } from '../../../../../invoices/transfer-stock/services/remove-rejected.service';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { TransferStockService } from '../../../../../invoices/transfer-stock/services/transfer-stock.service';

@Component({
  selector: 'rw-transfer-stock-preview',
  templateUrl: './transfer-stock-preview.component.html',
  styleUrls: ['./transfer-stock-preview.component.scss'],
  providers: [DatePipe],
})
export class TransferStockPreviewComponent implements OnInit, OnDestroy {
  subscriptionRefs;

  @Input() content: any = {};

  lng: string;

  langCss: string;

  transferStock: any;

  products: any = [];

  createdAt: Date;

  pendingForm: FormGroup;

  requestForm: FormGroup;

  transferStockVariants: TransferStockVariants[];

  transactions = [];

  element: HTMLCollectionOf<Element>;

  enableWarnings = false;

  statusMap = InvoiceStatusMap;

  locations: any[];

  requestInvoices = [];

  variantsStockValues = [];

  statusForTemplate: string;

  isRejectButtonEnabled = false;

  areRejectedProductsValid = true;

  expanded = false;

  isReceiveEnable = true;

  allAvailableLocations : any[];

  rejectedVariants = [];

  areRejectedVariantsValid = true;

  dateString: string;

  transferStockUpdatesSubject = new Subscription();

  isProcessing: boolean;

  isRemoveStockProcessing: boolean;

  permissions = {
    transferStockDetailViewCost: false,
    transferStockDetailUpdate: false
  };

  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private variantService: VariantService,
    private datePipe: DatePipe,
    private updates$: ActionsSubject,
    private removeRejectedService: RemoveRejectedService,
    private transferStockHelperService: TransferStockHelperService,
    private localStorageService: LocalStorageService,
    private dynamicDialogRef: DynamicDialogRef,
    private transferStockService: TransferStockService,
    private permissionService: PermissionHelperService,
  ) {
    this.transferStockUpdatesSubject = this.updates$
      .pipe(
        ofType(
          ...[
            TransferStockActions.transferStockLoaded,
            TransferStockActions.transferStockUpdated,
            removeStockActions.RemoveStockCreated,
          ],
        ),
      )
      .subscribe(() => {
        this.content.refreshTable();
        this.cancelModal();
      });
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lng);
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
  }

  ngOnDestroy() {
    this.transferStockUpdatesSubject.unsubscribe();
  }

  ngOnInit() {

    this.handlePermission()
    this.loadContent();
    this.store
      .pipe(
        select(selectAllLocations),
        tap((locations) => {
          this.locations = locations.filter(
            (location) =>
              location.isActive === true &&
              location.id !== this.transferStock.destinationLocationId,
          );
          this.allAvailableLocations = locations.filter(
            (location) => location.isActive === true
          )
        }),
      )
      .subscribe(() => {
        this.createForm();
      });
    this.mapProducts();
    this.mapStatusForTemplate(this.transferStock.status);
    this.isRejectEnabled();
    this.isReceiveEnabled();

    this.element = document.getElementsByClassName('modal-content');
    this.element[0]?.classList.add('preview');

    this.requestForm?.get('sourceLocation').valueChanges.subscribe((loc) => {
      this.products.forEach((product) => {
        const variant = this.variantsStockValues.find(
          (v) => v.id === product.id,
        );
        const location = variant?.ProductVariantToStockLocations.find(
          (l) => l.stockLocationId === loc.id,
        );
        product.sourceStock = this.calculateLocationStock(
          variant,
          loc.id,
          location,
        );
        this.requestInvoices.forEach((invoice) => {
          invoice.stockLocationId = loc.id;
          invoice.stockLocationName = loc.name;
          // todo: need to get this done in quick preview
          invoice.compositeVariants = [];

          this.updateVariantQuantities(product.id, location);
        });
      });
      this.validateRequestForm();
    });

    this.subscriptionRefs = this.store
      .pipe(select(isTransferStockProcessing))
      .subscribe((isProcessing) => {
        this.isProcessing = isProcessing;
      });

    this.subscriptionRefs = this.store
      .pipe(select(isRemoveStockProcessing))
      .subscribe((isProcessing) => {
        this.isRemoveStockProcessing = isProcessing;
      });
  }

  loadContent(): void {
    this.transferStock = this.content.res;
    this.createdAt = new Date(this.transferStock.createdAt);
    this.dateString = this.setContent(this.createdAt);
  }
  isReceiveEnabled():void{
    this.isReceiveEnable =  this.allAvailableLocations.some(
      location => location.id === this.transferStock.destinationLocationId
    );
  }

  setContent(date: Date): string {
    let str = '';
    const mon = this.translate.instant(
      `shorterMonths.${this.datePipe.transform(date, 'MMM')}`,
    );
    const hrs = this.datePipe.transform(date, 'hh');
    const meridian = this.translate.instant(
      this.datePipe.transform(date, 'aaa'),
    );
    str = `${date.getDate()} ${mon} ${date.getFullYear()}, ${hrs} ${meridian}`;
    return str;
  }

  createForm() {
    if (this.transferStock.status === 'Pending') {
      this.pendingForm = this.formBuilder.group({
        receivingDate: [
          this.transferStock.deliveryDate
            ? new Date(this.transferStock.deliveryDate)
            : '',
          Validators.required,
        ],
      });
    }
    if (this.transferStock.status === 'Requested') {
      this.requestForm = this.formBuilder.group({
        sourceLocation: ['', Validators.required],
      });
    }
  }

  calculateChildProductQuantities(
    parentQuantity: number,
    childVariant: ChildVariant,
  ) {
    return childVariant?.rate * parentQuantity;
  }

  isComposite(product: any) {
    const variantToComposites =
      product?.ProductVariant?.Product?.VariantToComposites;
    return variantToComposites && variantToComposites.length > 0;
  }

  isAcceptedOrRejected() {
    if (this.statusForTemplate === TRANSFER_STOCK_STATUSES.ACCEPTED)
      return TRANSFER_STOCK_STATUSES.ACCEPTED;
    if (this.statusForTemplate === TRANSFER_STOCK_STATUSES.REJECTED)
      return TRANSFER_STOCK_STATUSES.REJECTED;
    return '';
  }

  mapProducts() {
    this.transferStock.VariantToInvoices.forEach((prod) => {
      this.products.push({
        name: prod.name,
        sku: prod.sku,
        sentStock: prod.transferQuantity,
        sourceStock: prod.availableLocationQuantity,
        receivedStock: prod.quantity,
        destinationStock: prod.availableDestnationLocationQuantity,
        requestedStock: prod.requestedQuantity,
        id: prod.productVariantId,
        isComposite: this.isComposite(prod),
        childVariants: prod?.ProductVariant?.Product?.VariantToComposites ?? [],
      });
    });
    const ids = [];
    this.products.forEach((prod) => {
      ids.push(prod.id);
    });

    this.variantService
      .getVariantsAndStockLocationByIds(ids)
      .subscribe((res) => {
        this.variantsStockValues = res;
        const stockValues = [];
        res.forEach((variant) => {
          const stockLocationVariant =
            variant.ProductVariantToStockLocations.find(
              (vp) => vp.stockLocationId === this.transferStock.stockLocationId,
            );
          stockValues.push(stockLocationVariant);
        });
        if (this.transferStock.status === TRANSFER_STOCK_STATUSES.REQUESTED) {
          this.products.forEach((product) => {
            const variant = res.find((v) => v.id === product.id);
            product.destinationStock = this.calculateLocationStock(
              variant,
              this.transferStock.destinationLocationId,
            );
          });
          this.mapToRequestStock(res);
        }
      });
  }

  mapStatusForTemplate(status: string) {
    this.statusForTemplate = status;
  }

  public get TRANSFER_STOCK_STATUSES() {
    return TRANSFER_STOCK_STATUSES;
  }

  mapToRequestStock(variantStocks: any) {
    const variants = [];
    const {
      destinationLocationId,
      destinationLocationName,
      stockLocationId,
      stockLocationName,
      notes,
    } = this.transferStock;
    this.transferStock.VariantToInvoices.forEach((variant) => {
      const {
        name,
        sku,
        transferQuantity,
        productVariantId,
        oldCost,
        newCost,
        availableLocationQuantity,
        VariantToInvoiceTracks,
        ProductVariant: productVariant,
        requestedQuantity,
      } = variant;
      const varia = variantStocks.find((v) => v.id === productVariantId);
      const destination = varia.ProductVariantToStockLocations.find(
        (location) => location.stockLocationId === destinationLocationId,
      );
      variants.push({
        name,
        sku,
        transferQuantity,
        id: productVariantId,
        productVariantId,
        oldCost,
        newCost,
        availableDestnationLocationQuantity: destination?.quantity || 0,
        availableLocationQuantity,
        WeightedVariantToInvoice:
          VariantToInvoiceTracks?.WeightedVariantToInvoice || null,
        trackType: productVariant.trackType,
        isWeightedScale: productVariant.isWeightedScale,
        type: productVariant.type,
        destinationQuantity: destination?.quantity || 0,
        destLocationQuantity: destination?.quantity || 0,
        sourceQuantity: 0,
        srcLocationQuantity: 0,
        requestedQuantity,
        ProductVariantToStockLocations: varia.ProductVariantToStockLocations,
        Product: varia.Product,
      });
    });
    this.requestInvoices.push({
      destinationLocationId,
      destinationLocationName,
      stockLocationId,
      stockLocationName,
      notes,
      variants,
    });
  }

  updateVariantQuantities(id: number, location?) {
    this.requestInvoices[0].variants.forEach((variant) => {
      if (variant.productVariantId === id) {
        variant.availableLocationQuantity = location?.quantity || 0;
        variant.sourceQuantity = location?.quantity || 0;
        variant.srcLocationQuantity = location?.quantity || 0;
        variant.oldCost = location?.cost || 0;
        variant.newCost = location?.cost || 0;
      }
    });
  }

  detailedView() {
    if (
      this.transferStock.status === 'Draft' ||
      this.transferStock.status === 'Requested'
    ) {
      this.router.navigate([
        'invoices/transfer-stock/new-multiple',
        this.transferStock.id,
      ]);
    } else {
      this.router.navigate(['invoices/transfer-stock', this.transferStock.id]);
    }
    this.cancelModal();
  }

  calculateTransferedStock(): number {
    return this.transferStockHelperService.getTotalByProp(
      'transferQuantity',
      this.transferStock.VariantToInvoices,
    );
  }

  calculateReceivedStock(): number {
    return this.transferStockHelperService.getTotalByProp(
      'quantity',
      this.transferStock.VariantToInvoices,
    );
  }

  calculateRequestedStock(): number {
    return this.transferStockHelperService.getTotalByProp(
      'requestedQuantity',
      this.transferStock.VariantToInvoices,
    );
  }

  calculateCompositeVariantLocationStock(
    variant: ProductVariant,
    locationId: number,
  ): number {
    const compositeQuantityArray = variant?.Product?.VariantToComposites?.map(
      (childVariant: ChildVariant) => {
        const isPackaged =
          childVariant?.ProductVariant?.type === VariantTypes.Package;
        const childStock =
          this.transferStockHelperService.calculateCompositeChildStockAvailability(
            childVariant,
            locationId,
            isPackaged,
          );
        return Math.floor(
          InvoicesCalculator.divide(childStock, childVariant.rate),
        );
      },
    );
    return Math.min(...compositeQuantityArray) || 0;
  }

  calculateLocationStock(
    variant: any,
    locationId: number,
    stockLocation?: ProductVariantStockLocation,
  ): number {
    if (variant.type !== VariantTypes.Composite) {
      return stockLocation
        ? stockLocation.quantity || 0
        : variant.destinationStock;
    }
    return this.calculateCompositeVariantLocationStock(variant, locationId);
  }

  calculateTotalCost() {
    let cost = 0;
    this.transferStock.VariantToInvoices.forEach((invoice) => {
      const variant = this.variantsStockValues?.find(
        (v) => v.id === invoice.productVariantId,
      );
      cost += InvoicesCalculator.multiply(
        invoice.transferQuantity,
        this.calculateVariantCost(variant, invoice),
      );
    });
    return formatToFixedDigits(cost);
  }

  calculateRejectedStock() {
    const totalTransferred = this.calculateTransferedStock();
    const totalReceived = this.calculateReceivedStock();
    return totalTransferred - totalReceived;
  }

  calculateVariantCost(variant, invoice) {
    let cost = 0;
    if (!variant) return 0;
    const { type, Product, ProductVariantToStockLocations } = variant;
    if (type === VariantTypes.Composite) {
      Product.VariantToComposites.forEach((childProduct) => {
        const childCost =
          childProduct.ProductVariant.ProductVariantToStockLocations.find(
            (loc) => loc.stockLocationId === this.transferStock.stockLocationId,
          ).cost;
        cost += InvoicesCalculator.multiply(childCost, childProduct.rate);
      });
      return cost;
    }
    if (invoice.oldCost) return invoice.oldCost;
    return ProductVariantToStockLocations.find(
      (l) => l.stockLocationId === this.transferStock.stockLocationId,
    ).cost;
  }

  receiveHandler() {
    try {
      this.isProcessing = true;
      if (this.transferStock.status === 'Requested') {
        if (this.validateRequestForm()) {
          this.completeRequest();
        }
        return;
      }
      const transferStockVariants = [];
      this.transferStock.VariantToInvoices.forEach((invoice) => {
        const {
          productVariantId,
          name,
          sku,
          transferQuantity,
          oldCost,
          newCost,
          WeightedVariantToInvoice,
          availableLocationQuantity,
          availableDestnationLocationQuantity,
          requestedQuantity,
        } = invoice;

        invoice.VariantToInvoiceTracks?.forEach((variantToInvoiceTrack) => {
          // eslint-disable-next-line no-param-reassign
          variantToInvoiceTrack.acceptedTransferQuantity = 0;
        });

        transferStockVariants.push({
          name: WeightedVariantToInvoice
            ? `${name} (${WeightedVariantToInvoice.unit})`
            : name,
          sku,
          productVariantId,
          oldCost,
          newCost,
          transferQuantity,
          availableLocationQuantity,
          availableDestnationLocationQuantity,
          quantity: transferQuantity,
          requestedQuantity,
          VariantToInvoiceTracks: invoice.VariantToInvoiceTracks,
          HasTrackNumber:
            invoice.VariantToInvoiceTracks?.length > 0 &&
            invoice.VariantToInvoiceTracks[0].trackNo !== '',
          trackType: invoice?.ProductVariant?.trackType,
        });
      });
      this.transferStockVariants = transferStockVariants;
      this.transferStockVariants.forEach((variant) => {
        if (variant.trackType === 'batch' || variant.trackType === 'serial') {
          this.updateVarinat(variant);
        }
      });
      this.transferStockVariants.forEach((t) =>
        t.VariantToInvoiceTracks?.forEach((variantToInvoiceTrack) => {
          variantToInvoiceTrack.transferQuantity =
            variantToInvoiceTrack.quantity;
          variantToInvoiceTrack.quantity =
            variantToInvoiceTrack.acceptedTransferQuantity;
          const returnedQuantity =
            variantToInvoiceTrack.transferQuantity -
            variantToInvoiceTrack.quantity;
          variantToInvoiceTrack.availableQuantity += returnedQuantity;
        }),
      );

      this.receive();
    } catch (err) {
      this.isProcessing = false;
    }
  }

  updateVarinat(variant) {
    variant.VariantToInvoiceTracks.forEach((track) => {
      track.transferQuantity = track.quantity;
      track.acceptedTransferQuantity = track.quantity;
    });
  }

  receive() {
    this.enableWarnings = true;
    const invoiceId = this.transferStock.id;
    const { notes } = this.transferStock;
    const { receivingDate } = this.pendingForm.value;
    if (!this.pendingForm.valid) {
      this.isProcessing = false;
      return;
    }

    const requestBody = {
      receivingDate,
      notes,
      VariantToInvoices: this.transferStockVariants,
    };
    this.transactions = [requestBody];
    requestBody.receivingDate = requestBody.receivingDate.toISOString();
    this.transferStockService
      .acceptMultipleTransferStock(invoiceId, requestBody)
      .subscribe((response) => {
        this.content.refreshTable();
        this.cancelModal();
      });
  }

  rejectHandler(invoiceId) {
    if (this.transferStock.status === 'Requested') {
      this.transferStockService
        .rejectRequest(invoiceId)
        .subscribe((response) => {
          this.content.refreshTable();
          this.cancelModal();
        });
      return;
    }
    this.dynamicDialogRef = this.transferStockHelperService.openRejectPopUp(
      invoiceId,
      this.reject.bind(this),
      this.cancelModal.bind(this),
    );
  }

  reject(data: any) {
    this.enableWarnings = true;
    const { finalReason, invoiceId } = data;
    const requestBody = {
      rejectReason: finalReason,
    };
    this.transferStockService
      .cancelTransferStock(invoiceId, requestBody)
      .subscribe((response) => {
        this.content.refreshTable();
        this.cancelModal();
      });
  }

  validateRequestForm() {
    const error = this.products.find(
      (prod) => prod.requestedStock > prod.sourceStock,
    );
    if (error) {
      this.enableWarnings = true;
    } else {
      this.enableWarnings = false;
    }
    return !this.enableWarnings;
  }

  completeRequest() {
    const batchVariants = this.getBatchVariants();
    const serialVariants = this.getSerialVariants();

    if (serialVariants.length || batchVariants.length) {
      const modalRef = this.addTrackingInfo(serialVariants, batchVariants);
      this.subscribeToTrackingInfo(modalRef, this.sendAll.bind(this));
    } else {
      this.sendAll();
    }
  }

  getBatchVariants() {
    const batchVariants = [];
    this.requestInvoices.forEach((invoice) => {
      invoice.variants.forEach((variant) => {
        if (variant.trackType === 'batch') {
          delete variant.srcLocationQuantity;
          delete variant.destLocationQuantity;
          variant['New Quantity'] = variant.transferQuantity;
          variant.VariantToInvoiceTracks = [];
          batchVariants.push(variant);
        }
      });
    });
    return batchVariants;
  }

  getSerialVariants() {
    const serialvariants = [];
    this.requestInvoices.forEach((invoice) => {
      invoice.variants.forEach((variant) => {
        if (variant.trackType === 'serial') {
          delete variant.srcLocationQuantity;
          delete variant.destLocationQuantity;
          variant['New Quantity'] = variant.transferQuantity;
          variant.VariantToInvoiceTracks = [];
          serialvariants.push(variant);
        }
      });
    });
    return serialvariants;
  }

  addTrackingInfo(serialVariants, batchVariants) {
    return this.transferStockHelperService.openTrackingInfo(
      serialVariants,
      batchVariants,
      this.requestInvoices[0].stockLocationId,
    );
  }

  subscribeToTrackingInfo(modalRef: DynamicDialogRef, saveFunction) {
    const onClose$ = modalRef.onClose.subscribe((result) => {
      modalRef.destroy();
      onClose$.unsubscribe();
      if (result) {
        saveFunction(result);
      }
    });
  }

  mapRequestVariants(requestInvoice: any) {
    const compositeVariants = [];
    const variants = [];
    if (requestInvoice && requestInvoice.length > 0) {
      requestInvoice[0].variants?.forEach((variant) => {
        if (variant.type === VariantTypes.Composite) {
          compositeVariants.push(variant);
        } else {
          variants.push(variant);
        }
      });
    }
    requestInvoice[0].variants = variants;
    requestInvoice[0].compositeVariants = compositeVariants;
    return requestInvoice;
  }

  sendAll(trackedVaraints?: any) {
    this.prepareTrackedVariants(trackedVaraints);
    if (this.validateRequestForm()) {
      this.transferStockService
        .completeRequest(this.transferStock.id, this.requestInvoices)
        .subscribe((response) => {
          this.content.refreshTable();
          this.cancelModal();
        });
    }
  }

  prepareTrackedVariants(trackedVaraints?) {
    if (trackedVaraints?.batchVariants.length > 0) {
      trackedVaraints.batchVariants.forEach((batchVariant) => {
        this.requestInvoices[0].variants.forEach((variant) => {
          if (variant.productVariantId === batchVariant.productVariantId) {
            variant.VariantToInvoiceTracks =
              batchVariant.VariantToInvoiceTracks;
            variant.srcLocationQuantity = variant.sourceQuantity;
            variant.destLocationQuantity = variant.destinationQuantity;
            delete variant['New Quantity'];
          }
        });
      });
    }

    if (trackedVaraints?.serialVariants.length > 0) {
      trackedVaraints.serialVariants.forEach((serialVariant) => {
        this.requestInvoices[0].variants.forEach((variant) => {
          if (variant.productVariantId === serialVariant.productVariantId) {
            variant.VariantToInvoiceTracks =
              serialVariant.VariantToInvoiceTracks;
            variant.srcLocationQuantity = variant.sourceQuantity;
            variant.destLocationQuantity = variant.destinationQuantity;
            delete variant['New Quantity'];
          }
        });
      });
    }
  }

  isRejectEnabled(): void {
    if (
      this.transferStock.status ===
        TRANSFER_STOCK_STATUSES.PARTIALLY_ACCEPTED &&
      this.transferStock.isRejectedRemoved !== true
    ) {
      this.isRejectButtonEnabled = true;
      return;
    }
    this.isRejectButtonEnabled = false;
  }

  handleRemoveRejectedProducts(): void {
    const transactions = this.removeRejectedService.createTransactions({
      transferInvoice: this.transferStock,
      variantsStockValues: this.variantsStockValues,
    });
    if (transactions) {
      this.store.dispatch(
        removeStockActions.createRemoveStock({ removeStock: transactions }),
      );
    }
  }

  cancelModal(): void {
    this.dynamicDialogRef.destroy();
    this.element[0]?.classList.remove('preview');
    this.content.cancelTable();
  }

  handlePermission(): void {
    this.permissions.transferStockDetailViewCost =
      !!this.permissionService.isPermissionExist(
        StockControlPermissions.TransferStockDetailViewCost,
      );

    this.permissions.transferStockDetailUpdate =
      !!this.permissionService.isPermissionExist(
        StockControlPermissions.TransferStockDetailUpdate,
      );
  }
}
