import { Injectable } from '@angular/core';
import {
  CollectionReference,
  DocumentData,
  Firestore,
  Query,
  Timestamp,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { FirebaseAppService } from './firebase-app.service';
import { Merchant } from '../../model/Merchant';
import { FirebaseCollections } from '../enum/firebase-constants';
import { NotificationType } from '../../../firebase-notifications/enums';

@Injectable()
export class FirebaseQueryProviderService {
  constructor(
    private firebaseService: FirebaseAppService,
  ) {
  }

  limit = 20;

  buildLoadNextNotificationQuery(user: Merchant, lastNotification) {
    return query(
      this.getNotificationCollection(),
      where('s', '==', false),
      where('create', '>=', Timestamp.fromDate(this.getCreatedAtFilter())),
      where('oid', 'array-contains-any', [`${user.schemaName}-${user.id}`, user.schemaName, 'null']),
      orderBy('create', 'desc'),
      startAfter(lastNotification),
      limit(this.limit),
    );
  }

  buildGetLatestNotificationQuery(user: Merchant): Query<DocumentData> {
    return query(
      this.getNotificationCollection(),
      where('t', '==', NotificationType.OfflineInvoiceSynced),
      where('read', '==', false),
      where('oid', 'array-contains-any', [
        `${user.schemaName}-${user.id}`,
        user.schemaName,
        'null',
      ]),
      orderBy('create', 'desc'),
    );
  }

  buildInitialNotificationQuery(user: Merchant, isSilent: boolean = false, docCount = 20) {
    return query(
      this.getNotificationCollection(),
      where('s', '==', isSilent),
      where('create', '>=', Timestamp.fromDate(this.getCreatedAtFilter())),
      where('oid', 'array-contains-any', [`${user.schemaName}-${user.id}`, user.schemaName, 'null']),
      orderBy('create', 'desc'),
      limit(docCount),
    );
  }

  buildGetFirebaseUserQuery(userCollection: CollectionReference<DocumentData>, schemaName: string, userId: number) {
    return query(
      userCollection,
      where('mid', '==', schemaName),
      where('uid', '==', `${userId}`),
      limit(1),
    );
  }

  private getCreatedAtFilter() : Date {
    const createdAtFilter = new Date();
    createdAtFilter.setDate(createdAtFilter.getDate() - 30);
    return createdAtFilter;
  }

  private getNotificationCollection() {
    return collection(this.getFirestore(), FirebaseCollections.Notifications);
  }

  private getFirestore() : Firestore {
    return this.firebaseService.getFirestore();
  }
}
