import {
  CustomerInvoiceStatus,
  DiscountType,
  InvoiceStatus,
  InvoiceType,
  PayableInvoicePaymentStatus,
  PaymentOption,
  PaymentType,
  SellType,
  TemplateVersion,
  TransactionType,
  ZatcaVerificationStatus,
} from './enums';
import {
  ProductType,
  TrackType,
  VariantType,
  WeightedVariantUnit,
} from '../../inventory/product/enums';
import { Extra, Tax, Variant, ECard } from '../../inventory/product/types';
import { CustomFieldData, TaxType } from '../../common';
import { StockLocation } from '../../inventory/stock-location';

export interface VariantToInvoicePack {
  rate: number;
  sku: string;
  name: string;
  availableLocationQuantity: number;
  id?: number;
  variantToInvoiceId?: number;
  productVariantId: number;
  parentSku?: string;
  VariantToInvoice?: any;
  ProductVariant?: Variant;
}

export interface CompoundTax {
  id: number;
  name: string;
  rate: number;
  taxId: number;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
}

export interface TaxBreakdown extends Tax {
  taxableAmount: number;
  totalAmount: number;
  CoumpoundTax?: CompoundTax;
  subtotal?: number;
  createdAt?: string;
  deletedAt?: string;
  updatedAt?: string;
}

export interface VariantToInvoiceExtra {
  sku: string;
  price: number;
  extraId: number;
  availableLocationQuantity: number;
  quantity: number;
  taxAmount: number;
  taxJson: string;
  rate: number;
  name: string;
  productVariantId: number;
  totalExclusive?: number;
  taxAfterDiscount?: number;
  id?: number;
}

export interface CompositeVariantToInvoicePart {
  sku: string;
  rate: number;
  name: string;
  productVariantId: number;
  type: VariantType;
  availableLocationQuantity: number;
}

export interface VariantToInvoiceTrack {
  trackNo: string;
  quantity: number;
  availableQuantity?: number;
  issueDate?: Date | string;
  expiryDate?: Date | string;
  trackType: TrackType;
  variantToTrackId?: number;
  variantToInvoiceId?: number;
  transferQuantity?: number;
  productVariantToStockLocationId?: number;
  supplierId?: number;
  id?: number;
}

export interface VariantToTrack {
  id?: number;
  trackNo: string;
  stockLocationId: number;
  supplierId: number;
  productVariantId: number;
  quantity: number;
  issueDate: Date;
  expiryDate: Date;
  location?: string;
  supplier?: string;
}

export interface InvoiceProductBatch {
  trackNo: string;
  quantity: number;
}

export class SellProductExtra {
  price: number;
  quantity: number;
  extra?: Extra;
  id: number;
}

export interface Promotion {
  id: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  type: string;
  discountType: string;
  amount: number;
  isAllProducts?: boolean;
  isAllLocations?: boolean;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: any;
  isChecked?: boolean;
  status?: any;
  PromotionStockLocations: [];
  PromotionVariants: [];
}

export interface VariantToInvoiceEcard {
  code: string;
  ecardId: number;
  variantToInvoiceId?: number;
  id?: number;
}

export interface SellProduct {
  id?: number;
  sno?: number;
  variant: Variant;
  sku: string;
  name: string;
  quantity: number;
  price: number;
  initPrice: number;
  ecards?: string[];
  actualCost?: number;
  discountPercentage?: number;
  priceAfterDiscount?: number;
  serials?: string[];
  batches?: { trackNo: string; quantity: number }[];
  expand?: boolean;
  tax?: Tax;
  childrens?: Array<any>;
  extras?: SellProductExtra[];
  cartToken?: string;
  discount: number;
  calculatedPromotionAmount?: number;
  generalDiscountPercentage?: number;
  promotion?: Promotion;
  promotionId?: number;
  priceAfterPromotion?: number;
  promotionAmount?: number;
  promotionQuantity?: number;
  mainPrice?: number;
}

export interface PaymentMethod {
  serviceCharge?: number;
  name: string;
  description: string;
  type: string;
  isDefault: boolean;
  id: number;
  bankId?: number;
  bankName?: string;
}

export interface SellInvoicePayment {
  type: PaymentType;
  amount: number;
  paymentNumber?: number;
  displayPaymentNumber?: string;
  debitAmount?: number;
  PaymentMethod: PaymentMethod;
}

export interface InternalPosSetting {
  settingName: string;
  settingValue?: string;
}

export interface SellInvoice {
  notes?: string;
  userId?: number;
  customerId?: number;
  taxation?: TaxType;
  products: SellProduct[];
  payments?: SellInvoicePayment[];
  restForCustomer?: number;
  invoiceId?: number;
  supplyDate?: string;
  generalDiscountAmount?: number;
  generalDiscountType?: DiscountType;
  salesmanDetails?: { id: number; name: string };
  sellType: SellType;
  timezoneOffset?: string;
  displayInvoiceNumber?: string;
  orderNumber?: number;
  settings?: TemplateSettings;
  registerId?: number;
  variantToInvoices?: VariantToInvoice[];
  shiftId: number;
  completeDate?: string;
  templateVersionNumber?: TemplateVersion;
  templateSettings?: TemplateSettings;
  version: number;
  offlineFirst?: boolean;
  paymentDueDate: string;
  // Will change this
  promotionGroups: any[];
  customFieldsData?: CustomFieldData[];
  transactionReferenceId?: string;
}

export interface TemplateSettings {
  companyLogo?: boolean;
  salesNote?: boolean;
  customerLocation?: boolean;
  skuBarcode?: boolean;
  tax?: boolean;
  discount?: boolean;
  hideNaDiscountFooter?: boolean;
  invoiceBarcode?: boolean;
  showComment?: boolean;
  comment?: string;
  printLayout: string;
  textSize: number;
  printLanguage: string;
  compositeChilds?: boolean;
  serialNumber?: boolean;
  batchNumberBatchQty?: boolean;
  packRateMainProduct?: boolean;
  weightUnit?: boolean;
  totalItems?: boolean;
  qrCode?: boolean;
  displaySellInvoiceNumberInReturnReceipt?: boolean;
  taxPercentage?: boolean;
  customerVATNumber?: boolean;
  commentImage?: string;
  showCommentImage?: boolean;
  hideNaPromotionFooter?: boolean;
  promotion?: boolean;
  customerPhoneNumber?: boolean;
  salesmanInvoice?: boolean;
  salesmanReceipt?: boolean;
  includePrice?: boolean;
  includeTotal?: boolean;
  displaySectionC?: boolean;
  forAllCategories?: boolean;
  forUnCategorisedProducts?: boolean;
  dateOfSupply?: boolean;
  sellOnB2B?: boolean;
  termsOfService?: boolean;
  thanksYouImg?: boolean;
  customerAdditionalIdentifier?: boolean;
  customerIdentifierType?: string;
  commercialNumber?: boolean;
  address?: boolean;
  customerName?: boolean;
  totalTaxInclusive?: boolean;
  totalTaxExclusive?: boolean;
  orderNumberEnabled?: boolean;
  orderNumberStart?: number;
}

export interface WeightedVariantToInvoice {
  unit: WeightedVariantUnit;
  id: number;
  variantToInvoiceId: number;
  VariantToInvoice?: any;
}

export interface VariantToInvoice {
  sku: string;
  name: string;
  quantity: number;
  changedQuantity?: number;
  availableDestnationLocationQuantity?: number;
  availableLocationQuantity: number;
  costInclusive?: number;
  buyPriceExclusive?: number;
  costExclusive: number;
  totalExclusive: number;
  totalInclusive?: number;
  taxAmount: number;
  discount: number;
  id?: number;
  invoiceId?: number;
  productVariantId?: number;
  ProductVariant?: Variant;
  Tax?: Tax;
  taxJson?: string;
  CompositeVariantToInvoiceParts?: CompositeVariantToInvoicePart[];
  VariantToInvoicePacks?: VariantToInvoicePack[];
  VariantToInvoiceExtras?: VariantToInvoiceExtra[];
  type?: VariantType;
  newCost?: number;
  oldCost?: number;
  trackType?: TrackType;
  VariantToInvoiceTracks?: VariantToInvoiceTrack[];
  VariantToInvoiceEcards?: VariantToInvoiceEcard[];
  Ecards?: ECard[];
  deletedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  WeightedVariantToInvoice?: {
    unit: WeightedVariantUnit;
  };
  promotionName?: string;
  promotionAmount?: number;
  /**
   * The promotion applied on this line item
   */
  promotion?: Promotion;
  promotionQuantity: number;
  PromotionToInvoiceVariant?: any;
  costDiscountedTaxExclusive?: number;
  sno: number;
  originalPriceEx?: number;
  originalPriceInc?: number;
  discountRate?: number;
  packCost?: number;
  productType?: ProductType;
  taxId: number;
  CustomFieldsData?: CustomFieldData[];
  unitPromotionPriceDiscountedTaxInclusive?: number;
  unitPromotionPriceDiscountedTaxExclusive?: number;
  unitPriceDiscountedTaxInclusive?: number;
  unitPriceDiscountedTaxExclusive?: number;
  priceAfterPromoOrDiscount: number;
}

export interface Invoice {
  status: InvoiceStatus;
  registerJson: string;
  displayInvoiceNumber?: string;
  companyName: string;
  userId?: number;
  notes?: string;
  orderNumber?: number;
  customerName?: string;
  customerId?: number;
  customerMobileNumber?: string;
  type: InvoiceType;
  registerId: number;
  stockLocationName: string;
  stockLocationId: number;
  subTotalTaxExclusive: number;
  totalTax: number;
  totalTaxInclusive: number;
  supplyDate?: Date | string;
  templateVersionNumber?: TemplateVersion;
  PayableInvoice: {
    discountAmount: number;
    discountType?: DiscountType;
    taxation?: TaxType;
    restForCustomer?: number;
    paymentOption: PaymentOption;
    totalBeforePayment: number;
    totalAfterPayment: number;
    paidAmount: number;
    debitAmount: number;
    paymentStatus?: PayableInvoicePaymentStatus;
    paymentMethod?: string;
    paymentMethodId?: number;
    paymentDueDate: Date | number;
  };
  VariantToInvoices: VariantToInvoice[];
  completeDate?: Date;
  salesmanUsername?: string;
  salesmanId?: number;
  sellType: SellType;
  payments: SellInvoicePayment[];
  invoiceNumber?: number | string;
  settings?: any;
  totalDiscountExclusive?: number;
  subtotalExclusive?: number;
  version: number;
  isOfflineFirst?: boolean;
  uniqueInvoiceNumber?: string;
  promotionGroups: any[];
  CustomFieldsData?: CustomFieldData[];
}

export interface MappedCustomerInvoice extends Invoice {
  totalPromotionsAmount?: number;
  taxCategories?: any;
  invoiceId?: number;
  Customer?: Customer;
  userName?: string;
  companyVatNumber?: string;
  customerVatNumber?: string;
  transactionType: TransactionType;
  shiftId: number;
  referenceId?: string;
  zatcaStatus: ZatcaVerificationStatus;
  isOffline: boolean;
  saleInvoiceId?: number;
  isReturnInvoice?: number;
  sequenceNo?: number;
  invoiceXML?: string;
  invoiceHash?: string;
  customerInvoiceStatus?: CustomerInvoiceStatus;
  promotionGroups: any[];
}

export interface CustomerInvoice {
  id?: number;
  customerId?: number;
  invoiceId?: number;
  saleInvoiceId?: number;
  invoiceNumber: string;
  isReturnInvoice?: boolean;
  isOffline?: boolean;
  invoiceHash?: string;
  invoiceXML?: string;
  sequenceNo?: number;
  transactionType: TransactionType;
  zatcaStatus: ZatcaVerificationStatus;
  invoice: MappedCustomerInvoice;
  version: number;
  registerId: number;
  shiftId: number;
  referenceId?: string;
  status?: CustomerInvoiceStatus;
}

export interface CustomerInvoiceData {
  transactionType: TransactionType;
  shiftId: number;
  version?: number;
  invoiceNumber: string;
  invoiceId?: number;
  referenceId?: string;
  isPayingUsingSoftPos?: boolean;
  status?: CustomerInvoiceStatus;
  customer?: Customer;
  userName?: string;
  companyVatNumber?: string;
  customerVatNumber?: string;
  isOffline?: boolean;
}

export interface Customer {
  id: number;
  name: string;
  code?: string;
  mobileNumber?: string;
  vatNumber?: string;
  email?: string;
  birthDate?: Date;
  gender?: string;
  shippingAddress?: string;
  billingAddress?: string;
  shippingToAddress?: string;
  buildingNo?: number;
  streetName?: string;
  suburb?: string;
  city?: string;
  state?: string;
  zip?: number;
  countryKey?: string;
  district?: string;
  additionalNumber?: number;
  notes?: string;
  debitAmount?: number;
  totalPaid?: number;
  index?: number;
  commercialRegisterNumber?: string;
  nationalId?: string;
  CustomFieldsData?: CustomFieldData[];
}

export interface Register {
  name: string;
  description: string;
  status: string;
  isDefault: boolean;
  layoutId?: number;
  openedByUserId?: number;
  id: number;
  stockLocationId?: number;
  stockLocationName?: string;
  StockLocation?: StockLocation;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: string;
  layoutName?: string;
  shiftId?: number;
}

export interface TaxConfiguration {
  sellTaxation?: string;
  costTaxation?: string;
  id?: number;
  Tax?: Tax;
  taxId?: number;
  costTaxStatus?: string;
  defaultTaxRate?: string;
  sellTaxStatus?: string;
}

export enum DiscountLevelType {
  Product = 'Product',
  Invoice = 'Invoice',
  Promotion = 'Promotion',
}

export const PaymentSourceConstant = {
  ReceiveDebit: 'ReceiveDebit',
  SellInvoice: 'SellInvoice',
  ReturnInvoice: 'ReturnInvoice',
} as const;

export type PaymentSource =
  (typeof PaymentSourceConstant)[keyof typeof PaymentSourceConstant];
