import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FeatureFlagService } from '../types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../constants/feature-flag.constants';
import { OfflineFirstFlagValuesDefault } from '../types/constants';
import { OfflineFirstFlagValues } from '../types/types';
import { OnlineOfflineService } from './online-offline.service';
import { DeviceInfoService } from '../../../core/services/device-info.service';

@Injectable()
export class OfflineFirstService {
  private productsLoaded = false;

  private promotionsLoaded = false;

  private posRebornEnabled = false;

  private offlineFirstFlag: OfflineFirstFlagValues =
    OfflineFirstFlagValuesDefault;

  productsLoaded$ = new Subject<boolean>();

  constructor(
    private readonly onlineOfflineService: OnlineOfflineService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly deviceInfoService: DeviceInfoService,
  ) {
    this.featureFlagService
      .variation(FeatureFlagEnum.OfflineFirst, OfflineFirstFlagValuesDefault)
      .subscribe((flagValue) => {
        this.offlineFirstFlag = flagValue;
      });

      this.featureFlagService
      .variation(FeatureFlagEnum.PosReborn, false)
      .subscribe((flagValue) => {
        this.posRebornEnabled = flagValue;
      });
  }

  public setProductsLoaded(value: boolean): void {
    this.productsLoaded = value;
    this.productsLoaded$.next(true);
  }

  public setPromotionsLoaded(value: boolean): void {
    this.promotionsLoaded = value;
  }

  get productsEnabled(): boolean {
    return (
      this.offlineFirstFlag.products &&
      this.productsLoaded &&
      this.promotionsLoaded &&
      this.onlineOfflineService.isOnline &&
      this.deviceInfoService.isDesktop &&
      this.deviceInfoService.isChrome
    );
  }

  get invoicesEnabled(): boolean {
    return (
      this.offlineFirstFlag.invoices &&
      this.productsLoaded &&
      this.promotionsLoaded &&
      this.onlineOfflineService.isOnline &&
      this.deviceInfoService.isDesktop &&
      this.deviceInfoService.isChrome
    );
  }

  get customersEnabled(): boolean {
    return (
      this.offlineFirstFlag.customers &&
      this.onlineOfflineService.isOnline &&
      this.deviceInfoService.isDesktop &&
      this.deviceInfoService.isChrome &&
      !this.posRebornEnabled
    );
  }
}
