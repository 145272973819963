import {
  ElementRef,
  Input,
  OnChanges,
  AfterViewInit,
  Component,
  QueryList,
  ViewChildren,
  SimpleChanges,
} from '@angular/core';
import { BARCODE_SIZE_REVAMP, LocalStorageKey } from 'src/app/shared/constants';
import JsBarcode from 'jsbarcode';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-generate-barcode',
  templateUrl: './generate-barcode.component.html',
  styleUrls: ['./generate-barcode.component.scss'],
})
export class GenerateBarcodeComponent implements OnChanges, AfterViewInit {
  @ViewChildren('barcode') barcodeArray: QueryList<ElementRef>;

  @Input() variantInfoArray: {
    name: string;
    price: number;
    originalPrice: number;
    code: string;
  }[];

  @Input() printFeatures: {
    includeName: boolean;
    includeCompanyName: boolean;
    includePrice: boolean;
    includeTaxType: boolean;
    includeBarcode: boolean;
  };

  @Input() companyName: string;

  @Input() taxStatus: string;

  @Input() type: string;

  lng: string;

  langCss: string;

  barcodeSize = BARCODE_SIZE_REVAMP;

  localType = 'halo';

  fontSizeAndClassNameMapForStandard = {
    1: {
      fontClassName: 'standard-product-name-font-12px',
      maxCharactersInLine: 30,
    },
    2: {
      fontClassName: 'standard-product-name-font-10px',
      maxCharactersInLine: 32,
    },
    3: {
      fontClassName: 'standard-product-name-font-10px',
      maxCharactersInLine: 32,
    },
    4: {
      fontClassName: 'standard-product-name-font-9px',
      maxCharactersInLine: 34,
    },
    5: {
      fontClassName: 'standard-product-name-font-9px',
      maxCharactersInLine: 34,
    },
    6: {
      fontClassName: 'standard-product-name-font-8px',
      maxCharactersInLine: 38,
    },
    7: {
      fontClassName: 'standard-product-name-font-8px',
      maxCharactersInLine: 38,
    },
    8: {
      fontClassName: 'standard-product-name-font-7px',
      maxCharactersInLine: 42,
    },
    9: {
      fontClassName: 'standard-product-name-font-7px',
      maxCharactersInLine: 42,
    },
    10: {
      fontClassName: 'standard-product-name-font-6px',
      maxCharactersInLine: 60,
    },
  };

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ngAfterViewInit() {
    this.generateBarcodesForElementReference();
    this.barcodeArray.changes.subscribe(() => {
      this.generateBarcodesForElementReference();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ngOnChanges(changes: SimpleChanges) {
    if (changes.type && changes.type.firstChange === false) {
      this.generateBarcodesForElementReference();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  generateBarcodesForElementReference() {
    this.barcodeArray.forEach((element, index) => {
      JsBarcode(element.nativeElement, this.variantInfoArray[index].code, {
        format: 'CODE128',
        width: this.barcodeSize[this.type].width,
        height: this.barcodeSize[this.type].height,
        fontSize: this.barcodeSize[this.type].fontSize,
        margin: 0,
        textMargin: 0,
      });
    });
  }

  getClassForProductNameOnly(name: string): string {
    if (this.printFeatures.includeBarcode) {
      const fontClassName = this.getProductNameClassWithVariableFonts(name);
      return this.type === 'STANDARD'
        ? `product-name-standard-new ${fontClassName}`
        : 'product-name-large-new';
    }
    return this.type === 'STANDARD'
      ? 'product-name-standard-no-barcode'
      : 'product-name-large-no-barcode';
  }

  getClassProductName(): string {
    if (this.printFeatures.includeBarcode) {
      return this.type === 'STANDARD'
        ? 'product-name-standard'
        : 'product-name-large';
    }
    return this.type === 'STANDARD'
      ? 'product-name-standard-no-barcode'
      : 'product-name-large-no-barcode';
  }

  getProductNameClassWithVariableFonts(name: string): string {
    let fontKey = 1;
    if (name.length > 30) {
      fontKey = Math.ceil((name.length - 30) / 7);
      fontKey = fontKey > 10 ? 10 : fontKey;
    }
    return this.fontSizeAndClassNameMapForStandard[fontKey.toString()]
      .fontClassName;
  }

  getClassProductPrice(): string {
    if (this.printFeatures.includeBarcode) {
      return this.type === 'STANDARD'
        ? 'product-price-standard'
        : 'product-price-large';
    }
    return this.type === 'STANDARD'
      ? 'product-price-standard-no-barcode'
      : 'product-price-large-no-barcode';
  }
}
