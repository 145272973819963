import {
  CanLoad, Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MerchantService } from './merchant.service';
import { AuthService } from './auth.service';
import { AuthUser } from '../auth.models';

@Injectable()
export class GuardService implements CanLoad {
  constructor(
    private router: Router,
    private authService: AuthService,
    private merchantService: MerchantService,
  ) {
  }

  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated()
      .pipe(
        map((auth) => {
          if (!auth) {
            this.router.navigate(['/login']);
          }
          return auth;
        }),
        switchMap((authenticated) => {
          if (authenticated) {
            const user = this.authService.getAuthenticatedUser();
            return this.merchantService.getMerchantByEmail(user.getUsername());
          }
          this.router.navigate(['/login']);
          return Observable.create((o) => o.next(false));
        }),
        map((user: AuthUser) => user.status),
        map((status) => {
          if (status === 'Pending') {
            return false;
          }
          if (status === 'Active') {
            return true;
          }
          if (!status) {
            return false;
          }
          return false;
        }),
        /* tap(() => this.spinnerService.stopLoading()), */
        catchError(() => {
          const error = 'Internal system error';
          this.router.navigateByUrl('/error', { state: { error } });
          return of(false);
        }),
      );
  }
}
