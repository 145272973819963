import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Identifier } from 'src/app/internal-apps/pos/model/Quotation.types';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { formatDateToYYYYMMDDWithNoExtra } from 'src/app/shared/constants/date-formats.constants';
import { Quotation } from '../model/Quotation';

const API_URL = '/api';
@Injectable()
export class QuoteService {
  constructor(private http: HttpClient) { }

  getAllQuotes(query): Observable<{ result: Quotation[]; total: number }> {
    const productQueryParams = new HttpParams()
      .set('offset', query.offset ? query.offset : 0)
      .set('limit', query.limit ? query.limit : '')
      .set('search', query.search ? query.search : '')
      .set('id', query.id ? query.id : '')
      .set('terms', query.terms ? query.terms : '')
      .set('location', query.location ? query.location : '')
      .set('register', query.register ? query.register : '')
      .set('issuerName', query.issuerName ? query.issuerName : '')
      .set('customerName', query.customerName ? query.customerName : '')
      .set('customerPhoneNumber', query.customerPhoneNumber ? query.customerPhoneNumber : '')
      .set('totalQuantity', query.totalQuantity ? query.totalQuantity : '')
      .set('totalQuantityOP', query.totalQuantityOP ? query.totalQuantityOP : '')
      .set('totalPriceTaxInclusive', query.totalPriceTaxInclusive ? query.totalPriceTaxInclusive : '')
      .set('totalPriceTaxInclusiveOP', query.totalPriceTaxInclusiveOP ? query.totalPriceTaxInclusiveOP : '')
      .set('expiryDateFromDate', query.expiryDateFromDate ? query.expiryDateFromDate : '')
      .set('expiryDateToDate', query.expiryDateToDate ? query.expiryDateToDate : '')
      .set('issueDateFromDate', query.issueDateFromDate ? query.issueDateFromDate : '')
      .set('issueDateToDate', query.issueDateToDate ? query.issueDateToDate : '')
      .set('sortBy', query.sortBy ? query.sortBy : '');

    return this.http.get<{ result: Quotation[]; total: number }>(
      `${API_URL}/quotations`,
      {
        params: productQueryParams,
      },
    );
  }

  getStockLocations(): Observable<Identifier[]> {
    return this.http.get<Identifier[]>(`${API_URL}/stock-location`)
      .pipe(map((response) => response.map((e) => (<Identifier>{ id: e.id, name: e.name }))));
  }

  getRegisters(): Observable<Identifier[]> {
    return this.http.get<Identifier[]>(`${API_URL}/pos/registers`)
      .pipe(map((response) => response.map((e) => (<Identifier>{ id: e.id, name: e.name }))));
  }

  getQuoteDropdownLists(): Observable<Identifier[]> {
    return this.http.get<Identifier[]>(`${API_URL}/quotations-dropdownlist`)
      .pipe(map((response) => response.map((e) => (<Identifier>{ id: e.id, name: `Q${DateTime.fromISO(e.name).toFormat(formatDateToYYYYMMDDWithNoExtra)}-${e.id}` }))));
  }

  getQuote(quoteId: number): Observable<Quotation> {
    return this.http.get<any>(`${API_URL}/quotations/${quoteId}`);
  }

  addQuotation(quote: any): Observable<any> {
    return this.http.post<any[]>(`${API_URL}/quotations`, quote);
  }
}
