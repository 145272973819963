import { Injectable } from '@angular/core';

@Injectable()
export class LeftShiftService {
  public applyLeftShift(
    newVal: number | string,
    existingValue: string,
  ): string | undefined {
    /**
     * Remove decimal
     */
    const discountAmount: string = existingValue.replace('.', '');

    /**
     * Check if a zero is input with an existing value of '0.00'
     */
    if ((newVal === 0 || newVal === '0') && this.allZeroes(discountAmount)) {
      return undefined;
    }

    const newValue = `${discountAmount}${newVal}`;

    /**
     * Add back decimal
     */
    let newDiscountAmount = `${newValue.slice(0, -2)}.${newValue.slice(-2)}`;

    newDiscountAmount = this.removeLeadingZeroes(newDiscountAmount);

    return newDiscountAmount;
  }

  private allZeroes(str: string): boolean {
    return /^0+$/.test(str);
  }

  private removeLeadingZeroes(number: string): string {
    const integerPart = number.split('.')[0];
    const decimalPart = number.split('.')[1];

    let afterLeadingZeroesRemoved = integerPart.replace(/^0+(?=\d+$)/, '');

    if (afterLeadingZeroesRemoved === '') {
      afterLeadingZeroesRemoved = '0';
    }

    return `${afterLeadingZeroesRemoved}.${decimalPart}`;
  }

  public doDeletionAndReturn(oldValue: string): string {
    if (oldValue.length < 1) {
      return '0.00';
    }
    /**
     * Remove decimal
     */
    const discountAmount: string = oldValue.replace('.', '');

    /**
     * If all zeroes left, don't operate
     */
    if (this.allZeroes(discountAmount)) {
      return '0.00';
    }

    /**
     * Remove last character
     */
    let newValue: string = discountAmount.slice(0, -1);

    if (newValue.length < 3) {
      /**
       * Append a zero if length goes down to 2
       */
      newValue = `0${newValue}`;
    }

    const valueAfterDeletion = `${newValue.slice(0, -2)}.${newValue.slice(-2)}`;
    return valueAfterDeletion;
  }
}
