export class StockLocation {
  id: number;
  name: string;
  description?: string;
  isActive?: boolean;
  isDefault?: boolean;
  countryKey?: string;
  country?: string;
  city?: string;
  buildingNo?: string;
  additionalNumber?: number;
  zipCode?: string;
  streetName?: string;
  districtName?: string;
  code?: string;
  commercialRegisterNumber?: number;
}
