import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSetupComponent } from './components/account-setup/account-setup.component';

export const coreRoutes: Routes = [
  { path: 'account-setup', component: AccountSetupComponent },
];

@NgModule({
  imports: [RouterModule.forChild(coreRoutes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {
}
