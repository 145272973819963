import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const API_URL = '/api';

@Injectable()
export class ImportProductsService {
  constructor(private http: HttpClient) { }

  importProducts(fileName, isConfirmed, templateData) {
    return this.http.post<{ importStarted: boolean }>(`${API_URL}/products/import?isConfirmed=${isConfirmed}`, {
      fileName, ...templateData,
    });
  }

  getImportProgress(fileName, type, merchantId) {
    return this.http.get<{ importStarted: boolean, importResultUrl?: string, lastProcessedSku? :string, processedRows: number }>(`${API_URL}/products/import/progress?fileName=${fileName}&merchantId=${merchantId}&type=${type}`);
  }

  cancelImport(fileName, merchantId) {
    return this.http.post<{ importCanceled: boolean }>(`${API_URL}/products/import/cancel`, {
      fileName, merchantId,
    });
  }

  updateProducts(fileName, isConfirmed, templateData) {
    return this.http.post<{ importStarted: boolean, updateId: string }>(`${API_URL}/products/bulk-update?isConfirmed=${isConfirmed}`, {
      fileName, ...templateData,
    });
  }

  getUpdateProgress(fileName, selectedType, updateId, withItems = false) {
    return this.http.get<{ importStarted?: boolean, status?: any, items?:any[], updateId?: string, errors?: string[] }>(`${API_URL}/products/bulk-update/progress?fileName=${fileName}&updateId=${updateId}&type=${selectedType}&withItems=${withItems}`);
  }

  acceptBulkUpdateProducts(updateId:string,type?:string) {
    return this.http.post<{ updateId: string }>(`${API_URL}/products/bulk-update/accept?updateId=${updateId}`, {type});
  }

  cancelUpdate(fileName, merchantId) {
    // Backend is not done for cancel update
    return this.http.post<{ importCanceled: boolean }>(`${API_URL}/products/import/cancel`, {
      fileName, merchantId,
    });
  }

  getProductTrackTemplates(trackType: 'batch' | 'serial'): Observable<any> {
    return this.http.get<any>(`${API_URL}/products/track/${trackType}/import/templates`);
  }

  importTracks(trackType: 'batch' | 'serial', fileName: string, templateProps: any): Observable<any> {
    return this.http.post<any>(`${API_URL}/products/track/${trackType}/import`, { ...templateProps, fileName });
  }
}
