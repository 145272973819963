export class Country {
  id: number;

  countryKey: string;

  name: string;

  code: string;

  currency: string;

  language: string;

  constructor(country?) {
    Object.assign(this, country || {});
  }

  get label() {
    return this.name;
  }

  get value() {
    return this.id;
  }

  W;
}
