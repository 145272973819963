import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule as PrimeBreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [TranslateModule, PrimeBreadcrumbModule, CommonModule],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
