import { InvoicesCalculator } from 'src/app/invoices/utilities/invoices.calculator';
import { TAX_TYPES } from 'src/app/shared/constants';
import { ITaxConfiguration } from 'src/app/shared/model/TaxConfiguration';
import { DiscountTypes } from '../../promotion/utills/constants';
import { Quotation, QuotationDiscountJson } from '../model/Quotation';
import { VariantToQuotation } from '../model/VariantToQuotation';

export const getPromotionPrice = (variantToQuote: VariantToQuotation) => {
  const promotionJson = variantToQuote.promotionsJson;
  const promotion = promotionJson ? JSON.parse(promotionJson) : [];

  const priceBeforePromotionTaxInclusive = variantToQuote.priceTaxExclusive + variantToQuote.tax;

  if (!promotionJson) {
    return 0;
  }

  if (promotion.discountType === DiscountTypes.FIXED) {
    return promotion.amount;
  }

  if (promotion.discountType === DiscountTypes.PERCENTAGE) {
    return InvoicesCalculator.multiply(
      InvoicesCalculator.divide(promotion.amount, 100),
      priceBeforePromotionTaxInclusive,
    );
  }

  return 0;
};

// eslint-disable-next-line max-len
export const getProductDiscountPrice = (variantToQuote: VariantToQuotation) => variantToQuote.discount ?? 0;

// eslint-disable-next-line max-len
export const getProductPriceWithPromotionsAndDiscountTaxInclusive = (variantToQuote: VariantToQuotation) => {
  const priceBeforePromotionTaxInclusive = variantToQuote.priceTaxExclusive + variantToQuote.tax;

  const promotionPrice = getPromotionPrice(variantToQuote);

  const discountPrice = promotionPrice ?? getProductDiscountPrice(variantToQuote);

  const promotionAndDiscount = Math.max(promotionPrice, discountPrice);

  const promotionAndDiscountAdjustedPrice = InvoicesCalculator.subtract(
    priceBeforePromotionTaxInclusive,
    promotionAndDiscount,
  );

  return Math.max(
    promotionAndDiscountAdjustedPrice,
    0,
  );
};

export const getGeneralDiscount = (quote: Quotation) => {
  const { discountsJson } = quote;
  const discountArr: QuotationDiscountJson = discountsJson ? JSON.parse(discountsJson) : [];

  const discount = discountArr.shift();

  return discount;
};

export const getGeneralDiscountAbsoluteValue = (quote: Quotation) => {
  const discount = getGeneralDiscount(quote);

  if (!discount) {
    return 0;
  }

  if (discount.type === DiscountTypes.FIXED) {
    return discount.discount;
  }

  if (discount.type === DiscountTypes.PERCENTAGE) {
    const percentage = InvoicesCalculator.divide(
      discount.discount,
      100,
    );

    const subTotalTaxInclusive = InvoicesCalculator.add(
      quote.subtotalTaxExclusive,
      quote.totalTax,
    );

    const subTotalTaxInclusiveDiscountExclusive = InvoicesCalculator.subtract(
      subTotalTaxInclusive,
      quote.totalDiscount,
    );

    return InvoicesCalculator.multiply(
      percentage,
      subTotalTaxInclusiveDiscountExclusive,
    );
  }

  return 0;
};

export const getGeneralDiscountActualValue = (quote: Quotation) => {
  const discount = getGeneralDiscount(quote);

  if (discount) {
    if (discount.type === DiscountTypes.FIXED) {
      return discount.value;
    }
  }

  return 0;
};

export const getSellTaxExclusivePriceWhenTaxIsInclusive = (
  price: number,
  taxRate: number,
) => {
  const taxRateDivide = InvoicesCalculator.add(1, InvoicesCalculator
    .divide(taxRate, 100));

  return InvoicesCalculator.divide(
    price,
    taxRateDivide,
  );
};

export const getSellTaxExclusivePrice = (
  price: number,
  taxRate: number,
  priceConfiguration: ITaxConfiguration,
) => {
  if (priceConfiguration.sellTaxStatus === TAX_TYPES.INCLUSIVE) {
    return getSellTaxExclusivePriceWhenTaxIsInclusive(
      price,
      taxRate,
    );
  }

  return price;
};
