import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface IConfiguration {
  id?: number;
  name: ConfigurationName;
  value: any;
  groupKey: ConfigurationGroup;
}

export enum ConfigurationGroup {
  ADVANCE_ACCOUNTING = 'advance-accounting',
}

export enum ConfigurationName {
  SETUP = 'setup',
}

const API_URL = '/api';

@Injectable()
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  get(name: string, group?: string) {
    const options = {
      params: new HttpParams()
        .set('group', group)
        .set('name', name),
    };
    return this.http.get<IConfiguration>(`${API_URL}/configuration`, options);
  }

  set(config: IConfiguration) {
    return this.http.post<IConfiguration>(`${API_URL}/configuration`, config);
  }
}
