<router-outlet></router-outlet>
<rw-app-spinner *ngIf="spinnerService._loading$ | async"></rw-app-spinner>
<p-toast position="{{lng === 'en' ? 'top-right':'top-left'}}"></p-toast>
<p-toast key="paycreditmessage" position="{{lng === 'en' ? 'bottom-right':'bottom-left'}}"></p-toast>

<!-- Success Theme V2 -->
<p-toast class="rw__notification rw__notification-toast-success" key="topThemeV2" position="{{lng === 'en' ? 'top-right':'top-left'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-success rw__notification-toast-hide-icon" key="topThemeNoIconV2"
    position="{{lng === 'en' ? 'top-right':'top-left'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-success" key="bottomThemeV2" position="{{lng === 'en' ? 'bottom-right':'bottom-left'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-success" key="bottomThemeFlipV2" position="{{lng === 'en' ? 'bottom-right':'bottom-left'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-success rw__notification-toast-hide-icon" key="bottomThemeNoIconV2"
    position="{{lng === 'en' ? 'bottom-right':'bottom-left'}}"></p-toast>

<!-- Warning Theme V2-->
<p-toast class="rw__notification rw__notification-toast-warning" key="topThemeWarningV2" position="{{lng === 'en' ? 'top-right':'top-left'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-warning rw__notification-toast-hide-icon" key="topThemeWarningNoIconV2"
    position="{{lng === 'en' ? 'top-right':'top-left'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-warning" key="bottomThemeWarningV2" position="{{lng === 'en' ? 'bottom-right':'bottom-left'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-warning" key="bottomThemeWarningFlipV2" position="{{lng === 'en' ? 'bottom-left':'bottom-right'}}"></p-toast>
<p-toast class="rw__notification rw__notification-toast-warning rw__notification-toast-hide-icon" key="bottomThemeWarningNoIconV2"
    position="{{lng === 'en' ? 'bottom-right':'bottom-left'}}"></p-toast>
<p-toast
  class="rw__notification-toast-success w-full"
  styleClass="w-5"
  position="{{ lng === 'en' ? 'bottom-right' : 'bottom-left' }}"
  key="notFoundProductUpdateLink"
  [baseZIndex]="0"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="w-12 flex justify-content-between">
      <span class="font-medium text-black-alpha-80 w-8 flex justify-content-start align-items-center">{{ message.detail }}</span>
      <a
        [routerLink]="[message.summary]"
        class="clickable-link w-4 flex justify-content-end align-items-center"
        >{{ 'notFoundSimpleProduct.viewProductDetails' | translate }}</a
      >
    </div>
  </ng-template>
</p-toast>
<p-toast
  class="rw__notification rw__notification-toast-success"
  position="{{ lng === 'en' ? 'top-right' : 'top-left' }}"
  key="orderMgmtInvoiceIssue"
  [baseZIndex]="0"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <span class="p-toast-message-icon pi pi-check"></span>
    <div class="w-25rem flex justify-content-between">
      <span class="font-medium text-black-alpha-80 flex justify-content-start align-items-center">{{ message.detail }}</span>
      <a
        [routerLink]="[message.summary]"
        class="clickable-link flex justify-content-end align-items-center"
      >{{ 'orderMgmt.invoiceIssueToast.redirectBtn' | translate }}</a
      >
    </div>
  </ng-template>
</p-toast>
<p-toast
  class="rw__notification rw__notification-toast-success"
  position="{{ lng === 'en' ? 'top-right' : 'top-left' }}"
  key="orderDetailsIssueInvoice"
  [baseZIndex]="0"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <span class="p-toast-message-icon pi pi-check"></span>
    <div class="w-25rem flex justify-content-between">
      <span class="font-medium text-black-alpha-80 flex justify-content-start align-items-center">{{ message.detail }}</span>
      <a
        [routerLink]="[message.summary]"
        [queryParams]="message.data"
        class="clickable-link flex justify-content-end align-items-center"
      >{{ 'orderDetailsV2.invoiceIssueToast.redirectBtn' | translate }}</a
      >
    </div>
  </ng-template>
</p-toast>
