export const ProductTypeConstant = {
  Simple: 'simple',
  Variable: 'variable',
  Composite: 'composite',
  ECard: 'ecard',
  EProduct: 'eproduct',
} as const;

export const enum ProductType {
  Simple = 'simple',
  Variable = 'variable',
  Composite = 'composite',
  ECard = 'ecard',
  EProduct = 'eproduct',
}

export const enum ProductVariantType {
  child = 'child',
  package = 'package',
  composite = 'composite',
}

export const TrackTypeConstant = {
  Batch: 'batch',
  Serial: 'serial',
} as const;

export type TrackType =
  (typeof TrackTypeConstant)[keyof typeof TrackTypeConstant];

export const WeightedVariantUnitConstant = {
  Kg: 'kg',
  G: 'g',
  Lb: 'lb',
  Oz: 'o',
};

export type WeightedVariantUnit =
  (typeof WeightedVariantUnitConstant)[keyof typeof WeightedVariantUnitConstant];

export const VariantTypeConstant = {
  Child: 'child',
  Package: 'package',
  Composite: 'composite',
} as const;

export const enum VariantType {
  Child = 'child',
  Package = 'package',
  Composite = 'composite',
}

export const enum VariantTrackTypes {
  Batch = 'batch',
  Serial = 'serial',
}
