import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { map, catchError, switchMap, first } from 'rxjs/operators';
import { IPaymentInvoice } from '../payment/model/payment-invoice';
import { Invoice, InvoiceV2 } from '../../shared/model/invoice/Invoice';
import {
  downloadFileFromUrl,
  IDownloadFile,
} from '../../shared/utility/file.utility';
import { PaginationQuery } from '../../shared/model/PaginationQuery';
import { IInvoiceSearchFilter, IInvoiceSearchFilterV2 } from '../../shared/model/invoice/invoice-search-and-filter';
import { ValidateImportPOResponse } from '../purchase-order-new/common/types';
import { ImportVariantDetail, PayPoResponseV2 } from '../purchase-order-new/purchase-order-form/types';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import {
  mapAllInvoicesToV1,
  mapGetInvoicesParamsToV2,
  mapPayCreditV2ToV1,
  mapPayPoV2ToV1,
  mapQueryParamsToV2,
  mapReturnInvoiceV2ToV1,
  mapV2InvoiceToV1,
} from './mapper-functions-v2';
import { INVOICE_TYPES } from '../../shared/constants';
import { TransferStockService } from '../transfer-stock/services/transfer-stock.service';
import {
  mapQueryParamsToReturnV2,
  mapTransferStockQueryParamsToV2,
} from '../../shared/utility/transfer-stock-mappers';

const API_URL = '/api';

@Injectable()
export class InvoicesService {
  constructor(
    private readonly http: HttpClient,
    private readonly featureFlagService: FeatureFlagService,
    private readonly transferStockService: TransferStockService,
  ) {}

  private voucherPrintSubject = new Subject<any>();

  async getFeatureFlagPO(): Promise<boolean> {
    return firstValueFrom(
      this.featureFlagService.isEnabled(
        FeatureFlagEnum.PurchaseOrderRevampV2,
        false,
      ),
    );
  }

  emitResponseForVoucherPrint(response: any) {
    this.voucherPrintSubject.next(response);
  }

  getVoucherPrintObservable() {
    return this.voucherPrintSubject.asObservable();
  }

  createPurchaseOrderV2(purchaseOrder): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/stock-control/purchase-order/`,
      purchaseOrder,
    );
  }

  createPurchaseOrder(purchaseOrder): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/invoices/purchase-orders/create`,
      purchaseOrder,
    );
  }

  createRemoveStock(removeStock): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/invoices/remove-stock/create`,
      removeStock,
    );
  }

  createPayPurchaseOrder(payPurchaseOrder: Invoice): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/invoices/pay-purchase-orders/create`,
      payPurchaseOrder,
    );
  }

  saveStockCount(stockCountData): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/invoice/stock-count/create`,
      stockCountData,
    );
  }

  getInvoiceByIdV2(invoiceNo: string): Observable<InvoiceV2> {
    return this.http.get<InvoiceV2>(
      `${API_URL}/stock-control/purchase-order/${invoiceNo}`,
    );
  }

  getStockCountV2ById(stockCountId, includeChangedVariants?): Observable<any> {
    const changedVariantParam = includeChangedVariants
      ? '?includeChangedVariants=true'
      : '';
    return this.http.get<any>(
      `${API_URL}/invoice/stock-count/v2/${stockCountId}${changedVariantParam}`,
    );
  }

  saveStockCountV2(stockCountData): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/invoice/stock-count/v2`,
      stockCountData,
    );
  }

  updateStockCount(stockCountData): Observable<any> {
    return this.http.put<any>(
      `${API_URL}/invoice/stock-count/v2`,
      stockCountData,
    );
  }

  deleteStockCountById(stockCountId): Observable<any> {
    return this.http.delete<any>(
      `${API_URL}/invoice/stock-count/v2/${stockCountId}`,
    );
  }

  updatePurchaseOrderV2(invoiceId, purchaseOrder): Observable<any> {
    return this.http.put<any>(
      `${API_URL}/stock-control/purchase-order/${invoiceId}`,
      purchaseOrder,
    );
  }

  updatePurchaseOrder(invoiceNumber, purchaseOrder): Observable<Invoice> {
    return this.http.put<any>(
      `${API_URL}/invoices/purchase-orders/${invoiceNumber}`,
      purchaseOrder,
    );
  }

  createReturnStock(returnStocks): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/invoices/return-stocks/create`,
      returnStocks,
    );
  }

  verifyImportProducts(
    skus: Array<string>,
  ): Observable<ValidateImportPOResponse> {
    const params = new HttpParams().appendAll({
      sku: skus.map((x) => x.toString()),
    });

    return this.http.get<ValidateImportPOResponse>(
      `${API_URL}/invoices/import-products/verify`,
      { params },
    );
  }

  verifyImportProductsV2(
    skus: Array<string>,
  ): Observable<ValidateImportPOResponse> {
    const skusObject = { sku: skus.map((x) => x.toString()) };

    return this.http.post<any>(
      `${API_URL}/invoices/import-products/verify-v2`,
      skusObject,
    );
  }

  fetchImportProductsInfo(
    skus: Array<string | number>,
  ): Observable<[ImportVariantDetail]> {
    const params = new HttpParams().appendAll({
      sku: skus.map((x) => x.toString()),
    });
    return this.http.get<[ImportVariantDetail]>(
      `${API_URL}/invoices/import-products/info`,
      { params },
    );
  }

  fetchImportProductsInfoV2(
    skus: Array<string | number>,
  ): Observable<[ImportVariantDetail]> {
    const skusObject = { sku: skus.map((x) => x.toString()) };

    return this.http.post<[ImportVariantDetail]>(
      `${API_URL}/invoices/import-products/info-v2`,
      skusObject,
    );
  }

  getAllInvoicesV2(
    query: IInvoiceSearchFilter,
  ): Observable<{ result: InvoiceV2[]; total: number }> {
    const params = mapQueryParamsToV2(query);
    return this.http.get<{ result: InvoiceV2[]; total: number }>(
      `${API_URL}/stock-control/purchase-order/`,
      { params: { ...params } },
    );
  }

  getAllInvoices(): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${API_URL}/invoices`);
  }

  getAllInvoicesOfSupplierWithTypeAndPaymentStatuses(
    supplierId: number,
    type: string,
    paymentStatuses: string[],
    invoiceNumber: string,
  ): Observable<Invoice[]> {
    let params;
    let paramsMappedV2;
    if (invoiceNumber) {
      params = new HttpParams()
        .set('supplierId', supplierId.toString())
        .set('type', type)
        .set('paymentStatus', paymentStatuses.join(','))
        .set('invoiceNumber', invoiceNumber);
      paramsMappedV2 = mapGetInvoicesParamsToV2(
        supplierId,
        type,
        paymentStatuses,
        invoiceNumber,
      );
    } else {
      params = new HttpParams()
        .set('supplierId', supplierId.toString())
        .set('type', type)
        .set('paymentStatus', paymentStatuses.join(','));
      paramsMappedV2 = mapGetInvoicesParamsToV2(
        supplierId,
        type,
        paymentStatuses,
      );
    }
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          if (isStockControlServiceEnabled) {
            if (type === INVOICE_TYPES.RETURN_STOCK) {
              return this.getReturnInvoices(supplierId);
            }
            const paramsV2 = {};
            for (const key in paramsMappedV2) {
              if (paramsMappedV2[key]) {
                paramsV2[key] = paramsMappedV2[key];
              }
            }
            return this.http
              .get<{ result: InvoiceV2[]; total: number }>(
                `${API_URL}/stock-control/purchase-order`,
                { params: paramsV2 },
              )
              .pipe(map((response) => mapAllInvoicesToV1(response.result)));
          }
          return this.http.get<Invoice[]>(
            `${API_URL}/invoices/payable-invoices`,
            {
              params,
            },
          );
        }),
        first(),
      );
  }

  getReturnInvoices(supplierId: number): Observable<any[]> {
    const params = mapQueryParamsToReturnV2(supplierId);
    return this.http
      .get<{ result: any[]; total: number }>(
        `${API_URL}/stock-control/return-inventory`,
        {
          params: { ...params },
        },
      )
      .pipe(map((response) => mapReturnInvoiceV2ToV1(response.result)));
  }

  getExportInvoice(query: IInvoiceSearchFilter): Observable<number | any> {
    const queryParams = { ...query };
    queryParams.paymentDueDate =
      query?.paymentDueDate?.fromDate && query?.paymentDueDate?.toDate
        ? query.paymentDueDate
        : undefined;
    queryParams.issueDate =
      query?.dates?.fromDate && query?.dates?.toDate ? query.dates : undefined;
    queryParams.completeDate =
      query?.completeDate?.fromDate && query?.completeDate?.toDate
        ? query.completeDate
        : undefined;
    queryParams.totalTaxInclusive = queryParams.totalTaxInclusive?.operator
      ? queryParams.totalTaxInclusive
      : undefined;
    queryParams.scannerCode = query?.scannerCode;
    queryParams.search = query?.search;

    const propertyMapping = {
      dates: 'issueDate',
    };
    let cleanedQueryParams;
    if (queryParams.type === 'PurchaseOrder') {
      cleanedQueryParams = Object.entries(queryParams).reduce((acc, [k, v]) => {
        const mappedKey = propertyMapping[k] || k;
        return v !== null && v !== '' ? { ...acc, [mappedKey]: v } : acc;
      }, {});
    } else {
      cleanedQueryParams = Object.entries(queryParams).reduce(
        (acc, [k, v]) => (v !== null && v !== '' ? { ...acc, [k]: v } : acc),
        {},
      );
    }
    return this.http
      .post<IDownloadFile>(`${API_URL}/invoices/export`, cleanedQueryParams, {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<IDownloadFile>) => {
          if (
            response.status === 200 &&
            response.body != null &&
            response.body.filename &&
            response.body.downloadUrl
          ) {
            downloadFileFromUrl(response.body).subscribe();
          }
          return response.status;
        }),
        catchError((err) => of(err.status)),
      );
  }

  getStockCountById(invoiceId): Observable<Invoice> {
    return this.http.get<Invoice>(
      `${API_URL}/invoice/stock-count/${invoiceId}`,
    );
  }

  getAllInvoicesByType(invoiceType: string): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${API_URL}/invoicesByType/${invoiceType}`);
  }

  getInvoicesPageByType(
    invoiceType: string,
    pageQuery: PaginationQuery,
  ): Observable<{ result: Invoice[]; total: number }> {
    const { offset, limit } = pageQuery;
    return this.http.get<{ result: Invoice[]; total: number }>(
      `${API_URL}/invoicesByType/${invoiceType}?limit=${limit}&offset=${offset}`,
    );
  }

  getInvoicesPageFilterByType(
    invoiceType: string,
    query: IInvoiceSearchFilter,
  ): Observable<{ result: Invoice[]; total: number }> {
    // this is a diry solution to fix different types of data being passed into this function
    // Please refer to zod implementation of this api for correct typings of input data
    let paymentStatus;
    let invoiceStatus;
    let user;
    let destinationLocationId;
    let sourceLocationId;
    const convertBackToArray = (data: any[] | string) =>
      Array.isArray(data) ? data : data.split(',');
    if (query.paymentStatus) {
      paymentStatus = convertBackToArray(query.paymentStatus);
    }
    if (query.invoiceStatus) {
      invoiceStatus = convertBackToArray(query.invoiceStatus);
    }
    if (query.user) {
      user = convertBackToArray(query.user).map((x) => +x);
    }
    if (query.destinationLocationId) {
      destinationLocationId = convertBackToArray(
        query.destinationLocationId,
      ).map((x) => +x);
    }
    if (query.sourceLocationId) {
      sourceLocationId = convertBackToArray(query.sourceLocationId).map(
        (x) => +x,
      );
    }
    const updatedAt = {
      fromDate: query.updatedAtFromDate ?? undefined,
      toDate: query.updatedAtToDate ?? undefined,
    };
    const payload = {
      search: query.search,
      scannerCode: query.scannerCode || '',
      issueDate:
        query?.dates?.fromDate || query?.dates?.toDate
          ? query.dates
          : undefined,
      completeDate:
        query?.completeDate?.fromDate || query?.completeDate?.toDate
          ? query.completeDate
          : undefined,
      paymentStatus,
      invoiceStatus,
      companyVatNumber: query?.companyVatNumber ?? undefined,
      id: query.id || '',
      stockLocationId: sourceLocationId,
      destinationLocationId,
      invoiceNumber: query.invoiceNumber || undefined,
      updatedAt:
        query.updatedAtFromDate || query.updatedAtToDate
          ? updatedAt
          : undefined,
      paymentDueDate:
        (query?.paymentDueDate?.fromDate || query?.paymentDueDate?.toDate) &&
        query.paymentDueDate,
      supplierId: query.supplierId ?? undefined,
      totalTaxInclusive:
        query.totalTaxInclusive?.value &&
        query.totalTaxInclusive?.operator &&
        query.totalTaxInclusive,
      sortBy: query.sortBy,
      user,
      invoiceNumberFilter: query.invoiceNumberFilter || '',
      offset: query.offset || '0',
      limit: query.limit || '10',
      supplierInvoiceNumber: query?.supplierInvoiceNumber ?? undefined,
    };
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (
            isTransferStockServiceEnabled &&
            invoiceType === INVOICE_TYPES.TRANSFER_STOCK
          ) {
            const mappedQueryParams = mapTransferStockQueryParamsToV2(query);
            return this.transferStockService.getTransferStockList(
              mappedQueryParams,
            );
          }
          return this.http.post<{ result: Invoice[]; total: number }>(
            `${API_URL}/invoicesByType/${invoiceType}`,
            payload,
          );
        }),
        first(),
      );
  }

  getRemoveStockByInvoiceNumber(InvoiceNumber: string): Observable<Invoice> {
    return this.http.get<Invoice>(
      `${API_URL}/invoices/remove-stock-by-no/${InvoiceNumber}`,
    );
  }

  fetchPaymentInvoices(): Observable<Array<IPaymentInvoice>> {
    return this.http.get<Array<IPaymentInvoice>>(
      `${API_URL}/invoices/paymentlist`,
    );
  }

  fetchPaymentInvoicesPage(query: IInvoiceSearchFilter): Observable<{
    result: IPaymentInvoice[];
    total: number;
  }> {
    const queryParams = new HttpParams()
      .set('search', query.search || '')
      .set('type', query.type || '')
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      )
      .set('payment', query.payment || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10');
    return this.http.get<{ result: IPaymentInvoice[]; total: number }>(
      `${API_URL}/invoices/paymentlist`,
      { params: queryParams },
    );
  }

  getInvoiceByInvoiceNumber(InvoiceNumber: string): Observable<Invoice> {
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          if (isStockControlServiceEnabled) {
            return this.http
              .get<InvoiceV2>(
                `${API_URL}/stock-control/purchase-order/${InvoiceNumber}`,
              )
              .pipe(map((response) => mapV2InvoiceToV1(response)));
          }
          return this.http.get<Invoice>(
            `${API_URL}/invoices/invoiceByInvoiceNumber/${InvoiceNumber}`,
          );
        }),
        first(),
      );
  }

  getAllNonPayableInvoicesByType(invoiceType: string): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(
      `${API_URL}/non-payable-invoices-by-type/${invoiceType}`,
    );
  }

  getReceiveDebitInvoiceWithChildReturnInvoices(
    invoiceNumber: string,
  ): Observable<Invoice> {
    return this.http.get<Invoice>(
      `${API_URL}/invoices/receive-debit/${invoiceNumber}`,
    );
  }

  getPayCreditInvoiceWithChildReturnInvoices(invoiceNumber: string) {
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          if (isStockControlServiceEnabled) {
            return this.http
              .get<PayPoResponseV2>(
                `${API_URL}/stock-control/payments/pay-purchase-order/${invoiceNumber}`,
              )
              .pipe(map((response) => mapPayCreditV2ToV1(response)));
          }
          return this.http.get<Invoice>(
            `${API_URL}/invoices/pay-credit/${invoiceNumber}`,
          );
        }),
        first(),
      );
  }

  getPayPurchaseOrderWithPurchaseOrderInvoice(invoiceNumber: string) {
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          if (isStockControlServiceEnabled) {
            return this.http
              .get<PayPoResponseV2>(
                `${API_URL}/stock-control/payments/pay-purchase-order/${invoiceNumber}`,
              )
              .pipe(map((response) => mapPayPoV2ToV1(response)));
          }
          return this.http.get<Invoice>(
            `${API_URL}/invoices/pay-purchase-order/${invoiceNumber}`,
          );
        }),
        first(),
      );
  }

  getPayReturnStockWithReturnInvoice(invoiceNumber: string) {
    return this.http.get<Invoice>(
      `${API_URL}/invoices/pay-return-stock/${invoiceNumber}`,
    );
  }

  getRtnOrPOWithPaymentDetailsByInvoiceNumber(invoiceNumber: string) {
    return this.http.get<Invoice>(
      `${API_URL}/invoices/${invoiceNumber}/payments`,
    );
  }

  exportAllPurchaseOrders(input: {
    queryParams: IInvoiceSearchFilterV2;
    lang: string;
  }): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      `${API_URL}/stock-control/purchase-order/export`,
      input,
    );
  }

  getExportFile(id: number): Observable<{ fileName: string; fileUrl: string }> {
    return this.http.get<{ fileName: string; fileUrl: string }>(
      `${API_URL}/stock-control/purchase-order/export/${id}`,
    );
  }
}
