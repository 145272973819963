import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, locationsFeatureKey, LocationsState } from '../reducers/locations.reducer';

export const selectLocationsState = createFeatureSelector<LocationsState>(locationsFeatureKey);

export const areLocationsLoaded = createSelector(
  selectLocationsState,
  (state) => state && state.areLocationsLoaded,
);

export const selectAllLocations = createSelector(
  selectLocationsState,
  selectAll,
);

export const selectLocationById = (locationId: number) => createSelector(
  selectAllLocations,
  (locations) => locations.find((location) => location.id === locationId),
);
