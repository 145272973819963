export enum ShortcutAction {
  PosToggleShortcutsDialog = 'PosToggleShortcutsDialog',
  PosFocusSearch = 'PosFocusSearch',
  PosClearCart = 'PosClearCart',
  PosTogglePriceMode = 'PosTogglePriceMode',
  PosShowPaymentDialog = 'PosShowPaymentDialog',
  PosPay = 'PosPay',
  PosShowParkSaleDialog = 'PosShowParkSaleDialog',
  PosParkSale = 'PosParkSale',
  PosShowSalesmanDialog = 'PosShowSalesmanDialog',
  PosAssignNextSalesman = 'PosAssignNextSalesman',
  PosAssignPreviousSalesman = 'PosAssignPreviousSalesman',
  PosAddCustomer = 'PosAddCustomer',
}

export enum ShortcutContext {
  PosRegisterView = 'PosRegisterView',
  PosCartView = 'PosCartView',
  PosParkSaleForm = 'PosParkSaleForm',
  PosSalePayment = 'PosSalePayment',
  PosSaleItemSearch = 'PosSaleItemSearch',
  PosSaleItemList = 'PosSaleItemList',
  PosAssignSalesman = 'PosAssignSalesman',
  PosAddCustomerDialog = 'PosAddCustomerDialog',
}

export enum Shortcut {
  F2 = 'F2',
  F4 = 'F4',
  F8 = 'F8',
  F9 = 'F9',
  P = 'KeyP',
  S = 'KeyS',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  AltP = 'Alt-KeyP',
  AltS = 'Alt-KeyS',
  AltArrowUp = 'Alt-ArrowUp',
  AltArrowDown = 'Alt-ArrowDown',
  Enter = 'Enter',
  Delete = 'Delete',
}

export enum KeyboardEventKey {
  Alt = 'Alt',
  Ctrl = 'Control',
  Enter = 'Enter',
  Comma = 'Comma',
}
