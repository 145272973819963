export enum Tags {
  warning = 'warning',
  info = 'info',
  error = 'error',
  success = 'success',
  waiting = 'waiting',
  expired = 'expired',
}

export type TagTypes = Tags;
