import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, taxesFeatureKey, TaxesState } from '../reducers/tax.reducer';

export const selectTaxesState = createFeatureSelector<TaxesState>(taxesFeatureKey);

export const areTaxesLoaded = createSelector(
  selectTaxesState,
  (state) => state.areTaxesLoaded,
);

export const selectAllTaxes = createSelector(
  selectTaxesState,
  selectAll,
);

export const selectTaxById = (taxId: number) => createSelector(
  selectAllTaxes,
  (taxes) => taxes.find((tax) => tax.id === taxId),
);
