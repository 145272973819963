import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderError } from '../../../../model/order/OrderError';
import { LocalStorageKey, ORDER_STATUS } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-error-details-popup',
  templateUrl: './error-details-popup.component.html',
  styleUrls: ['./error-details-popup.component.scss'],
})
export class ErrorDetailsPopupComponent implements OnInit, OnDestroy {
  @Input() content: any;

  errors: Observable<OrderError[]>;

  lng: string;

  destroyed = false;

  langCss: 'rtl' | 'ltr';

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
      'en',
    );
    this.translate.setDefaultLang(this.lng);
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
    this.errors = (this.content.errors as Observable<OrderError[]>).pipe(
      map((errors) => errors.filter((e) => e.status !== 'Irresolvable')),
    );
  }

  ngOnDestroy() {
    this.destroyed = true;
  }

  totalErrors(errors: OrderError[]) {
    return errors.filter(
      (error) => error.status === 'Unresolved' || error.status === 'Resolved',
    ).length;
  }

  totalResolvableErrors(errors: OrderError[]) {
    return errors.filter((error) => error.status === 'Unresolved').length;
  }

  shouldEnableResolve() {
    return this.content?.orderStatus === ORDER_STATUS.PENDING_WITH_ERROR;
  }

  getSummaryMessage(errors: OrderError[]) {
    return `${this.totalErrors(errors)} ${this.translate.instant(
      'Errors',
    )}, ${this.totalResolvableErrors(errors)} ${this.translate.instant(
      'Resolvable',
    )}`;
  }

  getErrorDescription(error: OrderError) {
    switch (error.type) {
      case 'Insufficient Quantity':
        return 'insufficient_quantity_order_error';
      case 'Not Found Product':
        return 'not_found_product_order_error';
      case 'Price Lower Than Cost':
        return 'price_lower_than_cost_order_error';
      case 'Unlinked Product':
        return 'unlinked_product_order_error';
      default:
        return error?.description;
    }
  }

  getErrorRecoveryStep(error: OrderError) {
    switch (error.type) {
      case 'Insufficient Quantity':
        return 'insufficient_quantity_recovery';
      case 'Not Found Product':
        return 'not_found_product_recovery';
      case 'Price Lower Than Cost':
        return 'price_lower_than_cost_recovery';
      case 'Unlinked Product':
        return 'unlinked_product_order_recovery';
      default:
        return error?.description;
    }
  }

  handleResolveAllErrors() {
    this.content.resolveAll_btn.enabled = false;
    this.content.resolveAll_btn.handler().subscribe(() => {
      this.content.resolveAll_btn.enabled = true;
    });
  }
}
