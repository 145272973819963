export const ZatcaLogOrderByFieldConstant = {
  LogId: 'id',
} as const;

export type ZatcaLogOrderByField =
  (typeof ZatcaLogOrderByFieldConstant)[keyof typeof ZatcaLogOrderByFieldConstant];

export enum ZatcaLogStatus {
  Success = 'SUCCESS',
  SuccessWithWarnings = 'SUCCESS_WITH_WARNINGS',
  Error = 'ERROR',
}

export enum ZatcaLogAction {
  Report = 'REPORT',
  Clearance = 'CLEARANCE',
}

export enum ZatcaLogReferenceType {
  InvoiceNumber = 'INVOICE_NUMBER',
}
