/* eslint-disable import/order */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { MerchantAppSubscription } from 'src/app/shared/model/MerchantAppSubscription';
import { AppChannelSubscription } from '../../shared/model/AppChannelSubscription';
import { AppProductStatus } from '../../shared/model/AppProductStatus';
import { ErrorLog } from '../../shared/model/ErrorLog';
import { ImportRequest } from '../../shared/model/ImportRequest';
import { ImportProductsResponse } from '../../shared/model/ImportProductResponse';
import { ProductVariant } from '../../shared/model/product-variant';
import { AppState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { selectSubscribedApps } from '../selectors/app.selectors';
import { debounceTime, filter, map } from 'rxjs/operators';
import { AppActions } from '../app.action-types';
import { INTEGRATION_STATUSES } from '../../shared/constants';
import { UserService } from '../../auth/services/user.service';
import { IImportService } from './integrations-v2/import.service.interface';

const API_URL = '/api';

@Injectable()
export class AppService implements IImportService {
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  updateAppSubscription(variant: any): any {
    throw new Error('Method not implemented.');
  }

  private refreshAppStateSubject = new Subject<'app-state' | 'subscribed-app-state'>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private store: Store<AppState>,
  ) {
    let lastValue: 'app-state' | 'subscribed-app-state' = null;
    this.refreshAppStateSubject.pipe(
      map((value) => {
        if (lastValue === 'app-state') {
          return 'app-state';
        }
        lastValue = value;
        return lastValue;
      }),
      debounceTime(10000),
    ).subscribe((value) => {
      if (value === 'app-state') {
        this.store.dispatch(AppActions.trueLoadAllApps());
      } else if (value === 'subscribed-app-state') {
        this.store.dispatch(AppActions.trueLoadSubscribedApps());
      }
      lastValue = null;
    });
  }

  getApps(installed: boolean = false): Observable<AppChannelSubscription[]> {
    return this.http.get<AppChannelSubscription[]>(`${API_URL}/apps?installed=${installed}`);
  }

  getSubscribedApps(): Observable<AppChannelSubscription[]> {
    this.refreshAppStateSubject.next('subscribed-app-state');
    return this.store.select(
      selectSubscribedApps,
    ).pipe(
      filter((val) => !!val),
    );
  }

  getSubscribedAppsApiCall(): Observable<AppChannelSubscription[]> {
    return this.http.get<AppChannelSubscription[]>(`${API_URL}/subscribed-apps`);
  }

  linkAppProductIntegration(
    appId: number,
    appProductIntegrationStatus: AppProductStatus,
  ): Observable<ProductVariant> {
    // eslint-disable-next-line no-param-reassign
    appProductIntegrationStatus.status = INTEGRATION_STATUSES.LINKED;
    return this.http.post<ProductVariant>(`${API_URL}/apps/${appId}/product-listing/${appProductIntegrationStatus.id}`,
      appProductIntegrationStatus);
  }

  updateAppProductIntegration(
    appId: number,
    appProductIntegrationStatus: AppProductStatus,
  ): Observable<ProductVariant> {
    return this.http.put<ProductVariant>(`${API_URL}/apps/${appId}/product-listing/${appProductIntegrationStatus.id}`,
      appProductIntegrationStatus);
  }

  getAppErrorLogs(appId: number, query?: any): Observable<{ result: ErrorLog[], total: number }> {
    const orderQueryParams = new HttpParams()
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10')
      .set('sortBy', query.sortBy ? JSON.stringify(query.sortBy) : '')
      .set('search', query.search || '')
      .set('fromDate', query.fromDate || '')
      .set('toDate', query.toDate || '');
    return this.http.get<{ result: ErrorLog[], total: number }>(`${API_URL}/apps/${appId}/error-logs`, { params: orderQueryParams });
  }


  uninstallApp(subscriptionId) {
    return this.http.delete<any>(`${API_URL}/installation/cancel`,
      {
        params: {
          subscriptionId,
        },
      });
  }

  saveMerchantAppSubscription(appSubscription): Observable<MerchantAppSubscription> {
    return this.http.post<MerchantAppSubscription>(`${API_URL}/apps/subscriptions`, appSubscription);
  }

  getMerchantAppSubscription(appSubscriptionId: number): Observable<MerchantAppSubscription> {
    return this.http.get<MerchantAppSubscription>(`${API_URL}/apps/subscriptions/${appSubscriptionId}`);
  }

  importProducts(actionRequest: ImportRequest): Observable<ImportProductsResponse> {
    return this.http.post<ImportProductsResponse>(`${API_URL}/installation/import`, actionRequest);
  }

  importLater(actionRequest: ImportRequest): Observable<ImportProductsResponse> {
    return this.http.post<ImportProductsResponse>(`${API_URL}/installation/importLater`, actionRequest);
  }

  getInstallationProgress(appId: number, totalCount: number): Observable<{totalCount: number, count: number}> {
    return this.http.get<any>(`${API_URL}/installation/progress`,
      {
        params: {
          appId,
          totalCount,
        },
      });
  }

  getTotalProductsToImport(appId: number, merchantId: number): Observable<{ total: number, totalPages: number }> {
    return this.http.get<{ total: number, totalPages: number }>(`${API_URL}/installation/totalProductsToImport`,
      {
        params: {
          appId, merchantId,
        },
      });
  }

  getPublishedVariantsToSubscription(query, offset, limit, ...params):
  Observable<{ result: AppProductStatus[]; total: number }> {
    return this.http.get<{ result: AppProductStatus[], total: number }>(`${API_URL}/apps/subscriptions/${params[0]}/product-listing?query=${query}&offset=${offset}&limit=${limit}`);
  }

  refreshSallaTokens(payload: { code: string, state: string }) {
    return this.http.put<{ success: boolean }>(`${API_URL}/apps/subscriptions/refreshTokens/salla`, payload);
  }

  isInternalInventoryMigrationNeeded(): Observable<boolean> {
    return of(false);
  }

  // dummy implementation for this service to satiate the interface
  getInventoryImportProgress(): Observable<{
    totalProducts: number;
    migratedProducts: number;
  }> {
    return of({
      totalProducts: 0,
      migratedProducts: 0,
    });
  }

  // dummy implementation for this service to satiate the interface
  importInternalInventory(): Observable<void> {
    return of(undefined);
  }
}
