export enum FiscalPeriodStatus {
  OPEN = 'Open',
  CLOSE = 'Closed',
  REOPEN = 'Reopened',
}

export enum FiscalPeriodTimePeriod {
  Month = 'month',
  ThreeMonth = 'three-month',
  SixMonth = 'six-month',
  Year = 'year',
}

export enum FiscalPeriodGainType {
  Profit = 'Profit',
  Loss = 'Loss',
}
