import { Injectable, OnDestroy } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import {
  DocumentData, QuerySnapshot, Timestamp, Unsubscribe,
  doc,
  getDocs, onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { FirebaseNotification } from '../../../firebase-notifications/notification.interface';
import { NotificationsService } from '../../../firebase-notifications/notifications.service';
import { Merchant } from '../../model/Merchant';
import { CustomToastService } from '../custom-toast.service';
import { FirebaseCollections } from '../enum/firebase-constants';
import { FirebaseAppService } from './firebase-app.service';
import { FirebaseQueryProviderService } from './firebase-query-provider.service';
import { FirebaseUserService } from './firebase-user.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FirebaseNotificationService implements OnDestroy {
  notificationUnsubscribe: Unsubscribe;
  
  notificationSilentUnsubscribe: Unsubscribe;

  lastNotification;

  constructor(
    public toastMessage: CustomToastService,
    private notificationsService: NotificationsService,
    private firebaseQueryProviderService: FirebaseQueryProviderService,
    private firebaseUserService: FirebaseUserService,
    private firebaseAppService: FirebaseAppService,
  ) {
  }

  ngOnDestroy(): void {
    this.unregisterListener();
  }

  unregisterListener() {
    if (this.notificationUnsubscribe) {
      this.notificationUnsubscribe();
    }
    this.notificationSilentUnsubscribe?.();
  }

  async initializeNotification(user: Merchant) {
    if (environment.enableFirestore) {
      try {
        this.initializeNotificationsListener(user);
        this.initializeSilentNotificationsListener(user);
        this.initializeInvoicesSyncListener(user);
      } catch (error) {
        console.error('firebase err', error);
      }
    }
  }

  initializeNotificationsListener(user: Merchant) {
    const notificationQuery =
      this.firebaseQueryProviderService.buildInitialNotificationQuery(user);
    this.notificationUnsubscribe = onSnapshot(
      notificationQuery,
      (querySnapshot) => { this.processInitialNotificationQuerySnapshot(querySnapshot); },
      (error: FirebaseError) => { this.processError(error, 'FIREBASE ERROR'); },
    );
  }

  initializeSilentNotificationsListener(user: Merchant) {
    const notificationQuery =
      this.firebaseQueryProviderService.buildInitialNotificationQuery(
        user,
        true,
        5,
      );
    this.notificationSilentUnsubscribe = onSnapshot(
      notificationQuery,
      (querySnapshot) => {
        this.processInitialNotificationQuerySnapshot(querySnapshot, true);
      },
      (error: FirebaseError) => {
        this.processError(error, 'FIREBASE ERROR');
      },
    );
  }

  initializeInvoicesSyncListener(user: Merchant): void {
    const notificationQuery =
      this.firebaseQueryProviderService.buildGetLatestNotificationQuery(user);
    this.notificationSilentUnsubscribe = onSnapshot(
      notificationQuery,
      (querySnapshot) => {
        const notifications = this.formatNotifications(querySnapshot);
        this.notificationsService.setOfflineInvoicesSyncNotifications(
          notifications,
        );
      },
      (error: FirebaseError) => {
        this.processError(error, 'FIREBASE ERROR');
      },
    );
  }

  processInitialNotificationQuerySnapshot(querySnapshot: QuerySnapshot<DocumentData>, isSilent = false) {
    const notifications = this.formatNotifications(querySnapshot);
    if (!isSilent) {
      this.notificationsService.setNotifications(notifications);
      this.countNewNotifications(notifications);
    } else {
      this.notificationsService.setSilentNotifications(notifications);
    }
  }

  async loadNextNotifications(user: Merchant) {
    try {
      const notificationQuery = this.firebaseQueryProviderService.buildLoadNextNotificationQuery(user, this.lastNotification);
      const querySnapshot = await getDocs(notificationQuery);
      const notifications = this.formatNotifications(querySnapshot);
      this.notificationsService.setNotifications(notifications);
    } catch (error) {
      this.processError(error, 'FIREBASE ERROR');
    }
  }

  private countNewNotifications(notifications) {
    const notificationCount = notifications?.filter((n) => n.create >= this.getUserLastRead()).length;
    this.notificationsService.setNewNotificationsCount(notificationCount);
  }

  async updateNotificationRead() {
    try {
      const userId = this.getUserId();
      const firestore = this.firebaseAppService.getFirestore();
      const docRef = doc(firestore, FirebaseCollections.Users, userId);
      await updateDoc(docRef, { n_last_read: Timestamp.fromDate(new Date()) });
      this.notificationsService.setNewNotificationsCount(0);
    } catch (error) {
      this.processError(error, 'FIREBASE DOC update err');
    }
  }

  private formatNotifications(querySnapshot: QuerySnapshot<DocumentData>) {
    return querySnapshot
    .docChanges()
    .filter((doc) => doc.type === 'added')
    .map((doc) => this.formatNotification(doc.doc));
  }

  formatNotification(document: DocumentData): FirebaseNotification {
    const notification = document.data();
    notification.create = (notification.create as Timestamp)?.toDate();
    notification.id = document.id;
    this.lastNotification = document;
    return notification;
  }

  processError(error, message : string) {
    console.log(message, { error });
  }

  private getUserLastRead() {
    return this.firebaseUserService.getFirebaseUserLastReadInMillis();
  }

  getUserId() {
    return this.firebaseUserService.getFirebaseUserId();
  }
}
