export type ErrorData = { [key: string]: string | number };

export class BaseError extends Error {
  code: string;
  status: number;
  data: ErrorData;

  constructor(code: string, message: string, status: number, data?: ErrorData) {
    super(message);
    this.code = code;
    this.message = message;
    this.status = status;
    if (data) {
      this.data = data;
    }
  }
}
