import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Invoice } from '../../../shared/model/invoice/Invoice';
import { DatatableColumn } from '../../../shared/model/datatable';
import { TemplateVersion } from '../utils/constants';
import { mapTemplateVersionOneToTwo } from '../utils/common.utils';

const API_URL = '/api';

@Injectable()
export class InvoiceService {
  constructor(private http: HttpClient) {}

  getPosInvoiceById(invoiceId): Observable<any> {
    return this.http.get<any>(`${API_URL}/pos/invoices/${invoiceId}`)
      .pipe(
        tap((payload) => {
          if (
            payload.settings
          && payload.templateVersionNumber === TemplateVersion.ONE
          ) {
            payload.settings = JSON.parse(payload.settings);
            payload.settings = mapTemplateVersionOneToTwo(payload.settings);
            payload.settings = JSON.stringify(payload.settings);
          }
        }),
      );
  }

  getReturnInvoicesBySaleInvoiceId(saleInvoiceId): Observable<any> {
    return this.http.get<any>(`${API_URL}/pos/invoices/return/${saleInvoiceId}`);
  }

  getQueryInvoicesList(query): Observable<{ result: Invoice[]; total: number }> {
    const queryParams = new HttpParams()
      .set('invoiceNumber', query.invoiceNumber || '')
      .set('customer', query.customer || '')
      .set('searchVariant', query.searchVariant || '')
      .set('serialNumber', query.serialNumber || '')
      .set('fromDate', (query.dates && query.dates.fromDate) ? query.dates.fromDate.toUTCString() : '')
      .set('toDate', (query.dates && query.dates.toDate) ? query.dates.toDate.toUTCString() : '')
      .set('payment', query.payment || '')
      .set('locationId', query.location || '')
      .set('sellType', query.sellType || 'SELL')
      .set('app', 'new POS')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10');
    return this.http.get<{ result: Invoice[]; total: number }>(`${API_URL}/orders/invoices`, {
      params: queryParams,
    });
  }

  getColumns = (printHandler, showReceiveDebitModal): DatatableColumn[] => [
    {
      cellTemplate: 'text',
      name: 'Invoice Number',
      prop: 'invoiceNumber',
      sortable: true,
      dir: 'desc',
      canAutoResize: true,
      resizable: true,
    },
    {
      cellTemplate: 'Date',
      headerTemplate: 'Date',
      name: 'Date',
      prop: 'createdAt',
      sortable: false,
      canAutoResize: true,
      resizable: true,
      type: 'full datetime',
    },
    {
      cellTemplate: 'text',
      name: 'Payment Type',
      prop: 'paymentMethod',
      sortable: true,
      dir: 'desc',
      canAutoResize: true,
      resizable: true,
    },
    {
      cellTemplate: 'text',
      name: 'Price',
      prop: 'totalTaxInclusive',
      sortable: true,
      dir: 'desc',
      canAutoResize: true,
      resizable: true,
    },
    {
      cellTemplate: 'text',
      name: 'Amount Paid',
      prop: 'paidAmount',
      sortable: true,
      dir: 'desc',
      canAutoResize: true,
      resizable: true,
    },
    {
      cellTemplate: 'text',
      name: 'PosType',
      prop: 'typeShortName',
      sortable: true,
      dir: 'desc',
      canAutoResize: true,
      resizable: true,
    },
    {
      cellTemplate: 'settings',
      headerTemplate: 'text',
      name: '',
      prop: 'invoice',
      icon: 'fas fa-cog',
      items: [
        {
          iconClass: 'fas fa-pen pr-3',
          name: () => 'Print',
          handler: printHandler,
        },
        {
          iconClass: 'fas fa-pen pr-3',
          name: () => 'Receive Debit',
          handler: showReceiveDebitModal,
          disabled: (item) => !item.debitAmount || item.debitAmount === 0,
        },
      ],
    },
  ];

  getParkedSale(invoiceId: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${API_URL}/pos/invoices/${invoiceId}/park`);
  }

  receiveDebit(data: any) {
    return this.http.post<any>(`${API_URL}/payments/receive-debit-from-customer`, data);
  }
}