import { Injectable } from '@angular/core';
import uniqBy from 'lodash/uniqBy';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { doc, updateDoc } from 'firebase/firestore';
import { FirebaseNotification, FirebaseUser } from './notification.interface';
import { NotificationFilters } from './types';
import { FirebaseAppService } from '../shared/services/firebase.services/firebase-app.service';
import { CommonPolicies } from '../shared/constants';
import { PermissionHelperService } from '../shared/services/permission.helper.service';
@Injectable()
export class NotificationsService {
  private notifications$: BehaviorSubject<FirebaseNotification[][]> =
    new BehaviorSubject<FirebaseNotification[][]>([]);

  private offlineInvoicesSyncNotifications$: BehaviorSubject<
    FirebaseNotification[]
  > = new BehaviorSubject<FirebaseNotification[]>([]);

  private silentNotifications$: BehaviorSubject<FirebaseNotification[][]> =
    new BehaviorSubject<FirebaseNotification[][]>([]);

  private firebaseUser$: BehaviorSubject<FirebaseUser> =
    new BehaviorSubject<FirebaseUser>({} as FirebaseUser);

  private newNotificationCount$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  private readonly NOTIFICATIONS_COLLECTION_NAME = 'notifications';

  constructor(
    private readonly firebaseAppService: FirebaseAppService,
    public permSrvs: PermissionHelperService,
  ) {}

  hasPermissionToView(): boolean {
    return !!this.permSrvs.isPermissionExist(
      CommonPolicies.NEW_DASHBOARD_NOTIFICATIONS,
    );
  }

  getNotifications(
    filters?: NotificationFilters,
  ): Observable<FirebaseNotification[]> {
    if (filters.silent) {
      return this.silentNotifications$
        .asObservable()
        .pipe(
          map((notifications) =>
            this.filterNotifications(
              notifications[0],
              notifications[1],
              filters,
            ),
          ),
        );
    }
    return this.notifications$.asObservable().pipe(
      map((notifications) => {
        return this.filterNotifications(
          notifications[0],
          notifications[1],
          filters,
        );
      }),
    );
  }

  getOfflineInvoicesSyncNotifications(): Observable<FirebaseNotification[]> {
    return this.offlineInvoicesSyncNotifications$
      .asObservable()
      .pipe(map((notifications) => notifications));
  }

  private filterNotifications(
    allNotifications: FirebaseNotification[],
    newNotifications: FirebaseNotification[],
    filters?: NotificationFilters,
  ): FirebaseNotification[] {
    const notifications =
      (filters?.onlyNew ? newNotifications : allNotifications) || [];
    return notifications.filter((notification) => {
      let shouldReturn = true;
      if (filters?.silent !== undefined) {
        shouldReturn = shouldReturn && notification.s === filters.silent;
      }
      if (filters?.source) {
        shouldReturn = shouldReturn && notification.src === filters.source;
      }
      if (filters?.type) {
        shouldReturn = shouldReturn && notification.t === filters.type;
      }
      if (filters?.fromDate) {
        shouldReturn =
          shouldReturn &&
          new Date(notification.create as any) >= filters.fromDate;
      }
      if (filters?.v_t) {
        shouldReturn = shouldReturn && notification.v_t === filters.v_t;
      }
      return shouldReturn;
    });
  }

  setNotifications(notifications: FirebaseNotification[]) {
    const oldNotifications = this.notifications$.getValue()[0] || [];
    const uniqNotifications = uniqBy(
      [...notifications, ...oldNotifications],
      'id',
    );
    this.notifications$.next([
      uniqNotifications as FirebaseNotification[],
      notifications,
    ]);
  }

  setOfflineInvoicesSyncNotifications(
    notifications: FirebaseNotification[],
  ): void {
    const uniqNotifications = uniqBy([...notifications], 'id');
    this.offlineInvoicesSyncNotifications$.next(uniqNotifications);
  }

  setSilentNotifications(notifications: FirebaseNotification[]) {
    const oldNotifications = this.notifications$.getValue()[0] || [];
    const uniqNotifications = uniqBy(
      [...notifications, ...oldNotifications],
      'id',
    );
    this.silentNotifications$.next([uniqNotifications, notifications]);
  }

  getFirebaseUser(): Observable<FirebaseUser> {
    return this.firebaseUser$.asObservable();
  }

  setFirebaseUser(firebaseUser: FirebaseUser) {
    this.firebaseUser$.next(firebaseUser);
  }

  getNewNotificationsCount(): Observable<number> {
    return this.newNotificationCount$.asObservable();
  }

  setNewNotificationsCount(count: number) {
    this.newNotificationCount$.next(count);
  }

  async markNotificationAsRead(docId: string): Promise<void> {
    const docRef = doc(
      this.firebaseAppService.getFirestore(),
      this.NOTIFICATIONS_COLLECTION_NAME,
      docId,
    );
    await updateDoc(docRef, { read: true });
  }
}
