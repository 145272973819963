import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProductSerachFilter } from '../../../shared/model/product-search-and-filter';

const API_URL = '/api';

@Injectable()
export class ExportProductsService {
  constructor(private http: HttpClient) { }

  exportAllProductsOrTracktype(query
  : IProductSerachFilter, trackType, exportContent): Observable<any> {
    const productQueryParams = new HttpParams()
      .set('fromDate', (query.dates && query.dates.fromDate) ? query.dates.fromDate.toString() : '')
      .set('toDate', (query.dates && query.dates.toDate) ? query.dates.toDate.toString() : '')
      .set('search', query.search || '')
      .set('channel', query.channel || '')
      .set('type', query.type || '');
    if (trackType === 'batch list' || trackType === 'serial list') {
      const trkType = trackType === 'batch list' ? 'batch' : 'serial';
      return this.exportBatchList(productQueryParams, exportContent, trkType);
    }
    return this.exportAllProduct(productQueryParams);
  }

  exportAllProduct(productQueryParams) {
    return this.http.get<any>(`${API_URL}/products/export`, { params: productQueryParams });
  }

  exportBatchList(productQueryParams, exportContent, trkType) {
    return this.http.post<any>(`${API_URL}/products/track/${trkType}/export`, exportContent, { params: productQueryParams });
  }
}
