import { BadRequestException, PriceLessThanCost } from '../../errors/errors';
import {
  PosInvoiceTypes,
  PosInvoiceEnums,
  Merchant,
  ProductEnums,
  PosTaxTypes,
  ProductTypes,
  TaxType,
  StockLocationTypes,
} from '@rewaa-team/types';
import { posInvoiceCalculationService } from './pos-invoice-calculation.service';
export class PosInvoiceValidationService {
  validateSalePosInvoice = (
    register: PosInvoiceTypes.Register,
    invoice: PosInvoiceTypes.SellInvoice,
    variants: ProductTypes.Variant[],
    stockLocation: StockLocationTypes.StockLocation,
    internalPosSettings: PosInvoiceTypes.InternalPosSetting[],
    user: Merchant,
    customer: PosInvoiceTypes.Customer,
    mappedInvoice: PosInvoiceTypes.Invoice,
    schemaName: string,
    saleVariants: ProductTypes.Variant[],
    taxConfiguration: PosTaxTypes.TaxConfiguration,
  ) => {
    this.validatePosInvoice(
      register,
      invoice,
      variants,
      stockLocation,
      user,
      customer,
      schemaName,
    );

    invoice.products.forEach((product) => {
      const matchingVariant = variants.find(
        (variant) => variant.sku === product.sku,
      );
      if (!matchingVariant) {
        throw new BadRequestException(
          `product with sku: ${product.sku} is not found`,
        );
      } else if (matchingVariant) {
        const costExclusive =
          posInvoiceCalculationService.calculateExclusivePrice(
            matchingVariant,
            taxConfiguration,
            'cost',
          );
        const priceExlusive = posInvoiceCalculationService.calculateExclusive(
          product.price,
          matchingVariant,
          taxConfiguration,
        );
        if (
          this.getPOSSettingValue(
            internalPosSettings,
            'sell.on.price.lt.cost',
          ) === '0' &&
          priceExlusive < costExclusive &&
          invoice.sellType === PosInvoiceEnums.SellType.Retail
        ) {
          const message = `You can’t sell '${
            matchingVariant.name || matchingVariant.Product?.name
          }'. it has cost > price in the location.`;
          throw new PriceLessThanCost(message);
        }
      }
    });

    if (
      mappedInvoice?.PayableInvoice?.totalAfterPayment < -0.01 &&
      !invoice.displayInvoiceNumber
    ) {
      throw new BadRequestException('Paid amount is more than invoice total');
    }

    if (
      mappedInvoice.PayableInvoice &&
      (mappedInvoice.PayableInvoice?.discountAmount || 0) > 0 &&
      this.getPOSSettingValue(internalPosSettings, 'sell.on.price.lt.cost') ===
        '0'
    ) {
      (mappedInvoice.VariantToInvoices || []).forEach((mappedVariant) => {
        const matchingVariant = variants.find(
          (variant) => variant.sku === mappedVariant.sku,
        )!;
        const [stock] = matchingVariant.ProductVariantToStockLocations || [];
        const { cost = 0 } = stock || {};
        if (
          mappedVariant.costExclusive < cost &&
          mappedVariant?.promotion?.discountType !== 'free_product'
        ) {
          const message = `You can’t sell '${
            matchingVariant.name || matchingVariant.Product?.name
          }'. it has cost > price in the location.`;
          throw new PriceLessThanCost(message);
        }
      });
    }

    saleVariants.forEach((product) => {
      let availableLocationQuantity;
      if (
        product.Product &&
        product.Product.type === ProductEnums.ProductType.ECard
      ) {
        const matchedInvoice = mappedInvoice.VariantToInvoices.find(
          (variant) => variant.sku === product.sku,
        )!;
        availableLocationQuantity = matchedInvoice.availableLocationQuantity;
      } else {
        availableLocationQuantity =
          posInvoiceCalculationService.getVariantQuantity(product);
      }

      if (
        product.manageStockLevel &&
        (product.requiredQuantity || 0) > availableLocationQuantity &&
        this.getPOSSettingValue(
          internalPosSettings,
          'sell.on.quantity.lt.zero',
        ) === '0'
      ) {
        throw {
          message: `product '${product.name}' with sku: '${product.sku}' doesn't have enough quantity.`,
          status: 409,
        };
      }
    });

    let totalPaid = 0;
    invoice.payments?.forEach((payment) => {
      totalPaid = posInvoiceCalculationService.add(totalPaid, payment.amount);
    });

    // TODO removed because it is causing issues for clients.
    // if (subtract(totalPaid, mappedInvoice.totalTaxInclusive) >= 0.01) {
    //   // raiseBadRequest(`you can't pay more than the invoice total.`);
    // }

    if (
      !mappedInvoice.customerId &&
      posInvoiceCalculationService.subtract(
        mappedInvoice.totalTaxInclusive,
        totalPaid,
      ) >= 0.01
    ) {
      throw new BadRequestException(`you require to pay the invoice total.`);
    }

    mappedInvoice.VariantToInvoices.forEach((variantToInvoice) => {
      const matchingVariant = variants.find(
        (variant) => variant.sku === variantToInvoice.sku,
      )!;
      this.validateTrackedVariant(variantToInvoice);
      this.validateExtras(
        variantToInvoice,
        matchingVariant.VariantExtraLocations,
      );

      if (matchingVariant.Product?.type === ProductEnums.ProductType.ECard) {
        if (
          !(
            variantToInvoice.quantity > 0 &&
            variantToInvoice.VariantToInvoiceEcards &&
            variantToInvoice.quantity ===
              variantToInvoice.VariantToInvoiceEcards.length
          )
        ) {
          throw {
            message: `product '${matchingVariant.name}' with sku: '${matchingVariant.sku}' doesn't have enough quantity.`,
            status: 409,
          };
        }
      }
    });
  };

  private validatePosInvoice = (
    register: PosInvoiceTypes.Register,
    invoice: PosInvoiceTypes.SellInvoice,
    variants: ProductTypes.Variant[],
    stockLocation: StockLocationTypes.StockLocation,
    user: Merchant,
    customer: PosInvoiceTypes.Customer,
    clientDBName: string,
    invoiceId?: number,
  ) => {
    const validTaxationTypes = [TaxType.Exclusive, TaxType.Inclusive];
    if (!register) {
      throw new BadRequestException('register not found');
    }
    if (register.status !== PosInvoiceEnums.RegisterStatus.Opened) {
      throw new BadRequestException('register is not opened');
    }
    if (!invoice.taxation || !validTaxationTypes.includes(invoice.taxation)) {
      throw new BadRequestException(
        `taxation is not valid. valid values: ${validTaxationTypes}`,
      );
    }
    if (invoice.customerId) {
      if (!customer) {
        throw new BadRequestException('customer not found');
      }
    }
    if (!invoice.userId) {
      throw new BadRequestException('userId is not provided');
    } else if (!user || user.schemaName !== clientDBName) {
      throw new BadRequestException('invalid userId.');
    }

    if (!invoice.products || invoice.products.length === 0) {
      throw new BadRequestException('products can not be empty.');
    }

    if (!invoiceId) {
      invoice.products.forEach((product) => {
        const matchingVariant = variants.find(
          (variant) => variant.sku === product.sku,
        );
        if (!matchingVariant) {
          throw new BadRequestException(
            `product with sku: ${product.sku} is not found`,
          );
        }
      });
    }

    if (stockLocation && !stockLocation.isActive) {
      throw new BadRequestException('Stock location is not active');
    }
  };

  getPOSSettingValue = (
    internalPosSettings: PosInvoiceTypes.InternalPosSetting[],
    settingName: string,
  ) => {
    let value;
    const existSetting = internalPosSettings.find(
      (s) => s.settingName === settingName,
    );
    if (existSetting) {
      value = existSetting.settingValue;
    }
    return value;
  };

  private validateTrackedVariant = (
    variantToInvoice: PosInvoiceTypes.VariantToInvoice,
  ) => {
    if (variantToInvoice.trackType) {
      // insuring no duplicates
      const variantToInvoiceTracks = [
        ...(variantToInvoice.VariantToInvoiceTracks || []),
      ];
      const trackNumbers = Array.from(
        new Set(
          variantToInvoiceTracks.map(
            (invoiceVariantTrack) => invoiceVariantTrack.trackNo,
          ),
        ),
      );
      const filteredVariantToInvoiceTracks: PosInvoiceTypes.VariantToInvoiceTrack[] =
        [];
      trackNumbers.forEach((trackNumber) => {
        filteredVariantToInvoiceTracks.push(
          variantToInvoiceTracks.find(
            (batch) => batch.trackNo === trackNumber,
          )!,
        );
      });
      if (
        variantToInvoice.trackType === ProductEnums.TrackTypeConstant.Serial
      ) {
        if (
          filteredVariantToInvoiceTracks.length !== variantToInvoice.quantity
        ) {
          throw new BadRequestException(
            `entered serials don't match the number of sold products.`,
          );
        }
        const nonSavedSerials = filteredVariantToInvoiceTracks.filter(
          (track) => !track.variantToTrackId,
        );
        if (nonSavedSerials.length > 0) {
          const serials = nonSavedSerials.map((serial) => serial.trackNo);
          throw new BadRequestException(
            `serials [${serials}] are not associated with sku '${variantToInvoice.sku}'.`,
          );
        }
      } else if (
        variantToInvoice.trackType === ProductEnums.TrackTypeConstant.Batch
      ) {
        const notFoundTracks: string[] = [];
        let totalQuantity = 0;
        filteredVariantToInvoiceTracks.forEach((track) => {
          if (!track.variantToTrackId) {
            notFoundTracks.push(track.trackNo);
          }
          totalQuantity = posInvoiceCalculationService.add(
            totalQuantity,
            track.quantity,
          );
        });
        if (notFoundTracks.length > 0) {
          throw new BadRequestException(
            `batch numbers [${notFoundTracks}] are not found for product '${variantToInvoice.sku}'.`,
          );
        }
      }
    }
  };

  private validateExtras = (
    variantToInvoice: PosInvoiceTypes.VariantToInvoice,
    variantExtraLocations: ProductTypes.VariantExtraLocation[] = [],
  ) => {
    const { VariantToInvoiceExtras: variantToInvoiceExtras = [] } =
      variantToInvoice;

    variantToInvoiceExtras.forEach((invoiceExtra) => {
      const matchingExtra: any = variantExtraLocations.find(
        ({ extraId }) => extraId === invoiceExtra.extraId,
      )!;
      if (!matchingExtra) {
        throw new BadRequestException(
          `extra with id '${invoiceExtra.id} doesn't belong to product '${variantToInvoice.name}'.`,
        );
      }
      const {
        Extra: { ProductVariant: variant },
      } = matchingExtra;
      if (variant) {
        if (variant.isWeightedScale) {
          throw new BadRequestException(
            `extra ${matchingExtra.name} is weighted product. cannot sell weighted extra.`,
          );
        }
        if (variant.trackType) {
          throw new BadRequestException(
            `extra ${matchingExtra.name} is tracked by ${variant.trackType}. cannot sell a tracked extra.`,
          );
        }
      }
    });
  };

  validateShiftId(
    invoiceShiftId: number,
    registerShiftId: number,
    isOffline: boolean,
  ): number {
    if (invoiceShiftId == null && registerShiftId == null) {
      throw new BadRequestException(`shiftId cannot be determined`);
    }
    if (!isOffline && invoiceShiftId !== registerShiftId) {
      throw new BadRequestException('Wrong shift id');
    }
    return invoiceShiftId;
  }
}

export const posInvoiceValidationService = new PosInvoiceValidationService();
