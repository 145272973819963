<div [ngClass]="langCss">
  <div class="alert-box" class="receive-debit">
    <div class="close-button">
      <i class="fa-regular fa-xmark close-icon"  (click)="content.cancel_btn.handler()">
      </i>
      <b class="text-white font-16" [ngClass]="lng === 'en' ? 'ml-2' : 'mr-2'">{{'Close' | translate}}</b>
    </div>

    <div class="modal-body py-0">
      <div class="form" [formGroup]="form">
        <div class="grid">
          <div class="col-8 md:px-5 py-4">
            <div class="p-inputgroup">
              <input rwSelectOnClick pendo-tracker="rdi-4" type="text" formControlName="amount" pInputText
                (click)="showNumberDialogue()" required>
              <div class="p-inputgroup-addon">
                <button pButton pendo-tracker="rdb-2" class="p-button-text p-button-secondary" type="button" label="SAR"></button>
              </div>
            </div>

            <div class="button-center text-center py-3">
              <ng-container *ngFor="let val of autoPayValue; let i = index">
                <button pendo-tracker="rdb-3" class="auto-button font-13" (click)="onAddSetAmount(val)">{{val}} <span class="sr-font">{{'SR' |
                    translate}}</span></button>
              </ng-container>
            </div>
            <div>
              <button pButton rwDebounceClick pendo-tracker="rdb-4" [disabled]="!formValid" class="rw__button rw__button-raised w-full mx-auto" (debounceClick)="receiveDebit()" label="{{'Cash Pay' | translate}} {{form.get('amount').value | amountFormat}} SAR"></button>
            </div>
            <hr class="border-0 border-top-1 rw__mt-6 rw__mb-5">
            <div class="bg-white">
              <!-- <ul class="pl-0 mb-0 font-15" [ngClass]="lng === 'en' ? 'pl-3' : 'pr-3'"> -->
              <ul class="mb-0 font-15" [ngClass]="lng === 'en' ? 'pl-0' : 'pr-0 pl-0'">
                <li class="flex py-3" [ngClass]="lng === 'en' ? 'pr-3' : 'pl-3'">
                  <p class="col-10 mb-0" [ngClass]="lng === 'en' ? '' : 'text-right'">{{'Total Debit' | translate}} <span class="text-grey-60 font-para">{{'Before Payment' |
                      translate}}</span></p>
                  <p class="col text-right mb-0">{{ content.invoice.debitAmount | amountFormat}}</p>
                </li>
                <li class="flex py-3 custom-bg-Color" [ngClass]="lng === 'en' ? 'pr-3' : 'pl-3'">
                  <p class="col-10 font-bold mb-0" [ngClass]="lng === 'en' ? '' : 'text-right'">{{'Total Payment' | translate}} </p>
                  <p class="col text-right font-bold mb-0">{{form.get('amount').value | amountFormat}}</p>
                </li>
                <li class="flex py-3" [ngClass]="lng === 'en' ? 'pr-3' : 'pl-3'">
                  <p class="col-10 mb-0" [ngClass]="lng === 'en' ? '' : 'text-right'">{{'Remaining Debit' | translate }} <span class="text-grey-60 font-para">{{'After
                      Payment' | translate}}</span></p>
                  <p class="col text-right mb-0 remaining-debit-color">{{remainingDebit | amountFormat}}</p>
                </li>
              </ul>
            </div>
          </div>
        
          <div class="bg-custom-light px-0 col-4">
            <!-- [ngClass]="lng === 'en' ? 'pr-3' : 'pl-3'" -->
            <h5 class="rw__fs-6 font-bold p-3 mb-0 border-1 bg-white" [ngClass]="lng === 'en' ? 'pl-3' : 'pr-3 text-right'">{{'Payment Method' | translate}}
            </h5>
            <ul class="list-box list-none pl-0" [ngClass]="lng === 'en' ? 'pl-0' : 'pr-0 text-right'">
              <li class="border-bottom-1 p-3 border-bottom-1 list-box-item" *ngFor="let paymentMethod of paymentMethods">
                <div class="form-check pl-0 payment-radio" [ngClass]="lng === 'en' ? '' : 'add-remove-rtl'">
                  <input pendo-tracker="rdi-5" [value]="paymentMethod.id" type="radio" formControlName="paymentMethodName" [id]="paymentMethod.id">
                  <label class="form-check-label label-lenght rw__pt-0 rw__mb-0" [for]="paymentMethod.id">
                    <span class="font-custom-16 ml-1">{{paymentMethod.name | translate }}</span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>