import { Injector } from '@angular/core';
import { formatDate } from '@angular/common';
import JSBarcode from 'jsbarcode';
import Qrious from 'qrious';
import { ChildVariant } from 'src/app/inventory/model/product';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import {
  LocalStorageKey,
  POS_RECEIPT_TEMPLATE_MAP,
  PRODUCT_TYPES,
  VariantTypes,
} from 'src/app/shared/constants';
import { Merchant } from 'src/app/shared/model/Merchant';
import {
  Invoice,
  TransactionType,
  ZatcaTransactionStatus,
  ZatcaVerificationStatus,
} from 'src/app/shared/model/invoice/Invoice';
import { Setting } from '../model/Setting';
import { RANGE_OF_YEARS_FOR_CALENDAR, ShortcutKey, TableColumnState } from './constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { Denomination } from '../model/denomination.type';
import { TemplateTypes } from '@rewaa-team/types';
import { DateTime } from 'luxon';

export const limitTextLength = (text: string, limit: number): string => (text?.length > limit ? `${[...text].slice(0, limit - 3).join('')}...` : text);

export const getNumberPostFix = (num: number): string => {
  const postFixes: Array<string> = ['st', 'nd', 'rd', 'th'];
  return postFixes[Math.min(3, num - 1)];
};

export const compareStockQuantity = (
  quantity: number,
  availableQuantity: number,
  manageStockLevel: boolean,
) => {
  if (manageStockLevel) {
    return (availableQuantity
      ? availableQuantity >= quantity
      : availableQuantity > quantity);
  }
  return true;
};

export const getRandAlphaNumber = (len = 8) => {
  const alphaNumberic = ['0', '1', '2', '3', '4', '5', '6', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  let str = '';
  for (let i = 0; i < len; i += 1) {
    const randNum = Math.floor(Math.random() * alphaNumberic.length);
    str += alphaNumberic[randNum];
  }

  return str;
};
export const getDeepClone = (value) => JSON.parse(JSON.stringify(value));
export const cleanObject = (object:object) => Object.entries(object).reduce((acc, [k, v]) => (v !== null && v !== undefined ? { ...acc, [k]: v } : acc), {});
export const isSupportedProductInPOS = (variant: ProductVariant): boolean => {
  let isSupported = true;
  if (variant.type === VariantTypes.Composite) {
    const childVariants = variant.Product.VariantToComposites;

    childVariants.forEach((c: ChildVariant) => {
      if (c.ProductVariant.trackType
        || ![VariantTypes.Child, VariantTypes.Package].includes(c.ProductVariant.type as VariantTypes)
        || PRODUCT_TYPES.Digital.values.includes(c.ProductVariant.Product.type)
        || !c.rate
      ) { isSupported = false; }
    });
  } else if (variant.VariantExtraLocations && variant.VariantExtraLocations.length > 0) {
    variant.VariantExtraLocations.forEach((v) => {
      const { ProductVariant: childVariant, rate, hasOtherProduct } = v.Extra;

      if (childVariant) {
        if (childVariant.trackType
          || childVariant.type !== VariantTypes.Child
          || PRODUCT_TYPES.Digital.values.includes(childVariant.Product.type)
          || (hasOtherProduct && !rate)
          || childVariant.isWeightedScale
        ) { isSupported = false; }
      }
    });
  }

  return isSupported;
};

export const getSettingValue = (settingList: Setting[], key) => settingList.find(
  (s) => s.settingName === key,
)?.settingValue;

export const sleep = (ms) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const getYearRange = () => {
  const currentYear = new Date().getFullYear();
  return `${currentYear - RANGE_OF_YEARS_FOR_CALENDAR}:${currentYear}`;
};

export const getDefaultDate = () => (
  new Date(new Date().setFullYear(new Date().getFullYear() - RANGE_OF_YEARS_FOR_CALENDAR))
);

export const setTableColumnSelection = (tableId: string, columns: TableColumnState[]) => {
  const injector = Injector.create({
    providers: [
      { provide: LocalStorageService },
    ],
  });
  const localStorageService = injector.get(LocalStorageService);
  localStorageService.setItem<TableColumnState[]>(
    tableId as LocalStorageKey,
    columns,
  );
};

export const getTableColumnSelection = (tableId: string): TableColumnState[] => {
  const injector = Injector.create({
    providers: [
      { provide: LocalStorageService },
    ],
  });
  const localStorageService = injector.get(LocalStorageService);
  const cols = localStorageService.getItem<TableColumnState[]>(
    tableId as LocalStorageKey,
    [],
  );
  return cols;
}

const getTLV = (tag, valueStr) => {
  const v = new TextEncoder().encode(valueStr);
  const tl = Uint8Array.of(tag, v.byteLength);
  return [...tl, ...v];
};

export const generateQRCode = (merchant: Merchant, invoice: Invoice): string => {
  const invInfo = [
    ...getTLV(1, merchant.companyName),
    ...getTLV(2, merchant.vatNumber),
    ...getTLV(
      3,
      `${formatDate(invoice.completeDate, 'yyyy-MM-dd', 'en-GB')
      }T${formatDate(invoice.completeDate, 'HH:mm:ss.SSS', 'en-GB')
      }Z`,
    ),
    ...getTLV(4, Number(invoice.totalTaxInclusive).toFixed(2)),
    ...getTLV(5, Number(invoice.totalTax).toFixed(2)),
  ];
  const qr = new Qrious({
    size: 192,
    value: btoa(String.fromCharCode(...new Uint8Array(invInfo))),
  });
  return qr.toDataURL('image/jpeg');
};

export const generateBarcode = (invoice: Invoice): string => {
  const node = document.createElement('img');
  JSBarcode(
    node,
    invoice.displayInvoiceNumber || invoice.invoiceNumber,
    {
      format: 'CODE128',
      width: 1.4,
      height: 32,
    },
  );
  const dataUrl = (node as HTMLImageElement).src;
  return dataUrl;
};

export const mapTemplateVersionOneToTwo = (settings: Setting[]): TemplateTypes.TemplateSettings => {
  const templateSettings: any = {};
  settings.forEach((s) => {
    let settingName = POS_RECEIPT_TEMPLATE_MAP[s.settingName];

    if (!settingName) {
      settingName = s.settingName.replace('pos.inv.', '');
    }
    if (settingName?.includes('.')) {
      return;
    }

    switch (s.settingValue) {
      case '1':
        templateSettings[settingName] = true;
        break;
      case '0':
        templateSettings[settingName] = false;
        break;
      default:
        templateSettings[settingName] = s.settingValue;
    }
  });
  templateSettings.displaySectionC = true;
  templateSettings.includePrice = true;
  templateSettings.includeTotal = true;
  return templateSettings as TemplateTypes.TemplateSettings;
};

/**
 * @deprecated
 * This function is only for backward compatibility of the mobile app.
 * Remove this once mobile team will start using template-settings-v2.
 */
export const mapTemplateVersionTwoToOne = (posSetting: Setting[], settings: TemplateTypes.TemplateSettings): Setting[] => {
  posSetting.forEach((setting) => {
    const settingName = setting.settingName.replace('pos.inv.', '');
    if (settingName.includes('.')) return;
    if (settingName in settings) {
      const settingInTemplate = settings[settingName];
      // eslint-disable-next-line no-nested-ternary,no-param-reassign
      setting.settingValue =
        settingInTemplate === true
          ? '1'
          : settingInTemplate === false
          ? '0'
          : settingInTemplate;
    }
  });
  return posSetting;
};

export const downloadFile = (fileName: string, fileUrl: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = fileUrl;
  link.click();
};

export const blobToBase64Image = (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    resolve(reader.result as string);
  }, false);

  // eslint-disable-next-line prefer-promise-reject-errors
  reader.onerror = () => reject(this);
  reader.readAsDataURL(blob);
});

export const getKeyCombination = (keyCode: number, altKey: boolean, ctrlKey?: boolean): string => `${ctrlKey ? `${ShortcutKey.Ctrl}_` : ''}${altKey ? `${ShortcutKey.Alt}_` : ''}${keyCode}`;

export const getKeyCombinationString = (key: string, altKey: boolean, ctrlKey?: boolean): string => `${ctrlKey ? `${ShortcutKey.Ctrl}_` : ''}${altKey ? `${ShortcutKey.Alt}_` : ''}${key}`;

export const mapStatusAndTransactionType = (status: string, type: string): string => {
  switch (status) {
    case ZatcaVerificationStatus.Failed:
      return ZatcaTransactionStatus.Failed;

    case ZatcaVerificationStatus.InProgress:
      return ZatcaTransactionStatus.Pending;

    case ZatcaVerificationStatus.NotSubmitted:
      return ZatcaTransactionStatus.NotSubmitted;

    case ZatcaVerificationStatus.Completed:
      return type === TransactionType.B2B
        ? ZatcaTransactionStatus.Cleared
        : ZatcaTransactionStatus.Reported;

    default:
      return ZatcaTransactionStatus.NotSubmitted;
  }
};

export const getFractionDigits = (price: number): number =>
  Number.isInteger(price) ? 0 : 2;

export const getImageDataUrl = async(url: string): Promise<string>  => {
  if (!url) return;

  if (url?.startsWith('data:image')) return url;

  try {
    const imageData = await fetch(url, {
      headers: {
        'ngsw-bypass': '1',
      },
    });
    const imageBlob = await imageData.blob();
    return await blobToBase64Image(imageBlob);
  } catch (error) {
    // eslint-disable-next-line
    console.log(`Error while fetching image ${url} ${error}`);
  }

  return url;
}


export const getDenominationPreset = (): Denomination[] => {
  return [
    {
      displayValue: 'denomination.fiveHundred',
      displayUnit: 'currency.sr',
      value: 500,
      count: null,
    },
    {
      displayValue: 'denomination.twoHundred',
      displayUnit: 'currency.sr',
      value: 200,
      count: null,
    },
    {
      displayValue: 'denomination.hundred',
      displayUnit: 'currency.sr',
      value: 100,
      count: null,
    },
    {
      displayValue: 'denomination.fifty',
      displayUnit: 'currency.sr',
      value: 50,
      count: null,
    },
    {
      displayValue: 'denomination.twenty',
      displayUnit: 'currency.sr',
      value: 20,
      count: null,
    },
    {
      displayValue: 'denomination.ten',
      displayUnit: 'currency.sr',
      value: 10,
      count: null,
    },
    {
      displayValue: 'denomination.five',
      displayUnit: 'currency.sr',
      value: 5,
      count: null,
    },
    {
      displayValue: 'denomination.one',
      displayUnit: 'currency.sr',
      value: 1,
      count: null,
    },
    {
      displayValue: 'denomination.half',
      displayUnit: 'currency.halalas',
      value: 0.5,
      count: null,
    },
    {
      displayValue: 'denomination.quarter',
      displayUnit: 'currency.halalas',
      value: 0.25,
      count: null,
    },
  ];
}

export const getFormattedTime = (date: Date): string => {
  const dateTimeObj = DateTime.fromJSDate(date);
  return `${dateTimeObj.toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  })} - ${dateTimeObj.toFormat('dd / LLL / yyyy')}`;
}


export const getCurrentRegisterShiftKey = (
  shiftId: number,
  registerId: number,
): string => `offlineInvoiceSync-${shiftId}-${registerId}`;

export const getUserDeviceIdentifierConfigKey = (
  schemaName: string,
  registerId: number,
): string => `userDeviceIdentifier-${schemaName}-${registerId}`;
