<ng-template
    #rt
    let-tracklist="result"
    let-t="term"
>
    {{ tracklist.trackNo }}
</ng-template>

<div
    [ngClass]="langCss"
    class="m-3 content-body"
>
    <div class='header-row'>
        <div class="product-col">{{ 'Product Name' | translate }}</div>
        <div class="qty-col">{{ 'Quantity' | translate }}</div>
        <div class="batchid-col-header">
            <span class="required-astrik">*</span>
            {{ 'Batch ID' | translate }}
        </div>
        <div class="qty-col">{{ 'Batch Quantity' | translate }}</div>
        <div class="qty-col">{{ 'Available Quantity' | translate }}</div>
        <div class="date-col">{{ 'Expiration Date' | translate }}</div>
        <div class="date-col">{{ 'Issue Date' | translate }}</div>
        <div class="action-col"></div>
    </div>
    <div
        *ngFor="let variant of getTrackedVariant(); let varIndex = index"
        class="w-full"
    >
        <div>
            <div class="product-summary-row">
                <div class="product-col">{{variant.name}}</div>
                <div class="qty-col">
                    {{ getVariantToInvoiceQuantity(variant)}}
                </div>
                <div class="batchid-col"></div>
                <div class="qty-col"></div>
                <div class="qty-col"></div>
                <div class="date-col"></div>
                <div class="date-col"></div>
                <div class="action-col"></div>
            </div>



            <div
                class="product-detail-row"
                [formGroup]="trackForm"
                *ngFor="let trackForm of getVariantTrackFormArray(variant.id); let formNumber = index"
            >
                <div class="product-col">
                    <span
                        class="pointer add-batch pt-2"
                        *ngIf="formNumber === getVariantTrackFormArray(variant.id).length - 1"
                        (click)="addStockLocationPrice(variant.id, null)"
                    >
                        <span class="pi pi-plus pr-2 pt-1"></span>
                        {{'New Batch' | translate}}
                    </span>
                </div>
                <div class="qty-col"></div>
                <div class="batchid-col">
                    <p-autoComplete
                        #autoComplete
                        id="typeahead-trackNo{{variant.id}}{{formNumber}}"
                        type="text"
                        [suggestions]="searchResults"
                        field="trackNo"
                        [minLength]="1"
                        (completeMethod)="onSearchTrack($event, variant.id)"
                        pendo-tracker="bpi"
                        formControlName="trackNo"
                        appendTo="body"
                    >
                        <ng-template let-track pTemplate="item">
                            {{ track.trackNo }}
                        </ng-template>
                    </p-autoComplete>
                    <small
                        class="err-msg"
                        *ngIf="trackForm.get('trackNo').errors?.Duplicated"
                    >
                        {{'You can not enter the same batch ID twice for a specific product' | translate }}
                    </small>
                    <small
                        class="err-msg"
                        *ngIf="trackForm.get('trackNo').errors?.notAvilable"
                    >
                        {{'No results found for this search.' | translate }}
                    </small>
                    <small
                        class="err-msg"
                        *ngIf="trackForm.get('trackNo').errors?.unAvailableSerial"
                    >
                        {{'This Batch is not available' | translate }}
                    </small>
                </div>
                <div class="qty-col">
                    <input pendo-tracker="bpi-1"
                        [attr.disabled]="trackForm.get('trackNo').valid? null: ''"
                        type="number"
                        numeric
                        [decimals]="3"
                        formControlName="quantity"
                        class="form-control"
                        name="quantity"
                        id="quantity"
                    >
                    <small
                        class="err-msg"
                        *ngIf="trackForm.get('quantity').errors?.unavailableQuantity"
                    >
                        {{'quantity more than the available' | translate}}
                    </small>
                </div>
                <div class="qty-col pt-2">
                    {{trackForm.get('availableQuantity').value}}
                </div>
                <div class="date-col">
                    <input pendo-tracker="bpi-2"
                        type="date"
                        useValueAsDate
                        formControlName="expiryDate"
                        [value]="date | date: 'dd/MM/yyyy'"
                        class="form-control"
                        name="expiryDate"
                        id="expiryDate"
                    >
                    <small
                        class="err-msg"
                        *ngIf="trackForm.errors?.dates"
                    >
                        {{'Must be greater than issue Date' | translate }}
                    </small>
                </div>
                <div class="date-col">
                    <input pendo-tracker="bpi-3"
                        type="date"
                        useValueAsDate
                        formControlName="issueDate"
                        [value]="date | date: 'dd/MM/yyyy'"
                        class="form-control"
                        name="issueDate"
                        id="issueDate"
                    >
                </div>
                <div class="action-col">
                    <i
                        *ngIf="isBatchRemovable(variant.id)"
                        class="pointer icon-trash-v1 vertical-align-middle text-base"
                        id="delete-product"
                        (click)="removeBatch(trackForm.get('tempId').value)"
                    ></i>
                </div>
            </div>

            <div class="product-footer-row">
                <div class="batchqty-label-footer">{{'Batch Quantity' | translate}}</div>
                <div class="batchqty-footer">
                    {{getVariantTrackAddedQuantity(variant.id)}}
                </div>
                <div
                    class="batchqty-msg-footer"
                >
                    <div
                        *ngIf="validateAssignedQuantity(variant)"
                    >
                        ({{'Batch quantity should equal '}}  {{ getVariantToInvoiceQuantity(variant)}})
                    </div>
                </div>
            </div>



        </div>
    </div>
</div>