import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { Merchant } from 'src/app/shared/model/Merchant';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { RegexEnum } from '../../../shared/model/enum/RegexPattern';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { AuthService } from '../../services/auth.service';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../shared/constants';
import { DeliveryMediumType, UserType } from '../../../shared/types/auth.types';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';

@Component({
  selector: 'rw-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss'],
})
@AutoUnsubscribe
export class ActivateUserComponent implements OnInit, AutoUnsubscribeI {
  subscriptionRefs;

  fGroup: UntypedFormGroup;

  fieldTextType = false;

  changeLang: string;

  langCss: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private merchantsService: AuthService,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private featureFlagService: FeatureFlagService,
    private toast: CustomToastService,
    private userAuthenticationService: UserAuthenticationService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['ar', 'en']);
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    if (!lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.langCss = 'rtl';
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(lang);
      this.langCss = lang === 'en' ? 'ltr' : 'rtl';
      this.changeLang = lang === 'ar' ? 'en' : 'ar';
    }
  }

  ngOnInit() {
    this.fGroup = this.formBuilder.group({
      password: [
        '',
        [Validators.required, Validators.pattern(RegexEnum.password)],
      ],
      pwdConfirm: [''],
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get password() {
    return this.fGroup.get('password');
  }

  get pwdConfirm() {
    return this.fGroup.get('pwdConfirm');
  }

  updatePassword() {
    const password = this.password.value;
    const activatedCode =
      this.activatedRoute.snapshot.paramMap.get('activatedCode');

    this.spinnerService.startLoading();

    this.subscriptionRefs = this.authService.onActivateUser({ activatedCode, password }).subscribe(async (merchant: Merchant) => {
      if (merchant) {
        try {
          this.toast.success(this.translate.instant('The password has been set successfully'));
          await this.userAuthenticationService.login({ email: merchant.email, password });

          this.router.navigateByUrl('');
        } catch (err) {
          const errMessageKey = this.userAuthenticationService.getErrorMessageKey(err);
          const message = this.translate.instant(errMessageKey);
          this.toast.error(message);

            if (err.code === 'UserNotConfirmedException') {
              this.featureFlagService
                .isEnabled(FeatureFlagEnum.SmsOtp, false)
                .subscribe(async (smsOtpFeatureFlag) => {
                  if (smsOtpFeatureFlag) {
                    const response =
                      await this.userAuthenticationService.getDeliveryMediumForUser(
                        merchant.email,
                      );
                    let queryParams = {
                      email: merchant.email,
                      type:
                        response.medium === DeliveryMediumType.EMAIL
                          ? UserType.NonGcc
                          : UserType.GccWithoutPh,
                    };
                    if (response.medium === DeliveryMediumType.SMS) {
                      queryParams = Object.assign(queryParams, {
                        phone: response.text,
                      });
                    }
                    this.router.navigate(['/confirmation'], {
                      queryParams,
                    });
                    return;
                  }
                  this.router.navigate(['/confirmation'], {
                    queryParams: { email: merchant.email },
                  });
                });
            }

            if (err.code === 'NotAuthorizedException') {
              await lastValueFrom(this.authService.logAccountDisabled(err));
            }
          }
        }
      });
  }

  switchLang(lang) {
    this.localStorageService.setItem<string>(LocalStorageKey.Language, lang);
    this.merchantsService.switchLanguage(lang);
  }
}
