import { Injectable } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import {
  OfflineTrackingInvoice,
  TrackingEventEnum,
} from '../model/invoice/Invoice';
import { ExternalChannelTrackingEvents } from '../constants';

/**
 * @deprecated use event-tracking.service.ts
 */
@Injectable()
export class TrackingEventsService {
  private trackOfflineInvoiceEvent(
    trackingInvoice: OfflineTrackingInvoice,
    eventType: TrackingEventEnum,
  ) {
    FullStory.event(eventType, {
      creationDate_date: trackingInvoice.completeDate,
      invoiceCreationTimeTakenInMS_int: trackingInvoice.timeTakenInMilliSeconds,
      invoiceNumber_str: trackingInvoice.displayInvoiceNumber,
      userId_int: trackingInvoice.userId,
      isOfflineFirst_bool: trackingInvoice.offlineFirst,
      isPlugin_bool: trackingInvoice.isPlugin,
    });
  }

  public trackOfflineInvoiceCreated(
    trackingInvoice: OfflineTrackingInvoice,
  ): void {
    this.trackOfflineInvoiceEvent(
      trackingInvoice,
      TrackingEventEnum.OfflineInvoiceCreated,
    );
  }

  public trackOfflineInvoiceSynced(
    trackingInvoices: OfflineTrackingInvoice[],
  ): void {
    trackingInvoices.forEach((trackingInvoice) => {
      this.trackOfflineInvoiceEvent(
        trackingInvoice,
        TrackingEventEnum.OfflineInvoiceSynced,
      );
    });
  }

  public posRegisterSessionOccupied(payload: {
    userId: number;
    registerId: number;
    shiftId: number;
  }): void {
    FullStory.event(TrackingEventEnum.PosRegisterSessionOccupied, {
      userId_int: payload.userId,
      registerId_int: payload.registerId,
    });
  }

  public posRegisterSessionReleased(payload: {
    userId: number;
    registerId: number;
    shiftId: number;
  }): void {
    FullStory.event(TrackingEventEnum.PosRegisterSessionReleased, {
      userId_int: payload.userId,
      registerId_int: payload.registerId,
    });
  }

  public posRegisterSessionBlocked(payload: {
    userId: number;
    registerId: number;
    shiftId: number;
  }): void {
    FullStory.event(TrackingEventEnum.PosRegisterSessionBlocked, {
      userId_int: payload.userId,
      registerId_int: payload.registerId,
    });
  }

  public posSessionNotificationReceived(payload: {
    userId: number;
    registerId: number;
    shiftId: number;
  }): void {
    FullStory.event(TrackingEventEnum.PosRegisterSessionNotificationReceived, {
      userId_int: payload.userId,
      registerId_int: payload.registerId,
      shiftId_int: payload.shiftId,
    });
  }

  public appSubscriptionUpdate(payload: {
    appId: number;
    subscriptionId: number;
    timeTakenInMilliSeconds: number;
  }): void {
    FullStory.event(ExternalChannelTrackingEvents.SubscriptionUpdate, {
      appId_int: payload.appId,
      subscriptionId_int: payload.subscriptionId,
      susbcriptionUpdateTimeTakenInMS_int: payload.timeTakenInMilliSeconds,
    });
  }

  public appProductImportAndLink(payload: {
    appId: number;
    subscriptionId: number;
    timeTakenInMilliSeconds: number;
  }): void {
    FullStory.event(ExternalChannelTrackingEvents.ProductsImportAndLink, {
      appId_int: payload.appId,
      subscriptionId_int: payload.subscriptionId,
      productsImportAndLinkTimeTakenInMS_int: payload.timeTakenInMilliSeconds,
    });
  }

  public trackOderErrorResolution(payload: {
    orderId: number;
    channel: string;
    timeTakenInMins: number;
  }): void {
    FullStory.event(ExternalChannelTrackingEvents.OrderErrorResolution, {
      orderId_int: payload.orderId,
      channel_str: payload.channel,
      orderErrorResolutionTimeTakenInMins_int: payload.timeTakenInMins,
    });
  }
}
