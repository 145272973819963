import Currency from 'currency.js';
import { Tax } from '../../shared/model/Tax';
import { INVOICE_TYPES } from '../../shared/constants';
import { VariantToInvoice } from '../../shared/model/invoice/VariantToInvoice';
import { CreditableDebitable } from '../payment/model/creditable-debitable';
import { TaxLineAmount } from '../models/tax-line-amount';
import { GetTotalTaxPOInput } from '../purchase-order-new/common/types';

const OPTIONS = { precision: 8 };

export class InvoicesCalculator {
  static getSubtotal(componentInstance): number {
    const variantsPrices = componentInstance.variants.map((variant) => variant.total);
    let subtotal = 0;
    variantsPrices.forEach((price) => {
      subtotal = new Currency(price, OPTIONS).add(subtotal).value;
    });
    return subtotal;
  }

  static getTotalTaxAmount(variants: any[], invoiceType: INVOICE_TYPES): number {
    return variants.reduce((previousValue, variant) => {
      const {
        quantity, taxAmount,
      } = this.getCostAndQuantity(variant, invoiceType);
      const totalTaxForItem = new Currency(taxAmount, OPTIONS).multiply(quantity);
      return new Currency(previousValue, OPTIONS).add(totalTaxForItem).value;
    }, 0);
  }

  static getTotalTaxAmountForPO(input: GetTotalTaxPOInput[]): number {
    return input.reduce((previousValue, curr) => {
      const totalTaxForItem = new Currency(curr.costInclusive, OPTIONS).subtract(new Currency(curr.costExclusive, OPTIONS))
        .multiply(curr.newQuantity);
      return new Currency(previousValue, OPTIONS).add(totalTaxForItem).value;
    }, 0);
  }

  static getTotalAmountForPO(input: GetTotalTaxPOInput[]): number {
    return input.reduce((previousValue, curr) => {
      const total = new Currency(curr.costInclusive, OPTIONS).multiply(curr.newQuantity);
      return new Currency(previousValue, OPTIONS).add(total).value;
    }, 0);
  }

  static getTotalAmountTaxExclusiveForPO(input: GetTotalTaxPOInput[]): number {
    return input.reduce((previousValue, curr) => {
      const total = new Currency(curr.costExclusive, OPTIONS).multiply(curr.newQuantity);
      return new Currency(previousValue, OPTIONS).add(total).value;
    }, 0);
  }

  // stock count.
  static getCostForVariantInStockCount(change: number, initialCost: number): number {
    return new Currency(change, OPTIONS).multiply(initialCost).value;
  }

  static getStockChange(newQuantity: number, availableQuantity: number): number {
    return new Currency(newQuantity, OPTIONS).subtract(availableQuantity).value;
  }

  // pay credit/ receive debit.
  static getTotalPaid(amounts: { id: number, amount: number }[]): number {
    return amounts.reduce((previousValue, enteredAmount) => new Currency(previousValue,
      OPTIONS).add(enteredAmount.amount).value, 0);
  }

  static getTotalBeforePayment(invoiceData: CreditableDebitable[], type: 'credit' | 'debit'): number {
    return invoiceData.reduce((previousValue, invoice) => new Currency(previousValue,
      OPTIONS).add(invoice[type]).value, 0);
  }

  static getTotalCreditAfterPayment(invoiceData: CreditableDebitable[], amounts: {
    id: number,
    amount: number
  }[], type): number {
    return new Currency(this.getTotalBeforePayment(invoiceData, type), OPTIONS)
      .subtract(this.getTotalPaid(amounts)).value;
  }

  // general

  static getTaxAmountPerRate(price: number, taxRate: number): number {
    return new Currency(price, OPTIONS).multiply(new Currency(taxRate, OPTIONS)
      .divide(100).value).value;
  }

  static getCompoundTaxAmountPerRate(price: number, taxRate: number, compoundTaxRate: number)
    : number {
    return new Currency(price, OPTIONS).multiply(
      new Currency(
        new Currency(1, OPTIONS)
          .add(new Currency(taxRate, OPTIONS)
            .divide(100).value).value, OPTIONS,
      )
        .multiply(new Currency(compoundTaxRate, OPTIONS)
          .divide(100).value).value,
    ).value;
  }

  static calculateInclusiveValue(exclusiveValue, tax: Tax) {
    const taxRate = new Currency(tax.rate, OPTIONS).divide(100).value;
    if (tax.coumpoundTaxLineId && tax.CoumpoundTax) {
      const taxLinesRate = new Currency(tax.TaxLines.reduce((previousValue,
        taxLine) => new Currency(previousValue, OPTIONS).add(taxLine.rate).value, 0), OPTIONS)
        .divide(100).add(1).value;
      const compoundRate = new Currency(tax.CoumpoundTax.rate, OPTIONS).divide(100).add(1).value;
      return new Currency(exclusiveValue, OPTIONS).multiply(taxLinesRate).multiply(compoundRate).value;
    }
    const factor = new Currency(1, OPTIONS).add(taxRate).value;
    return new Currency(exclusiveValue, OPTIONS).multiply(factor).value;
  }

  static calculateExclusiveValue(inclusiveValue, tax: Tax) {
    const taxRate = new Currency(tax.rate, OPTIONS).divide(100).value;
    if (tax.coumpoundTaxLineId && tax.CoumpoundTax) {
      const taxLinesRate = new Currency(tax.TaxLines.reduce((previousValue,
        taxLine) => new Currency(previousValue,
        OPTIONS).add(taxLine.rate).value, 0), OPTIONS).divide(100).add(1).value;
      const compoundRate = new Currency(tax.CoumpoundTax.rate, OPTIONS).divide(100).add(1).value;
      return new Currency(inclusiveValue, OPTIONS).divide(taxLinesRate).divide(compoundRate).value;
    }
    const factor = new Currency(1, OPTIONS).add(taxRate).value;
    return new Currency(inclusiveValue, OPTIONS).divide(factor).value;
  }

  static calculateDebitAmountFromTotal(total: number, paidAmount: number) {
    return new Currency(total, OPTIONS).subtract(paidAmount).value;
  }

  static calculateDebit(total: number, paid: number) {
    return new Currency(total, OPTIONS).subtract(paid).value;
  }

  static calculateCredit(total: number, paid: number) {
    return new Currency(total, OPTIONS).subtract(paid).value;
  }

  static calculateCreditAfterPayment(total: number, paid: number, newlyPaidAmount: number) {
    return new Currency(total, OPTIONS).subtract(paid).subtract(newlyPaidAmount).value;
  }

  static calculateDebitAfterPayment(total: number, paid: number, newlyPaidAmount: number) {
    return new Currency(total, OPTIONS).subtract(paid).subtract(newlyPaidAmount).value;
  }

  static calculateTotalPaidAfterPayment(totalPaidBeforePayment: number, paidAmount: number) {
    return new Currency(totalPaidBeforePayment, OPTIONS).add(paidAmount).value;
  }

  // general util...
  static add(a, b) {
    return new Currency(a, OPTIONS).add(b).value;
  }

  static subtract(a, b) {
    return new Currency(a, OPTIONS).subtract(b).value;
  }

  static multiply(a, b) {
    return new Currency(a, OPTIONS).multiply(b).value;
  }

  static divide(a, b) {
    return new Currency(a, OPTIONS).divide(b).value;
  }

  static groupTaxLines(lines: TaxLineAmount[]): TaxLineAmount[] {
    const groupedMap = {};
    const groupedLines: TaxLineAmount[] = [];
    lines.forEach((line) => {
      const key = `${line.name}-${line.rate}`;
      if (groupedMap[key]) {
        groupedMap[key].amount += line.amount;
      } else {
        const taxLineAmount = { ...line };
        groupedLines.push(taxLineAmount);
        groupedMap[key] = taxLineAmount;
      }
    });
    return groupedLines;
  }

  static getTaxLinesAmounts(variants: any[], taxes: Tax[],
    invoiceType: INVOICE_TYPES): TaxLineAmount[] {
    const taxLinesAmounts: TaxLineAmount[] = [];

    variants.forEach(
      (variant) => {
        const {
          cost, quantity, taxAmount,
        } = this.getCostAndQuantity(variant, invoiceType);

        const tax = taxes.find((_tax) => _tax.id === parseInt(variant['Tax Code'], 10));
        let totalTaxLinesRate = 0;
        if (tax.TaxLines && tax.TaxLines.length > 0) {
          tax.TaxLines.forEach(
            (taxLine) => {
              const taxLineAmount = new Currency(taxAmount, OPTIONS).multiply(quantity).value;
              taxLinesAmounts.push({
                id: taxLine.id, name: taxLine.name, rate: taxLine.rate, amount: taxLineAmount,
              });
              totalTaxLinesRate = this.add(totalTaxLinesRate, taxLine.rate);
            },
          );
        } else {
          taxLinesAmounts.push({
            id: 0, name: 'No Tax', rate: 0, amount: 0,
          });
        }

        if (tax.CoumpoundTax && tax.CoumpoundTax.id) {
          const compoundTaxLineRate = new Currency(tax.CoumpoundTax.rate, OPTIONS)
            .divide(100).value;
          const nonCompoundAmount = new Currency(cost, OPTIONS)
            .multiply(new Currency(totalTaxLinesRate, OPTIONS).divide(100).value).value;
          const compoundTaxLineAmount = new Currency(cost, OPTIONS)
            .add(nonCompoundAmount).multiply(compoundTaxLineRate).multiply(quantity).value;
          taxLinesAmounts.push({
            id: tax.CoumpoundTax.id,
            name: tax.CoumpoundTax.name,
            rate: tax.CoumpoundTax.rate,
            amount: compoundTaxLineAmount,
          });
        }
      },
    );
    return this.groupTaxLines(taxLinesAmounts);
  }

  private static getCostAndQuantity(variant: any,
    invoiceType: INVOICE_TYPES): {
      cost: number,
      costInclusive: number, quantity: number, taxAmount: number
    } {
    let cost = 0;
    let costInclusive = 0;
    let quantity = 0;
    const { taxAmount } = variant;
    if (invoiceType === INVOICE_TYPES.PURCHASE_ORDER) {
      cost = variant['New Cost (Tax Exclusive)'];
      costInclusive = variant['New Cost (Tax Inclusive)'];
      quantity = variant['New Quantity'];
    } else if (invoiceType === INVOICE_TYPES.RETURN_STOCK) {
      cost = variant['Return Cost (Tax Exclusive)'];
      costInclusive = variant['Return Cost (Tax Inclusive)'];
      quantity = variant['Returned Quantity'];
    } else if (invoiceType === INVOICE_TYPES.REMOVE_STOCK) {
      cost = variant['Remove Cost (Tax Exclusive)'];
      costInclusive = variant['Remove Cost (Tax Inclusive)'];
      quantity = variant['Remove Quantity'];
    }
    return {
      cost, quantity, costInclusive, taxAmount,
    };
  }

  static getTaxLinesAmountsForExistingInvoice(_variantToInvoices: VariantToInvoice[]):
  TaxLineAmount[] {
    const taxLinesAmounts: TaxLineAmount[] = [];

    _variantToInvoices.forEach(
      (variantToInvoices) => {
        const { costExclusive: cost, discount, quantity } = variantToInvoices;

        const tax = JSON.parse(variantToInvoices.taxJson);
        let totalTaxLinesRate = 0;
        tax.TaxLines = tax.TaxLines || (tax && tax.rate ? [{ rate: tax.rate }] : []);
        if (tax.TaxLines && tax.TaxLines.length > 0) {
          tax.TaxLines.forEach(
            (taxLine) => {
              const taxLineAmount = new Currency(taxLine.rate,
                OPTIONS).divide(100).multiply(quantity).multiply(cost - discount).value;
              taxLinesAmounts.push({
                id: taxLine.id, name: taxLine.name, rate: taxLine.rate, amount: taxLineAmount,
              });
              totalTaxLinesRate = this.add(totalTaxLinesRate, taxLine.rate);
            },
          );
        } else {
          taxLinesAmounts.push({
            id: 0, name: 'No Tax', rate: 0, amount: 0,
          });
        }

        if (tax.CoumpoundTax && tax.CoumpoundTax.id) {
          const compoundTaxLineRate = new Currency(tax.CoumpoundTax.rate, OPTIONS)
            .divide(100).value;
          const nonCompoundAmount = new Currency(cost, OPTIONS)
            .multiply(new Currency(totalTaxLinesRate, OPTIONS).divide(100).value).value;
          const compoundTaxLineAmount = new Currency(cost, OPTIONS)
            .add(nonCompoundAmount).multiply(compoundTaxLineRate).multiply(quantity).value;
          taxLinesAmounts.push({
            id: tax.CoumpoundTax.id,
            name: tax.CoumpoundTax.name,
            rate: tax.CoumpoundTax.rate,
            amount: compoundTaxLineAmount,
          });
        }
      },
    );
    return this.groupTaxLines(taxLinesAmounts);
  }
}
