import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { LocalStorageKey } from '../constants';
import { LocalStorageService } from '../../core/services/local-storage.service';

@Injectable()
export class CustomToastService {
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
  ) {}

  options = () => {
    if (!this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    )) {
      return { positionClass: 'md-toast-top-right' };
    }

    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    if (lang === 'en') return { positionClass: 'md-toast-top-right' };
    return { positionClass: 'md-toast-top-left' };
  };

  success(message: string, header: string = '', key?: string, data?: unknown) {
    this.messageService.add({
      key,
      severity: 'success',
      summary: header,
      detail: message,
      data,
    });
  }

  error(
    message: string,
    header: string = this.translateService.instant('Error'),
    key?: string,
  ) {
    this.messageService.add({
      key,
      severity: 'error',
      summary: header,
      detail: message,
    });
  }

  info(message: string, header: string = this.translateService.instant('Info')) {
    this.messageService.add({
      severity: 'info',
      summary: header,
      detail: message,
    });
  }

  warning(
    message: string,
    header: string = this.translateService.instant('Warning'),
    key?: string,
  ) {
    this.messageService.add({
      severity: 'warning',
      summary: header,
      detail: message,
      key,
    });
  }
}
