export enum TransferStockStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Completed = 'completed',
  Draft = 'draft',
  Requested = 'requested',
  PartiallyAccepted = 'partially-accepted',
}

export enum ProductTypeForStockTransfer {
  Simple = 'simple',
  Variable = 'variable',
  Composite = 'composite',
  Ecard = 'ecard',
  Eproduct = 'eproduct',
}
