import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ahCodeTranslate',
})
export class AcHeadCodeTransPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(code: string): string {
    const [first, ...rest] = code.split('-');
    const titleWithoutCode = rest.join('').trim();
    const translate = this.translateService.instant(
      `advancedAccounting.${titleWithoutCode}`,
    );
    if (translate.startsWith('advancedAccounting.'))
      return `${first} ${titleWithoutCode}`;
    return `${first} ${translate}`;
  }
}
