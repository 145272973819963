export function toCamelCase(input: string): string {
  return input
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-(.)/g, (_, match) => match.toUpperCase()); // Convert the first letter of each word to uppercase
}

export function translateAccountHeadName(
  translator: (key: string) => string,
  title = '',
  separator = ' ',
): string {
  return title
    .split('-')
    .map((originalTitle: string) => {
      const translate = translator(
        `advancedAccounting.${toCamelCase(originalTitle.trim())}`,
      );
      if (translate.startsWith('advancedAccounting.'))
        return originalTitle.trim();
      return translate;
    })
    .join(separator);
}
