import { createAction, props } from '@ngrx/store';
import { RewaaPlanCoupon } from './models/RewaaPlanCoupon';

export const loadCouponById = createAction(
  '[Settings effects] Get Account Subscription Coupon',
  props<{ couponId: string }>(),
);

export const couponLoaded = createAction(
  '[Settings effects] Account Subscription Coupon loaded',
  props<{ coupon: RewaaPlanCoupon }>(),
);

export const couponInvalid = createAction(
  '[Settings effects] Account Subscription loaded invalid Coupon',
  props<{ coupon: RewaaPlanCoupon }>(),
);
