import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

export class CubeJsConfig {
    private static cubeJsConfig$ = new BehaviorSubject<{
    token: string;
    options?: {
        apiUrl: string;
    }
  }>({
    token: 'hello',
    // options: { apiUrl: environment.cubejsApiUrl },
  });

  static setIdToken(idToken: string) {
    CubeJsConfig.cubeJsConfig$.next({
      token: 'Bearer ' + idToken,
      // options: { apiUrl: environment.cubejsApiUrl },
    });
  }

  static getConfig() {
    return CubeJsConfig.cubeJsConfig$;
  }
}
