import { Action, createReducer, on } from '@ngrx/store';
import { QuantityConfiguration } from 'src/app/shared/model/QuantityConfiguration';
import { ShippingConfiguration } from 'src/app/shared/model/ShippingConfiguration';
import { Tax } from 'src/app/shared/model/Tax';
import { TaxConfiguration } from 'src/app/shared/model/TaxConfiguration';
import { WeightedProductConfiguration } from '../../shared/model/WeightedProductConfiguration';
import { SettingsActions } from '../settings.action-types';

export const productConfigurationStateKey = 'productConfiguration';

export interface ProductConfigurationState {
  productConfiguration : {
    quantityConfiguration: QuantityConfiguration,
    shippingConfiguration: ShippingConfiguration,
    weightedProductConfiguration: WeightedProductConfiguration,
    taxConfiguration: TaxConfiguration,
    taxes: Tax[]
  }
}

export const initialProductConfigurationState: ProductConfigurationState = {
  productConfiguration: undefined,
};

const reducer = createReducer(
  initialProductConfigurationState,
  on(SettingsActions.productConfigurationLoaded, (_state, action) => ({
    productConfiguration: action.config,
  })),
);

export function productConfigurationReducer(
  state: ProductConfigurationState | undefined, action: Action,
) {
  return reducer(state, action);
}
