/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { StockCountEditorComponent } from '../../invoices/stock-count/v2/components/stock-count-editor/stock-count-editor.component';

@Injectable()
export class StockCountUnsavedChangesGuardService
  implements CanDeactivate<StockCountEditorComponent>
{
  canDeactivate(
    component: StockCountEditorComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean {
    component.nextState = nextState;
    const notStarted = !component.stockCountStatus;
    const isEmpty =
      !component.countedCount && component.stockCountStatus === 'Draft';

    if (notStarted || (isEmpty && component.allowRedirect)) {
      return true; // instant redirect
    }

    const notSavedDraft =
      component.stockCountStatus === 'Draft' &&
      !component.allowRedirect &&
      !component.isUpdateMode;

    if (notSavedDraft) {
      component.openCancelStockCountDialog();
      return false;
    }
    return true;
  }
}
