
<div [ngClass]="langCss" id="generate-inventory-barcode">
    <div *ngFor="let variantInfo of variantInfoArray let i = index" class="print-barcode-container">
        <div [ngClass]= "type==='STANDARD' ? 'barcode-standard' : 'barcode-large' ">  
                <p [ngClass]= "getClassProductName()" *ngIf="printFeatures.includeCompanyName">{{companyName}}</p>
                <p [ngClass]= "getClassForProductNameOnly(variantInfo.name)" *ngIf="printFeatures.includeName"><b>{{variantInfo.name}}</b></p>   
                <hr class="header" />           
                <p [ngClass]= "getClassProductPrice()" *ngIf="printFeatures.includePrice">
                    <del *ngIf="variantInfo.price !== variantInfo.originalPrice">{{variantInfo.originalPrice | number:'1.2-2'}} {{' '}} {{'SR' | translate}}</del>
                    {{variantInfo.price | number:'1.2-2'}} {{' '}} {{'SR' | translate}}
                </p>
                <p [ngClass]= "getClassProductName()" *ngIf="printFeatures.includeTaxType">({{taxStatus}})</p>
                <hr class="header"/>
                <svg #barcode class="bars" text-anchor="middle" *ngIf="printFeatures.includeBarcode"></svg>
        </div>                    
    </div>
</div>