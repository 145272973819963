import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  debounceTime,
  fromEvent,
} from 'rxjs';
import {
  ShortcutAction,
  ShortcutContext,
  Shortcut,
} from '../constants/keyboard-shortcuts.constants';
import { LocalStorageKey } from '../constants';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ShortcutKey } from '../../internal-apps/pos/utils/constants';
import { ShortcutMap } from '../model/keyboard-shortcut.type';
import { EventTrackingService } from '../../core/services/event-tracking.service';

@Injectable()
export class KeyboardShortcutService implements OnDestroy {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly eventTrackingService: EventTrackingService,
  ) {
    this.keyboardEvent$ = fromEvent(document, 'keydown')
      .pipe(debounceTime(250))
      .subscribe((event: KeyboardEvent) => this.onKeyDown(event));
    const flag = this.trySetShortcutGuideFlag();
    this.tooltipGuideEnable$.next(flag);
    this.shortcutsMap = this.getShortcutsMap();
  }

  private keyboardEvent$: Subscription;

  private availableContexts: {
    key: ShortcutContext;
    subject: Subject<ShortcutAction>;
  }[] = [];

  private tooltipGuideEnable$ = new ReplaySubject<boolean>(1);

  get tooltipEnable(): Observable<boolean> {
    return this.tooltipGuideEnable$.asObservable();
  }

  private shortcutsMap: ShortcutMap;

  ngOnDestroy(): void {
    this.keyboardEvent$.unsubscribe();
    this.availableContexts.forEach((context) => context.subject.complete());
  }

  pushContext(contextKey: ShortcutContext): Observable<ShortcutAction> {
    const contextIndex = this.availableContexts.findIndex(
      (context) => context.key === contextKey,
    );
    if (contextIndex > -1) {
      return this.availableContexts[contextIndex].subject.asObservable();
    }
    const payload = {
      key: contextKey,
      subject: new Subject<ShortcutAction>(),
    };
    this.availableContexts.push(payload);
    return payload.subject.asObservable();
  }

  popContext(contextKey: ShortcutContext): void {
    const contextIndex = this.availableContexts.some(
      (context) => context.key === contextKey,
    );
    if (contextIndex) {
      this.availableContexts.pop();
    }
  }

  clearContext(): void {
    this.availableContexts = [];
  }

  toggleTooltipGuideFlag(value: boolean): void {
    this.localStorageService.setItem<number>(
      LocalStorageKey.keyboardShortcutTooltipEnabled,
      value ? 1 : 0,
    );
    this.tooltipGuideEnable$.next(value);
  }

  private getKeyCombination(
    code: string,
    altKey: boolean,
    ctrlKey: boolean,
  ): string {
    const keyCombinations: string[] = [];

    if (altKey) {
      keyCombinations.push(ShortcutKey.Alt);
    }

    if (ctrlKey) {
      keyCombinations.push(ShortcutKey.Ctrl);
    }

    if (code) {
      keyCombinations.push(code);
    }

    return keyCombinations.join('-');
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (!this.availableContexts?.length) {
      return;
    }

    const keyCombination = this.getKeyCombination(
      event.code,
      event.altKey,
      event.ctrlKey,
    );
    const activeContext =
      this.availableContexts[this.availableContexts.length - 1];
    const currentContextType = this.shortcutsMap[activeContext.key];

    if (currentContextType[keyCombination]) {
      activeContext.subject.next(currentContextType[keyCombination]);
      this.eventTrackingService.keyboardShortcutTriggered({
        shortcut: keyCombination,
        action: currentContextType[keyCombination],
        context: activeContext.key,
      });
    }
  }

  private trySetShortcutGuideFlag(): boolean {
    let value = this.localStorageService.getItem<number>(
      LocalStorageKey.keyboardShortcutTooltipEnabled,
    );
    if (!value) {
      value = 1;
      this.localStorageService.setItem<number>(
        LocalStorageKey.keyboardShortcutTooltipEnabled,
        1,
      );
    }

    return value === 1;
  }

  private getShortcutsMap(): ShortcutMap {
    return {
      [ShortcutContext.PosCartView]: {
        [Shortcut.AltP]: ShortcutAction.PosShowPaymentDialog,
        [Shortcut.AltS]: ShortcutAction.PosFocusSearch,
        [Shortcut.F4]: ShortcutAction.PosTogglePriceMode,
        [Shortcut.F8]: ShortcutAction.PosShowSalesmanDialog,
        [Shortcut.F9]: ShortcutAction.PosShowParkSaleDialog,
        [Shortcut.F2]: ShortcutAction.PosToggleShortcutsDialog,
        [Shortcut.Delete]: ShortcutAction.PosClearCart,
      },
      [ShortcutContext.PosParkSaleForm]: {
        [Shortcut.Enter]: ShortcutAction.PosParkSale,
      },
      [ShortcutContext.PosSalePayment]: {
        [Shortcut.Enter]: ShortcutAction.PosPay,
      },
      [ShortcutContext.PosAssignSalesman]: {
        [Shortcut.AltArrowUp]: ShortcutAction.PosAssignPreviousSalesman,
        [Shortcut.AltArrowDown]: ShortcutAction.PosAssignNextSalesman,
      },
      [ShortcutContext.PosAddCustomerDialog]: {
        [Shortcut.Enter]: ShortcutAction.PosAddCustomer,
      },
    };
  }
}
