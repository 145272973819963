import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerEnums, CustomerTypes } from '@rewaa-team/pos-sdk';
import {
  RewaaApiMeta,
  RewaaBaseApiResponse,
  RewaaOffsetPaginatedResponse,
  RewaaPaginatedResponse,
} from '@rewaa-team/types';
import { Observable, from, map } from 'rxjs';
import { GetCustomerQuery } from '../../orders/customers/components/customers-list/customers-list-v2/api/get-cusomer-query';
import { Customer } from '../model/order/Customer';
import { CustomerCacheService } from './offline/customer-cache.service';
import { OfflineFirstService } from './offline/offline-first.service';
import { OnlineOfflineService } from './offline/online-offline.service';
import { PosApiService } from './pos-api.service';

@Injectable()
export class CustomerV2Service {
  constructor(
    private http: HttpClient,
    private readonly posApiService: PosApiService,
    private onlineOfflineService: OnlineOfflineService,
    private offlineFirstService: OfflineFirstService,
    private customerCacheService: CustomerCacheService,
  ) {}

  public getCustomers(
    pageQuery: CustomerTypes.SearchCustomerQueryParam,
  ): Observable<
    RewaaPaginatedResponse<
      CustomerTypes.GetCustomerOutput,
      RewaaOffsetPaginatedResponse
    >
  > {
    return this.posApiService.getCustomers(pageQuery);
  }

  public getCustomersForPos(
    pageQuery: CustomerTypes.SearchCustomerQueryParam,
  ): Observable<
    RewaaPaginatedResponse<
      CustomerTypes.GetCustomerOutput,
      RewaaOffsetPaginatedResponse
    >
  > {
    const offlineFirst = this.offlineFirstService.customersEnabled;
    if (offlineFirst || !this.onlineOfflineService.isOnline) {
      const offlineCustomerPromise = this.getCustomersOffline(pageQuery);
      return from(offlineCustomerPromise).pipe(
        map((data) => ({
          data: data.result.map((customer) =>
            this.mapToGetCustomerOutput(customer),
          ),
          meta: {
            total: data.result.length,
            limit: pageQuery.limit,
            offset: pageQuery.offset,
          },
        })),
      );
    }
    return this.posApiService.getCustomersForPos(pageQuery);
  }

  private mapToGetCustomerOutput(
    customer: Customer,
  ): CustomerTypes.GetCustomerOutput {
    return {
      id: customer.id,
      code: customer.code,
      email: customer.email,
      name: customer.name,
      gender: customer.gender as CustomerEnums.Gender,
      birthDate: customer.birthDate,
      mobileNumber: customer.mobileNumber,
      vatNumber: customer.vatNumber,
      nationalId: customer.nationalId,
      commercialRegisterNumber: customer.commercialRegisterNumber,
      address: {
        buildingNumber: customer.buildingNo,
        streetName: customer.streetName,
        district: customer.district,
        city: customer.city,
        zip: customer.zip,
        additionalNumber: customer.additionalNumber,
        source: customer.source,
        countryKey: customer.countryKey,
      },
      notes: customer.notes,
      totalPaid: customer.totalPaid,
      debitAmount: customer.debitAmount,
      customFieldsData: customer.CustomFieldsData,
    };
  }

  public getCustomerById(
    id: number,
  ): Observable<RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>> {
    return this.posApiService.getCustomerById(id);
  }

  public updateCustomer(
    customer: CustomerTypes.PatchCustomerInput,
  ): Observable<RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>> {
    return this.posApiService.updateCustomer(customer);
  }

  public createCustomer(
    customer: CustomerTypes.PostCustomerInput,
  ): Observable<RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>> {
    return this.posApiService.createCustomer(customer);
  }

  public getCustomerByCode(
    code: string,
  ): Observable<RewaaBaseApiResponse<number | null, RewaaApiMeta>> {
    return this.posApiService.getCustomerByCode(code);
  }

  public getCustomerByMobile(
    mobileNumber: string,
  ): Observable<RewaaBaseApiResponse<number | null, RewaaApiMeta>> {
    return this.posApiService.getCustomerByMobile(mobileNumber);
  }

  public autoGenerateCustomerCode(): Observable<
    RewaaBaseApiResponse<string, RewaaApiMeta>
  > {
    return this.posApiService.autoGenerateCustomerCode();
  }

  private async getCustomersOffline(
    pageQuery: GetCustomerQuery,
  ): Promise<{ result: Customer[]; total: number }> {
    const { query = '' } = pageQuery;
    return this.customerCacheService.searchCustomers(query);
  }

  public deleteCustomer(
    id: number,
  ): Observable<RewaaBaseApiResponse<boolean, RewaaApiMeta>> {
    return this.posApiService.deleteCustomer(id);
  }

  public getUnpaidCustomerInvoices(
    customerId: number,
  ): Observable<
    RewaaBaseApiResponse<CustomerTypes.UnpaidInvoiceOutput[], never>
  > {
    return this.posApiService.getUnpaidCustomerInvoices(customerId);
  }

  public receiveDebit(
    data: CustomerTypes.ReceiveDebitInput,
  ): Observable<RewaaBaseApiResponse<CustomerTypes.ReceiveDebitOutput, never>> {
    return this.posApiService.receiveDebit(data);
  }
}
