/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { TransferStockMultipleCreateComponent } from '../components/transfer-stock-multiple-create/transfer-stock-multiple-create.component';

@Injectable()
export class UnsavedChangesGuardService
  implements CanDeactivate<TransferStockMultipleCreateComponent>
{
  canDeactivate(
    component: TransferStockMultipleCreateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean {
    if (!component.formSubmitted && component.transferStockForm?.dirty) {
      if (component.transactions) {
        if (component.transactions.length === 0) {
          component.showUnsavedModal(nextState);
          return false;
        }
      } else {
        component.showUnsavedModal(nextState);
        return false;
      }
    }
    return true;
  }
}
