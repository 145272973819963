<div [ngClass]="langCss" dir="langCss">
  <div class="alert-box" class="receive-debit">
    <div class="modal-body py-0 px-5">
      <div class="flex align-items-center justify-content-between">
        <h5 class="font-bold py-4">{{'Add Rejection Reason' | translate}}</h5>
        <i class="fa-regular fa-xmark fa-lg close-button" (click)="content.cancel_btn.handler()"></i>
      </div>

      <div [formGroup]="form">
        <div class="px-0 py-2" *ngFor="let reason of reasons;">
          <p-radioButton class="radio-buttons"
                        [inputId]="reason.key"
                        name="selectReason"
                        [value]="reason.name"
                        formControlName="selectReason"
                        [disabled]=""
                        ngDefaultControl>
          </p-radioButton>
          <label class="radio-buttons-text ml-5"
                [for]="reason.key">
            {{reason.name |translate}}
          </label>
        </div>
        <div class="pt-2" *ngIf="showReasonTextarea">
          <textarea
            class="w-full"
            pInputTextarea
            formControlName="reasontextarea"
            [placeholder]="'Write Your Reason' | translate"
            rows="3"
          >
          </textarea>
        </div>
        <div class="col-12 text-right px-0">
          <button pendo-tracker="rtsb" pButton pRipple type="button" label="{{'Reject' | translate}}" rwDebounceClick (debounceClick)="reject();" (beforeDebounce)="loading = true;" [disabled]="loading" class="p-button-danger p-button-text px-4"></button>
          <button pendo-tracker="rtsb-1" pButton pRipple type="button" label="{{'Back' | translate}}" (click)="content.cancel_btn.handler()" class="p-button-text p-button-plain px-4"></button>
        </div>
      </div>
      <!-- <div class="form" [formGroup]="form">
        <div class="grid">
          <div class="col-9 pt-4 pb-1 rewaa-text-left">
            <h5 class="font-bold">{{'Add Rejection Reason' | translate}}</h5>
          </div>
          <div class="col-3 pt-4 pb-1 rewaa-text-right">
            <i class="fas fa-times close-icon"  (click)="content.cancel_btn.handler()"></i>
          </div>
          <div class="col-12">
            <div class="rounded p-4 reason-section">
              <div class="form-check pl-0 pb-3 rewaa-text-left">
                <input pendo-tracker="rtsi" class="form-check-input" type="radio" formControlName="selectReason" id="option1" value="Missing products" checked>
                <label class="form-check-label" for="option1">
                  {{'Missing products' | translate}}
                </label>
              </div>
              <div class="form-check pl-0 pb-3 rewaa-text-left">
                <input pendo-tracker="rtsi-1" class="form-check-input" type="radio" formControlName="selectReason" id="option2" value="Additional products">
                <label class="form-check-label" for="option2">
                  {{'Additional products' | translate}}
                </label>
              </div>
              <div class="form-check pl-0 pb-3 rewaa-text-left">
                <input pendo-tracker="rtsi-2" class="form-check-input" type="radio" formControlName="selectReason" id="option3" value="Different products">
                <label class="form-check-label" for="option3">
                  {{'Different products' | translate}}
                </label>
              </div>
              <div class="form-check pl-0 pb-3 rewaa-text-left">
                <input pendo-tracker="rtsi-3" class="form-check-input" type="radio" formControlName="selectReason" id="option4" value="Damaged products">
                <label class="form-check-label" for="option4">
                  {{'Damaged products' | translate}}
                </label>
              </div>
              <div class="form-check pl-0 pb-3 rewaa-text-left">
                <input pendo-tracker="rtsi-4" class="form-check-input" type="radio" formControlName="selectReason" id="option5" value="Other">
                <label class="form-check-label" for="option5">
                  {{'Other' | translate}}
                </label>
              </div> 
              <span class="p-float-label rewaa-text-left" *ngIf="showReasonTextarea">
                <textarea [style]="{'width':'100%'}" id="float-input" rows="3" cols="30" formControlName="reasontextarea" pInputTextarea></textarea>
                <label for="float-input">{{ 'Write Your Reason' | translate }}</label>
              </span> 
            </div>            
          </div>
          <div class="col-12 text-right">
            <button pendo-tracker="rtsb-2" pButton pRipple type="button" label="{{'Cancel' | translate}}" (click)="content.cancel_btn.handler()" class="p-button-text p-button-plain px-4"></button>
            <button pendo-tracker="rtsb-3" pButton pRipple type="button" label="{{'Reject' | translate}}" (click)="reject()" class="p-button-danger p-button-text px-4"></button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>