import { HttpStatusCode } from '../constants/api-constants';
import { RewaaApiException } from '../internals/base-api-exception';

export class BadRequest extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.BadRequest, errorCode, errors, stackTrace);
  }
}

export class Unauthorized extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.Unauthorized, errorCode, errors, stackTrace);
  }
}

export class NotFound extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.NotFound, errorCode, errors, stackTrace);
  }
}

export class NotAcceptable extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.NotAcceptable, errorCode, errors, stackTrace);
  }
}

export class Conflict extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.Conflict, errorCode, errors, stackTrace);
  }
}

export class UnprocessableEntity extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.UnprocessableEntity, errorCode, errors, stackTrace);
  }
}

export class InternalServerError extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.InternalServerError, errorCode, errors, stackTrace);
  }
}

export class NotImplemented extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.NotImplemented, errorCode, errors, stackTrace);
  }
}

export class BadGateway extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.BadGateway, errorCode, errors, stackTrace);
  }
}

export class ServiceUnavailable extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.ServiceUnavailable, errorCode, errors, stackTrace);
  }
}

export class GatewayTimeout extends RewaaApiException {
  constructor(errors: string[], errorCode: string, stackTrace?: string) {
    super(HttpStatusCode.GatewayTimeout, errorCode, errors, stackTrace);
  }
}
