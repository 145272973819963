import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defaultHeadsDescription } from '../../advanced-accounting/advanced-accounting.constants';

@Pipe({
  name: 'ahDescTranslatePipe',
})
export class AcHeadDescTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(description: string, referenceUri: string): string {
    if (description !== defaultHeadsDescription || !referenceUri) {
      return description;
    }
    const skipReferenceUris = [
      'reconcile-adjustments',
      'cash-in-bank-salla-orders',
      'cash-in-bank-zid-orders',
    ];
    if (
      referenceUri.startsWith('custom-head') ||
      skipReferenceUris.includes(referenceUri)
    ) {
      return description !== defaultHeadsDescription ? description : '';
    }

    const referenceUriWithIds = [
      'custom-category-{id}',
      'inventory-{id}',
      'cash-on-hand-loc-{id}',
      'cash-in-bank-{id}',
      'cash-on-hand-reg-{id}',
      'cash-on-hand-loc-{id}-treasury',
    ];

    const regexPatterns = referenceUriWithIds.map((uri) => {
      const pattern = uri.replace('{id}', '\\d+');
      return new RegExp(`^${pattern}$`);
    });

    let matchedUri = referenceUri;
    for (let i = 0; i < regexPatterns.length; i++) {
      if (regexPatterns[i].test(referenceUri)) {
        matchedUri = referenceUriWithIds[i].replace('{id}', 'Id');
        break;
      }
    }
    matchedUri = matchedUri
      .split('-')
      .map((word, index) => {
        if (index === 0) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');
    const translatedDesc = this.translateService.instant(
      `advancedAccounting.coaDescriptions.${matchedUri}`,
    );
    if (translatedDesc.startsWith('advancedAccounting.coaDescriptions.')) {
      return description !== defaultHeadsDescription ? description : '';
    }
    return translatedDesc === description ? '' : translatedDesc;
  }
}
