import { Component, Input } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { LocalStorageKey } from '../../constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';

@Component({
  selector: 'rw-language-switcher-v2',
  templateUrl: './language-switcher-v2.component.html',
  styleUrls: ['./language-switcher-v2.component.scss'],
})
export class LanguageSwitcherV2Component {
  @Input() changeLang: string;

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) { }

  switchLang(lang) {
    if (lang === 'ar') {
      this.changeLang = 'en';
      this.localStorageService.setItem<string>(
        LocalStorageKey.Language,
        'ar',
      );
    } else if (lang === 'en') {
      this.changeLang = 'ar';
      this.localStorageService.setItem<string>(
        LocalStorageKey.Language,
        'en',
      );
    }
    this.authService.switchLanguage(lang);
  }
}
