import { Injectable } from '@angular/core';
import { LocalStorageKey } from '../../shared/constants';

@Injectable()
export class LocalStorageService {
  public setItem<T>(key: LocalStorageKey, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem<T>(key: LocalStorageKey, defaultValue?: T): T {
    const value = localStorage.getItem(key);
    if (value !== undefined && value !== null) {
      // backward compatability
      try {
        return JSON.parse(value) as T;
      } catch {
        this.setItem(key, value);
        return value as unknown as T;
      }
    }
    return defaultValue;
  }

  public removeItem(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }

  public allKeys(): LocalStorageKey[] {
    return Object.keys(localStorage) as LocalStorageKey[];
  }
}
