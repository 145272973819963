import {
  CreateMerchantAppSubscription,
  ValidateSkuConflictResponse,
  UninstallAppSubscription,
  ValidateSkuConflictRequest,
} from '../internals/types/order-integrations.types';
import { RewaaApi } from '../internals/rewaa-api';
import { RewaaApiResponse } from '../internals/types/base-api-types';

export class OrderIntegrationsService extends RewaaApi {
  constructor(apiUrl: string, apiKey: string) {
    super(apiUrl, apiKey);
  }

  async createMerchantAppSubscription(
    createMerchantAppSubscription: CreateMerchantAppSubscription,
  ): Promise<RewaaApiResponse<never>> {
    return this.post('/subscriptions', createMerchantAppSubscription, {});
  }

  async uninstallAppSubscription(
    uninstallAppSubscription: UninstallAppSubscription,
  ): Promise<RewaaApiResponse<never>> {
    return this.post(
      `/subscriptions/${uninstallAppSubscription.appName}/uninstall`,
      uninstallAppSubscription,
    );
  }
  async validateSkuOnExternalChannels(
    getExternalProducts: ValidateSkuConflictRequest,
  ): Promise<RewaaApiResponse<ValidateSkuConflictResponse>> {
    return this.post(
      `/external-products/validateSkuConflict`,
      getExternalProducts,
    );
  }
}
