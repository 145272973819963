import {
  AbstractControl, ValidationErrors, ValidatorFn, UntypedFormControl,
} from '@angular/forms';

export const arrayUniquenessValidator = (control: AbstractControl): ValidationErrors => {
  const array = control.value;
  for (let i = 0; i < array.length - 1; i += 1) {
    for (let j = i + 1; j < array.length; j += 1) {
      if (array[i] === array[j]) {
        return { duplicateValues: true };
      }
    }
  }
  return null;
};

export const noWhitespaceValidator = (control: UntypedFormControl) => {
  const strLength = (control.value?.toString() || '').trim().length;
  if (strLength === 0) {
    return { whitespace: true };
  }
  return null;
};
export const dateMinimum = (date: Date): ValidatorFn => (
  control: AbstractControl,
): ValidationErrors | null => {
  if (control.value == null) {
    return null;
  }

  return new Date(control.value).getTime() >= date.getTime() ? null : {
    'date-minimum': {
      'date-minimum': date,
      actual: control.value,
    },
  };
};

export const validatePayAmount = (totalHandler: any): ValidatorFn => (
  control: AbstractControl,
): ValidationErrors | null => {
  if (control.value > 0 && control.value <= totalHandler()) {
    return null;
  }
  return { invalid: true };
};
