import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as TransferStocks from './transfer-stock.reducers';
import { TransferStocksState } from './transfer-stock.reducers';

export const selectTransferStocksState = createFeatureSelector<TransferStocksState>('transfer-stocks');

export const selectAllTransferStock = createSelector(
  selectTransferStocksState,
  TransferStocks.selectAll,
);

export const selectTransferStockById = (id: number) => createSelector(
  selectAllTransferStock,
  (transferStocks) => transferStocks.find((transferStock) => transferStock.id === id),
);

export const areTransferStocksLoaded = createSelector(
  selectTransferStocksState,
  (state) => state.areTransferStocksLoaded,
);

export const isTransferStockProcessing = createSelector(
  selectTransferStocksState,
  (state) => state.isProcessing,
);
