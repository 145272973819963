import { SlackOptions } from '../types/slack-service-types';
import { SNS } from '@aws-sdk/client-sns';

export class SlackService {
  private options: SlackOptions;
  private sns: SNS = new SNS({});
  private channelTopics: Map<string, string> = new Map();
  constructor(options: SlackOptions) {
    this.options = options;
  }

  async initialize(): Promise<void> {
    try {
      for (const channel of this.options.channels) {
        const topicName = this.getTopicName(channel.name);
        const topicCreateResponse = await this.sns.createTopic({
          Name: topicName,
        });
        const topicArn = topicCreateResponse.TopicArn;
        if (!topicArn) {
          throw new Error(`SlackService: Topic creation failed`);
        }
        this.channelTopics.set(channel.name, topicArn);
        const subscriptions = await this.sns.listSubscriptionsByTopic({
          TopicArn: topicArn,
        });
        if (
          !subscriptions.Subscriptions?.some(
            (subscription) =>
              subscription.Endpoint === channel.subscriptionEndpoint,
          )
        ) {
          await this.sns.subscribe({
            TopicArn: topicArn,
            Endpoint: channel.subscriptionEndpoint,
            Protocol: 'HTTPS',
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error in Slack Service Init: ${JSON.stringify(error)}`);
    }
  }

  async sendMessage(channelName: string, message: string): Promise<boolean> {
    try {
      if (['local', 'test'].includes(this.options.environment)) {
        return true;
      }
      const topicArn = this.channelTopics.get(channelName);
      if (!topicArn) {
        throw new Error(
          `SlackService: Topic not found for channel ${channelName}`,
        );
      }
      return !!(await this.sns.publish({
        TopicArn: topicArn,
        Message: `Environment: ${this.options.environment}\n\n` + message,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        `Error in Slack Service sendMessage: ${JSON.stringify(error)}`,
      );
      return false;
    }
  }

  private getTopicName(channelName: string): string {
    return `${channelName}-${this.options.environment}`;
  }
}
