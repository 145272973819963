/* eslint-disable max-classes-per-file */
import { Category } from 'src/app/inventory/model/category';
import { Product, ProductImage } from 'src/app/inventory/model/product';
import { ProductVariant } from 'src/app/inventory/model/product-variant';
import { Register } from './Register';

export class Layout {
  Registers: Register[];

  createdAt: string;

  createdBy: string;

  deletedAt: string;

  description: string;

  id: number;

  name: string;

  type: string;

  updatedAt: string;

  LayoutCategories: Array<LayoutCategory>;

  hasSearchBox = false;
}

export class LayoutCategory extends Category {
  order: number;

  dataFetched: boolean;

  children?: LayoutCategory[];

  products?: any[] = [];

  LayoutProducts?: any[] = [];

  productCount: number;

  productOffset = 1;

  type: string;

  selected = false;

  excludedProducts: number[] = [];

  excludedVariants: number[] = [];

  allProductsFetched = false;

  isQuickMenu = false;

  loadedChildren = false;

  categoryId?: number;

  drag_type: string;

  seq?: number;

  parentCategoryId?:number;

  constructor(type?) {
    super();
    Object.assign(this, type || {});
  }
}

export class LayoutProduct extends Product {
  public dataFetched: boolean;

  public order?: number;

  public imageInfo?: ProductImage;

  public variants?: LayoutProductVariant[];

  public drag_type: string;
}

export class LayoutProductVariant extends ProductVariant {
  public order: number;

  public imageInfo: string;

  public options: string;

  public productName: string;

  public drag_type: string;
}
