import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { NgxFileDropModule } from 'ngx-file-drop';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { SupplierCreateComponent } from '../inventory/products/components/create/supplier-create/supplier-create.component';
import { CategoryCreateComponent } from './components/category-create/category-create.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { RoundPipe } from './components/datatable/round.pipe';
import { NoScrollInputDirective } from './directives/wheel.directive';
import { SelectOnClickDirective } from './directives/select-on-click.directive';
import { AddWithdrawMoneyComponent } from './components/modal/components/add-withdraw-money/add-withdraw-money.component';
import { AppDetailsPopupComponent } from './components/modal/components/app-details-popup/app-details-popup.component';
import { OfflineHintsComponent } from './components/modal/components/offline-hints/offline-hints.component';
import { PrintBarcodeComponentV2 } from './components/modal/components/print-barcode/index';
import { ReceiveDebitComponent } from './components/modal/components/receive-debit/receive-debit.component';
import { UninstallAppComponent } from './components/modal/components/uninstall-app/uninstall-app.component';
import { ModalComponent } from './components/modal/modal.component';
import { OfflineViewComponent } from './components/offline-view/offline-view.component';
import { ProductFilterDropdownComponent } from './components/products-filter-dropdown/product-filter-dropdown.component';
import { CustomLocationSearchComponent } from './components/searching-components/custom-location-search/custom-location-search.component';
import { LocationSearchComponent } from './components/searching-components/location-search/location-search.component';
import { SupplierSearchComponent } from './components/searching-components/supplier-search/supplier-search.component';
import { VariantSearchComponent } from './components/searching-components/variant-search/variant-search.component';
import { UploadProductImageComponent } from './components/upload-product-image/upload-product-image.component';
import { DragDropFileDirective } from './directives/drag-drop-file.directive';
import { FocusOnElementDirective } from './directives/focus.directive';
import { NumericDirective } from './directives/numeric.directive';
import { SelectImgDirective } from './directives/select-img.directive';
import { SortableHeaderDirective } from './directives/sortable-header.directive';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { ApiResponseInterceptor } from './interceptors/api.response.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { SideMenuModule } from './modules/side-menu/side-menu.module';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { CustomTranslationService } from './services/custom-translate.service';
import { FileUploadService } from './services/file-upload.service';
import { SpinnerService } from './services/spinner.service';
import { TaxesService } from './services/tax.service';

import { environment } from '../../environments/environment';
import { PosSettingsService } from '../internal-apps/pos/services/pos-settings.service';
import { TemplateService } from '../internal-apps/pos/services/template.service';
import { PaymentService } from '../invoices/payment/services/payment.service';
import { CustomersService } from '../orders/customers/services/customers.service';
import { FileSizePipe } from '../pipes/file-size.pipe';
import { AddSupplierComponent } from './components/add-supplier/add-supplier.component';
import { CountriesInputComponent } from './components/countries-input/countries-input.component';
import { DatatablePoVariantHeaderComponent as DatatablePOVariantHeaderComponent } from './components/datatable-po-variant/datatable-po-variant-header/datatable-po-variant-header.component';
import { DatatablePOVariantComponent } from './components/datatable-po-variant/datatable-po-variant.component';
import { DatatableV1Component } from './components/datatable-v1/datatable-v1.component';
import { DatatableV2Component } from './components/datatable-v2/datatable-v2.component';
import { DraggableElementComponent } from './components/draggable-element/draggable-element.component';
import { ImportBreadcrumbComponent } from './components/importer/import-breadcrumb/import-breadcrumb.component';
import { ImportHeaderComponent } from './components/importer/import-header/import-header.component';
import { ImportInfoComponent } from './components/importer/import-info/import-info.component';
import { ImporterComponent } from './components/importer/importer.component';
import { ProductsDropdownComponent } from './components/products-dropdown/products-dropdown.component';
import { LocationsDropdownComponent } from './components/locations-dropdown/locations-dropdown.component';
import { PromotionBreadcrumbComponent } from './components/promotion-breadcrumb/promotion-breadcrumb.component';
import { UnsavedChangesGuardService } from './services/unsaved-changes-guard.service';
import { StockCountUnsavedChangesGuardService } from './services/stock-count-unsaved-changes-guard.service';
import { FormatIdPipe } from './pipes/format-id.pipe';
import { RejectTransferStockComponent } from './components/modal/components/reject-transfer-stock/reject-transfer-stock.component';
import { LanguageSwitcherV2Component } from './components/language-switcher-v2/language-switcher-v2.component';
import { DateService } from './utility/date.service';
import { ConfigStoreService } from './services/offline/indexedDB/config-store.service';
import { CustomerStoreService } from './services/offline/indexedDB/customer-store.service';
import { InvoiceStoreService } from './services/offline/indexedDB/invoice-store.service';
import { OfflineInvoiceService } from './services/offline/offline-invoice.service';
import { CompressorService } from './services/compressor.service';
import { KeyboardShortcutsComponent } from './components/keyboard-shortcuts/keyboard-shortcuts.component';
import { AddSalesmanComponent } from './components/modal/components/add-salesman/add-salesman.component';
import { CancelProgressComponent } from './components/modal/components/cancel-progress/cancel-progress.component';
import { DeleteBulkProductsComponent } from './components/modal/components/delete-bulk-products/delete-bulk-products.component';
import { ErrorDetailsPopupComponent } from './components/modal/components/error-details-popup/error-details-popup.component';
import { PayWithGoCollectComponent } from './components/modal/components/pay-with-go-collect/pay-with-go-collect.component';
import { GenerateBarcodeComponent } from './components/modal/components/print-barcode/generate-barcode/generate-barcode.component';
import { TransferStockPreviewComponent } from './components/modal/components/transfer-stock-preview/transfer-stock-preview.component';
import { TransferStockPrintComponent } from './components/modal/components/transfer-stock-print/transfer-stock-print.component';
import { ViewQuoteComponent } from './components/modal/components/view-quote/view-quote.component';
import { ProductPreviewComponent } from './components/product-preview/product-preview.component';
import { ReceiptTemplateComponentOld } from './components/receipt-template-old/receipt-template-old.component';
import { SaleReceiptTemplateComponent } from './components/sale-receipt-template/sale-receipt-template.component';
import { VoucherComponent } from './components/voucher/voucher.component';
import { RegisterReportTemplateComponent } from './components/register-report-template/register-report-template.component';
import { AdvanceAccountingSearchComponent } from './components/searching-components/advance-accounting-search/advance-accounting-search.component';
import { LocationSearchV2Component } from './components/searching-components/location-search-v2/location-search.component';
import { SupplierSearchV2Component } from './components/searching-components/supplier-search-v2/supplier-search.component';
import { VariantSearchV2Component } from './components/searching-components/variant-search-v2/variant-search.component';
import { MarkAsteriskDirective } from './directives/MarkAsteriskDirective';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { NumScrollDisableDirective } from './directives/scroll-disable-input.directive';
import { FeatureFlagGuard } from './guards/feature-flag.guard';
import { GzipCompressionInterceptor } from './interceptors/gzip-response.interceptor';
import { BreadcrumbV2Module } from './modules/breadcrumb-v2';
import { DatatableV3Module } from './modules/datatable-v3/datatable-v3.module';
import { AbsPipe } from './pipes/abs.pipe';
import { AmountFormatPipe } from './pipes/amount-format.pipe';
import { FormatDatePipe } from './pipes/format-date';
import { ParenthesizeNegativePipe } from './pipes/parenthesize-negative';
import { FeatureFlagServiceLD } from '../core/services/feature-flag.service';
import { FirebaseAppService } from './services/firebase.services/firebase-app.service';
import { FirebaseAuthenticationService } from './services/firebase.services/firebase-authentication.service';
import { FirebaseNotificationService } from './services/firebase.services/firebase-notification.service';
import { FirebaseQueryProviderService } from './services/firebase.services/firebase-query-provider.service';
import { FirebaseUserService } from './services/firebase.services/firebase-user.service';
import { FormatAppNamesService } from './services/format-app-names.service';
import { FeatureFlagMockService } from './services/mocks/feature-flag.service.mock';
import { PoUnsavedChangesGuardService } from './services/po-unsaved-changes-guard.service';
import { PrintService } from './services/print.service';
import { TranslateAppNamesService } from './services/translate-app-names.service';
import { FeatureFlagService } from './services/types/feature-flag.service.interface';
import { UserGuidingService } from './services/user-guiding.service';
import { CustomFieldsInputComponent } from './components/custom-fields-input/custom-fields-input.component';
import { CustomFieldDetailsComponent } from '../users-settings/components/custom-field-details/custom-field-details.component';
import { CustomFieldDetailsMainFormComponent } from '../users-settings/components/custom-field-details/custom-field-details-main-form/custom-field-details-main-form.component';
import { CustomFieldFormService } from '../users-settings/services/custom-field-form.service';
import { CustomFieldDetailsSecondaryFormComponent } from '../users-settings/components/custom-field-details/custom-field-details-secondary-form/custom-field-details-secondary-form.component';
import { CustomFieldPreviewComponent } from '../users-settings/components/custom-field-details/custom-field-preview/custom-field-preview.component';
import { CustomFieldDetailsDialogComponent } from '../users-settings/components/custom-field-details/custom-field-details-dialog/custom-field-details-dialog.component';
import { TabViewModule } from './tab-view/tab-view.module';
import { CategoriesQuickViewComponent } from './components/categories-quick-view/categories-quick-view.component';
import { LabelsService } from './services/labels-service';
import { AssignCostCentersComponent } from './components/assign-cost-centers/assign-cost-centers.component';
import { AssignCostCentersModalComponent } from './components/assign-cost-centers-modal/assign-cost-centers-modal.component';
import { PluckPipe } from './pipes/pluck.pipe';
import { LabelMappingService } from './services/label-mapping.service';
import { UiModule } from '../ui/ui.module';
import { NumberDialogueComponent } from './components/number-dialogue/number-dialogue.component';
import { TransferStockMultipleCreateComponent } from './components/transfer-stock-multiple-create/transfer-stock-multiple-create.component';
import { TransferStockHelperService } from '../invoices/transfer-stock/services/transfer-stock-helper.service';
import { SerialProductComponent } from './components/serial-product/serial-product.component';
import { BatchedProductsComponent } from './components/batched-products/batched-products.component';
import { TrackedProductsComponent } from './components/tracked-products/tracked-products.component';
import { ExportInvoiceComponent } from './components/export-invoice/export-invoice-compnent';
import { InvoiceEcardsComponent } from './components/invoice-ecards/invoice-ecards.component';
import { InvoiceTrackingInfoComponent } from './components/invoice-tracking-info/invoice-tracking-info.component';
import { PurchaseOrderListToolbarComponent } from './components/purchase-order-list-toolbar/purchase-order-list-toolbar.component';
import { TrialEndedDialogComponent } from './components/trial-ended/trial-ended-dialog.component';
import { TrialBannerComponent } from './components/trial-baner/trial-banner.component';
import { RemoveRejectedService } from '../invoices/transfer-stock/services/remove-rejected.service';
import { ScrollableTabsV2Component } from './components/scrollable-tabs-v2/scrollable-tabs-v2.component';
import { CustomerReceiveDebitV3Component } from './components/customer-receive-debit-v3/customer-receive-debit-v3.component';
import { PosApiService } from './services/pos-api.service';
import { CustomerV2Service } from './services/customer-v2.service';
import { KeyboardShortcutService } from './services/keyboard-shortcut.service';
import { HighlightSearchPipe } from './pipes/highlight.pipe';
import { VirtualScrollDropdownComponent } from './components/virtual-scroll-dropdown/virtual-scroll-dropdown.component';
import { KitchenReceiptTemplateComponent } from './components/kitchen-receipt-template/kitchen-receipt-template.component';
import { AcHeadTransPipe } from './pipes/ah-translate.pipe';
import { AcHeadCodeTransPipe } from './pipes/ah-code-translate.pipe';
import { AcHeadCodeTitlePipe } from './pipes/ah-code-title.pipe';
import { LedgerReferenceIdDisplayPipe } from './pipes/ledger-reference-id-display.pipe';
import { LeftShiftService } from './services/left-shift.service';
import { LeftShiftDirective } from './directives/left-shift.directive';
import { CustomerReceiveDebitV4Component } from './components/customer-receive-debit-v4/customer-receive-debit-v4.component';
import { CustomerReceiveDebitWrapperComponent } from './components/customer-receive-debit-wrapper/customer-receive-debit-wrapper.component';
import { ReceiveDebitComponentV2 } from './components/modal/components/receive-debit';
import { SaleReceiptA4Component } from './components/sale-receipt-a4/sale-receipt-a4.component';
import { SaleReceiptThermalComponent } from './components/sale-receipt-thermal/sale-receipt-thermal.component';
import { DashboardPermissionDirective } from './directives/dashboard-permission.directive';
import { MonetaryInputDirective } from './directives/monetary-input/monetary-input.directive';
import { MonetaryInputComponent } from './components/input-monetary/input-monetary.component';
import { TransactionsMappingUpdateBannerComponent } from '../users-settings/transactions-mapping/transactions-mapping-update-banner/transactions-mapping-update-banner';
import { AcHeadDescTranslatePipe } from './pipes/ah-desc-translate.pipe';
import { FormatBigNumberPipe } from './pipes/format-big-number';
import { ImexService } from './services/imex.service';
import { CustomSearchDropdownComponent } from './components/custom-search-dropdown/custom-search-dropdown.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // Changed this to at least burst the local cache at least once so that the user gets new file.
  // This change will be removed in the next release.
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?v=1');
}

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    NgChartsModule,
    SideMenuModule,
    BreadcrumbV2Module,
    DatatableV3Module,
    RouterModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    FormsModule,
    FileSizePipe,
    PluckPipe,
    AutoCompleteModule,
    TabViewModule,
    UiModule,
  ],
  exports: [
    DatatablePOVariantHeaderComponent,
    CustomFieldDetailsDialogComponent,
    CustomFieldDetailsComponent,
    CustomFieldDetailsMainFormComponent,
    CustomFieldDetailsSecondaryFormComponent,
    CustomFieldPreviewComponent,
    DatatablePOVariantComponent,
    NumScrollDisableDirective,
    SelectImgDirective,
    AddSupplierComponent,
    OfflineViewComponent,
    DatatableComponent,
    SortableHeaderDirective,
    NoScrollInputDirective,
    SelectOnClickDirective,
    ModalComponent,
    LocationSearchComponent,
    LocationSearchV2Component,
    SupplierSearchComponent,
    SupplierSearchV2Component,
    VariantSearchComponent,
    VariantSearchV2Component,
    SupplierCreateComponent,
    SafePipe,
    NumericDirective,
    FeatureFlagDirective,
    DashboardPermissionDirective,
    UploadProductImageComponent,
    DragDropFileDirective,
    FocusOnElementDirective,
    PrintBarcodeComponentV2,
    DeleteBulkProductsComponent,
    CustomLocationSearchComponent,
    ProductFilterDropdownComponent,
    OrdinalPipe,
    SideMenuModule,
    BreadcrumbV2Module,
    DatatableV3Module,
    MarkAsteriskDirective,
    ImportInfoComponent,
    ImporterComponent,
    ProductsDropdownComponent,
    LocationsDropdownComponent,
    PromotionBreadcrumbComponent,
    ImporterComponent,
    FormatIdPipe,
    DragDropModule,
    DraggableElementComponent,
    LanguageSwitcherV2Component,
    ReactiveFormsModule,
    FormsModule,
    NumScrollDisableDirective,
    DatatableV1Component,
    DatatableV2Component,
    AdvanceAccountingSearchComponent,
    ReceiptTemplateComponentOld,
    VoucherComponent,
    TransferStockPreviewComponent,
    TransferStockPrintComponent,
    CountriesInputComponent,
    NumbersOnlyDirective,
    KeyboardShortcutsComponent,
    RegisterReportTemplateComponent,
    FileSizePipe,
    PluckPipe,
    FormatDatePipe,
    AbsPipe,
    AmountFormatPipe,
    LedgerReferenceIdDisplayPipe,
    AcHeadTransPipe,
    AcHeadCodeTransPipe,
    AcHeadDescTranslatePipe,
    CustomFieldsInputComponent,
    ParenthesizeNegativePipe,
    FormatBigNumberPipe,
    AssignCostCentersComponent,
    AssignCostCentersModalComponent,
    NumberDialogueComponent,
    MonetaryInputDirective,
    TransferStockMultipleCreateComponent,
    SerialProductComponent,
    BatchedProductsComponent,
    TrackedProductsComponent,
    ExportInvoiceComponent,
    InvoiceEcardsComponent,
    InvoiceTrackingInfoComponent,
    PurchaseOrderListToolbarComponent,
    TrialEndedDialogComponent,
    TrialBannerComponent,
    ScrollableTabsV2Component,
    SaleReceiptTemplateComponent,
    VirtualScrollDropdownComponent,
    CustomSearchDropdownComponent,
    KitchenReceiptTemplateComponent,
    ReceiveDebitComponentV2,
    LeftShiftDirective,
    SaleReceiptA4Component,
    SaleReceiptThermalComponent,
    MonetaryInputComponent,
    TransactionsMappingUpdateBannerComponent,
  ],
  declarations: [
    HighlightSearchPipe,
    ParenthesizeNegativePipe,
    FormatBigNumberPipe,
    LanguageSwitcherV2Component,
    ModalComponent,
    SelectImgDirective,
    DatatablePOVariantComponent,
    CustomFieldDetailsDialogComponent,
    DatatablePOVariantHeaderComponent,
    OfflineViewComponent,
    CustomFieldDetailsComponent,
    CustomFieldDetailsSecondaryFormComponent,
    CustomFieldDetailsMainFormComponent,
    CustomFieldPreviewComponent,
    DatatableComponent,
    AppDetailsPopupComponent,
    UninstallAppComponent,
    SortableHeaderDirective,
    NoScrollInputDirective,
    SelectOnClickDirective,
    LocationSearchComponent,
    LocationSearchV2Component,
    SupplierSearchComponent,
    SupplierSearchV2Component,
    VariantSearchComponent,
    VariantSearchV2Component,
    SafePipe,
    SupplierCreateComponent,
    CategoryCreateComponent,
    NumericDirective,
    FeatureFlagDirective,
    DashboardPermissionDirective,
    FocusOnElementDirective,
    UploadProductImageComponent,
    DragDropFileDirective,
    PrintBarcodeComponentV2,
    DeleteBulkProductsComponent,
    AddWithdrawMoneyComponent,
    OfflineHintsComponent,
    CustomLocationSearchComponent,
    ProductFilterDropdownComponent,
    OrdinalPipe,
    RoundPipe,
    ReceiveDebitComponent,
    CustomerReceiveDebitV3Component,
    MarkAsteriskDirective,
    ImporterComponent,
    ImportInfoComponent,
    ImportBreadcrumbComponent,
    ImportHeaderComponent,
    RejectTransferStockComponent,
    ProductsDropdownComponent,
    LocationsDropdownComponent,
    PromotionBreadcrumbComponent,
    FormatIdPipe,
    DraggableElementComponent,
    NumScrollDisableDirective,
    DatatableV1Component,
    DatatableV2Component,
    AdvanceAccountingSearchComponent,
    PayWithGoCollectComponent,
    AddSalesmanComponent,
    ErrorDetailsPopupComponent,
    ViewQuoteComponent,
    NumScrollDisableDirective,
    SaleReceiptTemplateComponent,
    ReceiptTemplateComponentOld,
    VoucherComponent,
    TransferStockPreviewComponent,
    TransferStockPrintComponent,
    CancelProgressComponent,
    CountriesInputComponent,
    NumbersOnlyDirective,
    AddSupplierComponent,
    GenerateBarcodeComponent,
    KeyboardShortcutsComponent,
    RegisterReportTemplateComponent,
    ProductPreviewComponent,
    FormatDatePipe,
    AbsPipe,
    LedgerReferenceIdDisplayPipe,
    AmountFormatPipe,
    AcHeadTransPipe,
    AcHeadCodeTransPipe,
    AcHeadDescTranslatePipe,
    CustomFieldsInputComponent,
    AssignCostCentersComponent,
    AssignCostCentersModalComponent,
    CategoriesQuickViewComponent,
    NumberDialogueComponent,
    MonetaryInputDirective,
    TransferStockMultipleCreateComponent,
    SerialProductComponent,
    BatchedProductsComponent,
    TrackedProductsComponent,
    ExportInvoiceComponent,
    InvoiceEcardsComponent,
    InvoiceTrackingInfoComponent,
    PurchaseOrderListToolbarComponent,
    TrialEndedDialogComponent,
    TrialBannerComponent,
    ScrollableTabsV2Component,
    VirtualScrollDropdownComponent,
    CustomSearchDropdownComponent,
    SaleReceiptTemplateComponent,
    KitchenReceiptTemplateComponent,
    ReceiveDebitComponentV2,
    CustomerReceiveDebitV4Component,
    CustomerReceiveDebitWrapperComponent,
    LeftShiftDirective,
    SaleReceiptA4Component,
    SaleReceiptThermalComponent,
    MonetaryInputComponent,
    TransactionsMappingUpdateBannerComponent,
  ],
  providers: [
    TransferStockHelperService,
    FileUploadService,
    CustomFieldFormService,
    SpinnerService,
    FirebaseAppService,
    FirebaseAuthenticationService,
    FirebaseUserService,
    FirebaseNotificationService,
    FirebaseQueryProviderService,
    TaxesService,
    ThemeService,
    UnsavedChangesGuardService,
    StockCountUnsavedChangesGuardService,
    PoUnsavedChangesGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GzipCompressionInterceptor,
      multi: true,
    },
    CustomTranslationService,
    { provide: 'customTranslator', useExisting: CustomTranslationService },
    DateService,
    CustomersService,
    CustomerStoreService,
    InvoiceStoreService,
    ConfigStoreService,
    OfflineInvoiceService,
    PaymentService,
    CompressorService,
    {
      provide: FeatureFlagService,
      useClass: environment.mockLaunchDarkly
        ? FeatureFlagMockService
        : FeatureFlagServiceLD,
    },
    PrintService,
    PosSettingsService,
    UserGuidingService,
    TemplateService,
    FeatureFlagGuard,
    DatePipe,
    DecimalPipe,
    LedgerReferenceIdDisplayPipe,
    AcHeadTransPipe,
    AcHeadCodeTransPipe,
    AcHeadDescTranslatePipe,
    AcHeadCodeTitlePipe,
    TranslateAppNamesService,
    FormatAppNamesService,
    LabelMappingService,
    LabelsService,
    RemoveRejectedService,
    PosApiService,
    CustomerV2Service,
    KeyboardShortcutService,
    LeftShiftService,
    ImexService,
  ],
})
export class SharedModule {}
