import { CartCalculationService } from './cart-calculation.service';
import { v4 } from 'uuid';
import {
  Cart,
  Extra,
  ExtraLineItem,
  LineItem,
  LineItemFindByParam,
  LineItemPrice,
  LineItemTax,
  TrackDetail,
  Variant,
} from './types';
import { CartTypes, InvoiceEnums } from '..';
import { ProductEnums } from '@rewaa-team/types';

class SellCartService extends CartCalculationService {
  /**
   *
   * @param cart The current cart
   * @param item The item variant to add to cart
   * @param quantity The quantity of the item to add
   * @param trackNumbers An array of string track numbers for adding serial products
   * @returns
   */
  public addSellLineItem(
    cart: Cart,
    item: Variant,
    quantity = 1,
    trackNumbers: string[] = [],
  ): Cart {
    const { lineItems } = cart;
    const lineItemIndex = lineItems.findIndex(
      (lineItem) => lineItem.sku === item.sku,
    );

    // update if line item exists in cart and is not an extra
    if (lineItemIndex >= 0 && !item.extras.length) {
      const lineItem = lineItems[lineItemIndex];
      return this.updateLineItemQuantity(
        cart,
        {
          id: lineItem.id,
        },
        lineItem.quantity + quantity,
      );
    }

    const newLineItem = this.mapVariantToLineItem(item, cart.priceMode, {
      quantity,
    });

    newLineItem.quantity = quantity;
    newLineItem.eCards = this.updateECardQuantity(newLineItem.eCards, quantity);

    let newQuantity = quantity;

    // update track details and quantity
    if (newLineItem.trackType) {
      const packRate =
        newLineItem.type === ProductEnums.VariantType.Package
          ? item.packs[0]?.rate || 1
          : 1;
      newLineItem.trackDetails = this.getTrackDetails(
        newLineItem.trackDetails,
        newLineItem.trackType,
        quantity,
        packRate,
        trackNumbers,
      );
      newQuantity = this.getTrackQuantity(newLineItem.trackDetails, packRate);
    }

    return this.addLineItem(cart, newLineItem, newQuantity);
  }

  private getBatchTrackDetails(
    tracks: TrackDetail[],
    quantity: number,
    existingQuantity = 0,
  ): TrackDetail[] {
    let trackIndex = 0;
    let quantityLeft = quantity + existingQuantity;
    while (trackIndex < tracks.length - 1) {
      const track = tracks[trackIndex];
      const { availableQuantity } = track;
      const batchQuantity = Math.min(availableQuantity, quantityLeft);
      track.quantity = batchQuantity;
      quantityLeft -= batchQuantity;
      trackIndex += 1;
    }
    tracks[trackIndex].quantity = quantityLeft;
    return tracks;
  }

  private getTrackDetails(
    trackDetails: TrackDetail[],
    trackType: ProductEnums.TrackType,
    quantity: number,
    packRate: number,
    trackNumbers: string[] = [],
  ): TrackDetail[] {
    if (!trackDetails.length) {
      return [];
    }
    if (trackType === ProductEnums.TrackTypeConstant.Batch) {
      return this.getBatchTrackDetails(trackDetails, quantity * packRate);
    }
    if (trackType === ProductEnums.TrackTypeConstant.Serial) {
      return this.getSerialTrackDetails(trackDetails, trackNumbers);
    }
    return trackDetails;
  }

  /**
   *
   * @param eCards array of all the ecards
   * @param quantity total quantity of ecards for this lineitem
   * @returns eCards array with the updated quantity
   */
  private updateECardQuantity(
    eCards: CartTypes.ECard[],
    quantity: number,
  ): CartTypes.ECard[] {
    let quantityAdded = 0;
    return eCards.map((eCard): CartTypes.ECard => {
      const qty = quantityAdded < quantity ? 1 : 0;
      quantityAdded += 1;

      return {
        ...eCard,
        quantity: qty,
      };
    });
  }

  mapVariantToLineItem(
    variant: Variant,
    priceMode: InvoiceEnums.PriceMode,
    partialLineItem: Partial<LineItem> = {},
  ): LineItem {
    const id = v4();
    const retail = variant.retailPrice;
    const wholesale = variant.wholesalePrice;
    const base =
      priceMode === InvoiceEnums.PriceModeConstant.Wholesale
        ? variant.wholesalePrice
        : variant.retailPrice;

    const extras = this.mapVariantExtraToLineItemExtra(variant.extras);
    const extrasTotal = 0;

    const priceTaxExclusive: LineItemPrice = {
      base,
      retail,
      wholesale,
      final: base,
      extrasTotal,
    };

    const tax: LineItemTax = this.mapTaxToLineItemTax(variant.tax, base);

    const priceTaxInclusive: LineItemPrice = {
      base: this.getTaxInclusivePrice(tax, priceTaxExclusive.base),
      retail: this.getTaxInclusivePrice(tax, priceTaxExclusive.retail),
      wholesale: this.getTaxInclusivePrice(tax, priceTaxExclusive.wholesale),
      final: this.getTaxInclusivePrice(tax, priceTaxExclusive.final),
      extrasTotal,
    };

    const totalTax = this.calculateTax(base, tax.rate);

    const total = this.getTaxInclusivePrice(tax, base);

    return {
      id,
      productId: variant.productId,
      quantity: 1,
      variantId: variant.variantId,
      sku: variant.sku,
      totalDiscount: 0,
      name: variant.name,
      productName: variant.productName,
      imageUrl: variant.imageUrl,
      availableQuantity: variant.availableQuantity,
      priceTaxExclusive,
      priceTaxInclusive,
      discounts: [],
      tax,
      totalTax,
      subtotal: base,
      subtotalWithTax: total,
      totalWithoutTax: base,
      total,
      categoryIds: variant.categoryIds,
      promotionDetails: [],
      promotions: variant.promotions,
      productType: variant.productType,
      trackType: variant.trackType,
      trackDetails: variant.trackDetails,
      type: variant.type,
      composites: variant.composites,
      packs: variant.packs,
      extras,
      eCards: variant.eCards,
      cost: variant.cost,
      unit: variant.unit,
      manageStockLevel: variant.manageStockLevel,
      index: 0,
      customFieldsData: variant.customFieldsData,
      ...partialLineItem,
    };
  }

  private mapVariantExtraToLineItemExtra(extras: Extra[]): ExtraLineItem[] {
    return extras.map((extra: Extra, index: number): ExtraLineItem => {
      const price = extra.price;
      const priceTaxExclusive = {
        base: price,
        retail: price,
        wholesale: price,
        final: price,
        extrasTotal: 0,
      };
      const inclusivePrice = this.getTaxInclusivePrice(extra.tax, price);
      const priceTaxInclusive = {
        base: inclusivePrice,
        retail: inclusivePrice,
        wholesale: inclusivePrice,
        final: inclusivePrice,
        extrasTotal: 0,
      };

      const tax = this.mapTaxToLineItemTax(extra.tax, 0);

      const extraLineItem: ExtraLineItem = {
        name: extra.name,
        id: extra.id,
        rate: extra.rate,
        imageUrl: extra.imageUrl,
        productId: extra.productId,
        isPartOfOtherProduct: extra.isPartOfOtherProduct,
        packs: extra.packs,
        cost: extra.cost,
        unit: extra.unit,
        availableQuantity: extra.availableQuantity,
        variantId: extra.variantId || 0,
        sku: extra.sku,
        quantity: 0,
        totalDiscount: 0,
        priceTaxInclusive,
        priceTaxExclusive,
        totalTax: 0,
        subtotal: 0,
        subtotalWithTax: 0,
        total: 0,
        totalWithoutTax: 0,
        discounts: [],
        tax,
        type: extra.type,
        manageStockLevel: extra.manageStockLevel,
        index,
      };
      return extraLineItem;
    });
  }

  /**
   *
   * @param cart
   * @param findBy
   * @param quantity the batches and ecards will be set according to the quantity
   * @returns Cart
   */
  public updateLineItemQuantity(
    cart: Cart,
    findBy: LineItemFindByParam,
    quantity: number,
  ): Cart {
    const lineItemIndex = this.getLineItemIndex(cart.lineItems, findBy);
    if (quantity === 0) {
      return this.removeLineItem(cart, findBy);
    }
    const lineItem = cart.lineItems[lineItemIndex];
    let trackDetails: TrackDetail[] = lineItem.trackDetails;
    if (lineItem.trackType === ProductEnums.TrackTypeConstant.Batch) {
      const packRate =
        lineItem.type === ProductEnums.VariantType.Package
          ? lineItem.packs[0]?.rate || 1
          : 1;
      trackDetails = this.getTrackDetails(
        lineItem.trackDetails,
        lineItem.trackType,
        quantity,
        packRate,
      );
    }
    const eCards = this.updateECardQuantity(lineItem.eCards, quantity);

    return this.updateLineItem(cart, lineItemIndex, {
      quantity,
      trackDetails,
      eCards,
    });
  }
}

export const sellCartService = new SellCartService();
