export const InvoiceStatusMap = {
  Open: 'Open',
  Pending: 'Waiting Receive',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Completed: 'Completed',
  Void: 'Void',
  Active: 'Active',
  Draft: 'Draft',
  Requested: 'Waiting Send',
  PartiallyAccepted: 'Partially Accepted',
  Saved: 'Draft',
};

export const InvoiceChannelMap = {
  POS: 'new POS',
};

export const InvoiceSellType = {
  Sell: 'SELL',
  Return: 'RETURN',
};
