import { environment } from '../../environments/environment';
import { Userpilot } from 'userpilot';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class UserpilotGuardService implements CanActivate {
  constructor() {}

  canActivate(): boolean {
    // if (environment.enableUserPilot) {
    //   Userpilot.reload();
    // }
    return true;
  }
}
