import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { LocalStorageKey, SupportedLanguages } from '../../shared/constants';
import { LocalStorageService } from '../../core/services/local-storage.service';

@Injectable()
export class TranslationCacheResolver implements Resolve<boolean> {
  resolve(): Promise<boolean> {
    return new Promise((resolve) => {
      SupportedLanguages.forEach((language) => {
        this.translate.getTranslation(language)
          .pipe(
            take(1),
          )
          .subscribe((translation) => {
            this.localStorageService.setItem<any>(
              language === 'en' ? LocalStorageKey.LanguageEn : LocalStorageKey.LanguageAr,
              translation,
            );
            resolve(true);
          });
      });
    });
  }

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
  }
}
