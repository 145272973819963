import { posInvoiceCalculationService } from './pos-invoice-calculation.service';
import {
  PosInvoiceEnums,
  PosInvoiceTypes,
  Merchant,
  TaxType,
  ProductEnums,
  ProductTypes,
  StockLocationTypes,
} from '@rewaa-team/types';
import { BadRequestException } from '../../errors/errors';
import { v4 as uuidv4 } from 'uuid';
import { generateShortHash } from '../../utils/hash.utils';

export class PosInvoiceService {
  private getPaymentStatus = (paidAmount: number, debitAmount: number) => {
    let status;
    if (paidAmount > 0 && debitAmount < 0.01) {
      status = PosInvoiceEnums.PayableInvoicePaymentStatus.Paid;
    } else if (paidAmount === 0 && debitAmount >= 0.01) {
      status = PosInvoiceEnums.PayableInvoicePaymentStatus.NotPaidDebtor;
    } else if (paidAmount > 0 && debitAmount >= 0.01) {
      status = PosInvoiceEnums.PayableInvoicePaymentStatus.PartiallyPaidDebtor;
    }
    return status;
  };

  private getAvailableLocationQuantity = (
    variant: ProductTypes.Variant,
    includedInfinity = false,
  ) => {
    if (!variant) return 0;
    if (variant && !variant.manageStockLevel)
      return includedInfinity ? Number.POSITIVE_INFINITY : 0;
    const [stock] = variant.ProductVariantToStockLocations || [];
    if (variant.trackType && stock.VariantToTracks) {
      return stock.VariantToTracks.reduce(
        (sum, t) =>
          posInvoiceCalculationService.add(sum, Math.max(0, t.quantity)),
        0,
      );
    }
    return stock.quantity;
  };

  private mapExtrasToVariantToInvoiceExtras = (
    extras: PosInvoiceTypes.SellProductExtra[] = [],
    variantExtraLocations: ProductTypes.VariantExtraLocation[] = [],
    taxation: TaxType,
    parentTaxId: number,
    taxes: ProductTypes.Tax[] = [],
    quantity: number,
  ): PosInvoiceTypes.VariantToInvoiceExtra[] => {
    return extras.map((extra) => {
      const matchingVariantExtraLocation = variantExtraLocations.find(
        (extraLocation) => extraLocation.extraId === extra.id,
      )!;
      const {
        Extra: { rate, productVariantId, name, ProductVariant: extraVariant },
      } = matchingVariantExtraLocation as any;
      const taxId = parentTaxId;
      let availableLocationQuantity;
      let sku;
      if (extraVariant) {
        sku = extraVariant.sku;
        const [extraStock] = extraVariant.ProductVariantToStockLocations;
        availableLocationQuantity = extraStock.quantity;
      }
      const tax = taxes.find((t) => t.id === taxId)!;
      const taxRate = posInvoiceCalculationService.divide(tax.rate, 100);
      const { price } = extra;
      const exclusivePrice =
        posInvoiceCalculationService.calculateExclusiveExtraPrice(
          taxation,
          price,
          taxRate,
        );
      const taxAmount = posInvoiceCalculationService.multiply(
        exclusivePrice,
        taxRate,
      );
      return {
        sku,
        price: exclusivePrice,
        extraId: extra.id,
        availableLocationQuantity,
        quantity: posInvoiceCalculationService.multiply(
          quantity,
          extra.quantity,
        ),
        taxAmount,
        taxJson: JSON.stringify(tax),
        rate,
        name,
        productVariantId,
      };
    });
  };

  private mapToCompositeVariantToInvoiceParts = (
    children: ProductTypes.CompositeChildVariant[] = [],
  ) => {
    const result: PosInvoiceTypes.CompositeVariantToInvoicePart[] = [];
    children.forEach((child) => {
      child.VariantToComposites?.forEach((variantToComposite) => {
        result.push({
          sku: child.sku,
          rate: variantToComposite.rate,
          name: child.name,
          productVariantId: child.id,
          type: child.type,
          availableLocationQuantity: this.getAvailableLocationQuantity(child),
        });
      });
    });
    return result;
  };

  private mapToVariantToInvoicePacks = (
    children: ProductTypes.PackChildVariant[] = [],
  ) => {
    const result: PosInvoiceTypes.VariantToInvoicePack[] = [];
    children.forEach((child) => {
      child.VariantToPackages?.forEach((variantToPackage) => {
        result.push({
          rate: variantToPackage.rate,
          sku: child.sku,
          name: child.name,
          productVariantId: child.id,
          availableLocationQuantity: this.getAvailableLocationQuantity(child),
          parentSku: variantToPackage.parentSku,
        });
      });
    });
    return result;
  };

  private mapToVariantToInvoiceTrackingBatches = (
    variantToTracks: ProductTypes.VariantToTrack[] = [],
    invoiceProductBatches: PosInvoiceTypes.InvoiceProductBatch[] = [],
    stock: ProductTypes.ProductVariantToStockLocation,
  ): PosInvoiceTypes.VariantToInvoiceTrack[] => {
    return invoiceProductBatches.map(({ trackNo, quantity }) => {
      const matchingTrackingBatch = variantToTracks.find(
        (track) => track.trackNo === `${trackNo}`,
      );
      const {
        id: variantToTrackId,
        issueDate,
        expiryDate,
      } = matchingTrackingBatch || ({} as any);
      return {
        trackNo,
        quantity,
        variantToTrackId,
        issueDate,
        expiryDate,
        trackType: ProductEnums.TrackTypeConstant.Batch,
        productVariantToStockLocationId: stock.id,
      };
    });
  };

  private mapToVariantToInvoiceTrackingSerials = (
    variantToTracks: ProductTypes.VariantToTrack[] = [],
    invoiceProductSerials: string[] = [],
    stock: ProductTypes.ProductVariantToStockLocation,
  ): PosInvoiceTypes.VariantToInvoiceTrack[] => {
    return invoiceProductSerials.map((productSerial) => {
      const matchingTrackingSerial = variantToTracks.find(
        (tracks) => tracks.trackNo === productSerial,
      );
      const {
        id: variantToTrackId,
        issueDate,
        expiryDate,
      } = matchingTrackingSerial!;
      return {
        trackNo: productSerial,
        quantity: 1,
        variantToTrackId,
        issueDate,
        expiryDate,
        trackType: ProductEnums.TrackTypeConstant.Serial,
        productVariantToStockLocationId: stock.id,
      };
    });
  };

  private mapPackTracks = (
    product: PosInvoiceTypes.SellProduct,
    matchingVariant: ProductTypes.Variant,
  ): PosInvoiceTypes.VariantToInvoiceTrack[] | undefined => {
    let variantToInvoiceTracks:
      | PosInvoiceTypes.VariantToInvoiceTrack[]
      | undefined;
    if (product.childrens) {
      product.childrens = product.childrens.filter(
        (c) => c.batches || c.serials,
      );

      variantToInvoiceTracks = [];

      product.childrens.forEach((c) => {
        const matchingChildVariant = matchingVariant.children?.find(
          (v) => v.sku === c.sku,
        );
        if (!matchingChildVariant) {
          throw new BadRequestException(`Product variant not found: ${c.sku}`);
        }
        const [matchingChildStock] =
          matchingChildVariant.ProductVariantToStockLocations || [];

        const [pack] = matchingChildVariant.VariantToPackages || [];

        if (
          matchingChildVariant.trackType ===
          ProductEnums.VariantTrackTypes.Batch
        ) {
          let quantity = posInvoiceCalculationService.multiply(
            product.quantity,
            pack.rate,
          );

          c.batches.forEach((b: any) => {
            const trackInfo = matchingChildStock.VariantToTracks?.find(
              (d: any) => d.trackNo === b.trackNo,
            );
            if (!trackInfo) {
              throw new BadRequestException(
                `Track Info not found: ${b.trackNo}`,
              );
            }
            b.quantity =
              trackInfo.quantity > quantity ? quantity : trackInfo.quantity;
            quantity = posInvoiceCalculationService.subtract(
              quantity,
              b.quantity,
            );
          });

          variantToInvoiceTracks = variantToInvoiceTracks?.concat(
            this.mapToVariantToInvoiceTrackingBatches(
              matchingChildStock.VariantToTracks,
              c.batches,
              matchingChildStock,
            ),
          );
        } else if (
          matchingChildVariant.trackType ===
          ProductEnums.VariantTrackTypes.Serial
        )
          variantToInvoiceTracks = variantToInvoiceTracks?.concat(
            this.mapToVariantToInvoiceTrackingSerials(
              matchingChildStock.VariantToTracks,
              c.serials,
              matchingChildStock,
            ),
          );
      });
    }

    return variantToInvoiceTracks;
  };

  private mapEcardsToInvoiceEcards = (
    invoiceVariant: PosInvoiceTypes.SellProduct,
    variant: ProductTypes.Variant,
  ): PosInvoiceTypes.VariantToInvoiceEcard[] => {
    const ecards: PosInvoiceTypes.VariantToInvoiceEcard[] = [];

    invoiceVariant.ecards?.forEach((e) => {
      const matchedEcard = variant.Ecards?.find((ec) => ec.code === e);
      if (!matchedEcard) {
        throw new BadRequestException(`Ecard not found: ${e}`);
      }
      ecards.push({ code: matchedEcard.code, ecardId: matchedEcard.id });
    });

    return ecards;
  };

  private mapCostAndTaxAfterDiscount(
    generalDiscountRate: number,
    costExclusive: number,
    totalExclusive: number,
    taxAmount: number,
    quantity: number,
  ) {
    const discountedCostExclusive = posInvoiceCalculationService.multiply(
      costExclusive,
      posInvoiceCalculationService.subtract(1, generalDiscountRate),
    );
    const discount = posInvoiceCalculationService.multiply(
      totalExclusive,
      generalDiscountRate,
    );
    const discountedTotalExclusive = posInvoiceCalculationService.multiply(
      discountedCostExclusive,
      quantity,
    );
    const taxAmountAfterDiscount = posInvoiceCalculationService.multiply(
      taxAmount,
      posInvoiceCalculationService.subtract(1, generalDiscountRate),
    );
    const costInclusive = posInvoiceCalculationService.add(
      discountedCostExclusive,
      posInvoiceCalculationService.divide(taxAmountAfterDiscount, quantity),
    );
    const totalInclusive = posInvoiceCalculationService.multiply(
      costInclusive,
      quantity,
    );

    return {
      costExclusive: discountedCostExclusive,
      discount,
      totalExclusive: discountedTotalExclusive,
      taxAmount: taxAmountAfterDiscount,
      costInclusive,
      totalInclusive,
    };
  }

  mapVariantToInvoicesForSellPos = (
    invoiceProducts: PosInvoiceTypes.SellProduct[],
    variants: ProductTypes.Variant[],
    taxes: ProductTypes.Tax[],
    taxation: TaxType,
    weightUnit: ProductEnums.WeightedVariantUnit,
    invoiceDiscountAmount = 0,
    invoiceDiscountType?: PosInvoiceEnums.DiscountType,
  ): PosInvoiceTypes.VariantToInvoice[] => {
    const variantsToInvoice = invoiceProducts.map(
      (invoiceProduct, i: number) => {
        const product = JSON.parse(
          JSON.stringify(invoiceProduct),
        ) as PosInvoiceTypes.SellProduct;
        const matchingVariant = variants.find(
          (variant) => variant.sku === product.sku,
        )!;
        product.initPrice = product.price;
        const { children = [] } = matchingVariant;
        const [stock] = matchingVariant.ProductVariantToStockLocations!;
        let packCost;
        if (children) {
          const baseVariant: ProductTypes.ChildVariant = children.find(
            (childVariant) =>
              childVariant.packSku === product.sku &&
              childVariant.type === 'child',
          )!;
          if (baseVariant) {
            const { VariantToPackages } = baseVariant;
            const pack = VariantToPackages?.find(
              (p) => p.packageVariantId === baseVariant.packId,
            );
            if (pack) {
              const packRate = pack.rate;
              const baseVariantCost =
                baseVariant.ProductVariantToStockLocations?.find(
                  (baseStock) =>
                    baseStock.stockLocationId === stock.stockLocationId,
                )?.cost || 0;
              packCost = +(baseVariantCost * packRate);
            }
          }
        }

        const matchingTax = taxes.find((tax) => tax.id === stock.taxId)!;
        const taxRate = posInvoiceCalculationService.divide(
          matchingTax.rate || 0,
          100,
        );
        const discountRate =
          posInvoiceCalculationService.divide(
            product.discountPercentage || 0,
            100,
          ) || 0;
        const originalPriceEx =
          posInvoiceCalculationService.calculateProductCostExclusive(
            taxation,
            product,
            taxRate,
          );
        const originalPriceInc =
          posInvoiceCalculationService.calculateProductCostInclusive(
            taxation,
            product,
            taxRate,
          );

        let promo = invoiceProduct.promotion;
        if (
          !promo &&
          matchingVariant.promotion &&
          matchingVariant.promotion.type === 'simple'
        ) {
          promo = matchingVariant.promotion;
          invoiceProduct.promotion = promo;
          product.promotion = promo;
        }
        if (
          promo &&
          product.promotionId &&
          +promo.id === +product.promotionId
        ) {
          if (
            [
              PosInvoiceEnums.DiscountType.Fixed,
              PosInvoiceEnums.DiscountType.Amount,
            ].includes(promo.discountType as PosInvoiceEnums.DiscountType)
          ) {
            product.price = posInvoiceCalculationService.subtract(
              product.price,
              promo.amount,
            );
          } else {
            const promoRate =
              posInvoiceCalculationService.divide(promo.amount, 100) || 0;
            product.price = posInvoiceCalculationService.multiply(
              product.price,
              posInvoiceCalculationService.subtract(1, promoRate),
            );
          }
        }
        if (discountRate) {
          product.price = posInvoiceCalculationService.multiply(
            product.price,
            posInvoiceCalculationService.subtract(1, discountRate),
          );
        }
        const costExclusive =
          posInvoiceCalculationService.calculateProductCostExclusive(
            taxation,
            product,
            taxRate,
          );
        const costInclusive =
          posInvoiceCalculationService.calculateProductCostInclusive(
            taxation,
            product,
            taxRate,
          );
        const totalExclusive =
          posInvoiceCalculationService.calculateProductTotalExclusive(
            taxation,
            product,
            taxRate,
          );
        const totalInclusive =
          posInvoiceCalculationService.calculateProductTotalInclusive(
            taxation,
            product,
            taxRate,
          );
        const taxAmount = posInvoiceCalculationService.subtract(
          totalInclusive,
          totalExclusive,
        );
        const discount = posInvoiceCalculationService.multiply(
          discountRate,
          posInvoiceCalculationService.multiply(
            product.quantity,
            originalPriceEx,
          ),
        );

        const invoiceToVariant: PosInvoiceTypes.VariantToInvoice = {
          sno: i,
          sku: product.sku,
          name: product.name,
          quantity: product.quantity,
          originalPriceEx,
          originalPriceInc,
          availableLocationQuantity:
            (matchingVariant.Product?.type === ProductEnums.ProductType.ECard
              ? matchingVariant.Ecards?.length
              : stock.quantity) || 0,
          costExclusive,
          // passed only for validation
          discountRate,
          costInclusive,
          priceAfterPromoOrDiscount: product.price,
          totalExclusive,
          totalInclusive,
          packCost,
          discount,
          taxAmount,
          promotion: promo,
          promotionQuantity: invoiceProduct.promotionQuantity
            ? Math.min(
                invoiceProduct.promotionQuantity || 0,
                invoiceProduct.quantity,
              )
            : invoiceProduct.quantity,
          productVariantId: matchingVariant.id,
          productType: matchingVariant.Product?.type,
          taxId: matchingTax.id,
          taxJson: JSON.stringify(matchingTax),
          type: matchingVariant.type,
          VariantToInvoiceExtras: [],
          CompositeVariantToInvoiceParts: [],
          VariantToInvoicePacks: [],
          VariantToInvoiceTracks: [],
          VariantToInvoiceEcards: [],
          CustomFieldsData:
            matchingVariant.CustomFieldsData?.filter(
              (customData) => customData.customFieldJSON?.addToReceiptOption,
            ).map((data) => ({
              value: data.value,
              customFieldJSON: {
                name: data.customFieldJSON?.name || '',
              },
            })) || [],
        };
        const { extras } = product;
        if (extras) {
          invoiceToVariant.VariantToInvoiceExtras =
            this.mapExtrasToVariantToInvoiceExtras(
              extras,
              matchingVariant.VariantExtraLocations,
              taxation,
              stock.taxId,
              taxes,
              product.quantity,
            );
        }
        if (matchingVariant.type === ProductEnums.VariantType.Composite) {
          invoiceToVariant.CompositeVariantToInvoiceParts =
            this.mapToCompositeVariantToInvoiceParts(children);

          const childVariants = children.filter(
            (c) => c.type === ProductEnums.VariantType.Package,
          );

          const isAnyChildhaveTrack = childVariants.find((cv) => {
            const [childPack] = cv.VariantToPackages || [];
            childPack.ProductVariant?.trackType ===
              ProductEnums.VariantTrackTypes.Serial ||
              childPack.ProductVariant?.trackType ===
                ProductEnums.VariantTrackTypes.Batch;
          });

          if (isAnyChildhaveTrack) {
            throw new BadRequestException(
              `Unsupported type of product tries to sell`,
            );
          }

          if (childVariants.length > 0) {
            childVariants.forEach((cv) => {
              const [childPack] = cv.VariantToPackages || [];
              invoiceToVariant.VariantToInvoicePacks = (
                invoiceToVariant.VariantToInvoicePacks || []
              ).concat(
                this.mapToVariantToInvoicePacks([
                  {
                    ...childPack.ProductVariant!,
                    VariantToPackages: [{ ...childPack, parentSku: cv.sku }],
                  },
                ]),
              );
            });
          }
        }
        if (matchingVariant.type === ProductEnums.VariantType.Package) {
          invoiceToVariant.VariantToInvoiceTracks = this.mapPackTracks(
            product,
            matchingVariant,
          );

          invoiceToVariant.VariantToInvoicePacks =
            this.mapToVariantToInvoicePacks(children);
        }
        if (
          matchingVariant.trackType === ProductEnums.VariantTrackTypes.Serial
        ) {
          const { serials = [] } = product;
          invoiceToVariant.VariantToInvoiceTracks = (
            invoiceToVariant.VariantToInvoiceTracks || []
          ).concat(
            this.mapToVariantToInvoiceTrackingSerials(
              stock.VariantToTracks,
              serials,
              stock,
            ),
          );
        }
        if (
          matchingVariant.trackType === ProductEnums.VariantTrackTypes.Batch
        ) {
          const { batches = [] } = product;
          invoiceToVariant.VariantToInvoiceTracks = (
            invoiceToVariant.VariantToInvoiceTracks || []
          ).concat(
            this.mapToVariantToInvoiceTrackingBatches(
              stock.VariantToTracks,
              batches,
              stock,
            ),
          );
        }
        if (matchingVariant.isWeightedScale) {
          invoiceToVariant.WeightedVariantToInvoice = { unit: weightUnit };
        }
        if (matchingVariant.Product?.type === ProductEnums.ProductType.ECard) {
          invoiceToVariant.VariantToInvoiceEcards =
            this.mapEcardsToInvoiceEcards(product, matchingVariant);
        }

        return invoiceToVariant;
      },
    );

    const subtotal =
      posInvoiceCalculationService.calculateSubtotalTaxExclusive(
        variantsToInvoice,
      );
    const totalTax =
      posInvoiceCalculationService.calculateTotalTax(variantsToInvoice);
    return variantsToInvoice.map((invoiceVariant) => {
      const generalDiscountRate =
        posInvoiceCalculationService.calculateDiscountRateForProduct(
          subtotal,
          totalTax,
          taxation,
          invoiceDiscountAmount,
          invoiceDiscountType,
        );
      if (generalDiscountRate > 0) {
        if (invoiceVariant.VariantToInvoiceExtras?.length) {
          // eslint-disable-next-line no-param-reassign
          invoiceVariant.VariantToInvoiceExtras =
            invoiceVariant.VariantToInvoiceExtras?.map((extra) => {
              const {
                costExclusive,
                discount,
                totalExclusive,
                taxAmount,
                costInclusive,
                totalInclusive,
              } = this.mapCostAndTaxAfterDiscount(
                generalDiscountRate,
                extra.price,
                extra.price * extra.quantity,
                extra.taxAmount,
                extra.quantity,
              );
              return {
                ...extra,
                costExclusive,
                discount,
                totalExclusive,
                taxAfterDiscount: taxAmount,
                costInclusive,
                totalInclusive,
              };
            });
        }
        const {
          costExclusive,
          discount,
          totalExclusive,
          taxAmount,
          costInclusive,
          totalInclusive,
        } = this.mapCostAndTaxAfterDiscount(
          generalDiscountRate,
          invoiceVariant.costExclusive,
          invoiceVariant.totalExclusive,
          invoiceVariant.taxAmount,
          invoiceVariant.quantity,
        );
        return {
          ...invoiceVariant,
          taxAmount,
          discount,
          discountRate: generalDiscountRate,
          costExclusive,
          costInclusive,
          totalExclusive,
          totalInclusive,
        };
      }
      return invoiceVariant;
    });
  };

  createPosInvoice = (
    invoice: PosInvoiceTypes.SellInvoice,
    customer: PosInvoiceTypes.Customer | undefined,
    stockLocation: StockLocationTypes.StockLocation,
    register: PosInvoiceTypes.Register,
    variantToInvoices: PosInvoiceTypes.VariantToInvoice[],
    mainMerchant: Merchant,
    offlineData?: {
      nextInvoiceNumber: number;
      nextPaymentNumber: number;
      orderNumber?: number;
      settings: PosInvoiceTypes.TemplateSettings;
      isOfflineFirst?: boolean;
    },
  ): PosInvoiceTypes.Invoice => {
    const { generalDiscountType, generalDiscountAmount } = invoice;
    const subTotalTaxExclusive =
      posInvoiceCalculationService.calculateSubtotalTaxExclusive(
        variantToInvoices,
      );
    const totalTax =
      posInvoiceCalculationService.calculateTotalTax(variantToInvoices);

    const registerJson = JSON.stringify({
      name: register.name,
      StockLocation: stockLocation,
    });
    const totalTaxInclusive = posInvoiceCalculationService.roundTo2Decimals(
      posInvoiceCalculationService.add(subTotalTaxExclusive, totalTax),
    );
    const paidAmount = invoice.payments
      ? invoice.payments.reduce(
          (amount, payment) =>
            posInvoiceCalculationService.add(amount, payment.amount),
          0,
        )
      : 0;
    const hasPostPay =
      customer &&
      posInvoiceCalculationService.subtract(totalTaxInclusive, paidAmount) >=
        0.01;
    const totalAfterPayment = posInvoiceCalculationService.subtract(
      totalTaxInclusive,
      paidAmount,
    );
    let paymentMethodsString = invoice.payments
      ?.map((p) => p.PaymentMethod.name)
      .join(', ');
    const paymentMethodId =
      invoice.payments?.length === 1
        ? invoice.payments[0].PaymentMethod.id
        : undefined;
    if (hasPostPay) {
      paymentMethodsString = paymentMethodsString?.length
        ? `${paymentMethodsString}, PostPay`
        : 'PostPay';
    }

    const status = PosInvoiceEnums.InvoiceStatus.Completed;

    const mappedInvoice: PosInvoiceTypes.Invoice = {
      status,
      registerJson,
      displayInvoiceNumber: invoice.displayInvoiceNumber,
      companyName: mainMerchant.companyName,
      userId: invoice.userId,
      notes: invoice.notes,
      orderNumber: offlineData?.orderNumber || invoice.orderNumber,
      customerName: customer ? customer.name : undefined,
      customerId: customer ? customer.id : undefined,
      customerMobileNumber: customer ? customer.mobileNumber : undefined,
      type: PosInvoiceEnums.InvoiceType.POSSale,
      registerId: register.id,
      stockLocationName: stockLocation.name,
      stockLocationId: stockLocation.id,
      subTotalTaxExclusive:
        posInvoiceCalculationService.roundTo2Decimals(subTotalTaxExclusive),
      totalTax: posInvoiceCalculationService.roundTo2Decimals(totalTax),
      totalTaxInclusive:
        posInvoiceCalculationService.roundTo2Decimals(totalTaxInclusive),
      supplyDate: invoice.supplyDate,
      templateVersionNumber: invoice.templateVersionNumber,
      PayableInvoice: {
        discountAmount: generalDiscountAmount || 0,
        discountType: generalDiscountType,
        taxation: invoice.taxation,
        restForCustomer: invoice.restForCustomer,
        paymentOption: hasPostPay
          ? PosInvoiceEnums.PaymentOption.PayLater
          : PosInvoiceEnums.PaymentOption.PayNow,
        totalBeforePayment:
          posInvoiceCalculationService.roundTo2Decimals(totalTaxInclusive),
        totalAfterPayment:
          posInvoiceCalculationService.roundTo2Decimals(totalAfterPayment),
        paidAmount,
        debitAmount:
          posInvoiceCalculationService.roundTo2Decimals(totalAfterPayment),
        paymentStatus: this.getPaymentStatus(paidAmount, totalAfterPayment),
        paymentMethod: paymentMethodsString,
        paymentMethodId,
        paymentDueDate: invoice.paymentDueDate
          ? Date.parse(invoice.paymentDueDate)
          : Date.now(),
      },
      VariantToInvoices: variantToInvoices,
      completeDate: invoice.completeDate
        ? new Date(invoice.completeDate)
        : new Date(),
      salesmanUsername: invoice.salesmanDetails?.name,
      salesmanId: invoice.salesmanDetails?.id,
      sellType: invoice.sellType,
      payments: [],
      invoiceNumber: offlineData?.nextInvoiceNumber,
      settings: JSON.stringify(
        offlineData?.settings || invoice.templateSettings,
      ),
      version: invoice.version,
      promotionGroups: invoice.promotionGroups || [],
      CustomFieldsData: invoice.customFieldsData || [],
    };
    if (offlineData?.nextInvoiceNumber) {
      mappedInvoice.displayInvoiceNumber = this.generateDisplayInvoiceNumber(
        new Date(),
        offlineData.nextInvoiceNumber,
        register.id,
        stockLocation.id,
      );
    }
    if (offlineData?.nextPaymentNumber) {
      mappedInvoice.payments = this.mapPaymentInvoiceNumbers(
        invoice.payments || [],
        offlineData.nextPaymentNumber,
        stockLocation.id,
        register.id,
        totalTaxInclusive,
      );
    }
    mappedInvoice.isOfflineFirst = offlineData?.isOfflineFirst;
    return mappedInvoice;
  };

  private pushUsedVariantForSell = (
    variantArr: ProductTypes.Variant[],
    variant: ProductTypes.Variant,
    quantity: number,
  ) => {
    const exist = variantArr.find((s) => s.sku === variant.sku);

    if (!exist) variantArr.push({ ...variant, requiredQuantity: quantity });
    else
      exist.requiredQuantity = posInvoiceCalculationService.add(
        exist.requiredQuantity || 0,
        quantity,
      );
  };

  getAllUsedVariants = (
    variants: ProductTypes.Variant[],
    invoiceVariants: PosInvoiceTypes.VariantToInvoice[],
  ) => {
    const variantArr: ProductTypes.Variant[] = [];

    invoiceVariants.forEach((matchingVariant) => {
      const variant = variants.find((i) => i.sku === matchingVariant.sku)!;
      this.pushUsedVariantForSell(
        variantArr,
        variant,
        matchingVariant.quantity,
      );

      if (variant.type === ProductEnums.VariantType.Composite) {
        variant.children?.forEach((c) => {
          const [child] = c.VariantToComposites!;
          const compositeQuantity = posInvoiceCalculationService.multiply(
            child.rate || 1,
            matchingVariant.quantity,
          );

          if (c.type === ProductEnums.VariantType.Package) {
            const [childPack] = c.VariantToPackages!;
            const packQuantity = posInvoiceCalculationService.multiply(
              childPack.rate,
              compositeQuantity,
            );
            this.pushUsedVariantForSell(
              variantArr,
              childPack.ProductVariant!,
              packQuantity,
            );
          }

          this.pushUsedVariantForSell(variantArr, c, compositeQuantity);
        });
      } else if (variant.type === ProductEnums.VariantType.Package) {
        variant.children?.forEach((p) => {
          const [child] = p.VariantToPackages!;
          const packQuantity = posInvoiceCalculationService.multiply(
            child.rate,
            matchingVariant.quantity,
          );
          this.pushUsedVariantForSell(variantArr, p, packQuantity);
        });
      } else if (
        variant.VariantExtraLocations &&
        variant.VariantExtraLocations.length > 0 &&
        matchingVariant.VariantToInvoiceExtras
      ) {
        matchingVariant.VariantToInvoiceExtras.forEach((extra) => {
          const matchingExtra = variant.VariantExtraLocations?.find(
            (v) => v.extraId === extra.extraId,
          );
          if (!matchingExtra) {
            throw new BadRequestException(`Extra not found: ${extra.extraId}`);
          }
          if (matchingExtra.Extra?.hasOtherProduct) {
            const { ProductVariant } = matchingExtra.Extra;
            this.pushUsedVariantForSell(
              variantArr,
              ProductVariant!,
              posInvoiceCalculationService.multiply(
                extra.quantity,
                extra.rate || 1,
              ),
            );
          }
        });
      }
    });

    return variantArr;
  };

  generateDisplayInvoiceNumber = (
    date: Date,
    invoiceNumber: number,
    registerId: number,
    stockLocationId: number,
  ): string => {
    const dateString = `${date.getFullYear()}${this.addNumberPadding(
      date.getMonth() + 1,
    )}${this.addNumberPadding(date.getDate())}`;

    const uuid = uuidv4();
    const randomCharacters = generateShortHash(
      `${stockLocationId}-${registerId}-${uuid}`,
    ).toUpperCase();

    return `S${dateString}-${randomCharacters}-${invoiceNumber}`;
  };

  mapPaymentInvoiceNumbers = (
    payments: PosInvoiceTypes.SellInvoicePayment[],
    paymentInvoiceNumber: number,
    stockLocationId: number,
    registerId: number,
    taxInclusive: number,
  ): PosInvoiceTypes.SellInvoicePayment[] => {
    if (!payments.length) return [];
    let debitAmount = taxInclusive;
    payments.forEach((payment) => {
      payment.paymentNumber = paymentInvoiceNumber + 1;
      payment.displayPaymentNumber = `PayS${new Date()
        .toJSON()
        .split('T')[0]
        .replace('-', '')}-${stockLocationId}-${registerId}-${
        paymentInvoiceNumber + 1
      }`;
      payment.debitAmount = 0;
      debitAmount -= payment.amount;
    });
    payments[payments.length - 1].debitAmount = debitAmount;
    return payments;
  };

  generateInvoiceSnapshot = (invoice: PosInvoiceTypes.Invoice) => {
    invoice = { ...invoice };
    let subtotalExclusive = 0;
    let totalDiscountExclusive = 0;
    invoice.VariantToInvoices = invoice.VariantToInvoices.map(
      (variant: any, i: number) => {
        const invoiceVariant = { ...variant };
        totalDiscountExclusive += invoiceVariant.discount || 0;
        // tax exclusive original cost
        invoiceVariant.costExclusive = invoiceVariant.originalPriceEx;

        // tax inclusive original cost
        invoiceVariant.costInclusive =
          invoiceVariant.totalInclusive / invoiceVariant.quantity;

        // tax exclusive total => original cost * qty ( no discount and no promo)
        invoiceVariant.subtotalExclusive =
          invoiceVariant.originalPriceEx * invoiceVariant.quantity +
          posInvoiceCalculationService.getExtrasTotalPrice(
            invoiceVariant.VariantToInvoiceExtras,
          );

        // tax exclusive total i.e the total amount to pay without tax => subtotal - discounts
        invoiceVariant.totalExclusive =
          invoiceVariant.subtotalExclusive - invoiceVariant.discount;

        // tax exclusive cost WITH discount and promo
        invoiceVariant.costDiscountedTaxExclusive =
          invoiceVariant.costExclusive -
          invoiceVariant.discount / invoiceVariant.quantity;
        // tax inclusive total i.e the total amount to pay with tax => subtotal - discounts
        invoiceVariant.totalInclusive =
          invoiceVariant.totalExclusive + invoiceVariant.taxAmount;

        // variable to calculate the INVOICE subtotal
        // without tax and without discount and without promo
        subtotalExclusive += invoiceVariant.subtotalExclusive;
        invoiceVariant.sno = i;
        return invoiceVariant;
      },
    );
    invoice.totalDiscountExclusive = totalDiscountExclusive;
    invoice.subtotalExclusive = subtotalExclusive;
    invoice.version = 2;
    return invoice;
  };

  private addNumberPadding = (num: number) => `0${num}`.slice(-2);

  createCustomerInvoice = (
    mappedCustomerInvoice: PosInvoiceTypes.MappedCustomerInvoice,
  ): PosInvoiceTypes.CustomerInvoice => {
    if (!mappedCustomerInvoice.invoiceNumber) {
      throw new BadRequestException(
        `Invoice number not provided for PosInvoiceTypes.Customer PosInvoiceTypes.Invoice`,
      );
    }
    const customerInvoice: PosInvoiceTypes.CustomerInvoice = {
      invoiceNumber: mappedCustomerInvoice.invoiceNumber as string,
      saleInvoiceId: mappedCustomerInvoice.saleInvoiceId,
      isOffline: mappedCustomerInvoice.isOffline,
      invoiceId: mappedCustomerInvoice.invoiceId,
      customerId: mappedCustomerInvoice.customerId,
      version: mappedCustomerInvoice.version,
      isReturnInvoice: !!mappedCustomerInvoice.isReturnInvoice,
      transactionType: mappedCustomerInvoice.transactionType,
      sequenceNo: mappedCustomerInvoice.sequenceNo,
      invoiceXML: mappedCustomerInvoice.invoiceXML,
      invoiceHash: mappedCustomerInvoice.invoiceHash,
      zatcaStatus: mappedCustomerInvoice.zatcaStatus,
      registerId: mappedCustomerInvoice.registerId,
      shiftId: mappedCustomerInvoice.shiftId,
      referenceId: mappedCustomerInvoice.referenceId,
      status: mappedCustomerInvoice.customerInvoiceStatus,
      invoice: mappedCustomerInvoice,
    };

    customerInvoice.invoice.VariantToInvoices?.forEach((variant) => {
      variant.VariantToInvoiceEcards = variant.VariantToInvoiceEcards || [];
      variant.VariantToInvoiceExtras = variant.VariantToInvoiceExtras || [];
      variant.VariantToInvoicePacks = variant.VariantToInvoicePacks || [];
      variant.VariantToInvoiceTracks = variant.VariantToInvoiceTracks || [];
      variant.CompositeVariantToInvoiceParts =
        variant.CompositeVariantToInvoiceParts || [];
    });

    return customerInvoice;
  };

  createMappedCustomerInvoice = (
    invoice: PosInvoiceTypes.Invoice,
    customerInvoiceData: PosInvoiceTypes.CustomerInvoiceData,
  ): PosInvoiceTypes.MappedCustomerInvoice => {
    const { VariantToInvoices: variantToInvoices } = invoice;
    let totalPromotionsAmount = 0;
    let totalSubtotalExclusive = 0;
    let totalDiscountExclusive = 0;
    let totalTax = 0;
    invoice.PayableInvoice.discountAmount =
      invoice.PayableInvoice.discountAmount || 0;

    const taxMap: {
      [x: number]: PosInvoiceTypes.TaxBreakdown;
    } = {};

    const subtotalExclusiveWithPromo = variantToInvoices.reduce(
      (total, vToI) => {
        const extrasPrice = posInvoiceCalculationService.getExtrasTotalPrice(
          vToI.VariantToInvoiceExtras,
        );
        const variantSubtotalExclusiveWithPromo =
          (vToI.originalPriceEx || 0) *
            (vToI.quantity - vToI.promotionQuantity) +
          vToI.priceAfterPromoOrDiscount * vToI.promotionQuantity +
          extrasPrice;
        total += variantSubtotalExclusiveWithPromo;
        return total;
      },
      0,
    );
    const VariantToInvoices: PosInvoiceTypes.VariantToInvoice[] =
      variantToInvoices.map((invoiceVariant) => {
        invoiceVariant.originalPriceEx = invoiceVariant.originalPriceEx || 0;
        let promotionAmount = 0;
        const tax: ProductTypes.Tax = JSON.parse(invoiceVariant.taxJson!);
        const { rate = 0 } = tax;

        // map promotion
        if (invoiceVariant.promotion) {
          const { promotion } = invoiceVariant;
          invoiceVariant.promotionName = promotion.name;
          if (
            [
              PosInvoiceEnums.DiscountType.Percentage,
              PosInvoiceEnums.DiscountType.FreeProduct,
            ].includes(promotion.discountType as PosInvoiceEnums.DiscountType)
          ) {
            promotionAmount =
              invoiceVariant.originalPriceEx * (promotion.amount / 100);
          } else if (invoice.PayableInvoice.taxation === TaxType.Inclusive) {
            promotionAmount = promotion.amount / (1 + rate / 100);
          } else {
            promotionAmount = promotion.amount;
          }
          promotionAmount *= Math.min(
            invoiceVariant.quantity,
            invoiceVariant.promotionQuantity,
          );
          totalPromotionsAmount += promotionAmount || 0;
        }
        // total discount on the variant including the discount applied on extras;
        let discountWithExtras = invoiceVariant.discount || 0;

        // discount on extras
        let extrasDiscount = 0;

        // extraPrice is the total price for extras for all the qty of the variant - tax exclusive
        // so if you buy 3 variants with 2 variants each, extras price is the total exclusice price of 6 variants
        const extrasPrice = posInvoiceCalculationService.getExtrasTotalPrice(
          invoiceVariant.VariantToInvoiceExtras,
        );

        // tax inclusive original cost
        const costInclusive = invoiceVariant.originalPriceInc;

        // tax exclusive original cost
        const costExclusive = invoiceVariant.originalPriceEx;

        // tax exclusive total => original cost * qty ( no discount and no promo)
        const subtotalExclusive =
          invoiceVariant.originalPriceEx * invoiceVariant.quantity +
          extrasPrice;

        let discountRate = 0;
        if (
          invoice.PayableInvoice.discountType ===
          PosInvoiceEnums.DiscountType.Percentage
        ) {
          discountRate = invoice.PayableInvoice.discountAmount / 100;
        } else if (invoice.PayableInvoice.discountType === 'fixed') {
          const { discountAmount } = invoice.PayableInvoice;
          // note: invoice.subTotalTaxExclusive is only without tax
          // it has all the discounts and promos deducted
          let total = subtotalExclusiveWithPromo;
          if (invoice.PayableInvoice.taxation === 'Inclusive') {
            total =
              invoice.totalTaxInclusive + invoice.PayableInvoice.discountAmount;
          }
          // discountRate represents the discount as a fraction of the total
          discountRate = discountAmount / total;
        }
        if (extrasPrice > 0) {
          extrasDiscount = posInvoiceCalculationService.roundTo2Decimals(
            extrasPrice * discountRate,
          );
          discountWithExtras += extrasPrice * discountRate;
        }
        if (discountRate === 0) {
          // Product level discount
          discountRate = invoiceVariant.discountRate || 0;
        }

        totalDiscountExclusive += discountWithExtras;

        // tax exclusive total i.e. the total amount to pay without tax => subtotal - discounts
        const totalExclusive =
          subtotalExclusive - promotionAmount - discountWithExtras;

        // tax exclusive cost WITH discount and promo
        // note here we will deduct the invoiceVariant discount
        const costDiscountedTaxExclusive = Math.max(
          costExclusive -
            (promotionAmount / invoiceVariant.promotionQuantity +
              invoiceVariant.discount / invoiceVariant.quantity),
          0,
        );

        const unitPromotionPriceDiscountedTaxInclusive =
          (costExclusive - costDiscountedTaxExclusive) * (1 + rate / 100);
        const unitPromotionPriceDiscountedTaxExclusive =
          costExclusive - costDiscountedTaxExclusive;
        const unitPriceDiscountedTaxExclusive =
          costExclusive * (1 - discountRate);
        const unitPriceDiscountedTaxInclusive =
          (costInclusive || 0) * (1 - discountRate);

        // tax inclusive total i.e. the total amount to pay with tax => subtotal - discounts
        const totalInclusive = posInvoiceCalculationService.multiply(
          totalExclusive,
          1 + rate / 100,
        );

        const taxAmount = posInvoiceCalculationService.subtract(
          totalInclusive,
          totalExclusive,
        );

        totalTax = posInvoiceCalculationService.add(totalTax, taxAmount);

        // Add to tax map so categories of tax are not made on the frontend
        if (!taxMap[tax.id]) {
          taxMap[tax.id] = {
            ...tax,
            totalAmount: taxAmount,
            taxableAmount: totalExclusive,
          };
        } else {
          taxMap[tax.id].totalAmount = posInvoiceCalculationService.add(
            taxMap[tax.id].totalAmount || 0,
            taxAmount,
          );
          taxMap[tax.id].taxableAmount = posInvoiceCalculationService.add(
            taxMap[tax.id].taxableAmount || 0,
            totalExclusive,
          );
        }

        // variable to calculate the INVOICE subtotal
        // without tax and without discount and without promo
        totalSubtotalExclusive = posInvoiceCalculationService.add(
          totalSubtotalExclusive,
          subtotalExclusive,
        );

        // the total exclusive price of extras after discount in 1 qty of the variant
        const discountedExtrasTotalPerVariantExc =
          posInvoiceCalculationService.divide(
            posInvoiceCalculationService.subtract(extrasPrice, extrasDiscount),
            invoiceVariant.quantity,
          );

        // the total inclusive price of extras after discount in 1 qty of the variant
        const discountedExtrasTotalPerVariantInc =
          posInvoiceCalculationService.multiply(
            discountedExtrasTotalPerVariantExc,
            1 + rate / 100,
          );

        // calculating total price with extras, with ProductTypes.Tax exclusive, and discount applied
        // Note: Here we will deduct the extras discount
        let costWithExtrasExclusiveDiscounted: number =
          posInvoiceCalculationService.add(
            discountedExtrasTotalPerVariantExc,
            costDiscountedTaxExclusive,
          );

        return {
          ...invoiceVariant,
          promotionAmount,
          costExclusive,
          costInclusive,
          extrasDiscount,
          costWithExtrasExclusiveDiscounted,
          originalPriceEx: undefined, // use costExclusive instead
          originalPriceInc: undefined, // use costInclusive instead
          costDiscountedTaxExclusive,
          subtotalExclusive,
          totalExclusive:
            +posInvoiceCalculationService.roundTo2Decimals(totalExclusive),
          totalInclusive:
            +posInvoiceCalculationService.roundTo2Decimals(totalInclusive),
          taxAmount: +posInvoiceCalculationService.roundTo2Decimals(taxAmount),
          unitPriceDiscountedTaxExclusive,
          unitPriceDiscountedTaxInclusive,
          unitPromotionPriceDiscountedTaxInclusive,
          unitPromotionPriceDiscountedTaxExclusive,
          discountedExtrasTotalPerVariantExc,
          discountedExtrasTotalPerVariantInc,
        };
      });

    // Convert it into an array containing values rounded to 2 decimal places
    const taxCategories: PosInvoiceTypes.TaxBreakdown[] = [];
    for (const key in taxMap) {
      taxMap[key].totalAmount = posInvoiceCalculationService.roundTo2Decimals(
        taxMap[key].totalAmount || 0,
      );
      taxCategories.push(taxMap[key]);
    }
    return {
      ...invoice,
      totalTaxInclusive: +posInvoiceCalculationService.roundTo2Decimals(
        totalSubtotalExclusive +
          totalTax -
          totalDiscountExclusive -
          totalPromotionsAmount,
      ),
      VariantToInvoices,
      totalDiscountExclusive: +posInvoiceCalculationService.roundTo2Decimals(
        totalDiscountExclusive,
      ),
      subtotalExclusive: totalSubtotalExclusive,
      totalPromotionsAmount: +posInvoiceCalculationService.roundTo2Decimals(
        totalPromotionsAmount,
      ),
      taxCategories,
      zatcaStatus: PosInvoiceEnums.ZatcaVerificationStatus.NotSubmitted,
      totalTax: posInvoiceCalculationService.roundTo2Decimals(+totalTax),
      shiftId: customerInvoiceData.shiftId,
      transactionType: customerInvoiceData.transactionType,
      version: customerInvoiceData.version || 2,
      customerInvoiceStatus: customerInvoiceData.isPayingUsingSoftPos
        ? PosInvoiceEnums.CustomerInvoiceStatus.Pending
        : PosInvoiceEnums.CustomerInvoiceStatus.Completed,
      type: PosInvoiceEnums.InvoiceType.POSSale,
      invoiceNumber: customerInvoiceData.invoiceNumber,
      invoiceId: customerInvoiceData.invoiceId,
      referenceId: customerInvoiceData.referenceId,
      Customer: customerInvoiceData.customer
        ? {
            ...customerInvoiceData.customer,
            CustomFieldsData:
              customerInvoiceData.customer.CustomFieldsData?.filter(
                (customData) => customData.customFieldJSON?.addToReceiptOption,
              ).map((data) => ({
                value: data.value,
                customFieldJSON: {
                  name: data.customFieldJSON?.name || '',
                },
              })) || [],
          }
        : undefined,
      userName: customerInvoiceData.userName,
      companyVatNumber: customerInvoiceData.companyVatNumber,
      customerVatNumber: customerInvoiceData.customerVatNumber,
      isOffline: !!customerInvoiceData.isOffline,
      promotionGroups: invoice.promotionGroups,
    };
  };

  addCostsToVariantToInvoices = (
    variantToInvoices: any[] = [],
    variants: any[] = [],
    taxConfiguration: any,
  ) =>
    variantToInvoices.map((variantToInvoice) => {
      const { productVariantId } = variantToInvoice;
      const variant = variants.find((v) => v.id === productVariantId);
      if (!variant) {
        // Variant deleted case
        return variantToInvoice;
      }
      let cost;
      if (variantToInvoice.packCost) {
        cost = variantToInvoice.packCost;
      } else {
        cost = this.calculateVariantPrice(variant, 'cost');
      }

      if (variantToInvoice.VariantToInvoiceExtras) {
        variantToInvoice.VariantToInvoiceExtras.forEach((ex: any) => {
          const exLocation = ex.productVariantId
            ? variant.VariantExtraLocations.find(
                (v: any) => v.Extra.productVariantId === ex.productVariantId,
              )
            : null;
          ex.cost =
            exLocation && exLocation.Extra.hasOtherProduct
              ? this.calculateExclusivePrice(
                  exLocation.Extra.ProductVariant,
                  taxConfiguration,
                  'cost',
                )
              : 0;
        });
      }

      return { ...variantToInvoice, oldCost: cost, newCost: cost };
    });

  calculateVariantPrice = (variant: any, prop = 'retailPrice') => {
    if (variant.type === ProductEnums.VariantType.Composite)
      return this.calculateCompositePrice(variant, prop);

    const [stock] = variant.ProductVariantToStockLocations;
    return stock[prop];
  };

  calculateCompositePrice = (variant: any, prop = 'retailPrice') =>
    variant.children.reduce((price: any, c: any) => {
      const [child] = c.VariantToComposites;
      return posInvoiceCalculationService.add(
        price,
        posInvoiceCalculationService.multiply(
          child.rate || 1,
          this.calculateVariantPrice(c, prop),
        ),
      );
    }, 0);

  calculateExclusivePrice = (
    variant: any,
    priceConfiguration: any,
    prop = 'retailPrice',
  ) => {
    const price = this.calculateVariantPrice(variant, prop);

    return this.calculateExclusive(price, variant, priceConfiguration, prop);
  };

  calculateExclusive = (
    price: number,
    variant: any,
    priceConfiguration: any,
    prop = 'retailPrice',
  ) => {
    const [stock] = variant.ProductVariantToStockLocations;
    return this.getTaxConfig(priceConfiguration, prop) === TaxType.Inclusive
      ? posInvoiceCalculationService.divide(
          price,
          posInvoiceCalculationService.add(
            1,
            posInvoiceCalculationService.divide(stock.Tax.rate || 0, 100),
          ),
        )
      : price;
  };

  getTaxConfig = (priceConfiguration: any, prop = 'retailPrice') =>
    prop === 'cost'
      ? priceConfiguration.costTaxation || priceConfiguration.costTaxStatus
      : priceConfiguration.sellTaxation || priceConfiguration.sellTaxStatus;
}

export const posInvoiceService = new PosInvoiceService();
