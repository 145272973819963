import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  debounceTime,
  fromEvent,
} from 'rxjs';
import * as FullStory from '@fullstory/browser';
import { getKeyCombination, getKeyCombinationString } from '../../internal-apps/pos/utils/common.utils';
import { POSShortcutAction, POSShortcutContext, ShortcutKey } from '../../internal-apps/pos/utils/constants';
import { LocalStorageKey } from '../constants';
import { LocalStorageService } from '../../core/services/local-storage.service';

/**
 * @deprecated use keyboard-shortcut.service
 */
@Injectable()
export class KeyboardShortcutServiceOld {
  constructor(private localStorageService: LocalStorageService) {}

  public initialize() {
    if (!this.keyboardEvent$ || this.keyboardEvent$?.closed)
      this.keyboardEvent$ = fromEvent(document, 'keydown')
        .pipe(debounceTime(250))
        .subscribe((event: KeyboardEvent) => this.handleInput(event));
    const flag = this.initShortcutGuideFlag();
    this.tooltipGuideEnable$.next(flag);
  }

  private keyboardEvent$: Subscription;

  private availableContexts: { key: POSShortcutContext, subject: Subject<POSShortcutAction> }[] = [];

  private contextShortcuts = {
    [POSShortcutContext.SaleViewClassic]: {
      [ShortcutKey.AltP]: POSShortcutAction.ShowPaymentDialog,
      [ShortcutKey.AltS]: POSShortcutAction.FocusSearch,
      [ShortcutKey.F4]: POSShortcutAction.TogglePriceMode,
      [ShortcutKey.F8]: POSShortcutAction.ShowSalesmanDialog,
      [ShortcutKey.F9]: POSShortcutAction.ShowParkSaleDialog,
      [ShortcutKey.F2]: POSShortcutAction.ToggleShortcutsDialog,
      [ShortcutKey.Delete]: POSShortcutAction.ClearCart,
    },
    [POSShortcutContext.ParkSaleForm]: {
      [ShortcutKey.Enter]: POSShortcutAction.ParkSale,
    },
    [POSShortcutContext.SalePayment]: {
      [ShortcutKey.Enter]: POSShortcutAction.Pay,
    },
    [POSShortcutContext.AssignSalesman]: {
      [ShortcutKey.AltDown]: POSShortcutAction.AssignNextSalesman,
      [ShortcutKey.AltUp]: POSShortcutAction.AssignPreviousSalesman,
    },
    [POSShortcutContext.AddCustomerDialog]: {
      [ShortcutKey.Enter]: POSShortcutAction.AddCustomer
    }
  };

  private tooltipGuideEnable$ = new ReplaySubject<boolean>(1);

  get tooltipEnable(): Observable<boolean> {
    return this.tooltipGuideEnable$.asObservable();
  }

  private handleInput(event: KeyboardEvent) {
    const keyCombination = getKeyCombination(event.keyCode, event.altKey, event.ctrlKey);
    const activeContext = this.availableContexts[this.availableContexts.length - 1];
    const currentContextType = this.contextShortcuts[activeContext.key];

    if (currentContextType[keyCombination]) {
      activeContext.subject.next(currentContextType[keyCombination]);

      // Sending events to fullstory on every valid shortcut used
      FullStory.event('Keyboard Shortcuts', {
        shortcut: getKeyCombinationString(event.key, event.altKey, event.ctrlKey),
        action: currentContextType[keyCombination],
        context: activeContext.key,
      });
    }
  }

  private initShortcutGuideFlag(): boolean {
    let value = this.localStorageService.getItem<number>(
      LocalStorageKey.keyboardShortcutTooltipEnabled,
    );
    if (!value) {
      value = 1;
      this.localStorageService.setItem<number>(
        LocalStorageKey.keyboardShortcutTooltipEnabled,
        1,
      );
    }

    return value === 1;
  }

  public registerContext(contextKey: POSShortcutContext): Observable<POSShortcutAction> {
    const contextIndex = this.availableContexts.findIndex((context) => context.key === contextKey);
    if (contextIndex > -1) {
      return this.availableContexts[contextIndex].subject.asObservable();
    }
    const payload = {
      key: contextKey,
      subject: new Subject<POSShortcutAction>(),
    };
    this.availableContexts.push(payload);
    return payload.subject.asObservable();
  }

  public unregisterContext(contextKey: POSShortcutContext) {
    const contextIndex = this.availableContexts.some((context) => context.key === contextKey);
    if (contextIndex) {
      this.availableContexts.pop();
    }
  }

  public toggleTooltipGuideFlag(value: boolean): void {
    this.localStorageService.setItem<number>(
      LocalStorageKey.keyboardShortcutTooltipEnabled,
      value ? 1 : 0,
    );
    this.tooltipGuideEnable$.next(value);
  }

  destroy() {
    this.keyboardEvent$.unsubscribe();
    this.availableContexts.forEach((context) => context.subject.complete());
  }
}
