<div class="bg-white flex flex-column fw-400 rw__p-6">
  <div class="border-bottom-1 flex align-items-start justify-content-between rw__pb-5 rw__mb-5">
    <div class="flex flex-column">
      <label class="rw__fs-6 text-grey-80 rw__mb-0" *ngIf="voucherData.type === 'PAYMENT'">{{'Payment Voucher' | translate}}</label>
      <label class="rw__fs-6 text-grey-80 rw__mb-0" *ngIf="voucherData.type === 'RECEIPT'">{{'Receipt Voucher' | translate}}</label>
      <span class="rw__fs-10 text-grey-60">{{mainMerchant?.companyName || voucherData?.companyName }}</span>
    </div>
    <span class="rw__fs-10 text-grey-60">#{{voucherData.id}}</span>
  </div>
  <div class="border-bottom-1 flex flex-wrap rw__mb-5">
    <div class="flex flex-column rw__mb-5 w-50">
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0" *ngIf="voucherData.type === 'PAYMENT'">{{'advancedAccounting.to' | translate}}</label>
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0" *ngIf="voucherData.type === 'RECEIPT'">{{'advancedAccounting.from' | translate}}</label>
      <span class="rw__fs-10 text-grey-60">{{voucherData.name}}</span>
    </div>
    <div class="flex flex-column rw__mb-5 w-50">
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0">{{'advancedAccounting.paidAmount' | translate}}</label>
      <span class="rw__fs-10 text-grey-60">{{formatNumber(voucherData.paidAmount)}}</span>
    </div>
    <div class="flex flex-column rw__mb-5 w-50">
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0">{{'advancedAccounting.issueDate' | translate}}</label>
      <span class="rw__fs-10 text-grey-60">{{voucherData.createdAt | date : 'dd/MM/YYYY HH:mm:ss' }}</span>
    </div>
    <div class="flex flex-column rw__mb-5 w-50">
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0">{{'advancedAccounting.paymentMethod' | translate}}</label>
      <span class="rw__fs-10 text-grey-60">{{voucherData.paymentMethod | translate}}</span>
    </div>
    <div class="flex flex-column rw__mb-5 w-50">
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0" *ngIf="voucherData.type === 'PAYMENT'">{{ 'advancedAccounting.paymentInvoiceNumber' | translate }}</label>
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0" *ngIf="voucherData.type === 'RECEIPT'">{{ 'advancedAccounting.invoiceNumber' | translate }}</label>
      <span class="rw__fs-10 text-grey-60">{{voucherData.invoiceNumber}}</span>
    </div>
  </div>
  <div class="border-bottom-1 flex rw__mb-5">
    <div class="flex flex-column rw__mb-5 w-full">
      <label class="fw-500 rw__fs-10 text-grey-80 rw__mb-0">{{'Notes' | translate }}</label>
      <span class="rw__fs-10 text-grey-60">{{voucherData.notes}}</span>
    </div>
  </div>
  <div class="flex">
    <div class="flex-1 rw__m-0"></div>
    <div class="flex-1 rw__m-0 text-center">
      <span class="rw__fs-10 text-grey-80">{{ 'advancedAccounting.signature' | translate }}</span>
    </div>
  </div>
</div>
