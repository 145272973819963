/* eslint-disable check-file/folder-naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import {
  promotionService,
  PromotionTypes,
  OfflinePosTypes,
} from '@rewaa-team/pos-sdk';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';
import { OfflineProductVariant } from './stores/OfflineProductVariantStore';
import { ConfigStoreService } from './config-store.service';
import {
  ConfigStoreKeys,
  OFFLINE_MODE_FULL_TEXT_SEARCH_LIMIT,
  VariantTypes,
} from '../../../constants';
import { AppState } from '../../../../reducers';
import { selectAllTaxes } from '../../../../users-settings/tax/selectors/tax.selector';
import { Tax } from '../../../model/Tax';
import { PromotionStoreService } from './promotion-store.service';
import { DatabaseService } from '../../../../database/database.service';
import { TableName } from '../../../../database/types';
import { PromotionStoreV2Service } from './promotion-store-v2.service';
import { CategoryService } from '../../../../inventory/products/services/category.service';
import { addParentsToCategory } from '../../../../internal-apps/pos/utils/sell.utils';
import { Category } from '../../../../inventory/model/category';
import { OnlineOfflineService } from '../online-offline.service';
import { TaxCacheService } from '../../tax-cache.service';
import { InvoicesCalculator } from '../../../../invoices/utilities/invoices.calculator';
import { isDefined } from '../../../../utils';

@Injectable()
export class VariantStoreService implements OnDestroy {
  private taxes: Tax[] = [];

  destroySubs$ = new Subject();

  private categories: Category[];

  isOffline = false;

  constructor(
    private configStoreService: ConfigStoreService,
    private promotionStoreV2Service: PromotionStoreV2Service,
    private categoryService: CategoryService,
    private onlineOfflineService: OnlineOfflineService,
    private taxCacheService: TaxCacheService,
  ) {
    this.categoryService.getCategories().subscribe((categories) => {
      categories.forEach((category) => {
        addParentsToCategory(categories, category, []);
      });
      this.categories = categories;
    });
    this.onlineOfflineService.networkChange
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((isOnline) => {
        this.isOffline = !isOnline;
      });

    this.taxCacheService.taxes
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((taxes) => {
        if (!taxes.length) {
          return;
        }
        this.taxes = taxes;
      });
  }

  ngOnDestroy(): void {
    this.destroySubs$.next(null);
    this.destroySubs$.complete();
  }

  private async getDB(): Promise<any> {
    return DatabaseService.getDB();
  }

  public async save(variants: OfflineProductVariant[]): Promise<number> {
    const databaseService: DatabaseService = await this.getDB();
    const lastKey: number = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkPut(variants);
    return lastKey;
  }

  public async delete(ids: number[]) {
    const databaseService: DatabaseService = await this.getDB();
    await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkDelete(ids);
  }

  public async deleteAll() {
    const databaseService: DatabaseService = await this.getDB();
    await Promise.all([
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.ProductApiConfig,
      ),
      this.configStoreService.deleteConfigration(
        ConfigStoreKeys.InventoryApiConfig,
      ),
      databaseService.getRepository(TableName.OfflineProductVariants).clear(),
    ]);
  }

  public async search(term: string): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variantsCount: number = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .count();
    let variants: OfflinePosTypes.ProductVariant[] = [];
    //  We are applying limit of 1000 here because the substring search is too slow when data size is larger
    if (variantsCount <= OFFLINE_MODE_FULL_TEXT_SEARCH_LIMIT) {
      variants = await databaseService
        .getRepository(TableName.OfflineProductVariants)
        .toArray();
      variants = variants
        .filter(
          (variant) =>
            variant.sku?.toLowerCase().includes(term.toLowerCase()) ||
            variant.name?.toLowerCase().includes(term.toLowerCase()) ||
            variant.barCode?.toLowerCase().includes(term.toLowerCase()) ||
            variant.product?.name?.toLowerCase().includes(term.toLowerCase()),
        )
        .slice(0, 20);
    } else {
      const query = databaseService
        .getRepository(TableName.OfflineProductVariants)
        .where('sku')
        .startsWithIgnoreCase(term)
        .or('barCode')
        .startsWithIgnoreCase(term);

      if (this.isOffline) {
        query
          .or('name')
          .startsWithIgnoreCase(term)
          .or('product.name')
          .startsWithIgnoreCase(term);
      }

      variants = await query.limit(40).toArray();
    }
    if (variants && variants.length) {
      const fullVariants = await this.loadFullVariants(variants);
      return fullVariants;
    }
    return [];
  }

  public async findById(id): Promise<OfflinePosTypes.ProductVariant> {
    const databaseService: DatabaseService = await this.getDB();
    const variant: OfflinePosTypes.ProductVariant = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .get(id);
    const [fullVariant] = (await this.loadFullVariants([variant])) || [];
    return fullVariant;
  }

  public async findByProductId(
    productId: number,
  ): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('productId')
      .equals(productId)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants;
  }

  public async findByProductIds(
    productIds: number[],
  ): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    let variants: OfflinePosTypes.ProductVariant[] = [];
    variants = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('productId')
      .anyOf(productIds)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants;
  }

  public async findBySkuOrBarcode(
    skuOrBarcode,
  ): Promise<OfflinePosTypes.ProductVariant> {
    const databaseService: DatabaseService = await this.getDB();
    const results = await Promise.all([
      databaseService
        .getRepository(TableName.OfflineProductVariants)
        .where('sku')
        .equals(skuOrBarcode)
        .first(),
      databaseService
        .getRepository(TableName.OfflineProductVariants)
        .where('barCode')
        .equals(skuOrBarcode)
        .first(),
    ]);
    const variant =
      results.length > 1
        ? results[1] || results[0]
        : results.length > 0
        ? results[0]
        : undefined;
    const [fullVariant] = (await this.loadFullVariants([variant])) || [];
    return fullVariant;
  }

  async findByIds(ids: number[]): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkGet(ids);
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants;
  }

  async findByIdsUnmapped(
    ids: number[],
  ): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkGet(ids);
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants;
  }

  async findByIdsAfterDate(
    ids: number[],
    updatedAfterDate: Date,
  ): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .bulkGet(ids);
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants.filter((variant) => {
      const updatedAt = new Date(variant.updatedAt);
      return updatedAt > updatedAfterDate;
    });
  }

  async findBySkus(skus: string[]): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('sku')
      .anyOf(skus)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants;
  }

  async findByProductNames(
    names: string[],
  ): Promise<OfflinePosTypes.ProductVariant[]> {
    const databaseService: DatabaseService = await this.getDB();
    const variants: OfflinePosTypes.ProductVariant[] = await databaseService
      .getRepository(TableName.OfflineProductVariants)
      .where('Product.name')
      .anyOf(names)
      .toArray();
    const fullVariants = await this.loadFullVariants(variants);
    return fullVariants;
  }

  async updateInventory(
    inventory: OfflinePosTypes.InventoryApiResult,
    locationId: number,
  ): Promise<void> {
    const inventoryVariants = inventory.variants;
    const databaseService: DatabaseService = await this.getDB();
    const variantIds: number[] = Object.keys(inventoryVariants).map(
      (id) => +id,
    );
    const variants: OfflinePosTypes.ProductVariant[] = (
      await databaseService
        .getRepository(TableName.OfflineProductVariants)
        .bulkGet(variantIds)
    ).filter((variant) => !!variant);
    variants.forEach((variant) => {
      const inventoryVariant = inventoryVariants[variant.id];
      const stockLocation = variant.productVariantToStockLocations.find(
        (stockLocation) => stockLocation.stockLocationId === locationId,
      );
      if (stockLocation) {
        stockLocation.quantity = inventoryVariant.quantity;
      }
      if (inventoryVariant.tracks?.length) {
        stockLocation.variantToTracks = inventoryVariant.tracks.map((track) => {
          return {
            id: track.id,
            expiryDate: track.expiryDate,
            quantity: track.quantity,
            trackNo: track.trackNo,
            productVariantToStockLocationId: stockLocation.id,
            issueDate: null,
            supplierId: null,
          };
        });
      }
      if (inventoryVariant.ecards) {
        variant.ecards = inventoryVariant.ecards.map((ecard) => {
          return {
            id: ecard.id,
            code: ecard.code,
            isSold: false,
            productVariantId: variant.id,
          };
        });
      } else {
        variant.ecards = [];
      }
    });
    await this.save(variants);
  }

  private handleCompositePackageWithZeroCost(
    variantToPackage: OfflinePosTypes.VariantToPackage[],
    locationId,
  ) {
    if (!Array.isArray(variantToPackage) || variantToPackage.length === 0) {
      return 0;
    }
    const basePackageVariant = variantToPackage[0];
    const baseVariantLocation =
      basePackageVariant?.productVariant.productVariantToStockLocations.find(
        (location) => location.stockLocationId === locationId,
      );
    const packageRate = basePackageVariant?.rate;
    return baseVariantLocation?.cost * packageRate;
  }

  private getCompositeCost(
    composites: OfflinePosTypes.VariantToComposite[],
    locationId: number,
  ) {
    const compositeCost = composites.reduce(
      (sum: number, childVariant): number => {
        let variantLocation =
          childVariant.productVariant?.productVariantToStockLocations.find(
            (stockLocation) => stockLocation.stockLocationId === +locationId,
          );

        if (!variantLocation) {
          [variantLocation] =
            childVariant.productVariant?.productVariantToStockLocations;
        }

        let { cost } = variantLocation;
        if (childVariant.productVariant?.type === VariantTypes.Package) {
          cost = variantLocation.cost || variantLocation.initialCost;
          const variantToPackage =
            childVariant?.productVariant?.variantToPackages;

          cost = this.handleCompositePackageWithZeroCost(
            variantToPackage,
            locationId,
          );
        }
        const costForRate: number =
          variantLocation && childVariant.rate
            ? InvoicesCalculator.multiply(cost, childVariant.rate)
            : 0;
        if (variantLocation) {
          return isDefined(sum)
            ? InvoicesCalculator.add(sum, costForRate)
            : costForRate;
        }
        return 0;
      },
      0,
    );
    return compositeCost || 0;
  }

  private async loadFullVariants(
    variants: OfflinePosTypes.ProductVariant[],
  ): Promise<OfflinePosTypes.ProductVariant[]> {
    if (!variants.length) {
      return variants;
    }
    variants = variants.filter((variant) => variant);
    const childVariantIds = this.getChildVariantIds(variants);
    const variantIds: number[] = variants.map((variant) => variant.id);
    const categoryIds: number[] = Array.from(
      new Set(
        variants.flatMap((variant: OfflinePosTypes.ProductVariant) => [
          variant.product.categoryId,
          ...(this.categories
            .find((c) => c.id === variant.product.categoryId)
            ?.parents?.map((c) => c.id) || []),
        ]),
      ),
    ).filter((id) => id);

    const variantPromos: any = await this.promotionStoreV2Service.findActive(
      variantIds,
      categoryIds,
    );
    let childVariants: OfflinePosTypes.ProductVariant[] = [];

    if (childVariantIds.length) {
      childVariants = await this.findByIdsUnmapped(childVariantIds);
    }

    for (const variant of variants) {
      // Map variant to extras
      for (const extraLocation of variant.variantExtraLocations) {
        const variantId = extraLocation.extra?.productVariantId;
        if (variantId) {
          if (variantId !== variant.id) {
            extraLocation.extra.productVariant = childVariants.find(
              (childVariant) => childVariant.id === variantId,
            );
          } else {
            extraLocation.extra.productVariant = JSON.parse(
              JSON.stringify({
                ...variant,
                variantExtraLocations: [],
              }),
            );
          }
        }
      }

      // Map variant to packages
      for (const packageVariant of variant.variantToPackages) {
        const variantId = packageVariant?.productVariantId;
        if (variantId !== variant.id) {
          packageVariant.productVariant = childVariants.find(
            (childVariant) => childVariant.id === variantId,
          );
        } else {
          packageVariant.productVariant = JSON.parse(
            JSON.stringify({
              ...variant,
              variantToPackages: [],
            }),
          );
        }
      }

      // Map variant to composites
      for (const compositeVariant of variant.product.variantToComposites) {
        const variantId = compositeVariant?.productVariantId;
        if (variantId !== variant.id) {
          compositeVariant.productVariant = childVariants.find(
            (childVariant) => childVariant.id === variantId,
          );
        } else {
          compositeVariant.productVariant = JSON.parse(JSON.stringify(variant));
        }
      }

      // Map tax and taxLines to stockLocations
      variant.productVariantToStockLocations.forEach((stockLocation) => {
        const locationTax = this.taxes.find(
          (tax) => tax.id === stockLocation.taxId,
        );
        if (!locationTax) {
          return;
        }
        stockLocation.tax = {
          code: locationTax.code,
          id: locationTax.id,
          coumpoundTaxLineId: locationTax.coumpoundTaxLineId,
          name: locationTax.name,
          taxLines: (locationTax.TaxLines as OfflinePosTypes.TaxLine[]) || [],
          compoundTax: locationTax.CoumpoundTax?.id
            ? (locationTax.CoumpoundTax as OfflinePosTypes.TaxLine)
            : null,
          rate: locationTax.rate,
        };

        if (variant.type === VariantTypes.Composite) {
          stockLocation.cost = this.getCompositeCost(
            variant.product?.variantToComposites || [],
            stockLocation.id,
          );
        }
      });

      const variantCategoryIds = [
        variant.product.categoryId,
        ...(this.categories
          .find((c) => c.id === variant.product.categoryId)
          ?.parents.map((c) => c.id) || []),
      ].filter((id) => id);
      promotionService.mapPromotionToVariant(
        variantCategoryIds,
        variant,
        variantPromos as PromotionTypes.ActiveAdvancePromotion,
      );
    }
    return variants;
  }

  private getChildVariantIds(
    variants: OfflinePosTypes.ProductVariant[],
  ): number[] {
    const ids: number[] = [];
    variants.forEach((variant) => {
      let childId: number;
      variant.variantExtraLocations.forEach((extraLocation) => {
        childId = extraLocation.extra?.productVariantId;
        if (childId && childId !== variant.id) {
          ids.push(extraLocation.extra.productVariantId);
        }
      });

      variant.variantToPackages.forEach((variantToPackage) => {
        childId = variantToPackage.productVariantId;
        if (childId && childId !== variant.id) {
          ids.push(variantToPackage.productVariantId);
        }
      });

      variant.product.variantToComposites.forEach((variantToComposite) => {
        childId = variantToComposite?.productVariantId;
        if (childId && childId !== variant.id) {
          ids.push(variantToComposite.productVariantId);
        }
      });
    });
    return Array.from(new Set(ids));
  }
}
