<div class="header" *ngIf="users">
    <div class="rw-logo">
        <a [routerLink]="['/']">
            <img src="/assets/icons/logo.svg" alt="" />
        </a>
    </div>
    <ul class="header-col list-style-list header-action-list">
        <li>
            <p-menu #userMenu [popup]="true" [model]="userMenuItems" appendTo="body"></p-menu>

            <p-button (click)="userMenu.toggle($event)" pRipple iconPos="right"
                [ngClass]="lang ==='en' ? 'ltr':'rtl'"
                styleClass="p-button-secondary p-button-text user-info-button">
                <ng-template pTemplate="content">
                    <div class="user-info-title">
                        <span class="user-info-name">{{ (users?.name) ? users.name : users.email }}</span>
                        <span class="user-info-role">{{users?.SystemRole|translate}}</span>
                    </div>
                    <i class="user-info-icon fa-regular fa-angle-down"></i>
                </ng-template>
            </p-button>
        </li>
    </ul>
</div>

<!-- content -->
<div [ngClass]="langCss" class="unauthorised-access-container">
    <div class="text-center">
        <img [src]="'../../../assets/icons/future-subscription-logo.svg'" alt="..."/>
    </div>
    <div class="col text-center mt-4 p-0 d-flex flex-column align-items-center justify-content-center">
        <h4>{{ 'Your subscription will be activated on' | translate}} {{ futureSubscriptionDate }}.</h4>
        <h6 class="mt-3 w-50 text-center">{{'You can access the Rewaa Platform at the date mentioned above. If you want to activate your subscription early please chat with Support' | translate }}.</h6>
    </div>
    <div class="text-center mt-3">
        <button pButton (click)="openIntercom()" pRipple type="button" class="rw__button p-button-outlined p-button-primary rw__ms-5" label="{{ 'Chat with Support' | translate }}"></button>
    </div>
</div>
