import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DecimalPipe } from '@angular/common';
import { AppState } from '../../../reducers';
import { Merchant } from '../../model/Merchant';
import { SettingsService } from '../../../users-settings/services/settings.service';

@Component({
  selector: 'rw-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit, OnDestroy {
  @Input() voucherData: any = {};

  mainMerchant: Merchant;

  subscriptionRefs;

  constructor(
    private store: Store<AppState>,
    private decimalPipe: DecimalPipe,
    private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.subscriptionRefs = this.settingsService
      .getCompanyOverview()
      .subscribe((overview) => {
        this.mainMerchant = overview;
      });
  }

  formatNumber(number: number): string {
    return this.decimalPipe.transform(number, '1.2-2');
  }

  ngOnDestroy(): void {
    if (this.subscriptionRefs) {
      this.subscriptionRefs.unsubscribe();
    }
  }
}
