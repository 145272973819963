import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerChangeSetAPIResponse } from '../types/offline-customer.type';

const API_URL = '/api';
const POS_SERVICE_URL = '/api/pos-service';

@Injectable()
export class OfflineCustomerService {
  constructor(private http: HttpClient) { }

  getOfflineCustomers(queryParams): Observable<any> {
    let customerQueryParams = new HttpParams();
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key]) {
        customerQueryParams = customerQueryParams.set(key, queryParams[key]);
      }
    });

    return this.http.get(`${API_URL}/offline-data/customers`, { params: customerQueryParams });
  }

  getOfflineCustomersChangeSet(
    updatedAt?: string,
  ): Observable<CustomerChangeSetAPIResponse[]> {
    let queryParams = new HttpParams();
    if (updatedAt) {
      queryParams = queryParams.set('updatedAt', updatedAt);
    }
    return this.http.get<CustomerChangeSetAPIResponse[]>(
      `${POS_SERVICE_URL}/customers/change-set`,
      {
        params: queryParams,
      },
    );
  }
}
