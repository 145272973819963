export enum Timezones {
  AsiaRiyadh = 'Asia/Riyadh',
  UTC = 'UTC',
}

export const isoDateOnlyRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export const isValidIsoDateOnlyFormat = (date: string): boolean => {
  return isoDateOnlyRegex.test(date);
};
